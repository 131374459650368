<template>
  <!-- Header -->
  <header class="header header-three" :class="{ sticky: isSticky }">
    <div class="container-fluid">
      <nav class="navbar navbar-expand-lg header-nav">
        <div class="navbar-header">
          <a id="mobile_btn" href="javascript:void(0);">
            <span class="bar-icon">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </a>
          <router-link to="/home/" class="navbar-brand logo scroll-logo">
            <img src="@/assets/img/logo-white.svg" class="img-fluid" alt="Logo" />
          </router-link>
          <router-link to="/home/" class="navbar-brand logo original-logo">
            <img src="@/assets/img/logo.svg" class="img-fluid" alt="Logo" />
          </router-link>
          <div class="input-group">
            <img src="@/assets/img/icon/dashboard-line.svg" alt="Img" />
            <vue-select :options="Categories" id="category" placeholder="Categories" />
          </div>
        </div>
        <div class="main-menu-wrapper">
          <div class="menu-header">
            <router-link to="/home/" class="menu-logo">
              <img src="@/assets/img/logo.svg" class="img-fluid" alt="Logo" />
            </router-link>
            <a id="menu_close" class="menu-close" href="javascript:void(0);">
              <i class="fas fa-times"></i>
            </a>
          </div>
          <header-menu></header-menu>
        </div>
        <ul class="nav header-navbar-rht">
          <li>
            <router-link to="/register" class="log-btn">Register</router-link>
          </li>
          <li><router-link to="/" class="log-btn"> Login</router-link></li>
          <li class="searchbar">
            <a href="javascript:void(0);" class="reg-btn"
              ><i class="feather-search"></i
            ></a>
            <div class="togglesearch">
              <form action="#">
                <div class="input-group">
                  <input type="text" class="form-control" />
                  <button type="submit" class="btn">Search</button>
                </div>
              </form>
            </div>
          </li>
          <li>
            <a href="javascript:void(0);" class="reg-btn"
              ><i class="feather-shopping-cart"></i
            ></a>
          </li>
          <li>
            <router-link to="/post-project" class="login-btn"
              ><i class="feather-plus me-1"></i>Post a Project
            </router-link>
          </li>
        </ul>
      </nav>
    </div>
  </header>
  <!-- /Header -->
</template>
<script>
export default {
  data() {
    return {
      sticky: false,
      header_bg: false,
      isSticky: false,
      Categories: [
        "Categories",
        "Video & Animation",
        "Music & Audio",
        "Writing & Translation",
        "Digital Marketing",
        "Design & Creative",
        "Development & IT",
      ],
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const scroll = window.pageYOffset || document.documentElement.scrollTop;
      this.isSticky = scroll >= 100;
    },
  },
};
</script>
