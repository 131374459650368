<template>
  <div class="text-center on-board select-account group-select">
    <div class="select-type">
      <div class="account-onborad onboard-head">
        <h2>Select Account Type</h2>
        <p>Don’t worry, this can be changed later.</p>
        <div class="select-box d-flex justify-content-center">
          <input
            checked="checked"
            id="freelance"
            type="radio"
            name="credit-card"
            value="visa"
          />
          <label
            class="employee-level free-lance-img accounts_type"
            data-id="freelance"
            for="freelance"
          >
            <router-link to="onboard-screen">
              <img src="@/assets/img/icon/checks.svg" alt="Img" class="checks-set" />
              <img src="@/assets/img/select-04.svg" alt="Img" class="img-fluid" />
              <span>Freelancer</span>
            </router-link>
          </label>
          <input
            id="employee"
            class="accounts_type"
            type="radio"
            name="credit-card"
            value="mastercard"
          />
          <label
            class="employee-level employee-img accounts_type"
            data-id="employee"
            for="employee"
            ><router-link to="onboard-screen-employer"
              ><img src="@/assets/img/select-05.svg" alt="Img" class="img-fluid" /><span
                >Employer</span
              ></router-link
            ></label
          >
        </div>
      </div>
      <input
        class="btn btn-prev prev_btn btn-back"
        name="next"
        type="button"
        value="Back"
        disabled
      />
      <input
        class="btn next_btn btn-primary btn-get btn-next"
        @click="nextStep"
        :disabled="currentStep === totalSteps"
        name="next"
        type="submit"
        value="Next"
      />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      currentStep: 1,
      totalSteps: 5,
    };
  },
  methods: {
    nextStep() {
      if (this.currentStep < this.totalSteps) {
        this.currentStep++;
      }
    },
  },
};
</script>
