<template>
  <div class="col-lg-12 w-100">
    <div class="card flex-fill mb-3">
      <div class="pro-head">
        <h4 class="pro-titles mb-0">Skills</h4>
      </div>
      <div class="pro-body skill-info">
        <div
          class="form-row align-items-center skill-cont"
          v-for="item in event"
          :key="item.id"
        >
          <div class="input-block col-md-6">
            <input type="text" class="form-control" :value="item.value" />
          </div>
          <div class="input-block col-md-5">
            <vue-select :options="Skils" placehloder="Basic" />
          </div>
          <div class="input-block col-md-1">
            <a
              href="javascript:void(0);"
              class="btn trash-icon"
              @click="deleteEvent(event)"
              ><i class="far fa-trash-alt"></i
            ></a>
          </div>
        </div>
        <div class="form-row align-items-center skill-cont">
          <div class="input-block col-md-6">
            <input type="text" class="form-control" value="React Developer" />
          </div>
          <div class="input-block col-md-5">
            <vue-select :options="Skils" placehloder="Basic" />
          </div>
          <div class="input-block col-md-1">
            <a
              href="javascript:void(0);"
              class="btn fund-btn skill-add add-btn-profile"
              @click="add()"
              ><i class="feather-plus"></i
            ></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const event = [
  {
    id: 1,
    placeholder: "Basic",
    value: "Front End Developer",
  },
  {
    id: 2,
    placeholder: "Advanced",
    value: "UI UX Designer",
  },
  {
    id: 4,
    placeholder: "Intermediate",
    value: "React Developer",
  },
];
export default {
  data() {
    return {
      Skils: ["Basic", "Intermediate", "Expert"],
      SkilsOne: ["Advanced", "Intermediate", "Expert"],
      SkilsTwo: ["Intermediate", "Basic", "Expert"],
      SkilsThree: ["Intermediate", "Basic", "Expert"],
      event,
    };
  },
  methods: {
    deleteEvent: function (event) {
      this.event.splice(this.event.indexOf(event), 1);
    },
    add() {
      this.event.push({
        id: 5,
        icon: "feather-github",
        placeholder: "Social Link",
      });
    },
  },
};
</script>
