<template>
  <div class="dashboard-page">
    <div class="content content-page bookmark">
      <div class="container">
        <div class="row">
          <!-- sidebar -->
          <div class="col-xl-3 col-lg-4 theiaStickySidebar">
            <freelancer-sidebar></freelancer-sidebar>
          </div>
          <!-- /sidebar -->

          <div class="col-xl-9 col-lg-8">
            <div class="dashboard-sec payout-section freelancer-statements">
              <div class="page-title portfolio-title">
                <h3 class="mb-0">Wallet</h3>
              </div>
              <div class="widget-section">
                <div class="row row-gap">
                  <div
                    class="col-md-6 col-lg-3 d-flex"
                    v-for="record in Withdraw_Money"
                    :key="record.id"
                  >
                    <div class="dash-widget flex-fill">
                      <div class="dash-info">
                        <div class="d-flex">
                          <div class="dashboard-icon">
                            <img
                              :src="require(`@/assets/img/icon/${record.Image}`)"
                              alt="icon"
                            />
                          </div>
                          <div class="dash-widget-info">
                            <span>{{ record.Total }}</span>
                            <h5>{{ record.Amount }}</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Table -->
              <div class="table-top-section">
                <div class="table-header">
                  <h5 class="mb-0">Wallet Transactions</h5>
                </div>
                <div class="table-options">
                  <div class="data-search-input">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search here"
                      name="search"
                      id="search"
                    />
                  </div>
                  <a href="javascript:void(0)';" class="table-option-btn">
                    <i class="feather-printer"></i>
                  </a>
                  <a href="javascript:void(0)';" class="table-option-btn">
                    <i class="feather-repeat"></i>
                  </a>
                </div>
              </div>
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>ID</th>
                      <th>Type</th>
                      <th>Payment Type</th>
                      <th>Amount</th>
                      <th>Fees</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="record in Withdraw_Table" :key="record.id">
                      <td>{{ record.Date }}</td>
                      <td>{{ record.ID }}</td>
                      <td>{{ record.Type }}</td>
                      <td>{{ record.Payment }}</td>
                      <td :class="record.Class">{{ record.Amount }}</td>
                      <td>{{ record.Fees }}</td>
                      <td>
                        <span :class="record.Class1">{{ record.Status }}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <!-- /Table -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Withdraw_Money from "@/assets/json/withdraw-money.json";
import Withdraw_Table from "@/assets/json/withdraw-table.json";
export default {
  data() {
    return {
      Withdraw_Money: Withdraw_Money,
      Withdraw_Table: Withdraw_Table,
    };
  },
};
</script>
