<template>
    <!-- Footer -->
    <footer class="footer footer-three">

        <!-- Footer Top Nav -->
        <div class="footer-top-blk">
            <div class="container">
                <div class="footer-social-group">
                    <div class="row">
                        <div class="col-md-6 col-lg-6 ">
                            <ul class="nav footer-bottom-nav p-0 mb-0">
                                <li><router-link to="/employers/chats">Chat</router-link></li>
                                <li><router-link to="/faq">Faq</router-link></li>
                                <li><router-link to="/employers/review">Reviews</router-link></li>
                                <li><router-link to="/privacy-policy">Privacy Policy</router-link></li>
                                <li><router-link to="/term-condition">Terms of use</router-link></li>
                            </ul>
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <div class="social-icon d-flex">
                                <span>Follow us on : </span>
                                <ul>
                                    <li><a href="javascript:void(0);" class="icon" target="_blank"><i
                                                class="fab fa-facebook-f"></i> </a></li>
                                    <li><a href="javascript:void(0);" class="icon" target="_blank"><i
                                                class="fab fa-linkedin-in"></i> </a></li>
                                    <li><a href="javascript:void(0);" class="icon" target="_blank"><i
                                                class="fab fa-twitter"></i> </a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /Footer Top Nav -->
        <div class="footer-top ">
            <div class="container">

                <div class="row">
                    <div class="col-lg-2 col-md-4">
                        <div class="footer-widget footer-menu">
                            <h2 class="footer-title">Useful Links</h2>
                            <ul>
                                <li><router-link to="/pages/about">About Us</router-link></li>
                                <li><router-link to="/blog/blog-list">Blog</router-link></li>
                                <li><router-link to="/">Login</router-link></li>
                                <li><router-link to="/register">Register</router-link></li>
                                <li><router-link to="/forgot-password">Forgot Password</router-link></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-4">
                        <div class="footer-widget footer-menu">
                            <h2 class="footer-title">Help & Support</h2>
                            <ul>
                                <li><a href="javascript:void(0);">Browse Candidates</a></li>
                                <li><a href="javascript:void(0);">Employers Dashboard</a></li>
                                <li><a href="javascript:void(0);">Job Packages</a></li>
                                <li><a href="javascript:void(0);">Jobs Featured</a></li>
                                <li><a href="javascript:void(0);">Post A Job</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-4">
                        <div class="footer-widget footer-menu">
                            <h2 class="footer-title">Other Links</h2>
                            <ul>
                                <li><router-link to="/freelancer/freelancer-dashboard">Freelancers</router-link></li>
                                <li><router-link to="/freelancer/freelancer-portfolio">Freelancer Details</router-link></li>
                                <li><router-link to="/freelancer/project">Project</router-link></li>
                                <li><router-link to="/freelancer/project-details">Project Details</router-link></li>
                                <li><router-link to="/post-project">Post Project</router-link></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-4">
                        <div class="footer-widget footer-menu">
                            <h2 class="footer-title">Categories</h2>
                            <ul>
                                <li><router-link to="/freelancer/project">Website Design</router-link></li>
                                <li><router-link to="/freelancer/project">Mobile Apps</router-link></li>
                                <li><router-link to="/freelancer/project">Android Apps</router-link></li>
                                <li><router-link to="/freelancer/project">iPhone Apps</router-link></li>
                                <li><router-link to="/freelancer/project">Software Architecture</router-link></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="footer-widget footer-menu">
                            <div class="applicate-mobile-blk">
                                <h6>Mobile Application</h6>
                                <p>We help our customers harness the power of AI to make smarter decisions</p>
                            </div>
                            <div class="row g-2">
                                <div class="col-lg-4 col-md-6 col-6">
                                    <a href="javascript:void(0);"><img class="img-fluid" src="@/assets/img/app-store.svg"
                                            alt="app-store"></a>
                                </div>
                                <div class="col-lg-4 col-md-6 col-6">
                                    <a href="javascript:void(0);"><img class="img-fluid" src="@/assets/img/google-play.svg"
                                            alt="google-play"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /Footer Top -->

        <!-- Footer Bottom -->
        <div class="footer-bottom">
            <!-- Copyright -->
            <div class="copyright">
                <div class="row">
                    <div class="col-md-12 col-lg-12 ">
                        <div class="copyright-text text-center">
                            <p class="mb-0">Copyright {{ new Date().getFullYear() }} © KofeJob. All right reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- /Copyright -->
        </div>
        <!-- /Footer Bottom -->
    </footer>
    <!-- /Footer -->
</template>