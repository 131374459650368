<template>
  <div class="company-detail-block pb-0">
    <h4 class="company-detail-title">Review (5)</h4>
    <div class="project-proposals-block" v-for="record in Review" :key="record.id">
      <div class="project-proposals-img">
        <img
          :src="require(`@/assets/img/user/${record.Image}`)"
          class="img-fluid"
          alt="user"
        />
      </div>
      <div class="project-proposals-description">
        <div class="proposals-user-detail">
          <div>
            <h5>{{ record.Name }}</h5>
            <ul class="d-flex">
              <li>
                <div class="proposals-user-review">
                  <span><i class="fa fa-star"></i>5.0 (346 Reviews)</span>
                </div>
              </li>
              <li>
                <div class="proposals-user-review">
                  <span><i class="feather-calendar"></i>{{ record.Mins }}</span>
                </div>
              </li>
            </ul>
          </div>
          <div>
            <div class="proposals-pricing">
              <h4>{{ record.Amount }}</h4>
              <span>Price : Fixed </span>
            </div>
          </div>
        </div>
        <p class="mb-0">
          I've been buying and selling on kofejob Marketplace for the past two years, and
          it's been a game-changer for me. The platform is user-friendly, and I've had
          great success selling my handmade crafts. Plus, the customer support team is
          always there to help if I have any questions or issues.
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import Review from "@/assets/json/review.json";
export default {
  data() {
    return {
      Review: Review,
    };
  },
};
</script>
