<template>
  <div class="dashboard-page">
    <div class="content content-page">
      <div class="container">
        <div class="row">
          <div class="col-xl-3 col-lg-4 theiaStickySidebar">
            <freelancer-sidebar></freelancer-sidebar>
          </div>
          <div class="col-xl-9 col-lg-8">
            <div class="page-title">
              <h3>Completed Projects</h3>
            </div>
            <!-- Proposals list -->
            <div class="proposals-section">
              <!-- Proposals -->
              <div class="freelancer-proposals proposal-ongoing">
                <div class="project-proposals align-items-center freelancer">
                  <div class="proposal-info">
                    <div class="proposals-details">
                      <h3 class="proposals-title">
                        3D Renders and Amazon Product Store images/Video
                      </h3>
                      <ul>
                        <li>
                          <div class="proposal-client-price">
                            <h4 class="title-info">Client Price</h4>
                            <h3 class="client-price">
                              $599.00 <span class="price-type">( Fixed )</span>
                            </h3>
                          </div>
                        </li>
                        <li>
                          <div class="proposal-job-type">
                            <h4 class="title-info">Project Deadline</h4>
                            <h3>28 Oct 2023</h3>
                          </div>
                        </li>
                        <li>
                          <div class="proposal-img">
                            <div class="proposal-client d-flex align-items-center">
                              <img
                                src="@/assets/img/user/table-avatar-02.jpg"
                                alt="Img"
                                class="img-fluid me-2"
                              />
                              <div>
                                <h4>Hayley Melba</h4>
                                <span
                                  >10 Oct 2023<i class="fa-solid fa-star"></i>5.0</span
                                >
                              </div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="project-action text-center">
                            <router-link
                              to="freelancer-view-project-detail"
                              class="projects-btn"
                              >View Details</router-link
                            >
                            <router-link
                              to="freelancer-view-project-detail"
                              class="projects-btn completed-btn"
                              ><i class="fa fa-award me-2"></i>Completed</router-link
                            >
                            <span>
                              <i class="fa-solid fa-star"></i>
                              <i class="fa-solid fa-star"></i>
                              <i class="fa-solid fa-star"></i>
                              <i class="fa-solid fa-star"></i>
                              <i class="fa-solid fa-star"></i>
                              4.5
                            </span>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Proposals -->

              <!-- Proposals -->
              <div class="freelancer-proposals proposal-ongoing">
                <div class="project-proposals align-items-center freelancer">
                  <div class="proposal-info">
                    <div class="proposals-details">
                      <h3 class="proposals-title">
                        Landing Page Redesign / Sales Page Redesign (Not Entire Web)
                      </h3>
                      <ul>
                        <li>
                          <div class="proposal-client-price">
                            <h4 class="title-info">Client Price</h4>
                            <h3 class="client-price">
                              $280.00 <span class="price-type">( Fixed )</span>
                            </h3>
                          </div>
                        </li>
                        <li>
                          <div class="proposal-job-type">
                            <h4 class="title-info">Project Deadline</h4>
                            <h3>25 Oct 2023</h3>
                          </div>
                        </li>
                        <li>
                          <div class="proposal-img">
                            <div class="proposal-client d-flex align-items-center">
                              <img
                                src="@/assets/img/user/user-01.jpg"
                                alt="Img"
                                class="img-fluid me-2"
                              />
                              <div>
                                <h4>James Douglas</h4>
                                <span
                                  >12 Oct 2023<i class="fa-solid fa-star"></i>5.0</span
                                >
                              </div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="project-action text-center">
                            <router-link
                              to="freelancer-view-project-detail"
                              class="projects-btn"
                              >View Details</router-link
                            >
                            <router-link
                              to="freelancer-view-project-detail"
                              class="projects-btn completed-btn"
                              ><i class="fa fa-award me-2"></i>Completed</router-link
                            >
                            <a
                              href="#write-review"
                              data-bs-toggle="modal"
                              class="btn-write-review"
                              >Write Review</a
                            >
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Proposals -->

              <!-- Proposals -->
              <div class="freelancer-proposals proposal-ongoing">
                <div class="project-proposals align-items-center freelancer">
                  <div class="proposal-info">
                    <div class="proposals-details">
                      <h3 class="proposals-title">
                        WooCommerce Product Page Back Up Restoration
                      </h3>
                      <ul>
                        <li>
                          <div class="proposal-client-price">
                            <h4 class="title-info">Client Price</h4>
                            <h3 class="client-price">
                              $140.00 <span class="price-type">( Fixed )</span>
                            </h3>
                          </div>
                        </li>
                        <li>
                          <div class="proposal-job-type">
                            <h4 class="title-info">Project Deadline</h4>
                            <h3>17 Oct 2023</h3>
                          </div>
                        </li>
                        <li>
                          <div class="proposal-img">
                            <div class="proposal-client d-flex align-items-center">
                              <img
                                src="@/assets/img/user/user-02.jpg"
                                alt="Img"
                                class="img-fluid me-2"
                              />
                              <div>
                                <h4>Tony Ingle</h4>
                                <span
                                  >26 Oct 2023<i class="fa-solid fa-star"></i>5.0</span
                                >
                              </div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="project-action text-center">
                            <router-link
                              to="freelancer-view-project-detail"
                              class="projects-btn"
                              >View Details</router-link
                            >
                            <router-link
                              to="freelancer-view-project-detail"
                              class="projects-btn completed-btn"
                              ><i class="fa fa-award me-2"></i>Completed</router-link
                            >
                            <a
                              href="#write-review"
                              data-bs-toggle="modal"
                              class="btn-write-review"
                              >Write Review</a
                            >
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Proposals -->

              <!-- Proposals -->
              <div class="freelancer-proposals proposal-ongoing">
                <div class="project-proposals align-items-center freelancer">
                  <div class="proposal-info">
                    <div class="proposals-details">
                      <h3 class="proposals-title">
                        Full-stack Developer to help us to build our
                      </h3>
                      <ul>
                        <li>
                          <div class="proposal-client-price">
                            <h4 class="title-info">Client Price</h4>
                            <h3 class="client-price">
                              $350.00 <span class="price-type">( Fixed )</span>
                            </h3>
                          </div>
                        </li>
                        <li>
                          <div class="proposal-job-type">
                            <h4 class="title-info">Project Deadline</h4>
                            <h3>28 Oct 2023</h3>
                          </div>
                        </li>
                        <li>
                          <div class="proposal-img">
                            <div class="proposal-client d-flex align-items-center">
                              <img
                                src="@/assets/img/user/table-avatar-05.jpg"
                                alt="Img"
                                class="img-fluid me-2"
                              />
                              <div>
                                <h4>Sam Prendiville</h4>
                                <span
                                  >03 Nov 2023<i class="fa-solid fa-star"></i>5.0</span
                                >
                              </div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="project-action text-center">
                            <router-link
                              to="freelancer-view-project-detail"
                              class="projects-btn"
                              >View Details</router-link
                            >
                            <router-link
                              to="freelancer-view-project-detail"
                              class="projects-btn completed-btn"
                              ><i class="fa fa-award me-2"></i>Completed</router-link
                            >
                            <a
                              href="#write-review"
                              data-bs-toggle="modal"
                              class="btn-write-review"
                              >Write Review</a
                            >
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Proposals -->

              <!-- Proposals -->
              <div class="freelancer-proposals proposal-ongoing">
                <div class="project-proposals align-items-center freelancer">
                  <div class="proposal-info">
                    <div class="proposals-details">
                      <h3 class="proposals-title">
                        Video animator to bring some illustrations to life
                      </h3>
                      <ul>
                        <li>
                          <div class="proposal-client-price">
                            <h4 class="title-info">Client Price</h4>
                            <h3 class="client-price">
                              $620.00<span class="price-type">( Fixed )</span>
                            </h3>
                          </div>
                        </li>
                        <li>
                          <div class="proposal-job-type">
                            <h4 class="title-info">Project Deadline</h4>
                            <h3>11 Oct 2023</h3>
                          </div>
                        </li>
                        <li>
                          <div class="proposal-img">
                            <div class="proposal-client d-flex align-items-center">
                              <img
                                src="@/assets/img/user/user-03.jpg"
                                alt="Img"
                                class="img-fluid me-2"
                              />
                              <div>
                                <h4>Samantha Ogden</h4>
                                <span
                                  >14 Nov 2023<i class="fa-solid fa-star"></i>5.0</span
                                >
                              </div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="project-action text-center">
                            <router-link
                              to="freelancer-view-project-detail"
                              class="projects-btn"
                              >View Details</router-link
                            >
                            <router-link
                              to="freelancer-view-project-detail"
                              class="projects-btn completed-btn"
                              ><i class="fa fa-award me-2"></i>Completed</router-link
                            >
                            <a
                              href="#write-review"
                              data-bs-toggle="modal"
                              class="btn-write-review"
                              >Write Review</a
                            >
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Proposals -->

              <!-- Proposals -->
              <div class="freelancer-proposals proposal-ongoing">
                <div class="project-proposals align-items-center freelancer">
                  <div class="proposal-info">
                    <div class="proposals-details">
                      <h3 class="proposals-title">Food Delivery Mobile App</h3>
                      <ul>
                        <li>
                          <div class="proposal-client-price">
                            <h4 class="title-info">Client Price</h4>
                            <h3 class="client-price">
                              $260.00 <span class="price-type">( Fixed )</span>
                            </h3>
                          </div>
                        </li>
                        <li>
                          <div class="proposal-job-type">
                            <h4 class="title-info">Project Deadline</h4>
                            <h3>03 Oct 2023</h3>
                          </div>
                        </li>
                        <li>
                          <div class="proposal-img">
                            <div class="proposal-client d-flex align-items-center">
                              <img
                                src="@/assets/img/user/table-avatar-04.jpg"
                                alt="Img"
                                class="img-fluid me-2"
                              />
                              <div>
                                <h4>Sam Facy</h4>
                                <span
                                  >18 Nov 2023<i class="fa-solid fa-star"></i>5.0</span
                                >
                              </div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="project-action text-center">
                            <router-link
                              to="freelancer-view-project-detail"
                              class="projects-btn"
                              >View Details</router-link
                            >
                            <router-link
                              to="freelancer-view-project-detail"
                              class="projects-btn completed-btn"
                              ><i class="fa fa-award me-2"></i>Completed</router-link
                            >
                            <a
                              href="#write-review"
                              data-bs-toggle="modal"
                              class="btn-write-review"
                              >Write Review</a
                            >
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Proposals -->
            </div>
            <!-- /Proposals list -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <freelancer-completed-modal></freelancer-completed-modal>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
