<template>
    <section class="section projects">
				<div class="container">
					<div class="row">					
						<div class="col-md-12 col-sm-12 col-12 mx-auto text-center">
							<div class="section-header aos aos-init aos-animate" data-aos="fade-up">
								<h2 class="header-title">Need something done?</h2>
								<p>Kofejob is here to help you to get job completed</p>
							</div>
						</div>
					</div>
					<div class="row row-gap">		
						<!-- Feature Item --> 
						<div class="col-xl-3 col-md-6 aos d-flex aos-init aos-animate" data-aos="zoom-in" data-aos-duration="1000">
							<div class="feature-items">
								<div class="feature-icon">
									<img src="@/assets/img/icon/great1.svg" class="img-fluid" alt="Img">
								</div>
								<div class="feature-content course-count">
									<h3>Browse Portfolios</h3>
									<p>Find professionals you can trust by browsing their samples of previous work .</p>
								</div>
							</div>
						</div>
						<!-- /Feature Item -->
						
						<!-- Feature Item -->
						<div class="col-xl-3 col-md-6 aos d-flex aos-init aos-animate" data-aos="zoom-in" data-aos-duration="1500">
							<div class="feature-items ">
								<div class="feature-icon">
									<img src="@/assets/img/icon/great2.svg" class="img-fluid" alt="Img">
								</div>
								<div class="feature-content course-count">
									<h3>Fast Bids</h3>
									<p>Receive obligation free quotes from our talented freelancers fast. 80% of projects get bid</p>
								</div>
							</div>
						</div>
						<!-- /Feature Item -->
						
						<!-- Feature Item -->
						<div class="col-xl-3 col-md-6 aos d-flex aos-init aos-animate" data-aos="zoom-in" data-aos-duration="2000">
							<div class="feature-items ">
								<div class="feature-icon">
									<img src="@/assets/img/icon/great3.svg" class="img-fluid" alt="Img">
								</div>
								<div class="feature-content course-count">
									<h3>Quality Work</h3>
									<p>Kofejob.com has by far the largest pool of quality freelancers globally- over 50 million to choose from.</p>
								</div>
							</div>
						</div>
						<!-- /Feature Item -->	

						<!-- Feature Item -->
						<div class="col-xl-3 col-md-6 aos d-flex aos-init aos-animate" data-aos="zoom-in" data-aos-duration="2500">
							<div class="feature-items ">
								<div class="feature-icon">
									<img src="@/assets/img/icon/great4.svg" class="img-fluid" alt="Img">
								</div>
								<div class="feature-content course-count">
									<h3>Track Progress</h3>
									<p>Keep up-to-date and on-the-go with our time tracker Always know what freelancers are up to.</p>
								</div>
							</div>
						</div>
						<!-- /Feature Item -->
												
					</div>
				</div>
			</section>
    </template>