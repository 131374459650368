<template>
  <div class="bg-four">
    <loader v-if="showLoader"></loader>
    <div class="home-four-wrapper">
      <header-four></header-four>
      <home-four-homebanner></home-four-homebanner>
      <home-four-categories></home-four-categories>
      <home-four-projects></home-four-projects>
      <home-four-developers></home-four-developers>
      <home-four-marketplace></home-four-marketplace>
      <home-four-feature></home-four-feature>
      <home-four-testimonial></home-four-testimonial>
      <home-four-platform></home-four-platform>
      <home-four-faq></home-four-faq>
      <home-four-blog></home-four-blog>
      <footer-four></footer-four>
    </div>
  </div>
  <!-- Scroll Top -->
  <button class="scroll-top scroll-top-next scroll-to-target" data-target="html">
    <span class="ti-angle-up"><i class="feather-arrow-up"></i></span>
  </button>
  <!-- /Scroll Top -->
</template>

<script>
export default {
  data() {
    return {
      showLoader: true,
    };
  },
  mounted() {
    window.addEventListener("scroll", function () {
      var scrollPosition = window.pageYOffset || document.documentElement.scrollTop;

      var scrollToTargetElements = document.querySelectorAll(".scroll-to-target");
      scrollToTargetElements.forEach(function (element) {
        if (scrollPosition > 0) {
          element.classList.add("open");
        } else {
          element.classList.remove("open");
        }

        if (scrollPosition > 500) {
          element.classList.add("open");
        } else {
          element.classList.remove("open");
        }
      });
    });
    var scrollToTargetElements = document.getElementsByClassName("scroll-to-target");
    if (scrollToTargetElements.length) {
      for (var i = 0; i < scrollToTargetElements.length; i++) {
        scrollToTargetElements[i].addEventListener("click", function () {
          var target = this.getAttribute("data-target");
          var targetElement = document.querySelector(target);
          if (targetElement) {
            var targetOffsetTop = targetElement.offsetTop;

            // animate scroll
            var scrollOptions = {
              top: targetOffsetTop,
              behavior: "smooth",
            };
            window.scrollTo(scrollOptions);
          }
        });
      }
    }
    setTimeout(() => {
      // Show the loader
      this.showLoader = true;

      setTimeout(() => {
        // Hide the loader
        this.showLoader = false;
      }, 100);
    }, 500);
  },
};
</script>
