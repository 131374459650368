<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>

  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content container-fluid">
      <!-- Page Header -->
      <div class="page-header subscribe-head">
        <div class="row align-items-center">
          <breadcrumbthree :title="title" :text="text" :text1="text1" />

          <div class="col-auto">
            <router-link to="/admin/view-price-employe" class="btn add-button me-2">
              View Pricing
            </router-link>
            <a
              class="btn filter-btn"
              href="javascript:;"
              data-bs-toggle="modal"
              data-bs-target="#add-plan-freelance"
            >
              New Subscription
            </a>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <div class="row">
        <div class="col-sm-12">
          <div class="subscribe-employe">
            <subscriptiontabset />
          </div>
          <div class="card">
            <div class="card-body">
              <div class="showentries">
                <label
                  >Show
                  <select>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  entries
                </label>
                <div class="entries">
                  <span>Search: </span>
                  <input
                    type="search"
                    class="form-control form-control-sm ms-2"
                    placeholder=""
                    aria-controls="DataTables_Table_0"
                  />
                </div>
              </div>
              <div class="table-responsive">
                <a-table :columns="columns" :data-source="data">
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key === 'Name'">
                      <a href="javascript:;">{{ record.Name }}</a>
                    </template>
                    <template v-else-if="column.key === 'inputbox'">
                      <div class="form-check custom-checkbox">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          id="customCheck5"
                        />
                        <label class="form-check-label" for="customCheck5"></label>
                      </div>
                    </template>
                    <template v-if="column.key === 'PlanName'">
                      <a href="javascript:;">{{ record.PlanName }}</a>
                    </template>
                    <template v-if="column.key === 'Status'">
                      <button :class="record.Class">{{ record.Status }}</button>
                    </template>
                    <template v-else-if="column.key === 'action'">
                      <div class="text-end subscription-end">
                        <a
                          href="javascript:void(0);"
                          class="btn btn-sm btn-secondary me-2"
                          data-bs-toggle="modal"
                          data-bs-target="#edit-category"
                          ><i class="far fa-edit"></i
                        ></a>
                        <a
                          href="javascript:void(0);"
                          class="btn btn-sm btn-danger"
                          data-bs-toggle="modal"
                          data-bs-target="#delete_category"
                          ><i class="far fa-trash-alt"></i
                        ></a>
                      </div>
                    </template>
                  </template>
                </a-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Page Wrapper -->
</template>

<script>
const columns = [
  {
    title: "#",
    key: "inputbox",
    sorter: true,
  },
  {
    title: "Name",
    dataIndex: "Name",
    key: "Name",
    sorter: {
      compare: (a, b) => {
        a = a.Name.toLowerCase();
        b = b.Name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Plan Name",
    dataIndex: "PlanName",
    key: "PlanName",
    sorter: {
      compare: (a, b) => {
        a = a.PlanName.toLowerCase();
        b = b.PlanName.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Plan Type",
    dataIndex: "PlanType",
    sorter: {
      compare: (a, b) => {
        a = a.PlanType.toLowerCase();
        b = b.PlanType.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Payment Method",
    dataIndex: "PaymentMethod",
    sorter: {
      compare: (a, b) => {
        a = a.PaymentMethod.toLowerCase();
        b = b.PaymentMethod.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Create Date",
    dataIndex: "CreateDate",
    sorter: {
      compare: (a, b) => {
        a = a.CreateDate.toLowerCase();
        b = b.CreateDate.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "End Date",
    dataIndex: "EndDate",
    sorter: {
      compare: (a, b) => {
        a = a.EndDate.toLowerCase();
        b = b.EndDate.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "Status",
    sorter: {
      compare: (a, b) => {
        a = a.Status.toLowerCase();
        b = b.Status.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Actions",
    key: "action",
    sorter: true,
  },
];

const data = [
  {
    FIELD1: "",
    Name: "Mark",
    PlanName: "Professional",
    PlanType: "Monthly",
    PaymentMethod: "Credit card",
    CreateDate: "25/03/2023",
    EndDate: "25/03/2023",
    Class: "btn active-btn",
    Status: "Active",
    Actions: "",
  },
  {
    FIELD1: "",
    Name: "Steawart",
    PlanName: "Enterprise",
    PlanType: "Yearly",
    PaymentMethod: "Paypal",
    CreateDate: "28-02-2022",
    EndDate: "28-02-2022",
    Class: "btn active-btn",
    Status: "Active",
    Actions: "",
  },
  {
    FIELD1: "",
    Name: "Hendry",
    PlanName: "Basic",
    PlanType: "Monthly",
    PaymentMethod: "Paypal",
    CreateDate: "28-02-2022",
    EndDate: "28-02-2022",
    Class: "btn inactive-btn",
    Status: "Inactive",
    Actions: "",
  },
];
export default {
  data() {
    return {
      title: "Subscriber List",
      text: "Home",
      text1: "Subscriber List",
      columns,
      data,
    };
  },
};
</script>
