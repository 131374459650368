<template>
  <div class="showentries">
    <label
      >Show
      <select>
        <option value="10">10</option>
        <option value="25">25</option>
        <option value="50">50</option>
        <option value="100">100</option>
      </select>
      entries
    </label>
    <div class="entries">
      <span>Search: </span>
      <input
        type="search"
        class="form-control form-control-sm ms-2"
        placeholder=""
        aria-controls="DataTables_Table_0"
      />
    </div>
  </div>
  <div class="table-responsive">
    <a-table :columns="columns">
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'Logo'">
          <h2 class="table-avatar">
            <router-link to="/admin/profile"
              ><img
                class="img-fluid me-2"
                :src="require(`@/assets/img/company/${record.Logo}`)"
                alt="User Image"
            /></router-link>
          </h2>
        </template>
        <template v-else-if="column.key === 'inputbox'">
          <div class="form-check custom-checkbox">
            <input type="checkbox" class="form-check-input" id="customCheck5" />
            <label class="form-check-label" for="customCheck5"></label>
          </div>
        </template>
      </template>
    </a-table>
  </div>
</template>

<script>
const columns = [
  {
    title: "#",
    key: "inputbox",
    sorter: true,
  },
  {
    title: "Logo",
    dataIndex: "Logo",
    key: "Logo",
    sorter: {
      compare: (a, b) => {
        a = a.Logo.toLowerCase();
        b = b.Logo.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Title",
    dataIndex: "Title",
    sorter: true,
  },
  {
    title: "Budget",
    dataIndex: "Budget",
    sorter: {
      compare: (a, b) => {
        a = a.Budget.toLowerCase();
        b = b.Budget.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Progress",
    dataIndex: "Progress",
    key: "Progress",
    sorter: {
      compare: (a, b) => {
        a = a.Progress.toLowerCase();
        b = b.Progress.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Technology",
    dataIndex: "Technology",
    sorter: {
      compare: (a, b) => {
        a = a.Technology.toLowerCase();
        b = b.Technology.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Company",
    dataIndex: "Company",
    sorter: {
      compare: (a, b) => {
        a = a.Company.toLowerCase();
        b = b.Company.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Startdate",
    dataIndex: "Startdate",
    sorter: {
      compare: (a, b) => {
        a = a.Startdate.toLowerCase();
        b = b.Startdate.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Company",
    dataIndex: "Company",
    sorter: {
      compare: (a, b) => {
        a = a.Company.toLowerCase();
        b = b.Company.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "action",
    sorter: true,
  },
];
const data = [
  {
    FIELD1: "",
    Logo: "",
    Title: "",
    Budget: "",
    Progress: "",
    Technology: "",
    Company: "",
    Startdate: "",
    Duedate: "",
  },
];
export default {
  data() {
    return {
      columns,
    };
  },
};
</script>
