<template>
    <div class="subscribe-employe users-list">
        <ul v-bind:class="{active: currentPath == 'transaction' || currentPath == 'transaction',}">
            <li :class="currentPath == 'transaction' ? 'active' : 'notactive'"> 
                <router-link to="/admin/transaction">All Transaction </router-link>
            </li>
            <li :class="currentPath == 'transaction-deposit' ? 'active' : 'notactive'">
                <router-link to="/admin/transaction-deposit">Deposit</router-link>
            </li>
            <li :class="currentPath == 'transaction-withdraw' ? 'active' : 'notactive'">
                <router-link to="/admin/transaction-withdraw">Withdraw</router-link>
            </li>
            <li :class="currentPath == 'transaction-pending' ? 'active' : 'notactive'">
                <router-link to="/admin/transaction-pending">Pending</router-link>
            </li>
            <li :class="currentPath == 'transaction-onhold' ? 'active' : 'notactive'"> 
                <router-link to="/admin/transaction-onhold">Onhold</router-link>
            </li>
            <li :class="currentPath == 'transaction-completed' ? 'active' : 'notactive'">
                <router-link to="/admin/transaction-completed">Completed</router-link>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    data() {
        return {
            
        }
    },
    computed: {
        currentPath() {
            return this.$route.name;
        },
        returnMenu() {
            return (
                this.$route.name == "transaction" ||
                this.$route.name == "transaction-deposit" ||
                this.$route.name == "transaction-withdraw" ||
                this.$route.name == "transaction-pending" ||
                this.$route.name == "transaction-onhold" ||
                this.$route.name == "transaction-completed"
            );
        },
    },
}
</script>