<template>
  <div class="card">
    <div class="card-body">
      <div class="showentries">
        <label
          >Show
          <select>
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
          entries
        </label>
        <div class="entries">
          <span>Search: </span>
          <input
            type="search"
            class="form-control form-control-sm ms-2"
            placeholder=""
            aria-controls="DataTables_Table_0"
          />
        </div>
      </div>
      <div class="table-responsive">
        <a-table :columns="columns" :data-source="data">
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'Name'">
              <div>{{ record.Name }}</div>
            </template>
            <template v-else-if="column.key === 'Img'">
              <h2 class="table-avatar">
                <h2 class="table-avatar">
                  <router-link to="/admin/profile">
                    <img
                      class="me-2 verify-img rounded-circle img-fluid"
                      :src="require(`@/assets/admin_img/profiles/${record.Document}`)"
                      alt="User Image"
                    />
                  </router-link>
                </h2>
              </h2>
            </template>
            <template v-else-if="column.key === 'action'">
              <a href="javascript:;" class="btn btn-success btn-disable me-2">Approve</a>
              <a href="javascript:;" class="btn btn-disable">Reject</a>
            </template>
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
const columns = [
  {
    title: "No",
    dataIndex: "No",
    sorter: true,
  },
  {
    title: "Name",
    dataIndex: "Name",
    key: "Name",
    sorter: {
      compare: (a, b) => {
        a = a.Name.toLowerCase();
        b = b.Name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Contact Number",
    dataIndex: "ContactNumber",
    sorter: {
      compare: (a, b) => {
        a = a.ContactNumber.toLowerCase();
        b = b.ContactNumber.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "CNIC / Passport / NIN / SSN",
    dataIndex: "CNIC",
    sorter: {
      compare: (a, b) => {
        a = a.CNIC.toLowerCase();
        b = b.CNIC.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Document",
    dataIndex: "Document",
    key: "Img",
    sorter: true,
  },
  {
    title: "Address",
    dataIndex: "Address",
    sorter: {
      compare: (a, b) => {
        a = a.Address.toLowerCase();
        b = b.Address.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Actions",
    key: "action",
    sorter: true,
  },
];
const data = [
  {
    No: 1,
    Name: "Emily Smith",
    ContactNumber: "601-316-9060",
    CNIC: "518-43-5504",
    Document: "avatar-01.jpg",
    Address: "148 Tanglewood Road, Fulton",
  },
  {
    No: 2,
    Name: "Angeline S Cutshaw",
    ContactNumber: "601-283-5402",
    CNIC: "158-07-8510",
    Document: "avatar-03.jpg",
    Address: "North Carolina, USAi",
  },
  {
    No: 3,
    Name: "Robert J Sullivan",
    ContactNumber: "601-316-5657",
    CNIC: "221-04-8830",
    Document: "avatar-02.jpg",
    Address: "Kentucky, USA",
  },
  {
    No: 4,
    Name: "Crystal Kemper",
    ContactNumber: "601-321-8956",
    CNIC: "221-04-5659",
    Document: "avatar-04.jpg",
    Address: "64 Florida, USA",
  },
  {
    No: 5,
    Name: "Andrew Glover",
    ContactNumber: "601-213-4545",
    CNIC: "221-04-7245",
    Document: "avatar-05.jpg",
    Address: "New Jersey, USA",
  },
  {
    No: 6,
    Name: "Jacqueline Daye",
    ContactNumber: "601-456-2468",
    CNIC: "221-04-8830",
    Document: "avatar-06.jpg",
    Address: "Indiana, USA",
  },
  {
    No: 7,
    Name: "Tony Ingle",
    ContactNumber: "601-345-7834",
    CNIC: "221-04-8830",
    Document: "avatar-07.jpg",
    Address: "644 Ferrell Street, Wadena",
  },
  {
    No: 8,
    Name: "Albert Boone",
    ContactNumber: "601-316-7765",
    CNIC: "321-04-3434",
    Document: "avatar-08.jpg",
    Address: "644 Ferrell Street, Wadena",
  },
  {
    No: 9,
    Name: "Kathleen Kaiser",
    ContactNumber: "601-545-2323",
    CNIC: "121-04-2323",
    Document: "avatar-09.jpg",
    Address: "644 Alabama, USA",
  },
  {
    No: 10,
    Name: "Mickey Bernier",
    ContactNumber: "601-343-5656",
    CNIC: "221-34-4545",
    Document: "avatar-10.jpg",
    Address: "North Carolina, USA",
  },
];
export default {
  data() {
    return {
      columns,
      data,
    };
  },
};
</script>
