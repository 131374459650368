<template>
  <div class="field-item personal-info space-info">
    <form action="#">
      <div class="row">
        <div class="col-md-12">
          <h4>Details</h4>
        </div>
        <div class="col-md-6">
          <div class="input-block">
            <label class="form-label">Company Name</label>
            <input type="text" class="form-control" />
          </div>
        </div>
        <div class="col-md-6">
          <div class="input-block">
            <label class="form-label">Tagline</label>
            <input type="text" class="form-control" />
          </div>
        </div>
        <div class="col-md-6">
          <div class="input-block">
            <label class="form-label">Established On</label>
            <input type="text" class="form-control" />
          </div>
        </div>
        <div class="col-md-6">
          <div class="input-block">
            <label class="form-label">Company Owner Name</label>
            <input type="text" class="form-control" />
          </div>
        </div>
        <div class="col-md-6">
          <div class="input-block">
            <label class="focus-label">Industry</label>
            <vue-select
              :options="SelectIndustry"
              id="selectindustry"
              placeholder="Select"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div class="input-block">
            <label class="form-label">Website</label>
            <input type="text" class="form-control" />
          </div>
        </div>
        <div class="col-md-12">
          <div class="input-block">
            <label class="form-label">Team Size</label>
          </div>
          <div class="check-radio">
            <ul>
              <li>
                <label class="custom_radio me-4">
                  <input type="radio" name="budget" value="Yes" checked="" />
                  <span class="checkmark"></span> It's just me
                </label>
              </li>
              <li>
                <label class="custom_radio me-4">
                  <input type="radio" name="budget" />
                  <span class="checkmark"></span>2-9 employees
                </label>
              </li>
              <li>
                <label class="custom_radio me-4">
                  <input type="radio" name="budget" />
                  <span class="checkmark"></span>10-99 employees
                </label>
              </li>
              <li>
                <label class="custom_radio me-4">
                  <input type="radio" name="budget" />
                  <span class="checkmark"></span>100-1000 employees
                </label>
              </li>
              <li>
                <label class="custom_radio me-4">
                  <input type="radio" name="budget" />
                  <span class="checkmark"></span>More than 1000 employees
                </label>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-12">
          <div class="input-block min-characters">
            <label class="form-label">Describe Yourself</label>
            <textarea class="form-control" rows="5"></textarea>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      SelectIndustry: ["Select", "Bachelor's Degree", "Master's Degree"],
    };
  },
};
</script>
