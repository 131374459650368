<template>
  <!-- Home Banner -->
  <section class="section home-banner home-four row-middle">
    <div class="banner-float-img">
      <img src="@/assets/img/happy-young-man.png" alt="banner-image" />
    </div>
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-8 col-lg-6">
          <div class="banner-blk-four aos" data-aos="fade-up">
            <div class="banner-content">
              <div class="banner-note">
                <h5 class="mb-0">With the world's #1 Developers Marketplace</h5>
              </div>
              <h1>Kofejob is a Community <br />for Freelancers</h1>
              <p>
                Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                amet sint. Velit officia consequat.
              </p>
              <form
                class="form"
                name="store"
                id="store"
                method="post"
                action="project"
              >
                <div class="form-inner">
                  <div class="input-group">
                    <span class="drop-detail">
                       <vue-select
                        :options="HomeFour"
                        id="homefour"
                        placeholder="Projects"
                      />
                    </span>
                    <input
                      type="email"
                      class="form-control"
                      placeholder="Keywords"
                    />
                    <button class="btn btn-primary sub-btn" type="submit">
                      Search
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="coplete-job aos" data-aos="fade-right">
        <div class="complete-icon me-2">
          <i class="feather-briefcase"></i>
        </div>
        <div class="complete-content course-count">
          <h3>+ <span class="counter-up">21</span> K</h3>
          <p>Jobs Completed</p>
        </div>
      </div>
      <div class="fullstack-blk aos" data-aos="fade-up">
        <div class="fullstacker-img">
          <img
            src="@/assets/img/user/avatar-12.jpg"
            class="img-fluid"
            alt="Img"
          />
          <span class="stacker-active"
            ><i class="fas fa-check-circle"></i
          ></span>
        </div>
        <div class="fullstacker-name">
          <h4>Chambers</h4>
          <p>Full stack developer</p>
        </div>
        <div class="rating">
          <i class="fas fa-star filled"></i>
          <i class="fas fa-star filled"></i>
          <i class="fas fa-star filled"></i>
          <i class="fas fa-star filled"></i>
          <i class="fas fa-star"></i>
          <span class="average-rating">5.0 (50 Review)</span>
        </div>
      </div>
      <div class="register-profesion aos" data-aos="fade-right">
        <div class="avatar-group">
          <div class="avatar avatar-xs group_img group_header">
            <img
              class="avatar-img rounded-circle"
              alt="User Image"
              src="@/assets/img/user/avatar-1.jpg"
            />
          </div>
          <div class="avatar avatar-xs group_img group_header">
            <img
              class="avatar-img rounded-circle"
              alt="User Image"
              src="@/assets/img/user/avatar-2.jpg"
            />
          </div>
          <div class="avatar avatar-xs group_img group_header">
            <img
              class="avatar-img rounded-circle"
              alt="User Image"
              src="@/assets/img/user/avatar-3.jpg"
            />
          </div>
        </div>
        <div class="profesion-content course-count">
          <p>Join Over 4000+ Students</p>
          <span>Register to the Online courses</span>
        </div>
      </div>
    </div>
  </section>

  <!-- /Home Banner -->
</template>
<script>
export default {
  data() {
    return {
      HomeFour: ["Projects", "Freelancers"],
    };
  },
};
</script>
