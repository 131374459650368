<template>
    <!-- Marketplace -->
    <section class="section best-marketplace">
        <div class="container">
            <div class="row">
                <div class="col-md-6 d-flex align-items-center">
                    <div class="market-place-group section-header-three aos" data-aos="fade-up">
                        <span class="badge title-badge">About Us</span>
                        <h2>Join World’s Best Marketplace for developers</h2>
                        <p>Why hire people when you can simply integrate our talented cloud workforce instead?</p>
                        <ul class="market-list-out">
                            <li><i class="fa-solid fa-circle-check me-2"></i> It’s free and easy to post a job.</li>
                            <li><i class="fa-solid fa-circle-check me-2"></i> We've got freelancers for jobs of any size or
                                budget, across 1800+ skills.</li>
                            <li><i class="fa-solid fa-circle-check me-2"></i> Only pay for work when it has been completed
                                and you're 100% satisfied. </li>
                            <li><i class="fa-solid fa-circle-check me-2"></i> Our talented team of recruiters can help you
                                find the best freelancer.</li>
                        </ul>
                        <div class="market-place-btn">
                            <router-link to="/freelancer/project" class="btn btn-primary market-project me-2">Post a Project<i
                                    class="feather-arrow-right ms-2"></i></router-link>
                            <router-link to="/employers/developer-details" class="btn btn-primary market-developer">Find Developers<i
                                    class="feather-arrow-right ms-2"></i></router-link>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="markrt-place-img aos" data-aos="fade-up">
                        <img src="@/assets/img/market-place.png" class="img-fluid" alt="Img">
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- /Marketplace -->

    <!-- Company Hire -->
    <section class="section company-hire">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="section-header hire-heading section-header-three text-center aos" data-aos="fade-up">
                        <span class="badge title-badge">Trusted Companies</span>
                        <h2>Featured Companies in Kofejob</h2>
                    </div>
                </div>
            </div>
            <div id="company-slider" class="owl-carousel owl-theme testimonial-slider aos" data-aos="fade-up">
                <Carousel :wrap-around="true" :settings="settings" :breakpoints="breakpoints">
                    <Slide v-for="item in CompanyHire" :key="item.id">
                        <div class="company-logos">
                            <img :src="require(`@/assets/img/${item.Image}`)" class="img-fluid" alt="Img">
                        </div>
                    </Slide>
                    <template #addons>
                        <Navigation />
                    </template>
                </Carousel>
            </div>
        </div>
    </section>
    <!-- / Company Hire -->
</template>

<script>
import { Carousel, Navigation, Slide } from "vue3-carousel";
import CompanyHire from "@/assets/json/company-hire.json";
import "vue3-carousel/dist/carousel.css";
export default {
    data() {
        return {
            CompanyHire: CompanyHire,
            settings: {
                itemsToShow: 1,
                snapAlign: "center",
            },
            breakpoints: {
                575: {
                    itemsToShow: 2,
                    snapAlign: "center",
                },
                767: {
                    itemsToShow: 3,
                    snapAlign: "center",
                },
                991: {
                    itemsToShow: 3,
                    snapAlign: "center",
                },
                1024: {
                    itemsToShow: 6,
                    snapAlign: "start",
                },
            },
        }
    },
    components: {
        Carousel,
        Slide,
        Navigation,
    },
}
</script>