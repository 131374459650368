<template>
    <div class="sidebar-body">
        <div class="mt-0 right_sidebar_logo">
            <div class="text-center right-sidebar-profile">
                <figure class="avatar avatar-xl mb-3">
                    <img src="@/assets/img/avatar/avatar-2.jpg" class="rounded-circle" alt="image">
                </figure>
                <h5 class="profile-name">Mark Villiams</h5>
                <div class="last-seen-profile">
                    <span>last seen at 07:15 PM</span>
                </div>
                <div class="chat-options chat-option-profile">
                    <ul class="list-inline">
                        <li class="list-inline-item" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Voice Call">
                            <a href="javascript:void(0);" class="btn btn-outline-light " data-bs-toggle="modal"
                                data-bs-target="#voice_call">
                                <i class="bx bx-phone"></i>
                            </a>
                        </li>
                        <li class="list-inline-item " data-bs-toggle="tooltip" data-bs-placement="bottom"
                            title="Video Call">
                            <a href="javascript:void(0);" class="btn btn-outline-light profile-open" data-bs-toggle="modal"
                                data-bs-target="#voice_call">
                                <i class="bx bx-video"></i>
                            </a>
                        </li>
                        <li class="list-inline-item">
                            <router-link to="/employers/chats" class="btn btn-outline-light" data-bs-toggle="tooltip"
                                data-bs-placement="bottom" title="Chat">
                                <i class="bx bx-message-square-dots"></i>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="chat-member-details">
                <div class="member-details">
                    <ul>
                        <li>
                            <h5>Info</h5>
                        </li>
                        <li>
                            <h6>Bio</h6>
                            <span>Hello, I am using DreamsChat</span>
                        </li>
                        <li>
                            <h6>Phone</h6>
                            <span>555-555-21541</span>
                        </li>
                        <li>
                            <h6>Email Address</h6>
                            <span>info@example.com</span>
                        </li>
                        <li>
                            <h6>Social Media</h6>
                            <div class="social-icons ">
                                <a href="https://www.facebook.com/" target="_blank"><i class="bx bxl-facebook"></i></a>
                                <a href="https://twitter.com/" target="_blank"><i class="bx bxl-twitter"></i></a>
                                <a href="javascript:void(0);" target="_blank"><i class="bx bxl-youtube"></i></a>
                                <a href="https://in.linkedin.com/" target="_blank"><i class="bx bxl-linkedin"></i></a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

        </div>
    </div>
    <div class="right-sidebar-head share-media">
        <div class="share-media-blk">
            <h5>Shared Media</h5>
            <a href="javascript:void(0);">View All</a>
        </div>
        <div class="about-media-tabs">
            <nav>
                <div class="nav nav-tabs " id="nav-tab">
                    <a class="nav-item nav-link active" id="nav-home-tab" data-bs-toggle="tab" href="#info">Photos</a>
                    <a class="nav-item nav-link" id="nav-profile-tab1" data-bs-toggle="tab" href="#Participants">Videos</a>
                    <a class="nav-item nav-link" id="nav-profile-tab2" data-bs-toggle="tab" href="#media">File</a>
                    <a class="nav-item nav-link" id="nav-profile-tab3" data-bs-toggle="tab" href="#link">Link</a>
                </div>
            </nav>
            <div class="tab-content pt-0" id="nav-tabContent">
                <div class="tab-pane fade show active" id="info">
                    <chat-right-image></chat-right-image>
                </div>
                <div class="tab-pane fade" id="Participants">
                    <chat-right-video></chat-right-video>
                </div>
                <div class="tab-pane fade" id="media">
                    <div class="media-file">
                        <div class="media-doc-blk">
                            <span><i class="bx bxs-file-doc"></i></span>
                            <div class="document-detail">
                                <h6>Landing_page_V1.doc</h6>
                                <ul>
                                    <li>12 Mar 2023</li>
                                    <li>246.3 KB</li>
                                </ul>
                            </div>
                        </div>
                        <div class="media-download">
                            <a href="javascript:void(0);"><i class="bx bx-download"></i></a>
                        </div>
                    </div>
                    <div class="media-file">
                        <div class="media-doc-blk">
                            <span><i class="bx bxs-file-pdf"></i></span>
                            <div class="document-detail">
                                <h6>Design Guideless.pdf</h6>
                                <ul>
                                    <li>12 Mar 2023</li>
                                    <li>246.3 KB</li>
                                </ul>
                            </div>
                        </div>
                        <div class="media-download">
                            <a href="javascript:void(0);"><i class="bx bx-download"></i></a>
                        </div>
                    </div>
                    <div class="media-file">
                        <div class="media-doc-blk">
                            <span><i class="bx bxs-file"></i></span>
                            <div class="document-detail">
                                <h6>sample site.txt</h6>
                                <ul>
                                    <li>12 Mar 2023</li>
                                    <li>246.3 KB</li>
                                </ul>
                            </div>
                        </div>
                        <div class="media-download">
                            <a href="javascript:void(0);"><i class="bx bx-download"></i></a>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="link">
                    <div class="media-link-grp">
                        <div class="link-img">
                            <a href="javascript:void(0);"><img src="@/assets/img/media-link-01.jpg" alt="Img"></a>
                        </div>
                        <div class="media-link-detail">
                            <h6><a href="javascript:void(0);">Digital Marketing Guide</a></h6>
                            <span><a href="javascript:void(0);">https://elements.envato.com/all-items/blog</a></span>
                        </div>
                    </div>
                    <div class="media-link-grp mb-0">
                        <div class="link-img">
                            <a href="javascript:void(0);"><img src="@/assets/img/media-link-02.jpg" alt="Img"></a>
                        </div>
                        <div class="media-link-detail">
                            <h6><a href="javascript:void(0);">Blog Post</a></h6>
                            <span><a href="javascript:void(0);">https://elements.envato.com/blog-post-TXQ5FB8</a></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="group-comman-theme">
        <h6>2 Group in Common</h6>
        <ul>
            <li>
                <div class="avatar">
                    <img src="@/assets/img/avatar/avatar-14.png" class="rounded-circle" alt="image">
                </div>
                <div class="theme-content">
                    <h6>Themeforest Group</h6>
                    <p>Mark Villiams, Elizabeth, Michael....</p>
                </div>
            </li>
            <li>
                <div class="avatar">
                    <img src="@/assets/img/avatar/avatar-15.png" class="rounded-circle" alt="image">
                </div>
                <div class="theme-content">
                    <h6>Graphic Designers</h6>
                    <p>Mark Villiams, Elizabeth, Michael....</p>
                </div>
            </li>
        </ul>
    </div>
</template>