<template>
  <!-- Footer -->
  <footer class="footer footer-four">
    <div class="footer-top">
      <div class="container">
        <div class="row row-gap">
          <div class="col-lg-6">
            <div class="row">
              <div class="col-lg-12">
                <div class="subscribe-four">
                  <div class="banner-content">
                    <form
                      class="form"
                      name="store"
                      method="post"
                      action="project"
                    >
                      <div class="form-inner">
                        <div class="input-group">
                          <input
                            type="email"
                            class="form-control"
                            placeholder="Your Email address....."
                          />
                          <button class="btn btn-primary sub-btn" type="submit">
                            Send mail
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-4">
                <div class="footer-widget footer-menu">
                  <h2 class="footer-title">Useful Links</h2>
                  <ul>
                    <li><router-link to="/pages/about">About Us</router-link></li>
                    <li><router-link to="/blog/blog-list">Blog</router-link></li>
                    <li><router-link to="/">Login</router-link></li>
                    <li><router-link to="/register">Register</router-link></li>
                  </ul>
                </div>
              </div>
              <div class="col-lg-4 col-md-4">
                <div class="footer-widget footer-menu">
                  <h2 class="footer-title">Help & Support</h2>
                  <ul>
                    <li><a href="javascript:void(0);">Browse Candidates</a></li>
                    <li>
                      <a href="javascript:void(0);">Employers Dashboard</a>
                    </li>
                    <li><a href="javascript:void(0);">Job Packages</a></li>
                    <li><a href="javascript:void(0);">Jobs Featured</a></li>
                    <li><a href="javascript:void(0);">Post A Job</a></li>
                  </ul>
                </div>
              </div>
              <div class="col-lg-4 col-md-4">
                <div class="footer-widget footer-menu">
                  <h2 class="footer-title">Catgeories</h2>
                  <ul>
                    <li>
                      <router-link to="/freelancer/project">Website Design</router-link>
                    </li>
                    <li>
                      <router-link to="/freelancer/project">Mobile Apps</router-link>
                    </li>
                    <li>
                      <router-link to="/freelancer/project">Android Apps</router-link>
                    </li>
                    <li>
                      <router-link to="/freelancer/project">iPhone Apps</router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="row">
              <div class="col-lg-12">
                <div class="footer-widget footer-menu play-app">
                  <div class="row">
                    <div class="col-lg-4 col-md-4">
                      <div class="free-customer">
                        <p>Toll Free Customer Care</p>
                        <h6>+91 26447 99875</h6>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4">
                      <div class="free-customer">
                        <p>Customization Email</p>
                        <h6>custom@example.com</h6>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4">
                      <div class="free-customer">
                        <p>Need live support?</p>
                        <h6>info@example.com</h6>
                      </div>
                    </div>
                  </div>
                  <div class="applicate-mobile-blk">
                    <h6>Mobile Application</h6>
                    <p>
                      Download our App and get the latest Breaking News Alerts
                      and latest headlines and daily articles near you.
                    </p>
                  </div>
                  <div class="row g-2">
                    <div class="col-lg-4">
                      <a href="javascript:void(0);"
                        ><img
                          class="img-fluid"
                          src="@/assets/img/app-store.svg"
                          alt="app-store"
                      /></a>
                    </div>
                    <div class="col-lg-4">
                      <a href="javascript:void(0);"
                        ><img
                          class="img-fluid"
                          src="@/assets/img/google-play.svg"
                          alt="google-play"
                      /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Footer Top -->

    <!-- Footer Bottom -->
    <div class="footer-bottom">
      <div class="container">
        <!-- Copyright -->
        <div class="copyright">
          <div class="row">
            <div class="col-md-12">
              <div class="copyright-text text-center">
                <p class="mb-0">
                  Copyright {{ new Date().getFullYear() }} © KofeJob. All right reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- /Copyright -->
      </div>
    </div>
    <!-- /Footer Bottom -->
  </footer>
  <!-- /Footer -->
</template>
