<template>
 <ul class="main-nav">
    <template v-for="item in HeaderMenu" :key="item.tittle">
      <li
        v-if="item.separateRoute === true"
        :class="{ active: route_array[1] === item.active_link }"
      >
        <router-link :to="item.route" :target="item.target">{{
          item.tittle
        }}</router-link>
      </li>
      <li
        class="has-submenu"
        v-if="item.separateRoute === false"
        :class="{ active: route_array[1] === item.active_link }"
      >
        <a href="javascript:void(0);" @click="item.showAsTab = !item.showAsTab">
          {{ item.tittle }} <i class="fas fa-chevron-down"></i>
        </a>
        <ul class="submenu" :class="{ 'd-block': item.showAsTab === true }">
          <template v-for="menu in item.menu" :key="menu.menuValue">
            <li
              v-if="menu.hasSubRoute === false"
              :class="{ active: currentPath === menu.active_link }"
            >
              <router-link :to="menu.route" :target="menu.target">{{
                menu.menuValue
              }}</router-link>
            </li>
            <li
              class="has-submenu"
              v-if="menu.hasSubRoute === true"
              :class="{ active: route_array[2] === item.active_link }"
            >
              <a @click="menu.showSubRoute = !menu.showSubRoute" :to="menu.route">
                {{ menu.menuValue }}
              </a>
              <ul class="submenu" :class="{ 'd-block': menu.showSubRoute === true }">
                <template v-for="subMenu in menu.subMenus" :key="subMenu.menuValue">
                  <li routerLinkActive="active">
                    <router-link :to="subMenu.route" :target="subMenu.target">{{
                      subMenu.menuValue
                    }}</router-link>
                  </li>
                </template>
              </ul>
            </li>
          </template>
        </ul>
      </li>
    </template>
  </ul>
</template>

<script>
import HeaderMenu from "@/assets/json/header-menu.json";
export default {
  data() {
    return {
      HeaderMenu: HeaderMenu,
      route_array: [],
    };
  },
  computed: {
    currentPath() {
      this.route_array = this.$route.path.split("/");
      return this.$route.path;
    },
  },
};
</script>
