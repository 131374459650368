<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>

  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content container-fluid">
      <!-- Page Header -->
      <breadcrumbthree :title="title" :text="text" :text1="text1" />
      <!-- /Page Header -->

      <div class="row">
        <div class="col-lg-12">
          <div class="card bg-white">
            <div class="card-body pt-0">
              <div class="card-header mb-4">
                <ul role="tablist" class="nav nav-pills card-header-pills nav-justified">
                  <li class="nav-item">
                    <a href="#employer" data-bs-toggle="tab" class="nav-link active me-1"
                      >Employers</a
                    >
                  </li>
                  <li class="nav-item">
                    <a href="#freelancer" data-bs-toggle="tab" class="nav-link"
                      >Freelancers</a
                    >
                  </li>
                </ul>
              </div>
              <div class="tab-content pt-0">
                <div role="tabpanel" id="employer" class="tab-pane fade show active">
                  <verify-employer />
                </div>
                <div role="tabpanel" id="freelancer" class="tab-pane fade">
                  <verify-freelance />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Page Wrapper -->
</template>

<script>
export default {
  data() {
    return {
      title: "Verify Identity Details",
      text: "Home",
      text1: "Verify Identity Details",
    };
  },
};
</script>
