<template>
  <div class="company-detail-block company-description">
    <h4 class="company-detail-title">Experience</h4>
    <div class="experience-set" v-for="record in Experience" :key="record.id">
      <div class="experience-set-img">
        <img src="@/assets/img/icon/expereience.png" alt="img" />
      </div>
      <div class="experience-set-content">
        <h5>
          {{ record.Posting }}<span>{{ record.Year }}</span>
        </h5>
        <span>UI UX Designer</span>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
          incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
          nostrud exercitation ullamco laboris
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import Experience from "@/assets/json/experience.json";
export default {
  data() {
    return {
      Experience: Experience,
    };
  },
};
</script>
