<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>

  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content report-box container-fluid">
      <!-- Page Header -->
      <div class="page-header page-border">
        <div class="row align-items-center">
          <breadcrumbthree :title="title" :text="text" :text1="text1" />

          <reportstabset></reportstabset>
        </div>
      </div>
      <!-- /Page Header -->

      <!-- Project Chart -->
      <div class="project-chart">
        <div class="row">
          <div class="col-xl-12 d-flex">
            <div class="card flex-fill">
              <div class="card-header">
                <div class="d-flex justify-content-between align-items-center">
                  <h5 class="card-title">Invoices Report</h5>

                  <div class="dropdown">
                    <button
                      class="btn btn-white btn-sm dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      2022
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <li>
                        <a href="javascript:void(0);" class="dropdown-item">2021</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" class="dropdown-item">2022</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" class="dropdown-item">2020</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div id="chart-bar-stacked"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Project Chart -->

      <projectdownload />

      <!-- Employer Project -->
      <div class="card">
        <div class="card-body">
          <div class="showentries">
            <label
              >Show
              <select>
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
              entries
            </label>
            <div class="entries">
              <span>Search: </span>
              <input
                type="search"
                class="form-control form-control-sm ms-2"
                placeholder=""
                aria-controls="DataTables_Table_0"
              />
            </div>
          </div>
          <div class="table-responsive">
            <a-table :columns="columns" :data-source="data">
              <template #bodyCell="{ column, record }">
                <template v-if="column.key === 'InvoiceNo'">
                  <a href="javascript:;">#55454</a>
                </template>
                <template v-else-if="column.key === 'Name'">
                  <div>
                    <a href="javascript:;" class="active-name">{{ record.Name }}</a>
                  </div>
                </template>
                <template v-else-if="column.key === 'inputbox'">
                  <div class="form-check custom-checkbox">
                    <input type="checkbox" class="form-check-input" id="customCheck5" />
                    <label class="form-check-label" for="customCheck5"></label>
                  </div>
                </template>
                <template v-else-if="column.key === 'ProjectName'">
                  <a href="javascript:;">{{ record.ProjectName }}</a>
                </template>
                <template v-else-if="column.key === 'Status'">
                  <button :class="record.Class">{{ record.Status }}</button>
                </template>
                <template v-else-if="column.key === 'id'">
                  <div class="text-end subscription-end">
                    <a
                      href="javascript:void(0);"
                      class="btn btn-sm btn-secondary me-2"
                      data-bs-toggle="modal"
                      data-bs-target="#edit-category"
                      ><i class="far fa-edit"></i
                    ></a>
                    <a
                      href="javascript:void(0);"
                      class="btn btn-sm btn-danger"
                      data-bs-toggle="modal"
                      data-bs-target="#delete_category"
                      ><i class="far fa-trash-alt"></i
                    ></a>
                  </div>
                </template>
              </template>
            </a-table>
          </div>
        </div>
      </div>
      <!-- /Employer Project -->
    </div>
    <!-- /Page Wrapper -->
  </div>

  <modelpopup />
  <delete />
</template>

<script>
import c3 from "c3";
// import "@/assets/plugins/c3-chart/c3.min.css";

const columns = [
  {
    title: "#",
    key: "inputbox",
    sorter: true,
  },
  {
    title: "Invoice No",
    dataIndex: "InvoiceNo",
    key: "InvoiceNo",
    sorter: {
      compare: (a, b) => {
        a = a.InvoiceNo.toLowerCase();
        b = b.InvoiceNo.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Name",
    dataIndex: "Name",
    key: "Name",
    sorter: {
      compare: (a, b) => {
        a = a.Name.toLowerCase();
        b = b.Name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Project Name",
    dataIndex: "ProjectName",
    key: "ProjectName",
    sorter: {
      compare: (a, b) => {
        a = a.ProjectName.toLowerCase();
        b = b.ProjectName.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "InvoiceDate",
    dataIndex: "InvoiceDate",
    sorter: {
      compare: (a, b) => {
        a = a.InvoiceDate.toLowerCase();
        b = b.InvoiceDate.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Price",
    dataIndex: "Price",
    sorter: {
      compare: (a, b) => {
        a = a.Price.toLowerCase();
        b = b.Price.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Paid date",
    dataIndex: "Paiddate",
    sorter: {
      compare: (a, b) => {
        a = a.Paiddate.toLowerCase();
        b = b.Paiddate.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "Status",
    sorter: {
      compare: (a, b) => {
        a = a.Status.toLowerCase();
        b = b.Status.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Actions",
    key: "id",
    class: "text-end",
    sorter: true,
  },
];

const data = [
  {
    FIELD1: "",
    InvoiceNo: "#55454",
    Name: "Hendry",
    ProjectName: "UI/UX Design",
    InvoiceDate: "25/03/2022",
    Price: "$450.44",
    Paiddate: "25/03/2022",
    Class: "btn active-btn",
    Status: "Paid",
    Actions: "",
  },
  {
    FIELD1: "",
    InvoiceNo: "#55454",
    Name: "Mark",
    ProjectName: "Doccure",
    InvoiceDate: "28-02-2022",
    Price: "$450.44",
    Paiddate: "25/03/2022",
    Class: "btn active-btn",
    Status: "Paid",
    Actions: "",
  },
  {
    FIELD1: "",
    InvoiceNo: "#55454",
    Name: "Steawart",
    ProjectName: "SmartHR",
    InvoiceDate: "28-02-2022",
    Price: "$450.44",
    Paiddate: "25/03/2022",
    Class: "btn inactive-btn",
    Status: "Unpaid",
    Actions: "",
  },
];

export default {
  data() {
    return {
      title: "Reports",
      text: "Home",
      text1: "Reports",
      columns,
      data,
    };
  },
  mounted() {
    this.generateChart();
  },
  methods: {
    generateChart() {
      c3.generate({
        bindto: "#chart-bar-stacked",
        data: {
          columns: [
            ["data1", 17, 58, 45, 30, 19, 17, 0, 25, 32, 20, 14, 20],
            ["data2", 4, 10, 18, 7, 10, 12, 54, 6, 2, 5, 2, 8],
          ],
          type: "bar",
          groups: [["data1", "data2"]],
          colors: {
            data1: "#7E84A3",
            data2: "#0B6BCB",
          },
          names: {
            data1: "Maximum",
            data2: "Minimum",
          },
        },
        axis: {
          x: {
            type: "category",
            categories: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ],
          },
        },
        bar: {
          width: 16,
        },
        legend: {
          show: false,
        },
        padding: {
          bottom: 0,
          top: 0,
        },
      });
    },
  },
};
</script>
