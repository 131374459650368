<template>
  <div class="dashboard-page">
    <div class="content content-page">
      <div class="container">
        <div class="row">
          <div class="col-xl-3 col-lg-4 theiaStickySidebar">
            <freelancer-sidebar></freelancer-sidebar>
          </div>
          <div class="col-xl-9 col-lg-8">
            <div class="page-title">
              <h3>Cancelled Projects</h3>
            </div>
            <!-- Proposals list -->
            <div class="proposals-section cancel-projects">
              <!-- Proposals -->
              <div
                class="freelancer-proposals proposal-ongoing"
                v-for="record in Freelancer_Ongoing_Proposals"
                :key="record.id"
              >
                <div class="project-proposals align-items-center freelancer">
                  <div class="proposal-info">
                    <div class="proposals-details">
                      <h3 class="proposals-title">
                        {{ record.Title }}
                      </h3>
                      <ul>
                        <li>
                          <div class="proposal-client-price">
                            <h4 class="title-info">Client Price</h4>
                            <h3 class="client-price">
                              {{ record.Amount }}
                              <span class="price-type">( Fixed )</span>
                            </h3>
                          </div>
                        </li>
                        <li>
                          <div class="proposal-job-type">
                            <h4 class="title-info">Project Deadline</h4>
                            <h3>{{ record.Date }}</h3>
                          </div>
                        </li>
                        <li>
                          <div class="proposal-img">
                            <div class="proposal-client d-flex align-items-center">
                              <img
                                :src="require(`@/assets/img/user/${record.Image}`)"
                                alt="Img"
                                class="img-fluid me-2"
                              />
                              <div>
                                <h4>{{ record.Name }}</h4>
                                <span
                                  >{{ record.Date1
                                  }}<i class="fa-solid fa-star"></i>5.0</span
                                >
                              </div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="project-action text-center">
                            <router-link
                              to="freelancer-view-project-detail"
                              class="projects-btn"
                              >Remove Project</router-link
                            >
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Proposals -->
            </div>
            <!-- /Proposals list -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Freelancer_Ongoing_Proposals from "@/assets/json/freelancer-ongoing-proposals.json";
export default {
  data() {
    return {
      Freelancer_Ongoing_Proposals: Freelancer_Ongoing_Proposals,
    };
  },
};
</script>
