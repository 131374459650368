<template>
  <!-- Blog -->
  <section class="section blog-article">
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-sm-12 col-12 mx-auto">
          <div
            class="section-header section-header-four section-locate aos"
            data-aos="fade-up"
          >
            <div class="text-center">
              <h2 class="header-title">Our Blog</h2>
              <p>Read Our Article To Get Tricks</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row aos row-gap" data-aos="fade-up">
        <div class="col-lg-4 col-md-6">
          <div class="blog-article-group">
            <div class="blog-image">
              <router-link to="/blog/blog-details"
                ><img
                  class="img-fluid"
                  src="@/assets/img/blog/blog-15.jpg"
                  alt="Post Image"
              /></router-link>
              <div class="article-Production">
                <span>Production</span>
              </div>
              <div class="article-blog-content">
                <span> <i class="feather-calendar"></i> 13, Dec2022</span>
                <h4>
                  <router-link to="/blog/blog-details"
                    >Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Amet.</router-link
                  >
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="blog-article-group">
            <div class="blog-image">
              <router-link to="/blog/blog-details"
                ><img
                  class="img-fluid"
                  src="@/assets/img/blog/blog1.jpg"
                  alt="Post Image"
              /></router-link>
              <div class="article-Production">
                <span>Jobs</span>
              </div>
              <div class="article-blog-content">
                <span><i class="feather-calendar"></i>12, Dec2022</span>
                <h4>
                  <router-link to="/blog/blog-details"
                    >Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Amet.</router-link
                  >
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="blog-article-group">
            <div class="blog-image">
              <router-link to="/blog/blog-details"
                ><img
                  class="img-fluid"
                  src="@/assets/img/blog/blog2.jpg"
                  alt="Post Image"
              /></router-link>
              <div class="article-Production">
                <span>Production</span>
              </div>
              <div class="article-blog-content">
                <span><i class="feather-calendar"></i>10, Dec2022</span>
                <h4>
                  <router-link to="/blog/blog-details"
                    >Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Amet.</router-link
                  >
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Popular Projects -->
</template>
