<template>
  <!-- Popular Projects -->
  <section class="section popular-projects-job">
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-sm-12 col-12 mx-auto text-center">
          <div
            class="section-header aos aos-init aos-animate"
            data-aos="fade-up"
          >
            <h2 class="header-title">Popular Projects near you</h2>
            <p>Bid and stary the new Jobs</p>
          </div>
        </div>
      </div>
      <div class="row">
        <!--- Project Item  -->
        <div class="col-lg-4 col-md-6 col-sm-6 d-flex">
          <div class="project-item project-popular-item aos" data-aos="fade-up">
            <div class="project-img">
              <router-link to="/freelancer/project"
                ><img
                  src="@/assets/img/project/project-35.jpg"
                  alt="Img"
                  class="img-fluid"
              /></router-link>
            </div>
            <div class="feature-content">
              <h4>
                <router-link to="/freelancer/project"
                  >3D Renders and Amazon Product</router-link
                >
              </h4>
              <ul class="feature-project-list nav">
                <li><i class="feather-user me-1"></i>UI/UX Designer</li>
                <li>$200</li>
              </ul>
            </div>
          </div>
        </div>
        <!--- /Project Item  -->

        <!--- Project Item  -->
        <div class="col-lg-4 col-md-6 col-sm-6 d-flex">
          <div class="project-item project-popular-item aos" data-aos="fade-up">
            <div class="project-img">
              <router-link to="/freelancer/project"
                ><img
                  src="@/assets/img/project/project-36.jpg"
                  alt="Img"
                  class="img-fluid"
              /></router-link>
            </div>
            <div class="feature-content">
              <h4>
                <router-link to="/freelancer/project"
                  >Website Design for a Consumer Shop</router-link
                >
              </h4>
              <ul class="feature-project-list nav">
                <li><i class="feather-user me-1"></i>Web Development</li>
                <li>$350</li>
              </ul>
            </div>
          </div>
        </div>
        <!--- /Project Item  -->

        <!--- Project Item  -->
        <div class="col-lg-4 col-md-6 col-sm-6 d-flex">
          <div class="project-item project-popular-item aos" data-aos="fade-up">
            <div class="project-img">
              <router-link to="/freelancer/project"
                ><img
                  src="@/assets/img/project/project-37.png"
                  alt="Img"
                  class="img-fluid"
              /></router-link>
            </div>
            <div class="feature-content">
              <h4>
                <router-link to="/freelancer/project"
                  >3D Renders and Amazon Product
                </router-link>
              </h4>
              <ul class="feature-project-list nav">
                <li><i class="feather-user me-1"></i>Php Development</li>
                <li>$99</li>
              </ul>
            </div>
          </div>
        </div>
        <!--- /Project Item  -->
        <div class="col-md-12">
          <div class="home-five-section-btn">
            <a href="project" class="btn btn-primary">View More Projects</a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Popular Projects -->
</template>
