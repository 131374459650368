<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper login-body">
    <div class="login-wrapper">
      <div class="container">
        <img
          class="img-fluid logo-dark mb-2"
          src="@/assets/admin_img/logo-01.png"
          alt="Logo"
        />
        <div class="loginbox">
          <div class="login-right">
            <div class="login-right-wrap">
              <h1>Welcome Back</h1>
              <p class="account-subtitle">
                Don't miss your next opportunity. Sign in to stay updated on your
                professional world.
              </p>

              <form action="/admin/index">
                <div class="form-group form-focus" :class="{ focused: isEmailFocused }">
                  <input
                    type="text"
                    class="form-control floating"
                    @focus="isEmailFocused = true"
                    @blur="handleEmailBlur"
                    v-model="emailInput"
                  />
                  <label
                    class="focus-label"
                    :class="{ active: isEmailFocused || emailInput !== '' }"
                    >Email</label
                  >
                </div>
                <div
                  class="form-group form-focus"
                  :class="{ focused: isPasswordFocused }"
                >
                  <input
                    type="password"
                    class="form-control floating"
                    @focus="isPasswordFocused = true"
                    @blur="handlePasswordBlur"
                    v-model="passwordInput"
                  />
                  <label
                    class="focus-label"
                    :class="{ active: isPasswordFocused || passwordInput !== '' }"
                    >Password</label
                  >
                </div>
                <div class="form-group">
                  <label class="custom_check">
                    <input type="checkbox" name="rem_password" />
                    <span class="checkmark"></span> Remember password
                  </label>
                </div>
                <button class="btn btn-primary btn-block btn-lg login-btn" type="submit"
                  >Login</button
                >
                <div class="login-or">
                  <p>Or login with</p>
                </div>
                <!-- Social Login -->
                <div class="row form-row social-login">
                  <div class="col-lg-4">
                    <a href="javascript:;" class="btn btn-twitter btn-block mb-1">
                      Twitter</a
                    >
                  </div>
                  <div class="col-lg-4">
                    <a href="javascript:;" class="btn btn-facebook btn-block mb-1">
                      Facebook</a
                    >
                  </div>
                  <div class="col-lg-4">
                    <a href="javascript:;" class="btn btn-google btn-block mb-1">
                      Google</a
                    >
                  </div>
                </div>
                <!-- /Social Login -->
                <div class="row form-row login-foot">
                  <div class="col-lg-6 login-forgot">
                    <router-link class="forgot-link" to="/admin/forgot-password"
                      >Forgot Password ?</router-link
                    >
                  </div>
                  <div class="col-lg-6 dont-have">
                    New to Kofejob?
                    <router-link to="/admin/register">Click here</router-link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Main Wrapper -->
</template>
<script>
export default {
  data() {
    return {
      isEmailFocused: false,
      emailInput: "",
      isPasswordFocused: false,
      passwordInput: "",
    };
  },
  methods: {
    handleEmailBlur() {
      if (this.emailInput.trim() === "") {
        this.isEmailFocused = false;
      }
    },
    handlePasswordBlur() {
      if (this.passwordInput.trim() === "") {
        this.isPasswordFocused = false;
      }
    },
  },
};
</script>
