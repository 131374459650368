<template>
  <!-- Counter -->
  <section class="section feature-count feature-count-three">
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-sm-12 col-12 mx-auto text-center">
          <div
            class="section-header aos aos-init aos-animate"
            data-aos="fade-up"
          >
            <h2 class="header-title">Achievement We Have Earned</h2>
            <p>
              At Freelancer, we believe that talent is borderless and
              opportunity should be too.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Counter -->

  <div class="count-sec">
    <div class="container">
      <div class="row">
        <!-- Feature Item -->
        <div class="col-xl-3 col-md-6">
          <div
            class="feature-item freelance-count aos aos-init aos-animate"
            data-aos="fade-up"
          >
            <div class="feature-icon">
              <img
                src="@/assets/img/icon/count-icon-01.svg"
                class="img-fluid"
                alt="Img"
              />
            </div>
            <div class="feature-content course-count">
              <h3 class="counter-up">9,207</h3>
              <p>Freelance developers</p>
            </div>
          </div>
        </div>
        <!-- /Feature Item -->

        <!-- Feature Item -->
        <div class="col-xl-3 col-md-6">
          <div class="feature-item aos aos-init aos-animate" data-aos="fade-up">
            <div class="feature-icon">
              <img
                src="@/assets/img/icon/count-icon-02.svg"
                class="img-fluid"
                alt="Img"
              />
            </div>
            <div class="feature-content course-count">
              <h3><span class="counter-up">6000</span></h3>
              <p>Projects Added</p>
            </div>
          </div>
        </div>
        <!-- /Feature Item -->

        <!-- Feature Item -->
        <div class="col-xl-3 col-md-6">
          <div
            class="feature-item comp-project aos aos-init aos-animate"
            data-aos="fade-up"
          >
            <div class="feature-icon">
              <img
                src="@/assets/img/icon/count-icon-03.svg"
                class="img-fluid"
                alt="Img"
              />
            </div>
            <div class="feature-content course-count">
              <h3 class="counter-up">919,207</h3>
              <p>Completed projects</p>
            </div>
          </div>
        </div>
        <!-- /Feature Item -->

        <!-- Feature Item -->
        <div class="col-xl-3 col-md-6">
          <div
            class="feature-item comp-project aos aos-init aos-animate"
            data-aos="fade-up"
          >
            <div class="feature-icon">
              <img
                src="@/assets/img/icon/count-icon-04.svg"
                class="img-fluid"
                alt="Img"
              />
            </div>
            <div class="feature-content course-count">
              <h3 class="counter-up">998</h3>
              <p>Companies Registered</p>
            </div>
          </div>
        </div>
        <!-- /Feature Item -->
      </div>
    </div>
  </div>
</template>
