<template>
  <!-- The Modal -->
  <div class="modal fade" id="rating">
    <div class="modal-dialog modal-dialog-centered modal-md">
      <div class="modal-content">
        <div class="modal-header d-block b-0 pb-0">
          <span class="modal-close float-end"
            ><a href="javascript:void(0);" data-bs-dismiss="modal" aria-label="Close"
              ><i class="far fa-times-circle orange-text"></i></a
          ></span>
        </div>
        <div class="modal-body">
          <form action="project">
            <div class="modal-info">
              <div class="text-center pt-0 mb-5">
                <h3>Please login to Favourite Freelancer</h3>
              </div>
              <div class="submit-section text-center">
                <button
                  data-bs-dismiss="modal"
                  class="btn btn-primary black-btn click-btn"
                >
                  Cancel
                </button>
                <button type="submit" class="btn btn-primary click-btn">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /The Modal -->
</template>
