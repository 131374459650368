<template>
  <header class="header header-bg">
    <div class="container">
      <nav class="navbar navbar-expand-lg header-nav">
        <div class="navbar-header">
          <a id="mobile_btn" href="javascript:void(0);">
            <span class="bar-icon">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </a>
          <router-link to="/home/" class="navbar-brand logo">
            <img src="@/assets/img/logo.svg" class="img-fluid" alt="Logo" />
          </router-link>
        </div>
        <div class="main-menu-wrapper">
          <div class="menu-header">
            <router-link to="/home/" class="menu-logo">
              <img src="@/assets/img/logo.svg" class="img-fluid" alt="Logo" />
            </router-link>
            <a id="menu_close" class="menu-close" href="javascript:void(0);">
              <i class="fas fa-times"></i>
            </a>
          </div>
          <header-menu></header-menu>
        </div>
        <ul class="nav header-navbar-rht">
          <li>
            <router-link to="/freelancer/freelancer-chats"
              ><img src="@/assets/img/icon/message-chat-icon.svg" alt="Img"
            /></router-link>
          </li>
          <notification></notification>
          <li>
            <router-link to="/post-project" class="login-btn"
              >Post a Project <i class="feather-plus ms-1"></i
            ></router-link>
          </li>
          <li class="nav-item dropdown account-item">
            <a
              href="javascript:void(0);"
              class="dropdown-toggle nav-link"
              data-bs-toggle="dropdown"
            >
              <span class="user-img">
                <img src="@/assets/img/user/avatar-1.jpg" alt="Img" />
              </span>
              <span>Bruce Bush</span>
            </a>
            <div class="dropdown-menu emp">
              <div class="drop-head">
                <a
                  href="javascript:void(0);"
                  class="dropdown-toggle nav-link"
                  data-bs-toggle="dropdown"
                >
                  <span class="user-img">
                    <img src="@/assets/img/user/avatar-1.jpg" alt="Img" />
                  </span>
                  <div>
                    <span>Bruce Bush</span>
                    <p>info@brucebush.com</p>
                  </div>
                </a>
              </div>
              <router-link class="dropdown-item" to="/employers/company-profile"
                ><img src="@/assets/img/icon/user-dropdown-icon--01.svg" alt="Img" /> My
                Profile</router-link
              >
              <router-link class="dropdown-item" to="/employers/manage-projects"
                ><img src="@/assets/img/icon/user-dropdown-icon--02.svg" alt="Img" /> My
                Projects</router-link
              >
              <router-link class="dropdown-item" to="/employers/favourites"
                ><img src="@/assets/img/icon/user-dropdown-icon--03.svg" alt="Img" />My
                Subscription</router-link
              >
              <router-link class="dropdown-item" to="/employers/deposit-funds"
                ><img src="@/assets/img/icon/user-dropdown-icon--04.svg" alt="Img" />My
                Statement</router-link
              >
              <router-link class="dropdown-item" to="/employers/chats"
                ><img src="@/assets/img/icon/user-dropdown-icon--05.svg" alt="Img" />
                Message</router-link
              >
              <router-link class="dropdown-item" to="/employers/profile-settings"
                ><img src="@/assets/img/icon/user-dropdown-icon--06.svg" alt="Img" />
                Profile Settings</router-link
              >
              <router-link class="dropdown-item" to="/home/"
                ><img src="@/assets/img/icon/user-dropdown-icon--07.svg" alt="Img" />
                Logout</router-link
              >
            </div>
          </li>
        </ul>
      </nav>
    </div>
  </header>
</template>
