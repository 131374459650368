<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>

  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content report-box container-fluid">
      <!-- Page Header -->
      <div class="page-header subscribe-head">
        <div class="row align-items-center">
          <div class="col">
            <breadcrumbadmin :title="title" :text="text" :text1="text1" />
          </div>
          <div class="col-auto">
            <router-link class="btn back-user" to="/admin/users">
              <img src="@/assets/admin_img/icon/icon-08.svg" alt="Image" /> Back to list
            </router-link>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <!-- User Profile -->
      <div class="profile-status">
        <div class="row">
          <div class="col-lg-6 col-md-6">
            <div class="profile-user-box">
              <router-link to="/admin/profile"
                ><img
                  class="avatar-img rounded-circle"
                  src="@/assets/admin_img/profiles/avatar-16.jpg"
                  alt="User Image"
              /></router-link>
              <div class="profile-name-box">
                <h2><router-link to="/admin/profile">William Cooper</router-link></h2>
                <p>Date of Join: 22 May 2023</p>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-2">
            <div class="additional-info">
              <p>Freelancer ID</p>
              <h5>UID00003</h5>
            </div>
          </div>
          <div class="col-lg-2 col-md-2">
            <div class="additional-info">
              <p>Email</p>
              <h5 class="verify-mail">
                <i data-feather="check-circle" class="me-1 text-success"></i> Verified
              </h5>
            </div>
          </div>
          <div class="col-lg-2 col-md-2">
            <div class="additional-info">
              <p>Account Status</p>
              <h5 class="text-danger">Active</h5>
            </div>
          </div>
        </div>
      </div>
      <!-- /User Profile -->

      <div class="row">
        <div class="col-sm-12">
          <div class="card profile-home">
            <userstabset />

            <!-- Active Profile Header -->
            <div class="page-header profile-information">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="page-title">Recent Transaction</h3>
                  <p>All the recent transaction made by user.</p>
                </div>
              </div>
            </div>
            <!-- /Active Profile Header -->

            <div class="card">
              <div class="card-body">
                <div class="table-responsive">
                  <a-table :columns="columns" :data-source="data">
                    <template #bodyCell="{ column, record }">
                      <template v-if="column.key === 'Type'">
                        <div class="table-avatar user-profile">
                          <router-link to="/admin/profile"
                            ><i :class="record.Icon"></i>
                          </router-link>
                          <div>
                            <h5>
                              <router-link to="/admin/profile">{{
                                record.Type
                              }}</router-link>
                            </h5>
                            <p class="receive-date">
                              {{ record.Dates }}
                            </p>
                          </div>
                        </div>
                      </template>
                      <template v-else-if="column.key === 'inputbox'">
                        <div class="form-check custom-checkbox">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            id="customCheck5"
                          />
                          <label class="form-check-label" for="customCheck5"></label>
                        </div>
                      </template>
                      <template v-else-if="column.key === 'Details'">
                        <div>
                          <button :class="record.Class">{{ record.Details }}</button>
                        </div>
                      </template>
                      <template v-else-if="column.key === 'Amount'">
                        <div class="table-avatar content-user">
                          <div>
                            <h5 :class="record.Amt">{{ record.Amount }}</h5>
                            <p>Balance : {{ record.Balance }}</p>
                          </div>
                        </div>
                      </template>
                      <template v-else-if="column.key === 'action'">
                        <div class="text-end complete-show">
                          <a href="javascript:;"
                            ><i
                              class="fas fa-arrow-right"
                              data-bs-toggle="modal"
                              data-bs-target="#transaction-category"
                            ></i
                          ></a>
                        </div>
                      </template>
                    </template>
                  </a-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Page Wrapper -->
  <modelpopup></modelpopup>
</template>

<script>
const columns = [
  {
    title: "#",
    key: "inputbox",
    sorter: true,
  },
  {
    title: "Order",
    dataIndex: "Order",
    sorter: {
      compare: (a, b) => {
        a = a.Order.toLowerCase();
        b = b.Order.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Type",
    dataIndex: "Type",
    key: "Type",
    sorter: {
      compare: (a, b) => {
        a = a.Type.toLowerCase();
        b = b.Type.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Details",
    dataIndex: "Details",
    key: "Details",
    sorter: {
      compare: (a, b) => {
        a = a.Details.toLowerCase();
        b = b.Details.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Reference",
    dataIndex: "Reference",
    key: "Reference",
    sorter: {
      compare: (a, b) => {
        a = a.Reference.toLowerCase();
        b = b.Reference.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Amount",
    dataIndex: "Amount",
    key: "Amount",
    sorter: {
      compare: (a, b) => {
        a = a.Amount.toLowerCase();
        b = b.Amount.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "action",
    sorter: true,
  },
];
const data = [
  {
    FIELD1: "",
    Order: "#24586414",
    Icon: "fas fa-exchange-alt exchange",
    Type: "Received from Investment Account",
    Dates: "Mar 13, 2023Completed",
    Class: "btn received-btn",
    Details: "Recieved",
    Reference: 151410331,
    Amt: "text-success",
    Amount: "+ 4,335.006 USD",
    Balance: "1,335.006 USD",
    FIELD7: "",
  },
  {
    FIELD1: "",
    Order: "#34586414",
    Icon: "fas fa-undo-alt refresh",
    Type: "Received from Investment Account",
    Dates: "Jun 13, 2023Completed",
    Class: "btn withdraw-btn text-danger",
    Details: "Withdrawn",
    Reference: 261410331,
    Amt: "text-danger",
    Amount: "- 5,335.006 USD",
    Balance: "1,335.006 USD",
    FIELD7: "",
  },
  {
    FIELD1: "",
    Order: "#34586414",
    Icon: "fas fa-exchange-alt exchange",
    Type: "Received from Investment Account",
    Dates: "Mar 13, 2023Completed",
    Class: "btn received-btn",
    Details: "Recieved",
    Reference: 341410331,
    Amt: "text-success",
    Amount: "+ 4,335.006 USD",
    Balance: "1,335.006 USD",
    FIELD7: "",
  },
  {
    FIELD1: "",
    Order: "#34586414",
    Icon: "fas fa-undo-alt refresh",
    Type: "Received from Investment Account",
    Dates: "Jun 13, 2023Completed",
    Class: "btn withdraw-btn text-danger",
    Details: "Withdrawn",
    Reference: 261410331,
    Amt: "text-danger",
    Amount: "- 5,335.006 USD",
    Balance: "1,335.006 USD",
    FIELD7: "",
  },
  {
    FIELD1: "",
    Order: "#24586414",
    Icon: "fas fa-exchange-alt exchange",
    Type: "Received from Investment Account",
    Dates: "Mar 13, 2023Completed",
    Class: "btn received-btn",
    Details: "Recieved",
    Reference: 151410331,
    Amt: "text-success",
    Amount: "+ 4,335.006 USD",
    Balance: "1,335.006 USD",
    FIELD7: "",
  },
  {
    FIELD1: "",
    Order: "#34586414",
    Icon: "fas fa-undo-alt refresh",
    Type: "Received from Investment Account",
    Dates: "Jun 13, 2023Completed",
    Class: "btn withdraw-btn text-danger",
    Details: "Withdrawn",
    Reference: 261410331,
    Amt: "text-danger",
    Amount: "- 5,335.006 USD",
    Balance: "1,335.006 USD",
    FIELD7: "",
  },
  {
    FIELD1: "",
    Order: "#34586414",
    Icon: "fas fa-exchange-alt exchange",
    Type: "Received from Investment Account",
    Dates: "Mar 13, 2023Completed",
    Class: "btn received-btn",
    Details: "Recieved",
    Reference: 341410331,
    Amt: "text-success",
    Amount: "+ 4,335.006 USD",
    Balance: "1,335.006 USD",
    FIELD7: "",
  },
];

export default {
  data() {
    return {
      title: "Freelancer Profile",
      text: "Home",
      text1: "Freelancer Profile",
      columns,
      data,
    };
  },
};
</script>
