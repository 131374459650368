<template>
	<!-- Marketplace -->
	<section class="section join-marketplace">
		<div class="container">
			<div class="row">
				<div class="col-lg-6 col-md-12">
					<div class="markrt-place-img aos" data-aos="fade-up">
						<img src="@/assets/img/plat-form1.png" class="img-fluid" alt="Img">
					</div>
				</div>
				<div class="col-lg-6 col-md-12 d-flex align-items-center">
					<div class="market-place-group join-place-blk aos" data-aos="fade-up">
						<h2>Join World’s Best Marketplacevfor developers</h2>
						<p>Why hire people when you can simply integrate our talented cloud workforce instead?</p>
						<ul class="market-list-out">
							<li><i class="fa-solid fa-circle-check"></i> It’s free and easy to post a job.</li>
							<li><i class="fa-solid fa-circle-check"></i> We've got freelancers for jobs of any size or
								budget, across 1800+ skills.</li>
							<li><i class="fa-solid fa-circle-check"></i> Only pay for work when it has been completed and
								you're 100% satisfied. </li>
							<li><i class="fa-solid fa-circle-check"></i> Our talented team of recruiters can help you find
								the best freelancer.</li>
						</ul>
						<div class="market-place-btn">
							<router-link to="/freelancer/project" class="btn btn-primary market-project me-2">Post a
								Project</router-link>
							<router-link to="/employers/developer-details" class="btn btn-primary market-developer">Find
								Developers</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<!-- /Marketplace -->

	<!-- Popular Projects -->
	<section class="section popular-projects">
		<div class="container">
			<div class="row">
				<div class="col-md-12 col-sm-12 col-12 mx-auto">
					<div class="section-header  section-locate aos" data-aos="fade-up">
						<div>
							<h2 class="header-title">Popular Projects near you </h2>
							<p>Bid and stary the new Jobs </p>
						</div>

					</div>
				</div>
			</div>
			<div id="popular-slider" class="owl-carousel owl-theme popular-slider developers-slider aos" data-aos="fade-up">
				<Carousel :wrap-around="true" :settings="settings" :breakpoints="breakpoints">
					<Slide v-for="item in IndexFourPopularProjects" :key="item.id">
						<div class="popular-group">
							<div class="blog-image">
								<router-link to="/freelancer/project"><img class="img-fluid"
										:src="require(`@/assets/img/project/${item.Img}`)" alt="Post Image"></router-link>
							</div>
							<div class="popular-content-blk">
								<div class="head-popular">
									<h4><router-link to="/freelancer/project">{{ item.Title }}</router-link></h4>
									<p><i class="feather-user me-1"></i>{{ item.Description }}</p>
								</div>
								<div class="popular-list-box">
									<ul class="nav">
										<li>{{ item.List }}</li>
										<li>{{ item.List1 }}</li>
									</ul>
									<h4>{{ item.Price }}<span>/hour</span></h4>
								</div>
								<p class="popular-foot">{{ item.Text }}</p>
							</div>
						</div>
					</Slide>
					<template #addons>
						<Navigation />
					</template>
				</Carousel>

			</div>
		</div>
	</section>
	<!-- /Popular Projects -->
</template>
<script>
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import IndexFourPopularProjects from "@/assets/json/indexfourpopularprojects.json";
export default {
	data() {
		return {
			IndexFourPopularProjects: IndexFourPopularProjects,
			settings: {
				itemsToShow: 1,
				snapAlign: "center",
				loop: true,
				margin: 24,
			},

			breakpoints: {
				575: {
					itemsToShow: 1,
					snapAlign: "center",
				},
				// 700px and up
				767: {
					itemsToShow: 1,
					snapAlign: "center",
				},
				// 991px and up
				991: {
					itemsToShow: 1.8,
					snapAlign: "center",
				},
				// 1024 and up
				1024: {
					itemsToShow: 2.9,
					snapAlign: "start",
				},
			},
		};
	},
	components: {
		Carousel,
		Slide,
		Pagination,
		Navigation,
	},
};
</script>