<template>
  <div class="chat-page main-chat-blk dashboard-page">
    
      <employer-header></employer-header>

      <div class="chat-page-wrapper">
        <div class="container">
          <div class="chats-content">
            <!-- sidebar group -->
            
            <chat-sidebar></chat-sidebar>
            <!-- /Sidebar group -->

            <!-- Chat -->
            <div class="chat chat-messages" id="middle">
              <div>
                <div class="chat-header">
                  <div class="user-details">
                    <div class="d-lg-none">
                      <ul class="list-inline mt-2 me-2">
                        <li class="list-inline-item">
                          <a class="text-muted px-0 left_sides" href="javascript:void(0);"
                          @click="handleLeftSidesClick" data-chat="open">
                            <i class="fas fa-arrow-left"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <figure class="avatar ms-1">
                      <img src="@/assets/img/avatar/avatar-2.jpg" class="rounded-circle" alt="image">
                    </figure>
                    <div class="mt-1">
                      <h5>Mark Villiams</h5>
                      <small class="last-seen">
                        Last Seen at 07:15 PM
                      </small>
                    </div>
                  </div>
                  <div class="chat-options ">
                    <ul class="list-inline">
                      <li class="list-inline-item">
                        <a href="javascript:void(0)" @click="showChat" class="btn btn-outline-light chat-search-btn"
                          data-bs-toggle="tooltip" data-bs-placement="bottom" title="Search">
                          <i class="bx bx-search"></i>
                        </a>
                      </li>
                      <li class="list-inline-item" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Video Call">
                        <a href="javascript:void(0)" class="btn btn-outline-light" data-bs-toggle="modal"
                          data-bs-target="#video_call">
                          <i class="bx bx-video"></i>
                        </a>
                      </li>
                      <li class="list-inline-item" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Voice Call">
                        <a href="javascript:void(0)" class="btn btn-outline-light" data-bs-toggle="modal"
                          data-bs-target="#voice_call">
                          <i class="bx bx-phone"></i>
                        </a>
                      </li>
                      <li class="list-inline-item dream_profile_menu" @click="toggleSidebar">
                        <a href="javascript:void(0)" class="btn btn-outline-light not-chat-user" data-bs-toggle="tooltip"
                          data-bs-placement="bottom" title="Contact Info">
                          <i class="bx bx-info-circle"></i>
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a class="btn btn-outline-light no-bg" href="javascript:void(0);" data-bs-toggle="dropdown">
                          <i class="bx bx-dots-vertical-rounded"></i>
                        </a>
                        <div class="dropdown-menu dropdown-menu-end">
                          <router-link to="/home/" class="dropdown-item "><span><i class="bx bx-x"></i></span>Close Chat </router-link>
                          <a href="javascript:void(0);" class="dropdown-item"><span><i class="bx bx-volume-mute"></i></span>Mute
                            Notification</a>
                          <a href="javascript:void(0);"><span><i
                                class="bx bx-time-five"></i></span>Disappearing Message</a>
                          <a href="javascript:void(0);" class="dropdown-item"><span><i class="bx bx-brush-alt"></i></span>Clear Message</a>
                          <a href="javascript:void(0);" class="dropdown-item"><span><i class="bx bx-trash"></i></span>Delete Chat</a>
                          <a href="javascript:void(0);" class="dropdown-item"><span><i class="bx bx-dislike"></i></span>Report</a>
                          <a href="javascript:void(0);" class="dropdown-item"><span><i class="bx bx-block"></i></span>Block</a>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <!-- Chat Search -->
                  <div class="chat-search" :class="{ 'visible-chat': chatVisible }">
                    <form>
                      <span class="form-control-feedback"><i class="bx bx-search"></i></span>
                      <input type="text" name="chat-search" placeholder="Search Chats" class="form-control">
                      <div class="close-btn-chat" @click="hideChat"><span class="material-icons">close</span></div>
                    </form>
                  </div>
                  <!-- /Chat Search -->
                </div>
                <div class="chat-body chat-page-group slimscroll">
                  <div class="messages">
                    <div class="chats">
                      <div class="chat-avatar">
                        <img src="@/assets/img/avatar/avatar-2.jpg" class="rounded-circle dreams_chat" alt="image">
                      </div>
                      <div class="chat-content">
                        <div class="chat-profile-name">
                          <h6>Mark Villiams<span>8:16 PM</span><span class="check-star msg-star d-none"><i
                                class="bx bxs-star"></i></span></h6>
                          <div class="chat-action-btns ms-2">
                            <div class="chat-action-col">
                              <a class="#" href="javascript:void(0);" data-bs-toggle="dropdown">
                                <i class="bx bx-dots-horizontal-rounded"></i>
                              </a>
                              <div class="dropdown-menu chat-drop-menu dropdown-menu-end">
                                <a href="javascript:void(0);" class="dropdown-item message-info-left"><span><i
                                      class="bx bx-info-circle"></i></span>Message Info </a>
                                <a href="javascript:void(0);" class="dropdown-item reply-button"><span><i
                                      class="bx bx-share"></i></span>Reply</a>
                                <a href="javascript:void(0);" class="dropdown-item"><span><i
                                      class="bx bx-smile"></i></span>React</a>
                                <a href="javascript:void(0);" class="dropdown-item"><span><i class="bx bx-reply"></i></span>Forward</a>
                                <a href="javascript:void(0);" class="dropdown-item click-star"><span><i
                                      class="bx bx-star"></i></span><span class="star-msg">Star Message</span></a>
                                <a href="javascript:void(0);" class="dropdown-item"><span><i class="bx bx-dislike"></i></span>Report</a>
                                <a href="javascript:void(0);" class="dropdown-item"><span><i class="bx bx-trash"></i></span>Delete</a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="message-content">
                          Hello <a href="javascript:void(0);">@Alex</a> Thank you for the beautiful web design ahead
                          schedule.
                          <div class="emoj-group">
                            <ul>
                              <li class="emoj-action"><a href="javascript:void(0);"><i class="bx bx-smile"></i></a>
                                <div class="emoj-group-list">
                                  <ul>
                                    <li><a href="javascript:void(0);"><img src="@/assets/img/icon/emoj-icon-01.svg"
                                          alt="Icon"></a></li>
                                    <li><a href="javascript:void(0);"><img src="@/assets/img/icon/emoj-icon-02.svg"
                                          alt="Icon"></a></li>
                                    <li><a href="javascript:void(0);"><img src="@/assets/img/icon/emoj-icon-03.svg"
                                          alt="Icon"></a></li>
                                    <li><a href="javascript:void(0);"><img src="@/assets/img/icon/emoj-icon-04.svg"
                                          alt="Icon"></a></li>
                                    <li><a href="javascript:void(0);"><img src="@/assets/img/icon/emoj-icon-05.svg"
                                          alt="Icon"></a></li>
                                    <li class="add-emoj"><a href="javascript:void(0);"><i class="feather-plus"></i></a>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                              <li><a href="javascript:void(0);"><i class="bx bx-share"></i></a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="chat-line">
                      <span class="chat-date">Today, July 24</span>
                    </div>
                    <div class="chats chats-right">
                      <div class="chat-content">
                        <div class="chat-profile-name text-end">
                          <h6><i class="bx bx-check-double me-1 inactive-check"></i>Alex Smith<span>8:16 PM</span><span
                              class="check-star msg-star-one d-none"><i class="bx bxs-star"></i></span></h6>
                          <div class="chat-action-btns ms-2">
                            <div class="chat-action-col">
                              <a class="#" href="javascript:void(0);" data-bs-toggle="dropdown">
                                <i class="bx bx-dots-horizontal-rounded"></i>
                              </a>
                              <div class="dropdown-menu chat-drop-menu dropdown-menu-end">
                                <a href="javascript:void(0);" class="dropdown-item message-info-left"><span><i
                                      class="bx bx-info-circle"></i></span>Message Info </a>
                                <a href="javascript:void(0);" class="dropdown-item reply-button"><span><i
                                      class="bx bx-share"></i></span>Reply</a>
                                <a href="javascript:void(0);" class="dropdown-item"><span><i
                                      class="bx bx-smile"></i></span>React</a>
                                <a href="javascript:void(0);" class="dropdown-item"><span><i class="bx bx-reply"></i></span>Forward</a>
                                <a href="javascript:void(0);" class="dropdown-item click-star-one"><span><i
                                      class="bx bx-star"></i></span><span class="star-msg-one">Star Message</span></a>
                                <a href="javascript:void(0);" class="dropdown-item"><span><i class="bx bx-edit-alt"></i></span>Edit</a>
                                <a href="javascript:void(0);" class="dropdown-item"><span><i class="bx bx-trash"></i></span>Delete</a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="message-content ">
                          <div class="emoj-group rig-emoji-group">
                            <ul>
                              <li class="emoj-action"><a href="javascript:void(0);"><i class="bx bx-smile"></i></a>
                                <div class="emoj-group-list">
                                  <ul>
                                    <li><a href="javascript:void(0);"><img src="@/assets/img/icon/emoj-icon-01.svg"
                                          alt="Icon"></a></li>
                                    <li><a href="javascript:void(0);"><img src="@/assets/img/icon/emoj-icon-02.svg"
                                          alt="Icon"></a></li>
                                    <li><a href="javascript:void(0);"><img src="@/assets/img/icon/emoj-icon-03.svg"
                                          alt="Icon"></a></li>
                                    <li><a href="javascript:void(0);"><img src="@/assets/img/icon/emoj-icon-04.svg"
                                          alt="Icon"></a></li>
                                    <li><a href="javascript:void(0);"><img src="@/assets/img/icon/emoj-icon-05.svg"
                                          alt="Icon"></a></li>
                                    <li class="add-emoj"><a href="javascript:void(0);"><i class="feather-plus"></i></a>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                              <li><a href="javascript:void(0);"><i class="bx bx-share"></i></a></li>
                            </ul>
                          </div>
                          <div class="chat-voice-group">
                            <ul>
                              <li><a href="javascript:void(0);"><span><img src="@/assets/img/icon/play-01.svg"
                                      alt="image"></span></a></li>
                              <li><img src="@/assets/img/voice.svg" alt="image"></li>
                              <li>0:05</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div class="chat-avatar">
                        <img src="@/assets/img/avatar/avatar-10.jpg" class="rounded-circle dreams_chat" alt="image">
                      </div>
                    </div>
                    <div class="chats">
                      <div class="chat-avatar">
                        <img src="@/assets/img/avatar/avatar-2.jpg" class="rounded-circle dreams_chat" alt="image">
                      </div>
                      <div class="chat-content">
                        <div class="chat-profile-name">
                          <h6>Mark Villiams<span>8:16 PM</span><span class="check-star msg-star-three d-none"><i
                                class="bx bxs-star"></i></span></h6>
                          <div class="chat-action-btns ms-2">
                            <div class="chat-action-col">
                              <a class="#" href="javascript:void(0);" data-bs-toggle="dropdown">
                                <i class="bx bx-dots-horizontal-rounded"></i>
                              </a>
                              <div class="dropdown-menu chat-drop-menu dropdown-menu-end">
                                <a href="javascript:void(0);" class="dropdown-item message-info-left"><span><i
                                      class="bx bx-info-circle"></i></span>Message Info </a>
                                <a href="javascript:void(0);" class="dropdown-item reply-button"><span><i
                                      class="bx bx-share"></i></span>Reply</a>
                                <a href="javascript:void(0);" class="dropdown-item"><span><i
                                      class="bx bx-smile"></i></span>React</a>
                                <a href="javascript:void(0);" class="dropdown-item"><span><i class="bx bx-reply"></i></span>Forward</a>
                                <a href="javascript:void(0);" class="dropdown-item click-star-three"><span><i
                                      class="bx bx-star"></i></span><span class="star-msg-three">Star Message</span></a>
                                <a href="javascript:void(0);" class="dropdown-item"><span><i class="bx bx-dislike"></i></span>Report</a>
                                <a href="javascript:void(0);" class="dropdown-item"><span><i class="bx bx-trash"></i></span>Delete</a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="message-content award-link chat-award-link">
                          <a href="javascript:void(0);">https://www.youtube.com/watch?v=GCmL3mS0Psk</a>
                          <img src="@/assets/img/award.jpg" alt="img">
                          <div class="emoj-group">
                            <ul>
                              <li class="emoj-action"><a href="javascript:void(0);"><i class="bx bx-smile"></i></a>
                                <div class="emoj-group-list">
                                  <ul>
                                    <li><a href="javascript:void(0);"><img src="@/assets/img/icon/emoj-icon-01.svg"
                                          alt="Icon"></a></li>
                                    <li><a href="javascript:void(0);"><img src="@/assets/img/icon/emoj-icon-02.svg"
                                          alt="Icon"></a></li>
                                    <li><a href="javascript:void(0);"><img src="@/assets/img/icon/emoj-icon-03.svg"
                                          alt="Icon"></a></li>
                                    <li><a href="javascript:void(0);"><img src="@/assets/img/icon/emoj-icon-04.svg"
                                          alt="Icon"></a></li>
                                    <li><a href="javascript:void(0);"><img src="@/assets/img/icon/emoj-icon-05.svg"
                                          alt="Icon"></a></li>
                                    <li class="add-emoj"><a href="javascript:void(0);"><i class="feather-plus"></i></a>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                              <li><a href="javascript:void(0);"><i class="bx bx-share"></i></a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="chats chats-right">
                      <div class="chat-content">
                        <div class="chat-profile-name justify-content-end">
                          <h6><i class="bx bx-check-double me-1 active-check"></i>Alex Smith<span>8:16 PM</span><span
                              class="check-star msg-star-four d-none"><i class="bx bxs-star"></i></span></h6>
                          <div class="chat-action-btns ms-2">
                            <div class="chat-action-col">
                              <a class="#" href="javascript:void(0);" data-bs-toggle="dropdown">
                                <i class="bx bx-dots-horizontal-rounded"></i>
                              </a>
                              <div class="dropdown-menu chat-drop-menu dropdown-menu-end">
                                <a href="javascript:void(0);" class="dropdown-item message-info-left"><span><i
                                      class="bx bx-info-circle"></i></span>Message Info </a>
                                <a href="javascript:void(0);" class="dropdown-item reply-button"><span><i
                                      class="bx bx-share"></i></span>Reply</a>
                                <a href="javascript:void(0);" class="dropdown-item"><span><i
                                      class="bx bx-smile"></i></span>React</a>
                                <a href="javascript:void(0);" class="dropdown-item"><span><i class="bx bx-reply"></i></span>Forward</a>
                                <a href="javascript:void(0);" class="dropdown-item click-star-four"><span><i
                                      class="bx bx-star"></i></span><span class="star-msg-four">Star Message</span></a>
                                <a href="javascript:void(0);" class="dropdown-item"><span><i class="bx bx-edit-alt"></i></span>Edit</a>
                                <a href="javascript:void(0);" class="dropdown-item"><span><i class="bx bx-trash"></i></span>Delete</a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="message-content fancy-msg-box">
                          <div class="emoj-group wrap-emoji-group ">
                            <ul>
                              <li class="emoj-action"><a href="javascript:void(0);"><i class="bx bx-smile"></i></a>
                                <div class="emoj-group-list">
                                  <ul>
                                    <li><a href="javascript:void(0);"><img src="@/assets/img/icon/emoj-icon-01.svg"
                                          alt="Icon"></a></li>
                                    <li><a href="javascript:void(0);"><img src="@/assets/img/icon/emoj-icon-02.svg"
                                          alt="Icon"></a></li>
                                    <li><a href="javascript:void(0);"><img src="@/assets/img/icon/emoj-icon-03.svg"
                                          alt="Icon"></a></li>
                                    <li><a href="javascript:void(0);"><img src="@/assets/img/icon/emoj-icon-04.svg"
                                          alt="Icon"></a></li>
                                    <li><a href="javascript:void(0);"><img src="@/assets/img/icon/emoj-icon-05.svg"
                                          alt="Icon"></a></li>
                                    <li class="add-emoj"><a href="javascript:void(0);"><i class="feather-plus"></i></a>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                              <li><a href="javascript:void(0);"><i class="bx bx-share"></i></a></li>
                            </ul>
                          </div>
                          <div class="download-col">
                            <chat-image></chat-image>
                          </div>
                        </div>
                      </div>
                      <div class="chat-avatar">
                        <img src="@/assets/img/avatar/avatar-10.jpg" class="rounded-circle dreams_chat" alt="image">
                      </div>
                    </div>

                    <div class="chats">
                      <div class="chat-avatar">
                        <img src="@/assets/img/avatar/avatar-2.jpg" class="rounded-circle dreams_chat" alt="image">
                      </div>
                      <div class="chat-content">
                        <div class="chat-profile-name">
                          <h6>Mark Villiams<span>8:16 PM</span><span class="check-star msg-star-five d-none"><i
                                class="bx bxs-star"></i></span></h6>
                          <div class="chat-action-btns ms-2">
                            <div class="chat-action-col">
                              <a class="#" href="javascript:void(0);" data-bs-toggle="dropdown">
                                <i class="bx bx-dots-horizontal-rounded"></i>
                              </a>
                              <div class="dropdown-menu chat-drop-menu dropdown-menu-end">
                                <a href="javascript:void(0);" class="dropdown-item message-info-left"><span><i
                                      class="bx bx-info-circle"></i></span>Message Info </a>
                                <a href="javascript:void(0);" class="dropdown-item reply-button"><span><i
                                      class="bx bx-share"></i></span>Reply</a>
                                <a href="javascript:void(0);" class="dropdown-item"><span><i
                                      class="bx bx-smile"></i></span>React</a>
                                <a href="javascript:void(0);" class="dropdown-item"><span><i class="bx bx-reply"></i></span>Forward</a>
                                <a href="javascript:void(0);" class="dropdown-item click-star-five"><span><i
                                      class="bx bx-star"></i></span><span class="star-msg-five">Star Message</span></a>
                                <a href="javascript:void(0);" class="dropdown-item"><span><i class="bx bx-dislike"></i></span>Report</a>
                                <a href="javascript:void(0);" class="dropdown-item"><span><i class="bx bx-trash"></i></span>Delete</a>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="like-chat-grp">
                          <ul>
                            <li class="like-chat"><a href="javascript:void(0);">2<img src="@/assets/img/icon/like.svg"
                                  alt="Icon"></a></li>
                            <li class="comment-chat"><a href="javascript:void(0);">2<img src="@/assets/img/icon/heart.svg"
                                  alt="Icon"></a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="chats">
                      <div class="chat-avatar">
                        <img src="@/assets/img/avatar/avatar-2.jpg" class="rounded-circle dreams_chat" alt="image">
                      </div>
                      <div class="chat-content">
                        <div class="chat-profile-name">
                          <h6>Mark Villiams<span>8:16 PM</span><span class="check-star msg-star d-none"><i
                                class="bx bxs-star"></i></span></h6>
                          <div class="chat-action-btns ms-2">
                            <div class="chat-action-col">
                              <a class="#" href="javascript:void(0);" data-bs-toggle="dropdown">
                                <i class="bx bx-dots-horizontal-rounded"></i>
                              </a>
                              <div class="dropdown-menu chat-drop-menu dropdown-menu-end">
                                <a href="javascript:void(0);" class="dropdown-item message-info-left"><span><i
                                      class="bx bx-info-circle"></i></span>Message Info </a>
                                <a href="javascript:void(0);" class="dropdown-item reply-button"><span><i
                                      class="bx bx-share"></i></span>Reply</a>
                                <a href="javascript:void(0);" class="dropdown-item"><span><i
                                      class="bx bx-smile"></i></span>React</a>
                                <a href="javascript:void(0);" class="dropdown-item"><span><i class="bx bx-reply"></i></span>Forward</a>
                                <a href="javascript:void(0);" class="dropdown-item click-star"><span><i
                                      class="bx bx-star"></i></span><span class="star-msg">Star Message</span></a>
                                <a href="javascript:void(0);" class="dropdown-item"><span><i class="bx bx-edit-alt"></i></span>Report</a>
                                <a href="javascript:void(0);" class="dropdown-item"><span><i class="bx bx-trash"></i></span>Delete</a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="message-content reply-getcontent">
                          Thank you for your support
                          <div class="emoj-group">
                            <ul>
                              <li class="emoj-action"><a href="javascript:void(0);"><i class="bx bx-smile"></i></a>
                                <div class="emoj-group-list">
                                  <ul>
                                    <li><a href="javascript:void(0);"><img src="@/assets/img/icon/emoj-icon-01.svg"
                                          alt="Icon"></a></li>
                                    <li><a href="javascript:void(0);"><img src="@/assets/img/icon/emoj-icon-02.svg"
                                          alt="Icon"></a></li>
                                    <li><a href="javascript:void(0);"><img src="@/assets/img/icon/emoj-icon-03.svg"
                                          alt="Icon"></a></li>
                                    <li><a href="javascript:void(0);"><img src="@/assets/img/icon/emoj-icon-04.svg"
                                          alt="Icon"></a></li>
                                    <li><a href="javascript:void(0);"><img src="@/assets/img/icon/emoj-icon-05.svg"
                                          alt="Icon"></a></li>
                                    <li class="add-emoj"><a href="javascript:void(0);"><i class="feather-plus"></i></a>
                                    </li>
                                  </ul>
                                </div>
                              </li>
                              <li><a href="javascript:void(0);"><i class="bx bx-share"></i></a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="chats">
                      <div class="chat-avatar">
                        <img src="@/assets/img/avatar/avatar-2.jpg" class="rounded-circle dreams_chat" alt="image">
                      </div>
                      <div class="chat-content chat-cont-type">
                        <div class="chat-profile-name chat-type-wrapper">
                          <p>Mark Villiams Typing...</p>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div class="chat-footer">
                <form>
                  <div class="smile-foot">
                    <div class="chat-action-btns">
                      <div class="chat-action-col">
                        <a class="action-circle" href="javascript:void(0);" data-bs-toggle="dropdown">
                          <i class="bx bx-dots-vertical-rounded"></i>
                        </a>
                        <div class="dropdown-menu dropdown-menu-end">
                          <a href="javascript:void(0);" class="dropdown-item "><span><i
                                class="bx bx-file"></i></span>Document</a>
                          <a href="javascript:void(0);" class="dropdown-item"><span><i
                                class="bx bx-camera"></i></span>Camera</a>
                          <a href="javascript:void(0);" class="dropdown-item"><span><i
                                class="bx bx-image"></i></span>Gallery</a>
                          <a href="javascript:void(0);" class="dropdown-item"><span><i
                                class="bx bx-volume-full"></i></span>Audio</a>
                          <a href="javascript:void(0);" class="dropdown-item"><span><i
                                class="bx bx-map"></i></span>Location</a>
                          <a href="javascript:void(0);" class="dropdown-item"><span><i
                                class="bx bx-user-pin"></i></span>Contact</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="smile-foot emoj-action-foot">
                    <a href="javascript:void(0);" class="action-circle"><i class="bx bx-smile"></i></a>
                    <div class="emoj-group-list-foot down-emoji-circle">
                      <ul>
                        <li><a href="javascript:void(0);"><img src="@/assets/img/icon/emoj-icon-01.svg" alt="Icon"></a></li>
                        <li><a href="javascript:void(0);"><img src="@/assets/img/icon/emoj-icon-02.svg" alt="Icon"></a></li>
                        <li><a href="javascript:void(0);"><img src="@/assets/img/icon/emoj-icon-03.svg" alt="Icon"></a></li>
                        <li><a href="javascript:void(0);"><img src="@/assets/img/icon/emoj-icon-04.svg" alt="Icon"></a></li>
                        <li><a href="javascript:void(0);"><img src="@/assets/img/icon/emoj-icon-05.svg" alt="Icon"></a></li>
                        <li class="add-emoj"><a href="javascript:void(0);"><i class="feather-plus"></i></a></li>
                      </ul>
                    </div>
                  </div>
                  <div class="smile-foot">
                    <a href="javascript:void(0);" class="action-circle"><i class="bx bx-microphone-off"></i></a>
                  </div>
                  <div class="replay-forms">
                    <div class="chats forward-chat-msg reply-div d-none">
                      <div class="contact-close_call text-end">
                        <a href="javascript:void(0);" class="close-replay">
                          <i class="bx bx-x"></i>
                        </a>
                      </div>
                      <div class="chat-avatar">
                        <img src="@/assets/img/avatar/avatar-2.jpg" class="rounded-circle dreams_chat" alt="image">
                      </div>
                      <div class="chat-content">
                        <div class="chat-profile-name">
                          <h6>Mark Villiams<span>8:16 PM</span></h6>
                          <div class="chat-action-btns ms-2">
                            <div class="chat-action-col">
                              <a class="#" href="javascript:void(0);" data-bs-toggle="dropdown">
                                <i class="bx bx-dots-horizontal-rounded"></i>
                              </a>
                              <div class="dropdown-menu chat-drop-menu dropdown-menu-end">
                                <a href="javascript:void(0);" class="dropdown-item message-info-left"><span><i
                                      class="bx bx-info-circle"></i></span>Message Info </a>
                                <a href="javascript:void(0);" class="dropdown-item reply-button"><span><i
                                      class="bx bx-share"></i></span>Reply</a>
                                <a href="javascript:void(0);" class="dropdown-item"><span><i
                                      class="bx bx-smile"></i></span>React</a>
                                <a href="javascript:void(0);" class="dropdown-item"><span><i class="bx bx-reply"></i></span>Forward</a>
                                <a href="javascript:void(0);" class="dropdown-item"><span><i
                                      class="bx bx-star"></i></span>Star Message</a>
                                <a href="javascript:void(0);" class="dropdown-item"><span><i class="bx bx-dislike"></i></span>Report</a>
                                <a href="javascript:void(0);" class="dropdown-item"><span><i class="bx bx-trash"></i></span>Delete</a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="message-content reply-content">
                        </div>
                      </div>
                    </div>
                    <input type="text" class="form-control chat_form" placeholder="Type your message here...">
                  </div>
                  <div class="form-buttons">
                    <button class="btn send-btn" type="submit">
                      <i class="bx bx-paper-plane"></i>
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <!-- /Chat -->

            <!-- Right sidebar -->
					  <div class="right-sidebar right_sidebar_profile right-side-contact show-right-sidebar"
            :class="{ 'show-right-sidebar': showSidebar, 'hide-right-sidebar': !showSidebar }" id="middle1">
						  <div class="right-sidebar-wrap active">
                <perfect-scrollbar class="scroll-area" :settings="settings" @ps-scroll-y="scrollHanle">
                  <div class="slimscroll">
                      <div class="left-chat-title d-flex justify-content-between align-items-center border-bottom-0">
                        <div class="fav-title mb-0">
                          <h6>Contact Info</h6>
                        </div>
                        <div class="contact-close_call text-end">
                          <a href="javascript:void(0);" @click="handleClick"
                            class="close_profile close_profile4">
                            <span class="material-icons">close</span>
                          </a>
                        </div>
                      </div>
                      <chat-right-sidebar></chat-right-sidebar>
                      <div class="chat-message-grp">
                        <ul>
                          <li>
                            <a href="javascript:void(0);" class="star-message-left" >
                              <div class="stared-group">
                                <span class="star-message"> <i class="bx bxs-star"></i></span>
                                <h6>Starred Messages</h6>
                              </div>
                              <div class="count-group">
                                <span>10</span>
                                <i class="feather-chevron-right"></i>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="stared-group">
                                <span class="mute-message"> <i class="bx bxs-microphone-off"></i></span>
                                <h6>Mute Notifications</h6>
                              </div>
                              <div class="count-group">
                                <i class="feather-chevron-right"></i>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="stared-group">
                                <span class="block-message"> <i class="bx bx-block"></i></span>
                                <h6>Block User</h6>
                              </div>
                              <div class="count-group">
                                <i class="feather-chevron-right"></i>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="stared-group">
                                <span class="report-message"> <i class="bx bx-dislike"></i></span>
                                <h6>Report User</h6>
                              </div>
                              <div class="count-group">
                                <i class="feather-chevron-right"></i>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <div class="stared-group">
                                <span class="delete-message"> <i class="bx bx-trash"></i></span>
                                <h6>Delete Chat</h6>
                              </div>
                              <div class="count-group">
                                <i class="feather-chevron-right"></i>
                              </div>
                            </a>
                          </li>
                        </ul>
                      </div>
                  </div>
                </perfect-scrollbar>
						  </div>
					  </div>
					  <!-- Right sidebar -->


          </div>
        </div>
      </div>
    
  </div>
  <managemodal></managemodal>
</template>

<script>
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
import { myClickHandler } from "@/views/frontend/pages/employers/chats/myFunctions.js";
import '@/assets/css/chat.css'
export default {
  components: {
    PerfectScrollbar,
  },
  data() {
    return {
      settings: {
        suppressScrollX: true,
      },
      showSidebar: false,
      chatVisible: false,
    }
  },
  methods: {
    scrollHanle(evt) { },
    handleClick() {
      myClickHandler();
    },
    toggleSidebar() {
      this.showSidebar = !this.showSidebar;
    },
    showChat() {
      this.chatVisible = true;
    },
    hideChat() {
      this.chatVisible = false;
    },
    handleLeftSidesClick() {
      if (window.innerWidth <= 767) {
        const sidebarGroup = document.getElementsByClassName("sidebar-group");
        const sidebarMenu = document.getElementsByClassName("sidebar-menu");

        for (let i = 0; i < sidebarGroup.length; i++) {
          sidebarGroup[i].classList.remove("hide-left-sidebar");
        }

        for (let i = 0; i < sidebarMenu.length; i++) {
          sidebarMenu[i].classList.remove("d-none");
        }
      }
    },
  },
  mounted() {
    var dreamProfileMenus = document.getElementsByClassName("dream_profile_menu");
    var chats = document.getElementsByClassName("chat");

    for (var i = 0; i < dreamProfileMenus.length; i++) {
      dreamProfileMenus[i].addEventListener("click", function () {
        if (window.innerWidth > 991 && window.innerWidth < 1201) {
          for (var j = 0; j < chats.length; j++) {
            if (
              !hasClass(chats[j], "right-side-contact") &&
              !hasClass(chats[j], "right_side_star")
            ) {
              chats[j].style.marginLeft = -chatBar + "px";
            }
          }
        }
        if (window.innerWidth < 992) {
          for (var k = 0; k < chats.length; k++) {
            if (
              !hasClass(chats[k], "right-side-contact") &&
              !hasClass(chats[k], "right_side_star")
            ) {
              chats[k].classList.add("hide-chatbar");
            }
          }
        }
      });
    }
    function hasClass(element, className) {
      return (" " + element.className + " ").indexOf(" " + className + " ") > -1;
    }

    var messageInfoLeft = document.getElementsByClassName("message-info-left")[0];

    messageInfoLeft.addEventListener("click", function () {
      var windowWidth = window.innerWidth;
      var chatElements = document.getElementsByClassName("chat");
      var rightSidebarChatElements = document.querySelectorAll(
        ".right_sidebar_info .chat"
      );
      // var chatBar = /* Replace with your chat_bar value */;

      if (windowWidth > 991 && windowWidth < 1201) {
        for (var i = 0; i < chatElements.length; i++) {
          if (!isDescendant(chatElements[i], rightSidebarChatElements)) {
            chatElements[i].style.marginLeft = -chatBar + "px";
          }
        }
      }

      if (windowWidth < 992) {
        for (var i = 0; i < chatElements.length; i++) {
          if (!isDescendant(chatElements[i], rightSidebarChatElements)) {
            chatElements[i].classList.add("hide-chatbar");
          }
        }
      }
    });

    // Function to check if an element is a descendant of any elements in a NodeList
    function isDescendant(element, nodeList) {
      for (var i = 0; i < nodeList.length; i++) {
        if (nodeList[i].contains(element)) {
          return true;
        }
      }
      return false;
    }
  },
}
</script>