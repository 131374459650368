<template>
  <div class="modal fade edit-proposal-modal" id="write-review">
    <div class="modal-dialog modal-dialog-centered modal-md">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Write a Review</h4>
          <span class="modal-close"
            ><a href="javascript:void(0);" data-bs-dismiss="modal" aria-label="Close"
              ><i class="feather-x"></i></a
          ></span>
        </div>
        <div class="modal-body">
          <form action="#">
            <div class="modal-info">
              <div class="reviewed-user">
                <img src="@/assets/img/user/table-avatar-02.jpg" alt="Img" />
                <span>Bolethe Fleischer</span>
              </div>
              <div class="input-block form-info">
                <label class="col-form-label mb-0 mt-2">Ratings</label>
                <div class="rating rating-select mb-0">
                  <a href="javascript:void(0);"><i class="fas fa-star"></i></a>
                  <a href="javascript:void(0);"><i class="fas fa-star"></i></a>
                  <a href="javascript:void(0);"><i class="fas fa-star"></i></a>
                  <a href="javascript:void(0);"><i class="fas fa-star"></i></a>
                  <a href="javascript:void(0);"><i class="fas fa-star"></i></a>
                </div>
              </div>
              <div class="input-block">
                <label class="form-label">Description</label>
                <textarea class="form-control summernote"></textarea>
              </div>
            </div>
            <div class="submit-section text-end">
              <a href="javascript:void(0);" class="btn btn-cancel">Cancel</a>
              <button type="submit" class="btn btn-primary submit-btn">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
