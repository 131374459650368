<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>

  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content container-fluid">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row align-items-center">
          <div class="col">
            <breadcrumbadmin :title="title" :text="text" :text1="text1" :text2="text2" />
          </div>
          <div class="col-auto">
            <a
              href="javascript:;"
              class="btn add-button me-2"
              data-bs-toggle="modal"
              data-bs-target="#add-category"
            >
              <i class="fas fa-plus"></i>
            </a>
            <a
              class="btn filter-btn"
              href="javascript:void(0);"
              @click="toggleFilterInputs"
              id="filter_search"
            >
              <i class="fas fa-filter"></i>
            </a>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <!-- Search Filter -->
      <div
        class="card filter-card"
        :style="{ display: filterInputsDisplay }"
        id="filter_inputs"
      >
        <div class="card-body pb-0">
          <form action="javascript:;" method="post">
            <div class="row filter-row">
              <div class="col-sm-4 col-md-4">
                <div class="form-group">
                  <label>Parent Category</label>
                  <vue-select
                    :options="Category"
                    id="category"
                    placeholder="Select A Parent Category"
                  />
                </div>
              </div>
              <div class="col-sm-4 col-md-4">
                <div class="form-group">
                  <label>Sub Category Name</label>
                  <input type="text" class="form-control" />
                </div>
              </div>
              <div class="col-sm-4 col-md-4">
                <div class="form-group">
                  <button class="btn btn-primary btn-block" type="submit">Submit</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <!-- /Search Filter -->

      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
                <a-table
                  class="stripped table-hover"
                  :columns="columns"
                  :data-source="data"
                >
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key === 'Category'">
                      <div>{{ record.Category }}</div>
                    </template>
                    <template v-else-if="column.key === 'id'">
                      <td class="text-end">
                        <a
                          href="javascript:void(0);"
                          class="btn btn-sm btn-secondary me-2"
                          data-bs-toggle="modal"
                          data-bs-target="#edit-category"
                          ><i class="far fa-edit"></i
                        ></a>
                        <a
                          href="javascript:void(0);"
                          class="btn btn-sm btn-danger"
                          data-bs-toggle="modal"
                          data-bs-target="#delete_category"
                          ><i class="far fa-trash-alt"></i
                        ></a>
                      </td>
                    </template>
                  </template>
                </a-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Wrapper -->
  </div>

  <!-- Add Modal -->
  <div class="modal fade custom-modal" id="add-category">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Add Sub Categories</h4>
          <button type="button" class="close" data-bs-dismiss="modal">
            <span>&times;</span>
          </button>
        </div>

        <!-- Modal body -->
        <div class="modal-body">
          <form>
            <div class="form-group">
              <label>Select A Parent Category</label>
              <vue-select
                :options="Category"
                id="graphics"
                placeholder="Select A Parent Category"
              />
            </div>
            <div class="form-group">
              <label>Sub Category Name</label>
              <input
                type="text"
                class="form-control"
                placeholder="Create a Sub Category"
              />
            </div>
            <div class="mt-4">
              <button type="submit" class="btn btn-primary btn-block">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Add Modal -->

  <!-- Edit Modal -->
  <div class="modal fade custom-modal" id="edit-category">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Edit Sub Categories</h4>
          <button type="button" class="close" data-bs-dismiss="modal">
            <span>&times;</span>
          </button>
        </div>

        <!-- Modal body -->
        <div class="modal-body">
          <form>
            <div class="form-group">
              <label>Select A Parent Category</label>
              <vue-select
                :options="Category"
                id="design"
                placeholder="Select A Parent Category"
              />
            </div>
            <div class="form-group">
              <label>Sub Category Name</label>
              <input type="text" class="form-control" value="Design a Banner" />
            </div>
            <div class="mt-4">
              <button type="submit" class="btn btn-primary btn-block">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Edit Modal -->

  <delete />
</template>

<script>
const columns = [
  {
    title: "Parent Category",
    dataIndex: "Category",
    key: "Category",
    sorter: {
      compare: (a, b) => {
        a = a.Category.toLowerCase();
        b = b.Category.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Sub Category Name",
    dataIndex: "Name",
    sorter: {
      compare: (a, b) => {
        a = a.Name.toLowerCase();
        b = b.Name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "id",
    class: "text-end",
    sorter: true,
  },
];

const data = [
  {
    Category: "Angular",
    Name: "Website Design",
    Actions: "",
  },
  {
    Category: "Angular",
    Name: "web application Development",
    Actions: "",
  },
  {
    Category: "Graphics & Design",
    Name: "Design a Website Mockup",
    Actions: "",
  },
  {
    Category: "Graphics & Design",
    Name: "Design a Banner",
    Actions: "",
  },
  {
    Category: "Web Design",
    Name: "Design a Website",
    Actions: "",
  },
  {
    Category: "Graphics & Design",
    Name: "Design a Logo",
    Actions: "",
  },
  {
    Category: "Web Design",
    Name: "Design a Admin panel",
    Actions: "",
  },
];
export default {
  data() {
    return {
      Category: ["Select A Parent Category", "Graphics & Design", "Graphics & Design"],
      columns,
      data,
      title: "Sub Categories",
      text: "Home",
      text1: "Categories",
      text2: "Sub Categories",
      filterInputsDisplay: "none",
    };
  },
  methods: {
    toggleFilterInputs() {
      this.filterInputsDisplay = this.filterInputsDisplay === "none" ? "block" : "none";
    },
  },
};
</script>
