<template>
  <div class="field-item personal-info space-info">
    <form>
      <div class="row">
        <div class="col-md-12 col-lg-12">
          <div class="pro-form-img">
            <div class="profile-pic">Profile Photo</div>
            <div class="upload-files">
              <label class="file-upload image-upbtn">
                <i class="feather-upload me-2"></i>Upload Photo
                <input type="file" />
              </label>
              <span>For better preview recommended size is 450px x 450px. Max size
                5mb.</span>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-6">
          <div class="input-block">
            <label class="form-label">First Name</label>
            <input type="text" class="form-control" />
          </div>
        </div>
        <div class="col-md-6 col-lg-6">
          <div class="input-block">
            <label class="form-label">Last Name</label>
            <input type="text" class="form-control" />
          </div>
        </div>
        <div class="col-md-6 col-lg-6">
          <div class="input-block">
            <label class="form-label">Phone Number</label>
            <input type="text" class="form-control" />
          </div>
        </div>
        <div class="col-md-6 col-lg-6">
          <div class="input-block">
            <label class="form-label">Email Address</label>
            <input type="text" class="form-control" />
          </div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="input-block">
            <label class="form-label">Date of Birth</label>
            <div class="cal-icon">
              <datepicker
                v-model="startdate"
                placeholder="Choose Date"
                class="form-control datetimepicker"
                :editable="true"
                :clearable="false"
              />
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="input-block">
            <label class="form-label">Your Job Title</label>
            <input type="text" class="form-control" />
          </div>
        </div>
        <div class="col-md-6 col-lg-4">
          <div class="input-block">
            <label class="form-label">Type of Job</label>
            <vue-select :options="Job" id="job" placeholder="Select" />
          </div>
        </div>
        <div class="col-md-12">
          <div class="input-block min-characters">
            <label class="form-label">Describe Yourself</label>
            <textarea class="form-control" rows="5"></textarea>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>


<script>
import { ref } from "vue";
const currentDate = ref(new Date());
export default {
  data() {
    return {
      Job: ["Select", "Full Time", "Part Time", "Hourly"],
      startdate: currentDate,
    }
  },
}
</script>