<template>
    <!-- Feature Projects -->
    <section class="section feaure-project">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-12 mx-auto">
                    <div class="section-header section-header-three justify-content-start text-start aos"
                        data-aos="fade-up">
                        <span class="badge title-badge">Featured Developers</span>
                        <h2 class="header-title">We have over 1400+ Developers</h2>
                    </div>
                </div>
            </div>
            <div id="feature-project-slider" class="feature-dev-three owl-carousel owl-theme trend-slider aos"
                data-aos="fade-up">
                <Carousel :wrap-around="true" :settings="settings" :breakpoints="breakpoints">
                    <Slide v-for="item in Feature" :key="item.id">
                        <div class="project-item project-item-feature">
                            <div class="project-img heart-blk">
                                <router-link to="/employers/developer-details"><img :src="require(`@/assets/img/developer/${item.Image}`)" alt="Img"
                                        class="img-fluid"></router-link>
                                <h5 class="hour-dollr">{{item.Price}} <span>/ hr</span></h5>
                            </div>
                            <div class="feature-project-content">
                                <div class="d-flex justify-content-between align-items-center mb-1">
                                    <h4 class="d-flex"><router-link to="/employers/developer-details">{{item.Name}}</router-link><img
                                            src="@/assets/img/icon/verify-badge.svg" class="ms-1" alt="Img"></h4>
                                    <a href="javascript:void(0);" class="select-favourite"><i
                                            class="fa-regular fa-bookmark"></i></a>
                                </div>

                                <p class="text-start">{{item.Role}}</p>
                                <div class="d-flex justify-content-between align-items-center">
                                    <div class="rating mb-0">
                                        <i class="fas fa-star filled"></i>
                                        <i class="fas fa-star filled"></i>
                                        <i class="fas fa-star filled"></i>
                                        <i class="fas fa-star filled"></i>
                                        <i class="fas fa-star"></i>
                                        <span class="average-rating">5.0</span>
                                    </div>
                                    <div class="feature-developer-foot d-flex justify-content-center">
                                        <router-link to="/employers/developer-details"><i class="feather-arrow-right"></i></router-link>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </Slide>
                    <template #addons>
                        <Navigation />
                        <Pagination />            
                    </template>
                </Carousel>
            </div>
            <div class="row">
                <div class="col-xl-12">
                    <div class="more-project text-center aos aos-init aos-animate" data-aos="fade-up">
                        <router-link to="/freelancer/project" class="btn btn-primary">View More Developers<i
                                class="feather-arrow-right ms-2"></i></router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Feature Projects -->

    <!-- Review -->
    <section class="section review-people review-people-three">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="section-header section-header-three text-center aos" data-aos="fade-up">
                        <span class="badge title-badge">Testimonials</span>
                        <h2 class="header-title">What our User Says</h2>
                    </div>
                </div>
            </div>
            <div class="trend-slider aos row" data-aos="fade-up">
                <div class="col-lg-4 col-md-6">
                    <div class="review-blog user-review">
                        <div class="review-top ">
                            <div class="review-img mx-auto">
                                <router-link to="/employers/review"><img class="img-fluid" src="@/assets/img/user/review-user-01.jpg"
                                        alt="Post Image"></router-link>
                            </div>
                            <div class="rating">
                                <i class="fas fa-star filled"></i>
                                <i class="fas fa-star filled"></i>
                                <i class="fas fa-star filled"></i>
                                <i class="fas fa-star filled"></i>
                                <i class="fas fa-star"></i>
                                <span class="average-rating">5.0(45)</span>
                            </div>

                        </div>
                        <div class="review-content text-center">
                            <p>I've been using [Your Product/Service] for a year now, and it has exceeded my expectations.
                                The quality and functionality are unmatched, and it has made my life so much easier.</p>
                        </div>
                        <div class="quate text-center d-flex justify-content-center">
                            <img src="@/assets/img/icon/user-quates.svg" alt="Img">
                        </div>
                        <div class="review-info text-center">
                            <h3><router-link to="/employers/review">Durso Raeen</router-link></h3>
                            <h5>Project Lead</h5>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="review-blog user-review">
                        <div class="review-top ">
                            <div class="review-img mx-auto">
                                <router-link to="/employers/review"><img class="img-fluid" src="@/assets/img/user/review-user-02.jpg"
                                        alt="Post Image"></router-link>
                            </div>
                            <div class="rating">
                                <i class="fas fa-star filled"></i>
                                <i class="fas fa-star filled"></i>
                                <i class="fas fa-star filled"></i>
                                <i class="fas fa-star filled"></i>
                                <i class="fas fa-star"></i>
                                <span class="average-rating">5.0(30)</span>
                            </div>

                        </div>
                        <div class="review-content text-center">
                            <p>I had a problem with [Specific Issue], and the support team at [Your Company] went above and
                                beyond to resolve it. Their dedication to customer satisfaction is truly impressive.</p>
                        </div>
                        <div class="quate text-center d-flex justify-content-center">
                            <img src="@/assets/img/icon/user-quates.svg" alt="Img">
                        </div>
                        <div class="review-info text-center">
                            <h3><router-link to="/employers/review">Durso Raeen</router-link></h3>
                            <h5>Project Lead</h5>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="review-blog user-review">
                        <div class="review-top ">
                            <div class="review-img mx-auto">
                                <router-link to="/employers/review"><img class="img-fluid" src="@/assets/img/user/review-user-03.jpg"
                                        alt="Post Image"></router-link>
                            </div>
                            <div class="rating">
                                <i class="fas fa-star filled"></i>
                                <i class="fas fa-star filled"></i>
                                <i class="fas fa-star filled"></i>
                                <i class="fas fa-star filled"></i>
                                <i class="fas fa-star"></i>
                                <span class="average-rating">5.0(13)</span>
                            </div>

                        </div>
                        <div class="review-content text-center">
                            <p>I encountered an issue with [Your Product], and the customer support team provided a quick
                                and effective solution. I'm impressed by their responsiveness.</p>
                        </div>
                        <div class="quate text-center d-flex justify-content-center">
                            <img src="@/assets/img/icon/user-quates.svg" alt="Img">
                        </div>
                        <div class="review-info text-center">
                            <h3><router-link to="/employers/review">Durso Raeen</router-link></h3>
                            <h5>Project Lead</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- / Review -->
</template>

<script>
import { Carousel, Navigation, Pagination, Slide } from "vue3-carousel";
import Feature from "@/assets/json/three-feature.json";
import "vue3-carousel/dist/carousel.css";
export default {
    data() {
        return {
            Feature: Feature,
            settings: {
                itemsToShow: 1,
                snapAlign: "center",
            },
            breakpoints: {
                575: {
                    itemsToShow: 2,
                    snapAlign: "center",
                },
                767: {
                    itemsToShow: 2,
                    snapAlign: "center",
                },
                991: {
                    itemsToShow: 3,
                    snapAlign: "center",
                },
                1024: {
                    itemsToShow: 4,
                    snapAlign: "start",
                },
            },
        }
    },
    components: {
        Carousel,
        Slide,
        Navigation,
        Pagination
    },
}
</script>