<template>
    <!-- Footer -->
    <footer class="footer footer-two" id="bottom-scroll">
        <div class="footer-top ">
            <div class="container">

                <div class="row">
                    <div class="col-xl-3 col-md-3">
                        <div class="footer-widget footer-menu">
                            <h2 class="footer-title">Useful Links</h2>
                            <ul>
                                <li><a href="javascript:void(0);">Browse Candidates</a></li>
                                <li><a href="javascript:void(0);">Employers Dashboard</a></li>
                                <li><a href="javascript:void(0);">Job Packages</a></li>
                                <li><a href="javascript:void(0);">Jobs Featured</a></li>
                                <li><a href="javascript:void(0);">Post A Job</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-xl-2 col-md-3">
                        <div class="footer-widget footer-menu">
                            <h2 class="footer-title">Pages</h2>
                            <ul>
                                <li><router-link to="/freelancer/freelancer-dashboard">Freelancers</router-link></li>
                                <li><router-link to="/freelancer/freelancer-portfolio">Freelancer Details</router-link></li>
                                <li><router-link to="/freelancer/project">Project</router-link></li>
                                <li><router-link to="/freelancer/project-details">Project Details</router-link></li>
                                <li><router-link to="/post-project">Post Project</router-link></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-xl-3 col-md-4">
                        <div class="footer-widget footer-menu">
                            <h2 class="footer-title">Featured Categories</h2>
                            <ul>
                                <li><a href="javascript:void(0);">Website Design</a></li>
                                <li><a href="javascript:void(0);">Mobile Apps</a></li>
                                <li><a href="javascript:void(0);">Android Apps</a></li>
                                <li><a href="javascript:void(0);">iPhone Apps</a></li>
                                <li><a href="javascript:void(0);">Software Architecture</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-xl-4 col-md-6">
                        <div class="footer-widget footer-menu">
                            <h2 class="footer-title">Get Contact</h2>
                            <ul class="info-contact">
                                <li>Phone: (406) 555-0120</li>
                                <li>E-mail: info@example.com</li>
                            </ul>
                            <h2 class="footer-title mb-0">Newsletter</h2>
                            <div class="banner-content aos aos-init aos-animate" data-aos="fade-up">
                                <form class="form" name="store" method="post" action="project">
                                    <div class="form-inner mb-0">
                                        <div class="input-group">
                                            <input type="email" class="form-control" placeholder="Enter Your Email here..">
                                            <button class="btn btn-primary sub-btn" type="submit">Subscribe</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /Footer Top -->

        <!-- Footer Bottom -->
        <div class="footer-bottom">
            <!-- Copyright -->
            <div class="copyright">
                <div class="row">
                    <div class="col-md-12">
                        <div class="copyright-text text-center">
                            <p class="mb-0">Copyright  {{ new Date().getFullYear() }} &copy; KofeJob. All right reserved. </p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- /Copyright -->
        </div>
        <!-- /Footer Bottom -->
    </footer>
    <!-- /Footer -->
</template>