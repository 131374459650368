<template>
    <!-- Settings Menu -->
    <div class="widget settings-menu">
        <ul>
            <li class="nav-item">
                <router-link to="/admin/settings" class="nav-link">
                    <i class="far fa-user"></i> <span>Profile Settings</span>
                </router-link>
            </li>
            <li class="nav-item">
                <router-link to="/admin/tax-types" class="nav-link">
                    <i class="far fa-check-square"></i> <span>Tax Types</span>
                </router-link>
            </li>
            <li class="nav-item">
                <router-link to="/admin/change-password" class="nav-link">
                    <i class="fas fa-unlock-alt"></i> <span>Change Password</span>
                </router-link>
            </li>
            <li class="nav-item">
                <router-link to="/admin/delete-account" class="nav-link">
                    <i class="fas fa-ban"></i> <span>Delete Account</span>
                </router-link>
            </li>
        </ul>
    </div>
    <!-- /Settings Menu -->
</template>