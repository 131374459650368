<template>
  <div class="account-page">
    <div class="login-wrapper">
      <div class="content w-100">
        <div class="container">
          <!-- Login Content -->
          <div class="account-content">
            <div class="align-items-center justify-content-center">
              <div class="login-right">
                <div class="login-header text-center">
                  <h2>Password Change</h2>
                  <span>Change New Password</span>
                </div>
                <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
                  <div class="input-block">
                    <label class="focus-label"
                      >Old Password <span class="label-star"> *</span></label
                    >
                    <div class="position-relative">
                      <Field
                        name="confirmpassword1"
                        :type="showPassword2 ? 'text' : 'password'"
                        class="form-control pass-input mt-2"
                        :class="{ 'is-invalid': errors.confirmpassword1 }"
                      />
                      <span @click="toggleShow2" class="toggle-password2">
                        <i
                          :class="{
                            'fas fa-eye': showPassword2,
                            'fas fa-eye-slash': !showPassword2,
                          }"
                        ></i>
                      </span>
                      <div class="invalid-feedback">{{ errors.confirmpassword1 }}</div>
                      <div class="emailshow text-danger" id="confirmpassword"></div>
                    </div>
                  </div>
                  <div class="input-block">
                    <label class="focus-label"
                      >New Password <span class="label-star"> *</span></label
                    >
                    <div class="position-relative">
                      <Field
                        name="confirmpassword"
                        :type="showPassword1 ? 'text' : 'password'"
                        class="form-control pass-input mt-2"
                        :class="{ 'is-invalid': errors.confirmpassword }"
                      />
                      <span @click="toggleShow1" class="toggle-password2">
                        <i
                          :class="{
                            'fas fa-eye': showPassword1,
                            'fas fa-eye-slash': !showPassword1,
                          }"
                        ></i>
                      </span>
                      <div class="invalid-feedback">{{ errors.confirmpassword }}</div>
                      <div class="emailshow text-danger" id="confirmpassword"></div>
                    </div>
                  </div>
                  <div class="input-block">
                    <label class="focus-label"
                      >Confirm New Password <span class="label-star"> *</span></label
                    >
                    <div class="position-relative">
                      <Field
                        name="password"
                        :type="showPassword ? 'text' : 'password'"
                        class="form-control pass-input mt-2"
                        placeholder="Password"
                        :class="{ 'is-invalid': errors.password }"
                      />
                      <span @click="toggleShow" class="toggle-password2">
                        <i
                          :class="{
                            'fas fa-eye': showPassword,
                            'fas fa-eye-slash': !showPassword,
                          }"
                        ></i>
                      </span>
                      <div class="invalid-feedback">{{ errors.password }}</div>
                      <div class="emailshow text-danger" id="password"></div>
                    </div>
                  </div>
                  <button
                    class="btn btn-primary btn-lg login-btn d-flex align-items-center justify-content-center w-100"
                    type="submit"
                  >
                    Password Change<i class="feather-arrow-right ms-2"></i>
                  </button>
                </Form>
              </div>
            </div>
          </div>
          <!-- /Login Content -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Form, Field } from "vee-validate";
import { router } from "@/router";
import * as Yup from "yup";
export default {
  components: {
    Form,
    Field,
  },
  data() {
    return {
      activeTab: "developer",
      showPassword: false,
      showPassword1: false,
      showPassword2: false,
      password: null,
    };
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
    buttonLabel() {
      return this.showPassword1 ? "Hide" : "Show";
    },
    buttonLabel() {
      return this.showPassword2 ? "Hide" : "Show";
    },
  },
  methods: {
    activateTab(tab) {
      this.activeTab = tab;
    },
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    toggleShow1() {
      this.showPassword1 = !this.showPassword1;
    },
    toggleShow2() {
      this.showPassword2 = !this.showPassword2;
    },
  },
  mounted() {},
  setup() {
    const schema = Yup.object().shape({
      password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .required("Confirm New password is required"),
      confirmpassword: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .required("New password is required"),
      confirmpassword1: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .required("Old password is required"),
    });
    const onSubmit = (values) => {
      if (values.password === values.confirmpassword) {
        let Rawdata = localStorage.getItem("storedData");
        let Pdata = [];
        Pdata = JSON.parse(Rawdata);
        const Eresult = Pdata.find(({ password }) => password == values.password);
        if (Eresult) {
          // document.getElementById("password").innerHTML = "This email are already exist";
        } else {
          Pdata.push(values);
          const jsonData = JSON.stringify(Pdata);
          router.push("/home");
          localStorage.setItem("storedData", jsonData);
        }
      } else {
        document.getElementById("password").innerHTML = "Password not matching";
      }
    };
    return {
      schema,
      onSubmit,
    };
  },
};
</script>
