<template>
    <div class="subscribe-employe users-list transaction-list">
        <ul v-bind:class="{active: currentPath == 'user-profile' || currentPath == 'user-profile',}">
            <li :class="currentPath == 'user-profile' ? 'active' : 'notactive'">
                <router-link to="/admin/user-profile">
                    <img class="me-2" src="@/assets/admin_img/icon/icon-09.svg" alt="Image">Personal 
                </router-link>
            </li>
            <li :class="currentPath == 'profile-transactions' ? 'active' : 'notactive'">
                <router-link to="/admin/profile-transactions">
                    <img class="me-2" src="@/assets/admin_img/icon/icon-13.svg" alt="Image">Transactions
                </router-link>
            </li>
            <li :class="currentPath == 'activities' ? 'active' : 'notactive'">
                <router-link to="/admin/activities">
                    <img class="me-2" src="@/assets/admin_img/icon/icon-11.svg" alt="Image">Activities
                </router-link>
            </li>
        </ul>
        <div class="profile-icon-group">
            <div class="d-flex">
                <a href="javascript:void(0);" class="reset-icon me-1">
                    <img src="@/assets/admin_img/icon/icon-03.svg" alt="Image">
                </a>
                <a class="suspend-icon" href="javascript:void(0);">
                    <img src="@/assets/admin_img/icon/icon-04.svg" alt="Image">
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            
        }
    },
    computed: {
        currentPath() {
            return this.$route.name;
        },
        returnMenu() {
            return (
                this.$route.name == "user-profile" ||
                this.$route.name == "profile-transactions" ||
                this.$route.name == "activities"
            );
        },
    },
}
</script>