<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>

  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content container-fluid">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row align-items-center">
          <breadcrumbthree :title="title" :text="text" :text1="text1" />
          <div class="col-auto">
            <a
              class="btn filter-btn"
              href="javascript:void(0);"
              @click="toggleFilterInputs"
              id="filter_search"
            >
              <i class="fas fa-filter"></i>
            </a>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <!-- Search Filter -->
      <div
        class="card filter-card"
        :style="{ display: filterInputsDisplay }"
        id="filter_inputs"
      >
        <div class="card-body pb-0">
          <form action="javascript:;" method="post">
            <div class="row filter-row">
              <div class="col-sm-6 col-md-3">
                <div class="form-group">
                  <label>Company Name</label>
                  <input class="form-control" type="text" />
                </div>
              </div>
              <div class="col-sm-6 col-md-3">
                <div class="form-group">
                  <label>From Date</label>
                  <div class="cal-icon">
                    <datepicker
                      v-model="startdate"
                      placeholder="Choose Date"
                      class="form-control datetimepicker"
                      :editable="true"
                      :clearable="false"
                    />
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-3">
                <div class="form-group">
                  <label>To Date</label>
                  <div class="cal-icon">
                    <datepicker
                      v-model="startdate"
                      placeholder="Choose Date"
                      class="form-control datetimepicker"
                      :editable="true"
                      :clearable="false"
                    />
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-3">
                <div class="form-group">
                  <button class="btn btn-primary btn-block" type="submit">Submit</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <!-- /Search Filter -->

      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-body">
              <div class="showentries">
                <label
                  >Show
                  <select>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  entries
                </label>
                <div class="entries">
                  <span>Search: </span>
                  <input
                    type="search"
                    class="form-control form-control-sm ms-2"
                    placeholder=""
                    aria-controls="DataTables_Table_0"
                  />
                </div>
              </div>
              <div class="table-responsive">
                <a-table :columns="columns" :data-source="data">
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key === 'Logo'">
                      <h2 class="table-avatar">
                        <a href="javascript:void(0);"
                          ><img
                            class="img-fluid me-2"
                            :src="require(`@/assets/admin_img/company/${record.Logo}`)"
                            alt="User Image"
                        /></a>
                      </h2>
                    </template>
                    <template v-else-if="column.key === 'inputbox'">
                      <div class="form-check custom-checkbox">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          id="customCheck5"
                        />
                        <label class="form-check-label" for="customCheck5"></label>
                      </div>
                    </template>
                    <template v-else-if="column.key === 'Companyname'">
                      <h2 class="table-avatar">
                        <a href="javascript:void(0);">{{ record.Companyname }}</a>
                      </h2>
                    </template>
                    <template v-else-if="column.key === 'Primarycontent'">
                      <h2 class="table-avatar">
                        <router-link to="/admin/profile">
                          {{ record.Primarycontact }}
                        </router-link>
                      </h2>
                    </template>
                    <template v-else-if="column.key === 'Status'">
                      <a href="javascript:;" :class="record.Class">{{ record.Status }}</a>
                    </template>
                    <template v-else-if="column.key === 'action'">
                      <div class="text-end">
                        <a
                          href="javascript:void(0);"
                          class="btn btn-sm btn-secondary me-2"
                          data-bs-toggle="modal"
                          data-bs-target="#add-category"
                          ><i class="far fa-edit"></i
                        ></a>
                        <a
                          href="javascript:void(0);"
                          class="btn btn-sm btn-danger"
                          data-bs-toggle="modal"
                          data-bs-target="#delete_category"
                          ><i class="far fa-trash-alt"></i
                        ></a>
                      </div>
                    </template>
                  </template>
                </a-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Wrapper -->
  </div>
  <modelpopup />
  <delete />
</template>

<script>
const columns = [
  {
    title: "#",
    key: "inputbox",
    sorter: true,
  },
  {
    title: "ID",
    dataIndex: "ID",
    sorter: {
      compare: (a, b) => {
        a = a.ID.toLowerCase();
        b = b.ID.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Logo",
    dataIndex: "Logo",
    key: "Logo",
    sorter: {
      compare: (a, b) => {
        a = a.Logo.toLowerCase();
        b = b.Logo.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Company name",
    dataIndex: "Companyname",
    sorter: {
      compare: (a, b) => {
        a = a.Companyname.toLowerCase();
        b = b.Companyname.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Primary contact",
    dataIndex: "Primarycontact",
    key: "Primarycontact",
    sorter: {
      compare: (a, b) => {
        a = a.Primarycontact.toLowerCase();
        b = b.Primarycontact.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "website",
    dataIndex: "website",
    sorter: {
      compare: (a, b) => {
        a = a.website.toLowerCase();
        b = b.website.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Total Projects",
    dataIndex: "TotalProjects",
    sorter: true,
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "Status",
    sorter: {
      compare: (a, b) => {
        a = a.Status.toLowerCase();
        b = b.Status.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Actions",
    key: "action",
    sorter: true,
  },
];

const data = [
  {
    FIELD1: "",
    ID: "C1",
    Logo: "img-1.png",
    Companyname: "Focused Holistic Hardware",
    Primarycontact: "Emily Smith",
    website: "https://focusedhardware.de",
    TotalProjects: 120,
    Class: "btn btn-enable",
    Status: "Enabled",
    Actions: "",
  },
  {
    FIELD1: "",
    ID: "C2",
    Logo: "img-2.png",
    Companyname: "Phased Actuating Interface",
    Primarycontact: "Joshuah Runolfsdottir",
    website: "https://phasedactuating.com",
    TotalProjects: 132,
    Class: "btn btn-disable",
    Status: "Disable",
    Actions: "",
  },
  {
    FIELD1: "",
    ID: "C3",
    Logo: "img-3.png",
    Companyname: "Intuitive Global Encoding",
    Primarycontact: "Amy Wilkinson",
    website: "https://globalencoding.com",
    TotalProjects: 90,
    Class: "btn btn-enable",
    Status: "Enabled",
    Actions: "",
  },
  {
    FIELD1: "",
    ID: "C4",
    Logo: "img-4.png",
    Companyname: "Visionary Scalable Alliance",
    Primarycontact: "Jimmy Richardson",
    website: "https://visionaryscalable.com",
    TotalProjects: 110,
    Class: "btn btn-disable",
    Status: "Disable",
    Actions: "",
  },
  {
    FIELD1: "",
    ID: "C5",
    Logo: "img-5.png",
    Companyname: "Self-Enabling Neutral Matrix",
    Primarycontact: "Damon Cohn",
    website: "https://neutralmatrix.com",
    TotalProjects: 158,
    Class: "btn btn-disable",
    Status: "Disable",
    Actions: "",
  },
  {
    FIELD1: "",
    ID: "C6",
    Logo: "img-6.png",
    Companyname: "Amaze Tech",
    Primarycontact: "Jacqueline Daye",
    website: "https://amazetech.de",
    TotalProjects: 120,
    Class: "btn btn-enable",
    Status: "Enabled",
    Actions: "",
  },
  {
    FIELD1: "",
    ID: "C7",
    Logo: "img-7.png",
    Companyname: "Park Inc",
    Primarycontact: "Tony Ingle",
    website: "https://parkinc.de",
    TotalProjects: 120,
    Class: "btn btn-disable",
    Status: "Disable",
    Actions: "",
  },
  {
    FIELD1: "",
    ID: "C8",
    Logo: "img-8.png",
    Companyname: "Tech Zone",
    Primarycontact: "Albert Boone",
    website: "https://techzone.de",
    TotalProjects: 120,
    Class: "btn btn-enable",
    Status: "Enabled",
    Actions: "",
  },
  {
    FIELD1: "",
    ID: "C9",
    Logo: "img-9.png",
    Companyname: "ABC Software",
    Primarycontact: "Kathleen Kaiser",
    website: "https://abcsoftware.de",
    TotalProjects: 120,
    Class: "btn btn-enable",
    Status: "Enabled",
    Actions: "",
  },
  {
    FIELD1: "",
    ID: "C10",
    Logo: "img-10.png",
    Companyname: "Host Technologies",
    Primarycontact: "Mickey Bernier",
    website: "https://hosttechnologies.de",
    TotalProjects: 120,
    Class: "btn btn-disable",
    Status: "Disable",
    Actions: "",
  },
];

import { ref } from "vue";
const currentDate = ref(new Date());
export default {
  data() {
    return {
      title: "Providers",
      text: "Home",
      text1: "Providers",
      columns,
      data,
      filterInputsDisplay: "none",
      startdate: currentDate,
    };
  },
  methods: {
    toggleFilterInputs() {
      this.filterInputsDisplay = this.filterInputsDisplay === "none" ? "block" : "none";
    },
  },
};
</script>
