<template>
  <div class="col-lg-6 report-btns">
    <ul
      class="project-report"
      v-bind:class="{ active: currentPath == 'reports' || currentPath == 'reports' }"
    >
      <li :class="currentPath == 'reports' ? 'active-project' : 'notactive'">
        <router-link to="/admin/reports">Projects</router-link>
      </li>
      <li :class="currentPath == 'project-bidding' ? 'active-project' : 'notactive'">
        <router-link to="/admin/project-bidding">Bidding</router-link>
      </li>
      <li :class="currentPath == 'project-invoice' ? 'active-project' : 'notactive'">
        <router-link to="/admin/project-invoice">Invoices</router-link>
      </li>
      <li
        :class="
          currentPath == 'project-earnings' ||
          currentPath == 'earning-employer' ||
          currentPath == 'earning-freelancer'
            ? 'active-project'
            : 'notactive'
        "
      >
        <router-link to="/admin/project-earnings">Earnings</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    currentPath() {
      return this.$route.name;
    },
    returnMenu() {
      return (
        this.$route.name == "reports" ||
        this.$route.name == "project-bidding" ||
        this.$route.name == "project-invoice" ||
        this.$route.name == "project-earnings"
      );
    },
  },
};
</script>
