<template>
  <!-- Home Banner -->
  <section class="section home-banner home-five row-middle">
				<div class="container">
					<div class="row align-items-center justify-content-center">
						<div class="col-lg-12 ">
							<div class="banner-blk-five justify-content-center" >
								<div class="banner-content text-center aos" data-aos="fade-up">
								
									<h1>Get the Perfect Developers & Projects</h1>
									<p>With the world's #1 Developers Marketplace</p>
									<form class="form"  name="store" id="store" method="post" action="project">
										<div class="form-inner">
											<div class="input-group">
												<span class="drop-detail">
                          <vue-select
                            :options="HomeFive"
                            id="homefive"
                            placeholder="Projects"
                          />
												</span>
												<input type="email" class="form-control" placeholder="Keywords">
												<button class="btn btn-primary sub-btn" type="submit">Search</button>
											</div>
										</div>
									</form>
								
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<!-- /Home Banner -->
  <div class="trusted-by-section">
    <span>Trusted By :</span>
    <ul>
      <li>
        <img src="@/assets/img/icon/trusted1.svg" alt="Img" />
      </li>
      <li>
        <img src="@/assets/img/icon/trusted2.svg" alt="Img" />
      </li>
      <li>
        <img src="@/assets/img/icon/trusted3.svg" alt="Img" />
      </li>
      <li>
        <img src="@/assets/img/icon/trusted4.svg" alt="Img" />
      </li>
      <li>
        <img src="@/assets/img/icon/trusted5.svg" alt="Img" />
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  data() {
    return {
      HomeFive: ["Projects", "Freelancers"],
    };
  },
};
</script>
