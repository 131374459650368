<template>
  <li class="dropdown">
    <a data-bs-toggle="dropdown" href="javascript:void(0);"
      ><img src="@/assets/img/icon/notification-bell-icon.svg" alt="Img"
    /></a>
    <div class="dropdown-menu notifications">
      <div class="topnav-dropdown-header">
        <div class="notification-title">
          Notifications<span class="msg-count-badge">2</span>
        </div>
        <a href="javascript:void(0)" class="clear-noti d-flex align-items-center"
          >Mark all as read <i class="fe fe-check-circle"></i
        ></a>
      </div>
      <div class="noti-content">
        <ul class="notification-list">
          <li class="notification-message">
            <router-link to="/notification">
              <div class="d-flex">
                <span class="avatar avatar-md active">
                  <img
                    class="avatar-img rounded-circle"
                    alt="avatar-img"
                    src="@/assets/img/avatar/avatar-2.jpg"
                  />
                </span>
                <div class="media-body">
                  <span class="noti-title">Edward Curr</span>
                  <p class="noti-details">
                    Notifications inform you when someone likes, reacts
                  </p>
                  <p class="noti-time">
                    <span class="notification-time">Yesterday at 11:42 PM</span>
                  </p>
                </div>
              </div>
            </router-link>
          </li>
          <li class="notification-message">
            <router-link to="/notification">
              <div class="d-flex">
                <span class="avatar avatar-md active">
                  <img
                    class="avatar-img rounded-circle"
                    alt="avatar-img"
                    src="@/assets/img/avatar/avatar-1.jpg"
                  />
                </span>
                <div class="media-body">
                  <span class="noti-title">Maria Hill</span>
                  <p class="noti-details">
                    Notifications alert you to new messages in your Kofejob inbox.
                  </p>
                  <div class="notification-btn">
                    <span class="btn btn-primary">Accept</span>
                    <span class="btn btn-outline-primary">Reject</span>
                  </div>
                  <p class="noti-time">
                    <span class="notification-time">Today at 9:42 AM</span>
                  </p>
                </div>
              </div>
            </router-link>
          </li>

          <li class="notification-message">
            <router-link to="/notification">
              <div class="d-flex">
                <span class="avatar avatar-md">
                  <img
                    class="avatar-img rounded-circle"
                    alt="avatar-img"
                    src="@/assets/img/avatar/avatar-3.jpg"
                  />
                </span>
                <div class="media-body">
                  <span class="noti-title">Maria Hill</span>
                  <p class="noti-details">
                    Notifications alert you to new messages in your Kofejob inbox.
                  </p>
                  <p class="noti-time">
                    <span class="notification-time">Yesterday at 5:42 PM</span>
                  </p>
                </div>
              </div>
            </router-link>
          </li>
          <li class="notification-message">
            <router-link to="/notification">
              <div class="d-flex">
                <span class="avatar avatar-md">
                  <img
                    class="avatar-img rounded-circle"
                    alt="avatar-img"
                    src="@/assets/img/avatar/avatar-4.jpg"
                  />
                </span>
                <div class="media-body">
                  <span class="noti-title">Edward Curr</span>
                  <p class="noti-details">
                    Notifications inform you when someone likes, reacts
                  </p>
                  <p class="noti-time">
                    <span class="notification-time">Last Wednesday at 11:15 AM</span>
                  </p>
                </div>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
      <div class="topnav-dropdown-footer text-center">
        <router-link to="/notification">View All Notification</router-link>
      </div>
    </div>
  </li>
</template>
