<template>
  <div class="dashboard-page">
    <div class="content">
      <div class="container">
        <div class="row">
          <div class="col-xl-3 col-lg-4 theiaStickySidebar">
            <freelancer-sidebar></freelancer-sidebar>
          </div>

          <div class="col-xl-9 col-lg-8">
            <div class="page-title">
              <h3>Manage Projects</h3>
            </div>
            <nav class="user-tabs mb-4">
              <ul class="nav nav-tabs nav-tabs-bottom nav-justified">
                <li class="nav-item">
                  <router-link class="nav-link" to="freelancer-view-project-detail"
                    >Overview & Discussions</router-link
                  >
                </li>
                <li class="nav-item">
                  <router-link class="nav-link" to="freelancer-milestones"
                    >Milestones</router-link
                  >
                </li>
                <li class="nav-item">
                  <router-link class="nav-link" to="freelancer-task">Tasks</router-link>
                </li>
                <li class="nav-item">
                  <router-link class="nav-link" to="freelancer-files">Files</router-link>
                </li>
                <li class="nav-item">
                  <router-link class="nav-link active" to="freelancer-payment"
                    >Payments</router-link
                  >
                </li>
              </ul>
            </nav>
            <!-- project list -->
            <div class="my-projects-view">
              <div class="row">
                <div class="col-lg-12">
                  <div
                    class="title-head d-flex justify-content-between align-items-center mb-4"
                  >
                    <h4 class="mb-0">Payments</h4>
                  </div>

                  <div class="table-responsive table-box manage-projects-table">
                    <table class="table table-center table-hover datatable no-sort">
                      <thead class="thead-pink">
                        <tr>
                          <th>Name</th>
                          <th>Type of Payment</th>
                          <th>Budget</th>
                          <th>Status</th>
                          <th>Date Paid</th>
                          <th>Invoice</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="record in Freelancer_Payment" :key="record.id">
                          <td>{{ record.Name }}</td>
                          <td>Milestone</td>
                          <td>{{ record.Budget }}</td>
                          <td>
                            <span class="badge badge-pill bg-success-light"
                              >Completed</span
                            >
                          </td>
                          <td>29 Sep 2023</td>
                          <td>
                            <div class="action-table-data">
                              <div class="edit-delete-action m-0">
                                <a
                                  href="#edit-milestone"
                                  class="download-icon"
                                  data-bs-toggle="modal"
                                  ><i class="feather-download"></i
                                ></a>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <!-- project list -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <freelancer-payment-modal></freelancer-payment-modal>
</template>
<script>
import Freelancer_Payment from "@/assets/json/freelancer-payment.json";
export default {
  data() {
    return {
      Freelancer_Payment: Freelancer_Payment,
    };
  },
};
</script>
