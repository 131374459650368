<template>
    <section class="section review">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="work-set-image">
                        <div class="work-set">
                            <div class="recent-pro-img aos" data-aos="zoom-in" data-aos-duration="1000">
                                <img src="@/assets/img/work1.jpg" alt="Img" class="img-fluid ">
                            </div>
                        </div>
                        <div class="work-sets">
                            <div class="recent-pro-img">
                                <img src="@/assets/img/work2.jpg" alt="Img" class="img-fluid mb-2 aos" data-aos="zoom-in"
                                    data-aos-duration="2000">
                                <img src="@/assets/img/work3.jpg" alt="Img" class="img-fluid aos" data-aos="zoom-in"
                                    data-aos-duration="2500">
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-lg-6">
                    <div class="aos " data-aos="fade-up">
                        <div class="demand-professional">
                            <h2>Get Expert in Less Time and Our Work Process</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                                labore et dolore magna aliqua. </p>
                        </div>
                        <div class="demand-post-job">
                            <div class="demand-post-img">
                                <img src="@/assets/img/recent-icon-01.svg" alt="Img" class="img-fluid">
                            </div>
                            <div class="demand-content">
                                <h6>Post a job</h6>
                                <p>Publish the job posting on your selected platforms. Follow the specific submission
                                    process for each platform.</p>
                            </div>
                        </div>
                        <div class="demand-post-job">
                            <div class="demand-post-img">
                                <img src="@/assets/img/recent-icon-02.svg" alt="Img" class="img-fluid">
                            </div>
                            <div class="demand-content">
                                <h6>Hire Freelancers</h6>
                                <p>Depending on the platform, you can either wait for freelancers to apply or invite
                                    specific freelancers to submit proposals.</p>
                            </div>
                        </div>
                        <div class="demand-post-job">
                            <div class="demand-post-img">
                                <img src="@/assets/img/recent-icon-03.svg" alt="Img" class="img-fluid">
                            </div>
                            <div class="demand-content">
                                <h6>Get Work Done</h6>
                                <p>Utilize productivity tools and apps to help you stay organized, manage tasks, and set
                                    reminders.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>


    <!-- Projects -->
    <section class="section projects">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-12 mx-auto">
                    <div class="section-header text-center aos" data-aos="fade-up">
                        <h2 class="header-title">Get Inspired By Development Projects</h2>
                        <p>High performing solutions to your requests</p>
                    </div>
                </div>
            </div>
            <div class="row">

                <!--- Project Item  -->
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="project-item aos" data-aos="fade-up">
                        <div class="project-img">
                            <router-link to="/freelancer/project"><img src="@/assets/img/project/project-01.jpg" alt="Img"
                                    class="img-fluid"></router-link>
                            <h6>Android Apps</h6>
                        </div>
                        <div class="d-flex justify-content-between align-items-start">
                            <div class="project-content">
                                <h4>45</h4>
                                <h5><router-link to="/freelancer/project">Projects</router-link></h5>
                            </div>
                            <div class="pro-icon">
                                <div class="project-icon"></div>
                            </div>
                            <div class="project-content">
                                <h4>20</h4>
                                <h5><router-link to="/freelancer/project">Developers</router-link></h5>
                            </div>
                        </div>
                    </div>
                </div>
                <!--- /Project Item  -->

                <!--- Project Item  -->
                <div class="col-lg-3 col-md-6 col-sm-6 d-flex">
                    <div class="project-item aos" data-aos="fade-up">
                        <div class="project-img">
                            <router-link to="/freelancer/project"><img src="@/assets/img/project/project-02.jpg" alt="Img"
                                    class="img-fluid"></router-link>
                            <h6>Laravel</h6>
                        </div>
                        <div class="d-flex justify-content-between align-items-start">
                            <div class="project-content">
                                <h4>52</h4>
                                <h5><router-link to="/freelancer/project">Projects</router-link></h5>
                            </div>
                            <div class="pro-icon">
                                <div class="project-icon"></div>
                            </div>
                            <div class="project-content">
                                <h4>17</h4>
                                <h5><router-link to="/freelancer/project">Developers</router-link></h5>
                            </div>
                        </div>
                    </div>
                </div>
                <!--- /Project Item  -->

                <!--- Project-Item  -->
                <div class="col-lg-3 col-md-6 col-sm-6 d-flex">
                    <div class="project-item aos" data-aos="fade-up">
                        <div class="project-img">
                            <router-link to="/freelancer/project"><img src="@/assets/img/project/project-03.jpg" alt="Img"
                                    class="img-fluid"></router-link>
                            <h6>React</h6>
                        </div>
                        <div class="d-flex justify-content-between align-items-start">
                            <div class="project-content">
                                <h4>58</h4>
                                <h5><router-link to="/freelancer/project">Projects</router-link></h5>
                            </div>
                            <div class="pro-icon">
                                <div class="project-icon"></div>
                            </div>
                            <div class="project-content">
                                <h4>12</h4>
                                <h5><router-link to="/freelancer/project">Developers</router-link></h5>
                            </div>
                        </div>
                    </div>
                </div>
                <!--- /Project Item  -->

                <!--- Project Item  -->
                <div class="col-lg-3 col-md-6 col-sm-6 d-flex">
                    <div class="project-item aos" data-aos="fade-up">
                        <div class="project-img">
                            <router-link to="/freelancer/project"><img src="@/assets/img/project/project-04.jpg" alt="Img"
                                    class="img-fluid"></router-link>
                            <h6>Angular</h6>
                        </div>
                        <div class="d-flex justify-content-between align-items-start">
                            <div class="project-content">
                                <h4>26</h4>
                                <h5><router-link to="/freelancer/project">Projects</router-link></h5>
                            </div>
                            <div class="pro-icon">
                                <div class="project-icon"></div>
                            </div>
                            <div class="project-content">
                                <h4>19</h4>
                                <h5><router-link to="/freelancer/project">Developers</router-link></h5>
                            </div>
                        </div>
                    </div>
                </div>
                <!--- /Project Item  -->

                <!--- Project Item  -->
                <div class="col-lg-3 col-md-6 col-sm-6 d-flex">
                    <div class="project-item aos" data-aos="fade-up">
                        <div class="project-img">
                            <router-link to="/freelancer/project"><img src="@/assets/img/project/project-05.jpg" alt="Img"
                                    class="img-fluid"></router-link>
                            <h6>.NET</h6>
                        </div>
                        <div class="d-flex justify-content-between align-items-start">
                            <div class="project-content">
                                <h4>45</h4>
                                <h5><router-link to="/freelancer/project">Projects</router-link></h5>
                            </div>
                            <div class="pro-icon">
                                <div class="project-icon"></div>
                            </div>
                            <div class="project-content">
                                <h4>20</h4>
                                <h5><router-link to="/freelancer/project">Developers</router-link></h5>
                            </div>
                        </div>
                    </div>
                </div>
                <!--- /Project Item  -->

                <!--- Project Item  -->
                <div class="col-lg-3 col-md-6 col-sm-6 d-flex">
                    <div class="project-item aos" data-aos="fade-up">
                        <div class="project-img">
                            <router-link to="/freelancer/project"><img src="@/assets/img/project/project-06.jpg" alt="Img"
                                    class="img-fluid"></router-link>
                            <h6>Java</h6>
                        </div>
                        <div class="d-flex justify-content-between align-items-start">
                            <div class="project-content">
                                <h4>49</h4>
                                <h5><router-link to="/freelancer/project">Projects</router-link></h5>
                            </div>
                            <div class="pro-icon">
                                <div class="project-icon"></div>
                            </div>
                            <div class="project-content">
                                <h4>36</h4>
                                <h5><router-link to="/freelancer/project">Developers</router-link></h5>
                            </div>
                        </div>
                    </div>
                </div>
                <!--- /Project Item  -->

                <!--- Project Item  -->
                <div class="col-lg-3 col-md-6 col-sm-6 d-flex">
                    <div class="project-item aos" data-aos="fade-up">
                        <div class="project-img">
                            <router-link to="/freelancer/project"><img src="@/assets/img/project/project-07.jpg" alt="Img"
                                    class="img-fluid"></router-link>
                            <h6>Python</h6>
                        </div>
                        <div class="d-flex justify-content-between align-items-start">
                            <div class="project-content">
                                <h4>73</h4>
                                <h5><router-link to="/freelancer/project">Projects</router-link></h5>
                            </div>
                            <div class="pro-icon">
                                <div class="project-icon"></div>
                            </div>
                            <div class="project-content">
                                <h4>59</h4>
                                <h5><router-link to="/freelancer/project">Developers</router-link></h5>
                            </div>
                        </div>
                    </div>
                </div>
                <!--- /Project Item  -->

                <!--- Project-Item  -->
                <div class="col-lg-3 col-md-6 col-sm-6 d-flex">
                    <div class="project-item aos" data-aos="fade-up">
                        <div class="project-img">
                            <router-link to="/freelancer/project"><img src="@/assets/img/project/project-08.jpg" alt="Img"
                                    class="img-fluid"></router-link>
                            <h6>PHP</h6>
                        </div>
                        <div class="d-flex justify-content-between align-items-start">
                            <div class="project-content">
                                <h4>61</h4>
                                <h5><router-link to="/freelancer/project">Projects </router-link></h5>
                            </div>
                            <div class="pro-icon">
                                <div class="project-icon"></div>
                            </div>
                            <div class="project-content">
                                <h4>53</h4>
                                <h5><router-link to="/freelancer/project">Developers</router-link></h5>
                            </div>
                        </div>
                    </div>
                </div>
                <!--- /Project Item  -->

            </div>
            <div class="row">
                <div class="col-md-12 text-center">
                    <div class="see-all aos" data-aos="fade-up">
                        <router-link to="/freelancer/project" class="btn all-btn">View More Projects</router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- /Projects -->
</template>