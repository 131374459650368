<template>
  <div class="modal fade success-modal hire-modal" id="success-verified">
    <div class="modal-dialog modal-dialog-centered modal-md">
      <div class="modal-content">
        <div class="modal-body pt-4">
          <div class="success-msg-content text-center">
            <h4>Verification Submitted Successfully</h4>
            <p>You will be verified once, admin approves your verification</p>
            <a
              href="javascript:void(0);"
              data-bs-dismiss="modal"
              class="btn btn-primary mt-3"
              >Okay</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
