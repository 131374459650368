<template>
  <!-- Feature Developers -->
  <section class="section feaure-for-developer">
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-sm-12 col-12 mx-auto">
          <div class="section-header section-header-four section-locate aos" data-aos="fade-up">
            <div>
              <h2 class="header-title mb-0">We have over 1400+ Developers</h2>
            </div>
          </div>
        </div>
      </div>

      <div id="dev-slider" class="owl-carousel popular-slider developers-slider owl-theme  aos" data-aos="fade-up">
        <Carousel :wrap-around="true" :settings="settings" :breakpoints="breakpoints">
          <Slide v-for="item in IndexFourDevelopers" :key="item.id">
            <div class="project-item project-item-feature aos" data-aos="fade-up">
              <router-link to="/employers/developer-details">
                <div class="project-img heart-blk">
                  <img :src="require(`@/assets/img/developer/${item.Img}`)" alt="Img" class="img-fluid">
                  <span class="hour-dollr develop-dollr">{{ item.Price }} <small>/ hr</small></span>
                </div>
              </router-link>
              <div class="developer-detail-box">
                <div class="developer-detail-card">
                  <div>
                    <div class="d-flex align-items-center">
                      <h4 class="mb-0"><router-link to="/employers/developer-details">{{ item.Name }}</router-link></h4><img
                        class="img-fluid ms-1" src="@/assets/img/icon/verified-badge-fill.svg" alt="Img">
                    </div>
                    <p class="mb-0">{{ item.Designation }}</p>
                  </div>
                  <div>
                    <a href="javascript:void(0);" class="bookmark-check"><i class="fa-regular fa-bookmark"></i></a>
                  </div>
                </div>
                <div class="rate-block">
                  <div class="rating">
                    <i class="fas fa-star filled"></i>
                    <i class="fas fa-star filled"></i>
                    <i class="fas fa-star filled"></i>
                    <i class="fas fa-star filled"></i>
                    <i class="fas fa-star"></i>
                    <span class="average-rating">{{ item.Rating }}</span>
                  </div>
                  <div>
                    <router-link to="/employers/developer-details"><i class="feather-arrow-right"></i></router-link>
                  </div>
                </div>
              </div>

            </div>
          </Slide>
          <template #addons>
            <Navigation />
          </template>
        </Carousel>
      </div>
      <div class="home-four-viewall">
        <router-link to="/employers/developer-details" class="home-four-viewall-btn">View More Developers <i
            class="feather-arrow-right ms-2"></i></router-link>
      </div>
    </div>
  </section>
  <!-- Feature Developers -->
</template>
<script>
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import IndexFourDevelopers from "@/assets/json/indexfourdevelopers.json";
export default {
  data() {
    return {
      IndexFourDevelopers: IndexFourDevelopers,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      breakpoints: {
        575: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        767: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        991: {
          itemsToShow: 3,
          snapAlign: "center",
        },
        1024: {
          itemsToShow: 4,
          snapAlign: "start",
        },
      },
    };
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
};
</script>
