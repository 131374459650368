<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>

  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content container-fluid">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <h3 class="page-title">Form Mask</h3>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <!-- Form Mask -->
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-body">
              <div class="card-header pt-0 mb-4">
                <h5 class="card-title">Form Mask</h5>
                <p class="card-text">
                  Input masks can be used to force the user to enter data conform a
                  specific format. Unlike validation, the user can't enter any other key
                  than the ones specified by the mask.
                </p>
              </div>
              <form action="javascript:;">
                <div class="form-group">
                  <label>Phone</label>
                  <MaskedInput
                    v-model="phone"
                    class="form-control"
                    :mask="'(999) 999-9999'"
                    :value="phone"
                  />
                  <span class="form-text text-muted">(999) 999-9999</span>
                </div>
                <div class="form-group">
                  <label>Date</label>
                  <MaskedInput
                    v-model="date"
                    class="form-control"
                    :mask="'99 / 99 / 9999'"
                    :value="date"
                  />
                  <span class="form-text text-muted">dd/mm/yyyy</span>
                </div>
                <div class="form-group">
                  <label>SSN field 1</label>
                  <MaskedInput
                    v-model="ssn"
                    class="form-control"
                    :mask="'999-99-9999'"
                    :value="ssn"
                  />
                  <span class="form-text text-muted">e.g "999-99-9999"</span>
                </div>
                <div class="form-group">
                  <label>Phone field + ext.</label>
                  <MaskedInput
                    v-model="phoneExt"
                    class="form-control"
                    :mask="'(999) 999-9999? x99999'"
                    :value="phoneExt"
                  />
                  <span class="form-text text-muted">+40 999 999 999</span>
                </div>
                <div class="form-group">
                  <label>Product Key</label>
                  <MaskedInput
                    v-model="product"
                    class="form-control"
                    :mask="'a*-999-a999'"
                    :value="product"
                  />
                  <span class="form-text text-muted">e.g a*-999-a999</span>
                </div>
                <div class="form-group">
                  <label>Currency</label>
                  <MaskedInput
                    v-model="currency"
                    class="form-control"
                    :mask="'999,999,999.99'"
                    :value="currency"
                  />
                  <span class="form-text text-muted">$ 999,999,999.99</span>
                </div>
                <div class="form-group">
                  <label>Eye Script</label>
                  <MaskedInput
                    v-model="eyescript"
                    class="form-control"
                    :mask="'~9.99 ~9.99 999'"
                    :value="eyescript"
                  />
                  <span class="form-text text-muted">~9.99 ~9.99 999</span>
                </div>
                <div class="form-group">
                  <label>Percent</label>
                  <MaskedInput
                    v-model="pct"
                    class="form-control"
                    :mask="'99%'"
                    :value="pct"
                  />
                  <span class="form-text text-muted">e.g "99%"</span>
                </div>
                <div class="form-group mb-0">
                  <label>Credit Card Number</label>
                  <MaskedInput
                    v-model="ccn"
                    class="form-control"
                    :mask="'9999 9999 9999 9999'"
                    :value="ccn"
                  />
                  <span class="form-text text-muted">e.g "999.999.999.9999"</span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- /Form Mask -->
    </div>
  </div>
  <!-- /Page Wrapper -->
</template>

<script>
import MaskedInput from "./MaskedInput.vue";

export default {
  components: {
    MaskedInput,
  },
  data() {
    return {
      phone: "",
      date: "",
      ssn: "",
      phoneExt: "",
      product: "",
      currency: "",
      eyescript: "",
      pct: "",
      ccn: "",
    };
  },
};
</script>

<style>
masked-input {
  height: 40px;
}
</style>
