<template>
    <div class="subscribe-employe users-list">
        <ul v-bind:class="{active: currentPath == 'users' || currentPath == 'users',}">
            <li :class="currentPath == 'users' ? 'active' : 'notactive'">
                <router-link to="/admin/users">All Freelancer </router-link>
            </li>
            <li :class="currentPath == 'user-active' ? 'active' : 'notactive'">
                <router-link to="/admin/user-active">Active Freelancer</router-link>
            </li>
            <li :class="currentPath == 'user-inactive' ? 'active' : 'notactive'">
                <router-link to="/admin/user-inactive">Inactive Freelancer</router-link>
            </li>
            <li :class="currentPath == 'user-suspended' ? 'active' : 'notactive'">
                <router-link to="/admin/user-suspended">Suspended</router-link>
            </li>
            <li :class="currentPath == 'user-administrator' ? 'active' : 'notactive'">
                <router-link to="/admin/user-administrator">Administrator</router-link>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    data() {
        return {
            
        }
    },
    computed: {
        currentPath() {
            return this.$route.name;
        },
        returnMenu() {
            return (
                this.$route.name == "users" ||
                this.$route.name == "user-active" ||
                this.$route.name == "user-inactive" ||
                this.$route.name == "user-suspended" ||
                this.$route.name == "user-administrator"
            );
        },
    },
}
</script>