<template>
    <!-- Home Banner -->
    <section class="section home-banner row-middle home-two">
        <div class="container">
            <div class="row align-items-center">
                <div class=" col-lg-6 col-md-12">
                    <div class="banner-content aos" data-aos="fade-up">
                        <div class="market-place">
                            <h3>With the world's #1 Developers Marketplace</h3>
                        </div>
                        <h1>Get the perfect <br>Developers & Projects</h1>
                        <form class="form" name="store" id="store" method="post" action="project">
                            <div class="form-inner">
                                <div class="input-group">
                                    <span class="drop-detail">
                                        <vue-select 
                                            :options="Work"
                                            id="work"
                                            placeholder="Projects"
                                        />
                                    </span>
                                    <input type="email" class="form-control" placeholder="Keywords">
                                    <button class="btn btn-primary sub-btn" type="submit">Search</button>
                                </div>
                            </div>
                        </form>
                        <div class="home-count">
                            <ul class="nav">
                                <li class="course-count">
                                    <vue3-autocounter class="counter-up" ref="counter" :startAmount="1000" :delay="3" :endAmount="9207"
                                    :duration="5" separator="," :autoinit="true" />
                                    <span class="list-count">Freelance developers</span>
                                </li>
                                <li class="course-count">
                                    <vue3-autocounter class="counter-up" ref="counter" :startAmount="1000" :delay="3" :endAmount="6000"
                                    :duration="5" separator="," :autoinit="true" />
                                    <span>+</span>
                                    <span class="list-count">Projects Added</span>
                                </li>
                                <li class="course-count">
                                    <vue3-autocounter class="counter-up" ref="counter" :startAmount="1000" :delay="3" :endAmount="919207"
                                    :duration="5" separator="," :autoinit="true" />
                                    <span class="list-count">Completed projects</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-lg-5">
                    <div class="banner-two-img aos" data-aos="fade-up">
                        <img src="@/assets/img/home-two-banner-bg-01.png" class="img-fluid trusted-user-img aos"
                            data-aos="zoom-in" alt="banner" data-aos-duration="3000">
                        <img src="@/assets/img/home-two-banner-bg-02.png" class="img-fluid best-marketing-img aos"
                            data-aos="zoom-in" alt="banner" data-aos-duration="3000">
                    </div>
                </div>
            </div>
            <!-- <div class="banner-top-bottom">
						<a href="#bottom-scroll"><i class="feather-arrow-down"></i></a>
					</div> -->
        </div>
    </section>
    <!-- /Home Banner -->

    <!-- Our Feature -->
    <section class="section update-project">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-sm-12 col-12 mx-auto">
                    <div class="section-header section-header-two text-center aos" data-aos="fade-up">
                        <h2 class="header-title">Recently Updated <span>Projects for you</span></h2>
                        <p>Get work done in over 60 different categories</p>
                    </div>
                </div>
            </div>
            <div class="row">

                <!-- Updated Item -->
                <div class="col-xl-3 col-md-6">
                    <div class="aos" data-aos="fade-up">
                        <router-link to="/freelancer/project" class="update-project-blk move-box ">
                            <div class="update-content">
                                <h6>Website design</h6>
                                <p>400 Projects Available</p>
                            </div>
                            <div class="update-icon-end">
                                <i class="feather-arrow-right"></i>
                            </div>
                        </router-link>
                    </div>
                </div>
                <!-- /Updated Item -->

                <!-- Updated Item -->
                <div class="col-xl-3 col-md-6">
                    <div class="aos" data-aos="fade-up">
                        <router-link to="/freelancer/project" class="update-project-blk move-box">
                            <div class="update-content">
                                <h6>Python</h6>
                                <p>60 Projects Available</p>
                            </div>
                            <div class="update-icon-end">
                                <i class="feather-arrow-right"></i>
                            </div>
                        </router-link>
                    </div>
                </div>
                <!-- /Updated Item -->

                <!-- Updated Item -->
                <div class="col-xl-3 col-md-6">
                    <div class="aos" data-aos="fade-up">
                        <router-link to="/freelancer/project" class="update-project-blk move-box">
                            <div class="update-content">
                                <h6>Photoshop</h6>
                                <p>221 Projects Available</p>
                            </div>
                            <div class="update-icon-end">
                                <i class="feather-arrow-right"></i>
                            </div>
                        </router-link>
                    </div>
                </div>
                <!-- /Updated Item -->

                <!-- Updated Item -->
                <div class="col-xl-3 col-md-6">
                    <div class="aos" data-aos="fade-up">
                        <router-link to="/freelancer/project" class="update-project-blk move-box">
                            <div class="update-content">
                                <h6>Android Apps</h6>
                                <p>40 Projects Available</p>
                            </div>
                            <div class="update-icon-end">
                                <i class="feather-arrow-right"></i>
                            </div>
                        </router-link>
                    </div>
                </div>
                <!-- /Updated Item -->

                <!-- Updated Item -->
                <div class="col-xl-3 col-md-6">
                    <div class="aos" data-aos="fade-up">
                        <router-link to="/freelancer/project" class="update-project-blk move-box">
                            <div class="update-content">
                                <h6>Software Architecture</h6>
                                <p>400 Projects Available</p>
                            </div>
                            <div class="update-icon-end">
                                <i class="feather-arrow-right"></i>
                            </div>
                        </router-link>
                    </div>
                </div>
                <!-- /Updated Item -->

                <!-- Updated Item -->
                <div class="col-xl-3 col-md-6">
                    <div class="aos" data-aos="fade-up">
                        <router-link to="/freelancer/project" class="update-project-blk move-box">
                            <div class="update-content">
                                <h6>Ecommerce</h6>
                                <p>400 Projects Available</p>
                            </div>
                            <div class="update-icon-end">
                                <i class="feather-arrow-right"></i>
                            </div>
                        </router-link>
                    </div>
                </div>
                <!-- /Updated Item -->

                <!-- Updated Item -->
                <div class="col-xl-3 col-md-6">
                    <div class="aos" data-aos="fade-up">
                        <router-link to="/freelancer/project" class="update-project-blk move-box">
                            <div class="update-content">
                                <h6>Banner Design</h6>
                                <p>400 Projects Available</p>
                            </div>
                            <div class="update-icon-end">
                                <i class="feather-arrow-right"></i>
                            </div>
                        </router-link>
                    </div>
                </div>
                <!-- /Updated Item -->

                <!-- Updated Item -->
                <div class="col-xl-3 col-md-6">
                    <div class="aos" data-aos="fade-up">
                        <router-link to="/freelancer/project" class="update-project-blk move-box">
                            <div class="update-content">
                                <h6>Link Building</h6>
                                <p>400 Projects Available</p>
                            </div>
                            <div class="update-icon-end">
                                <i class="feather-arrow-right"></i>
                            </div>
                        </router-link>
                    </div>
                </div>
                <!-- /Updated Item -->

                <!-- Updated Item -->
                <div class="col-xl-3 col-md-6">
                    <div class="aos" data-aos="fade-up">
                        <router-link to="/freelancer/project" class="update-project-blk move-box">
                            <div class="update-content">
                                <h6>JavaScript</h6>
                                <p>400 Projects Available</p>
                            </div>
                            <div class="update-icon-end">
                                <i class="feather-arrow-right"></i>
                            </div>
                        </router-link>
                    </div>
                </div>
                <!-- /Updated Item -->

                <!-- Updated Item -->
                <div class="col-xl-3 col-md-6">
                    <div class="aos" data-aos="fade-up">
                        <router-link to="/freelancer/project" class="update-project-blk move-box">
                            <div class="update-content">
                                <h6>Marketing</h6>
                                <p>400 Projects Available</p>
                            </div>
                            <div class="update-icon-end">
                                <i class="feather-arrow-right"></i>
                            </div>
                        </router-link>
                    </div>
                </div>
                <!-- /Updated Item -->

                <!-- Updated Item -->
                <div class="col-xl-3 col-md-6">
                    <div class="aos" data-aos="fade-up">
                        <router-link to="/freelancer/project" class="update-project-blk move-box">
                            <div class="update-content">
                                <h6>MYSQL</h6>
                                <p>400 Projects Available</p>
                            </div>
                            <div class="update-icon-end">
                                <i class="feather-arrow-right"></i>
                            </div>
                        </router-link>
                    </div>
                </div>
                <!-- /Updated Item -->

                <!-- Updated Item -->
                <div class="col-xl-3 col-md-6">
                    <div class="aos" data-aos="fade-up">
                        <router-link to="/freelancer/project" class="update-project-blk move-box">
                            <div class="update-content">
                                <h6>Illustration</h6>
                                <p>400 Projects Available</p>
                            </div>
                            <div class="update-icon-end">
                                <i class="feather-arrow-right"></i>
                            </div>
                        </router-link>
                    </div>
                </div>
                <!-- /Updated Item -->

                <div class="col-xl-12">
                    <div class="more-project text-center aos" data-aos="fade-up">
                        <router-link to="/freelancer/project" class="btn btn-primary">View More Projects</router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- /Our Feature -->

    <!-- Our Platform -->
    <section class="section platform">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <div class="plat-form-img aos" data-aos="fade-up">
                        <img src="@/assets/img/plat-form.png" alt="Img" class="img-fluid">
                    </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <div class="platform-group aos" data-aos="fade-up">
                        <h1>Discover Project Around your Location <span> In Our Platform</span></h1>
                        <h5>Get Inspired by Development Projects</h5>
                        <p>We Provide Stable Service With Experts Freelancers around the globe, are looking for work and
                            provide the best they have .Experience state-of-the-art marketplace platform with the Kofejob.
                            We combine the experience of our global community around the globe for a best marketplace theme.
                        </p>
                        <div class="market-place-btn platform-btn">
                            <router-link to="/freelancer/project" class="btn btn-primary market-project me-2">View Projects</router-link>
                            <router-link to="/freelancer/project" class="btn btn-primary project-post">Post a Project</router-link>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>
    <!-- /Our Platform -->
</template>

<script>
export default {
    data() {
        return {
            Work: ["Projects", "Freelancers"]
        }
    },
}
</script>