<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>

  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content report-box container-fluid">
      <!-- Page Header -->
      <div class="page-header subscribe-head">
        <div class="row align-items-center">
          <breadcrumbthree :title="title" :text="text" :text1="text1" />

          <div class="col-auto">
            <a
              class="btn add-user"
              href="javascript:;"
              data-bs-toggle="modal"
              data-bs-target="#add-category"
            >
              <i class="fas fa-plus"></i> Add Freelancer
            </a>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <div class="row">
        <div class="col-sm-12">
          <freelancertabset />

          <!-- Active User Header -->
          <div class="page-header user-active">
            <div class="row align-items-center">
              <div class="col">
                <h3 class="page-title">Inactive Freelancer</h3>
                <p>Total <span>25</span> Freelancer</p>
              </div>
              <div class="col-auto">
                <a href="javascript:;" class="btn export-btn me-1">
                  <img src="@/assets/admin_img/export.svg" alt="Image" /> Export
                </a>
                <a
                  class="btn filter-btn"
                  href="javascript:void(0);"
                  @click="toggleFilterInputs"
                  id="filter_search"
                >
                  <i class="fas fa-filter"></i>
                </a>
              </div>
            </div>
          </div>
          <!-- /Active User Header -->

          <!-- Search Filter -->
          <div
            class="card filter-card"
            :style="{ display: filterInputsDisplay }"
            id="filter_inputs"
          >
            <div class="card-body pb-0">
              <form action="javascript:;" method="post">
                <div class="row filter-row">
                  <div class="col-sm-6 col-md-3">
                    <div class="form-group">
                      <label>Name</label>
                      <input class="form-control" type="text" />
                    </div>
                  </div>
                  <div class="col-sm-6 col-md-3">
                    <div class="form-group">
                      <label>Email</label>
                      <input type="email" class="form-control" />
                    </div>
                  </div>
                  <div class="col-sm-6 col-md-3">
                    <div class="form-group">
                      <label>Expertise</label>
                      <input type="text" class="form-control" />
                    </div>
                  </div>
                  <div class="col-sm-6 col-md-3">
                    <div class="form-group">
                      <button class="btn btn-primary btn-block" type="submit">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <!-- /Search Filter -->

          <div class="card">
            <div class="card-body">
              <div class="showentries">
                <label
                  >Show
                  <select>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  entries
                </label>
                <div class="entries">
                  <span>Search: </span>
                  <input
                    type="search"
                    class="form-control form-control-sm ms-2"
                    placeholder=""
                    aria-controls="DataTables_Table_0"
                  />
                </div>
              </div>
              <div class="table-responsive">
                <a-table :columns="columns" :data-source="data">
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key === 'Freelancer'">
                      <div class="table-avatar user-profile">
                        <router-link to="/admin/profile"
                          ><img
                            class="avatar-img rounded-circle"
                            :src="require(`@/assets/admin_img/profiles/${record.Img}`)"
                            alt="User Image"
                          />
                        </router-link>
                        <div>
                          <h5>
                            <router-link to="/admin/profile">{{
                              record.Freelancer
                            }}</router-link>
                          </h5>
                          <p>{{ record.Email }}</p>
                        </div>
                      </div>
                    </template>
                    <template v-else-if="column.key === 'inputbox'">
                      <div class="form-check custom-checkbox">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          id="customCheck5"
                        />
                        <label class="form-check-label" for="customCheck5"></label>
                      </div>
                    </template>
                    <template v-else-if="column.key === 'Verified'">
                      <div class="verify-mail">
                        <i :class="record.Class"></i>{{ record.Verified }}
                      </div>
                    </template>
                    <template v-else-if="column.key === 'Status'">
                      <a href="javascript:;" class="user-inactive-btn">{{
                        record.Status
                      }}</a>
                    </template>
                    <template v-else-if="column.key === 'action'">
                      <div class="text-center three-dots">
                        <a
                          href="javascript:;"
                          class="dropdown-toggle nav-link"
                          data-bs-toggle="dropdown"
                          ><i class="fas fa-ellipsis-v"></i
                        ></a>
                        <div class="dropdown-menu user-menu-list">
                          <a class="dropdown-item" href="javascript:;"
                            ><img
                              class="me-2"
                              src="@/assets/admin_img/icon/icon-01.svg"
                              alt="Image"
                            />
                            View Details</a
                          >
                          <a class="dropdown-item" href="javascript:;"
                            ><img
                              class="me-2"
                              src="@/assets/admin_img/icon/icon-02.svg"
                              alt="Image"
                            />
                            Transaction</a
                          >
                          <a class="dropdown-item" href="javascript:;"
                            ><img
                              class="me-2"
                              src="@/assets/admin_img/icon/icon-03.svg"
                              alt="Image"
                            />
                            Reset Password</a
                          >
                          <a class="dropdown-item" href="javascript:;"
                            ><img
                              class="me-2"
                              src="@/assets/admin_img/icon/icon-04.svg"
                              alt="Image"
                            />
                            Suspend user</a
                          >
                          <a class="dropdown-item" href="javascript:;"
                            ><i class="feather feather-edit me-2"></i> Edit</a
                          >
                          <a class="dropdown-item mb-0" href="javascript:;"
                            ><i class="feather feather-trash-2 me-2 text-danger"></i>
                            Delete</a
                          >
                        </div>
                      </div>
                    </template>
                  </template>
                </a-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Page Wrapper -->

  <!-- Add Modal -->
  <div class="modal fade custom-modal" id="add-category">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header flex-wrap">
          <div class="text-center w-100 mb-3">
            <img src="@/assets/admin_img/logo-small.png" alt="Image" />
          </div>
          <h4 class="modal-title">Add New Freelancer</h4>
          <button type="button" class="close" data-bs-dismiss="modal">
            <span>&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <form>
            <div class="form-group">
              <label>Full Name</label>
              <input type="text" class="form-control" />
            </div>
            <div class="form-group">
              <label>Email</label>
              <input type="email" class="form-control" />
            </div>
            <div class="form-group">
              <label>Password</label>
              <input type="password" class="form-control" />
            </div>
            <div class="form-group">
              <label>Confirm Password</label>
              <input type="password" class="form-control" />
            </div>
            <div class="form-group">
              <label>Freelancer Type</label>
              <select class="form-control form-select">
                <option>Select</option>
                <option>Frontend Developer</option>
                <option>Graphic Designer</option>
              </select>
            </div>
            <div class="mt-4">
              <button type="submit" class="btn btn-primary btn-block">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- Add Modal -->
</template>

<script>
const columns = [
  {
    title: "#",
    key: "inputbox",
    sorter: true,
  },
  {
    title: "Freelancer",
    dataIndex: "Freelancer",
    key: "Freelancer",
    sorter: {
      compare: (a, b) => {
        a = a.Freelancer.toLowerCase();
        b = b.Freelancer.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Expertise",
    dataIndex: "Expertise",
    sorter: {
      compare: (a, b) => {
        a = a.Expertise.toLowerCase();
        b = b.Expertise.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Verified",
    dataIndex: "Verified",
    key: "Verified",
    sorter: {
      compare: (a, b) => {
        a = a.Verified.toLowerCase();
        b = b.Verified.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Account Balance",
    dataIndex: "AccountBalance",
    sorter: {
      compare: (a, b) => {
        a = a.AccountBalance.toLowerCase();
        b = b.AccountBalance.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Joined Date",
    dataIndex: "JoinedDate",
    sorter: {
      compare: (a, b) => {
        a = a.JoinedDate.toLowerCase();
        b = b.JoinedDate.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Last Login",
    dataIndex: "LastLogin",
    sorter: {
      compare: (a, b) => {
        a = a.LastLogin.toLowerCase();
        b = b.LastLogin.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "Status",
    sorter: {
      compare: (a, b) => {
        a = a.Status.toLowerCase();
        b = b.Status.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "action",
    sorter: true,
  },
];

const data = [
  {
    FIELD1: "",
    Img: "avatar-14.jpg",
    Freelancer: "Janet Paden",
    Email: "georgewells@example.com",
    Expertise: "Frontend Developer",
    Verified: "Email",
    Class: "feather feather-check-circle me-1 text-success",
    AccountBalance: "$7,763.05",
    JoinedDate: "12 Mar 2023",
    LastLogin: "18 May 2023",
    Status: "Inactive",
  },
  {
    FIELD1: "",
    Img: "avatar-15.jpg",
    Freelancer: "Floyd Lewis",
    Email: "floydlewis@example.com",
    Expertise: "Graphic Designer",
    Verified: "Email",
    Class: "feather feather-check-circle me-1 email-check",
    AccountBalance: "$6,763.05",
    JoinedDate: "11 April 2023	",
    LastLogin: "14 Jun 2023",
    Status: "Inactive",
  },
  {
    FIELD1: "",
    Img: "avatar-16.jpg",
    Freelancer: "Andrew Glover",
    Email: "Andrewglover@example.com",
    Expertise: "PHP Developer",
    Verified: "Email",
    Class: "feather feather-check-circle me-1 text-success",
    AccountBalance: "$2,763.05",
    JoinedDate: "05 Feb 2022",
    LastLogin: "07 April 2023	",
    Status: "Inactive",
  },
  {
    FIELD1: "",
    Img: "avatar-14.jpg",
    Freelancer: "Janet Paden",
    Email: "georgewells@example.com",
    Expertise: "Frontend Developer",
    Verified: "Email",
    Class: "feather feather-check-circle me-1 text-success",
    AccountBalance: "$7,763.05",
    JoinedDate: "12 Mar 2023",
    LastLogin: "18 May 2023",
    Status: "Inactive",
  },
  {
    FIELD1: "",
    Img: "avatar-15.jpg",
    Freelancer: "Floyd Lewis",
    Email: "floydlewis@example.com",
    Expertise: "Graphic Designer",
    Verified: "Email",
    Class: "feather feather-check-circle me-1 email-check",
    AccountBalance: "$6,763.05",
    JoinedDate: "11 April 2023	",
    LastLogin: "14 Jun 2023",
    Status: "Inactive",
  },
  {
    FIELD1: "",
    Img: "avatar-16.jpg",
    Freelancer: "Andrew Glover",
    Email: "Andrewglover@example.com",
    Expertise: "PHP Developer",
    Verified: "Email",
    Class: "feather feather-check-circle me-1 text-success",
    AccountBalance: "$2,763.05",
    JoinedDate: "05 Feb 2022",
    LastLogin: "07 April 2023	",
    Status: "Inactive",
  },
];

export default {
  data() {
    return {
      title: "Freelancer",
      text: "Home",
      text1: "Freelancer",
      columns,
      data,
      filterInputsDisplay: "none",
    };
  },
  methods: {
    toggleFilterInputs() {
      this.filterInputsDisplay = this.filterInputsDisplay === "none" ? "block" : "none";
    },
  },
};
</script>
