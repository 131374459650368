<template>
    <div class="subscribe-employe users-list">
        <ul v-bind:class="{active: currentPath == 'withdrawn' || currentPath == 'withdrawn',}">
            <li :class="currentPath == 'withdrawn' ? 'active' : 'notactive'">
                <router-link to="/admin/withdrawn">History </router-link>
            </li>
            <li :class="currentPath == 'withdrawn-pending' ? 'active' : 'notactive'">
                <router-link to="/admin/withdrawn-pending">Pending</router-link>
            </li>
            <li :class="currentPath == 'withdrawn-cancelled' ? 'active' : 'notactive'">
                <router-link to="/admin/withdrawn-cancelled">Cancelled</router-link>
            </li>
            <li :class="currentPath == 'withdrawn-completed' ? 'active' : 'notactive'">
                <router-link to="/admin/withdrawn-completed">Completed</router-link>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    data() {
        return {
            
        }
    },
    computed: {
        currentPath() {
            return this.$route.name;
        },
        returnMenu() {
            return (
                this.$route.name == "withdrawn" ||
                this.$route.name == "withdrawn-pending" ||
                this.$route.name == "withdrawn-cancelled" ||
                this.$route.name == "withdrawn-completed"
            );
        },
    },
}
</script>