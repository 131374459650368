<template>
  <pagebreadcrumb :title="title" :text="text" :text1="text1" />

  <div class="content">
    <div class="container">
      <div class="row">
        <div class="col-md-8">
          <div class="terms-content">
            <div class="terms-text">
              <h4>Welcome to Kofejob</h4>

              <p>
                Thanks for using our products and services ("Services"). The Services are
                provided by Pixeel Ltd. ("Kofejob"), located at 153 Williamson Plaza,
                Maggieberg, MT 09514, England, United Kingdom.
              </p>

              <p>
                By using our Services, you are agreeing to these terms. Please read them
                carefully.
              </p>

              <p>
                Our Services are very diverse, so sometimes additional terms or product
                requirements (including age requirements) may apply. Additional terms will
                be available with the relevant Services, and those additional terms become
                part of your agreement with us if you use those Services.
              </p>
            </div>

            <div class="terms-text">
              <h4>1. Using our services</h4>

              <p>
                You must follow any policies made available to you within the Services.
              </p>

              <p>
                Don't misuse our Services. For example, don't interfere with our Services
                or try to access them using a method other than the interface and the
                instructions that we provide. You may use our Services only as permitted
                by law, including applicable export and re-export control laws and
                regulations. We may suspend or stop providing our Services to you if you
                do not comply with our terms or policies or if we are investigating
                suspected misconduct.
              </p>

              <p>
                Using our Services does not give you ownership of any intellectual
                property rights in our Services or the content you access. You may not use
                content from our Services unless you obtain permission from its owner or
                are otherwise permitted by law. These terms do not grant you the right to
                use any branding or logos used in our Services. Don't remove, obscure, or
                alter any legal notices displayed in or along with our Services.
              </p>
            </div>

            <div class="terms-text">
              <h4>A. Personal Data that we collect about you.</h4>

              <p>
                Personal Data is any information that relates to an identified or
                identifiable individual. The Personal Data that you provide directly to us
                through our Sites will be apparent from the context in which you provide
                the data. In particular:
              </p>

              <p>
                When you register for a Kofejob account we collect your full name, email
                address, and account log-in credentials.
              </p>
              <p>
                When you fill-in our online form to contact our sales team, we collect
                your full name, work email, country, and anything else you tell us about
                your project, needs and timeline.
              </p>
              <p>
                When you use the "Remember Me" feature of Kofejob Checkout, we collect
                your email address, payment card number, CVC code and expiration date.
              </p>
              <p>
                When you respond to Kofejob emails or surveys we collect your email
                address, name and any other information you choose to include in the body
                of your email or responses. If you contact us by phone, we will collect
                the phone number you use to call Kofejob. If you contact us by phone as a
                Kofejob User, we may collect additional information in order to verify
                your identity.
              </p>
            </div>

            <div class="terms-text">
              <h4>B. Information that we collect automatically on our Sites.</h4>

              <p>
                We also may collect information about your online activities on websites
                and connected
              </p>

              <p>
                These Terms will be applied fully and affect to your use of this Website.
                By using this Website, you agreed to accept all terms and conditions
                written in here. You must not use this Website if you disagree with any of
                these Website Standard Terms and Conditions.
              </p>
            </div>
          </div>
        </div>

        <div class="col-md-4 theiaStickySidebar">
          <div class="card list-widget">
            <div class="card-header">
              <h4 class="card-title">All List</h4>
            </div>
            <div class="card-body">
              <ul class="list-group list-group-flush">
                <li class="list-group-item">
                  <a href="javascript:void(0);">1. Using our services</a>
                  <ul>
                    <li>
                      <a href="javascript:void(0);"
                        >A. Personal Data that we collect about you</a
                      >
                    </li>
                    <li>
                      <a href="javascript:void(0);"
                        >B. Information that we collect automatically on our Sites</a
                      >
                    </li>
                  </ul>
                </li>
                <li class="list-group-item">
                  <a href="javascript:void(0);">2. Privacy and copyright protection</a>
                </li>
                <li class="list-group-item">
                  <a href="javascript:void(0);">3. Your content in our services</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      title: "TERMS & CONDITIONS",
      text: "Home",
      text1: "Terms & Conditions",
    };
  },
};
</script>
