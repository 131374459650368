<template>
  <!-- Add Modal -->
  <div class="modal fade transaction-modal" id="transaction-category">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header flex-wrap">
          <div class="transaction-group">
            <div class="head-transaction">
              <h4>Transaction</h4>
              <p>#TNX24586414</p>
            </div>
            <div class="complete-btn">
              <a href="javascript:;" class="btn btn-primary">Completed</a>
            </div>
          </div>
        </div>

        <div class="modal-body">
          <div class="table-avatar user-profile invest-acount">
            <router-link to="/admin/profile"
              ><img
                class="exchange case-icon"
                src="@/assets/admin_img/icon/icon-15.svg"
                alt="Image"
              />
              <div>
                <h5>200.00 USDT</h5>
                <p class="receive-date">Apr 25, 2023 11:01 AM</p>
              </div>
            </router-link>
          </div>
          <div class="acount-transaction">
            <div class="row">
              <div class="col-md-6">
                <div class="basic-info">
                  <h4>IN ACCOUNT</h4>
                  <div class="additional-info">
                    <p>Amount</p>
                    <h5>6,335.006 USD</h5>
                  </div>
                  <div class="additional-info">
                    <p>Fees</p>
                    <h5>0.00 USD</h5>
                  </div>
                  <div class="additional-info">
                    <p>Freelancer Investment</p>
                    <h5>6,335.006 USD</h5>
                  </div>
                  <div class="additional-info">
                    <p>Freelancer Account</p>
                    <h5>UID00003</h5>
                  </div>
                  <div class="additional-info">
                    <p>Freelancer Email</p>
                    <h5>william@example.com</h5>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="basic-info">
                  <h4>IN TRANSACTION</h4>
                  <div class="additional-info">
                    <p>Amount</p>
                    <h5>6,335.006 USD</h5>
                  </div>
                  <div class="additional-info">
                    <p>Fees</p>
                    <h5>0.00 USD</h5>
                  </div>
                  <div class="additional-info">
                    <p>Total Investment</p>
                    <h5>6,335.006 USD</h5>
                  </div>
                  <div class="additional-info">
                    <p>Freelancer Account</p>
                    <h5>UID00003</h5>
                  </div>
                  <div class="additional-info">
                    <p>Freelancer Email</p>
                    <h5>william@example.com</h5>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="basic-info additional-detail top-border">
                  <h4>Order Details</h4>
                  <div class="row">
                    <div class="col-lg-3 col-md-6">
                      <div class="additional-info">
                        <p>Order Date</p>
                        <h5>Mar 13, 2023</h5>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                      <div class="additional-info">
                        <p>Placed By</p>
                        <h5>UID00001</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="basic-info additional-detail">
                  <h4 class="mt-0">Additional Details</h4>
                  <div class="row">
                    <div class="col-lg-3 col-md-6">
                      <div class="additional-info">
                        <p>Transaction Type</p>
                        <h5>Deposit</h5>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                      <div class="additional-info">
                        <p>Payment Gateway</p>
                        <h5>Paypal</h5>
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                      <div class="additional-info">
                        <p>Payment To</p>
                        <h5>usdttest87i2UK3JUWzR7iy6z5eRrxbAeT</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="basic-info additional-detail">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="additional-info">
                        <p>Updated Balance</p>
                        <h5 class="active-balance">13,144.626 USD</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Add Modal -->

  <!-- Category Modal -->
  <div class="modal fade custom-modal" id="add-category">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header flex-wrap">
          <h4 class="modal-title">Edit Provider</h4>
          <button type="button" class="close" data-bs-dismiss="modal">
            <span>&times;</span>
          </button>
        </div>

        <!-- Modal body -->
        <div class="modal-body">
          <form>
            <div class="form-group">
              <label>Company Name</label>
              <input
                type="text"
                class="form-control"
                placeholder="Focused Holistic Hardware"
              />
            </div>
            <div class="form-group">
              <label>Primary Contact</label>
              <input type="text" class="form-control" placeholder="Emily Smith" />
            </div>
            <div class="form-group">
              <label>Website</label>
              <input
                type="text"
                class="form-control"
                placeholder="https://focusedhardware.de"
              />
            </div>
            <div class="form-group">
              <label>Total Projects</label>
              <input type="text" class="form-control" placeholder="120" />
            </div>
            <div class="form-group">
              <label>Status</label>
              <vue-select 
                :options="Status"
                id="status"
                placeholder="Enabled"
              />
            </div>
            <div class="mt-4">
              <button type="submit" class="btn btn-primary btn-block">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Category Modal -->

  <!-- Add Modal -->
  <div class="modal fade custom-modal" id="add-plan-employe">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header flex-wrap add-plan-head">
          <h4 class="modal-title">Add Plan</h4>
          <button type="button" class="close" data-bs-dismiss="modal">
            <span>&times;</span>
          </button>
        </div>
        <div class="modal-body add-plan">
          <form>
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group">
                  <label>Plan Name <span>*</span></label>
                  <input type="text" class="form-control" />
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <label>Plan Type <span>*</span></label>
                  <vue-select 
                    :options="Plan"
                    id="plan"
                    placeholder="Select"
                  />
                </div>
              </div>
              <div class="col-lg-12">
                <div class="form-group">
                  <label>Plan Amount <span>*</span></label>
                  <input type="text" class="form-control" />
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <label>Project Credits <span>*</span></label>
                  <vue-select 
                    :options="Plan"
                    id="credits"
                    placeholder="Select"
                  />
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <label>No of Projects <span>*</span></label>
                  <vue-select 
                    :options="Plan"
                    id="projects"
                    placeholder="Select"
                  />
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <label>Project Visibility <span>*</span></label>
                  <vue-select 
                    :options="Plan"
                    id="visibility"
                    placeholder="Select"
                  />
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <label>Featured Service <span>*</span></label>
                  <vue-select 
                    :options="Plan"
                    id="featured"
                    placeholder="Select"
                  />
                </div>
              </div>
              <div class="col-lg-12">
                <div class="form-group">
                  <label>Plan Description <span>*</span></label>
                  <textarea
                    class="form-control"
                    rows="5"
                    id="comment1"
                    name="text"
                  ></textarea>
                </div>
              </div>
              <div class="form-group">
                <label>Status <span>*</span></label>
                <div
                  class="status-toggle d-flex justify-content-between align-items-center"
                >
                  <input type="checkbox" id="status_2" class="check" checked />
                  <label for="status_2" class="checktoggle"></label>
                </div>
              </div>
            </div>
            <div class="plan-btn text-center">
              <button type="submit" class="btn btn-primary remove-form">Close</button>
              <button type="submit" class="btn btn-primary save-form"
                >Save Plan</button
              >
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- Add Modal -->

  <!-- Add Modal -->
  <div class="modal fade custom-modal" id="edit-category">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header flex-wrap">
          <div class="text-center w-100 mb-3">
            <img src="@/assets/admin_img/logo-small.png" alt="Image" />
          </div>
          <h4 class="modal-title">Edit User</h4>
          <button type="button" class="close" data-bs-dismiss="modal">
            <span>&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <form>
            <div class="form-group">
              <label>Full Name</label>
              <input type="text" class="form-control" placeholder=" George Wells" />
            </div>
            <div class="form-group">
              <label>Email</label>
              <input
                type="email"
                class="form-control"
                placeholder="georgewells@example.com"
              />
            </div>
            <div class="form-group">
              <label>Password</label>
              <input type="password" class="form-control" placeholder="*******" />
            </div>
            <div class="form-group">
              <label>Confirm Password</label>
              <input type="password" class="form-control" placeholder="********" />
            </div>
            <div class="form-group">
              <label>User Type</label>
              <vue-select 
                :options="Plan"
                id="user"
                placeholder="Select"
              />
            </div>
            <div class="mt-4">
              <button type="submit" class="btn btn-primary btn-block">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- Add Modal -->
  <div class="modal fade custom-modal" id="add-plan-freelance">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header flex-wrap add-plan-head">
          <h4 class="modal-title">Add Plan</h4>
          <button type="button" class="close" data-bs-dismiss="modal">
            <span>&times;</span>
          </button>
        </div>
        <div class="modal-body add-plan">
          <form>
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group">
                  <label>Plan Name <span>*</span></label>
                  <input type="text" class="form-control" />
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <label>Plan Type <span>*</span></label>
                  <vue-select 
                    :options="Plan"
                    id="type"
                    placeholder="Select"
                  />
                </div>
              </div>
              <div class="col-lg-12">
                <div class="form-group">
                  <label>Plan Amount <span>*</span></label>
                  <input type="text" class="form-control" />
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <label>Bidding Credits <span>*</span></label>
                  <vue-select 
                    :options="Plan"
                    id="bidding"
                    placeholder="Select"
                  />
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <label>No of Projects <span>*</span></label>
                  <vue-select 
                    :options="Plan"
                    id="project"
                    placeholder="Select"
                  />
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <label>Bidding visibility <span>*</span></label>
                  <vue-select 
                    :options="Plan"
                    id="developer"
                    placeholder="Select"
                  />
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <label>Featured Service <span>*</span></label>
                  <vue-select 
                    :options="Plan"
                    id="service"
                    placeholder="Select"
                  />
                </div>
              </div>
              <div class="col-lg-12">
                <div class="form-group">
                  <label>Plan Description <span>*</span></label>
                  <textarea
                    class="form-control"
                    rows="5"
                    id="comment2"
                    name="text"
                  ></textarea>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="form-group">
                  <label>Status <span>*</span></label>
                  <div
                    class="status-toggle d-flex justify-content-between align-items-center"
                  >
                    <input type="checkbox" id="status_1" class="check" checked />
                    <label for="status_1" class="checktoggle"></label>
                  </div>
                </div>
              </div>
            </div>
            <div class="plan-btn text-center">
              <button type="submit" class="btn btn-primary remove-form">Close</button>
              <button type="submit" class="btn btn-primary save-form">Save Plan</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      Status: ["Enabled", "Disable"],
      Plan: ["Select", "Frontend Developer", "Graphic Designer"]
    }
  },
}
</script>