<template>
  <div class="page-wrapper board-screen">
    <div class="content container-fluid">
      <div class="acc-content">

        <div class="row">
          <div class="col-sm-12">
            <div class="multistep-form">

              <!-- Freelancer Multistep -->
              <div class="multistep-progress" id="freelance_step">
                <div class="container">
                  <div class="first-progress">
                    <div class="row align-items-center">
                      <div class="col-md-3">
                        <div class="board-logo">
                          <router-link to="/home/"><img src="@/assets/img/logo.svg" alt="Img"
                              class="img-fluid"></router-link>
                        </div>
                      </div>
                      <div class="col-md-9">
                        <ul id="progressbar" class="progressbar" v-show="currentStep === 1">
                          <li class="progress-active">
                            <div class="multi-step"><img src="@/assets/img/icon/wizard-icon-01.svg" alt="Img"></div>
                            <div class="steps-count">
                              <span>Step 1/5</span>
                              <h5>Account Type</h5>
                            </div>
                          </li>
                          <li class="">
                            <div class="multi-step"><img src="@/assets/img/icon/wizard-icon-02.svg" alt="Img"></div>
                            <div class="steps-count">
                              <span>Step 2/5</span>
                              <h5>Personal info</h5>
                            </div>
                          </li>
                          <li class="">
                            <div class="multi-step"><img src="@/assets/img/icon/wizard-icon-03.svg" alt="Img"></div>
                            <div class="steps-count">
                              <span>Step 3/5</span>
                              <h5>Skills & Experience</h5>
                            </div>
                          </li>
                          <li class="">
                            <div class="multi-step"><img src="@/assets/img/icon/wizard-icon-04.svg" alt="Img"></div>
                            <div class="steps-count">
                              <span>Step 4/5</span>
                              <h5>Other Information</h5>
                            </div>
                          </li>
                          <li class="">
                            <div class="multi-step"><img src="@/assets/img/icon/wizard-icon-05.svg" alt="Img"></div>
                            <div class="steps-count">
                              <span>Step 5/5</span>
                              <h5>Email Verification</h5>
                            </div>
                          </li>
                        </ul>
                        <ul id="progressbar" class="progressbar" v-show="currentStep === 2">
                          <li class="progress-activated">
                            <div class="multi-step"><img src="@/assets/img/icon/wizard-icon-01.svg" alt="Img"></div>
                            <div class="steps-count">
                              <span>Step 1/5</span>
                              <h5>Account Type</h5>
                            </div>
                          </li>
                          <li class="progress-active">
                            <div class="multi-step"><img src="@/assets/img/icon/wizard-icon-02.svg" alt="Img"></div>
                            <div class="steps-count">
                              <span>Step 2/5</span>
                              <h5>Personal info</h5>
                            </div>
                          </li>
                          <li class="">
                            <div class="multi-step"><img src="@/assets/img/icon/wizard-icon-03.svg" alt="Img"></div>
                            <div class="steps-count">
                              <span>Step 3/5</span>
                              <h5>Skills & Experience</h5>
                            </div>
                          </li>
                          <li class="">
                            <div class="multi-step"><img src="@/assets/img/icon/wizard-icon-04.svg" alt="Img"></div>
                            <div class="steps-count">
                              <span>Step 4/5</span>
                              <h5>Other Information</h5>
                            </div>
                          </li>
                          <li class="">
                            <div class="multi-step"><img src="@/assets/img/icon/wizard-icon-05.svg" alt="Img"></div>
                            <div class="steps-count">
                              <span>Step 5/5</span>
                              <h5>Email Verification</h5>
                            </div>
                          </li>
                        </ul>
                        <ul id="progressbar" class="progressbar" v-show="currentStep === 3">
                          <li class="progress-activated">
                            <div class="multi-step"><img src="@/assets/img/icon/wizard-icon-01.svg" alt="Img"></div>
                            <div class="steps-count">
                              <span>Step 1/5</span>
                              <h5>Account Type</h5>
                            </div>
                          </li>
                          <li class="progress-activated">
                            <div class="multi-step"><img src="@/assets/img/icon/wizard-icon-02.svg" alt="Img"></div>
                            <div class="steps-count">
                              <span>Step 2/5</span>
                              <h5>Personal info</h5>
                            </div>
                          </li>
                          <li class="progress-active">
                            <div class="multi-step"><img src="@/assets/img/icon/wizard-icon-03.svg" alt="Img"></div>
                            <div class="steps-count">
                              <span>Step 3/5</span>
                              <h5>Skills & Experience</h5>
                            </div>
                          </li>
                          <li class="">
                            <div class="multi-step"><img src="@/assets/img/icon/wizard-icon-04.svg" alt="Img"></div>
                            <div class="steps-count">
                              <span>Step 4/5</span>
                              <h5>Other Information</h5>
                            </div>
                          </li>
                          <li class="">
                            <div class="multi-step"><img src="@/assets/img/icon/wizard-icon-05.svg" alt="Img"></div>
                            <div class="steps-count">
                              <span>Step 5/5</span>
                              <h5>Email Verification</h5>
                            </div>
                          </li>
                        </ul>
                        <ul id="progressbar" class="progressbar" v-show="currentStep === 4">
                          <li class="progress-activated">
                            <div class="multi-step"><img src="@/assets/img/icon/wizard-icon-01.svg" alt="Img"></div>
                            <div class="steps-count">
                              <span>Step 1/5</span>
                              <h5>Account Type</h5>
                            </div>
                          </li>
                          <li class="progress-activated">
                            <div class="multi-step"><img src="@/assets/img/icon/wizard-icon-02.svg" alt="Img"></div>
                            <div class="steps-count">
                              <span>Step 2/5</span>
                              <h5>Personal info</h5>
                            </div>
                          </li>
                          <li class="progress-activated">
                            <div class="multi-step"><img src="@/assets/img/icon/wizard-icon-03.svg" alt="Img"></div>
                            <div class="steps-count">
                              <span>Step 3/5</span>
                              <h5>Skills & Experience</h5>
                            </div>
                          </li>
                          <li class="progress-active">
                            <div class="multi-step"><img src="@/assets/img/icon/wizard-icon-04.svg" alt="Img"></div>
                            <div class="steps-count">
                              <span>Step 4/5</span>
                              <h5>Other Information</h5>
                            </div>
                          </li>
                          <li class="">
                            <div class="multi-step"><img src="@/assets/img/icon/wizard-icon-05.svg" alt="Img"></div>
                            <div class="steps-count">
                              <span>Step 5/5</span>
                              <h5>Email Verification</h5>
                            </div>
                          </li>
                        </ul>
                        <ul id="progressbar" class="progressbar" v-show="currentStep === 5">
                          <li class="progress-activated">
                            <div class="multi-step"><img src="@/assets/img/icon/wizard-icon-01.svg" alt="Img"></div>
                            <div class="steps-count">
                              <span>Step 1/5</span>
                              <h5>Account Type</h5>
                            </div>
                          </li>
                          <li class="progress-activated">
                            <div class="multi-step"><img src="@/assets/img/icon/wizard-icon-02.svg" alt="Img"></div>
                            <div class="steps-count">
                              <span>Step 2/5</span>
                              <h5>Personal info</h5>
                            </div>
                          </li>
                          <li class="progress-activated">
                            <div class="multi-step"><img src="@/assets/img/icon/wizard-icon-03.svg" alt="Img"></div>
                            <div class="steps-count">
                              <span>Step 3/5</span>
                              <h5>Skills & Experience</h5>
                            </div>
                          </li>
                          <li class="progress-activated">
                            <div class="multi-step"><img src="@/assets/img/icon/wizard-icon-04.svg" alt="Img"></div>
                            <div class="steps-count">
                              <span>Step 4/5</span>
                              <h5>Other Information</h5>
                            </div>
                          </li>
                          <li class="progress-active">
                            <div class="multi-step"><img src="@/assets/img/icon/wizard-icon-05.svg" alt="Img"></div>
                            <div class="steps-count">
                              <span>Step 5/5</span>
                              <h5>Email Verification</h5>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Freelancer Multistep -->

              <!-- Accounting Onboard -->
              <div class="text-center on-board select-account group-select" v-if="currentStep === 1">
                <div class="select-type">
                  <div class="account-onborad onboard-head">
                    <h2>Select Account Type</h2>
                    <p>Don’t worry, this can be changed later.</p>
                    <div class="select-box d-flex justify-content-center">
                      <input checked="checked" id="freelance" type="radio" name="credit-card" value="visa">
                      <label class="employee-level free-lance-img accounts_type" data-id="freelance" for="freelance">
                        <router-link to="onboard-screen">
                          <img src="@/assets/img/select-04.svg" alt="Img" class="img-fluid">
                          <span>Freelancer</span>
                        </router-link>
                      </label>
                      <input id="employee" class="accounts_type" type="radio" name="credit-card" value="mastercard">
                      <label class="employee-level employee-img accounts_type" data-id="employee" for="employee">
                        <router-link to="onboard-screen-employer">
                          <img src="@/assets/img/icon/checks.svg" alt="Img" class="checks-set">
                          <img src="@/assets/img/select-05.svg" alt="Img" class="img-fluid">
                          <span>Employer</span>
                        </router-link>
                      </label>
                    </div>
                  </div>
                  <input class="btn btn-prev prev_btn btn-back" name="next" type="button" value="Back" disabled>
                  <input class="btn next_btn btn-primary btn-get btn-next" @click="nextStep"
                    :disabled="currentStep === totalSteps" name="next" type="submit" value="Next">
                </div>
              </div>
              <!-- /Accounting Onboard -->

              <!-- Personal Info -->
              <div class="on-board field-card select-account select-btn" v-if="currentStep === 2">
                <div class="text-center onboard-head">
                  <h2>Personal Info</h2>
                  <p>Tell a bit about yourself. This information will appear on your public profile, so that potential
                    buyers can get to know you better.</p>
                </div>
                <employer-personal-info></employer-personal-info>
                <div class="text-center">
                  <input class="btn btn-prev prev_btn btn-back" @click="previousStep" :disabled="currentStep === 1"
                    name="next" type="button" value="Back">
                  <input class="btn next_btn btn-primary btn-get btn-next" @click="nextStep"
                    :disabled="currentStep === totalSteps" name="next" type="submit" value="Next">
                </div>
              </div>
              <!-- /Personal Info -->

              <!-- Skills & Experience -->
              <div class="on-board field-card select-account select-btn" v-if="currentStep === 3">
                <div class="text-center onboard-head">
                  <h2>Employer Info</h2>
                  <p>This is your time to shine. Let potential buyers know what you do best and how you gained your
                    skills, certifications and experience</p>
                </div>
                <employer-skills-experience></employer-skills-experience>
                <div class="text-center">
                  <input class="btn btn-prev prev_btn btn-back" @click="previousStep" :disabled="currentStep === 2"
                    name="next" type="button" value="Back">
                  <input class="btn next_btn btn-primary btn-get btn-next" @click="nextStep"
                    :disabled="currentStep === totalSteps" name="next" type="submit" value="Next">
                </div>
              </div>
              <!-- /Skills & Experience -->

              <!-- Other Info -->
              <div class="on-board field-card select-account select-btn" v-if="currentStep === 4">
                <div class="text-center onboard-head">
                  <h2>Other info</h2>
                  <p>Don’t worry, this can be changed later.</p>
                </div>
                <others-info></others-info>
                <div class="text-center">
                  <input class="btn btn-prev prev_btn btn-back" @click="previousStep" :disabled="currentStep === 3" name="next" type="button" value="Back">
                  <input class="btn next_btn btn-primary btn-get btn-next" @click="nextStep"
                    :disabled="currentStep === totalSteps" name="next" type="button" value="Submit">
                </div>
              </div>
              <!-- /Other Info -->

              <!-- Completeing Register -->
              <div class="on-board field-card" v-if="currentStep === 5">
                <div class="account-onborad complte-board back-home pb-0">
                  <img src="@/assets/img/icon/mail.png" class="img-fluid" alt="icon">
                  <h2>Email Verification</h2>
                  <h3>We have send a verification link to info@email.com </h3>
                  <p>Click on the link to complete the verification Process</p>
                  <p>You might need to check your Spam folder</p>
                  <a href="javascript:void(0);" class="link-danger"><i class="feather-refresh-cw me-2 "></i> Resend
                    Email</a>
                </div>
                <div class="text-center">
                  <input class="btn btn-prev prev_btn btn-back" @click="previousStep" :disabled="currentStep === 4" name="next" type="button" value="Back">
                </div>
              </div>
              <!-- /Completeing Register -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      currentStep: 1,
      totalSteps: 5,
      Industry: ["Select", "Bachelor's degree", "Master's Degree"],
      ZIP: ["Select", "US", "UK", "India"]
    };
  },
  methods: {
    nextStep() {
      if (this.currentStep < this.totalSteps) {
        this.currentStep++;
      }
    },
    previousStep() {
      if (this.currentStep > 1) {
        this.currentStep--;
      }
    },
    showProgress(step) {
      return this.currentStep === step;
    },
  },
  name: "onboard-screen",
};
</script>
<style>
.multistep-form .on-board {
  display: block !important;
}
</style>