<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>

  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content container-fluid">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row align-items-center">
          <div class="col">
            <breadcrumbthree :title="title" :text="text" :text1="text1" />
          </div>
          <div class="col-auto">
            <a
              href="javascript:;"
              class="btn add-button me-1"
              data-bs-toggle="modal"
              data-bs-target="#add-category"
            >
              <i class="fas fa-plus"></i>
            </a>
            <a
              class="btn btn-primary filter-btn"
              href="javascript:void(0);"
              @click="toggleFilterInputs"
              id="filter_search"
            >
              <i class="fas fa-filter"></i>
            </a>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <!-- Search Filter -->
      <div
        class="card filter-card"
        :style="{ display: filterInputsDisplay }"
        id="filter_inputs"
      >
        <div class="card-body pb-0">
          <form action="javascript:;" method="post">
            <div class="row filter-row">
              <div class="col-sm-6 col-md-3">
                <div class="form-group">
                  <label>Roles</label>
                  <input class="form-control" type="text" />
                </div>
              </div>
              <div class="col-sm-6 col-md-3">
                <div class="form-group">
                  <label>From Date</label>
                  <div class="cal-icon">
                    <datepicker
                      v-model="startdate"
                      placeholder="Choose Date"
                      class="form-control datetimepicker"
                      :editable="true"
                      :clearable="false"
                    />
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-3">
                <div class="form-group">
                  <label>To Date</label>
                  <div class="cal-icon">
                    <datepicker
                      v-model="startdate"
                      placeholder="Choose Date"
                      class="form-control datetimepicker"
                      :editable="true"
                      :clearable="false"
                    />
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-3">
                <div class="form-group">
                  <button class="btn btn-primary btn-block" type="submit">Submit</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <!-- /Search Filter -->

      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-body">
              <div class="showentries">
                <label
                  >Show
                  <select>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  entries
                </label>
                <div class="entries">
                  <span>Search: </span>
                  <input
                    type="search"
                    class="form-control form-control-sm ms-2"
                    placeholder=""
                    aria-controls="DataTables_Table_0"
                  />
                </div>
              </div>
              <div class="table-responsive">
                <a-table :columns="columns" :data-source="data">
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key === 'RoleName'">
                      {{ record.RoleName }}
                    </template>
                    <template v-else-if="column.key === 'action'">
                      <div class="text-end">
                        <router-link
                          to="/admin/roles-permission"
                          class="btn btn-sm bg-pink me-2"
                          ><i class="fas fa-user-shield"></i
                        ></router-link>
                        <a
                          href="javascript:void(0);"
                          class="btn btn-sm btn-secondary me-2"
                          data-bs-toggle="modal"
                          data-bs-target="#edit-category"
                          ><i class="far fa-edit"></i
                        ></a>
                        <a
                          href="javascript:void(0);"
                          class="btn btn-sm btn-danger"
                          data-bs-toggle="modal"
                          data-bs-target="#delete_category"
                          ><i class="far fa-trash-alt"></i
                        ></a>
                      </div>
                    </template>
                  </template>
                </a-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Wrapper -->
  </div>

  <!-- Add Modal -->
  <div class="modal fade custom-modal" id="add-category">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Add Roles</h4>
          <button type="button" class="close" data-bs-dismiss="modal">
            <span>&times;</span>
          </button>
        </div>
        <!-- /Modal Header -->

        <!-- Modal body -->
        <div class="modal-body">
          <form>
            <div class="form-group">
              <label>Role Name</label>
              <input type="text" class="form-control" placeholder="Enter Role Name" />
            </div>
            <div class="mt-4">
              <button type="submit" class="btn btn-primary btn-block">Submit</button>
            </div>
          </form>
        </div>
        <!-- /Modal body -->
      </div>
    </div>
  </div>
  <!-- /Add Modal -->

  <!-- Edit Modal -->
  <div class="modal fade custom-modal" id="edit-category">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Edit Roles</h4>
          <button type="button" class="close" data-bs-dismiss="modal">
            <span>&times;</span>
          </button>
        </div>
        <!-- /Modal Header -->

        <!-- Modal body -->
        <div class="modal-body">
          <form>
            <div class="form-group">
              <label>Role Name</label>
              <input type="text" class="form-control" placeholder="Admin" />
            </div>
            <div class="mt-4">
              <button type="submit" class="btn btn-primary btn-block">Submit</button>
            </div>
          </form>
        </div>
        <!-- /Modal body -->
      </div>
    </div>
  </div>
  <!-- /Add Modal -->

  <delete />
</template>

<script>
const columns = [
  {
    title: "Role Name",
    dataIndex: "RoleName",
    key: "RoleName",
    sorter: {
      compare: (a, b) => {
        a = a.RoleName.toLowerCase();
        b = b.RoleName.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Actions",
    key: "action",
    class: "text-end",
    sorter: true,
  },
];
const data = [
  {
    RoleName: "Admin",
    Actions: "",
  },
  {
    RoleName: "Client",
    Actions: "",
  },
  {
    RoleName: "Staff",
    Actions: "",
  },
];

import { ref } from "vue";
const currentDate = ref(new Date());
export default {
  data() {
    return {
      title: "Roles & Permission",
      text: "Home",
      text1: "Roles",
      columns,
      data,
      filterInputsDisplay: "none",
      startdate: currentDate,
    };
  },
  methods: {
    toggleFilterInputs() {
      this.filterInputsDisplay = this.filterInputsDisplay === "none" ? "block" : "none";
    },
  },
};
</script>
