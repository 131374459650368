<template>
  <div class="bg-five">
    <loader v-if="showLoader"></loader>
    <div class="home-five-wrapper">
      <header-five></header-five>
      <home-five-homebanner></home-five-homebanner>
      <home-five-categories></home-five-categories>
      <home-five-projects></home-five-projects>
      <home-five-job></home-five-job>
      <home-five-marketplace></home-five-marketplace>
      <home-five-developers></home-five-developers>
      <home-five-faq></home-five-faq>
      <home-five-counter></home-five-counter>
      <home-five-review></home-five-review>
      <home-five-blog></home-five-blog>
      <footer-five></footer-five>
    </div>
  </div>

  <!-- Scroll Top -->
  <button class="scroll-top scroll-top-next scroll-to-target" data-target="html">
    <span class="ti-angle-up"><i class="feather-arrow-up"></i></span>
  </button>
  <!-- /Scroll Top -->
</template>

<script>
export default {
  data() {
    return {
      showLoader: true,
    };
  },
  mounted() {
    window.addEventListener("scroll", function () {
      var scrollPosition = window.pageYOffset || document.documentElement.scrollTop;

      var scrollToTargetElements = document.querySelectorAll(".scroll-to-target");
      scrollToTargetElements.forEach(function (element) {
        if (scrollPosition > 0) {
          element.classList.add("open");
        } else {
          element.classList.remove("open");
        }

        if (scrollPosition > 500) {
          element.classList.add("open");
        } else {
          element.classList.remove("open");
        }
      });
    });
    var scrollToTargetElements = document.getElementsByClassName("scroll-to-target");
    if (scrollToTargetElements.length) {
      for (var i = 0; i < scrollToTargetElements.length; i++) {
        scrollToTargetElements[i].addEventListener("click", function () {
          var target = this.getAttribute("data-target");
          var targetElement = document.querySelector(target);
          if (targetElement) {
            var targetOffsetTop = targetElement.offsetTop;

            // animate scroll
            var scrollOptions = {
              top: targetOffsetTop,
              behavior: "smooth",
            };
            window.scrollTo(scrollOptions);
          }
        });
      }
    }
    setTimeout(() => {
      // Show the loader
      this.showLoader = true;

      setTimeout(() => {
        // Hide the loader
        this.showLoader = false;
      }, 100);
    }, 500);
  },
};
</script>
