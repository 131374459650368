<template>
  <div class="modal fade edit-proposal-modal success-modal" id="edit-milestone">
    <div class="modal-dialog modal-dialog-centered modal-md">
      <div class="modal-content">
        <div class="modal-header justify-content-end">
          <span class="modal-close"
            ><a href="javascript:void(0);" data-bs-dismiss="modal" aria-label="Close"
              ><i class="feather-x"></i></a
          ></span>
        </div>
        <div class="modal-body">
          <div class="success-msg-content text-center">
            <h4>Payment Request Sent Successfully</h4>
            <p>
              Your payment request for milestone ”Logo Design” has been successfully
              started
            </p>
            <router-link to="/freelancer/freelancer-dashboard" class="btn btn-primary mt-3"
              >Go to Dashboard</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
