<template>
    <!-- Review -->
    <section class="section review review-two">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="section-header section-header-two text-center aos" data-aos="fade-up">
                        <h2 class="header-title">Reviews Of The <span>Users</span></h2>
                        <p>What our Clients say about us </p>
                    </div>
                </div>
            </div>
            <div class="row aos" data-aos="fade-up">
                <div class="col-lg-4 col-md-6">
                    <!-- Review Widget -->
                    <div class="review-blog user-review">
                        <div class="review-top ">
                            <div class="review-img mx-auto">
                                <router-link to="/employers/review"><img class="img-fluid" src="@/assets/img/review/review-01.jpg"
                                        alt="Post Image"></router-link>
                            </div>
                            <div class="review-info text-center">
                                <h3><router-link to="/employers/review">Durso Raeen</router-link></h3>
                                <h5>Project Lead</h5>
                            </div>
                        </div>
                        <div class="review-content text-center">
                            <p>Respond to every review, both positive and negative. Thank clients for positive feedback and
                                address concerns in negative reviews professionally and empathetically.</p>
                        </div>
                        <div class="rating">
                            <i class="fas fa-star filled"></i>
                            <i class="fas fa-star filled"></i>
                            <i class="fas fa-star filled"></i>
                            <i class="fas fa-star filled"></i>
                            <i class="fas fa-star"></i>
                            <span class="average-rating">5.0</span>
                        </div>
                    </div>
                    <!-- / Review Widget -->
                </div>
                <div class="col-lg-4 col-md-6">
                    <!-- Review Widget -->
                    <div class="review-blog user-review">
                        <div class="review-top ">
                            <div class="review-img mx-auto">
                                <router-link to="/employers/review"><img class="img-fluid" src="@/assets/img/review/review-02.jpg"
                                        alt="Post Image"></router-link>
                            </div>
                            <div class="review-info text-center">
                                <h3><router-link to="/employers/review">Camelia Rennesa</router-link></h3>
                                <h5>Team Lead</h5>
                            </div>
                        </div>
                        <div class="review-content text-center">
                            <p>Respond promptly to reviews. Aim to acknowledge and reply to reviews within a reasonable
                                timeframe, ideally within 24-48 hours.</p>
                        </div>
                        <div class="rating">
                            <i class="fas fa-star filled"></i>
                            <i class="fas fa-star filled"></i>
                            <i class="fas fa-star filled"></i>
                            <i class="fas fa-star filled"></i>
                            <i class="fas fa-star"></i>
                            <span class="average-rating">5.0</span>
                        </div>
                    </div>
                    <!-- / Review Widget -->
                </div>
                <div class="col-lg-4 col-md-6">
                    <!-- Review Widget -->
                    <div class="review-blog user-review">
                        <div class="review-top ">
                            <div class="review-img mx-auto">
                                <router-link to="/employers/review"><img class="img-fluid" src="@/assets/img/review/review-03.jpg"
                                        alt="Post Image"></router-link>
                            </div>
                            <div class="review-info text-center">
                                <h3><router-link to="/employers/review">Brayan</router-link></h3>
                                <h5>Project Lead</h5>
                            </div>
                        </div>
                        <div class="review-content text-center">
                            <p>Maintain a professional and courteous tone in all responses, even when addressing negative
                                reviews. Avoid getting defensive or confrontational.</p>
                        </div>
                        <div class="rating">
                            <i class="fas fa-star filled"></i>
                            <i class="fas fa-star filled"></i>
                            <i class="fas fa-star filled"></i>
                            <i class="fas fa-star filled"></i>
                            <i class="fas fa-star"></i>
                            <span class="average-rating">5.0</span>
                        </div>
                    </div>
                    <!-- / Review Widget -->
                </div>
            </div>
        </div>
    </section>
    <!-- / Review -->

    <!-- Company Hire -->
    <section class="section top-company-two border-bottom-0">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="section-header section-header-two text-center aos" data-aos="fade-up">
                        <h2 class="header-title">Trusted By The <span>World’s Best</span></h2>
                        <p>It is a long established fact that a reader will be distracted by the readable content of a page
                            when looking at its layout. </p>
                    </div>
                </div>
            </div>
            <div id="company-slider" class="owl-carousel owl-theme testimonial-slider aos" data-aos="fade-up">
                <Carousel :wrap-around="true" :settings="settings" :breakpoints="breakpoints">
                    <Slide v-for="item in CompanyHire" :key="item.id">
                        <div class="company-logos">
                            <img :src="require(`@/assets/img/${item.Image}`)" class="img-fluid" alt="Img">
                        </div>
                    </Slide>
                    <template #addons>
                        <Navigation />
                    </template>
                </Carousel>
            </div>
        </div>
    </section>
    <!-- / Company Hire -->

    <!-- News -->
    <section class="section blog-tricks">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="section-header section-header-two text-center aos" data-aos="fade-up">
                        <h2 class="header-title">Featured <span>Blogs</span></h2>
                        <p>Read Our Article To Get Tricks </p>
                    </div>
                </div>
            </div>
            <div class="row aos" data-aos="fade-up">
                <div class="col-lg-4 col-md-6">
                    <div class="grid-blog blog-two aos" data-aos="fade-up">
                        <div class="blog-image">
                            <router-link to="/blog/blog-details"><img class="img-fluid" src="@/assets/img/blog/blog-12.jpg"
                                    alt="Post Image"></router-link>
                        </div>
                        <div class="blog-content">
                            <div class="feature-time-blk">
                                <span class="badge bg-pink d-flex align-items-center"><i
                                        class="feather-tag me-1"></i>Jobs</span>
                                <span><i class="far fa-calendar me-1"></i> 06 Oct, 2023</span>
                            </div>
                            <h3 class="blog-title mt-0"><router-link to="/blog/blog-details">Top 10 Resume Tips for Landing Your
                                    Dream Job</router-link></h3>
                            <p>Customize your resume for each job application. Highlight skills and experiences that
                                align...</p>
                            <div class="blog-read">
                                <router-link to="/blog/blog-details">Read More <i
                                        class="fas fa-arrow-right ms-1"></i></router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="grid-blog blog-two aos" data-aos="fade-up">
                        <div class="blog-image">
                            <router-link to="/blog/blog-details"><img class="img-fluid" src="@/assets/img/blog/blog-13.jpg"
                                    alt="Post Image"></router-link>
                        </div>
                        <div class="blog-content">
                            <div class="feature-time-blk">
                                <span class="badge bg-pink d-flex align-items-center"><i
                                        class="feather-tag me-1"></i>Jobs</span>
                                <span><i class="far fa-calendar me-1"></i> 06 Oct, 2023</span>
                            </div>
                            <h3 class="blog-title mt-0"><router-link to="/blog/blog-details">Navigating the Gig Economy:
                                    Freelancing
                                    and Side Hustles</router-link></h3>
                            <p>Start by evaluating your skills, interests, and passions. What are you good at, and what...
                            </p>
                            <div class="blog-read">
                                <router-link to="/blog/blog-details">Read More <i
                                        class="fas fa-arrow-right ms-1"></i></router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="grid-blog blog-two aos" data-aos="fade-up">
                        <div class="blog-image">
                            <router-link to="/blog/blog-details"><img class="img-fluid" src="@/assets/img/blog/blog-14.jpg"
                                    alt="Post Image"></router-link>
                        </div>
                        <div class="blog-content">
                            <div class="feature-time-blk">
                                <span class="badge bg-pink d-flex align-items-center"><i
                                        class="feather-tag me-1"></i>Jobs</span>
                                <span><i class="far fa-calendar me-1"></i> 06 Oct, 2023</span>
                            </div>
                            <h3 class="blog-title mt-0"><router-link to="/blog/blog-details">Interview Success: How to Ace Common
                                    Interview Questions</router-link></h3>
                            <p>Select a weakness that is not a critical skill for the job and discuss how you've been
                                working...</p>
                            <div class="blog-read">
                                <router-link to="/blog/blog-details">Read More <i
                                        class="fas fa-arrow-right ms-1"></i></router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- / News -->

    <!-- News -->
    <section class="section job-register-two">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="register-job-blk justify-content-center">
                        <div class="job-content-blk text-center aos" data-aos="fade-up">
                            <h2>Find Your Next Great Job Opportunity!</h2>
                            <p>Quisque pretium dolor turpis, quis blandit turpis semper ut. Nam malesuada eros nec luctus
                                laoreet.</p>
                        </div>
                        <div class="bg-img">
                            <img src="@/assets/img/bg/job-sec-bg-01.png" class="img-fluid job-right-bg" alt="Img">
                            <img src="@/assets/img/bg/job-sec-bg-02.png" class="img-fluid job-left-bg" alt="Img">
                        </div>
                    </div>
                    <div class="sign-in-btn text-center mt-5 aos" data-aos="fade-up">
                        <router-link to="/home/" class="btn btn-primary">Register Kofejob </router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>


<script>
import { Carousel, Navigation, Slide } from "vue3-carousel";
import CompanyHire from "@/assets/json/company-hire.json";
import "vue3-carousel/dist/carousel.css";
export default {
    data() {
        return {
            CompanyHire: CompanyHire,
            settings: {
                itemsToShow: 1,
                snapAlign: "center",
            },
            breakpoints: {
                575: {
                    itemsToShow: 2,
                    snapAlign: "center",
                },
                767: {
                    itemsToShow: 3,
                    snapAlign: "center",
                },
                991: {
                    itemsToShow: 3,
                    snapAlign: "center",
                },
                1024: {
                    itemsToShow: 6,
                    snapAlign: "start",
                },
            },
        }
    },
    components: {
        Carousel,
        Slide,
        Navigation,
    },
}
</script>