<template>
  <!-- Page Content -->
  <div class="login-wrapper account-page">
    <div class="content">
      <!-- Login Content -->
      <div class="account-content">
        <div class="align-items-center justify-content-center">
          <div class="login-right">
            <div class="login-header text-center">
              <router-link to="/home/"
                ><img src="@/assets/img/logo.svg" alt="logo" class="img-fluid"
              /></router-link>
              <h3>Welcome! Nice to see you again</h3>
            </div>
            <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
              <div class="input-block">
                <label class="focus-label"
                  >Email Address <span class="label-star"> *</span></label
                >
                <Field
                  name="email"
                  type="text"
                  value="example@dreamstechnologies.com"
                  class="form-control"
                  :class="{ 'is-invalid': errors.email }"
                />
                <div class="invalid-feedback">{{ errors.email }}</div>
                <div class="emailshow text-danger" id="email"></div>
              </div>
              <div class="input-block">
                <label class="focus-label"
                  >Password <span class="label-star"> *</span></label
                >
                <div class="position-relative">
                  <Field
                    name="password"
                    :type="showPassword ? 'text' : 'password'"
                    value="123456"
                    class="form-control pass-input mt-2"
                    :class="{ 'is-invalid': errors.password }"
                  />
                  <span @click="toggleShow" class="toggle-password2">
                    <i
                      :class="{
                        'fas fa-eye': showPassword,
                        'fas fa-eye-slash': !showPassword,
                      }"
                    ></i>
                  </span>
                  <div class="invalid-feedback">{{ errors.password }}</div>
                  <div class="emailshow text-danger" id="password"></div>
                </div>
              </div>
              <button
                class="btn btn-primary w-100 btn-lg login-btn d-flex align-items-center justify-content-center"
                type="submit"
              >
                Login Now<i class="feather-arrow-right ms-2"></i>
              </button>
              <div class="login-or">
                <p><span>OR</span></p>
              </div>
              <div class="row social-login">
                <div class="col-sm-4">
                  <a href="javascript:void(0);" class="btn btn-block"
                    ><img src="@/assets/img/icon/google-icon.svg" alt="Google" />Google</a
                  >
                </div>
                <div class="col-sm-4">
                  <a href="javascript:void(0);" class="btn btn-block"
                    ><img src="@/assets/img/icon/fb-icon.svg" alt="Fb" />Facebook</a
                  >
                </div>
                <div class="col-sm-4">
                  <a href="javascript:void(0);" class="btn btn-block"
                    ><img src="@/assets/img/icon/ios-icon.svg" alt="Apple" />Apple</a
                  >
                </div>
              </div>
              <div class="row">
                <div class="col-sm-8 dont-have d-flex align-items-center">
                  New to Kofejob
                  <router-link to="/register" class="ms-2">Signup?</router-link>
                </div>
                <div class="col-sm-4 text-sm-end">
                  <router-link class="forgot-link" to="/forgot-password"
                    >Lost Password?</router-link
                  >
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
      <!-- /Login Content -->
    </div>
  </div>

  <!-- /Page Content -->
</template>
<script>
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import { router } from "@/router";
export default {
  components: {
    Form,
    Field,
  },
  data() {
    return {
      showPassword: false,
      password: null,
      emailError: "",
      passwordError: "",
    };
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    onSubmit(values) {
      this.emailError = "";
      this.passwordError = "";

      let data = localStorage.getItem("storedData");
      var Pdata = JSON.parse(data);
      const Eresult = Pdata.find(({ email }) => email === values.email);
      if (Eresult) {
        if (Eresult.password === values.password) {
          this.$router.push("/home"); // Use this.$router instead of router
        } else {
          this.passwordError = "Incorrect password";
        }
      } else {
        this.emailError = "Email is not valid";
      }
    },
  },
  setup() {
    let users = localStorage.getItem("storedData");
    if (users === null) {
      let password = [
        {
          email: "example@dreamstechnologies.com",
          password: "123456",
        },
      ];
      const jsonData = JSON.stringify(password);
      localStorage.setItem("storedData", jsonData);
    }
    const schema = Yup.object().shape({
      email: Yup.string().required("Email is required").email("Email is invalid"),
      password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .required("Password is required"),
    });
    return {
      schema,
    };
  },
};
</script>
