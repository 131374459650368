<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>
  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content report-box container-fluid">
      <!-- Page Header -->
      <div class="page-header subscribe-head">
        <div class="row align-items-center">
          <breadcrumbthree :title="title" :text="text" :text1="text1" />

          <div class="col-auto">
            <router-link class="btn back-user" to="/admin/users">
              <img src="@/assets/admin_img/icon/icon-08.svg" alt="Image" /> Back to list
            </router-link>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <!-- User Profile -->
      <div class="profile-status">
        <div class="row">
          <div class="col-lg-6 col-md-6">
            <div class="profile-user-box">
              <router-link to="/admin/profile"
                ><img
                  class="avatar-img rounded-circle"
                  src="@/assets/admin_img/profiles/avatar-16.jpg"
                  alt="User Image"
              /></router-link>
              <div class="profile-name-box">
                <h2><router-link to="/admin/profile">William Cooper</router-link></h2>
                <p>Date of Join: 22 May 2023</p>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-2">
            <div class="additional-info">
              <p>Freelancer ID</p>
              <h5>UID00003</h5>
            </div>
          </div>
          <div class="col-lg-2 col-md-2">
            <div class="additional-info">
              <p>Email</p>
              <h5 class="verify-mail">
                <i class="feather feather-check-circle me-1 text-success"></i> Verified
              </h5>
            </div>
          </div>
          <div class="col-lg-2 col-md-2">
            <div class="additional-info">
              <p>Account Status</p>
              <h5 class="text-danger">Active</h5>
            </div>
          </div>
        </div>
      </div>
      <!-- /User Profile -->

      <div class="row">
        <div class="col-sm-12">
          <div class="card profile-home">
            <userstabset />

            <!-- Active Profile Header -->
            <div class="page-header profile-information">
              <div class="row align-items-center">
                <div class="col-12">
                  <h3 class="page-title">Login Activities</h3>
                  <p>The activities of login for the user.</p>
                </div>
              </div>
            </div>
            <!-- /Active Profile Header -->

            <div class="card">
              <div class="card-body">
                <div class="showentries">
                  <label
                    >Show
                    <select>
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                    entries
                  </label>
                  <div class="entries">
                    <span>Search: </span>
                    <input
                      type="search"
                      class="form-control form-control-sm ms-2"
                      placeholder=""
                      aria-controls="DataTables_Table_0"
                    />
                  </div>
                </div>
                <div class="table-responsive">
                  <a-table
                    class="stripped table-hover"
                    :columns="columns"
                    :data-source="data"
                  >
                    <template #bodyCell="{ column, record }">
                      <template v-if="column.key === 'Name'">
                        <div class="name-user">
                          <a href="javascript:;">{{ record.Name }}</a>
                        </div>
                      </template>
                    </template>
                  </a-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Page Wrapper -->

  <!-- Add Modal -->
  <div class="modal fade custom-modal" id="add-category">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header flex-wrap">
          <div class="text-center w-100 mb-3">
            <img src="@/assets/admin_img/logo-small.png" alt="Image" />
          </div>
          <h4 class="modal-title">Add New User</h4>
          <button type="button" class="close" data-bs-dismiss="modal">
            <span>&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <form>
            <div class="form-group">
              <label>Full Name</label>
              <input type="text" class="form-control" />
            </div>
            <div class="form-group">
              <label>Email</label>
              <input type="email" class="form-control" />
            </div>
            <div class="form-group">
              <label>Password</label>
              <input type="password" class="form-control" />
            </div>
            <div class="form-group">
              <label>Confirm Password</label>
              <input type="password" class="form-control" />
            </div>
            <div class="form-group">
              <label>User Type</label>
              <select class="form-control form-select">
                <option>Select</option>
                <option>Frontend Developer</option>
                <option>Graphic Designer</option>
              </select>
            </div>
            <div class="mt-4">
              <button type="submit" class="btn btn-primary btn-block">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- Add Modal -->

  <!-- Add Modal -->
  <div class="modal fade custom-modal" id="edit-category">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header flex-wrap">
          <div class="text-center w-100 mb-3">
            <img src="@/assets/admin_img/logo-small.png" alt="Image" />
          </div>
          <h4 class="modal-title">Edit User</h4>
          <button type="button" class="close" data-bs-dismiss="modal">
            <span>&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <form>
            <div class="form-group">
              <label>Full Name</label>
              <input type="text" class="form-control" value=" George Wells" />
            </div>
            <div class="form-group">
              <label>Email</label>
              <input type="email" class="form-control" value="georgewells@example.com" />
            </div>
            <div class="form-group">
              <label>Password</label>
              <input type="password" class="form-control" value="*******" />
            </div>
            <div class="form-group">
              <label>Confirm Password</label>
              <input type="password" class="form-control" value="********" />
            </div>
            <div class="form-group">
              <label>User Type</label>
              <select class="form-control form-select">
                <option>Select</option>
                <option selected>Frontend Developer</option>
                <option>Graphic Designer</option>
              </select>
            </div>
            <div class="mt-4">
              <button type="submit" class="btn btn-primary btn-block">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- Add Modal -->

  <!--Delete modal-->
  <delete />
  <!-- /Delete modal-->
</template>

<script>
const columns = [
  {
    title: "Name",
    dataIndex: "Name",
    key: "Name",
    sorter: {
      compare: (a, b) => {
        a = a.Name.toLowerCase();
        b = b.Name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Event Info",
    dataIndex: "Event_Info",
    key: "id",
    sorter: (a, b) => a.Event_Info.localeCompare(b.Amount),
  },
  {
    title: "Detection IP",
    dataIndex: "Detection_IP",
    sorter: (a, b) => a.Detection_IP.localeCompare(b.Date),
  },
  {
    title: "Detection Time",
    dataIndex: "Detection_Time",
    sorter: {
      compare: (a, b) => {
        a = a.Detection_Time.toLowerCase();
        b = b.Detection_Time.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
];
const data = [
  {
    Name: "User Login",
    Event_Info: "Login to “System/Administrator”",
    Detection_IP: "116.165.159.125",
    Detection_Time: "Apr 22, 2023 05:24 AM",
  },
  {
    Name: "Task Updation",
    Event_Info: "Change task “Inprrogress” to “Completed”",
    Detection_IP: "104.165.159.125",
    Detection_Time: "May 26, 2023 05:14 AM",
  },
  {
    Name: "User Logoff",
    Event_Info: "Logoff “System/Administrator”",
    Detection_IP: "123.165.159.125",
    Detection_Time: "Jun 12, 2023 03:54 AM",
  },
  {
    Name: "Task Updation",
    Event_Info: "Change task “Inprrogress” to “Completed”",
    Detection_IP: "104.165.159.125",
    Detection_Time: "Dec 16, 2023 02:24 AM",
  },
  {
    Name: "User Logoff",
    Event_Info: "Logoff “System/Administrator”",
    Detection_IP: "123.165.159.125",
    Detection_Time: "Feb 10, 2023 04:44 AM",
  },
];
export default {
  data() {
    return {
      data,
      columns,
      title: "Freelancer Profile",
      text: "Home",
      text1: "Freelancer Profile",
    };
  },
};
</script>
