<template>
  <!-- The Modal -->
  <div class="modal fade edit-proposal-modal" id="add-milestone">
    <div class="modal-dialog modal-dialog-centered modal-md">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Add New File</h4>
          <span class="modal-close"
            ><a href="javascript:void(0);" data-bs-dismiss="modal" aria-label="Close"
              ><i class="feather-x"></i></a
          ></span>
        </div>
        <div class="modal-body">
          <form action="#">
            <div class="modal-info">
              <div class="row">
                <div class="col-md-12 col-lg-12">
                  <div class="pro-form-img">
                    <div class="profile-pic">Profile Photo</div>
                    <div class="upload-files">
                      <label class="file-upload image-upbtn">
                        <i class="feather-upload me-1"></i>
                        Upload Photo <input type="file" />
                      </label>
                      <span
                        >For better preview recommended size is 450px x 450px. Max size
                        5mb.</span
                      >
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="input-block">
                    <label class="form-label">Title</label>
                    <input class="form-control" type="text" />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="input-block">
                    <label class="form-label">Description</label>
                    <textarea class="form-control summernote"></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="submit-section text-end">
              <a href="javascript:void(0);" class="btn btn-cancel">Cancel</a>
              <button type="submit" class="btn btn-primary submit-btn">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /The Modal -->
</template>
