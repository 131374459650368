<template>
  <div class="stickysidebar">
    <div class="settings-widget">
      <div
        class="settings-header d-sm-flex flex-row flex-wrap text-center text-sm-start align-items-center"
      >
        <router-link to="/employers/company-profile"
          ><img
            alt="profile image"
            src="@/assets/img/user/avatar-1.jpg"
            class="avatar-lg rounded-circle"
        /></router-link>
        <div class="ms-sm-3 ms-md-0 ms-lg-3 mt-2 mt-sm-0 mt-md-2 mt-lg-0">
          <h3 class="mb-0">
            <router-link to="/employers/company-profile">Bruce Bush</router-link
            ><img src="@/assets/img/icon/verified-badge.svg" class="ms-1" alt="Img" />
          </h3>
          <p class="mb-0">@brucebush</p>
        </div>
      </div>
      <div class="settings-menu">
        <div id="sidebar-menu" class="sidebar-menu">
          <ul>
            <li class="nav-item">
              <router-link to="/freelancer/freelancer-dashboard" class="nav-link">
                <img src="@/assets/img/icon/sidebar-icon-01.svg" alt="Img" />
                Dashboard
              </router-link>
            </li>
            <li class="nav-item submenu">
              <a
                href="#sidebarProjects"
                data-bs-toggle="collapse"
                role="button"
                aria-expanded="false"
                aria-controls="sidebarProjects"
                :class="{ 'active subdrop': ProjectsMenus, notactive: !ProjectsMenus }"
                @click="ProjectsMenus = !ProjectsMenus"
                class="nav-link"
              >
                <img src="@/assets/img/icon/sidebar-icon-02.svg" alt="Img" /> Projects
                <span class="menu-arrow"></span>
              </a>
              <ul class="sub-menu-ul collapse menu-dropdown" id="sidebarProjects">
                <li>
                  <router-link
                    :class="{
                      active: currentPath === '/freelancer/freelancer-project-proposals',
                      notactive:
                        currentPath !== '/freelancer/freelancer-project-proposals',
                    }"
                    to="/freelancer/freelancer-project-proposals"
                  >
                    My Proposal
                  </router-link>
                </li>
                <li>
                  <router-link
                    :class="{
                      active: currentPath === '/freelancer/freelancer-ongoing-projects',
                      notactive:
                        currentPath !== '/freelancer/freelancer-ongoing-projects',
                    }"
                    to="/freelancer/freelancer-ongoing-projects"
                  >
                    Ongoing Projects
                  </router-link>
                </li>
                <li>
                  <router-link
                    :class="{
                      active: currentPath === '/freelancer/freelancer-completed-projects',
                      notactive:
                        currentPath !== '/freelancer/freelancer-completed-projects',
                    }"
                    to="/freelancer/freelancer-completed-projects"
                  >
                    Completed Projects
                  </router-link>
                </li>
                <li>
                  <router-link
                    :class="{
                      active: currentPath === '/freelancer/freelancer-cancelled-projects',
                      notactive:
                        currentPath !== '/freelancer/freelancer-cancelled-projects',
                    }"
                    to="/freelancer/freelancer-cancelled-projects"
                  >
                    Cancelled Projects
                  </router-link>
                </li>
              </ul>
            </li>
            <li class="nav-item submenu">
              <a
                href="#sidebarFavourites"
                data-bs-toggle="collapse"
                role="button"
                aria-expanded="false"
                aria-controls="sidebarFavourites"
                v-bind:class="FavouritesMenu ? 'active subdrop' : 'notactive'"
                v-on:click="FavouritesMenus = !FavouritesMenus"
                :class="{ subdrop: FavouritesMenus }"
                class="nav-link"
              >
                <img src="@/assets/img/icon/sidebar-icon-03.svg" alt="Img" />
                Favourites
                <span class="menu-arrow"></span>
              </a>
              <ul class="sub-menu-ul collapse menu-dropdown" id="sidebarFavourites">
                <li>
                  <router-link
                    :class="
                      currentPath == '/freelancer/freelancer-favourites'
                        ? 'active'
                        : 'notactive'
                    "
                    to="/freelancer/freelancer-favourites"
                    >Bookmarked Projects</router-link
                  >
                </li>
                <li>
                  <router-link
                    :class="
                      currentPath == '/freelancer/freelancer-invitations'
                        ? 'active'
                        : 'notactive'
                    "
                    to="/freelancer/freelancer-invitations"
                    >Invitations</router-link
                  >
                </li>
              </ul>
            </li>
            <li class="nav-item">
              <router-link to="/freelancer/freelancer-chats" class="nav-link">
                <img src="@/assets/img/icon/sidebar-icon-04.svg" alt="Img" /> Reviews
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/freelancer/freelancer-portfolio" class="nav-link">
                <img src="@/assets/img/icon/sidebar-icon-05.svg" alt="Img" />
                Portfolio
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/freelancer/freelancer-chats" class="nav-link">
                <img src="@/assets/img/icon/sidebar-icon-06.svg" alt="Img" /> Chat
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="freelancer-withdraw-money" class="nav-link">
                <img src="@/assets/img/icon/sidebar-icon-07.svg" alt="Img" /> Payments
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="freelancer-payout" class="nav-link">
                <img src="@/assets/img/icon/sidebar-icon-08.svg" alt="Img" /> Payout
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="freelancer-withdraw-money" class="nav-link">
                <img src="@/assets/img/icon/sidebar-icon-09.svg" alt="Img" />
                Statement
              </router-link>
            </li>
            <li class="nav-item">
              <a
                href="#sidebarSettings"
                data-bs-toggle="collapse"
                role="button"
                aria-expanded="false"
                aria-controls="sidebarSettings"
                v-bind:class="SettingsMenu ? 'active subdrop' : 'notactive'"
                v-on:click="SettingsMenus = !SettingsMenus"
                :class="{ subdrop: SettingsMenus }"
                class="nav-link"
              >
                <img src="@/assets/img/icon/sidebar-icon-10.svg" alt="Img" /> Settings
                <span class="menu-arrow"></span>
              </a>
              <ul class="sub-menu-ul collapse menu-dropdown" id="sidebarSettings">
                <li>
                  <router-link
                    :class="
                      currentPath == '/freelancer/freelancer-profile-settings'
                        ? 'active'
                        : 'notactive'
                    "
                    to="/freelancer/freelancer-profile-settings"
                    >Profile</router-link
                  >
                </li>
                <li>
                  <router-link
                    :class="
                      currentPath == '/freelancer/freelancer-membership'
                        ? 'active'
                        : 'notactive'
                    "
                    to="/freelancer/freelancer-membership"
                    >Plan & Billing</router-link
                  >
                </li>
                <li>
                  <router-link
                    :class="
                      currentPath == '/freelancer/freelancer-verify-identity'
                        ? 'active'
                        : 'notactive'
                    "
                    to="/freelancer/freelancer-verify-identity"
                    >Verify Identity</router-link
                  >
                </li>
                <li>
                  <router-link
                    :class="
                      currentPath == '/freelancer/freelancer-change-password'
                        ? 'active'
                        : 'notactive'
                    "
                    to="/freelancer/freelancer-change-password"
                    >Change Password</router-link
                  >
                </li>
                <li>
                  <router-link
                    :class="
                      currentPath == '/freelancer/freelancer-delete-account'
                        ? 'active'
                        : 'notactive'
                    "
                    to="/freelancer/freelancer-delete-account"
                    >Delete Account</router-link
                  >
                </li>
              </ul>
            </li>
            <li class="nav-item">
              <router-link to="/home/" class="nav-link">
                <img src="@/assets/img/icon/sidebar-icon-11.svg" alt="Img" /> Logout
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      ProjectsMenus: false,
      FavouritesMenus: false,
      SettingsMenus: false,
      activeClass: "active",
    };
  },
  computed: {
    currentPath() {
      return this.$route.name;
    },
    ProjectsMenu() {
      return (
        this.$route.name == "/freelancer/freelancer-project-proposals" ||
        this.$route.name == "/freelancer/freelancer-ongoing-projects" ||
        this.$route.name == "/freelancer/freelancer-completed-projects" ||
        this.$route.name == "/freelancer/freelancer-cancelled-projects"
      );
    },
    FavouritesMenu() {
      return (
        this.$route.name == "/freelancer/freelancer-favourites" ||
        this.$route.name == "/freelancer/freelancer-invitations"
      );
    },
    SettingsMenu() {
      return (
        this.$route.name == "/freelancer/freelancer-profile-settings" ||
        this.$route.name == "/freelancer/freelancer-membership" ||
        this.$route.name == "/freelancer/freelancer-verify-identity" ||
        this.$route.name == "/freelancer/freelancer-change-password" ||
        this.$route.name == "/freelancer/freelancer-delete-account"
      );
    },
  },
};
</script>
