<template>
  <div class="col-lg-4 col-md-12 sidebar-right theiaStickySidebar project-client-view">
    <div class="stickysidebar">
      <div class="card budget-widget">
        <div class="budget-widget-details">
          <h6>Budget</h6>
          <h4>$125 - $180</h4>
          <p class="mb-0">Hourly Rate</p>
          <ul class="buget-profiles">
            <li>
              <h6><i class="feather-map-pin me-2"></i>Location</h6>
              <h5>London, UK</h5>
            </li>
            <li>
              <h6><i class="feather-airplay me-2"></i>Years of Experience</h6>
              <h5>5 Years</h5>
            </li>
            <li>
              <h6><i class="feather-calendar me-2"></i>Delivery Time</h6>
              <h5>3-5 Days</h5>
            </li>
            <li>
              <h6><i class="feather-globe me-2"></i>Language Level</h6>
              <h5>Basic</h5>
            </li>
            <li>
              <h6><i class="feather-phone me-2"></i>Phone</h6>
              <h5>+91 9988776655</h5>
            </li>

            <li>
              <h6><i class="feather-mail me-2"></i>Mail</h6>
              <h5>info@example.com</h5>
            </li>
          </ul>
        </div>
        <div>
          <a data-bs-toggle="modal" href="#file" class="btn proposal-btn btn-primary"
            >Send Invite</a
          >
        </div>
      </div>
      <div class="card budget-widget">
        <h6>Skills</h6>
        <div class="tags">
          <a href="javascript:void(0);"
            ><span class="badge badge-pill badge-design">After Effects</span></a
          >
          <a href="javascript:void(0);"
            ><span class="badge badge-pill badge-design">Illustrator</span></a
          >
          <a href="javascript:void(0);"
            ><span class="badge badge-pill badge-design">HTML</span></a
          >
          <a href="javascript:void(0);"
            ><span class="badge badge-pill badge-design">Whiteboard</span></a
          >
          <a href="javascript:void(0);"
            ><span class="badge badge-pill badge-design">Software Design</span></a
          >
          <a href="javascript:void(0);"
            ><span class="badge badge-pill badge-design">HTML5</span></a
          >
          <a href="javascript:void(0);"
            ><span class="badge badge-pill badge-design">Design Writing</span></a
          >
          <a href="javascript:void(0);"
            ><span class="badge badge-pill badge-design">Whiteboard</span></a
          >
          <a href="javascript:void(0);"
            ><span class="badge badge-pill badge-design">Web design</span></a
          >
        </div>
      </div>
      <div class="card budget-widget">
        <h6>Location</h6>
        <div class="map-location p-0">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d2965.0824050173574!2d-93.63905729999999!3d41.998507000000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sWebFilings%2C+University+Boulevard%2C+Ames%2C+IA!5e0!3m2!1sen!2sus!4v1390839289319"
          ></iframe>
        </div>
      </div>
    </div>
  </div>
</template>
