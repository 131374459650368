<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>

  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content report-box container-fluid">
      <!-- Page Header -->

      <breadcrumbthree :title="title" :text="text" :text1="text1" />

      <!-- /Page Header -->

      <div class="row">
        <div class="col-sm-12">
          <withdrawntabset />

          <!-- Active User Header -->
          <div class="page-header user-active">
            <div class="row align-items-center">
              <div class="col">
                <h3 class="page-title">Pending</h3>
                <p>Total <span>57</span> Transaction</p>
              </div>
              <div class="col-auto">
                <a href="javascript:;" class="btn export-btn me-1">
                  <img src="@/assets/admin_img/export.svg" alt="Image" /> Export
                </a>
                <a
                  class="btn filter-btn"
                  href="javascript:void(0);"
                  @click="toggleFilterInputs"
                  id="filter_search"
                >
                  <i class="fas fa-filter"></i>
                </a>
              </div>
            </div>
          </div>
          <!-- /Active User Header -->

          <!-- Search Filter -->
          <div
            class="card filter-card"
            :style="{ display: filterInputsDisplay }"
            id="filter_inputs"
          >
            <div class="card-body pb-0">
              <form action="javascript:;" method="post">
                <div class="row filter-row">
                  <div class="col-sm-6 col-md-3">
                    <div class="form-group">
                      <label>Name</label>
                      <input class="form-control" type="text" />
                    </div>
                  </div>
                  <div class="col-sm-6 col-md-3">
                    <div class="form-group">
                      <label>Email</label>
                      <input type="email" class="form-control" />
                    </div>
                  </div>
                  <div class="col-sm-6 col-md-3">
                    <div class="form-group">
                      <label>Expertise</label>
                      <input type="text" class="form-control" />
                    </div>
                  </div>
                  <div class="col-sm-6 col-md-3">
                    <div class="form-group">
                      <button class="btn btn-primary btn-block" type="submit">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <!-- /Search Filter -->

          <div class="card">
            <div class="card-body">
              <div class="showentries">
                <label
                  >Show
                  <select>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  entries
                </label>
                <div class="entries">
                  <span>Search: </span>
                  <input
                    type="search"
                    class="form-control form-control-sm ms-2"
                    placeholder=""
                    aria-controls="DataTables_Table_0"
                  />
                </div>
              </div>
              <div class="table-responsive">
                <a-table :columns="columns" :data-source="data">
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key === 'User'">
                      <div class="table-avatar user-profile">
                        <router-link to="/admin/profile"
                          ><img
                            class="avatar-img rounded-circle"
                            :src="require(`@/assets/admin_img/profiles/${record.Img}`)"
                            alt="User Image"
                          />
                        </router-link>
                        <div>
                          <h5>
                            <a href="javascript:;">{{ record.User }}</a>
                          </h5>
                          <p>{{ record.Email }}</p>
                        </div>
                      </div>
                    </template>
                    <template v-else-if="column.key === 'inputbox'">
                      <div class="form-check custom-checkbox">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          id="customCheck5"
                        />
                        <label class="form-check-label" for="customCheck5"></label>
                      </div>
                    </template>
                    <template v-else-if="column.key === 'Details'">
                      <div class="table-avatar content-user">
                        <div>
                          <h5>{{ record.Details }}</h5>
                          <p>{{ record.Dates }}</p>
                        </div>
                      </div>
                    </template>
                    <template v-else-if="column.key === 'Amount'">
                      <div class="table-avatar content-user">
                        <div>
                          <h5 class="text-danger">{{ record.Amount }}</h5>
                          <p>Balance : {{ record.Balance }}</p>
                        </div>
                      </div>
                    </template>
                    <template v-else-if="column.key === 'Status'">
                      <a href="javascript:;" class="pending">{{ record.Status }}</a>
                    </template>
                    <template v-else-if="column.key === 'action'">
                      <div class="text-center three-dots">
                        <a
                          href="javascript:;"
                          class="dropdown-toggle nav-link"
                          data-bs-toggle="dropdown"
                          ><i class="fas fa-ellipsis-v"></i
                        ></a>
                        <div class="dropdown-menu user-menu-list">
                          <a
                            class="dropdown-item"
                            href="javascript:;"
                            data-bs-toggle="modal"
                            data-bs-target="#transaction-category"
                            ><img
                              class="me-2"
                              src="@/assets/admin_img/icon/icon-01.svg"
                              alt="Image"
                            />
                            View Details</a
                          >
                          <router-link class="dropdown-item" to="/admin/user-profile"
                            ><img
                              class="me-2"
                              src="@/assets/admin_img/icon/icon-05.svg"
                              alt="Image"
                            />
                            User Profile</router-link
                          >
                          <a class="dropdown-item" href="javascript:;"
                            ><img
                              class="me-2"
                              src="@/assets/admin_img/icon/icon-06.svg"
                              alt="Image"
                            />
                            Approve</a
                          >
                          <a class="dropdown-item mb-0" href="javascript:;"
                            ><img
                              class="me-2"
                              src="@/assets/admin_img/icon/icon-07.svg"
                              alt="Image"
                            />
                            Reject
                          </a>
                        </div>
                      </div>
                    </template>
                  </template>
                </a-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Page Wrapper -->

  <modelpopup />
</template>

<script>
const columns = [
  {
    title: "#",
    key: "inputbox",
    sorter: true,
  },
  {
    title: "Order",
    dataIndex: "Order",
    sorter: {
      compare: (a, b) => {
        a = a.Order.toLowerCase();
        b = b.Order.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "User",
    dataIndex: "User",
    key: "User",
    sorter: {
      compare: (a, b) => {
        a = a.User.toLowerCase();
        b = b.User.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Details",
    dataIndex: "Details",
    key: "Details",
    sorter: {
      compare: (a, b) => {
        a = a.Details.toLowerCase();
        b = b.Details.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Tnx By",
    dataIndex: "TnxBy",
    key: "TnxBy",
    sorter: {
      compare: (a, b) => {
        a = a.TnxBy.toLowerCase();
        b = b.TnxBy.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Amount",
    dataIndex: "Amount",
    key: "Amount",
    sorter: {
      compare: (a, b) => {
        a = a.Amount.toLowerCase();
        b = b.Amount.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Status",
    dataIndex: "Status",
    key: "Status",
    sorter: {
      compare: (a, b) => {
        a = a.Status.toLowerCase();
        b = b.Status.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "action",
    sorter: true,
  },
];

const data = [
  {
    FIELD1: "",
    Img: "avatar-14.jpg",
    Order: "#34586414",
    User: "Janet Paden",
    Email: "georgewells@example.com",
    Details: "Withdraw Funds",
    Dates: "Feb 25, 2023",
    TnxBy: "PayPal",
    Amount: "- 5,165,000 USD",
    Balance: "6,335.006 USD",
    Status: "Pending",
  },
  {
    FIELD1: "",
    Img: "avatar-15.jpg",
    Order: "#24586414",
    User: "Floyd Lewis",
    Email: "floydlewis@example.com",
    Details: "Withdraw Funds",
    Dates: "Apr 25, 2023",
    TnxBy: "Bank Transfer",
    Amount: "- 5,165,000 USD",
    Balance: "1,335.006 USD",
    Status: "Pending",
  },
  {
    FIELD1: "",
    Img: "avatar-16.jpg",
    Order: "#54586414",
    User: "Andrew Glover",
    Email: "Andrewglover@example.com",
    Details: "Withdraw Funds",
    Dates: "Jun 23, 2023",
    TnxBy: "Bank Transfer",
    Amount: "- 2,335.006 USD",
    Balance: "1,335.006 USD",
    Status: "Pending",
  },
  {
    FIELD1: "",
    Img: "avatar-14.jpg",
    Order: "#34586414",
    User: "Janet Paden",
    Email: "georgewells@example.com",
    Details: "Withdraw Funds",
    Dates: "Feb 25, 2023",
    TnxBy: "PayPal",
    Amount: "- 5,165,000 USD",
    Balance: "6,335.006 USD",
    Status: "Pending",
  },
  {
    FIELD1: "",
    Img: "avatar-14.jpg",
    Order: "#64586414",
    User: "Floyd Lewis",
    Email: "floydlewis@example.com",
    Details: "Withdraw Funds",
    Dates: "Apr 15, 2023",
    TnxBy: "Bank Transfer",
    Amount: "- 5,165,000 USD",
    Balance: "1,335.006 USD",
    Status: "Pending",
  },
  {
    FIELD1: "",
    Img: "avatar-16.jpg",
    Order: "#54586414",
    User: "Andrew Glover",
    Email: "Andrewglover@example.com",
    Details: "Withdraw Funds",
    Dates: "Jun 23, 2023",
    TnxBy: "Bank Transfer",
    Amount: "- 2,335.006 USD",
    Balance: "1,335.006 USD",
    Status: "Pending",
  },
];

import withdrawntabset from "@/components/admin/withdrawntabset.vue";
export default {
  data() {
    return {
      title: "Withdrawals",
      text: "Home",
      text1: "Withdrawals",
      columns,
      data,
      filterInputsDisplay: "none",
    };
  },
  methods: {
    toggleFilterInputs() {
      this.filterInputsDisplay = this.filterInputsDisplay === "none" ? "block" : "none";
    },
  },
};
</script>
