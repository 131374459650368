<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>

  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content container-fluid">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row align-items-center">
          <div class="col">
            <breadcrumbadmin :title="title" :text="text" :text1="text1" :text2="text2" />
          </div>
          <div class="col-auto">
            <a
              href="javascript:;"
              class="btn add-button me-2"
              data-bs-toggle="modal"
              data-bs-target="#add-category"
            >
              <i class="fas fa-plus"></i>
            </a>
            <a
              class="btn filter-btn"
              href="javascript:void(0);"
              @click="toggleFilterInputs"
              id="filter_search"
            >
              <i class="fas fa-filter"></i>
            </a>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <!-- Search Filter -->
      <div
        class="card filter-card"
        :style="{ display: filterInputsDisplay }"
        id="filter_inputs"
      >
        <div class="card-body pb-0">
          <form action="javascript:;" method="post">
            <div class="row filter-row">
              <div class="col-sm-6 col-md-3">
                <div class="form-group">
                  <label>Projects Fees</label>
                  <input class="form-control" type="text" />
                </div>
              </div>
              <div class="col-sm-6 col-md-3">
                <div class="form-group">
                  <label>From Date</label>
                  <div class="cal-icon">
                    <datepicker
                      v-model="startdate"
                      placeholder="Choose Date"
                      class="form-control datetimepicker"
                      :editable="true"
                      :clearable="false"
                    />
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-3">
                <div class="form-group">
                  <label>To Date</label>
                  <div class="cal-icon">
                    <datepicker
                      v-model="startdate"
                      placeholder="Choose Date"
                      class="form-control datetimepicker"
                      :editable="true"
                      :clearable="false"
                    />
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-3">
                <div class="form-group">
                  <button class="btn btn-primary btn-block" type="submit">Submit</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <!-- /Search Filter -->

      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
                <div class="showentries">
                  <label
                    >Show
                    <select>
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                    entries
                  </label>
                  <div class="entries">
                    <span>Search: </span>
                    <input
                      type="search"
                      class="form-control form-control-sm ms-2"
                      placeholder=""
                      aria-controls="DataTables_Table_0"
                    />
                  </div>
                </div>
                <a-table
                  class="stripped table-hover"
                  :columns="columns"
                  :data-source="data"
                >
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key === 'Name'">
                      <div>{{ record.Name }}</div>
                    </template>
                    <template v-else-if="column.key === 'Info'">
                      <div class="white-space">
                        {{ record.Info }}
                      </div>
                    </template>
                    <template v-else-if="column.key === 'id'">
                      <td class="text-end">
                        <a
                          href="javascript:void(0);"
                          class="btn btn-sm btn-secondary me-2"
                          data-bs-toggle="modal"
                          data-bs-target="#edit-category"
                          ><i class="far fa-edit"></i
                        ></a>
                        <a
                          href="javascript:void(0);"
                          class="btn btn-sm btn-danger"
                          data-bs-toggle="modal"
                          data-bs-target="#delete_category"
                          ><i class="far fa-trash-alt"></i
                        ></a>
                      </td>
                    </template>
                  </template>
                </a-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Wrapper -->
  </div>

  <!-- Category Modal -->
  <div class="modal fade custom-modal" id="add-category">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Add Project Fees</h4>
          <button type="button" class="close" data-bs-dismiss="modal">
            <span>&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="form-group">
              <label>Name</label>
              <input type="text" class="form-control" placeholder="Enter Fee Name" />
            </div>
            <div class="form-group">
              <label>Info</label>
              <textarea rows="5" class="form-control" placeholder="Enter Info"></textarea>
            </div>
            <div class="form-group">
              <label>Value</label>
              <input type="text" class="form-control" placeholder="Enter Value" />
            </div>
            <div class="mt-4">
              <button type="submit" class="btn btn-primary btn-block">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Category Modal -->

  <!-- Edit Modal -->
  <div class="modal fade custom-modal" id="edit-category">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Edit Project Fees</h4>
          <button type="button" class="close" data-bs-dismiss="modal">
            <span>&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="form-group">
              <label>Name</label>
              <input type="text" class="form-control" value="Featured" />
            </div>
            <div class="form-group">
              <label>Info</label>
              <textarea rows="5" class="form-control">
Featured projects attract more,higher-quality bids.	</textarea
              >
            </div>
            <div class="form-group">
              <label>Value</label>
              <input type="text" class="form-control" value="$450.00" />
            </div>
            <div class="mt-4">
              <button type="submit" class="btn btn-primary btn-block">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Edit Modal -->
  <delete />
</template>

<script>
const columns = [
  {
    title: "Name",
    dataIndex: "Name",
    key: "Name",
    sorter: {
      compare: (a, b) => {
        a = a.Name.toLowerCase();
        b = b.Name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Info",
    dataIndex: "Info",
    key: "Info",
    sorter: {
      compare: (a, b) => {
        a = a.Info.toLowerCase();
        b = b.Info.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Value",
    dataIndex: "Value",
    sorter: {
      compare: (a, b) => {
        a = a.Budget.toLowerCase();
        b = b.Budget.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "id",
    class: "text-end",
    sorter: true,
  },
];
const data = [
  {
    Name: "Featured",
    Info: "Featured projects attract more,higher-quality bids.",
    Value: "$450.00",
    Actions: "",
  },
  {
    Name: "Posting a Project",
    Info:
      "Marks a project as urgent. Receive a faster response from Freelancers to get your project started within 24 hours.",
    Value: "$500.00",
    Actions: "",
  },
  {
    Name: "Urgent",
    Info:
      "Marks a project as urgent. Receive a faster response from Freelancers to get your project started within 24 hours.",
    Value: "$590.00",
    Actions: "",
  },
];

import { ref } from "vue";
const currentDate = ref(new Date());
export default {
  data() {
    return {
      columns,
      data,
      title: "Projects Fees",
      text: "Home",
      text1: "Fees",
      text2: "Projects Fees",
      filterInputsDisplay: "none",
      startdate: currentDate,
    };
  },
  methods: {
    toggleFilterInputs() {
      this.filterInputsDisplay = this.filterInputsDisplay === "none" ? "block" : "none";
    },
  },
};
</script>
