<template>
  <div class="table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th>Purchased Date</th>
          <th>Details</th>
          <th>Expiry Date</th>
          <th>Type</th>
          <th>Price</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="record in Membership_Table" :key="record.id">
          <td>{{ record.PurchasedDate }}</td>
          <td class="invoice-td">
            <p class="mb-0 fw-bold">{{ record.Details }}</p>
            <a href="javascript:void(0);" class="">{{ record.Invoice }}</a>
          </td>
          <td>{{ record.ExpiryDate }}</td>
          <td>{{ record.Type }}</td>
          <td>{{ record.Price }}</td>
          <td>
            <div :class="record.Class">
              <span>{{ record.Status }}</span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import Membership_Table from "@/assets/json/membership-table.json";
export default {
  data() {
    return {
      Membership_Table: Membership_Table,
    };
  },
};
</script>
