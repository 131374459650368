<template>
  <div class="dashboard-page">
    <div class="content content-page">
      <div class="container">
        <div class="row">
          <div class="col-xl-3 col-lg-4 theiaStickySidebar">
            <freelancer-sidebar></freelancer-sidebar>
          </div>
          <div class="col-xl-9 col-lg-8">
            <div class="pro-pos">
              <div class="setting-content">
                <form action="freelancer-profile">
                  <div class="card">
                    <div class="pro-head">
                      <h3>Profile Setting</h3>
                    </div>
                    <div class="pro-body">
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="form-row pro-pad pt-0 ps-0">
                            <div class="input-block col-md-6 pro-pic">
                              <label class="form-label">Profile Picture</label>
                              <div class="d-flex align-items-center">
                                <div class="upload-images freelancer-pic-box">
                                  <img
                                    src="@/assets/img/icon/user-img.svg"
                                    alt=""
                                    id="blah"
                                  />
                                </div>
                                <div class="ms-3 freelancer-pic-upload">
                                  <label class="file-upload image-upbtn">
                                    Upload Image <input type="file" id="imgInp" />
                                  </label>
                                  <p>Max Image size 300*300</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                          <div class="mb-3">
                            <label class="form-label">First Name</label>
                            <input type="text" class="form-control" />
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                          <div class="mb-3">
                            <label class="form-label">Last Name</label>
                            <input type="text" class="form-control" />
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                          <div class="mb-3">
                            <label class="form-label">Phone Number</label>
                            <input type="text" class="form-control" />
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                          <div class="mb-3">
                            <label class="form-label">Email Address</label>
                            <input type="text" class="form-control" />
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-12">
                          <div class="mb-3">
                            <label class="form-label">Date of Birth</label>
                            <input type="text" class="form-control" />
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-12">
                          <div class="mb-3">
                            <label class="form-label">Your Job Title</label>
                            <input type="text" class="form-control" />
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-12">
                          <div class="mb-3">
                            <label class="form-label">Type of Job</label>
                            <input type="text" class="form-control" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card">
                    <div class="pro-head">
                      <h4 class="pro-titles mb-0">Overview</h4>
                    </div>
                    <div class="pro-body">
                      <div class="row">
                        <div class="input-block col-md-12">
                          <label class="form-label">Description</label>
                          <textarea class="form-control" rows="5"></textarea>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-row">
                    <freelancerskills></freelancerskills>

                    <freelancereducation></freelancereducation>

                    <freelancercertification></freelancercertification>

                    <freelancerexperience></freelancerexperience>

                    <freelancerlanguage></freelancerlanguage>

                    <div class="col-lg-12 w-100">
                      <div class="card flex-fill mb-3">
                        <div class="pro-head">
                          <h4 class="pro-titles mb-0">Social Media</h4>
                        </div>
                        <div class="pro-body">
                          <div class="form-row align-items-center skill-cont">
                            <div class="input-block col-lg-4">
                              <label class="form-label">Facebook</label>
                              <input type="text" class="form-control" />
                            </div>
                            <div class="input-block col-lg-4">
                              <label class="form-label">Instagram </label>
                              <input type="text" class="form-control" />
                            </div>
                            <div class="input-block col-lg-4">
                              <label class="form-label">LinkedIn</label>
                              <input type="text" class="form-control" />
                            </div>
                            <div class="input-block col-lg-4">
                              <label class="form-label">Behance</label>
                              <input type="text" class="form-control" />
                            </div>
                            <div class="input-block col-lg-4">
                              <label class="form-label">Pinterest </label>
                              <input type="text" class="form-control" />
                            </div>
                            <div class="input-block col-lg-4">
                              <label class="form-label">Twitter</label>
                              <input type="text" class="form-control" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-12">
                      <div class="card flex-fill mb-3">
                        <div class="pro-head">
                          <h4 class="pro-titles mb-0">Personal Website</h4>
                        </div>
                        <div class="pro-body">
                          <div class="form-row align-items-center skill-cont">
                            <div class="input-block col-lg-12">
                              <label class="form-label">Website Url</label>
                              <input type="text" class="form-control" />
                            </div>
                            <div class="input-block col-lg-3">
                              <label class="form-label">City</label>
                              <input type="text" class="form-control" />
                            </div>
                            <div class="input-block col-lg-3">
                              <label class="form-label">State / Province</label>
                              <input type="text" class="form-control" />
                            </div>
                            <div class="input-block col-lg-3">
                              <label class="form-label">ZIP / Post Code</label>
                              <input type="text" class="form-control" />
                            </div>
                            <div class="input-block col-md-3">
                              <label class="form-label">Country</label>
                              <vue-select
                                :options="SelectCountry"
                                id="selectcountry"
                                placeholder="Select"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <freelancersamedate></freelancersamedate>

                  <div class="card text-end border-0">
                    <div class="pro-body">
                      <button class="btn btn-secondary click-btn btn-plan">Cancel</button>
                      <button class="btn btn-primary click-btn btn-plan" type="submit">
                        Update
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      SelectCountry: ["Select", "USA", "UK"],
    };
  },
};
</script>
