<template>
  <div class="col-lg-12 w-100">
    <div class="card flex-fill mb-3">
      <div class="pro-head">
        <h4 class="pro-titles without-border mb-0">Certification</h4>
      </div>
      <div class="pro-body">
        <div class="form-row align-items-center skill-cont">
          <div class="input-block col-lg-4">
            <label class="form-label">Certification or Award</label>
            <input type="text" class="form-control" value="Feast of UI" />
          </div>
          <div class="input-block col-lg-4">
            <label class="form-label">Certified from</label>
            <input type="text" class="form-control" value="PSD Technologies" />
          </div>
          <div class="col-lg-3 input-block floating-icon">
            <label class="form-label">Year</label>
            <datepicker
              v-model="startdate"
              placeholder="Choose"
              class="form-control datetimepicker"
              :editable="true"
              :clearable="false"
            />
          </div>
          <div class="input-block col-lg-1 mb-0">
            <a
              href="javascript:void(0);"
              class="btn trash-icon"
              @click="deletecertify(index)"
              ><i class="far fa-trash-alt"></i
            ></a>
          </div>
          <div id="certi_add_row" class="w-100">
            <div class="col-lg-12 w-100" v-for="(certify, index) in certify" :key="index">
              <div class="card flex-fill mb-3">
                <div class="pro-head">
                  <h4 class="pro-titles without-border mb-0">Certification</h4>
                </div>
                <div class="pro-body">
                  <div class="form-row align-items-center skill-cont">
                    <div class="input-block col-lg-4">
                      <label class="form-label">Certification or Award</label>
                      <input type="text" class="form-control" value="Feast of UI" />
                    </div>
                    <div class="input-block col-lg-4">
                      <label class="form-label">Certified from</label>
                      <input type="text" class="form-control" value="PSD Technologies" />
                    </div>
                    <div class="col-lg-3 input-block floating-icon">
                      <label class="form-label">Year</label>
                      <datepicker
                        v-model="startdateTwo"
                        placeholder="Choose"
                        class="form-control datetimepicker"
                        :editable="true"
                        :clearable="false"
                      />
                    </div>
                    <div class="input-block col-lg-1 mb-0">
                      <a
                        href="javascript:void(0);"
                        class="btn trash-icon"
                        @click="deletecertify(index)"
                        ><i class="far fa-trash-alt"></i
                      ></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <a
            href="javascript:void(0)"
            class="add-btn-form add-certi w-100 d-block text-end"
            @click="addcertify"
            ><i class="feather-plus-circle me-2"></i>Add New</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
const currentDate = ref(new Date());
const currentDateOne = ref(new Date());
export default {
  data() {
    return {
      certify: [],
      startdate: currentDate,
      startdateOne: currentDateOne,
    };
  },
  methods: {
    addcertify() {
      this.certify.push({});
    },
    deletecertify(index) {
      this.certify.splice(index, 1);
    },
  },
};
</script>
