<template>
  <!-- The Modal -->
  <div class="modal fade edit-proposal-modal" id="add-milestone">
    <div class="modal-dialog modal-dialog-centered modal-md">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Add Milestone</h4>
          <span class="modal-close"
            ><a href="javascript:void(0);" data-bs-dismiss="modal" aria-label="Close"
              ><i class="feather-x"></i></a
          ></span>
        </div>
        <div class="modal-body">
          <form action="#">
            <div class="modal-info">
              <div class="row">
                <div class="col-lg-4">
                  <div class="input-block">
                    <label class="form-label">Milestone name</label>
                    <input type="text" class="form-control" />
                  </div>
                </div>
                <div class="col-lg-2">
                  <div class="input-block">
                    <label class="form-label">Amount</label>
                    <input type="text" class="form-control" />
                    <span class="input-group-text">$</span>
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="input-block">
                    <label class="form-label">Start Date</label>
                    <div class="cal-icon">
                      <input class="form-control datetimepicker" type="date" />
                    </div>
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="input-block">
                    <label class="form-label">End Date</label>
                    <div class="cal-icon">
                      <input class="form-control datetimepicker" type="date" />
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="input-block">
                    <label class="form-label">Description</label>
                    <editor v-model="content" />
                  </div>
                </div>
              </div>
            </div>
            <div class="submit-section text-end">
              <a href="javascript:void(0);" class="btn btn-cancel">Cancel</a>
              <button type="submit" class="btn btn-primary submit-btn">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /The Modal -->
  <!-- The Modal -->
  <div class="modal fade edit-proposal-modal" id="edit-milestone">
    <div class="modal-dialog modal-dialog-centered modal-md">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Edit Milestone</h4>
          <span class="modal-close"
            ><a href="javascript:void(0);" data-bs-dismiss="modal" aria-label="Close"
              ><i class="feather-x"></i></a
          ></span>
        </div>
        <div class="modal-body">
          <form action="#">
            <div class="modal-info">
              <div class="row">
                <div class="col-lg-4">
                  <div class="input-block">
                    <label class="form-label">Milestone name</label>
                    <input type="text" class="form-control" value="Creating Logo" />
                  </div>
                </div>
                <div class="col-lg-2">
                  <div class="input-block">
                    <label class="form-label">Amount</label>
                    <input type="text" class="form-control" value="200" />
                    <span class="input-group-text">$</span>
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="input-block">
                    <label class="form-label">Start Date</label>
                    <div class="cal-icon">
                      <input class="form-control datetimepicker" type="text" />
                    </div>
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="input-block">
                    <label class="form-label">End Date</label>
                    <div class="cal-icon">
                      <input class="form-control datetimepicker" type="text" />
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="input-block">
                    <label class="form-label">Description</label>
                    <textarea class="form-control summernote">
Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</textarea
                    >
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-block">
                    <label class="form-label">Completion (%)</label>
                    <vue-select :options="Completion" id="completion" placeholder="10" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-block">
                    <label class="form-label">Status</label>
                    <vue-select :options="Hold" id="hold" placeholder="Select" />
                  </div>
                </div>
              </div>
            </div>
            <div class="submit-section text-end">
              <a href="javascript:void(0);" class="btn btn-cancel">Cancel</a>
              <button type="submit" class="btn btn-primary submit-btn">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /The Modal -->

  <!-- The Modal -->
  <div class="modal fade edit-proposal-modal" id="view-milestone">
    <div class="modal-dialog modal-dialog-centered modal-md">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">View Milestone</h4>
          <span class="modal-close"
            ><a href="javascript:void(0);" data-bs-dismiss="modal" aria-label="Close"
              ><i class="feather-x"></i></a
          ></span>
        </div>
        <div class="modal-body">
          <div class="d-flex justify-content-between milestone-view">
            <h5>Create desktop applications</h5>
            <span>Amount : $400</span>
          </div>
          <ul class="download-item">
            <li>
              <a href="javascript:void(0);"
                >Preview_Screens.zip <i class="feather-download"></i
              ></a>
            </li>
            <li>
              <a href="javascript:void(0);"
                >Finalupdate.zip <i class="feather-download"></i
              ></a>
            </li>
          </ul>
          <div class="text-end">
            <a href="javascript:void(0);" class="btn btn-primary">Approve</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /The Modal -->

  <!-- The Modal -->
  <div class="modal fade edit-proposal-modal success-modal" id="success-milestone">
    <div class="modal-dialog modal-dialog-centered modal-md">
      <div class="modal-content">
        <div class="modal-header justify-content-end">
          <span class="modal-close"
            ><a href="javascript:void(0);" data-bs-dismiss="modal" aria-label="Close"
              ><i class="feather-x"></i></a
          ></span>
        </div>
        <div class="modal-body">
          <div class="success-msg-content text-center">
            <h4>Payment Initiated Successfully</h4>
            <p>You will be notified when payment is credited to your account</p>
            <router-link to="/employers/manage-projects" class="btn btn-primary mt-3"
              >Go to Projects</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /The Modal -->
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
export default {
  components: {
    editor: Editor,
  },
  data() {
    return {
      content: "Description",
      Completion: ["10", "20", "30", "40"],
      Hold: ["Select", "Approved", "On Hold", "Cancelled"],
    };
  },
};
</script>
