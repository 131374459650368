<template>
  <section class="section review">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="work-set-image">
            <img src="@/assets/img/work4.png" alt="Img" class="img-fluid" />
          </div>
        </div>
        <div class="col-lg-6">
          <div class="aos" data-aos="fade-up">
            <div class="demand-professional">
              <h2>More than 50 million professionals on demand</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
            </div>
            <div class="demand-post-job">
              <div class="demand-post-img">
                <img
                  src="@/assets/img/icon/recent-icon-04.svg"
                  alt="Img"
                  class="img-fluid"
                />
              </div>
              <div class="demand-content">
                <h6>Post a job</h6>
                <p>
                  Publish the job posting on your selected platforms. Follow the
                  specific submission process for each platform.
                </p>
              </div>
            </div>
            <div class="demand-post-job">
              <div class="demand-post-img">
                <img
                  src="@/assets/img/icon/recent-icon-05.svg"
                  alt="Img"
                  class="img-fluid"
                />
              </div>
              <div class="demand-content">
                <h6>Hire Freelancers</h6>
                <p>
                  Depending on the platform, you can either wait for freelancers
                  to apply or invite specific freelancers to submit proposals.
                </p>
              </div>
            </div>
            <div class="demand-post-job">
              <div class="demand-post-img">
                <img
                  src="@/assets/img/icon/recent-icon-06.svg"
                  alt="Img"
                  class="img-fluid"
                />
              </div>
              <div class="demand-content">
                <h6>Get Work Done</h6>
                <p>
                  Utilize productivity tools and apps to help you stay
                  organized, manage tasks, and set reminders.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Marketplace -->
  <section class="section market-place-join">
    <div class="container">
      <div class="market-place-bg">
        <div class="row">
          <div class="col-md-6 d-flex align-items-center">
            <div class="join-market-world aos" data-aos="fade-up">
              <h2>Join World’s Best Marketplace for developers</h2>
              <p>
                Why hire people when you can simply integrate our talented cloud
                workforce instead?
              </p>
              <div class="market-place-btn bidding-btn">
                <router-link
                  to="/freelancer/project"
                  class="btn btn-primary market-project me-2"
                  >Post a Project</router-link
                >
                <router-link to="/freelancer/project" class="btn btn-primary project-post"
                  >Start Bidding</router-link
                >
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="join-platform-img aos" data-aos="fade-up">
              <img
                src="@/assets/img/platform-img1.png"
                alt="Img"
                class="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Marketplace -->
</template>
