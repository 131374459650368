<template>
  <!-- Page Content -->
  <div class="content content-page">
    <div class="container">
      <div class="row">
        <div class="col-xl-3 col-lg-4 theiaStickySidebar">
          <freelancer-sidebar></freelancer-sidebar>
        </div>

        <div class="col-xl-9 col-lg-8">
          <div class="dashboard-sec">
            <div class="page-title">
              <h3>Dashboard</h3>
            </div>
            <div class="row">
              <div class="col-md-6 col-lg-4 col-xl-3">
                <div class="dash-widget">
                  <div class="dash-info">
                    <div class="dashboard-icon">
                      <img
                        src="@/assets/img/icon/freelancer-dashboard-icon-01.svg"
                        alt="Img"
                      />
                    </div>
                    <div class="dash-widget-info">Completed Jobs</div>
                  </div>
                  <div
                    class="dash-widget-more d-flex align-items-center justify-content-between"
                  >
                    <div class="dash-widget-count">30</div>
                    <router-link to="/freelancer/freelancer-completed-projects" class="d-flex"
                      >View Details</router-link
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-4 col-xl-3">
                <div class="dash-widget">
                  <div class="dash-info">
                    <div class="dashboard-icon dashboard-icon-two">
                      <img
                        src="@/assets/img/icon/freelancer-dashboard-icon-02.svg"
                        alt="Img"
                      />
                    </div>
                    <div class="dash-widget-info">Task Completed</div>
                  </div>
                  <div
                    class="dash-widget-more d-flex align-items-center justify-content-between"
                  >
                    <div class="dash-widget-count">5</div>
                    <router-link to="/freelancer/freelancer-completed-projects" class="d-flex"
                      >View Details</router-link
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-4 col-xl-3">
                <div class="dash-widget">
                  <div class="dash-info">
                    <div class="dashboard-icon dashboard-icon-three">
                      <img
                        src="@/assets/img/icon/freelancer-dashboard-icon-03.svg"
                        alt="Img"
                      />
                    </div>
                    <div class="dash-widget-info">Reviews</div>
                  </div>
                  <div
                    class="dash-widget-more d-flex align-items-center justify-content-between"
                  >
                    <div class="dash-widget-count">25</div>
                    <router-link to="/freelancer/freelancer-completed-projects" class="d-flex"
                      >View Details</router-link
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-4 col-xl-3">
                <div class="dash-widget">
                  <div class="dash-info">
                    <div class="dashboard-icon dashboard-icon-four">
                      <img
                        src="@/assets/img/icon/freelancer-dashboard-icon-04.svg"
                        alt="Img"
                      />
                    </div>
                    <div class="dash-widget-info">Earning</div>
                  </div>
                  <div
                    class="dash-widget-more d-flex align-items-center justify-content-between"
                  >
                    <div class="dash-widget-count">5962</div>
                    <router-link to="/freelancer/freelancer-completed-projects" class="d-flex"
                      >View Details</router-link
                    >
                  </div>
                </div>
              </div>
            </div>

            <!-- Chart Content -->
            <div class="row">
              <div class="col-xl-8 d-flex">
                <div class="card flex-fill ongoing-project-card">
                  <div class="pro-head">
                    <h5 class="card-title mb-0">Overview</h5>
                    <div class="month-detail">
                      <vue-select
                        :options="Months"
                        id="months"
                        placeholder="Last 6 months"
                      />
                    </div>
                  </div>
                  <div class="pro-body p-0">
                    <apexchart
                      type="line"
                      height="300"
                      :options="chartprofile.profile"
                      :series="chartprofile.series"
                    ></apexchart>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 d-flex">
                <div class="flex-fill card ongoing-project-card">
                  <div class="pro-head b-0">
                    <h5 class="card-title mb-0">Static Analytics</h5>
                  </div>
                  <div class="pro-body p-0">
                    <apexchart
                      type="radialBar"
                      height="250"
                      :options="chartradial.radial"
                      :series="chartradial.series"
                    ></apexchart>
                    <div class="d-flex flex-wrap justify-content-between">
                      <ul class="static-list">
                        <li>
                          <span><i class="fas fa-circle text-violet me-1"></i>Jobs</span>
                        </li>
                        <!-- <li><span><i class="fas fa-circle text-pink me-1"></i> Applied Proposals</span></li> -->
                        <li>
                          <span
                            ><i class="fas fa-circle text-yellow me-1"></i>Proposals</span
                          >
                        </li>
                        <!-- <li><span><i class="fas fa-circle text-blue me-1"></i>Bookmarked Projects</span></li> -->
                      </ul>
                      <ul class="static-list">
                        <!-- <li><span><i class="fas fa-circle text-violet me-1"></i>Jobs</span></li> -->
                        <li>
                          <span
                            ><i class="fas fa-circle text-pink me-1"></i> Applied
                            Proposals</span
                          >
                        </li>
                        <!-- <li><span><i class="fas fa-circle text-yellow me-1"></i>Proposals</span></li> -->
                        <li>
                          <span
                            ><i class="fas fa-circle text-blue me-1"></i>Bookmarked
                            Projects</span
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Chart Content -->

            <div class="row">
              <!-- Ongoing Projects -->
              <div class="col-xl-6 d-flex">
                <div class="card flex-fill ongoing-project-card">
                  <div class="pro-head">
                    <h2>Ongoing Projects</h2>
                    <router-link to="/freelancer/freelancer-ongoing-projects" class="btn fund-btn"
                      >View All</router-link
                    >
                  </div>
                  <div class="pro-body p-0">
                    <div
                      class="on-project"
                      v-for="record in Ongoing_Project"
                      :key="record.id"
                    >
                      <span>{{ record.Title }}</span>
                      <h5>Landing Page Redesign / Sales Page Redesign</h5>
                      <div class="pro-info">
                        <ul class="list-details">
                          <li>
                            <div class="slot">
                              <p>Project type</p>
                              <h5>Hourly</h5>
                            </div>
                          </li>
                          <li>
                            <div class="slot">
                              <p>Location</p>
                              <h5 class="d-flex align-items-center">
                                <img
                                  src="@/assets/img/icon/flag-icon.svg"
                                  class="me-2"
                                  alt="Icon"
                                />UK
                              </h5>
                            </div>
                          </li>

                          <li>
                            <div class="slot">
                              <p>Expiry</p>
                              <h5>5 Days Left</h5>
                            </div>
                          </li>
                          <li>
                            <div class="slot">
                              <p>Price</p>
                              <h5>$280</h5>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Ongoing Projects -->
              <!-- Past Earnings -->
              <div class="col-xl-6">
                <div class="card mb-4 ongoing-project-card">
                  <div class="pro-head">
                    <h2>Recent Earnings</h2>
                  </div>
                  <div class="table-responsive recent-earnings flex-fill">
                    <table class="table mb-0">
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Client</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="record in Recent_Project" :key="record.id">
                          <td>{{ record.Date }}</td>
                          <td>
                            <h2 class="table-avatar table-user">
                              <a href="javascript:void(0);" class="avatar avatar-m me-2"
                                ><img
                                  class="avatar-img"
                                  :src="require(`@/assets/img/user/${record.Image}`)"
                                  alt="User Image"
                              /></a>
                              <a href="javascript:void(0);"> {{ record.Client }} </a>
                            </h2>
                          </td>
                          <td class="action-bg">{{ record.Amount }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <!-- /Past Earnings -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Page Content -->
</template>
<script>
import { chartprofile, chartradial } from "./data";
import Ongoing_Project from "@/assets/json/ongoing-project.json";
import Recent_Project from "@/assets/json/recent-project.json";
export default {
  data() {
    return {
      Months: ["Last 6 months", "Last 2 months"],
      chartradial: chartradial,
      chartprofile: chartprofile,
      Recent_Project: Recent_Project,
      Ongoing_Project: Ongoing_Project,
    };
  },
};
</script>
