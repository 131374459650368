<template>
    <!-- Top Instructor -->
    <section class="section subscribe">
        <div class="bg-img">
            <img src="@/assets/img/bg/bg3.png" class="bg-img3" alt="img">
        </div>
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-sm-12 col-12 mx-auto ">
                    <div class="section-header aos text-center" data-aos="fade-up">
                        <h2 class="header-title">Subscribe To Get Discounts, Updates & More</h2>
                        <p>Monthly product updates, industry news and more!</p>
                    </div>
                </div>
                <div class="subscribe-form aos " data-aos="fade-up">
                    <input type="text" placeholder="Enter your Email">
                    <a href="javascript:void(0);" class="btn btn-sub">Send</a>
                </div>
            </div>

        </div>
    </section>
    <!-- End Developer -->
    <!-- Review -->
    <section class="section testimonial-section review">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="section-header aos text-center" data-aos="fade-up">
                        <h2 class="header-title">Top Reviews</h2>
                        <p>High Performing Developers To Your</p>
                    </div>
                </div>
            </div>
            <div id="testimonial-slider" class="owl-carousel owl-theme testimonial-slider aos" data-aos="fade-up">
                <Carousel :wrap-around="true" :settings="settings" :breakpoints="breakpoints">
                    <Slide v-for="item in IndexReview" :key="item.id">
                <!-- Review Widget -->
                <div class="review-blog">
                    <div class="review-top d-flex align-items-center">
                        <div class="review-img">
                            <router-link to="/employers/review"><img class="img-fluid" :src="require(`@/assets/img/review/${item.Image}`)"
                                    alt="Post Image"></router-link>
                        </div>
                        <div class="review-info">
                            <h3><router-link to="/employers/review">{{item.Name}}</router-link></h3>
                            <h5>{{item.Role}}</h5>

                        </div>
                        <div class="test-quote-img">
                            <img class="img-fluid" src="@/assets/img/test-quote.svg" alt="quote">
                        </div>
                    </div>
                    <div class="review-content">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat orci enim, mattis nibh aliquam
                            dui, nibh faucibus aenean.</p>
                        <div class="rating">
                            <i class="fas fa-star filled"></i>
                            <i class="fas fa-star filled"></i>
                            <i class="fas fa-star filled"></i>
                            <i class="fas fa-star filled"></i>
                            <i class="fas fa-star"></i>
                            <span class="average-rating">{{item.Rating}}</span>
                        </div>
                    </div>
                </div>
                <!-- / Review Widget -->
            </Slide>
                    <template #addons>
                        <Navigation />
                    </template>
                </Carousel>
            </div>
        </div>
    </section>
    <!-- / Review -->
</template>

<script>
import { Carousel, Navigation, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import IndexReview from '@/assets/json/index-review.json'
export default {
    data() {
        return {
            IndexReview: IndexReview,
            settings: {
                itemsToShow: 1,
                snapAlign: "center",
            },
            breakpoints: {
                575: {
                    itemsToShow: 2,
                    snapAlign: "center",
                },
                767: {
                    itemsToShow: 2,
                    snapAlign: "center",
                },
                991: {
                    itemsToShow: 2.5,
                    snapAlign: "center",
                },
                1024: {
                    itemsToShow: 3,
                    snapAlign: "start",
                },
            },
        }
    },
    components: {
        Carousel,
        Slide,
        Navigation,
    },
}
</script>