<template>
  <!-- Our Feature -->
  <section class="section projects">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-12 mx-auto">
          <div class="section-header text-center aos" data-aos="fade-up">
            <h2 class="header-title">What’s great about it?</h2>
            <p>All the features of kofejob below</p>
          </div>
        </div>
      </div>
      <div class="row row-gap">
        <!-- Feature Item -->
        <div class="col-xl-3 col-md-6">
          <div class="great-card aos" data-aos="fade-up">
            <div class="feature-icon mb-0 me-3">
              <img
                src="@/assets/img/icon/great5.svg"
                class="img-fluid"
                alt="Img"
              />
            </div>
            <div class="feature-content course-count text-start">
                <h4><vue3-autocounter
     class="counterUp"
      ref="counter"
       :startAmount="0"
       :delay="3"
       :endAmount="9207"
        :duration="5"
       separator=","
       :autoinit="true"
 /></h4>
              
              <p>Freelance developers</p>
            </div>
          </div>
        </div>
        <!-- /Feature Item -->

        <!-- Feature Item -->
        <div class="col-xl-3 col-md-6">
          <div class="great-card aos" data-aos="fade-up">
            <div class="feature-icon mb-0 me-3">
              <img
                src="@/assets/img/icon/great6.svg"
                class="img-fluid"
                alt="Img"
              />
            </div>
            <div class="feature-content course-count text-start">
                <h4><vue3-autocounter
     class="counterUp"
      ref="counter"
       :startAmount="0"
       :delay="3"
       :endAmount="6000"
        :duration="5"
       separator=""
       :autoinit="true"
 />+</h4>
              
              <p>Projects Added</p>
            </div>
          </div>
        </div>
        <!-- /Feature Item -->

        <!-- Feature Item -->
        <div class="col-xl-3 col-md-6">
          <div class="great-card aos" data-aos="fade-up">
            <div class="feature-icon mb-0 me-3">
              <img
                src="@/assets/img/icon/great7.svg"
                class="img-fluid"
                alt="Img"
              />
            </div>
            <div class="feature-content course-count text-start">
              <h4 class="counter-up">919,207</h4>
              <p>Completed projects</p>
            </div>
          </div>
        </div>
        <!-- /Feature Item -->

        <!-- Feature Item -->
        <div class="col-xl-3 col-md-6">
          <div class="great-card aos" data-aos="fade-up">
            <div class="feature-icon mb-0 me-3">
              <img
                src="@/assets/img/icon/great8.svg"
                class="img-fluid"
                alt="Img"
              />
            </div>
            <div class="feature-content course-count text-start">
              <h4 class="counter-up">998</h4>
              <p>Companies Registered</p>
            </div>
          </div>
        </div>
        <!-- /Feature Item -->
      </div>
    </div>
  </section>
  <!-- /Our Feature -->
  <!-- Job Location -->
  <section class="section review">
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-sm-12 col-12 mx-auto">
          <div
            class="section-header d-block section-locate aos"
            data-aos="fade-up"
          >
            <div class="text-center">
              <h2 class="header-title">Jobs by Location</h2>
              <p>Find your favourite jobs and get the benefits of yourself</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row row-gap">
        <div class="col-xl-3 col-md-4 aos" data-aos="fade-up">
          <div class="blog-article-group job-loc">
            <div class="blog-image">
              <router-link to="/blog/blog-details"
                ><img
                  class="img-fluid"
                  src="@/assets/img/location/loc1.jpg"
                  alt="Post Image"
              /></router-link>
              <div class="article-blog-content">
                <span> Nevada, USA</span>
                <h4>
                  <router-link to="/blog/blog-details">40 Companies</router-link>
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-4 aos" data-aos="fade-up">
          <div class="blog-article-group job-loc">
            <div class="blog-image">
              <router-link to="/blog/blog-details"
                ><img
                  class="img-fluid"
                  src="@/assets/img/location/loc2.jpg"
                  alt="Post Image"
              /></router-link>
              <div class="article-blog-content">
                <span> London, UK</span>
                <h4>
                  <router-link to="/blog/blog-details">52 Companies</router-link>
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-4 aos" data-aos="fade-up">
          <div class="blog-article-group job-loc">
            <div class="blog-image">
              <router-link to="/blog/blog-details"
                ><img
                  class="img-fluid"
                  src="@/assets/img/location/loc3.jpg"
                  alt="Post Image"
              /></router-link>
              <div class="article-blog-content">
                <span>Bangalore, India</span>
                <h4>
                  <router-link to="/blog/blog-details">77 Companies</router-link>
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-4 aos" data-aos="fade-up">
          <div class="blog-article-group job-loc">
            <div class="blog-image">
              <router-link to="/blog/blog-details"
                ><img
                  class="img-fluid"
                  src="@/assets/img/location/loc4.jpg"
                  alt="Post Image"
              /></router-link>
              <div class="article-blog-content">
                <span>New York, USA</span>
                <h4>
                  <router-link to="/blog/blog-details">85 Companies</router-link>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Job Location -->
</template>
