<template>
  <!-- Review -->
  <section class="section review-five">
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-sm-12 col-12 mx-auto">
          <div class="section-header text-center aos" data-aos="fade-up">
            <h2 class="header-title">Top Review</h2>
            <p>People love to come again for Kofejob</p>
          </div>
        </div>
      </div>
      <div class="offset-xl-1 col-xl-10">
        <div class="slid-review-group">
          <div class="slider say-about slider-for aos" data-aos="fade-up">
            <Carousel
              :wrap-around="true"
              :settings="settings"
              :breakpoints="breakpoints"
            >
              <Slide v-for="item in IndexFiveReview" :key="item.id">
                <div>
                  <div class="testimonial-all d-flex justify-content-center">
                    <div class="row">
                      <div class="col-md-3">
                        <div class="img-reviews">
                          <div class="review-quote">
                            <img src="@/assets/img/quote-02.svg" alt="Img" />
                          </div>
                          <img
                            :src="require(`@/assets/img/user/${item.Img}`)"
                            alt="Img"
                          />
                        </div>
                      </div>
                      <div class="col-md-9">
                        <div class="testimonial-two-head">
                          <h3>{{ item.Name }}</h3>
                          <span>{{ item.Title }}</span>
                          <p>{{ item.Text }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Slide>
              <template #addons>
                <Navigation />
              </template>
            </Carousel>
          </div>
        <home-five-client></home-five-client>
        </div>
      </div>
    </div>
  </section>
  <!-- Review -->
</template>
<script>
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import IndexFiveReview from "@/assets/json/indexfivereview.json";
export default {
  data() {
    return {
      IndexFiveReview: IndexFiveReview,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
        loop: true,
        margin: 24,
      },

      breakpoints: {
        575: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 700px and up
        767: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 991px and up
        991: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 1,
          snapAlign: "start",
        },
      },
    };
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
};
</script>
