<template>
  <!-- Breadcrumb -->
  <pagebreadcrumb :title="title" :text="text" :text1="text1" />
  <!-- /Breadcrumb -->

  <!-- Page Content -->
  <div class="content">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 col-md-12">
          <div class="row blog-grid-row">
            <div class="col-md-6 col-sm-12" v-for="record in Blog_Grid" :key="record.id">
              <!-- Blog Post -->
              <div class="blog grid-blog">
                <div class="blog-image">
                  <router-link to="/blog/blog-details"
                    ><img
                      class="img-fluid"
                      :src="require(`@/assets/img/blog/${record.image}`)"
                      alt="Post Image"
                  /></router-link>
                </div>
                <div class="blog-content">
                  <ul class="entry-meta meta-item">
                    <li>
                      <div class="post-author">
                        <router-link to="/employers/developer-details"
                          ><img
                            :src="require(`@/assets/img/${record.authorImage}`)"
                            alt="Post Author"
                          />
                          <span> {{ record.author }}</span></router-link
                        >
                      </div>
                    </li>
                    <li><i class="far fa-clock"></i> {{ record.date }}</li>
                  </ul>
                  <h3 class="blog-title">
                    <router-link to="/blog/blog-details">{{ record.title }}</router-link>
                  </h3>
                  <p class="mb-0">
                    Lorem ipsum dolor sit amet, consectetur em adipiscing elit, sed do
                    eiusmod tempor.
                  </p>
                </div>
              </div>
              <!-- /Blog Post -->
            </div>
          </div>

          <!-- Blog Pagination -->
          <div class="row">
            <div class="col-md-12">
              <ul class="paginations list-pagination">
                <li class="page-item">
                  <a href="javascript:void(0);"><i class="feather-chevron-left"></i></a>
                </li>
                <li class="page-item">
                  <a href="javascript:void(0);" class="active">1</a>
                </li>
                <li class="page-item"><a href="javascript:void(0);">2</a></li>
                <li class="page-item"><a href="javascript:void(0);">3</a></li>
                <li class="page-item"><a href="javascript:void(0);">...</a></li>
                <li class="page-item"><a href="javascript:void(0);">10</a></li>
                <li class="page-item">
                  <a href="javascript:void(0);"><i class="feather-chevron-right"></i></a>
                </li>
              </ul>
            </div>
          </div>
          <!-- /Blog Pagination -->
        </div>

        <!-- Blog Sidebar -->
        <blog-sidebar></blog-sidebar>
        <!-- /Blog Sidebar -->
      </div>
    </div>
  </div>
  <!-- /Page Content -->
</template>
<script>
import Blog_Grid from "@/assets/json/blog-grid.json";
export default {
  data() {
    return {
      Blog_Grid: Blog_Grid,
      title: "Blog Grid",
      text: "Home",
      text1: "Blog Grid",
    };
  },
};
</script>
