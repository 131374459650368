<template>
  <header class="header">
    <div class="container">
      <nav class="navbar navbar-expand-lg header-nav p-0">
        <div class="navbar-header">
          <a id="mobile_btn" href="javascript:void(0);" @click="toggleSidebar">
            <span class="bar-icon">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </a>
          <router-link to="/home/" class="navbar-brand logo">
            <img src="@/assets/img/logo.svg" class="img-fluid" alt="Logo" />
          </router-link>
        </div>
        <div class="main-menu-wrapper">
          <div class="menu-header">
            <router-link to="/home/" class="menu-logo">
              <img src="@/assets/img/logo.svg" class="img-fluid" alt="Logo" />
            </router-link>
            <a
              id="menu_close"
              class="menu-close"
              href="javascript:void(0);"
              @click="closeSidebar"
            >
              <i class="fas fa-times"></i>
            </a>
          </div>
          <header-menu></header-menu>
        </div>
        <ul class="nav header-navbar-rht reg-head">
          <li>
            <router-link to="/register" class="reg-btn"
              ><img
                src="@/assets/img/icon/users.svg"
                class="me-1"
                alt="img"
              />Register</router-link
            >
          </li>
          <li>
            <router-link to="/" class="log-btn"
              ><img src="@/assets/img/icon/lock.svg" class="me-1" alt="img" />
              Login</router-link
            >
          </li>
          <li>
            <router-link to="/post-project" class="login-btn"
              ><i class="feather-plus me-1"></i>Post a Project
            </router-link>
          </li>
        </ul>
      </nav>
    </div>
  </header>
</template>
<script>
export default {
  data() {
    return {
      isSidebarOpen: true,
    };
  },
  methods: {
    toggleSidebar() {
      this.isSidebarOpen = !this.isSidebarOpen;
      document.documentElement.classList.toggle("menu-opened");
    },
    closeSidebar() {
      this.isSidebarOpen = false;
      document.documentElement.classList.remove("menu-opened");
    },
  },
};
</script>
