<template>
  <div class="page-header subscribe-head border-bottom-0" v-if="isBottomRoute">
    <div class="row align-items-center">
      <div class="col">
        <h3 class="page-title">{{ title }}</h3>
        <ul class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/admin/index">{{ text }}</router-link>
          </li>
          <li class="breadcrumb-item active">{{ text1 }}</li>
        </ul>
      </div>
    </div>
  </div>
  <div class="page-header" v-else-if="isheaderRoute">
    <div class="row align-items-center">
      <div class="col">
        <h3 class="page-title">{{ title }}</h3>
        <ul class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/admin/index">{{ text }}</router-link>
          </li>
          <li class="breadcrumb-item active">{{ text1 }}</li>
        </ul>
      </div>
    </div>
  </div>
  <div class="page-header" v-else-if="istaxRoute">
    <div class="row">
      <div class="col-sm-6">
        <h3 class="page-title">{{ title }}</h3>
        <ul class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/admin/index">{{ text }}</router-link>
          </li>
          <li class="breadcrumb-item active">{{ text1 }}</li>
        </ul>
      </div>
    </div>
  </div>
  <div class="col-lg-6" v-else-if="isColRoute">
    <h3 class="page-title">{{ title }}</h3>
    <ul class="breadcrumb">
      <li class="breadcrumb-item">
        <router-link to="/admin/index">{{ text }}</router-link>
      </li>
      <li class="breadcrumb-item active">{{ text1 }}</li>
    </ul>
  </div>
  <div class="col" v-else>
    <h3 class="page-title">{{ title }}</h3>
    <ul class="breadcrumb">
      <li class="breadcrumb-item">
        <router-link to="/admin/index">{{ text }}</router-link>
      </li>
      <li class="breadcrumb-item active">{{ text1 }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
    text1: {
      type: String,
      default: "",
    },
  },
  computed: {
    isBottomRoute() {
      return (
        this.$route.path === "/admin/withdrawn" ||
        this.$route.path === "/admin/withdrawn-pending" ||
        this.$route.path === "/admin/withdrawn-completed" ||
        this.$route.path === "/admin/withdrawn-cancelled" ||
        this.$route.path === "/admin/transaction" ||
        this.$route.path === "/admin/transaction-withdraw" ||
        this.$route.path === "/admin/transaction-pending" ||
        this.$route.path === "/admin/transaction-onhold" ||
        this.$route.path === "/admin/transaction-deposit" ||
        this.$route.path === "/admin/transaction-completed" ||
        this.$route.path === "/admin/deposit" ||
        this.$route.path === "/admin/deposit-pending" ||
        this.$route.path === "/admin/deposit-hold" ||
        this.$route.path === "/admin/deposit-cancelled" ||
        this.$route.path === "/admin/deposit-completed"
      );
    },
    isheaderRoute() {
      return (
        this.$route.path === "/admin/verify-identity" ||
        this.$route.path === "/admin/transaction" ||
        this.$route.path === "/admin/transaction-withdraw" ||
        this.$route.path === "/admin/profile" ||
        this.$route.path === "/admin/index"
      );
    },
    istaxRoute() {
      return this.$route.path === "/admin/tax-types";
    },
    isColRoute() {
      return (
        this.$route.path === "/admin/reports" ||
        this.$route.path === "/admin/project-bidding" ||
        this.$route.path === "/admin/project-invoice" ||
        this.$route.path === "/admin/project-earnings" ||
        this.$route.path === "/admin/earning-employer" ||
        this.$route.path === "/admin/earning-freelancer"
      );
    },
  },
};
</script>
