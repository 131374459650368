<template>
  <!-- Page Content -->
  <div class="content content-page bookmark">
    <div class="container">
      <div class="row">
        <projectsidebar></projectsidebar>

        <div class="col-xl-9 col-lg-8">
          <div class="pro-pos">
            <div class="setting-content employee-profile-new">
              <form action="profile-settings">
                <div class="card">
                  <div class="pro-head">
                    <h3>Profile Setting</h3>
                  </div>
                  <div class="pro-body">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-row pro-pad pt-0 ps-0">
                          <div class="input-block col-md-6 pro-pic">
                            <h4 class="mb-3">Personal Information</h4>
                            <label class="form-label">Profile Picture</label>
                            <div class="d-flex align-items-center">
                              <div class="upload-images">
                                <img
                                  src="@/assets/img/img-02.jpg"
                                  alt="Image"
                                  id="blah"
                                />
                              </div>
                              <div class="ms-3">
                                <label class="file-upload image-upbtn ms-0">
                                  Upload Image <input type="file" id="imgInp" />
                                </label>
                                <p>Max Image size 300*300</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-12">
                        <div class="mb-3">
                          <label class="form-label">First Name</label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-12">
                        <div class="mb-3">
                          <label class="form-label">Last Name</label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-12">
                        <div class="mb-3">
                          <label class="form-label">Phone Number</label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-12">
                        <div class="mb-3">
                          <label class="form-label">Email Address</label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card">
                  <div class="pro-body">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-row pro-pad pt-0 ps-0">
                          <div class="input-block col-md-6 pro-pic">
                            <h4 class="mb-3">Address</h4>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-12">
                        <div class="mb-3">
                          <label class="form-label">Address</label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-12">
                        <div class="mb-3">
                          <label class="form-label">Country</label>
                          <vue-select
                            :options="Country"
                            id="citys"
                            placeholder="Select"
                          />
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-12">
                        <div class="mb-3">
                          <label class="form-label">State</label>
                          <vue-select
                            :options="Country"
                            id="cityuk"
                            placeholder="Select"
                          />
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-12">
                        <div class="mb-3">
                          <label class="form-label">City</label>
                          <vue-select
                            :options="Country"
                            id="cityusa"
                            placeholder="Select"
                          />
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-12">
                        <div class="mb-3">
                          <label class="form-label">Zipcode</label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card">
                  <div class="pro-body">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-row pro-pad pt-0 ps-0">
                          <div class="input-block col-md-6 pro-pic">
                            <h4 class="mb-3">Details</h4>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="mb-3">
                          <label class="form-label">Company Name</label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="mb-3">
                          <label class="form-label">Tagline</label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-12">
                        <div class="mb-3">
                          <label class="form-label">Established On</label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-12">
                        <div class="mb-3">
                          <label class="form-label">Company Owner Name</label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-12">
                        <div class="mb-3">
                          <label class="form-label">Industry</label>
                          <vue-select
                            :options="Country"
                            id="industryusa"
                            placeholder="Select"
                          />
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-12">
                        <div class="mb-3">
                          <label class="form-label">Website</label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="input-block">
                          <label class="form-label">Team Size</label>
                          <div class="check-radio">
                            <ul class="d-flex flex-wrap">
                              <li>
                                <label class="custom_radio me-4">
                                  <input
                                    type="radio"
                                    name="budget"
                                    value="Yes"
                                    checked=""
                                  />
                                  <span class="checkmark"></span> It's just me
                                </label>
                              </li>
                              <li>
                                <label class="custom_radio me-4">
                                  <input type="radio" name="budget" />
                                  <span class="checkmark"></span>2-9 employees
                                </label>
                              </li>
                              <li>
                                <label class="custom_radio me-4">
                                  <input type="radio" name="budget" />
                                  <span class="checkmark"></span>10-99 employees
                                </label>
                              </li>
                              <li>
                                <label class="custom_radio me-4">
                                  <input type="radio" name="budget" />
                                  <span class="checkmark"></span>100-1000 employees
                                </label>
                              </li>
                              <li>
                                <label class="custom_radio me-4">
                                  <input type="radio" name="budget" />
                                  <span class="checkmark"></span>More than 1000 employees
                                </label>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-12">
                        <label class="form-label">About</label>
                        <textarea rows="4" class="form-control"></textarea>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <h4>Working Hours</h4>
                  </div>
                  <div class="col-md-6">
                    <div class="input-block d-flex">
                      <div
                        class="form-check form-switch d-inline-block work-check position-relative"
                      >
                        <input
                          type="checkbox"
                          class="form-check-input"
                          id="check_hour"
                          @click="toggleElements"
                          checked
                        />
                      </div>
                      <label class="form-label ms-2">Same Every Day</label>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="checkout-hour">
                      <div class="other-info-list">
                        <ul>
                          <li class="active-hour">Mon</li>
                          <li class="active-hour">Tue</li>
                          <li class="active-hour">Wed</li>
                          <li class="active-hour">Thr</li>
                          <li class="active-hour">Fri</li>
                          <li>Sat</li>
                          <li>Sun</li>
                        </ul>
                      </div>
                    </div>
                    <div class="check-hour">
                      <div class="col-md-12">
                        <h4>Select Days</h4>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="row align-items-center">
                            <div class="col-md-1">
                              <div class="other-info-list">
                                <ul>
                                  <li class="active-hour m-0">Mon</li>
                                </ul>
                              </div>
                            </div>
                            <div class="col-md-5">
                              <div class="input-block">
                                <label class="form-label">Start Time </label>
                                <input type="text" class="form-control" Value="8:00" />
                              </div>
                            </div>
                            <div class="col-md-5">
                              <div class="input-block">
                                <label class="form-label">End Time </label>
                                <input type="text" class="form-control" Value="20:00" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="row align-items-center">
                            <div class="col-md-1">
                              <div class="other-info-list">
                                <ul>
                                  <li class="active-hour m-0">Tue</li>
                                </ul>
                              </div>
                            </div>
                            <div class="col-md-5">
                              <div class="input-block">
                                <label class="form-label">Start Time </label>
                                <input type="text" class="form-control" Value="8:00" />
                              </div>
                            </div>
                            <div class="col-md-5">
                              <div class="input-block">
                                <label class="form-label">End Time </label>
                                <input type="text" class="form-control" Value="20:00" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="row align-items-center">
                            <div class="col-md-1">
                              <div class="other-info-list">
                                <ul>
                                  <li class="active-hour m-0">Wed</li>
                                </ul>
                              </div>
                            </div>
                            <div class="col-md-5">
                              <div class="input-block">
                                <label class="form-label">Start Time </label>
                                <input type="text" class="form-control" Value="8:00" />
                              </div>
                            </div>
                            <div class="col-md-5">
                              <div class="input-block">
                                <label class="form-label">End Time </label>
                                <input type="text" class="form-control" Value="20:00" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="row align-items-center">
                            <div class="col-md-1">
                              <div class="other-info-list">
                                <ul>
                                  <li class="active-hour m-0">Thur</li>
                                </ul>
                              </div>
                            </div>
                            <div class="col-md-5">
                              <div class="input-block">
                                <label class="form-label">Start Time </label>
                                <input type="text" class="form-control" Value="8:00" />
                              </div>
                            </div>
                            <div class="col-md-5">
                              <div class="input-block">
                                <label class="form-label">End Time </label>
                                <input type="text" class="form-control" Value="20:00" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="row align-items-center">
                            <div class="col-md-1">
                              <div class="other-info-list">
                                <ul>
                                  <li class="active-hour m-0">Fri</li>
                                </ul>
                              </div>
                            </div>
                            <div class="col-md-5">
                              <div class="input-block">
                                <label class="form-label">Start Time </label>
                                <input type="text" class="form-control" Value="8:00" />
                              </div>
                            </div>
                            <div class="col-md-5">
                              <div class="input-block">
                                <label class="form-label">End Time </label>
                                <input type="text" class="form-control" Value="20:00" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="row align-items-center">
                            <div class="col-md-1">
                              <div class="other-info-list">
                                <ul>
                                  <li class="m-0">Fri</li>
                                </ul>
                              </div>
                            </div>
                            <div class="col-md-5">
                              <div class="input-block">
                                <label class="form-label">Start Time </label>
                                <input type="text" class="form-control" Value="8:00" />
                              </div>
                            </div>
                            <div class="col-md-5">
                              <div class="input-block">
                                <label class="form-label">End Time </label>
                                <input type="text" class="form-control" Value="20:00" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="row align-items-center">
                            <div class="col-md-1">
                              <div class="other-info-list">
                                <ul>
                                  <li class="m-0">Sun</li>
                                </ul>
                              </div>
                            </div>
                            <div class="col-md-5">
                              <div class="input-block">
                                <label class="form-label">Start Time </label>
                                <input type="text" class="form-control" Value="8:00" />
                              </div>
                            </div>
                            <div class="col-md-5">
                              <div class="input-block">
                                <label class="form-label">End Time </label>
                                <input type="text" class="form-control" Value="20:00" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card">
                  <div class="pro-body">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-row pro-pad pt-0 ps-0">
                          <div class="input-block col-md-6 pro-pic">
                            <h4 class="mb-3">Social Media</h4>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="mb-3">
                          <label class="form-label">Facebook</label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="mb-3">
                          <label class="form-label">Instagram </label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-12">
                        <div class="mb-3">
                          <label class="form-label">LinkedIn</label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-12">
                        <div class="mb-3">
                          <label class="form-label">Behance</label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-12">
                        <div class="mb-3">
                          <label class="form-label">Pinterest </label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-12">
                        <div class="mb-3">
                          <label class="form-label">Twitter</label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card">
                  <div class="pro-body">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-row pro-pad pt-0 ps-0">
                          <div class="input-block col-md-6 pro-pic">
                            <h4 class="mb-3">Location</h4>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-12">
                        <div class="mb-3">
                          <label class="form-label">Address</label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-xl-3 col-md-6">
                        <div class="mb-3">
                          <label class="form-label">City </label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-xl-3 col-md-6">
                        <div class="mb-3">
                          <label class="form-label">State / Province</label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-xl-3 col-md-6">
                        <div class="mb-3">
                          <label class="form-label">ZIP / Post Code </label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-xl-3 col-md-6">
                        <div class="mb-3">
                          <label class="form-label">Country</label>
                          <vue-select :options="Country" id="usa" placeholder="Select" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card border-0">
                  <div class="pro-body">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-row pro-pad pt-0 ps-0">
                          <div class="input-block col-md-6 pro-pic">
                            <h4 class="mb-3">Language</h4>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="mb-3">
                          <label class="form-label">Language</label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="mb-3">
                          <label class="form-label">Fluency</label>
                          <vue-select
                            :options="Country"
                            id="usafluency"
                            placeholder="Select"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card text-end border-0">
                  <div class="pro-body">
                    <button class="btn btn-secondary click-btn btn-plan">Cancel</button>
                    <button class="btn btn-primary click-btn btn-plan" type="submit">
                      Update
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- /Page Content -->
</template>

<script>
export default {
  data() {
    return {
      Country: ["Select", "UK", "USA"],
    };
  },
  mounted() {
    // Your existing JavaScript code goes here
    document.getElementById("check_hour").addEventListener("click", this.toggleElements);
  },
  methods: {
    toggleElements() {
      var checkoutHourElements = document.getElementsByClassName("checkout-hour");
      var checkHourElements = document.getElementsByClassName("check-hour");

      if (document.getElementById("check_hour").checked) {
        this.showElements(checkoutHourElements);
        this.hideElements(checkHourElements);
      } else {
        this.hideElements(checkoutHourElements);
        this.showElements(checkHourElements);
      }
    },
    showElements(elements) {
      for (var i = 0; i < elements.length; i++) {
        elements[i].style.display = "block";
      }
    },
    hideElements(elements) {
      for (var i = 0; i < elements.length; i++) {
        elements[i].style.display = "none";
      }
    },
  },
};
</script>
