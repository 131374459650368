<template>
  <!-- Project Download -->
  <div class="project-download">
    <div class="title-group d-flex">
      <h2>Administrator Earnings</h2>
      <div class="down-load">
        <a href="javascript:;" class="btn-primary"
          ><i class="fas fa-cloud-download-alt"></i>Download</a
        >
      </div>
    </div>
    <div class="select-group">
      <div class="row">
        <div class="col-lg-2 col-md-6">
          <select class="form-select">
            <option>Date</option>
            <option>Option 1</option>
            <option>Option 2</option>
            <option>Option 3</option>
            <option>Option 4</option>
            <option>Option 5</option>
          </select>
        </div>
        <div class="col-lg-2 col-md-6">
          <select class="form-select">
            <option>Reference Number</option>
            <option>Option 1</option>
            <option>Option 2</option>
            <option>Option 3</option>
            <option>Option 4</option>
            <option>Option 5</option>
          </select>
        </div>
        <div class="col-lg-2 col-md-6">
          <select class="form-select">
            <option>Project name</option>
            <option>Option 1</option>
            <option>Option 2</option>
            <option>Option 3</option>
            <option>Option 4</option>
            <option>Option 5</option>
          </select>
        </div>
        <div class="col-lg-2 col-md-6">
          <select class="form-select">
            <option>Payment type</option>
            <option>Option 1</option>
            <option>Option 2</option>
            <option>Option 3</option>
            <option>Option 4</option>
            <option>Option 5</option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <!-- /Project Download -->
</template>
