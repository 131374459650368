<template>
  <!-- Browse Categories -->
  <section class="section browse-categories">
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-sm-12 col-12 mx-auto">
          <div
            class="section-header section-header-four section-locate aos"
            data-aos="fade-up"
          >
            <div class="text-center">
              <h2 class="header-title">Browse Projects By Category</h2>
              <p>Get work done in over 60 different categories</p>
            </div>
          </div>
        </div>
      </div>
      <div class="browse-categories-content">
        <div class="row row-gap aos" data-aos="fade-up">
          <div
            class="col-lg-3 col-md-6 col-12 aos"
            v-for="item in IndexFourCategory"
            :key="item.id"
          >
            <div class="popular-catergories">
              <div class="popular-catergories-img">
                <span
                  ><img :src="require(`@/assets/img/icon/${item.Img}`)" alt="img"
                /></span>
              </div>
              <div class="popular-catergories-content">
                <h5>{{ item.Title }}</h5>
                <a href="javascript:void(0);">{{ item.Skills }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="home-four-viewall">
        <router-link to="/freelancer/project" class="home-four-viewall-btn"
          >View all Categories</router-link
        >
      </div>
    </div>
  </section>
  <!-- /Browse Categories -->
</template>
<script>
import IndexFourCategory from "@/assets/json/indexfourcategory.json";
// import AOS from "aos";
// import "aos/dist/aos.css";
export default {
  data() {
    return {
      IndexFourCategory: IndexFourCategory,
    };
  },
  // mounted() {
  //   this.$nextTick(() => {
  //     AOS.init();
  //   });
  // },
};
</script>
