<template>
  <div class="dashboard-page">
    <div class="content content-page bookmark">
      <div class="container">
        <div class="row">
          <div class="col-xl-3 col-lg-4 theiaStickySidebar">
            <freelancer-sidebar></freelancer-sidebar>
          </div>
          <div class="col-xl-9 col-lg-8">
            <div class="dashboard-sec freelance-favourites">
              <div class="page-title">
                <h3>Invitations</h3>
              </div>
              <div class="row row-gap">
                <div
                  class="col-md-6 col-lg-12 col-xl-4"
                  v-for="record in Freelancer_Favourites"
                  :key="record.id"
                >
                  <div class="freelance-widget widget-author">
                    <div class="freelance-content">
                      <div class="freelance-top-content d-flex">
                        <span>
                          <i class="feather-clock me-1"></i>{{ record.Posted }}</span
                        >
                        <a href="javascript:void(0);" class="favourite"
                          ><i class="feather-heart"></i
                        ></a>
                      </div>
                      <div class="author-heading">
                        <div class="profile-img">
                          <span class="project-verified"
                            ><i class="fas fa-check-circle"></i
                          ></span>
                          <router-link to="/freelancer/project-details">
                            <img
                              :src="require(`@/assets/img/company/${record.Image}`)"
                              alt="author"
                            />
                          </router-link>
                        </div>
                        <div class="profile-name">
                          <div class="author-location">
                            <router-link to="/freelancer/project-details"
                              >{{ record.Name }}
                            </router-link>
                          </div>
                        </div>
                        <div class="freelance-info">
                          <h3>
                            <a href="javascript:void(0);">{{ record.Developer }}</a>
                          </h3>
                          <div class="freelance-location">
                            <i class="feather-map-pin me-1"></i>{{ record.Location }}
                          </div>
                        </div>
                        <div class="freelance-tags">
                          <a href="javascript:void(0);"
                            ><span class="badge badge-pill badge-design me-1"
                              >Web Design</span
                            ></a
                          >
                          <a href="javascript:void(0);"
                            ><span class="badge badge-pill badge-design me-1"
                              >UI Design</span
                            ></a
                          >
                          <a href="javascript:void(0);"
                            ><span class="badge badge-pill badge-design me-1">Node Js</span></a
                          >
                        </div>
                        <div class="freelancers-price">{{ record.Amount }}</div>
                      </div>
                      <div class="counter-stats">
                        <ul>
                          <li>
                            <h5>Expiry</h5>
                            <h3 class="counter-value">{{ record.Days }}</h3>
                          </li>
                          <li>
                            <h5>Proposals</h5>
                            <h3 class="counter-value">{{ record.Proposal }}</h3>
                          </li>
                          <li>
                            <h5>Job Type</h5>
                            <h3 class="counter-value">
                              <span class="jobtype">{{ record.Jobtype }}</span>
                            </h3>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="cart-hover">
                      <router-link to="/freelancer/project-details" class="btn-cart" tabindex="-1"
                        >View Profile</router-link
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <ul class="paginations list-pagination">
                    <li class="page-item">
                      <a href="javascript:void(0);"
                        ><i class="feather-chevron-left"></i
                      ></a>
                    </li>
                    <li class="page-item">
                      <a href="javascript:void(0);" class="active">1</a>
                    </li>
                    <li class="page-item"><a href="javascript:void(0);">2</a></li>
                    <li class="page-item"><a href="javascript:void(0);">3</a></li>
                    <li class="page-item"><a href="javascript:void(0);">...</a></li>
                    <li class="page-item"><a href="javascript:void(0);">10</a></li>
                    <li class="page-item">
                      <a href="javascript:void(0);"
                        ><i class="feather-chevron-right"></i
                      ></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- project list -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Freelancer_Favourites from "@/assets/json/freelancer-favourites.json";
export default {
  data() {
    return {
      Freelancer_Favourites: Freelancer_Favourites,
    };
  },
};
</script>
