<template>
  <!-- sidebar -->
  <div class="col-xl-3 col-lg-4 theiaStickySidebar">
    <div class="stickysidebar">
      <div class="settings-widget">
        <div
          class="settings-header d-sm-flex flex-row flex-wrap text-center text-sm-start align-items-center"
        >
          <router-link to="/employers/company-profile"
            ><img
              alt="profile image"
              src="@/assets/img/user/table-avatar-03.jpg"
              class="avatar-lg rounded-circle"
          /></router-link>
          <div class="ms-sm-3 ms-md-0 ms-lg-3 mt-2 mt-sm-0 mt-md-2 mt-lg-0">
            <h3 class="mb-0">
              <router-link to="/employers/profile-settings">Walter Griffin</router-link
              ><img src="@/assets/img/icon/verified-badge.svg" class="ms-1" alt="Img" />
            </h3>
            <p class="mb-0">@waltergriffin</p>
          </div>
        </div>
        <div class="settings-menu">
          <div id="sidebar-menu" class="sidebar-menu">
            <ul>
              <li class="nav-item">
                <router-link to="/employers/dashboard" class="nav-link">
                  <img src="@/assets/img/icon/sidebar-icon-01.svg" alt="Img" /> Dashboard
                </router-link>
              </li>
              <li class="nav-item submenu">
                <a
                  href="#sidebarProjects"
                  data-bs-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  aria-controls="sidebarProjects"
                  v-bind:class="ProjectsMenu ? 'active subdrop' : 'notactive'"
                  v-on:click="ProjectsMenus = !ProjectsMenus"
                  :class="{ subdrop: ProjectsMenus }"
                  class="nav-link"
                >
                  <img src="@/assets/img/icon/sidebar-icon-02.svg" alt="Img" /> Projects
                  <span class="menu-arrow"></span>
                </a>
                <ul class="sub-menu-ul collapse menu-dropdown" id="sidebarProjects">
                  <li>
                    <router-link
                      :class="
                        currentPath == '/employers/manage-projects'
                          ? 'active'
                          : 'notactive'
                      "
                      to="/employers/manage-projects"
                      >All Projects</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      :class="
                        currentPath == '/employers/ongoing-projects'
                          ? 'active'
                          : 'notactive'
                      "
                      to="/employers/ongoing-projects"
                      >Ongoing Projects</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      :class="
                        currentPath == '/employers/completed-projects'
                          ? 'active'
                          : 'notactive'
                      "
                      to="/employers/completed-projects"
                      >Completed Projects</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      :class="
                        currentPath == '/employers/pending-projects'
                          ? 'active'
                          : 'notactive'
                      "
                      to="/employers/pending-projects"
                      >Pending Projects</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      :class="
                        currentPath == '/employers/cancelled-projects'
                          ? 'active'
                          : 'notactive'
                      "
                      to="/employers/cancelled-projects"
                      >Cancelled Projects</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      :class="
                        currentPath == '/employers/expired-projects'
                          ? 'active'
                          : 'notactive'
                      "
                      to="/employers/expired-projects"
                      >Expired Projects</router-link
                    >
                  </li>
                </ul>
              </li>
              <li class="nav-item submenu">
                <a
                  href="#sidebarFavourites"
                  data-bs-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  aria-controls="sidebarFavourites"
                  v-bind:class="FavouritesMenu ? 'active subdrop' : 'notactive'"
                  v-on:click="FavouritesMenus = !FavouritesMenus"
                  :class="{ subdrop: FavouritesMenus }"
                  class="nav-link"
                >
                  <img src="@/assets/img/icon/sidebar-icon-03.svg" alt="Img" /> Favourites
                  <span class="menu-arrow"></span>
                </a>
                <ul class="sub-menu-ul collapse menu-dropdown" id="sidebarFavourites">
                  <li>
                    <router-link
                      :class="
                        currentPath == '/employers/favourites' ? 'active' : 'notactive'
                      "
                      to="/employers/favourites"
                      >Bookmarked Projects</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      :class="
                        currentPath == '/employers/invited-favourites'
                          ? 'active'
                          : 'notactive'
                      "
                      to="/employers/invited-favourites"
                      >Invitations</router-link
                    >
                  </li>
                </ul>
              </li>
              <li class="nav-item">
                <router-link to="/employers/review" class="nav-link">
                  <img src="@/assets/img/icon/sidebar-icon-04.svg" alt="Img" /> Reviews
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/employers/chats" class="nav-link">
                  <img src="@/assets/img/icon/sidebar-icon-06.svg" alt="Img" /> Chat
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="/employers/deposit-funds" class="nav-link">
                  <img src="@/assets/img/icon/sidebar-icon-07.svg" alt="Img" /> Payments
                </router-link>
              </li>
              <li class="nav-item">
                <a
                  href="#sidebarSettings"
                  data-bs-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  aria-controls="sidebarSettings"
                  v-bind:class="SettingsMenu ? 'active subdrop' : 'notactive'"
                  v-on:click="SettingsMenus = !SettingsMenus"
                  :class="{ subdrop: SettingsMenus }"
                  class="nav-link"
                >
                  <img src="@/assets/img/icon/sidebar-icon-10.svg" alt="Img" /> Settings
                  <span class="menu-arrow"></span>
                </a>
                <ul class="sub-menu-ul collapse menu-dropdown" id="sidebarSettings">
                  <li>
                    <router-link to="/employers/profile-settings">Profile</router-link>
                  </li>
                  <li>
                    <router-link to="/employers/membership-plans"
                      >Plan & Billing</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/employers/verify-identity"
                      >Verify Identity</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/employers/change-password"
                      >Change Password</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/employers/delete-account"
                      >Delete Account</router-link
                    >
                  </li>
                </ul>
              </li>
              <li class="nav-item">
                <router-link to="/home/" class="nav-link">
                  <img src="@/assets/img/icon/sidebar-icon-11.svg" alt="Img" /> Logout
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /sidebar -->
</template>

<script>
export default {
  data() {
    return {
      ProjectsMenus: false,
      FavouritesMenus: false,
      SettingsMenus: false,
      activeClass: "active",
    };
  },
  computed: {
    currentPath() {
      return this.$route.name;
    },
    ProjectsMenu() {
      return (
        this.$route.name == "/employers/manage-projects" ||
        this.$route.name == "/employers/ongoing-projects" ||
        this.$route.name == "/employers/completed-projects" ||
        this.$route.name == "/employers/pending-projects" ||
        this.$route.name == "/employers/cancelled-projects" ||
        this.$route.name == "/employers/expired-projects"
      );
    },
    FavouritesMenu() {
      return (
        this.$route.name == "/employers/favourites" ||
        this.$route.name == "/employers/invited-favourites"
      );
    },
    SettingsMenu() {
      return (
        this.$route.name == "/employers/profile-settings" ||
        this.$route.name == "/employers/membership-plans" ||
        this.$route.name == "/employers/verify-identity" ||
        this.$route.name == "/employers/change-password" ||
        this.$route.name == "/employers/delete-account"
      );
    },
  },
};
</script>
