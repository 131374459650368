<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card bg-white projects-card">
        <div class="card-body pt-0">
          <div class="card-header">
            <h5 class="card-title">Reviews</h5>
          </div>
          <div class="reviews-menu-links">
            <ul role="tablist" class="nav nav-pills card-header-pills nav-justified">
              <li class="nav-item">
                <a href="#tab-4" data-bs-toggle="tab" class="nav-link active"
                  >All (272)</a
                >
              </li>
              <li class="nav-item">
                <a href="#tab-5" data-bs-toggle="tab" class="nav-link">Active (218)</a>
              </li>
              <li class="nav-item">
                <a href="#tab-6" data-bs-toggle="tab" class="nav-link">
                  Pending Approval (03)
                </a>
              </li>
              <li class="nav-item">
                <a href="#tab-7" data-bs-toggle="tab" class="nav-link">Trash (0)</a>
              </li>
            </ul>
          </div>

          <div class="tab-content pt-0">
            <div role="tabpanel" id="tab-4" class="tab-pane fade active show">
              <showentries></showentries>

              <reviewall />
            </div>
            <div role="tabpanel" id="tab-5" class="tab-pane fade">
              <showentries></showentries>

              <reviewactive />
            </div>
            <div role="tabpanel" id="tab-6" class="tab-pane fade">
              <showentries></showentries>

              <reviewpending />
            </div>
            <div role="tabpanel" id="tab-7" class="tab-pane fade">
              <showentries></showentries>

              <reviewtrash />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
