<template>
  <!-- Header -->
  <header class="header header-two" :class="{ sticky: isSticky }">
    <div class="top-header">
      <div class="container">
        <div class="top-head-items">
          <ul class="nav user-menu">
            <!-- Flag -->
            <li class="nav-item dropdown has-arrow flag-nav">
              <a
                class="nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
                href="javascript:void(0);"
                role="button"
              >
                <img
                  src="@/assets/img/flags/us.png"
                  class="me-1"
                  alt="Flag"
                  height="20"
                />
                <span>English</span>
              </a>
              <div class="dropdown-menu dropdown-menu-right">
                <a href="javascript:void(0);" class="dropdown-item">
                  <img src="@/assets/img/flags/us.png" alt="Flag" height="16" /> English
                </a>
                <a href="javascript:void(0);" class="dropdown-item">
                  <img src="@/assets/img/flags/fr.png" alt="Flag" height="16" /> French
                </a>
                <a href="javascript:void(0);" class="dropdown-item">
                  <img src="@/assets/img/flags/es.png" alt="Flag" height="16" /> Spanish
                </a>
                <a href="javascript:void(0);" class="dropdown-item">
                  <img src="@/assets/img/flags/de.png" alt="Flag" height="16" /> German
                </a>
              </div>
            </li>
            <!-- /Flag -->
            <li class="nav-item dropdown has-arrow flag-nav">
              <a
                class="nav-link dropdown-toggle"
                data-bs-toggle="dropdown"
                href="javascript:void(0);"
                role="button"
              >
                <span>USD</span>
              </a>

              <div class="dropdown-menu dropdown-menu-right">
                <a href="javascript:void(0);" class="dropdown-item">USD</a>
                <a href="javascript:void(0);" class="dropdown-item"> French </a>
              </div>
            </li>
          </ul>
          <ul class="top-header-links">
            <li>
              <ul>
                <li>
                  <a href="javascript:void(0);">
                    <i class="fa-brands fa-facebook-f"></i>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0);">
                    <i class="fa-brands fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0);">
                    <i class="fa-brands fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0);">
                    <i class="fa-brands fa-linkedin-in"></i>
                  </a>
                </li>
                <li>
                  <a href="javascript:void(0);">
                    <i class="fa-regular fa-bell"></i>
                  </a>
                </li>
              </ul>
            </li>
            <li>
              <router-link to="/register"
                ><i class="feather feather-lock"></i> Register</router-link
              >
            </li>
            <li>
              <router-link to="/"><i class="feather feather-user"></i>Login</router-link>
            </li>
            <li>
              <router-link to="/admin/index" target="_blank"
                ><i class="feather feather-user"></i>Admin</router-link
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="container">
      <nav
        class="navbar navbar-expand-lg header-nav p-0"
        :class="{ 'header-bg': header_bg === true }"
      >
        <div class="navbar-header header-select">
          <a id="mobile_btn" href="javascript:void(0);">
            <span class="bar-icon">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </a>
          <router-link to="/home/" class="navbar-brand logo">
            <img src="@/assets/img/logo.svg" class="img-fluid" alt="Logo" />
          </router-link>
          <div class="input-group">
            <img src="@/assets/img/icon/dashboard-line.svg" alt="Img" />
            <vue-select :options="Categories" id="categorie" placeholder="Categories" />
          </div>
        </div>

        <div class="main-menu-wrapper">
          <div class="menu-header">
            <router-link to="/home/" class="menu-logo">
              <img src="@/assets/img/logo.svg" class="img-fluid" alt="Logo" />
            </router-link>
            <a id="menu_close" class="menu-close" href="javascript:void(0);">
              <i class="fas fa-times"></i>
            </a>
          </div>

          <header-menu></header-menu>
        </div>
        <ul class="nav header-navbar-rht">
          <li>
            <router-link to="/post-project" class="login-btn"
              ><i class="fa-solid fa-plus me-2"></i>Post a Project
            </router-link>
          </li>
        </ul>
      </nav>
    </div>
  </header>
  <!-- /Header -->
</template>

<script>
export default {
  data() {
    return {
      sticky: false,
      header_bg: false,
      isSticky: false,
      Categories: [
        "Categories",
        "Video & Animation",
        "Music & Audio",
        "Writing & Translation",
        "Digital Marketing",
        "Design & Creative",
        "Development & IT",
      ],
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const scroll = window.pageYOffset || document.documentElement.scrollTop;
      this.isSticky = scroll >= 100;
    },
  },
};
</script>
