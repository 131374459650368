<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>

  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content container-fluid">
      <!-- Page Header -->
      <singlebreadcrumb :title="title" />
      <!-- /Page Header -->

      <!-- Datatable -->
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-body">
              <div class="card-header pt-0 mb-0">
                <h4 class="card-title">Default Datatable</h4>
                <p class="card-text">
                  This is the most basic example of the datatables with zero
                  configuration. Use the
                  <code>.datatable</code> class to initialize datatables.
                </p>
                <hr />
                <div class="showentries">
                  <label
                    >Show
                    <select>
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                    entries
                  </label>
                  <div class="entries">
                    <span>Search: </span>
                    <input
                      type="search"
                      class="form-control form-control-sm ms-2"
                      placeholder=""
                      aria-controls="DataTables_Table_0"
                    />
                  </div>
                </div>
              </div>
              <div class="table-responsive">
                <a-table
                  class="stripped table-hover"
                  :columns="columns"
                  :data-source="data"
                >
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key === 'Name'">
                      <div>{{ record.Name }}</div>
                    </template>
                  </template>
                </a-table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Datatable -->
    </div>
  </div>
  <!-- /Page Wrapper -->
</template>

<script>
const columns = [
  {
    title: "Name",
    dataIndex: "Name",
    key: "Name",
    sorter: {
      compare: (a, b) => {
        a = a.Name.toLowerCase();
        b = b.Name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Position",
    dataIndex: "Position",
    sorter: {
      compare: (a, b) => {
        a = a.Position.toLowerCase();
        b = b.Position.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Office",
    dataIndex: "Office",
    sorter: {
      compare: (a, b) => {
        a = a.Office.toLowerCase();
        b = b.Office.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Age",
    dataIndex: "Age",
    sorter: {
      compare: (a, b) => {
        a = a.Age.toLowerCase();
        b = b.Age.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Start date",
    dataIndex: "Startdate",
    sorter: {
      compare: (a, b) => {
        a = a.Startdate.toLowerCase();
        b = b.Startdate.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Salary",
    dataIndex: "Salary",
    sorter: {
      compare: (a, b) => {
        a = a.Salary.toLowerCase();
        b = b.Salary.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
];

const data = [
  {
    Name: "Tiger Nixon",
    Position: "System Architect",
    Office: "Edinburgh",
    Age: 61,
    Startdate: "25/04/2023",
    Salary: "$320,800",
  },
  {
    Name: "Garrett Winters",
    Position: "Accountant",
    Office: "Tokyo",
    Age: 63,
    Startdate: "25/03/2023",
    Salary: "$170,750",
  },
  {
    Name: "Ashton Cox",
    Position: "Junior Technical Author",
    Office: "San Francisco",
    Age: 66,
    Startdate: "12/01/2023",
    Salary: "$86,000",
  },
  {
    Name: "Cedric Kelly",
    Position: "Senior Javascript Developer",
    Office: "Edinburgh",
    Age: 22,
    Startdate: "29/03/2012",
    Salary: "$433,060",
  },
  {
    Name: "Airi Satou",
    Position: "Accountant",
    Office: "Tokyo",
    Age: 33,
    Startdate: "28/11/2022",
    Salary: "$162,700",
  },
  {
    Name: "Brielle Williamson",
    Position: "Integration Specialist",
    Office: "New York",
    Age: 61,
    Startdate: "02/12/2022",
    Salary: "$372,000",
  },
  {
    Name: "Herrod Chandler",
    Position: "Sales Assistant",
    Office: "San Francisco",
    Age: 59,
    Startdate: "06/05/2023",
    Salary: "$137,500",
  },
  {
    Name: "Rhona Davidson",
    Position: "Integration Specialist",
    Office: "Tokyo",
    Age: 55,
    Startdate: "14/10/2010",
    Salary: "$327,900",
  },
  {
    Name: "Colleen Hurst",
    Position: "Javascript Developer",
    Office: "San Francisco",
    Age: 39,
    Startdate: "15/09/2022",
    Salary: "$205,500",
  },
  {
    Name: "Sonya Frost",
    Position: "Software Engineer",
    Office: "Edinburgh",
    Age: 23,
    Startdate: "13/12/2022",
    Salary: "$103,600",
  },
  {
    Name: "Jena Gaines",
    Position: "Office Manager",
    Office: "London",
    Age: 30,
    Startdate: "19/12/2022",
    Salary: "$90,560",
  },
  {
    Name: "Quinn Flynn",
    Position: "Support Lead",
    Office: "Edinburgh",
    Age: 22,
    Startdate: "03/03/2023",
    Salary: "$342,000",
  },
  {
    Name: "Charde Marshall",
    Position: "Regional Director",
    Office: "San Francisco",
    Age: 36,
    Startdate: "16/04/2023",
    Salary: "$470,600",
  },
  {
    Name: "Haley Kennedy",
    Position: "Senior Marketing Designer",
    Office: "London",
    Age: 43,
    Startdate: "18/12/2022",
    Salary: "$313,500",
  },
  {
    Name: "Tatyana Fitzpatrick",
    Position: "Regional Director",
    Office: "London",
    Age: 19,
    Startdate: "17/03/2023",
    Salary: "$385,750",
  },
  {
    Name: "Michael Silva",
    Position: "Marketing Designer",
    Office: "London",
    Age: 66,
    Startdate: "27/11/2022",
    Salary: "$198,500",
  },
  {
    Name: "Paul Byrd",
    Position: "Chief Financial Officer (CFO)",
    Office: "New York",
    Age: 64,
    Startdate: "09/06/2023",
    Salary: "$725,000",
  },
  {
    Name: "Gloria Little",
    Position: "Systems Administrator",
    Office: "New York",
    Age: 59,
    Startdate: "10/04/2023",
    Salary: "$237,500",
  },
  {
    Name: "Bradley Greer",
    Position: "Software Engineer",
    Office: "London",
    Age: 41,
    Startdate: "13/10/2022",
    Salary: "$132,000",
  },
  {
    Name: "Dai Rios",
    Position: "Personnel Lead",
    Office: "Edinburgh",
    Age: 35,
    Startdate: "26/09/2022",
    Salary: "$217,500",
  },
  {
    Name: "Jenette Caldwell",
    Position: "Development Lead",
    Office: "New York",
    Age: 30,
    Startdate: "03/09/2023",
    Salary: "$345,000",
  },
  {
    Name: "Yuri Berry",
    Position: "Chief Marketing Officer (CMO)",
    Office: "New York",
    Age: 40,
    Startdate: "25/05/2023",
    Salary: "$675,000",
  },
  {
    Name: "Caesar Vance",
    Position: "Pre-Sales Support",
    Office: "New York",
    Age: 21,
    Startdate: "12/12/2022",
    Salary: "$106,450",
  },
  {
    Name: "Doris Wilder",
    Position: "Sales Assistant",
    Office: "Sidney",
    Age: 23,
    Startdate: "20/09/2022",
    Salary: "$85,600",
  },
  {
    Name: "Angelica Ramos",
    Position: "Chief Executive Officer (CEO)",
    Office: "London",
    Age: 47,
    Startdate: "09/10/2022",
    Salary: "$1,200,000",
  },
  {
    Name: "Gavin Joyce",
    Position: "Developer",
    Office: "Edinburgh",
    Age: 42,
    Startdate: "22/12/2022",
    Salary: "$92,575",
  },
  {
    Name: "Jennifer Chang",
    Position: "Regional Director",
    Office: "Singapore",
    Age: 28,
    Startdate: "14/11/2022",
    Salary: "$357,650",
  },
  {
    Name: "Brenden Wagner",
    Position: "Software Engineer",
    Office: "San Francisco",
    Age: 28,
    Startdate: "07/06/2023",
    Salary: "$206,850",
  },
  {
    Name: "Fiona Green",
    Position: "Chief Operating Officer (COO)",
    Office: "San Francisco",
    Age: 48,
    Startdate: "11/03/2023",
    Salary: "$850,000",
  },
  {
    Name: "Shou Itou",
    Position: "Regional Marketing",
    Office: "Tokyo",
    Age: 20,
    Startdate: "14/04/2023",
    Salary: "$163,000",
  },
];
export default {
  data() {
    return {
      columns,
      data,
      title: "Data Tables",
    };
  },
};
</script>
