<template>
  <div class="col-lg-4 col-md-12 sidebar-right theiaStickySidebar">
    <div class="stickysidebar">
      <!-- Latest Posts -->
      <div class="pro-post widget-box post-widget">
        <h4 class="pro-title">Latest Posts</h4>
        <div class="pro-content pt-0">
          <ul class="latest-posts">
            <li>
              <div class="post-thumb">
                <router-link to="/blog/blog-details">
                  <img
                    class="img-fluid"
                    src="@/assets/img/blog/blog-thumb-03.jpg"
                    alt="Img"
                  />
                </router-link>
              </div>
              <div class="post-info">
                <h4>
                  <router-link to="/blog/blog-details"
                    >Kofejob - How to get job through online?</router-link
                  >
                </h4>
                <a href="javascript:void(0);" class="posts-date"
                  ><i class="feather-calendar"></i> 2 May 2021</a
                >
              </div>
            </li>
            <li>
              <div class="post-thumb">
                <router-link to="/blog/blog-details">
                  <img
                    class="img-fluid"
                    src="@/assets/img/blog/blog-thumb-02.jpg"
                    alt="Img"
                  />
                </router-link>
              </div>
              <div class="post-info">
                <h4>
                  <router-link to="/blog/blog-details"
                    >People who completed NAND technology got job 90%
                  </router-link>
                </h4>
                <a href="javascript:void(0);" class="posts-date"
                  ><i class="feather-calendar"></i> 3 May 2021</a
                >
              </div>
            </li>
            <li>
              <div class="post-thumb">
                <router-link to="/blog/blog-details">
                  <img
                    class="img-fluid"
                    src="@/assets/img/blog/blog-thumb-01.jpg"
                    alt="Img"
                  />
                </router-link>
              </div>
              <div class="post-info">
                <h4>
                  <router-link to="/blog/blog-details"
                    >There are many variations of passages</router-link
                  >
                </h4>
                <a href="javascript:void(0);" class="posts-date"
                  ><i class="feather-calendar"></i> 4 May 2021</a
                >
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- /Latest Posts -->

      <!-- Categories -->
      <div class="pro-post widget-box category-widget">
        <h4 class="pro-title">Blog Categories</h4>
        <div class="pro-content">
          <ul class="category-link">
            <li><a href="javascript:void(0);">Web Development</a></li>
            <li><a href="javascript:void(0);">IT Consultancy</a></li>
            <li><a href="javascript:void(0);">Email Marketing</a></li>
            <li><a href="javascript:void(0);">Business Consulting</a></li>
            <li><a href="javascript:void(0);">Apps Development</a></li>
            <li><a href="javascript:void(0);">SEO Optimizations</a></li>
          </ul>
        </div>
      </div>
      <!-- /Categories -->

      <!-- Tags -->
      <div class="pro-post widget-box tags-widget">
        <h4 class="pro-title">Tags</h4>
        <div class="pro-content">
          <ul class="tags">
            <li><a href="javascript:void(0);" class="tag">Employer</a></li>
            <li><a href="javascript:void(0);" class="tag">Student</a></li>
            <li><a href="javascript:void(0);" class="tag">Freelancer</a></li>
            <li><a href="javascript:void(0);" class="tag">Designer</a></li>
            <li><a href="javascript:void(0);" class="tag">Jobs</a></li>
            <li><a href="javascript:void(0);" class="tag">Developer</a></li>
            <li><a href="javascript:void(0);" class="tag">Coding</a></li>
            <li><a href="javascript:void(0);" class="tag">Skills</a></li>
            <li><a href="javascript:void(0);" class="tag">Knowledge</a></li>
            <li><a href="javascript:void(0);" class="tag">Node Js</a></li>
            <li><a href="javascript:void(0);" class="tag">Courses</a></li>
            <li><a href="javascript:void(0);" class="tag">Engineer</a></li>
            <li><a href="javascript:void(0);" class="tag">Online</a></li>
            <li><a href="javascript:void(0);" class="tag">Study</a></li>
            <li><a href="javascript:void(0);" class="tag">Project</a></li>
            <li><a href="javascript:void(0);" class="tag">Experience</a></li>
            <li><a href="javascript:void(0);" class="tag">Freshers</a></li>
          </ul>
        </div>
      </div>
      <!-- /Tags -->
    </div>
  </div>
</template>
