<template>
  <pagebreadcrumb :title="title" :text="text" :text1="text1" />
  <!-- Page Content -->
  <div class="content">
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-lg-4 col-xl-3 theiaStickySidebar">
          <div class="stickysidebar">
            <projectsearch></projectsearch>
          </div>
        </div>

        <div class="col-md-12 col-lg-8 col-xl-9">
          <div class="sort-tab develop-list-select">
            <div class="row align-items-center">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                <div class="d-flex align-items-center">
                  <div class="freelance-view">
                    <h4>Found 9 Results</h4>
                  </div>
                </div>
              </div>
              <div
                class="col-xl-6 col-lg-6 col-md-6 col-sm-6 d-flex justify-content-sm-end"
              >
                <div class="sort-by">
                  <vue-select :options="Rating" id="rating" placeholder="Select" />
                </div>
                <ul class="list-grid d-flex align-items-center">
                  <li>
                    <router-link to="/freelancer/project" class="favour-active"
                      ><i class="fas fa-th-large"></i
                    ></router-link>
                  </li>
                  <li>
                    <router-link to="/freelancer/project-list"
                      ><i class="fas fa-list"></i
                    ></router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="bootstrap-tags text-start pl-0 d-none">
            <span class="badge badge-pill badge-skills"
              >UI/UX Developer
              <span class="tag-close" data-role="remove"
                ><i class="fas fa-times"></i></span
            ></span>
            <span class="badge badge-pill badge-skills"
              >USA
              <span class="tag-close" data-role="remove"
                ><i class="fas fa-times"></i></span
            ></span>
            <span class="badge badge-pill badge-skills"
              >Hourly
              <span class="tag-close" data-role="remove"
                ><i class="fas fa-times"></i></span
            ></span>
            <span class="badge badge-pill badge-skills"
              >0-1 years
              <span class="tag-close" data-role="remove"
                ><i class="fas fa-times"></i></span
            ></span>
            <span class="badge badge-pill badge-skills"
              >USD
              <span class="tag-close" data-role="remove"
                ><i class="fas fa-times"></i></span
            ></span>
          </div>

          <div class="row">
            <!-- Project Content -->
            <div
              class="col-xl-4 col-md-6"
              v-for="record in Project_Grid"
              :key="record.id"
            >
              <div class="freelance-widget widget-author position-relative">
                <div class="freelance-content">
                  <div class="freelance-location freelance-time">
                    <i class="feather-clock me-1"></i> {{ record.Posted }}
                  </div>
                  <a data-bs-toggle="modal" href="#rating" class="favourite"
                    ><i class="feather-heart"></i
                  ></a>
                  <div class="author-heading">
                    <div class="freelance-img">
                      <a href="javascript:void(0);">
                        <img
                          :src="require(`@/assets/img/company/${record.Image}`)"
                          alt="author"
                        />
                        <span class="verified"><i class="fas fa-check-circle"></i></span>
                      </a>
                    </div>
                    <div class="profile-name">
                      <div class="author-location">{{ record.Amaze }}</div>
                    </div>
                    <div class="freelance-info">
                      <h3>
                        <a href="javascript:void(0);">{{ record.Ui }}</a>
                      </h3>
                      <div class="freelance-location">
                        <img
                          :src="require(`@/assets/img/icon/${record.Image1}`)"
                          alt="img"
                        />{{ record.location }}
                      </div>
                    </div>
                    <div class="freelance-tags">
                      <a href="javascript:void(0);"
                        ><span class="badge badge-pill badge-design me-1">{{
                          record.Html
                        }}</span></a
                      >
                      <a href="javascript:void(0);"
                        ><span class="badge badge-pill badge-design me-1">{{
                          record.React
                        }}</span></a
                      >
                      <a href="javascript:void(0);"
                        ><span class="badge badge-pill badge-design me-1">{{
                          record.Php
                        }}</span></a
                      >
                    </div>
                    <div class="freelancers-price">{{ record.Amount }}</div>
                  </div>
                  <div class="counter-stats">
                    <ul>
                      <li>
                        <h5>{{ record.Date }}</h5>
                        <h3 class="counter-value">{{ record.Days }}</h3>
                      </li>
                      <li>
                        <h5>{{ record.Proposal }}</h5>
                        <h3 class="counter-value">{{ record.cost }}</h3>
                      </li>
                      <li>
                        <h5>{{ record.job }}</h5>
                        <h3 class="counter-value">
                          <span class="jobtype">{{ record.jobtype }}</span>
                        </h3>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="cart-hover">
                  <router-link to="/freelancer/project-details" class="btn-cart" tabindex="-1"
                    >View Project</router-link
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <ul class="paginations list-pagination">
                <li class="page-item">
                  <a href="javascript:void(0);"><i class="feather-chevron-left"></i></a>
                </li>
                <li class="page-item">
                  <a href="javascript:void(0);" class="active">1</a>
                </li>
                <li class="page-item"><a href="javascript:void(0);">2</a></li>
                <li class="page-item"><a href="javascript:void(0);">3</a></li>
                <li class="page-item"><a href="javascript:void(0);">...</a></li>
                <li class="page-item"><a href="javascript:void(0);">10</a></li>
                <li class="page-item">
                  <a href="javascript:void(0);"><i class="feather-chevron-right"></i></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Page Content -->
  <projectmodal></projectmodal>
</template>
<script>
import Project_Grid from "@/assets/json/project-grid.json";
export default {
  data() {
    return {
      Project_Grid: Project_Grid,
      title: "Project Grid",
      text: "Home",
      text1: "Projects",
      Rating: ["Sort by (Default)", "Relevance", "Rating", "Popular", "Latest", "Free"],
    };
  },
};
</script>
