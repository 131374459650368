<template>
  <div class="col-lg-12 w-100">
    <div class="card flex-fill mb-3">
      <div class="pro-head">
        <h4 class="pro-titles without-border mb-0">Experience</h4>
      </div>
      <div class="pro-body">
        <div class="form-row align-items-center skill-cont">
          <div class="input-block col-lg-3">
            <label class="form-label">Company Name</label>
            <input type="text" class="form-control" />
          </div>
          <div class="input-block col-lg-3">
            <label class="form-label">Position</label>
            <input type="text" class="form-control" />
          </div>
          <div class="col-md-3 input-block floating-icon">
            <label class="form-label">Start Date</label>
            <datepicker
              v-model="startdate"
              placeholder="Choose"
              class="form-control datetimepicker"
              :editable="true"
              :clearable="false"
            />
          </div>
          <div class="col-md-2 input-block floating-icon">
            <label class="form-label">End Date</label>
            <datepicker
              v-model="startdateOne"
              placeholder="Choose"
              class="form-control datetimepicker"
              :editable="true"
              :clearable="false"
            />
          </div>
          <div class="input-block col-lg-1 mb-0">
            <a
              href="javascript:void(0);"
              class="btn trash-icon"
              @click="deleteexperience(index)"
              ><i class="far fa-trash-alt"></i
            ></a>
          </div>
          <div id="exp_add_row" class="w-100">
            <div
              class="col-lg-12 w-100"
              v-for="(experience, index) in experiences"
              :key="index"
            >
              <div class="card flex-fill mb-3">
                <div class="pro-head">
                  <h4 class="pro-titles without-border mb-0">Experience</h4>
                </div>
                <div class="pro-body">
                  <div class="form-row align-items-center skill-cont">
                    <div class="input-block col-lg-3">
                      <label class="form-label">Company Name</label>
                      <input type="text" class="form-control" />
                    </div>
                    <div class="input-block col-lg-3">
                      <label class="form-label">Position</label>
                      <input type="text" class="form-control" />
                    </div>
                    <div class="col-md-3 input-block floating-icon">
                      <label class="form-label">Start Date</label>
                      <datepicker
                        v-model="startdateTwo"
                        placeholder="Choose"
                        class="form-control datetimepicker"
                        :editable="true"
                        :clearable="false"
                      />
                    </div>
                    <div class="col-md-2 input-block floating-icon">
                      <label class="form-label">End Date</label>
                      <datepicker
                        v-model="startdateThree"
                        placeholder="Choose"
                        class="form-control datetimepicker"
                        :editable="true"
                        :clearable="false"
                      />
                    </div>
                    <div class="input-block col-lg-1 mb-0">
                      <a
                        href="javascript:void(0);"
                        class="btn trash-icon"
                        @click="deleteexperience(index)"
                        ><i class="far fa-trash-alt"></i
                      ></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <a
            href="javascript:void(0)"
            class="add-btn-form add-exp w-100 d-block text-end"
            @click="addexperience"
            ><i class="feather-plus-circle me-2"></i>Add New</a
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
const currentDate = ref(new Date());
const currentDateOne = ref(new Date());
const currentDateTwo = ref(new Date());
const currentDateThree = ref(new Date());
export default {
  data() {
    return {
      experiences: [],
      startdate: currentDate,
      startdateOne: currentDateOne,
      startdateTwo: currentDateTwo,
      startdateThree: currentDateThree,
    };
  },
  methods: {
    addexperience() {
      this.experiences.push({});
    },
    deleteexperience(index) {
      this.experiences.splice(index, 2);
    },
  },
};
</script>
