<template>
    <!-- Footer -->
    <footer class="footer">
        <div class="footer-top ">
            <div class="container">

                <div class="row">
                    <div class=" col-lg-4 col-md-12">
                        <div class="footer-bottom-logo">
                            <router-link to="/home/" class="menu-logo">
                                <img src="@/assets/img/logo.svg" class="img-fluid" alt="Logo">
                            </router-link>
                            <p>We’re always in search for talented and motivated people. Don’t be shy introduce yourself!
                            </p>
                            <ul>
                                <li>
                                    <a href="javascript:void(0);"><i class="fa-brands fa-facebook-f"
                                            aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);"><i class="fa-brands fa-twitter"
                                            aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);"><i class="fa-brands fa-instagram"
                                            aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);"><i class="fa-brands fa-linkedin"
                                            aria-hidden="true"></i></a>
                                </li>
                            </ul>
                            <a href="javascript:void(0);" class="btn btn-connectus">Contact with us</a>
                        </div>
                    </div>
                    <div class=" col-lg-8 col-md-12">
                        <div class="row">
                            <div class="col-xl-3 col-md-6">
                                <div class="footer-widget footer-menu">
                                    <h2 class="footer-title">Useful Links</h2>
                                    <ul>
                                        <li><router-link to="/pages/about"><i class="fas fa-angle-right me-1"></i>About Us</router-link></li>
                                        <li><router-link to="/blog/blog-list"><i class="fas fa-angle-right me-1"></i>Blog</router-link></li>
                                        <li><router-link to="/"><i class="fas fa-angle-right me-1"></i>Login</router-link></li>
                                        <li><router-link to="/register"><i class="fas fa-angle-right me-1"></i>Register</router-link></li>
                                        <li><router-link to="/forgot-password"><i class="fas fa-angle-right me-1"></i>Forgot
                                                Password</router-link></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-xl-3 col-md-6">
                                <div class="footer-widget footer-menu">
                                    <h2 class="footer-title">Help & Support</h2>
                                    <ul>
                                        <li><a href="javascript:void(0);"><i class="fas fa-angle-right me-1"></i>Browse
                                                Candidates</a></li>
                                        <li><a href="javascript:void(0);"><i class="fas fa-angle-right me-1"></i>Employers
                                                Dashboard</a></li>
                                        <li><a href="javascript:void(0);"><i class="fas fa-angle-right me-1"></i>Job
                                                Packages</a></li>
                                        <li><a href="javascript:void(0);"><i class="fas fa-angle-right me-1"></i>Jobs
                                                Featured</a></li>
                                        <li><a href="javascript:void(0);"><i class="fas fa-angle-right me-1"></i>Post A
                                                Job</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-xl-3 col-md-6">
                                <div class="footer-widget footer-menu">
                                    <h2 class="footer-title">Other Links</h2>
                                    <ul>
                                        <li><router-link to="/freelancer/freelancer-dashboard"><i
                                                    class="fas fa-angle-right me-1"></i>Freelancers</router-link></li>
                                        <li><router-link to="/freelancer/freelancer-portfolio"><i
                                                    class="fas fa-angle-right me-1"></i>Freelancer Details</router-link></li>
                                        <li><router-link to="/freelancer/project"><i class="fas fa-angle-right me-1"></i>Project</router-link></li>
                                        <li><router-link to="/freelancer/project-details"><i class="fas fa-angle-right me-1"></i>Project
                                                Details</router-link></li>
                                        <li><router-link to="/post-project"><i class="fas fa-angle-right me-1"></i>Post
                                                Project</router-link></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-xl-3 col-md-6">
                                <div class="footer-widget footer-menu">
                                    <h2 class="footer-title">Connect With Us</h2>
                                    <ul>
                                        <li><router-link to="/freelancer/freelancer-chats"><i class="fas fa-angle-right me-1"></i>Chat</router-link>
                                        </li>
                                        <li><router-link to="/faq"><i class="fas fa-angle-right me-1"></i>Faq</router-link></li>
                                        <li><router-link to="/freelancer/freelancer-chats"><i
                                                    class="fas fa-angle-right me-1"></i>Reviews</router-link></li>
                                        <li><router-link to="/privacy-policy"><i class="fas fa-angle-right me-1"></i>Privacy
                                                Policy</router-link></li>
                                        <li><router-link to="/term-condition"><i class="fas fa-angle-right me-1"></i>Terms of
                                                use</router-link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /Footer Top -->

        <!-- Footer Bottom -->
        <div class="footer-bottom">
            <div class="container">

                <!-- Copyright -->
                <div class="copyright">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="copyright-text text-center">
                                <p class="mb-0">Copyright {{ new Date().getFullYear() }} &copy; KofeJob. All right reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- /Copyright -->
            </div>
        </div>
        <!-- /Footer Bottom -->
    </footer>
    <!-- /Footer -->
</template>