<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>

  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content report-box container-fluid">
      <!-- Page Header -->
      <div class="page-header subscribe-head">
        <div class="row align-items-center">
          <div class="col">
            <breadcrumbadmin :title="title" :text="text" :text1="text1" />
          </div>
          <div class="col-auto">
            <router-link class="btn back-user" to="/admin/users">
              <img src="@/assets/admin_img/icon/icon-08.svg" alt="Image" /> Back to list
            </router-link>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <!-- User Profile -->
      <div class="profile-status">
        <div class="row">
          <div class="col-lg-6 col-md-6">
            <div class="profile-user-box">
              <router-link to="/admin/profile"
                ><img
                  class="avatar-img rounded-circle"
                  src="@/assets/admin_img/profiles/avatar-16.jpg"
                  alt="User Image"
              /></router-link>
              <div class="profile-name-box">
                <h2><router-link to="/admin/profile">William Cooper</router-link></h2>
                <p>Date of Join: 22 May 2023</p>
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-md-2">
            <div class="additional-info">
              <p>Freelancer ID</p>
              <h5>UID00003</h5>
            </div>
          </div>
          <div class="col-lg-2 col-md-2">
            <div class="additional-info">
              <p>Email</p>
              <h5 class="verify-mail">
                <i data-feather="check-circle" class="me-1 text-success"></i> Verified
              </h5>
            </div>
          </div>
          <div class="col-lg-2 col-md-2">
            <div class="additional-info">
              <p>Account Status</p>
              <h5 class="text-danger">Active</h5>
            </div>
          </div>
        </div>
      </div>
      <!-- /User Profile -->

      <div class="row">
        <div class="col-sm-12">
          <div class="card profile-home">
            <userstabset />

            <!-- Active Profile Header -->
            <div class="page-header profile-information">
              <div class="row align-items-center">
                <div class="col-12">
                  <h3 class="page-title">Personal Information</h3>
                  <p>Basic info, like name and address etc that used on platform.</p>
                </div>
              </div>
            </div>
            <!-- /Active Profile Header -->

            <!-- Basic Information -->
            <div class="row">
              <div class="col-md-6">
                <div class="basic-info">
                  <h4>BASIC INFORMATION</h4>
                  <ul class="information-list">
                    <li>Username <span>William</span></li>
                    <li>Email Address <span>william@example.com</span></li>
                    <li>Full Name <span>William Cooper</span></li>
                    <li>Display Name <span>Ishtiyak</span></li>
                    <li>Mobile Number <span>707 229 8991</span></li>
                    <li>Date of Birth <span>Jan 08, 1997</span></li>
                    <li>Gender <span>Not updated yet</span></li>
                  </ul>
                </div>
              </div>
              <div class="col-md-6">
                <div class="basic-info">
                  <h4>RESIDENTIAL ADDRESS</h4>
                  <ul class="information-list">
                    <li>Address Line <span>Not updated yet</span></li>
                    <li>City<span>Not updated yet</span></li>
                    <li>State / Province<span>Not updated yet</span></li>
                    <li>Zip / Postal Code<span>Not updated yet</span></li>
                    <li>Country <span>Us</span></li>
                  </ul>
                </div>
              </div>
              <div class="col-md-12">
                <div class="basic-info additional-space">
                  <h4>ADDITIONAL INFORMATION</h4>
                </div>
                <div class="row">
                  <div class="col-lg-3 col-md-6">
                    <div class="additional-info">
                      <p>Join Date</p>
                      <h5>May 20, 2023</h5>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-6">
                    <div class="additional-info">
                      <p>Reg Method</p>
                      <h5>By Email</h5>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-6">
                    <div class="additional-info">
                      <p>Email Verified At</p>
                      <h5>May 20, 2023 04:53 AM</h5>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-6">
                    <div class="additional-info mb-0">
                      <p>Save Activity Logs</p>
                      <h5>Enabled</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Basic Information -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Page Wrapper -->
</template>

<script>
export default {
  data() {
    return {
      title: "Freelancer Profile",
      text: "Home",
      text1: "Freelancer Profile",
    };
  },
};
</script>
