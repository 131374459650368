<template>
  <div id="app">
    <div class="home-page bg-one">
      <div class="main-wrapper">
<!--        <layouts-header v-if="showHeader"></layouts-header>-->

        <router-view />

<!--        <layouts-footer v-if="showFooter"></layouts-footer>-->
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { loadStyles } from "@/loadStyles";

export default {
  setup() {
    const router = useRouter();
    loadStyles();
    return {
      router,
    };
  },
  computed: {
    showHeader() {
      return (
        this.$route.path !== "/home/home2" &&
        this.$route.path !== "/home/home3" &&
        this.$route.path !== "/home/home4" &&
        this.$route.path !== "/home/home5" &&
        this.$route.path !== "/pages/onboard-screen" &&
        this.$route.path !== "/pages/onboard-screen-employer" &&
        this.$route.path !== "/chats" &&
        this.$route.path !== "/admin/index" &&
        this.$route.path !== "/admin/activities" &&
        this.$route.path !== "/admin/bid-fees" &&
        this.$route.path !== "/admin/categories" &&
        this.$route.path !== "/admin/change-password" &&
        this.$route.path !== "/admin/components" &&
        this.$route.path !== "/admin/contest-entry-fees" &&
        this.$route.path !== "/admin/contests-fees" &&
        this.$route.path !== "/admin/data-tables" &&
        this.$route.path !== "/admin/delete-account" &&
        this.$route.path !== "/admin/deposit-cancelled" &&
        this.$route.path !== "/admin/deposit" &&
        this.$route.path !== "/admin/deposit-pending" &&
        this.$route.path !== "/admin/deposit-hold" &&
        this.$route.path !== "/admin/deposit-completed" &&
        this.$route.path !== "/admin/projects" &&
        this.$route.path !== "/admin/users" &&
        this.$route.path !== "/admin/user-active" &&
        this.$route.path !== "/admin/user-inactive" &&
        this.$route.path !== "/admin/user-suspended" &&
        this.$route.path !== "/admin/user-administrator" &&
        this.$route.path !== "/admin/withdrawn" &&
        this.$route.path !== "/admin/withdrawn-pending" &&
        this.$route.path !== "/admin/withdrawn-cancelled" &&
        this.$route.path !== "/admin/withdrawn-completed" &&
        this.$route.path !== "/admin/transaction" &&
        this.$route.path !== "/admin/transaction-deposit" &&
        this.$route.path !== "/admin/transaction-withdraw" &&
        this.$route.path !== "/admin/transaction-pending" &&
        this.$route.path !== "/admin/transaction-onhold" &&
        this.$route.path !== "/admin/transaction-completed" &&
        this.$route.path !== "/admin/providers" &&
        this.$route.path !== "/admin/subscription" &&
        this.$route.path !== "/admin/subscripe-freelancer" &&
        this.$route.path !== "/admin/view-price-employe" &&
        this.$route.path !== "/admin/view-price-freelance" &&
        this.$route.path !== "/admin/employe-list" &&
        this.$route.path !== "/admin/freelance-list" &&
        this.$route.path !== "/admin/reports" &&
        this.$route.path !== "/admin/roles" &&
        this.$route.path !== "/admin/roles-permission" &&
        this.$route.path !== "/admin/skills" &&
        this.$route.path !== "/admin/verify-identity" &&
        this.$route.path !== "/admin/settings" &&
        this.$route.path !== "/admin/localization-details" &&
        this.$route.path !== "/admin/payment-settings" &&
        this.$route.path !== "/admin/email-settings" &&
        this.$route.path !== "/admin/social-settings" &&
        this.$route.path !== "/admin/social-links" &&
        this.$route.path !== "/admin/seo-settings" &&
        this.$route.path !== "/admin/others-settings" &&
        this.$route.path !== "/admin/project-bidding" &&
        this.$route.path !== "/admin/project-invoice" &&
        this.$route.path !== "/admin/project-earnings" &&
        this.$route.path !== "/admin/earning-employer" &&
        this.$route.path !== "/admin/earning-freelancer" &&
        this.$route.path !== "/admin/profile-transactions" &&
        this.$route.path !== "/admin/user-profile" &&
        this.$route.path !== "/admin/tax-types" &&
        this.$route.path !== "/admin/form-basic-inputs" &&
        this.$route.path !== "/admin/form-input-groups" &&
        this.$route.path !== "/admin/form-horizontal" &&
        this.$route.path !== "/admin/form-vertical" &&
        this.$route.path !== "/admin/form-mask" &&
        this.$route.path !== "/admin/form-validation" &&
        this.$route.path !== "/admin/tables-basic" &&
        this.$route.path !== "/admin/profile" &&
        this.$route.path !== "/admin/forgot-password" &&
        this.$route.path !== "/admin/login" &&
        this.$route.path !== "/admin/projects-fees" &&
        this.$route.path !== "/admin/sub-category" &&
        this.$route.path !== "/admin/register" &&
        this.$route.path !== "/admin/view-estimate" &&
        this.$route.path !== "/admin/view-invoice"
      );
    },
    showFooter() {
      return (
        this.$route.path !== "/home/home2" &&
        this.$route.path !== "/home/home3" &&
        this.$route.path !== "/home/home4" &&
        this.$route.path !== "/home/home5" &&
        this.$route.path !== "/pages/onboard-screen" &&
        this.$route.path !== "/pages/onboard-screen-employer" &&
        this.$route.path !== "/employers/chats" &&
        this.$route.path !== "/freelancer/freelancer-chats" &&
        this.$route.path !== "/register" &&
        this.$route.path !== "/change-passwords" &&
        this.$route.path !== "/" &&
        this.$route.path !== "/admin/index" &&
        this.$route.path !== "/admin/activities" &&
        this.$route.path !== "/admin/bid-fees" &&
        this.$route.path !== "/admin/categories" &&
        this.$route.path !== "/admin/change-password" &&
        this.$route.path !== "/admin/components" &&
        this.$route.path !== "/admin/contest-entry-fees" &&
        this.$route.path !== "/admin/contests-fees" &&
        this.$route.path !== "/admin/data-tables" &&
        this.$route.path !== "/admin/delete-account" &&
        this.$route.path !== "/admin/deposit-cancelled" &&
        this.$route.path !== "/admin/deposit" &&
        this.$route.path !== "/admin/deposit-pending" &&
        this.$route.path !== "/admin/deposit-hold" &&
        this.$route.path !== "/admin/deposit-completed" &&
        this.$route.path !== "/admin/projects" &&
        this.$route.path !== "/admin/users" &&
        this.$route.path !== "/admin/user-active" &&
        this.$route.path !== "/admin/user-inactive" &&
        this.$route.path !== "/admin/user-suspended" &&
        this.$route.path !== "/admin/user-administrator" &&
        this.$route.path !== "/admin/withdrawn" &&
        this.$route.path !== "/admin/withdrawn-pending" &&
        this.$route.path !== "/admin/withdrawn-cancelled" &&
        this.$route.path !== "/admin/withdrawn-completed" &&
        this.$route.path !== "/admin/transaction" &&
        this.$route.path !== "/admin/transaction-deposit" &&
        this.$route.path !== "/admin/transaction-withdraw" &&
        this.$route.path !== "/admin/transaction-pending" &&
        this.$route.path !== "/admin/transaction-onhold" &&
        this.$route.path !== "/admin/transaction-completed" &&
        this.$route.path !== "/admin/providers" &&
        this.$route.path !== "/admin/subscription" &&
        this.$route.path !== "/admin/subscripe-freelancer" &&
        this.$route.path !== "/admin/view-price-employe" &&
        this.$route.path !== "/admin/view-price-freelance" &&
        this.$route.path !== "/admin/employe-list" &&
        this.$route.path !== "/admin/freelance-list" &&
        this.$route.path !== "/admin/reports" &&
        this.$route.path !== "/admin/roles" &&
        this.$route.path !== "/admin/roles-permission" &&
        this.$route.path !== "/admin/skills" &&
        this.$route.path !== "/admin/verify-identity" &&
        this.$route.path !== "/admin/settings" &&
        this.$route.path !== "/admin/localization-details" &&
        this.$route.path !== "/admin/payment-settings" &&
        this.$route.path !== "/admin/email-settings" &&
        this.$route.path !== "/admin/social-settings" &&
        this.$route.path !== "/admin/social-links" &&
        this.$route.path !== "/admin/seo-settings" &&
        this.$route.path !== "/admin/others-settings" &&
        this.$route.path !== "/admin/project-bidding" &&
        this.$route.path !== "/admin/project-invoice" &&
        this.$route.path !== "/admin/project-earnings" &&
        this.$route.path !== "/admin/earning-employer" &&
        this.$route.path !== "/admin/earning-freelancer" &&
        this.$route.path !== "/admin/profile-transactions" &&
        this.$route.path !== "/admin/user-profile" &&
        this.$route.path !== "/admin/tax-types" &&
        this.$route.path !== "/admin/form-basic-inputs" &&
        this.$route.path !== "/admin/form-input-groups" &&
        this.$route.path !== "/admin/form-horizontal" &&
        this.$route.path !== "/admin/form-vertical" &&
        this.$route.path !== "/admin/form-mask" &&
        this.$route.path !== "/admin/form-validation" &&
        this.$route.path !== "/admin/tables-basic" &&
        this.$route.path !== "/admin/profile" &&
        this.$route.path !== "/admin/forgot-password" &&
        this.$route.path !== "/admin/login" &&
        this.$route.path !== "/admin/projects-fees" &&
        this.$route.path !== "/admin/sub-category" &&
        this.$route.path !== "/admin/register" &&
        this.$route.path !== "/admin/view-estimate" &&
        this.$route.path !== "/admin/view-invoice"
      );
    },
  },
};
</script>
