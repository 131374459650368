<template>
  <section class="faq-section-three bg-white" id="faq">
    <div class="container">
      <div
        class="section-header section-header-three text-center aos"
        data-aos="fade-up"
      >
        <span class="badge title-badge">FAQ’S</span>
        <h2 class="header-title">Frequently Question Answer</h2>
      </div>
      <div class="row" id="accordionExample">
        <div class="col-lg-6">
          <div class="faq-card aos" data-aos="fade-up">
            <h4 class="faq-title">
              <a
                class="collapseds active"
                data-bs-toggle="collapse"
                href="#faqOne"
                aria-expanded="true"
                >1. What are the costs to buy a house?</a
              >
            </h4>
            <div
              id="faqOne"
              class="card-collapse collapse show"
              data-bs-parent="#accordionExample"
            >
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
                tellus, luctus nec ullamcorper mattis, pulvinar dapibus
                leo.Lorem ipsum dolor sit amet,
              </p>
            </div>
          </div>
          <div class="faq-card aos" data-aos="fade-up">
            <h4 class="faq-title">
              <a
                class="collapsed"
                data-bs-toggle="collapse"
                href="#faqtwo"
                aria-expanded="false"
                >2. What are the costs to buy a house?</a
              >
            </h4>
            <div
              id="faqtwo"
              class="card-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
                tellus, luctus nec ullamcorper mattis, pulvinar dapibus
                leo.Lorem ipsum dolor sit amet,
              </p>
            </div>
          </div>
          <div class="faq-card aos" data-aos="fade-up">
            <h4 class="faq-title">
              <a
                class="collapsed"
                data-bs-toggle="collapse"
                href="#faqthree"
                aria-expanded="false"
                >3. Do you have loan consultants?</a
              >
            </h4>
            <div
              id="faqthree"
              class="card-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
                tellus, luctus nec ullamcorper mattis, pulvinar dapibus
                leo.Lorem ipsum dolor sit amet,
              </p>
            </div>
          </div>
          <div class="faq-card aos" data-aos="fade-up">
            <h4 class="faq-title">
              <a
                class="collapsed"
                data-bs-toggle="collapse"
                href="#faqfour"
                aria-expanded="false"
                >4. What are the costs to buy a house?</a
              >
            </h4>
            <div
              id="faqfour"
              class="card-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
                tellus, luctus nec ullamcorper mattis, pulvinar dapibus
                leo.Lorem ipsum dolor sit amet,
              </p>
            </div>
          </div>
          <div class="faq-card aos" data-aos="fade-up">
            <h4 class="faq-title">
              <a
                class="collapsed"
                data-bs-toggle="collapse"
                href="#faqfive"
                aria-expanded="false"
                >5. What are the costs to buy a house?</a
              >
            </h4>
            <div
              id="faqfive"
              class="card-collapse collapse"
              data-bs-parent="#accordionExample"
            >
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
                tellus, luctus nec ullamcorper mattis, pulvinar dapibus
                leo.Lorem ipsum dolor sit amet,
              </p>
            </div>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="faq-imgs">
            <img src="@/assets/img/faqs.jpg" alt="img" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
