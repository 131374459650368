<template>
  <div class="modal fade" id="payout_modal">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h4>Subscription - Basic Plan</h4>
          <span class="modal-close"
            ><a href="javascript:void(0);" data-bs-dismiss="modal" aria-label="Close"
              ><i class="fa fa-times orange-text"></i></a
          ></span>
        </div>
        <div class="modal-body">
          <div class="modal-info">
            <div class="wallet-add">
              <div class="row">
                <div class="col-md-4">
                  <div class="bank-selection">
                    <input
                      type="radio"
                      value="attach_link"
                      id="rolelink"
                      name="attachment"
                      checked=""
                    />
                    <label for="rolelink">
                      <img src="@/assets/img/paypal.svg" alt="Paypal" />
                      <span class="role-check"
                        ><i class="fa-solid fa-circle-check"></i
                      ></span>
                    </label>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="bank-selection">
                    <input
                      type="radio"
                      value="attach_link"
                      id="rolelink1"
                      name="attachment"
                    />
                    <label for="rolelink1">
                      <img src="@/assets/img/stripe.svg" alt="Stripe" />
                      <span class="role-check"
                        ><i class="fa-solid fa-circle-check"></i
                      ></span>
                    </label>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="bank-selection">
                    <input
                      type="radio"
                      value="attach_link"
                      id="rolelink2"
                      name="attachment"
                    />
                    <label for="rolelink2">
                      <img src="@/assets/img/bank.svg" alt="image" />
                      <span class="role-check"
                        ><i class="fa-solid fa-circle-check"></i
                      ></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <form action="freelancer-membership">
            <div class="doc-btn text-end">
              <a data-bs-dismiss="modal" class="btn btn-gray">Cancel</a>
              <a href="#success-hire" data-bs-toggle="modal" class="btn btn-primary"
                >Subscribe Now</a
              >
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- The Modal -->
  <div class="modal fade success-modal hire-modal" id="success-hire">
    <div class="modal-dialog modal-dialog-centered modal-md">
      <div class="modal-content">
        <div class="modal-body pt-4">
          <div class="success-msg-content text-center">
            <h4>Payment Successfully</h4>
            <p>
              Thanks for Purchasing “Basic plan”, Enjoy the benefit of plan for a month.
            </p>
            <a
              href="javascript:void(0);"
              data-bs-dismiss="modal"
              class="btn btn-primary mt-3"
              >Close</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /The Modal -->
</template>
