<template>
    <section class="faq-section-three" id="faq">
        <div class="container">
            <div class="section-header section-header-three text-center aos" data-aos="fade-up">
                <span class="badge title-badge">FAQ’S</span>
                <h2 class="header-title">Frequently Question Answer </h2>
            </div>
            <div class="row" id="accordionExample">
                <div class="col-lg-6">
                    <div class="faq-card aos aos-init aos-animate" data-aos="fade-up">
                        <h4 class="faq-title">
                            <a class="collapseds active" data-bs-toggle="collapse" href="#faqOne" aria-expanded="true">1.
                                What are the costs to buy a house?</a>
                        </h4>
                        <div id="faqOne" class="card-collapse collapse show" data-bs-parent="#accordionExample">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec
                                ullamcorper mattis, pulvinar dapibus leo.Lorem ipsum dolor sit amet,</p>
                        </div>
                    </div>
                    <div class="faq-card aos aos-init aos-animate" data-aos="fade-up">
                        <h4 class="faq-title">
                            <a class="collapsed" data-bs-toggle="collapse" href="#faqtwo" aria-expanded="false">2. What are
                                the costs to buy a house?</a>
                        </h4>
                        <div id="faqtwo" class="card-collapse collapse" data-bs-parent="#accordionExample">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec
                                ullamcorper mattis, pulvinar dapibus leo.Lorem ipsum dolor sit amet,</p>
                        </div>
                    </div>
                    <div class="faq-card aos aos-init aos-animate" data-aos="fade-up">
                        <h4 class="faq-title">
                            <a class="collapsed" data-bs-toggle="collapse" href="#faqthree" aria-expanded="false">3. Do you
                                have loan consultants?</a>
                        </h4>
                        <div id="faqthree" class="card-collapse collapse" data-bs-parent="#accordionExample">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec
                                ullamcorper mattis, pulvinar dapibus leo.Lorem ipsum dolor sit amet,</p>
                        </div>
                    </div>
                    <div class="faq-card aos aos-init aos-animate" data-aos="fade-up">
                        <h4 class="faq-title">
                            <a class="collapsed" data-bs-toggle="collapse" href="#faqfour" aria-expanded="false">4. What are
                                the costs to buy a house?</a>
                        </h4>
                        <div id="faqfour" class="card-collapse collapse" data-bs-parent="#accordionExample">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec
                                ullamcorper mattis, pulvinar dapibus leo.Lorem ipsum dolor sit amet,</p>
                        </div>
                    </div>
                    <div class="faq-card aos aos-init aos-animate" data-aos="fade-up">
                        <h4 class="faq-title">
                            <a class="collapsed" data-bs-toggle="collapse" href="#faqfive" aria-expanded="false">5. What are
                                the costs to buy a house?</a>
                        </h4>
                        <div id="faqfive" class="card-collapse collapse" data-bs-parent="#accordionExample">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec
                                ullamcorper mattis, pulvinar dapibus leo.Lorem ipsum dolor sit amet,</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="faq-card aos aos-init aos-animate" data-aos="fade-up">
                        <h4 class="faq-title">
                            <a class="collapsed" data-bs-toggle="collapse" href="#faqsix" aria-expanded="false"> 6. What are
                                the costs to buy a house?</a>
                        </h4>
                        <div id="faqsix" class="card-collapse collapse" data-bs-parent="#accordionExample">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec
                                ullamcorper mattis, pulvinar dapibus leo.Lorem ipsum dolor sit amet,</p>
                        </div>
                    </div>
                    <div class="faq-card aos aos-init aos-animate" data-aos="fade-up">
                        <h4 class="faq-title">
                            <a class="collapsed" data-bs-toggle="collapse" href="#faqseven" aria-expanded="false">7. What
                                are the costs to buy a house?</a>
                        </h4>
                        <div id="faqseven" class="card-collapse collapse" data-bs-parent="#accordionExample">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec
                                ullamcorper mattis, pulvinar dapibus leo.Lorem ipsum dolor sit amet,</p>
                        </div>
                    </div>
                    <div class="faq-card aos aos-init aos-animate" data-aos="fade-up">
                        <h4 class="faq-title">
                            <a class="collapsed" data-bs-toggle="collapse" href="#faqeight" aria-expanded="false"> 8. What
                                are the costs to buy a house?</a>
                        </h4>
                        <div id="faqeight" class="card-collapse collapse" data-bs-parent="#accordionExample">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec
                                ullamcorper mattis, pulvinar dapibus leo.Lorem ipsum dolor sit amet,</p>
                        </div>
                    </div>
                    <div class="faq-card aos aos-init aos-animate" data-aos="fade-up">
                        <h4 class="faq-title">
                            <a class="collapsed" data-bs-toggle="collapse" href="#faqnine" aria-expanded="false">9. What are
                                the costs to buy a house?</a>
                        </h4>
                        <div id="faqnine" class="card-collapse collapse" data-bs-parent="#accordionExample">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec
                                ullamcorper mattis, pulvinar dapibus leo.Lorem ipsum dolor sit amet,</p>
                        </div>
                    </div>
                    <div class="faq-card aos aos-init aos-animate" data-aos="fade-up">
                        <h4 class="faq-title">
                            <a class="collapsed" data-bs-toggle="collapse" href="#faqten" aria-expanded="false">10. What are
                                the costs to buy a house?</a>
                        </h4>
                        <div id="faqten" class="card-collapse collapse" data-bs-parent="#accordionExample">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec
                                ullamcorper mattis, pulvinar dapibus leo.Lorem ipsum dolor sit amet,</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- Blog -->
    <section class="section news">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-12 mx-auto">
                    <div class="section-header section-header-three aos" data-aos="fade-up">
                        <span class="badge title-badge">Our Blog</span>
                        <h2 class="header-title">Read Our Article</h2>
                    </div>
                </div>
            </div>
            <div class="trend-slider row aos" data-aos="fade-up">
                <div class="col-lg-6 col-md-12">
                    <!-- Blog Post -->
                    <div class="blog blog-trick aos" data-aos="fade-up">
                        <div class="blog-image">
                            <router-link to="/blog/blog-details"><img class="img-fluid" src="@/assets/img/blog/blog-21.jpg"
                                    alt="Post Image"></router-link>
                        </div>
                        <div class="blog-trick-content-main">
                            <div class="d-flex justify-content-between align-items-center mb-3">
                                <ul class="entry-meta meta-item d-flex align-items-center mb-0">
                                    <li>
                                        <div class="post-author blog-hover-img">
                                            <router-link to="/employers/developer-details"><img src="@/assets/img/img-02.jpg"
                                                    alt="Post Author"> <span> Rihana madonna</span></router-link>
                                        </div>
                                    </li>
                                    <li>
                                        <i class="feather-calendar"></i>
                                        15 Jan 2023
                                    </li>
                                </ul>
                                <div class="blog-text">
                                    <span><i class="feather-grid me-2"></i>Education</span>
                                </div>
                            </div>

                            <div class="blog-content blog-trick-content">
                                <h3 class="blog-title"><router-link to="/blog/blog-details">Lorem ipsum dolor sit amet,
                                        consectetur
                                        adipiscing elit. Amet.</router-link></h3>
                                <p>Lorem ipsum dolor sit amet consectetur. Vitae amet cras nulla mi laoreet quis amet
                                    phasellus....</p>

                            </div>
                            <a href="javascript:void(0);" class="more-option">Learn More<i
                                    class="feather-arrow-right ms-2"></i></a>
                        </div>
                    </div>
                    <!-- Blog Post -->
                </div>
                <div class="col-lg-6 col-md-12">
                    <!-- Blog Post -->
                    <div class="blog blog-trick aos" data-aos="fade-up">
                        <div class="row align-items-center">
                            <div class="col-sm-4">
                                <div class="blog-image blog-list-img">
                                    <router-link to="/blog/blog-details"><img class="img-fluid"
                                            src="@/assets/img/blog/blog-list-01.jpg" alt="Post Image"></router-link>
                                </div>
                            </div>
                            <div class="col-sm-8">
                                <div class="blog-trick-content-main blog-list-main">
                                    <div class="blog-content blog-trick-content">
                                        <h3 class="blog-title"><router-link to="/blog/blog-details">Lorem ipsum dolor sit amet,
                                            </router-link>
                                        </h3>
                                        <p>Lorem ipsum dolor sit amet consectetur. Vitae amet</p>
                                    </div>
                                    <div class="d-flex justify-content-between align-items-center mb-2 mt-2">
                                        <ul class="entry-meta meta-item d-flex align-items-center mb-0">
                                            <li>
                                                <div class="post-author blog-hover-img">
                                                    <router-link to="/employers/developer-details"><img src="@/assets/img/img-03.jpg"
                                                            alt="Post Author"> <span> Aidan Funnell</span></router-link>
                                                </div>
                                            </li>
                                            <li>
                                                <i class="feather-calendar"></i>
                                                15 Jan 2023
                                            </li>
                                        </ul>
                                    </div>
                                    <a href="javascript:void(0);" class="more-option mt-0">Learn More<i
                                            class="feather-arrow-right ms-2"></i></a>
                                </div>
                            </div>
                        </div>


                    </div>
                    <!-- Blog Post -->

                    <!-- Blog Post -->
                    <div class="blog blog-trick aos" data-aos="fade-up">
                        <div class="row align-items-center">
                            <div class="col-sm-4">
                                <div class="blog-image blog-list-img">
                                    <router-link to="/blog/blog-details"><img class="img-fluid"
                                            src="@/assets/img/blog/blog-list-02.jpg" alt="Post Image"></router-link>
                                </div>
                            </div>
                            <div class="col-sm-8">
                                <div class="blog-trick-content-main blog-list-main">
                                    <div class="blog-content blog-trick-content">
                                        <h3 class="blog-title"><router-link to="/blog/blog-details">Lorem ipsum dolor sit amet,
                                            </router-link>
                                        </h3>
                                        <p>Lorem ipsum dolor sit amet consectetur. Vitae amet</p>
                                    </div>
                                    <div class="d-flex justify-content-between align-items-center mb-2 mt-2">
                                        <ul class="entry-meta meta-item d-flex align-items-center mb-0">
                                            <li>
                                                <div class="post-author blog-hover-img">
                                                    <router-link to="/employers/developer-details"><img src="@/assets/img/img-04.jpg"
                                                            alt="Post Author"> <span> Cameron Morley</span></router-link>
                                                </div>
                                            </li>
                                            <li>
                                                <i class="feather-calendar"></i>
                                                15 Jan 2023
                                            </li>
                                        </ul>
                                    </div>
                                    <a href="javascript:void(0);" class="more-option mt-0">Learn More<i
                                            class="feather-arrow-right ms-2"></i></a>
                                </div>
                            </div>
                        </div>


                    </div>
                    <!-- Blog Post -->

                    <!-- Blog Post -->
                    <div class="blog blog-trick aos" data-aos="fade-up">
                        <div class="row align-items-center">
                            <div class="col-sm-4">
                                <div class="blog-image blog-list-img">
                                    <router-link to="/blog/blog-details"><img class="img-fluid"
                                            src="@/assets/img/blog/blog-list-03.jpg" alt="Post Image"></router-link>
                                </div>
                            </div>
                            <div class="col-sm-8">
                                <div class="blog-trick-content-main blog-list-main">
                                    <div class="blog-content blog-trick-content">
                                        <h3 class="blog-title"><router-link to="/blog/blog-details">Lorem ipsum dolor sit amet,
                                            </router-link>
                                        </h3>
                                        <p>Lorem ipsum dolor sit amet consectetur. Vitae amet</p>
                                    </div>
                                    <div class="d-flex justify-content-between align-items-center mb-2 mt-2">
                                        <ul class="entry-meta meta-item d-flex align-items-center mb-0">
                                            <li>
                                                <div class="post-author blog-hover-img">
                                                    <router-link to="/employers/developer-details"><img src="@/assets/img/img-05.jpg"
                                                            alt="Post Author"> <span> Amelie Hannah</span></router-link>
                                                </div>
                                            </li>
                                            <li>
                                                <i class="feather-calendar"></i>
                                                15 Jan 2023
                                            </li>
                                        </ul>
                                    </div>
                                    <a href="javascript:void(0);" class="more-option mt-0">Learn More<i
                                            class="feather-arrow-right ms-2"></i></a>
                                </div>
                            </div>
                        </div>


                    </div>
                    <!-- Blog Post -->
                </div>
                <div class="col-md-12">
                    <div class="next-job-btn mt-3">
                        <router-link to="/blog/blog-details" class="btn btn-primary next-register me-3">View More Articles<i
                                class="feather-arrow-right ms-2"></i></router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- / Blog -->
</template>