<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>

  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content container-fluid">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row">
          <div class="col-sm-12">
            <h3 class="page-title">Form Validation</h3>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <div class="row">
        <div class="col-sm-12">
          <!-- Custom Boostrap Validation -->
          <div class="card">
            <div class="card-body">
              <div class="card-header pt-0 mb-4">
                <h5 class="card-title">Custom Bootstrap Form Validation</h5>
                <p class="card-text">
                  For custom Bootstrap form validation messages, you’ll need to add the
                  <code>novalidate</code> boolean attribute to your form. For server side
                  validation
                  <a
                    href="https://getbootstrap.com/docs/5.0/forms/validation/"
                    target="_blank"
                    >read full documentation</a
                  >.
                </p>
              </div>
              <div class="row">
                <div class="col-sm">
                  <form class="needs-validation" novalidate>
                    <div class="form-row row">
                      <div class="col-md-4 mb-3">
                        <label for="validationCustom01">First name</label>
                        <input
                          type="text"
                          class="form-control"
                          id="validationCustom01"
                          placeholder="First name"
                          value="Mark"
                          required
                        />
                        <div class="valid-feedback">Looks good!</div>
                      </div>
                      <div class="col-md-4 mb-3">
                        <label for="validationCustom02">Last name</label>
                        <input
                          type="text"
                          class="form-control"
                          id="validationCustom02"
                          placeholder="Last name"
                          value="Otto"
                          required
                        />
                        <div class="valid-feedback">Looks good!</div>
                      </div>
                      <div class="col-md-4 mb-3">
                        <label for="validationCustomUsername">Username</label>
                        <div class="input-group">
                          <span class="input-group-text" id="inputGroupPrepend">@</span>
                          <input
                            type="text"
                            class="form-control"
                            id="validationCustomUsername"
                            placeholder="Username"
                            aria-describedby="inputGroupPrepend"
                            required
                          />
                          <div class="invalid-feedback">Please choose a username.</div>
                        </div>
                      </div>
                    </div>
                    <div class="form-row row">
                      <div class="col-md-6 mb-3">
                        <label for="validationCustom03">City</label>
                        <input
                          type="text"
                          class="form-control"
                          id="validationCustom03"
                          placeholder="City"
                          required
                        />
                        <div class="invalid-feedback">Please provide a valid city.</div>
                      </div>
                      <div class="col-md-3 mb-3">
                        <label for="validationCustom04">State</label>
                        <input
                          type="text"
                          class="form-control"
                          id="validationCustom04"
                          placeholder="State"
                          required
                        />
                        <div class="invalid-feedback">Please provide a valid state.</div>
                      </div>
                      <div class="col-md-3 mb-3">
                        <label for="validationCustom05">Zip</label>
                        <input
                          type="text"
                          class="form-control"
                          id="validationCustom05"
                          placeholder="Zip"
                          required
                        />
                        <div class="invalid-feedback">Please provide a valid zip.</div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="invalidCheck"
                          required
                        />
                        <label class="form-check-label" for="invalidCheck">
                          Agree to terms and conditions
                        </label>
                        <div class="invalid-feedback">
                          You must agree before submitting.
                        </div>
                      </div>
                    </div>
                    <button variant="primary" type="submit">Submit form</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <!-- /Custom Boostrap Validation -->

          <!-- Default Browser Validation -->
          <div class="card">
            <div class="card-body">
              <div class="card-header pt-0 mb-4">
                <h5 class="card-title">Browser defaults</h5>
                <p class="card-text">
                  Not interested in custom validation feedback messages or writing
                  JavaScript to change form behaviors? All good, you can use the browser
                  defaults. Try submitting the form below.
                </p>
              </div>
              <div class="row">
                <div class="col-sm">
                  <form>
                    <div class="form-row row">
                      <div class="col-md-4 mb-3">
                        <label for="validationDefault01">First name</label>
                        <input
                          type="text"
                          class="form-control"
                          id="validationDefault01"
                          placeholder="First name"
                          value="Mark"
                          required
                        />
                      </div>
                      <div class="col-md-4 mb-3">
                        <label for="validationDefault02">Last name</label>
                        <input
                          type="text"
                          class="form-control"
                          id="validationDefault02"
                          placeholder="Last name"
                          value="Otto"
                          required
                        />
                      </div>
                      <div class="col-md-4 mb-3">
                        <label for="validationDefaultUsername">Username</label>
                        <div class="input-group">
                          <span class="input-group-text" id="inputGroupPrepend2">@</span>
                          <input
                            type="text"
                            class="form-control"
                            id="validationDefaultUsername"
                            placeholder="Username"
                            aria-describedby="inputGroupPrepend2"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div class="form-row row">
                      <div class="col-md-6 mb-3">
                        <label for="validationDefault03">City</label>
                        <input
                          type="text"
                          class="form-control"
                          id="validationDefault03"
                          placeholder="City"
                          required
                        />
                      </div>
                      <div class="col-md-3 mb-3">
                        <label for="validationDefault04">State</label>
                        <input
                          type="text"
                          class="form-control"
                          id="validationDefault04"
                          placeholder="State"
                          required
                        />
                      </div>
                      <div class="col-md-3 mb-3">
                        <label for="validationDefault05">Zip</label>
                        <input
                          type="text"
                          class="form-control"
                          id="validationDefault05"
                          placeholder="Zip"
                          required
                        />
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          value=""
                          id="invalidCheck2"
                          required
                        />
                        <label class="form-check-label" for="invalidCheck2">
                          Agree to terms and conditions
                        </label>
                      </div>
                    </div>
                    <button variant="primary" type="submit">Submit form</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <!-- /Default Browser Validation -->
          <servervalidation></servervalidation>
        </div>
      </div>
    </div>
  </div>
  <!-- /Page Wrapper -->
</template>
<script>
export default {
  mounted() {
    (function () {
      "use strict";
      window.addEventListener(
        "load",
        function () {
          // Fetch all the forms we want to apply custom Bootstrap validation styles to
          var forms = document.getElementsByClassName("needs-validation");
          // Loop over them and prevent submission
          var validation = Array.prototype.filter.call(forms, function (form) {
            form.addEventListener(
              "submit",
              function (event) {
                if (form.checkValidity() === false) {
                  event.preventDefault();
                  event.stopPropagation();
                }
                form.classList.add("was-validated");
              },
              false
            );
          });
        },
        false
      );
    })();
  },
};
</script>
