<template>
  <section class="section job-register">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="register-job-blk">
            <div class="job-content-blk aos" data-aos="fade-up">
              <h2>Find Your Next Great Job Opportunity!</h2>
              <p>
                Quisque pretium dolor turpis, quis blandit turpis semper ut. Nam malesuada
                eros nec luctus laoreet.
              </p>
              <router-link to="/register" class="btn all-btn">Join Now</router-link>
            </div>
            <div class="see-all mt-0 aos opportunity" data-aos="zoom-in">
              <img src="@/assets/img/job1.png" alt="img" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
