import {createRouter, createWebHistory} from 'vue-router';

/**************** Frontend  *************/
import Login from '@/views/frontend/pages/login.vue'
import Home from '@/views/frontend/pages/index/home.vue'
import Index from '@/views/frontend/pages/index/home/index.vue'
import Indextwo from '@/views/frontend/pages/index/home2/index-two.vue'
import Indexthree from '@/views/frontend/pages/index/home3/index-three.vue'
import IndexFour from '@/views/frontend/pages/index/home4/index-four.vue'
import IndexFive from '@/views/frontend/pages/index/home5/index-five.vue'
import Employers from '@/views/frontend/pages/employers/employers.vue'
import ManageProject from '@/views/frontend/pages/employers/project/manage-projects.vue'
import OngoingProjects from '@/views/frontend/pages/employers/project/ongoing-projects.vue'
import CompletedProjects from '@/views/frontend/pages/employers/project/completed-projects.vue'
import PendingProjects from '@/views/frontend/pages/employers/project/pending-projects.vue'
import CancelledProjects from '@/views/frontend/pages/employers/project/cancelled-projects.vue'
import ExpiredProjects from '@/views/frontend/pages/employers/project/expired-projects.vue'
import Favourites from '@/views/frontend/pages/employers/favourites/favourites.vue'
import CompanyProject from '@/views/frontend/pages/employers/company-project.vue'
import InvitedFavourites  from '@/views/frontend/pages/employers/favourites/invited-favourites.vue'
import Review  from '@/views/frontend/pages/employers/review.vue'
import DepositFunds  from '@/views/frontend/pages/employers/payments/deposit-funds.vue'
import ProfileSettings  from '@/views/frontend/pages/employers/settings/profile-settings.vue'
import MembershipPlans  from '@/views/frontend/pages/employers/membership/membership-plans.vue'
import Verifyidentity from '@/views/frontend/pages/employers/verifyidentity/verify-identity.vue'
import Project_Changepassword from '@/views/frontend/pages/employers/settings/project-change-password.vue'
import Deleteaccount from '@/views/frontend/pages/employers/settings/delete-account.vue'
import Developer from '@/views/frontend/pages/freelancer/developer.vue'
import Developerdetails from '@/views/frontend/pages/freelancer/developer-details.vue'
import Dashboard from '@/views/frontend/pages/employers/dashboard.vue'
import CompanyProfile from '@/views/frontend/pages/employers/company-profile.vue'
import ViewProjectDetail from '@/views/frontend/pages/employers/milestonelist/view-project-detail.vue'
import MileStones from '@/views/frontend/pages/employers/milestonelist/milestones.vue'
import Task from '@/views/frontend/pages/employers/milestonelist/tasks.vue'
import Files from '@/views/frontend/pages/employers/milestonelist/files.vue'
import ProjectPayment from '@/views/frontend/pages/employers/milestonelist/project-payment.vue'
import Completedprojectsviewdetails from '@/views/frontend/pages/employers/completedprojectlist/completed-projects-view-details.vue'
import Completedprojectsmilestones from '@/views/frontend/pages/employers/completedprojectlist/completed-projects-milestones.vue'
import Completedprojectstasks from '@/views/frontend/pages/employers/completedprojectlist/completed-projects-tasks.vue'
import Completedprojectsprojectpayment from '@/views/frontend/pages/employers/completedprojectlist/completed-projects-project-payment.vue'
import Completedprojectsfiles from '@/views/frontend/pages/employers/completedprojectlist/completed-projects-files.vue'
import Projectemployerviewproposal from '@/views/frontend/pages/employers/project/project-employer-view-proposal.vue'
import Pages from '@/views/frontend/pages/pages/pages.vue'
import About from '@/views/frontend/pages/pages/about-us/about-us.vue'
import Blank_Page from '@/views/frontend/pages/pages/blank-page.vue'
import Page_404 from '@/views/frontend/pages/pages/404-page.vue'
import register from '@/views/frontend/pages/pages/register.vue'
import Forgot_Password from '@/views/frontend/pages/pages/forgot-password.vue'
import Change_Passwords from '@/views/frontend/pages/pages/change-passwords.vue'
import Onboard_Screen from '@/views/frontend/pages/pages/onboard-screen/onboard-screen.vue'
import Onboard_Screen_Employer from '@/views/frontend/pages/pages/onboard-screen/onboard-screen-employer.vue'
import Blog from '@/views/frontend/pages/blogs/blog.vue'
import Blog_List from '@/views/frontend/pages/blogs/blog-list/blog-list.vue'
import Blog_Grid from '@/views/frontend/pages/blogs/blog-grid/blog-grid.vue'
import Blog_Details from '@/views/frontend/pages/blogs/blog-details/blog-details.vue'
import Chats from '@/views/frontend/pages/employers/chats/chats.vue'
import Freelancer_Verify_Identity from '@/views/frontend/pages/freelancer/freelancer-verify-identity.vue'
import Freelancer_Review from '@/views/frontend/pages/freelancer/freelancer-review.vue'
import Freelancer_Chats from '@/views/frontend/pages/employers/chats/chats.vue'
import Freelancer_Portfolio from '@/views/frontend/pages/freelancer/freelancer-portfolio.vue'
import Freelancer from '@/views/frontend/pages/freelancer/freelancer.vue'
import Project from '@/views/frontend/pages/freelancer/project-grid/project-grid.vue'
import Projectlist from '@/views/frontend/pages/freelancer/project-list/project-list.vue'
import Projectdetails from '@/views/frontend/pages/freelancer/project-details/project-details.vue'
import Freelancerdashboard from '@/views/frontend/pages/freelancer/dashboard/freelancer-dashboard.vue'
import Freelancer_Change_Password from '@/views/frontend/pages/freelancer/freelancer-change-password.vue'
import Freelancer_Membership from '@/views/frontend/pages/freelancer/membership/freelancer-membership.vue'
import Freelancer_Withdraw_Money from '@/views/frontend/pages/freelancer/freelancer-withdraw-money.vue'
import Freelancer_Payout from '@/views/frontend/pages/freelancer/freelancer-payout.vue'
import Freelancer_Favourites from '@/views/frontend/pages/freelancer/freelancer-favourites.vue'
import Freelancer_Invitations from '@/views/frontend/pages/freelancer/freelancer-invitations.vue'
import Freelancer_Project_Proposals from '@/views/frontend/pages/freelancer/projects/freelancer-project-proposals.vue'
import Freelancer_Ongoing_Proposals from '@/views/frontend/pages/freelancer/projects/freelancer-ongoing-proposals.vue'
import Freelancer_Completed_Projects from '@/views/frontend/pages/freelancer/projects/freelancer-completed-projects.vue'
import Freelancer_Cancelled_Projects from '@/views/frontend/pages/freelancer/projects/freelancer-cancelled-projects.vue'
import Freelancer_View_Project_Detail from '@/views/frontend/pages/freelancer/projects/freelancer-view-project-detail.vue'
import Freelancer_Milestones from '@/views/frontend/pages/freelancer/projects/freelancer-milestones.vue'
import Freelancer_Task from '@/views/frontend/pages/freelancer/projects/freelancer-task.vue'
import Freelancer_Files from '@/views/frontend/pages/freelancer/projects/freelancer-files.vue'
import Freelancer_Payment from '@/views/frontend/pages/freelancer/projects/freelancer-payment.vue'
import Freelancer_Delete_Account from '@/views/frontend/pages/freelancer/projects/freelancer-delete-account.vue'
import Freelancer_Profile_Settings from '@/views/frontend/pages/freelancer/freelancer-profile-settings/freelancer-profile-settings.vue'
import Freelancer_Developer_Profile from '@/views/frontend/pages/freelancer/developer-profile/developer-profile.vue'

import Notifications from '@/views/frontend/pages/notifications.vue'
import Faq from '@/views/frontend/pages/faq.vue'
import Privacy_Policy from '@/views/frontend/pages/privacy-policy.vue'
import Post_Project from '@/views/frontend/pages/post-project/post-project.vue'
import Term_Condition from '@/views/frontend/pages/term-condition.vue'




/**************** Admin  *************/
import Activities from '@/views/admin/pages/freelancer/activities.vue'
import AdminIndex from '@/views/admin/pages/home/adminindex.vue'
import BidFees from '@/views/admin/pages/bid-fees.vue'
import Categories from '@/views/admin/pages/categories/categories.vue'
import ChangePassword from '@/views/admin/pages/settings/change-password.vue'
import Components from '@/views/admin/pages/components/components.vue'
import ContestEntryFees from '@/views/admin/pages/contest-entry-fees.vue'
import ContestsFees from '@/views/admin/pages/contests-fees.vue'
import DataTables from '@/views/admin/pages/tables/data-tables.vue'
import DeleteAccount from '@/views/admin/pages/settings/delete-account.vue'
import DepositCancelled from '@/views/admin/pages/deposit/deposit-cancelled.vue'
import Deposit from '@/views/admin/pages/deposit/deposit.vue'
import DepositPending from '@/views/admin/pages/deposit/deposit-pending.vue'
import DepositHold from '@/views/admin/pages/deposit/deposit-hold.vue'
import DepositCompleted from '@/views/admin/pages/deposit/deposit-completed.vue'
import Projects from '@/views/admin/pages/projects/projects.vue'
import Users from '@/views/admin/pages/freelancer/users.vue'
import UsersActive from '@/views/admin/pages/freelancer/user-active.vue'
import UserInactive from '@/views/admin/pages/freelancer/user-inactive.vue'
import UserSuspended from '@/views/admin/pages/freelancer/user-suspended.vue'
import UserAdministrator from '@/views/admin/pages/freelancer/user-administrator.vue'
import Withdrawn from '@/views/admin/pages/withdrawn/withdrawn.vue'
import WithdrawnPending from '@/views/admin/pages/withdrawn/withdrawn-pending.vue'
import WithdrawnCancelled from '@/views/admin/pages/withdrawn/withdrawn-cancelled.vue'
import WithdrawnCompleted from '@/views/admin/pages/withdrawn/withdrawn-completed.vue'
import Transaction from '@/views/admin/pages/transaction/transaction.vue'
import TransactionDeposit from '@/views/admin/pages/transaction/transaction-deposit.vue'
import TransactionWithdraw from '@/views/admin/pages/transaction/transaction-withdraw.vue'
import TransactionPending from '@/views/admin/pages/transaction/transaction-pending.vue'
import TransactionOnhold from '@/views/admin/pages/transaction/transaction-onhold.vue'
import TransactionCompleted from '@/views/admin/pages/transaction/transaction-completed.vue'
import Providers from "@/views/admin/pages/providers.vue"
import Subscription from '@/views/admin/pages/subscription/subscription.vue'
import SubscripeFreelancer from '@/views/admin/pages/subscription/subscripe-freelancer.vue'
import ViewPriceEmployee from '@/views/admin/pages/subscription/view-price-employe.vue'
import ViewPriceFreelance from '@/views/admin/pages/subscription/view-price-freelance.vue'
import EmployeList from '@/views/admin/pages/subscription/employe-list.vue'
import FreelanceList from '@/views/admin/pages/subscription/freelance-list.vue'
import Reports from '@/views/admin/pages/reports/reports.vue'
import Roles from '@/views/admin/pages/roles/roles.vue'
import RolesPermission from '@/views/admin/pages/roles/roles-permission.vue'
import Skills from '@/views/admin/pages/skills/skills.vue'
import VerifyIdentity from '@/views/admin/pages/verify-identity/verify-identity.vue'
import Settings from '@/views/admin/pages/settings/settings.vue'
import LocalizationDetails from '@/views/admin/pages/settings/localization-details.vue'
import PaymentSettings from '@/views/admin/pages/settings/payment-settings.vue'
import EmailSettings from '@/views/admin/pages/settings/email-settings.vue'
import SocialSettings from '@/views/admin/pages/settings/social-settings.vue'
import SocialLinks from '@/views/admin/pages/settings/social-links.vue'
import SeoSettings from '@/views/admin/pages/settings/seo-settings.vue'
import OthersSettings from '@/views/admin/pages/settings/others-settings.vue'
import ProjectBidding from '@/views/admin/pages/reports/project-bidding.vue'
import ProjectInvoice from '@/views/admin/pages/reports/project-invoice.vue'
import ProjectEarnings from '@/views/admin/pages/reports/project-earnings.vue'
import EarningEmployer from '@/views/admin/pages/reports/earning-employer.vue'
import EarningFreelancer from '@/views/admin/pages/reports/earning-freelancer.vue'
import ProfileTransactions from '@/views/admin/pages/freelancer/profile-transactions.vue'
import UserProfile from '@/views/admin/pages/freelancer/user-profile.vue'
import TaxTypes from '@/views/admin/pages/settings/tax-types.vue'
import FormBasicInputs from '@/views/admin/pages/forms/form-basic-inputs.vue'
import  FormInputGroups from '@/views/admin/pages/forms/form-input-groups.vue'
import FormHorizontal from '@/views/admin/pages/forms/form-horizontal.vue'
import FormVertical from '@/views/admin/pages/forms/form-vertical.vue'
import FormMask from '@/views/admin/pages/forms/form-mask.vue'
import FormValidation from '@/views/admin/pages/forms/form-validation.vue'
import TablesBasic from '@/views/admin/pages/tables/tables-basic.vue'
import Profile from '@/views/admin/pages/projects/profile.vue'
import ForgotPassword from '@/views/admin/pages/forgot-password.vue'
import LoginAdmin from '@/views/admin/pages/login.vue'
import ProjectFees from '@/views/admin/pages/projects/projects-fees.vue'
import SubCategory from '@/views/admin/pages/categories/sub-category.vue'
import RegisterAdmin from '@/views/admin/pages/register.vue'
import ViewEstimate from '@/views/admin/pages/view-estimate.vue'
import ViewInvoice from '@/views/admin/pages/view-invoice.vue'

//*********** Frontend ********** //


const routes = [
{
    path: '/',
    name: 'login',
    component: Projectlist
},
{
    path: '/notification',
    name: 'notifications',
    component: Notifications
},
{
    path: '/faq',
    name: 'faq',
    component: Faq
},
{
    path: '/term-condition',
    name: 'term-condition',
    component: Term_Condition
},
{
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: Privacy_Policy
},
{
    path: '/post-project',
    name: 'post-project',
    component: Post_Project
},
{
    path: "/employers",
    component: Employers,
    children: [
      { path: "", redirect: "/employers/manage-projects" },
      { path: "manage-projects", component:ManageProject },
      { path: "ongoing-projects", component:OngoingProjects },
      { path: "completed-projects", component:CompletedProjects },
      { path: "pending-projects", component:PendingProjects },
      { path: "cancelled-projects", component:CancelledProjects },
      { path: "expired-projects", component:ExpiredProjects },
      { path: "chats", component:Chats },
      { path: "favourites", component:Favourites },
      { path: "company-project", component:CompanyProject },
      { path: "invited-favourites", component:InvitedFavourites },
      { path: "review", component:Review },
      { path: "deposit-funds", component:DepositFunds },
      { path: "profile-settings", component:ProfileSettings },
      { path: "membership-plans", component:MembershipPlans},
      { path: "verify-identity", component:Verifyidentity},
      { path: "change-password", component:Project_Changepassword},
      { path: "delete-account", component:Deleteaccount},
      { path: "developer", component:Developer},
      { path: "developer-details", component:Developerdetails},
      { path: "dashboard", component:Dashboard},
      { path: "company-profile", component:CompanyProfile},
      { path: "view-project-detail", component:ViewProjectDetail},
      { path: "milestones", component:MileStones},
      { path: "tasks", component:Task},
      { path: "files", component:Files},
      { path: "project-payment", component:ProjectPayment},
      { path: "completed-projects-view-details", component:Completedprojectsviewdetails},
      { path: "completed-projects-milestones", component:Completedprojectsmilestones},
      { path: "completed-projects-tasks", component:Completedprojectstasks},
      { path: "completed-projects-files", component:Completedprojectsfiles},
      { path: "completed-projects-project-payment", component: Completedprojectsprojectpayment},
      { path: "project-employer-view-proposal", component:Projectemployerviewproposal},
    ],
},
{
    path: "/blog",
    component: Blog,
    children: [
      { path: "", redirect: "/blog/blog-list" },
      { path: "blog-list", component:Blog_List },
      { path: "blog-grid", component:Blog_Grid },
      { path: "blog-details", component:Blog_Details },
    ],
},
{
    path: "/pages",
    component: Pages,
    children: [
      { path: "", redirect: "/pages/about" },
      { path: "about", component:About },
      { path: "blank-page", component:Blank_Page },
      { path: "404-page", component:Page_404 },
      { path: "onboard-screen", component:Onboard_Screen },
      { path: "onboard-screen-employer", component:Onboard_Screen_Employer },
    ],
},
{
    path: "/freelancer",
    component: Freelancer,
    children: [
      { path: "", redirect: "/freelancer/project" },
      { path: "freelancer-verify-identity", component:Freelancer_Verify_Identity },
      { path: "freelancer-review", component:Freelancer_Review },
      { path: "freelancer-chats", component:Freelancer_Chats },
      { path: "freelancer-portfolio", component:Freelancer_Portfolio },
      { path: "project", component:Project },
      { path: "project-list", component:Projectlist },
      { path: "project-details", component:Projectdetails },
      { path: "freelancer-dashboard", component:Freelancerdashboard },
      { path: "freelancer-change-password", component:Freelancer_Change_Password },
      { path: "freelancer-membership", component:Freelancer_Membership },
      { path: "freelancer-withdraw-money", component:Freelancer_Withdraw_Money },
      { path: "freelancer-payout", component:Freelancer_Payout },
      { path: "freelancer-favourites", component:Freelancer_Favourites },
      { path: "freelancer-invitations", component:Freelancer_Invitations },
      { path: "freelancer-project-proposals", component:Freelancer_Project_Proposals },
      { path: "freelancer-ongoing-projects", component:Freelancer_Ongoing_Proposals },
      { path: "freelancer-completed-projects", component:Freelancer_Completed_Projects },
      { path: "freelancer-cancelled-projects", component:Freelancer_Cancelled_Projects },
      { path: "freelancer-view-project-detail", component:Freelancer_View_Project_Detail},
      { path: "freelancer-milestones", component:Freelancer_Milestones},
      { path: "freelancer-task", component:Freelancer_Task},
      { path: "freelancer-files", component:Freelancer_Files},
      { path: "freelancer-payment", component:Freelancer_Payment},
      { path: "freelancer-delete-account", component:Freelancer_Delete_Account},
      { path: "freelancer-profile-settings", component:Freelancer_Profile_Settings},
      { path: "developer-profile", component:Freelancer_Developer_Profile},
    ],
},
{
    path: '/forgot-password',
    name: 'forgot-password',
    component: Forgot_Password
},
{
    path: '/change-passwords',
    name: 'change-passwords',
    component: Change_Passwords
},
{
    path: '/register',
    name: 'register',
    component: register
},
{
    path: "/home",
    component: Home,
    children: [
      { path: "", redirect: "/home/home" },
      { path: "home", component:Index },
      { path: "home2", component:Indextwo },
      { path: "home3", component:Indexthree },
      { path: "home4", component:IndexFour },
      { path: "home5", component:IndexFive },
    ],
},



// ********* ADMIN *********

{
    path: '/admin/activities',
    name: 'activities',
    component: Activities
},
{
    path: '/admin/index',
    name: 'adminindex',
    component: AdminIndex
},
{
    path: '/admin/bid-fees',
    name: 'bid-fees',
    component: BidFees
},
{
    path: '/admin/categories',
    name: 'categories',
    component: Categories
},
{
    path: '/admin/change-password',
    name: 'changepassword',
    component: ChangePassword
},
{
    path: '/admin/components',
    name: 'components',
    component: Components
},
{
    path: '/admin/contest-entry-fees',
    name: 'contest-entry-fees',
    component: ContestEntryFees
},
{
    path: '/admin/contests-fees',
    name: 'contests-fees',
    component: ContestsFees
},
{
    path: '/admin/data-tables',
    name: '/admin/data-tables',
    component: DataTables
},
{
    path: '/admin/delete-account',
    name: 'deleteaccount',
    component: DeleteAccount
},
{
    path: '/admin/deposit-cancelled',
    name: 'deposit-cancelled',
    component: DepositCancelled
},
{
    path: '/admin/deposit',
    name: 'deposit',
    component: Deposit
},
{
    path: '/admin/deposit-pending',
    name: 'deposit-pending',
    component: DepositPending
},
{
    path: '/admin/deposit-hold',
    name: 'deposit-hold',
    component: DepositHold
},
{
    path: '/admin/deposit-completed',
    name: 'deposit-completed',
    component: DepositCompleted
},
{
    path: '/admin/projects',
    name: 'projects',
    component: Projects
},
{
    path: '/admin/users',
    name: 'users',
    component: Users
},
{
    path: '/admin/user-active',
    name: 'user-active',
    component: UsersActive
},
{
    path: '/admin/user-inactive',
    name: 'user-inactive',
    component: UserInactive
},
{
    path: '/admin/user-suspended',
    name: 'user-suspended',
    component: UserSuspended
},
{
    path: '/admin/user-administrator',
    name: 'user-administrator',
    component: UserAdministrator
},
{
    path: '/admin/withdrawn',
    name: 'withdrawn',
    component: Withdrawn
},
{
    path: '/admin/withdrawn-pending',
    name: 'withdrawn-pending',
    component: WithdrawnPending
},
{
    path: '/admin/withdrawn-cancelled',
    name: 'withdrawn-cancelled',
    component: WithdrawnCancelled
},
{
    path: '/admin/withdrawn-completed',
    name: 'withdrawn-completed',
    component: WithdrawnCompleted
},
{
    path: '/admin/transaction',
    name: 'transaction',
    component: Transaction
},
{
    path: '/admin/transaction-deposit',
    name: 'transaction-deposit',
    component: TransactionDeposit
},
{
    path: '/admin/transaction-withdraw',
    name: 'transaction-withdraw',
    component: TransactionWithdraw
},
{
    path: '/admin/transaction-pending',
    name: 'transaction-pending',
    component: TransactionPending
},
{
    path: '/admin/transaction-onhold',
    name: 'transaction-onhold',
    component: TransactionOnhold
},
{
    path: '/admin/transaction-completed',
    name: 'transaction-completed',
    component: TransactionCompleted
},
{
    path: '/admin/providers',
    name: 'providers',
    component: Providers
},
{
    path: '/admin/subscription',
    name: 'subscription',
    component: Subscription
},
{
    path: '/admin/subscripe-freelancer',
    name: 'subscripe-freelancer',
    component: SubscripeFreelancer
},
{
    path: '/admin/view-price-employe',
    name: 'view-price-employe',
    component: ViewPriceEmployee
},
{
    path: '/admin/view-price-freelance',
    name: 'view-price-freelance',
    component: ViewPriceFreelance
},
{
    path: '/admin/employe-list',
    name: 'employe-list',
    component: EmployeList
},
{
    path: '/admin/freelance-list',
    name: 'freelance-list',
    component: FreelanceList
},
{
    path: '/admin/reports',
    name: 'reports',
    component: Reports
},
{
    path: '/admin/roles',
    name: 'roles',
    component: Roles
},
{
    path: '/admin/roles-permission',
    name: 'roles-permission',
    component: RolesPermission
},
{
    path: '/admin/skills',
    name: 'skills',
    component: Skills
},
{
    path: '/admin/verify-identity',
    name: 'verifyidentity',
    component: VerifyIdentity
},
{
    path: '/admin/settings',
    name: 'settings',
    component: Settings
},
{
    path: '/admin/localization-details',
    name: 'localization-details',
    component: LocalizationDetails
},
{
    path: '/admin/payment-settings',
    name: 'payment-settings',
    component: PaymentSettings
},
{
    path: '/admin/email-settings',
    name: 'email-settings',
    component: EmailSettings
},
{
    path: '/admin/social-settings',
    name: 'social-settings',
    component: SocialSettings
},
{
    path: '/admin/social-links',
    name: 'social-links',
    component: SocialLinks
},
{
    path: '/admin/seo-settings',
    name: 'seo-settings',
    component: SeoSettings
},
{
    path: '/admin/others-settings',
    name: 'others-settings',
    component: OthersSettings
},
{
    path: '/admin/project-bidding',
    name: 'project-bidding',
    component: ProjectBidding
},
{
    path: '/admin/project-invoice',
    name: 'project-invoice',
    component: ProjectInvoice
},
{
    path: '/admin/project-earnings',
    name: 'project-earnings',
    component: ProjectEarnings
},
{
    path: '/admin/earning-employer',
    name: 'earning-employer',
    component: EarningEmployer
},
{
    path: '/admin/earning-freelancer',
    name: 'earning-freelancer',
    component: EarningFreelancer
},
{
    path: '/admin/profile-transactions',
    name: 'profile-transactions',
    component: ProfileTransactions
},
{
    path: '/admin/user-profile',
    name: 'user-profile',
    component: UserProfile
},
{
    path: '/admin/tax-types',
    name: 'tax-types',
    component: TaxTypes
},
{
    path: '/admin/form-basic-inputs',
    name: '/admin/form-basic-inputs',
    component: FormBasicInputs
},
{
    path: '/admin/form-input-groups',
    name: '/admin/form-input-groups',
    component: FormInputGroups
},
{
    path: '/admin/form-horizontal',
    name: '/admin/form-horizontal',
    component: FormHorizontal
},
{
    path: '/admin/form-vertical',
    name: '/admin/form-vertical',
    component: FormVertical
},
{
    path: '/admin/form-mask',
    name: '/admin/form-mask',
    component: FormMask
},
{
    path: '/admin/form-validation',
    name: '/admin/form-validation',
    component: FormValidation
},
{
    path: '/admin/tables-basic',
    name: '/admin/tables-basic',
    component: TablesBasic
},
{
    path: '/admin/profile',
    name: 'profile',
    component: Profile
},
{
    path: '/admin/forgot-password',
    name: 'forgotpassword',
    component: ForgotPassword
},
{
    path: '/admin/login',
    name: 'adminlogin',
    component: LoginAdmin
},
{
    path: '/admin/projects-fees',
    name: 'project-fees',
    component: ProjectFees
},
{
    path: '/admin/sub-category',
    name: 'sub-category',
    component: SubCategory
},
{
    path: '/admin/register',
    name: 'adminregister',
    component: RegisterAdmin
},
{
    path: '/admin/view-estimate',
    name: 'view-estimate',
    component: ViewEstimate
},
{
    path: '/admin/view-invoice',
    name: 'viewinvoice',
    component: ViewInvoice
},
]

export const router = createRouter({
    history: createWebHistory('/'),
    linkActiveClass: 'active',
    routes,
});

router.beforeEach((to, from, next) => {
    // Scroll to the top of the page
    window.scrollTo({ top: 0, behavior: 'smooth' });

    // Continue with the navigation
    next();
});

