<template>
    <section class="section developer">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-sm-12 col-12 mx-auto">
                    <div class="section-header aos" data-aos="fade-up">
                        <h2 class="header-title">Most Hired Developers</h2>
                        <p>Work with talented people at the most affordable price</p>
                    </div>
                </div>
            </div>
            <div id="developers-slider" class="owl-carousel owl-theme developers-slider aos" data-aos="fade-up">
                <Carousel :wrap-around="true" :settings="settings" :breakpoints="breakpoints">
                    <Slide v-for="item in Instructor" :key="item.id">
                        <div class="freelance-widget">
                            <div class="freelance-content">
                                <a data-toggle="modal" href="#rating" class="favourite"><i class="feather-heart"></i></a>
                                <div class="freelance-img">
                                    <router-link to="/employers/developer-details">
                                        <img :src="require(`@/assets/img/user/${item.Image}`)" alt="User Image">
                                        <span class="verified"><i class="fas fa-check-circle"></i></span>
                                    </router-link>
                                </div>
                                <div class="freelance-info">
                                    <h3><router-link to="/employers/developer-details">{{ item.Name }}</router-link></h3>
                                    <div class="freelance-specific">{{ item.Role }}</div>
                                    <div class="freelance-location"><img src="@/assets/img/icon/locations.svg" class="me-2"
                                            alt="img">{{ item.Location }}</div>
                                    <div class="rating">
                                        <i class="fas fa-star filled"></i>
                                        <i class="fas fa-star filled"></i>
                                        <i class="fas fa-star filled"></i>
                                        <i class="fas fa-star filled"></i>
                                        <i class="fas fa-star"></i>
                                        <span class="average-rating">{{ item.Rating }}</span>
                                    </div>
                                    <div class="freelance-tags">
                                        <a href="javascript:void(0);"><span class="badge badge-pill badge-design me-1">Web
                                                Design</span></a>
                                        <a href="javascript:void(0);"><span class="badge badge-pill badge-design me-1">UI
                                                Design</span></a>
                                        <a href="javascript:void(0);"><span class="badge badge-pill badge-design me-1">Node
                                                Js</span></a>
                                    </div>
                                    <div class="freelance-blk-location">
                                        <div class="freelancers-price">$25 Hourly</div>
                                    </div>
                                </div>
                            </div>
                            <div class="cart-hover">
                                <router-link to="/employers/developer-details" class="btn-cart" tabindex="-1">View
                                    Profile</router-link>
                            </div>
                        </div>
                    </Slide>
                        <template #addons>
                            <Navigation />
                        </template>
                </Carousel>
            </div>
        </div>
    </section>
</template>

<script>
import { Carousel, Navigation, Slide } from "vue3-carousel";
import Instructor from "@/assets/json/index-instructor.json";
import "vue3-carousel/dist/carousel.css";
export default {
    data() {
        return {
            Instructor: Instructor,
            settings: {
                itemsToShow: 1,
                snapAlign: "center",
            },
            breakpoints: {
                575: {
                    itemsToShow: 2,
                    snapAlign: "center",
                },
                767: {
                    itemsToShow: 3,
                    snapAlign: "center",
                },
                991: {
                    itemsToShow: 3,
                    snapAlign: "center",
                },
                1024: {
                    itemsToShow: 4,
                    snapAlign: "start",
                },
            },
        }
    },
    components: {
        Carousel,
        Slide,
        Navigation,
    },
}
</script>