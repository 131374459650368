<template>
  <!-- Header -->
  <header class="header" :class="{ sticky: isSticky }">
    <div class="container-fluid">
      <nav class="navbar navbar-expand-lg header-nav p-0">
        <div class="navbar-header">
          <a id="mobile_btn" href="javascript:void(0);">
            <span class="bar-icon">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </a>
          <router-link to="/home/" class="navbar-brand logo">
            <img src="@/assets/img/logo.svg" class="img-fluid" alt="Logo" />
          </router-link>
        </div>
        <div class="main-menu-wrapper">
          <div class="menu-header">
            <router-link to="/home/" class="menu-logo">
              <img src="@/assets/img/logo.svg" class="img-fluid" alt="Logo" />
            </router-link>
            <a id="menu_close" class="menu-close" href="javascript:void(0);">
              <i class="fas fa-times"></i>
            </a>
          </div>
          <header-menu></header-menu>
        </div>
        <ul class="nav header-navbar-rht reg-head">
          <li class="searchbar">
            <a href="javascript:void(0);" class="reg-btn"
              ><i class="feather-search"></i
            ></a>
            <div class="togglesearch">
              <form action="javascript:void(0);">
                <div class="input-group">
                  <input type="text" class="form-control" />
                  <button type="submit" class="btn">Search</button>
                </div>
              </form>
            </div>
          </li>
          <li>
            <router-link to="/register" class="reg-btn">Become a Seller</router-link>
          </li>
          <li>
            <router-link to="/register" class="reg-btn"
              ><img
                src="@/assets/img/icon/users.svg"
                class="me-1"
                alt="img"
              />Register</router-link
            >
          </li>
          <li>
            <router-link to="/" class="log-btn"
              ><img src="@/assets/img/icon/lock.svg" class="me-1" alt="img" />
              Login</router-link
            >
          </li>
          <li>
            <router-link to="/post-project" class="login-btn"
              ><i class="feather-plus me-1"></i>Post a Project
            </router-link>
          </li>
        </ul>
      </nav>
    </div>
  </header>
  <!-- /Header -->
</template>
<script>
export default {
  data() {
    return {
      sticky: false,
      header_bg: false,
      isSticky: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const scroll = window.pageYOffset || document.documentElement.scrollTop;
      this.isSticky = scroll >= 100;
    },
  },
};
</script>
