<template>
  <div class="slider client-img slider-nav aos" data-aos="fade-up">
    <Carousel
      :wrap-around="true"
      :settings="settings"
      :breakpoints="breakpoints"
    >
      <Slide v-for="item in IndexFiveClient" :key="item.id">
        <div class="testimonial-thumb">
          <img :src="require(`@/assets/img/user/${item.Img}`)" alt="Img" />
        </div>
      </Slide>
      <template #addons>
      </template>
    </Carousel>
  </div>
</template>
<script>
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import IndexFiveClient from "@/assets/json/indexfiveclient.json";
export default {
  data() {
    return {
      IndexFiveClient: IndexFiveClient,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
        loop: true,
        margin: 24,
      },

      breakpoints: {
        575: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        // 700px and up
        767: {
          itemsToShow: 3,
          snapAlign: "center",
        },
        // 991px and up
        991: {
          itemsToShow: 3,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 3,
          snapAlign: "start",
        },
      },
    };
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
};
</script>
