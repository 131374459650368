<template>
  <div class="bg-one">
    <loader v-if="showLoader"></loader>
    <index-banner></index-banner>

    <index-developed></index-developed>

    <index-project></index-project>

    <index-instructor></index-instructor>

    <index-review></index-review>

    <index-news></index-news>

    <!-- Scroll Top -->
    <button class="scroll-top scroll-to-target" data-target="html">
      <span class="ti-angle-up"
        ><img src="@/assets/img/icon/top-icon.svg" class="img-fluid" alt="Image"
      /></span>
    </button>
    <!-- /Scroll Top -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      showLoader: true,
    };
  },
  mounted() {
    window.addEventListener("scroll", function () {
      var scrollPosition = window.pageYOffset || document.documentElement.scrollTop;

      var scrollToTargetElements = document.querySelectorAll(".scroll-to-target");
      scrollToTargetElements.forEach(function (element) {
        if (scrollPosition > 0) {
          element.classList.add("open");
        } else {
          element.classList.remove("open");
        }

        if (scrollPosition > 500) {
          element.classList.add("open");
        } else {
          element.classList.remove("open");
        }
      });
    });
    var scrollToTargetElements = document.getElementsByClassName("scroll-to-target");
    if (scrollToTargetElements.length) {
      for (var i = 0; i < scrollToTargetElements.length; i++) {
        scrollToTargetElements[i].addEventListener("click", function () {
          var target = this.getAttribute("data-target");
          var targetElement = document.querySelector(target);
          if (targetElement) {
            var targetOffsetTop = targetElement.offsetTop;

            // animate scroll
            var scrollOptions = {
              top: targetOffsetTop,
              behavior: "smooth",
            };
            window.scrollTo(scrollOptions);
          }
        });
      }
    }
    setTimeout(() => {
      // Show the loader
      this.showLoader = true;

      setTimeout(() => {
        // Hide the loader
        this.showLoader = false;
      }, 100);
    }, 500);
  },
  name: "index",
};
</script>
