<template>
  <div class="dashboard-page">
    <div class="content content-page">
      <div class="container">
        <div class="row">
          <div class="col-xl-3 col-lg-4 theiaStickySidebar">
            <freelancer-sidebar></freelancer-sidebar>
          </div>

          <div class="col-xl-9 col-lg-8">
            <div class="dashboard-sec payout-section">
              <div class="page-title portfolio-title">
                <h3 class="mb-0">Payout</h3>
                <a
                  class="btn btn-primary title-btn"
                  data-bs-toggle="modal"
                  href="#payout_modal"
                  ><i class="feather-settings"></i> Payout Setting</a
                >
              </div>
              <div class="widget-section">
                <div class="row row-gap">
                  <div class="col-md-6 col-xl-4 d-flex">
                    <div class="dash-widget flex-fill">
                      <div class="dash-info">
                        <div class="d-flex">
                          <div class="dashboard-icon">
                            <img src="@/assets/img/icon/wallet-icon.svg" alt="Img" />
                          </div>
                          <div class="dash-widget-info">
                            <span>Previous Payout</span>
                            <h5>$5,231.00</h5>
                          </div>
                        </div>
                        <div class="badge badge-paid"><span>Paid</span></div>
                      </div>
                      <div
                        class="dash-widget-more d-flex align-items-center justify-content-between"
                      >
                        <div class="dash-widget-date"><span>17 Aug 2023</span></div>
                        <router-link to="freelancer-completed-projects" class="d-flex"
                          >View</router-link
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-xl-4 d-flex">
                    <div class="dash-widget flex-fill">
                      <div class="dash-info">
                        <div class="d-flex">
                          <div class="dashboard-icon">
                            <img src="@/assets/img/icon/wallet-icon.svg" alt="Img" />
                          </div>
                          <div class="dash-widget-info">
                            <span>Balance</span>
                            <h5>$3,270.00</h5>
                          </div>
                        </div>
                        <div class="badge badge-pending"><span>Pending</span></div>
                      </div>
                      <div
                        class="dash-widget-more d-flex align-items-center justify-content-between"
                      >
                        <div class="dash-widget-date">
                          <a href="javascript:void(0);"> Payout Request</a>
                        </div>
                        <router-link to="freelancer-completed-projects" class="d-flex"
                          >View</router-link
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-xl-4 d-flex">
                    <div class="dash-widget flex-fill d-flex align-items-center">
                      <div class="dash-info mb-0">
                        <div class="d-flex">
                          <div class="dashboard-icon">
                            <img src="@/assets/img/icon/wallet-icon.svg" alt="Img" />
                          </div>
                          <div class="dash-widget-info">
                            <span>Total Projects</span>
                            <h5>107</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Table -->
              <div class="table-top-section">
                <div class="table-header">
                  <h5 class="mb-0">Payout History</h5>
                </div>
              </div>
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Payout Date</th>
                      <th>Payment Type</th>
                      <th>Amount</th>
                      <th>Status</th>
                      <th>Payout Processed</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="record in Freelancer_Payout" :key="record.id">
                      <td>{{ record.Date }}</td>
                      <td>{{ record.Type }}</td>
                      <td>{{ record.Amount }}</td>
                      <td>
                        <div :class="record.Class">
                          <span>{{ record.Status }}</span>
                        </div>
                      </td>
                      <td>{{ record.Payout }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /Table -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Freelancer_Payout from "@/assets/json/freelancer-payout.json";
export default {
  data() {
    return {
      Freelancer_Payout: Freelancer_Payout,
    };
  },
};
</script>
