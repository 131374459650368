<template>
  <div class="content content-page portfolio-page">
    <div class="container">
      <div class="row">
        <div class="col-xl-3 col-lg-4 theiaStickySidebar">
          <freelancer-sidebar></freelancer-sidebar>
        </div>
        <div class="col-xl-9 col-lg-8">
          <div class="dashboard-sec freelance-favourites">
            <div class="page-title portfolio-title">
              <h3 class="mb-0">Portfolio</h3>
              <a
                class="btn btn-primary title-btn"
                data-bs-toggle="modal"
                href="#portfolio"
                ><i class="feather-plus"></i> Add Portfolio</a
              >
            </div>
            <div class="pro-content">
              <div class="row row-gap">
                <div
                  class="col-sm-6 col-lg-4"
                  v-for="record in Freelancer_Portfolio"
                  :key="record.id"
                >
                  <div class="project-widget">
                    <div class="portfolio-img">
                      <img
                        class="img-fluid"
                        alt="User Image"
                        :src="require(`@/assets/img/${record.Image}`)"
                      />
                      <div class="portfolio-live">
                        <div class="portfolio-content">
                          <a
                            data-bs-toggle="modal"
                            href="#portfolio-edit"
                            class="port-icon"
                            ><i class="feather-edit-2"></i
                          ></a>
                          <a href="#delete_modal" data-bs-toggle="modal" class="port-icon"
                            ><i class="feather-trash-2"></i
                          ></a>
                        </div>
                      </div>
                    </div>
                    <div class="portfolio-detail">
                      <h3 class="pro-name">{{ record.Name }}</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <ul class="paginations list-pagination">
                  <li class="page-item">
                    <a href="javascript:void(0);"><i class="feather-chevron-left"></i></a>
                  </li>
                  <li class="page-item">
                    <a href="javascript:void(0);" class="active">1</a>
                  </li>
                  <li class="page-item"><a href="javascript:void(0);">2</a></li>
                  <li class="page-item"><a href="javascript:void(0);">3</a></li>
                  <li class="page-item"><a href="javascript:void(0);">...</a></li>
                  <li class="page-item"><a href="javascript:void(0);">10</a></li>
                  <li class="page-item">
                    <a href="javascript:void(0);"
                      ><i class="feather-chevron-right"></i
                    ></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- project list -->
        </div>
      </div>
    </div>
  </div>
  <freelancer-portfolio-modal></freelancer-portfolio-modal>
</template>
<script>
import Freelancer_Portfolio from "@/assets/json/freelancer-portfolio.json";
export default {
  data() {
    return {
      Freelancer_Portfolio: Freelancer_Portfolio,
    };
  },
};
</script>
