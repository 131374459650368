<template>
  <!-- Review -->
  <section class="section testimonial-section review review-four">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="section-header aos text-center" data-aos="fade-up">
            <h2 class="header-title">Client testimonials</h2>
            <p>
              Learning communicate to global world and build a bright future and
              career development, increase your skill with our histudy.
            </p>
          </div>
        </div>
      </div>
      <div
        id="testimonial-slider"
        class="owl-carousel owl-theme testimonial-slider aos"
        data-aos="fade-up"
      >
       <Carousel
          :wrap-around="true"
          :settings="settings"
          :breakpoints="breakpoints"
        >
          <Slide v-for="item in IndexFourTestimonial" :key="item.id">
        <!-- Review Widget -->
        <div class="review-blog">
          <div class="review-top d-flex align-items-center">
            <div class="review-img">
              <router-link to="/employers/review"
                ><img
                  class="img-fluid"
                  :src="require(`@/assets/img/review/${item.Img}`)"
                  alt="Post Image"
              /></router-link>
            </div>
            <div class="review-info">
              <h3><router-link to="/employers/review">{{ item.Name }}</router-link></h3>
              <h5>{{ item.Position }}</h5>
            </div>
          </div>
          <div class="review-content">
            <p>
              {{ item.Text }}
            </p>
            <div class="rating">
              <i class="fas fa-star filled"></i>
              <i class="fas fa-star filled"></i>
              <i class="fas fa-star filled"></i>
              <i class="fas fa-star filled"></i>
              <i class="fas fa-star"></i>
            </div>
          </div>
        </div>
        <!-- / Review Widget -->
</Slide>
          <template #addons>
            <Navigation />
          </template>
        </Carousel>
      </div>
    </div>
  </section>
  <!-- / Review -->
</template>
<script>
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import IndexFourTestimonial from "@/assets/json/indexfourtestimonial.json";
export default {
  data() {
    return {
      IndexFourTestimonial: IndexFourTestimonial,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
        loop: true,
        margin: 24,
      },

      breakpoints: {
        575: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 700px and up
        767: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 991px and up
        991: {
          itemsToShow: 2.8,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 3,
          snapAlign: "start",
        },
      },
    };
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
};
</script>