<template>
  <section class="project-by-category">
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-sm-12 col-12 mx-auto text-center">
          <div class="section-header aos aos-init aos-animate" data-aos="fade-up">
            <h2 class="header-title">Browse Projects By Category</h2>
            <p>Get work done in over 60 different categories</p>
          </div>
        </div>
      </div>
      <div class="category-slider-section">
        <div id="category-slider" class="owl-carousel owl-theme blog-trick-five blog-slider aos" data-aos="fade-up">
          <Carousel :wrap-around="true" :settings="settings" :breakpoints="breakpoints">
            <Slide v-for="item in IndexFiveCategory" :key="item.id">
              <div class="project-by-category-widget">
                <router-link to="/freelancer/project" class="category-widget-img">
                  <img :src="require(`@/assets/img/slider/${item.Img}`)" class="img-fluid" alt="slider-image" />
                </router-link>
                <div class="category-widget-content">
                  <h5>{{ item.Title }}</h5>
                  <p class="mb-0">{{ item.Text }}</p>
                </div>
              </div>
            </Slide>
            <template #addons>
              <Navigation />
            </template>
          </Carousel>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="home-five-section-btn">
            <router-link to="/freelancer/project" class="btn btn-primary">View all Categories</router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import IndexFiveCategory from "@/assets/json/indexfivecategory.json";
export default {
  data() {
    return {
      IndexFiveCategory: IndexFiveCategory,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      breakpoints: {
        575: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 700px and up
        767: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        // 991px and up
        991: {
          itemsToShow: 3.5,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 4.9,
          snapAlign: "start",
        },
      },
    };
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
};
</script>
