<template>
  <div class="field-item personal-info space-info">
    <form>
      <div class="row">
        <div class="col-md-12">
          <h4>Skill</h4>
        </div>
        <div class="col-md-6">
          <div class="input-block">
            <label class="form-label">Skills</label>
            <input type="text" class="form-control" />
          </div>
        </div>
        <div class="col-md-6">
          <div class="input-block">
            <label class="focus-label">Level</label>
            <vue-select :options="Level" placeholder="Choose Level" />
            <div class="new-addd">
              <a class="add-new" @click="addSkill" id="skill_add"><i class="feather-plus-circle me-1"></i> Add New</a>
            </div>
          </div>
        </div>
        <div id="skill_add_row">
          <div class="row" id="form-row" v-for="(skill, index) in skills" :key="index">
            <div class="col-md-12">
            </div>
            <div class="col-md-6">
              <div class="input-block">
                <label class="form-label">Skills</label>
                <input type="text" class="form-control" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="input-block">
                <label class="focus-label">Level</label>
                <vue-select :options="Level" placeholder="Choose Level" />
                <div class="new-addd">
                  <a id="remove_row" class="remove_row" @click="deleteSkill(index)"> Remove</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h4>Education</h4>
        </div>
        <div class="col-md-6 col-lg-3">
          <div class="input-block">
            <label class="focus-label">Degree Name</label>
            <vue-select :options="Bachelor" id="bachelor" placeholder="Select" />
          </div>
        </div>
        <div class="col-md-6 col-lg-3">
          <div class="input-block">
            <label class="focus-label">University Name</label>
            <input type="text" name="education['university_name'][]" class="form-control" />
          </div>
        </div>
        <div class="col-md-6 col-lg-3">
          <div class="input-block">
            <label class="focus-label">Start Date</label>
            <div class="cal-icon">
              <datepicker v-model="startdate" placeholder="Choose Date" class="form-control datetimepicker"
                :editable="true" :clearable="false" />
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-3">
          <div class="input-block">
            <label class="focus-label">End Date</label>
            <div class="cal-icon">
              <datepicker v-model="startdateOne" placeholder="Choose Date" class="form-control datetimepicker"
                :editable="true" :clearable="false" />
            </div>
            <div class="new-addd">
              <a class="add-new" id="edu_add" @click="addeducation"><i class="feather-plus-circle me-1"></i> Add New</a>
            </div>
          </div>
        </div>
        <div id="education_add_row">
          <div class="row" id="form-row" v-for="(education, index) in educations" :key="index">
            <div class="col-md-12">
              <h4>Education</h4>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="input-block">
                <label class="focus-label">Degree Name</label>
                <vue-select :options="Bachelor" placeholder="Select" />
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="input-block">
                <label class="focus-label">University Name</label>
                <input type="text" name="education['university_name'][]" class="form-control" />
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="input-block">
                <label class="focus-label">Start Date</label>
                <div class="cal-icon">
                  <datepicker v-model="startdate" placeholder="Choose Date" class="form-control datetimepicker"
                    :editable="true" :clearable="false" />
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="input-block">
                <label class="focus-label">End Date</label>
                <div class="cal-icon">
                  <datepicker v-model="startdateOne" placeholder="Choose Date" class="form-control datetimepicker"
                    :editable="true" :clearable="false" />
                </div>
                <div class="new-addd">
                  <a id="remove_row" class="remove_row" @click="deleteeducation(index)"> Remove</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h5>Certification</h5>
        </div>
        <div class="col-md-4 col-lg-4">
          <div class="input-block">
            <label class="focus-label">Certification or Award</label>
            <vue-select :options="Certification" id="certification" placeholder="Select" />
          </div>
        </div>
        <div class="col-md-4 col-lg-4">
          <div class="input-block">
            <label class="focus-label">Certified from</label>
            <input type="text" name="certification['certified_from'][]" class="form-control" />
          </div>
        </div>
        <div class="col-md-4 col-lg-4">
          <div class="input-block">
            <label class="focus-label">Year</label>
            <div class="cal-icon">
              <datepicker v-model="startdateTwo" placeholder="Choose Date" class="form-control datetimepicker"
                :editable="true" :clearable="false" />
            </div>
            <div class="new-addd">
              <a class="add-new" id="certify_add" @click="addcertify"><i class="feather-plus-circle me-1"></i> Add New</a>
            </div>
          </div>
        </div>
        <div id="certify_add_row">
          <div class="row" v-for="(certify, index) in certify" :key="index">
            <div class="col-md-12">
              <h5>Certification</h5>
            </div>
            <div class="col-md-4 col-lg-4">
              <div class="input-block">
                <label class="focus-label">Certification or Award</label>
                <vue-select :options="Certification" placeholder="Select" />
              </div>
            </div>
            <div class="col-md-4 col-lg-4">
              <div class="input-block">
                <label class="focus-label">Certified from</label>
                <input type="text" name="certification['certified_from'][]" class="form-control" />
              </div>
            </div>
            <div class="col-md-4 col-lg-4">
              <div class="input-block">
                <label class="focus-label">Year</label>
                <div class="cal-icon">
                  <datepicker v-model="startdateTwo" placeholder="Choose Date" class="form-control datetimepicker"
                    :editable="true" :clearable="false" />
                </div>
                <div class="new-addd">
                  <a id="remove_row" class="remove_row" @click="deletecertify(index)">
                    Remove</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h4>Experience</h4>
        </div>
        <div class="col-md-6 col-lg-3">
          <div class="input-block">
            <label class="focus-label">Company Name</label>
            <input type="text" name="experience['company_name'][]" class="form-control" />
          </div>
        </div>
        <div class="col-md-6 col-lg-3">
          <div class="input-block">
            <label class="focus-label">Position</label>
            <vue-select :options="Level" placeholder="Choose Level" />
          </div>
        </div>
        <div class="col-md-6 col-lg-3">
          <div class="input-block">
            <label class="focus-label">Start Date</label>
            <div class="cal-icon">
              <datepicker v-model="startdateThree" placeholder="Choose Date" class="form-control datetimepicker"
                :editable="true" :clearable="false" />
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-3">
          <div class="input-block">
            <label class="focus-label">End Date</label>
            <div class="cal-icon">
              <datepicker v-model="startdateFour" placeholder="Choose Date" class="form-control datetimepicker"
                :editable="true" :clearable="false" />
            </div>
            <div class="new-addd">
              <a class="add-new" id="experience_add" @click="addexperience"><i class="feather-plus-circle me-1"></i> Add
                New</a>
            </div>
          </div>
        </div>
        <div id="experience_add_row">
          <div class="row" v-for="(experience, index) in experiences" :key="index">
            <div class="col-md-12">
              <h4>Experience</h4>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="input-block">
                <label class="focus-label">Company Name</label>
                <input type="text" name="experience['company_name'][]" class="form-control" />
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="input-block">
                <label class="focus-label">Position</label>
                <vue-select :options="Level" placeholder="Choose Level" />
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="input-block">
                <label class="focus-label">Start Date</label>
                <div class="cal-icon">
                  <datepicker v-model="startdateThree" placeholder="Choose Date" class="form-control datetimepicker"
                    :editable="true" :clearable="false" />
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="input-block">
                <label class="focus-label">End Date</label>
                <div class="cal-icon">
                  <datepicker v-model="startdateFour" placeholder="Choose Date" class="form-control datetimepicker"
                    :editable="true" :clearable="false" />
                </div>
                <div class="new-addd">
                  <a id="remove_row" class="remove_row" @click="deleteexperience(index)">
                    Remove</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h4>Language</h4>
        </div>
        <div class="col-md-6">
          <div class="input-block">
            <label class="form-label">Language</label>
            <input type="text" class="form-control" />
          </div>
        </div>
        <div class="col-md-6">
          <div class="input-block">
            <label class="focus-label">Level</label>
            <vue-select :options="Level" placeholder="Choose Level" />
            <div class="new-addd">
              <a class="add-new" id="lang_add" @click="addlanguage"><i class="feather-plus-circle me-1"></i> Add New</a>
            </div>
          </div>
        </div>
        <div id="language_add_row">
          <div class="row" v-for="(language, index) in languages" :key="index">
            <div class="col-md-12">
              <h4>Language</h4>
            </div>
            <div class="col-md-6">
              <div class="input-block">
                <label class="form-label">Language</label>
                <input type="text" class="form-control" />
              </div>
            </div>
            <div class="col-md-6">
              <div class="input-block">
                <label class="focus-label">Level</label>
                <vue-select :options="Level" placeholder="Choose Level" />
                <div class="new-addd">
                  <a id="remove_row" class="remove_row" @click="deletelanguage(index)">
                    Remove</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { ref } from "vue";
const currentDate = ref(new Date());
const currentDateOne = ref(new Date());
const currentDateTwo = ref(new Date());
const currentDateThree = ref(new Date());
const currentDateFour = ref(new Date());
export default {
  data() {
    return {
      Level: ["Choose Level", "Basic", "Intermediate", "Proficient"],
      Bachelor: ["Select", "Bachelor's degree", "Master's Degree"],
      Certification: ["Select", "Certification", "Award"],
      startdate: currentDate,
      startdateOne: currentDateOne,
      startdateTwo: currentDateTwo,
      startdateThree: currentDateThree,
      startdateFour: currentDateFour,
      skills: [],
      educations: [],
      certify: [],
      experiences: [],
      languages: [],
    }
  },
  methods: {
    addSkill() {
      this.skills.push({
        skill: "",
        Level: "Choose Level",
      });
    },
    deleteSkill(index) {
      this.skills.splice(index, 1);
    },
    addeducation() {
      this.educations.push({});
    },
    deleteeducation(index) {
      this.educations.splice(index, 1);
    },
    addcertify() {
      this.certify.push({});
    },
    deletecertify(index) {
      this.certify.splice(index, 1);
    },
    addexperience() {
      this.experiences.push({});
    },
    deleteexperience(index) {
      this.experiences.splice(index, 1);
    },
    addlanguage() {
      this.languages.push({});
    },
    deletelanguage(index) {
      this.languages.splice(index, 1);
    },
  },
}
</script>