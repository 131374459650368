<template>
  <div class="showentries">
    <label
      >Show
      <select>
        <option value="10">10</option>
        <option value="25">25</option>
        <option value="50">50</option>
        <option value="100">100</option>
      </select>
      entries
    </label>
    <div class="entries">
      <span>Search: </span>
      <input
        type="search"
        class="form-control form-control-sm ms-2"
        placeholder=""
        aria-controls="DataTables_Table_0"
      />
    </div>
  </div>
</template>
