<template>
    <section class="section projects">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-12 mx-auto">
                    <div class="section-header text-center aos aos-init aos-animate" data-aos="fade-up">
                        <h2 class="header-title">Trusted by the world’s best</h2>
                        <p>Top companies</p>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12 text-center">
                    <div class="best-company aos aos-init aos-animate" data-aos="fade-up">
                        <ul class="mb-0">
                            <li>
                                <div class="company-bestimg">
                                    <img src="@/assets/img/company/theme-1.png" alt="img">
                                </div>
                            </li>
                            <li>
                                <div class="company-bestimg">
                                    <img src="@/assets/img/company/theme-2.png" alt="img">
                                </div>
                            </li>
                            <li>
                                <div class="company-bestimg">
                                    <img src="@/assets/img/company/theme-3.png" alt="img">
                                </div>
                            </li>
                            <li>
                                <div class="company-bestimg">
                                    <img src="@/assets/img/company/theme-4.png" alt="img">
                                </div>
                            </li>
                            <li>
                                <div class="company-bestimg">
                                    <img src="@/assets/img/company/theme-5.png" alt="img">
                                </div>
                            </li>
                            <li>
                                <div class="company-bestimg">
                                    <img src="@/assets/img/company/theme-6.png" alt="img">
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <!-- News -->
    <section class="section news">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="section-header text-center aos" data-aos="fade-up">
                        <h2 class="header-title">Our Blog</h2>
                        <p>Freelancing refers to working as an independent contractor</p>
                    </div>
                </div>
            </div>
            <div class="row blog-grid-row">
                <div class="col-xl-4 col-md-6 d-flex">

                    <!-- Blog Post -->
                    <div class="blog grid-blog aos" data-aos="fade-up">
                        <div class="blog-image">
                            <router-link to="/blog/blog-details"><img class="img-fluid" src="@/assets/img/blog/blog-01.jpg"
                                    alt="Post Image"></router-link>
                        </div>
                        <div class="blog-content">
                            <ul class="entry-meta meta-item">
                                <li>
                                    <div class="post-author">
                                        <router-link to="/employers/developer-details"><img src="@/assets/img/img-02.jpg"
                                                alt="Post Author">
                                            <span>Aidan Funnell</span></router-link>
                                    </div>
                                </li>
                                <li><i class="feather-calendar me-1"></i> 4 Oct 2023</li>
                            </ul>
                            <h3 class="blog-title"><router-link to="/blog/blog-details">Choose a Blogging Platform</router-link>
                            </h3>
                            <p class="mb-0">Select a blogging platform that suits your needs. WordPress, Blogger, and Medium
                                are popular options.</p>
                            <div class="blog-read">
                                <router-link to="/blog/blog-details">Read More <i
                                        class="fas fa-arrow-right ms-1"></i></router-link>
                            </div>
                        </div>
                    </div>
                    <!-- /Blog Post -->

                </div>
                <div class="col-xl-4 col-md-6 d-flex">

                    <!-- Blog Post -->
                    <div class="blog grid-blog aos" data-aos="fade-up">
                        <div class="blog-image">
                            <router-link to="/blog/blog-details"><img class="img-fluid" src="@/assets/img/blog/blog-02.jpg"
                                    alt="Post Image"></router-link>
                        </div>
                        <div class="blog-content">
                            <ul class="entry-meta meta-item">
                                <li>
                                    <div class="post-author">
                                        <router-link to="/employers/developer-details"><img src="@/assets/img/img-03.jpg"
                                                alt="Post Author">
                                            <span> Deborah Angel</span></router-link>
                                    </div>
                                </li>
                                <li><i class="feather-calendar me-1"></i> 10 Oct 2023</li>
                            </ul>
                            <h3 class="blog-title"><router-link to="/blog/blog-details">Pick a Domain Name</router-link></h3>
                            <p class="mb-0">Choose a memorable and relevant domain name for your blog. Ideally, it should
                                reflect your niche and personal brand.</p>
                            <div class="blog-read">
                                <router-link to="/blog/blog-details">Read More <i
                                        class="fas fa-arrow-right ms-1"></i></router-link>
                            </div>
                        </div>
                    </div>
                    <!-- /Blog Post -->

                </div>
                <div class="col-xl-4 col-md-6 d-flex">

                    <!-- Blog Post -->
                    <div class="blog grid-blog aos" data-aos="fade-up">
                        <div class="blog-image">
                            <router-link to="/blog/blog-details"><img class="img-fluid" src="@/assets/img/blog/blog-03.jpg"
                                    alt="Post Image"></router-link>
                        </div>
                        <div class="blog-content">
                            <ul class="entry-meta meta-item">
                                <li>
                                    <div class="post-author">
                                        <router-link to="/employers/developer-details"><img src="@/assets/img/img-04.jpg"
                                                alt="Post Author">
                                            <span>Darren Elder</span></router-link>
                                    </div>
                                </li>
                                <li><i class="feather-calendar me-1"></i> 3 Nov 2023</li>
                            </ul>
                            <h3 class="blog-title"><router-link to="/blog/blog-details">Analyze and Improve</router-link></h3>
                            <p class="mb-0">Use analytics tools (e.g., Google Analytics) to track your blog's performance.
                                Analyze which content performs well and adjust your strategy accordingly.</p>
                            <div class="blog-read">
                                <router-link to="/blog/blog-details">Read More <i
                                        class="fas fa-arrow-right ms-1"></i></router-link>
                            </div>
                        </div>
                    </div>
                    <!-- /Blog Post -->

                </div>
            </div>
        </div>
    </section>
    <!-- / News -->

    <!-- News -->
    <section class="section job-register">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="register-job-blk">
                        <div class="job-content-blk aos" data-aos="fade-up">
                            <h2>Find Your Next Great Job Opportunity!</h2>
                            <p>Quisque pretium dolor turpis, quis blandit turpis semper ut. Nam malesuada eros nec luctus
                                laoreet.</p>
                            <router-link to="/register" class="btn all-btn">Join Now</router-link>
                        </div>
                        <div class="see-all mt-0 aos opportunity" data-aos="zoom-in">
                            <img src="@/assets/img/job1.png" alt="img">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>