<template>
  <!-- The Modal -->
  <div class="modal fade" id="portfolio">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4>Add Portfolio</h4>
          <span class="modal-close"
            ><a href="javascript:void(0);" data-bs-dismiss="modal" aria-label="Close"
              ><i class="fa fa-times orange-text"></i></a
          ></span>
        </div>
        <div class="modal-body">
          <form action="freelancer-portfolio">
            <div class="modal-info">
              <div class="row">
                <div class="col-md-12">
                  <div class="input-block">
                    <label class="form-label">Title</label>
                    <input type="text" class="form-control" />
                  </div>
                  <div class="input-block">
                    <label class="form-label">Link</label>
                    <input type="text" class="form-control" />
                  </div>
                  <div class="input-block mb-0 d-flex last-group">
                    <div class="w-100">
                      <label class="form-label">Image</label>
                      <input id="test" type="text" value="" class="form-control" />
                    </div>
                    <label class="file-upload image-upbtn">
                      Upload <input type="file" id="FileUpload1" onchange="addLink()" />
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="submit-section text-end">
              <a data-bs-dismiss="modal" class="btn btn-cancel submit-btn">Cancel</a>
              <button
                type="submit"
                data-bs-dismiss="modal"
                class="btn btn-primary submit-btn"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- The Modal -->
  <div class="modal fade" id="portfolio-edit">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4>Edit Portfolio</h4>
          <span class="modal-close"
            ><a href="javascript:void(0);" data-bs-dismiss="modal" aria-label="Close"
              ><i class="fa fa-times orange-text"></i></a
          ></span>
        </div>
        <div class="modal-body">
          <form action="freelancer-portfolio">
            <div class="modal-info">
              <div class="row">
                <div class="col-md-12">
                  <div class="input-block">
                    <label class="form-label">Title</label>
                    <input type="text" value="Portfolio Name" class="form-control" />
                  </div>
                  <div class="input-block">
                    <label class="form-label">Link</label>
                    <input
                      type="text"
                      value="https://themeforest.net/item/tasklay-a-task-marketplace-for-freelancers/34742243"
                      class="form-control"
                    />
                  </div>
                  <div class="input-block mb-0 d-flex last-group">
                    <div class="w-100">
                      <label class="form-label">Image</label>
                      <input
                        id="test2"
                        type="text"
                        value="file:///C:/Users/DreamGuys/Downloads/bird-colorful-logo-gradient-vector/7.jpg"
                        class="form-control"
                      />
                    </div>
                    <label class="file-upload image-upbtn">
                      Upload <input type="file" id="FileUpload" onchange="addLink()" />
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="submit-section text-end">
              <a data-bs-dismiss="modal" class="btn btn-cancel submit-btn">Cancel</a>
              <button
                type="submit"
                data-bs-dismiss="modal"
                class="btn btn-primary submit-btn"
              >
                Save Changes
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- Delete Modal -->
  <div class="modal fade" id="delete_modal">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header small-modal">
          <h4>Confirm to Delete Portfolio</h4>
          <span class="modal-close"
            ><a href="javascript:void(0);" data-bs-dismiss="modal" aria-label="Close"
              ><i class="fa fa-times orange-text"></i></a
          ></span>
        </div>
        <div class="modal-body">
          <div class="modal-info">
            <p class="mb-0">Are you sure you want to delete this portfolio?</p>
          </div>
          <form action="freelancer-portfolio">
            <div class="submit-section text-end">
              <a data-bs-dismiss="modal" class="btn btn-cancel submit-btn">Cancel</a>
              <button
                type="submit"
                data-bs-dismiss="modal"
                class="btn btn-primary submit-btn"
              >
                Delete
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
