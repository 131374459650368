<template>
  <div class="col-md-12">
    <h4 class="mb-3">Working Hours</h4>
  </div>
  <div class="col-md-6">
    <div class="input-block d-flex">
      <div class="form-check form-switch d-inline-block work-check position-relative">
        <input
          type="checkbox"
          class="form-check-input"
          id="check_hour"
          @click="toggleElements"
          checked
        />
      </div>
      <label class="form-label ms-2">Same Every Day</label>
    </div>
  </div>
  <div class="col-md-12">
    <div class="checkout-hour">
      <div class="other-info-list">
        <ul>
          <li class="active-hour">Mon</li>
          <li class="active-hour">Tue</li>
          <li class="active-hour">Wed</li>
          <li class="active-hour">Thr</li>
          <li class="active-hour">Fri</li>
          <li>Sat</li>
          <li>Sun</li>
        </ul>
      </div>
    </div>
    <div class="check-hour">
      <div class="col-md-12">
        <h4>Select Days</h4>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="row align-items-center">
            <div class="col-md-1">
              <div class="other-info-list">
                <ul>
                  <li class="active-hour m-0">Mon</li>
                </ul>
              </div>
            </div>
            <div class="col-md-5">
              <div class="input-block">
                <label class="form-label">Start Time </label>
                <input type="text" class="form-control" Value="8:00" />
              </div>
            </div>
            <div class="col-md-5">
              <div class="input-block">
                <label class="form-label">End Time </label>
                <input type="text" class="form-control" Value="20:00" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="row align-items-center">
            <div class="col-md-1">
              <div class="other-info-list">
                <ul>
                  <li class="active-hour m-0">Tue</li>
                </ul>
              </div>
            </div>
            <div class="col-md-5">
              <div class="input-block">
                <label class="form-label">Start Time </label>
                <input type="text" class="form-control" Value="8:00" />
              </div>
            </div>
            <div class="col-md-5">
              <div class="input-block">
                <label class="form-label">End Time </label>
                <input type="text" class="form-control" Value="20:00" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="row align-items-center">
            <div class="col-md-1">
              <div class="other-info-list">
                <ul>
                  <li class="active-hour m-0">Wed</li>
                </ul>
              </div>
            </div>
            <div class="col-md-5">
              <div class="input-block">
                <label class="form-label">Start Time </label>
                <input type="text" class="form-control" Value="8:00" />
              </div>
            </div>
            <div class="col-md-5">
              <div class="input-block">
                <label class="form-label">End Time </label>
                <input type="text" class="form-control" Value="20:00" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="row align-items-center">
            <div class="col-md-1">
              <div class="other-info-list">
                <ul>
                  <li class="active-hour m-0">Thur</li>
                </ul>
              </div>
            </div>
            <div class="col-md-5">
              <div class="input-block">
                <label class="form-label">Start Time </label>
                <input type="text" class="form-control" Value="8:00" />
              </div>
            </div>
            <div class="col-md-5">
              <div class="input-block">
                <label class="form-label">End Time </label>
                <input type="text" class="form-control" Value="20:00" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="row align-items-center">
            <div class="col-md-1">
              <div class="other-info-list">
                <ul>
                  <li class="active-hour m-0">Fri</li>
                </ul>
              </div>
            </div>
            <div class="col-md-5">
              <div class="input-block">
                <label class="form-label">Start Time </label>
                <input type="text" class="form-control" Value="8:00" />
              </div>
            </div>
            <div class="col-md-5">
              <div class="input-block">
                <label class="form-label">End Time </label>
                <input type="text" class="form-control" Value="20:00" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="row align-items-center">
            <div class="col-md-1">
              <div class="other-info-list">
                <ul>
                  <li class="m-0">Fri</li>
                </ul>
              </div>
            </div>
            <div class="col-md-5">
              <div class="input-block">
                <label class="form-label">Start Time </label>
                <input type="text" class="form-control" Value="8:00" />
              </div>
            </div>
            <div class="col-md-5">
              <div class="input-block">
                <label class="form-label">End Time </label>
                <input type="text" class="form-control" Value="20:00" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="row align-items-center">
            <div class="col-md-1">
              <div class="other-info-list">
                <ul>
                  <li class="m-0">Sun</li>
                </ul>
              </div>
            </div>
            <div class="col-md-5">
              <div class="input-block">
                <label class="form-label">Start Time </label>
                <input type="text" class="form-control" Value="8:00" />
              </div>
            </div>
            <div class="col-md-5">
              <div class="input-block">
                <label class="form-label">End Time </label>
                <input type="text" class="form-control" Value="20:00" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {
    // Your existing JavaScript code goes here
    document.getElementById("check_hour").addEventListener("click", this.toggleElements);
  },
  methods: {
    toggleElements() {
      var checkoutHourElements = document.getElementsByClassName("checkout-hour");
      var checkHourElements = document.getElementsByClassName("check-hour");

      if (document.getElementById("check_hour").checked) {
        this.showElements(checkoutHourElements);
        this.hideElements(checkHourElements);
      } else {
        this.hideElements(checkoutHourElements);
        this.showElements(checkHourElements);
      }
    },
    showElements(elements) {
      for (var i = 0; i < elements.length; i++) {
        elements[i].style.display = "block";
      }
    },
    hideElements(elements) {
      for (var i = 0; i < elements.length; i++) {
        elements[i].style.display = "none";
      }
    },
  },
};
</script>
