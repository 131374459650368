<template>
  <div class="table-responsive">
    <a-table :columns="columns" >
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'Profile'">
          <h2 class="table-avatar">
            <router-link to="/admin/profile" class="review-text">
              <img
                class="review-img avatar-img rounded-circle me-2"
                :src="require(`@/assets/admin_img/profiles/${record.Profile}`)"
                alt="User Image"
              />
              {{ record.Name }}
            </router-link>
          </h2>
        </template>
      </template>
    </a-table>
  </div>
</template>

<script>
const columns = [
  {
    title: "Profile",
    dataIndex: "Profile",
    key: "Profile",
    sorter: {
      compare: (a, b) => {
        a = a.Profile.toLowerCase();
        b = b.Profile.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Designation",
    dataIndex: "Designation",
    sorter: {
      compare: (a, b) => {
        a = a.Designation.toLowerCase();
        b = b.Designation.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "comments",
    dataIndex: "comments",
    sorter: {
      compare: (a, b) => {
        a = a.comments.toLowerCase();
        b = b.comments.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Stars",
    dataIndex: "Stars",
    key: "Stars",
    sorter: {
      compare: (a, b) => {
        a = a.Stars.toLowerCase();
        b = b.Stars.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Category",
    dataIndex: "Category",
    sorter: {
      compare: (a, b) => {
        a = a.Stars.toLowerCase();
        b = b.Stars.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Actions",
    key: "action",
    class: "text-end",
    sorter: true,
  },
];

export default {
  data() {
    return {
      columns,
    };
  },
};
</script>
