<template>
  <section class="section about">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-12 d-flex align-items-center aos" data-aos="fade-up">
          <div class="about-content">
            <h2>About We’re on a mission to empowering Jobs worldwide.</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Est modi, saepe
              hic esse maxime quasi, sapiente ex debitis quis dolorum unde, neque
              quibusdam eveniet nobis enim porro repudiandae nesciunt quidem.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Magni delectus
              soluta adipisci beatae ullam quisquam, quia recusandae rem assumenda,
              praesentium porro sequi eaque doloremque tenetur incidunt officiis explicabo
              optio perferendis.
            </p>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="about-content-img aos" data-aos="zoom-in">
            <img src="@/assets/img/blog/aboutus.jpg" class="img-fluid" alt="img" />
          </div>
        </div>
        <div class="col-lg-6">
          <div class="about-content-img aos" data-aos="zoom-in">
            <img src="@/assets/img/blog/aboutus1.jpg" class="img-fluid" alt="img" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
