<template>
  <!-- The Modal -->
  <div class="modal fade edit-proposal-modal" id="add-milestone">
    <div class="modal-dialog modal-dialog-centered modal-md">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Add New Task</h4>
          <span class="modal-close"
            ><a href="javascript:void(0);" data-bs-dismiss="modal" aria-label="Close"
              ><i class="feather-x"></i></a
          ></span>
        </div>
        <div class="modal-body">
          <form action="#">
            <div class="modal-info">
              <div class="row">
                <div class="col-md-6">
                  <div class="input-block">
                    <label class="form-label">Milestone</label>
                    <vue-select :options="TaskSelect" id="task" placeholder="Select" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-block">
                    <label class="form-label">Due date</label>
                    <div class="cal-icon">
                      <datepicker
                        v-model="startdate"
                        placeholder="Choose Date"
                        class="form-control datetimepicker"
                        :editable="true"
                        :clearable="false"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="input-block">
                    <label class="form-label">Task Name</label>
                    <input type="text" class="form-control" />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="input-block">
                    <label class="form-label">Description</label>
                    <editor v-model="content" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-block">
                    <label class="form-label">Status</label>
                    <vue-select
                      :options="Holdselect"
                      id="holdselect"
                      placeholder="On Hold"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="submit-section text-end">
              <a href="javascript:void(0);" class="btn btn-cancel">Cancel</a>
              <button type="submit" class="btn btn-primary submit-btn">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /The Modal -->
  <!-- The Modal -->
  <div class="modal fade edit-proposal-modal" id="edit-milestone">
    <div class="modal-dialog modal-dialog-centered modal-md">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Edit Task</h4>
          <span class="modal-close"
            ><a href="javascript:void(0);" data-bs-dismiss="modal" aria-label="Close"
              ><i class="feather-x"></i></a
          ></span>
        </div>
        <div class="modal-body">
          <form action="#">
            <div class="modal-info">
              <div class="row">
                <div class="col-md-6">
                  <div class="input-block">
                    <label class="form-label">Milestone</label>
                    <vue-select
                      :options="TaskSelectOne"
                      id="taskselectone"
                      placeholder="Research"
                    />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-block">
                    <label class="form-label">Due date</label>
                    <div class="cal-icon">
                      <datepicker
                        v-model="startdateOne"
                        placeholder="Choose Date"
                        class="form-control datetimepicker"
                        :editable="true"
                        :clearable="false"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="input-block">
                    <label class="form-label">Task Name</label>
                    <input
                      type="text"
                      class="form-control"
                      value="Full-stack Developer  "
                    />
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="input-block">
                    <label class="form-label">Description</label>
                    <editor v-model="content1" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-block">
                    <label class="form-label">Status</label>
                    <vue-select
                      :options="HoldselectOne"
                      id="holdselectone"
                      placeholder="On Hold"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="submit-section text-end">
              <a href="javascript:void(0);" class="btn btn-cancel">Cancel</a>
              <button type="submit" class="btn btn-primary submit-btn">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /The Modal -->
</template>
<script>
import { ref } from "vue";
const currentDate = ref(new Date());
const currentDateOne = ref(new Date());
import Editor from "@tinymce/tinymce-vue";
export default {
  components: {
    editor: Editor,
  },
  data() {
    return {
      startdate: currentDate,
      startdateOne: currentDateOne,
      content: "Description",
      content1:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
      TaskSelect: ["Select", "Research", "Design", "Development"],
      Holdselect: ["On Hold", "Completed", "Inprogress", "Cancel"],
      TaskSelectOne: ["Select", "Research", "Design", "Development"],
      HoldselectOne: ["On Hold", "Completed", "Inprogress", "Cancel"],
    };
  },
};
</script>
