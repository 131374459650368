<template>
    <!-- Home Banner  -->
    <div class="home-banner home-three">
        <div class="dot-slider slider">
            <a-carousel :dot-position="dotPosition">
                <div class="profile-widget-slide">
                    <div class="slide-pro-img">
                        <a href="javascript:void(0);">
                            <img class="img-fluid" src="@/assets/img/slider/slider-01.jpg" alt="Image" />
                        </a>
                    </div>
                </div>

                <div class="profile-widget-slide">
                    <div class="slide-pro-img">
                        <a href="javascript:void(0);">
                            <img class="img-fluid" src="@/assets/img/slider/slider-02.jpg" alt="Image" />
                        </a>
                    </div>
                </div>

                <div class="profile-widget-slide">
                    <div class="slide-pro-img">
                        <a href="javascript:void(0);">
                            <img class="img-fluid" src="@/assets/img/slider/slider-01.jpg" alt="Image" />
                        </a>
                    </div>
                </div>

                <div class="profile-widget-slide">
                    <div class="slide-pro-img">
                        <a href="javascript:void(0);">
                            <img class="img-fluid" src="@/assets/img/slider/slider-02.jpg" alt="Image" />
                        </a>
                    </div>
                </div>
            </a-carousel>

        </div>
        <div class="container">
            <div class="banner-kofe">
                <div class="banner-content aos" data-aos="fade-up">
                    <h5>With the world's #1 Developers Marketplace</h5>
                    <h1>The Largest Online Learning <span> Platform For Drive Your</span> Career.</h1>
                    <p>We are experienced in educationl platform and skilled strategies for the success of our online
                        learning.</p>
                    <form class="form" name="store" id="store" method="post" action="project">
                        <div class="form-inner">
                            <div class="input-group">
                                <span class="drop-detail">
                                    <vue-select :options="Projects" id="projectsone" placeholder="Projects" />
                                </span>
                                <input type="email" class="form-control" placeholder="What are you looking for">
                                <button class="btn btn-primary sub-btn" type="submit">Search </button>
                            </div>
                        </div>
                    </form>
                    <div class="banner-join-users">
                        <ul>
                            <li><a href="javascript:void(0);"><img src="@/assets/img/user/banner-user-01.jpg" alt="Img"></a>
                            </li>
                            <li><a href="javascript:void(0);"><img src="@/assets/img/user/banner-user-02.jpg" alt="Img"></a>
                            </li>
                            <li><a href="javascript:void(0);"><img src="@/assets/img/user/banner-user-03.jpg" alt="Img"></a>
                            </li>
                        </ul>
                        <div class="join-user-content">
                            <h6>Join Over 4000+ Students</h6>
                            <span>Register to the Online courses</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- /Home Banner -->


    <!-- Different Categories -->
    <section class="section different-categories">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-sm-12 col-12 mx-auto">
                    <div class="section-header section-header-three section-locate aos" data-aos="fade-up">
                        <div>
                            <span class="badge title-badge">Projects By Category</span>
                            <h2 class="header-title">Get work done in over 60 different categories</h2>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-xl-3 col-lg-4 col-md-6 d-flex">
                    <div class="different-categories-blk aos" data-aos="fade-up">
                        <div class="differ-img">
                            <img src="@/assets/img/icon/category-01.svg" alt="Img">
                        </div>
                        <div class="differ-img-right">
                            <img src="@/assets/img/icon/category-09.svg" alt="Img">
                        </div>
                        <div class="differ-content">
                            <h6>Angular Development</h6>
                            <p>Web development, UI/UX development, Module development & more</p>
                            <div class="category-foot">
                                <span>840 Projects</span>
                                <router-link to="/freelancer/project"><i class="feather-arrow-right"></i></router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-6 d-flex">
                    <div class="different-categories-blk aos" data-aos="fade-up">
                        <div class="differ-img">
                            <img src="@/assets/img/icon/category-02.svg" alt="Img">
                        </div>
                        <div class="differ-img-right">
                            <img src="@/assets/img/icon/category-10.svg" alt="Img">
                        </div>
                        <div class="differ-content">
                            <h6>Python Development</h6>
                            <p>Web development, UI/UX development, Module development & more</p>
                            <div class="category-foot">
                                <span>640 Projects</span>
                                <router-link to="/freelancer/project"><i class="feather-arrow-right"></i></router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-6 d-flex">
                    <div class="different-categories-blk aos" data-aos="fade-up">
                        <div class="differ-img">
                            <img src="@/assets/img/icon/category-03.svg" alt="Img">
                        </div>
                        <div class="differ-img-right">
                            <img src="@/assets/img/icon/category-11.svg" alt="Img">
                        </div>
                        <div class="differ-content">
                            <h6>Node JS Development</h6>
                            <p>Web development, UI/UX development, Module development & more</p>
                            <div class="category-foot">
                                <span>240 Projects</span>
                                <router-link to="/freelancer/project"><i class="feather-arrow-right"></i></router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-6 d-flex">
                    <div class="different-categories-blk aos" data-aos="fade-up">
                        <div class="differ-img">
                            <img src="@/assets/img/icon/category-04.svg" alt="Img">
                        </div>
                        <div class="differ-img-right">
                            <img src="@/assets/img/icon/category-12.svg" alt="Img">
                        </div>
                        <div class="differ-content">
                            <h6>PHP Development</h6>
                            <p>Web development, UI/UX development, Module development & more</p>
                            <div class="category-foot">
                                <span>840 Projects</span>
                                <router-link to="/freelancer/project"><i class="feather-arrow-right"></i></router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-6 d-flex">
                    <div class="different-categories-blk aos" data-aos="fade-up">
                        <div class="differ-img">
                            <img src="@/assets/img/icon/category-05.svg" alt="Img">
                        </div>
                        <div class="differ-img-right">
                            <img src="@/assets/img/icon/category-13.svg" alt="Img">
                        </div>
                        <div class="differ-content">
                            <h6>Illustration</h6>
                            <p>Web development, UI/UX development, Module development & more</p>
                            <div class="category-foot">
                                <span>340 Projects</span>
                                <router-link to="/freelancer/project"><i class="feather-arrow-right"></i></router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-6 d-flex">
                    <div class="different-categories-blk aos" data-aos="fade-up">
                        <div class="differ-img">
                            <img src="@/assets/img/icon/category-06.svg" alt="Img">
                        </div>
                        <div class="differ-img-right">
                            <img src="@/assets/img/icon/category-14.svg" alt="Img">
                        </div>
                        <div class="differ-content">
                            <h6>Link Building</h6>
                            <p>Web development, UI/UX development, Module development & more</p>
                            <div class="category-foot">
                                <span>540 Projects</span>
                                <router-link to="/freelancer/project"><i class="feather-arrow-right"></i></router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-6 d-flex">
                    <div class="different-categories-blk aos" data-aos="fade-up">
                        <div class="differ-img">
                            <img src="@/assets/img/icon/category-07.svg" alt="Img">
                        </div>
                        <div class="differ-img-right">
                            <img src="@/assets/img/icon/category-15.svg" alt="Img">
                        </div>
                        <div class="differ-content">
                            <h6>Android Apps</h6>
                            <p>Web development, UI/UX development, Module development & more</p>
                            <div class="category-foot">
                                <span>640 Projects</span>
                                <router-link to="/freelancer/project"><i class="feather-arrow-right"></i></router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-6 d-flex">
                    <div class="different-categories-blk aos" data-aos="fade-up">
                        <div class="differ-img">
                            <img src="@/assets/img/icon/category-08.svg" alt="Img">
                        </div>
                        <div class="differ-img-right">
                            <img src="@/assets/img/icon/category-16.svg" alt="Img">
                        </div>
                        <div class="differ-content">
                            <h6>MYSQL</h6>
                            <p>Web development, UI/UX development, Module development & more</p>
                            <div class="category-foot">
                                <span>140 Projects</span>
                                <router-link to="/freelancer/project"><i class="feather-arrow-right"></i></router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-12">
                    <div class="more-project text-center aos aos-init aos-animate" data-aos="fade-up">
                        <router-link to="/freelancer/project" class="btn btn-primary">View More Projects<i
                                class="feather-arrow-right ms-2"></i></router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- /Different Categories -->
</template>


<script>
import { defineComponent, ref } from "vue";
export default defineComponent({
    setup() {
        return {
            dotPosition: ref("right"),
            Projects: ["Projects", "Freelancers"]
        };
    },
});
</script>

<style>
/* For demo */
.ant-carousel :deep(.slick-slide) {
    text-align: center;
    height: 100px;
    line-height: 160px;
    overflow: hidden;
}

.ant-carousel img {
    width: 100%;
    height: 100%;
}
</style>