<template>
  <div class="dashboard-page">
    <div class="content content-page">
      <div class="container">
        <div class="row">
          <div class="col-xl-3 col-lg-4 theiaStickySidebar">
            <freelancer-sidebar></freelancer-sidebar>
          </div>

          <div class="col-xl-9 col-lg-8">
            <div class="dashboard-sec payout-section freelancer-statements">
              <div class="page-title portfolio-title">
                <h3 class="mb-0">Change Password</h3>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="input-block">
                    <label class="focus-label">Old Password </label>
                    <div class="position-relative">
                      <input type="password" class="form-control floating pass-input" />
                      <div class="password-icon">
                        <span class="fas toggle-password fa-eye-slash"></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="input-block">
                    <label class="focus-label">New Password </label>
                    <div class="position-relative">
                      <input type="password" class="form-control floating pass-input1" />
                      <div class="password-icon">
                        <span class="fas toggle-password1 fa-eye-slash"></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="input-block">
                    <label class="focus-label">Confirm Password </label>
                    <div class="position-relative">
                      <input type="password" class="form-control floating pass-inputs" />
                      <div class="password-icon">
                        <span class="fas toggle-passwords fa-eye-slash"></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="d-flex doc-btn">
                    <a href="javascript:void(0);" class="btn btn-gray">Cancel</a>
                    <a
                      href="#password-changed"
                      data-bs-toggle="modal"
                      class="btn btn-primary"
                      >Update</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <freelancer-password-modal></freelancer-password-modal>
</template>
<script>
export default {
  mounted() {
    this.setupPasswordToggle(".toggle-password", ".pass-input");
    this.setupPasswordToggle(".toggle-passwords", ".pass-inputs");
    this.setupPasswordToggle(".toggle-password1", ".pass-input1");
  },
  methods: {
    setupPasswordToggle(toggleClass, inputClass) {
      if (document.querySelector(toggleClass)) {
        document.addEventListener("click", (event) => {
          const toggleElement = event.target.closest(toggleClass);
          if (toggleElement) {
            toggleElement.classList.toggle("fa-eye");
            toggleElement.classList.toggle("fa-eye-slash");
            const input = document.querySelector(inputClass);
            if (input.type === "password") {
              input.type = "text";
            } else {
              input.type = "password";
            }
          }
        });
      }
    },
  },
};
</script>
