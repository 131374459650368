<template>
  <!-- Server Side Validation -->
  <div class="card">
    <div class="card-body">
      <div class="card-header pt-0 mb-4">
        <h5 class="card-title">Server side</h5>
        <p class="card-text">
          We recommend using client side validation, but in case you require server side,
          you can indicate invalid and valid form fields with
          <code>.is-invalid</code> and <code>.is-valid</code>. Note that
          <code>.invalid-feedback</code> is also supported with these classes.
        </p>
      </div>
      <form>
        <div class="form-row row">
          <div class="col-md-4 mb-3">
            <label for="validationServer01">First name</label>
            <input
              type="text"
              class="form-control is-valid"
              id="validationServer01"
              placeholder="First name"
              value="Mark"
              required
            />
            <div class="valid-feedback">Looks good!</div>
          </div>
          <div class="col-md-4 mb-3">
            <label for="validationServer02">Last name</label>
            <input
              type="text"
              class="form-control is-valid"
              id="validationServer02"
              placeholder="Last name"
              value="Otto"
              required
            />
            <div class="valid-feedback">Looks good!</div>
          </div>
          <div class="col-md-4 mb-3">
            <label for="validationServerUsername">Username</label>
            <div class="input-group">
              <span class="input-group-text" id="inputGroupPrepend3">@</span>
              <input
                type="text"
                class="form-control is-invalid"
                id="validationServerUsername"
                placeholder="Username"
                aria-describedby="inputGroupPrepend3"
                required
              />
              <div class="invalid-feedback">Please choose a username.</div>
            </div>
          </div>
        </div>
        <div class="form-row row">
          <div class="col-md-6 mb-3">
            <label for="validationServer03">City</label>
            <input
              type="text"
              class="form-control is-invalid"
              id="validationServer03"
              placeholder="City"
              required
            />
            <div class="invalid-feedback">Please provide a valid city.</div>
          </div>
          <div class="col-md-3 mb-3">
            <label for="validationServer04">State</label>
            <input
              type="text"
              class="form-control is-invalid"
              id="validationServer04"
              placeholder="State"
              required
            />
            <div class="invalid-feedback">Please provide a valid state.</div>
          </div>
          <div class="col-md-3 mb-3">
            <label for="validationServer05">Zip</label>
            <input
              type="text"
              class="form-control is-invalid"
              id="validationServer05"
              placeholder="Zip"
              required
            />
            <div class="invalid-feedback">Please provide a valid zip.</div>
          </div>
        </div>
        <div class="form-group">
          <div class="form-check">
            <input
              class="form-check-input is-invalid"
              type="checkbox"
              value=""
              id="invalidCheck3"
              required
            />
            <label class="form-check-label" for="invalidCheck3">
              Agree to terms and conditions
            </label>
            <div class="invalid-feedback">You must agree before submitting.</div>
          </div>
        </div>
        <button variant="primary" type="submit">Submit form</button>
      </form>
    </div>
  </div>
  <!-- /Server Side Validation -->

  <!-- Supported Elements -->
  <div class="card">
    <div class="card-body">
      <div class="card-header pt-0 mb-4">
        <h5 class="card-title">Supported elements</h5>
        <p class="card-text">
          Form validation styles are also available for bootstrap custom form controls.
        </p>
      </div>
      <div class="row">
        <div class="col-sm">
          <form class="was-validated">
            <div class="custom-control form-check mb-3">
              <input
                type="checkbox"
                class="form-check-input"
                id="customControlValidation1"
                required
              />
              <label class="form-check-label" for="customControlValidation1"
                >Check this custom checkbox</label
              >
              <div class="invalid-feedback">Example invalid feedback text</div>
            </div>
            <div class="custom-control form-check">
              <input
                type="radio"
                class="form-check-input"
                id="customControlValidation2"
                name="radio-stacked"
                required
              />
              <label class="form-check-label" for="customControlValidation2"
                >Toggle this custom radio</label
              >
            </div>
            <div class="custom-control form-check mb-3">
              <input
                type="radio"
                class="form-check-input"
                id="customControlValidation3"
                name="radio-stacked"
                required
              />
              <label class="form-check-label" for="customControlValidation3"
                >Or toggle this other custom radio</label
              >
              <div class="invalid-feedback">More example invalid feedback text</div>
            </div>
            <div class="form-group">
              <select class="form-select" required>
                <option value="">Open this select menu</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
              <div class="invalid-feedback">Example invalid custom select feedback</div>
            </div>

            <div class="form-group">
              <input type="file" class="form-control" id="validatedCustomFile" required />
              <div class="invalid-feedback">Example invalid custom file feedback</div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /Supported Elements -->

  <!-- Validation Tooltips -->
  <div class="card">
    <div class="card-body">
      <div class="card-header pt-0 mb-4">
        <h5 class="card-title">Tooltips</h5>
        <p class="card-text">
          You can swap the <code>.{valid|invalid}-feedback</code> classes for
          <code>.{valid|invalid}-tooltip</code> classes to display validation feedback in
          a styled tooltip.
        </p>
      </div>
      <form class="needs-validation" novalidate>
        <div class="form-row row">
          <div class="col-md-4 mb-3">
            <label for="validationTooltip01">First name</label>
            <input
              type="text"
              class="form-control"
              id="validationTooltip01"
              placeholder="First name"
              value="Mark"
              required
            />
            <div class="valid-tooltip">Looks good!</div>
          </div>
          <div class="col-md-4 mb-3">
            <label for="validationTooltip02">Last name</label>
            <input
              type="text"
              class="form-control"
              id="validationTooltip02"
              placeholder="Last name"
              value="Otto"
              required
            />
            <div class="valid-tooltip">Looks good!</div>
          </div>
          <div class="col-md-4 mb-3">
            <label for="validationTooltipUsername">Username</label>
            <input
              type="text"
              class="form-control"
              id="validationTooltipUsername"
              placeholder="Username"
              required
            />
            <div class="invalid-tooltip">Please choose a unique and valid username.</div>
          </div>
        </div>
        <div class="form-row row">
          <div class="col-md-6 mb-3">
            <label for="validationTooltip03">City</label>
            <input
              type="text"
              class="form-control"
              id="validationTooltip03"
              placeholder="City"
              required
            />
            <div class="invalid-tooltip">Please provide a valid city.</div>
          </div>
          <div class="col-md-3 mb-3">
            <label for="validationTooltip04">State</label>
            <input
              type="text"
              class="form-control"
              id="validationTooltip04"
              placeholder="State"
              required
            />
            <div class="invalid-tooltip">Please provide a valid state.</div>
          </div>
          <div class="col-md-3 mb-3">
            <label for="validationTooltip05">Zip</label>
            <input
              type="text"
              class="form-control"
              id="validationTooltip05"
              placeholder="Zip"
              required
            />
            <div class="invalid-tooltip">Please provide a valid zip.</div>
          </div>
        </div>
        <button variant="primary" type="submit">Submit form</button>
      </form>
    </div>
  </div>
  <!-- /Validation Tooltips -->
</template>
