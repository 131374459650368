<template>
  <!-- Most Hired Developers -->
  <section class="most-hired-section">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-12 col-sm-12 col-12 mx-auto text-center">
          <div
            class="section-header aos aos-init aos-animate"
            data-aos="fade-up"
          >
            <h2 class="header-title">Most Hired Developers</h2>
            <p>Work with talented people at the most affordable price</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
          <div class="hired-developers aos" data-aos="fade-up">
            <div class="hired-developers-img">
              <router-link to="/employers/developer-details"
                ><img
                  src="@/assets/img/developer/developer-13.jpg"
                  class="img-fluid"
                  alt="Img"
              /></router-link>
            </div>
            <div
              class="hired-developers-img-content d-flex justify-content-between align-items-start"
            >
              <div>
                <a
                  data-toggle="modal"
                  href="#rating"
                  class="favourite color-active"
                  ><i class="feather-heart"></i
                ></a>
                <h4>
                  <router-link to="/employers/developer-details"
                    >Steven Picard</router-link
                  >
                </h4>
                <span>Angular Developer</span>
                <h6>
                  <img
                    src="@/assets/img/icon/dollar-developper.svg"
                    class="me-1"
                    alt="Img"
                  />$32 /hr
                </h6>
              </div>
              <div class="rating">
                <i class="fas fa-star filled"></i>
                <i class="fas fa-star filled"></i>
                <i class="fas fa-star filled"></i>
                <i class="fas fa-star filled"></i>
                <i class="fas fa-star filled"></i>
                <span class="average-rating">5.0</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
          <div class="hired-developers aos" data-aos="fade-up">
            <div class="hired-developers-img">
              <router-link to="/employers/developer-details"
                ><img
                  src="@/assets/img/developer/developer-14.jpg"
                  class="img-fluid"
                  alt="Img"
              /></router-link>
            </div>
            <div
              class="hired-developers-img-content d-flex justify-content-between align-items-start"
            >
              <div>
                <a
                  data-toggle="modal"
                  href="#rating"
                  class="favourite color-active"
                  ><i class="feather-heart"></i
                ></a>
                <h4>
                  <router-link to="/employers/developer-details">Aroon Merkel</router-link>
                </h4>
                <span>Java Developer</span>
                <h6>
                  <img
                    src="@/assets/img/icon/dollar-developper.svg"
                    class="me-1"
                    alt="Img"
                  />$72 /hr
                </h6>
              </div>
              <div class="rating">
                <i class="fas fa-star filled"></i>
                <i class="fas fa-star filled"></i>
                <i class="fas fa-star filled"></i>
                <i class="fas fa-star filled"></i>
                <i class="fas fa-star filled"></i>
                <span class="average-rating">5.0</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
          <div class="hired-developers aos" data-aos="fade-up">
            <div class="hired-developers-img">
              <router-link to="/employers/developer-details"
                ><img
                  src="@/assets/img/developer/developer-15.jpg"
                  class="img-fluid"
                  alt="Img"
              /></router-link>
            </div>
            <div
              class="hired-developers-img-content d-flex justify-content-between align-items-start"
            >
              <div>
                <a
                  data-toggle="modal"
                  href="#rating"
                  class="favourite color-active"
                  ><i class="feather-heart"></i
                ></a>
                <h4>
                  <router-link to="/employers/developer-details">Durso Raeen</router-link>
                </h4>
                <span>React Developer</span>
                <h6>
                  <img
                    src="@/assets/img/icon/dollar-developper.svg"
                    class="me-1"
                    alt="Img"
                  />$452 /hr
                </h6>
              </div>
              <div class="rating">
                <i class="fas fa-star filled"></i>
                <i class="fas fa-star filled"></i>
                <i class="fas fa-star filled"></i>
                <i class="fas fa-star filled"></i>
                <i class="fas fa-star filled"></i>
                <span class="average-rating">5.0</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
          <div class="hired-developers aos" data-aos="fade-up">
            <div class="hired-developers-img">
              <router-link to="/employers/developer-details"
                ><img
                  src="@/assets/img/developer/developer-16.jpg"
                  class="img-fluid"
                  alt="Img"
              /></router-link>
            </div>
            <div
              class="hired-developers-img-content d-flex justify-content-between align-items-start"
            >
              <div>
                <a
                  data-toggle="modal"
                  href="#rating"
                  class="favourite color-active"
                  ><i class="feather-heart"></i
                ></a>
                <h4>
                  <router-link to="/employers/developer-details">Andrew Jene</router-link>
                </h4>
                <span>Html Developer</span>
                <h6>
                  <img
                    src="@/assets/img/icon/dollar-developper.svg"
                    class="me-1"
                    alt="Img"
                  />$63 /hr
                </h6>
              </div>
              <div class="rating">
                <i class="fas fa-star filled"></i>
                <i class="fas fa-star filled"></i>
                <i class="fas fa-star filled"></i>
                <i class="fas fa-star filled"></i>
                <i class="fas fa-star filled"></i>
                <span class="average-rating">5.0</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Most Hired Developers -->
</template>
