<template>
    <div class="sidebar-group left-sidebar chat_sidebar">
        <!-- Chats sidebar -->
        <div id="chats" class="left-sidebar-wrap sidebar active slimscroll">
            <perfect-scrollbar class="scroll-area" :settings="settings" @ps-scroll-y="scrollHanle">
                <div class="slimscroll">
                    <div class="left-chat-title all-chats d-flex justify-content-between align-items-center">
                        <div class="select-group-chat">
                            <div class="dropdown">
                                <a href="javascript:void(0);"> All Chats </a>
                            </div>
                        </div>
                        <div class="add-section">
                            <ul>
                                <li>
                                    <a href="javascript:void(0);" class="user-chat-search-btn" @click="showChat"><i
                                            class="bx bx-search"></i></a>
                                </li>
                                <li>
                                    <div class="chat-action-btns">
                                        <div class="chat-action-col">
                                            <a class="#" href="javascript:void(0);" data-bs-toggle="dropdown"
                                                aria-expanded="false">
                                                <i class="bx bx-dots-vertical-rounded"></i>
                                            </a>
                                            <div class="dropdown-menu dropdown-menu-end">
                                                <a href="javascript:void(0);" class="dropdown-item"><span><i
                                                            class="bx bx-message-rounded-add"></i></span>New Chat
                                                </a>
                                                <a href="javascript:void(0);" class="dropdown-item"><span><i
                                                            class="bx bx-user-circle"></i></span>Create Group</a>
                                                <a href="javascript:void(0);" class="dropdown-item"><span><i
                                                            class="bx bx-user-plus"></i></span>Invite Others</a>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <!-- Chat Search -->
                            <div class="user-chat-search" :class="{ 'visible-chat': chatVisible }">
                                <form>
                                    <span class="form-control-feedback"><i class="bx bx-search"></i></span>
                                    <input type="text" name="chat-search" placeholder="Search" class="form-control" />
                                    <div class="user-close-btn-chat" @click="hideChat">
                                        <span class="material-icons">close</span>
                                    </div>
                                </form>
                            </div>
                            <!-- /Chat Search -->
                        </div>
                    </div>
                    <!-- /Left Chat Title -->

                    <!-- Top Online Contacts -->
                    <div class="top-online-contacts">
                        <div class="fav-title">
                            <h6>Online Now</h6>
                        </div>
                        <div class="swiper-container">
                            <div class="swiper-wrapper">
                                <div class="swiper-slide">
                                    <div class="top-contacts-box">
                                        <div class="profile-img online">
                                            <router-link to="/employers/chats"><img src="@/assets/img/avatar/avatar-2.jpg"
                                                    alt="Image" /></router-link>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-slide">
                                    <div class="top-contacts-box">
                                        <div class="profile-img online">
                                            <router-link to="/employers/chats"><img src="@/assets/img/avatar/avatar-1.jpg"
                                                    alt="Image" /></router-link>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-slide">
                                    <div class="top-contacts-box">
                                        <div class="profile-img online">
                                            <router-link to="/employers/chats"><img src="@/assets/img/avatar/avatar-7.jpg"
                                                    alt="Image" /></router-link>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-slide">
                                    <div class="top-contacts-box">
                                        <div class="profile-img online">
                                            <router-link to="/employers/chats"><img src="@/assets/img/avatar/avatar-5.jpg"
                                                    alt="Image" /></router-link>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-slide">
                                    <div class="top-contacts-box">
                                        <div class="profile-img online">
                                            <router-link to="/employers/chats"><img src="@/assets/img/avatar/avatar-3.jpg"
                                                    alt="Image" /></router-link>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-slide">
                                    <div class="top-contacts-box">
                                        <div class="profile-img online">
                                            <router-link to="/employers/chats"><img src="@/assets/img/avatar/avatar-2.jpg"
                                                    alt="Image" /></router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- /Top Online Contacts -->

                    <div class="sidebar-body chat-body" id="chatsidebar">
                        <!-- Left Chat Title -->
                        <div class="d-flex justify-content-between align-items-center ps-0 pe-0">
                            <div class="fav-title pin-chat">
                                <h6><i class="bx bx-pin me-1"></i>Pinned Chat</h6>
                            </div>
                        </div>
                        <!-- /Left Chat Title -->

                        <ul class="user-list space-chat">
                            <li class="user-list-item chat-user-list">
                                <a href="javascript:void(0);" class="status-active" @click="handleUserLinkClick">
                                    <div class="avatar avatar-online">
                                        <img src="@/assets/img/avatar/avatar-2.jpg" class="rounded-circle" alt="image" />
                                    </div>
                                    <div class="users-list-body">
                                        <div>
                                            <h5>Mark Villiams</h5>
                                            <p>Have you called them?</p>
                                        </div>
                                        <div class="last-chat-time">
                                            <small class="text-muted">10:20 PM</small>
                                            <div class="chat-pin">
                                                <i class="bx bx-pin me-2"></i>
                                                <i class="bx bx-check-double"></i>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                                <div class="chat-hover ms-1">
                                    <div class="chat-action-col">
                                        <span class="d-flex" data-bs-toggle="dropdown">
                                            <i class="bx bx-dots-vertical-rounded"></i>
                                        </span>
                                        <div class="dropdown-menu chat-drop-menu dropdown-menu-end">
                                            <span class="dropdown-item"><span><i class="bx bx-archive-in"></i></span>Archive
                                                Chat
                                            </span>
                                            <span class="dropdown-item"><span><i class="bx bx-volume-mute"></i></span>Mute
                                                Notification</span>
                                            <span class="dropdown-item"><span><i class="bx bx-log-out"></i></span>Delete
                                                Chat</span>
                                            <span class="dropdown-item"><span><i class="bx bx-pin"></i></span>Unpin
                                                Chat</span>
                                            <span class="dropdown-item"><span><i class="bx bx-check-square"></i></span>Mark
                                                as
                                                Unread</span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="user-list-item chat-user-list">
                                <a href="javascript:void(0);">
                                    <div>
                                        <div class="avatar">
                                            <img src="@/assets/img/avatar/avatar-13.jpg" class="rounded-circle"
                                                alt="image" />
                                        </div>
                                    </div>
                                    <div class="users-list-body">
                                        <div>
                                            <h5>Elizabeth Sosa</h5>
                                            <p>
                                                <span class="animate-typing-col">Typing
                                                    <span class="dot"></span>
                                                    <span class="dot"></span>
                                                    <span class="dot"></span>
                                                </span>
                                            </p>
                                        </div>
                                        <div class="last-chat-time">
                                            <small class="text-muted">Yesterday</small>
                                            <div class="chat-pin">
                                                <i class="bx bx-pin me-2"></i>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                                <div class="chat-hover">
                                    <div class="chat-action-col">
                                        <span class="d-flex" data-bs-toggle="dropdown">
                                            <i class="bx bx-dots-vertical-rounded"></i>
                                        </span>
                                        <div class="dropdown-menu chat-drop-menu dropdown-menu-end">
                                            <span class="dropdown-item"><span><i class="bx bx-archive-in"></i></span>Archive
                                                Chat
                                            </span>
                                            <span class="dropdown-item"><span><i class="bx bx-volume-mute"></i></span>Mute
                                                Notification</span>
                                            <span class="dropdown-item"><span><i class="bx bx-log-out"></i></span>Delete
                                                Chat</span>
                                            <span class="dropdown-item"><span><i class="bx bx-pin"></i></span>Unpin
                                                Chat</span>
                                            <span class="dropdown-item"><span><i class="bx bx-check-square"></i></span>Mark
                                                as
                                                Unread</span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="user-list-item chat-user-list">
                                <a href="javascript:void(0);">
                                    <div class="avatar avatar-online">
                                        <img src="@/assets/img/avatar/avatar-5.jpg" class="rounded-circle" alt="image" />
                                    </div>
                                    <div class="users-list-body">
                                        <div>
                                            <h5>Michael Howard</h5>
                                            <p>Thank you</p>
                                        </div>
                                        <div class="last-chat-time">
                                            <small class="text-muted">10:20 PM</small>
                                            <div class="chat-pin">
                                                <i class="bx bx-pin me-2"></i>
                                                <i class="bx bx-check-double check"></i>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                                <div class="chat-hover ms-1">
                                    <div class="chat-action-col">
                                        <span class="d-flex" data-bs-toggle="dropdown">
                                            <i class="bx bx-dots-vertical-rounded"></i>
                                        </span>
                                        <div class="dropdown-menu chat-drop-menu dropdown-menu-end">
                                            <span class="dropdown-item"><span><i class="bx bx-archive-in"></i></span>Archive
                                                Chat
                                            </span>
                                            <span class="dropdown-item"><span><i class="bx bx-volume-mute"></i></span>Mute
                                                Notification</span>
                                            <span class="dropdown-item"><span><i class="bx bx-log-out"></i></span>Delete
                                                Chat</span>
                                            <span class="dropdown-item"><span><i class="bx bx-pin"></i></span>Unpin
                                                Chat</span>
                                            <span class="dropdown-item"><span><i class="bx bx-check-square"></i></span>Mark
                                                as
                                                Unread</span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>

                        <!-- Left Chat Title -->
                        <div class="d-flex justify-content-between align-items-center ps-0 pe-0">
                            <div class="fav-title pin-chat">
                                <h6><i class="bx bx-message-square-dots me-1"></i>Recent Chat</h6>
                            </div>
                        </div>
                        <!-- /Left Chat Title -->
                        <ul class="user-list">
                            <li class="user-list-item chat-user-list">
                                <a href="javascript:void(0);">
                                    <div class="avatar avatar-online">
                                        <img src="@/assets/img/avatar/avatar-1.jpg" class="rounded-circle" alt="image" />
                                    </div>
                                    <div class="users-list-body">
                                        <div>
                                            <h5>Horace Keene</h5>
                                            <p>Have you called them?</p>
                                        </div>
                                        <div class="last-chat-time">
                                            <small class="text-muted">Just Now</small>
                                            <div class="chat-pin">
                                                <span class="count-message">5</span>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                                <div class="chat-hover ms-1">
                                    <div class="chat-action-col">
                                        <span class="d-flex" data-bs-toggle="dropdown">
                                            <i class="bx bx-dots-vertical-rounded"></i>
                                        </span>
                                        <div class="dropdown-menu chat-drop-menu dropdown-menu-end">
                                            <span class="dropdown-item"><span><i class="bx bx-archive-in"></i></span>Archive
                                                Chat
                                            </span>
                                            <span class="dropdown-item"><span><i class="bx bx-volume-mute"></i></span>Mute
                                                Notification</span>
                                            <span class="dropdown-item"><span><i class="bx bx-trash"></i></span>Delete
                                                Chat</span>
                                            <span class="dropdown-item"><span><i class="bx bx-pin"></i></span>Pin
                                                Chat</span>
                                            <span class="dropdown-item"><span><i class="bx bx-check-square"></i></span>Mark
                                                as Read</span>
                                            <span class="dropdown-item"><span><i class="bx bx-block"></i></span>Block</span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="user-list-item chat-user-list">
                                <a href="javascript:void(0);">
                                    <div>
                                        <div class="avatar avatar-online">
                                            <img src="@/assets/img/avatar/avatar-3.jpg" class="rounded-circle"
                                                alt="image" />
                                        </div>
                                    </div>
                                    <div class="users-list-body">
                                        <div>
                                            <h5>Hollis Tran</h5>
                                            <p><i class="bx bx-video me-1"></i>Video</p>
                                        </div>
                                        <div class="last-chat-time">
                                            <small class="text-muted">Yesterday</small>
                                            <div class="chat-pin">
                                                <i class="bx bx-check"></i>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                                <div class="chat-hover ms-1">
                                    <div class="chat-action-col">
                                        <span class="d-flex" data-bs-toggle="dropdown">
                                            <i class="bx bx-dots-vertical-rounded"></i>
                                        </span>
                                        <div class="dropdown-menu chat-drop-menu dropdown-menu-end">
                                            <span class="dropdown-item"><span><i class="bx bx-archive-in"></i></span>Archive
                                                Chat
                                            </span>
                                            <span class="dropdown-item"><span><i class="bx bx-volume-mute"></i></span>Mute
                                                Notification</span>
                                            <span class="dropdown-item"><span><i class="bx bx-trash"></i></span>Delete
                                                Chat</span>
                                            <span class="dropdown-item"><span><i class="bx bx-pin"></i></span>Pin
                                                Chat</span>
                                            <span class="dropdown-item"><span><i class="bx bx-check-square"></i></span>Mark
                                                as
                                                Unread</span>
                                            <span class="dropdown-item"><span><i class="bx bx-block"></i></span>Block</span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="user-list-item chat-user-list">
                                <a href="javascript:void(0);">
                                    <div class="avatar">
                                        <img src="@/assets/img/avatar/avatar-4.jpg" class="rounded-circle" alt="image" />
                                    </div>
                                    <div class="users-list-body">
                                        <div>
                                            <h5>James Albert</h5>
                                            <p><i class="bx bx-file me-1"></i>Project Tools.doc</p>
                                        </div>
                                        <div class="last-chat-time">
                                            <small class="text-muted">10:20 PM</small>
                                        </div>
                                    </div>
                                </a>
                                <div class="chat-hover ms-1">
                                    <div class="chat-action-col">
                                        <span class="d-flex" data-bs-toggle="dropdown">
                                            <i class="bx bx-dots-vertical-rounded"></i>
                                        </span>
                                        <div class="dropdown-menu chat-drop-menu dropdown-menu-end">
                                            <span class="dropdown-item"><span><i class="bx bx-archive-in"></i></span>Archive
                                                Chat
                                            </span>
                                            <span class="dropdown-item"><span><i class="bx bx-volume-mute"></i></span>Mute
                                                Notification</span>
                                            <span class="dropdown-item"><span><i class="bx bx-trash"></i></span>Delete
                                                Chat</span>
                                            <span class="dropdown-item"><span><i class="bx bx-pin"></i></span>Pin
                                                Chat</span>
                                            <span class="dropdown-item"><span><i class="bx bx-check-square"></i></span>Mark
                                                as
                                                Unread</span>
                                            <span class="dropdown-item"><span><i class="bx bx-block"></i></span>Block</span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="user-list-item chat-user-list">
                                <a href="javascript:void(0);">
                                    <div>
                                        <div class="avatar avatar-online">
                                            <img src="@/assets/img/avatar/avatar-9.jpg" class="rounded-circle"
                                                alt="image" />
                                        </div>
                                    </div>
                                    <div class="users-list-body">
                                        <div>
                                            <h5>Debra Jones</h5>
                                            <p><i class="bx bx-microphone me-1"></i>Audio</p>
                                        </div>
                                        <div class="last-chat-time">
                                            <small class="text-muted">12:30 PM</small>
                                            <div class="chat-pin">
                                                <i class="bx bx-check-double check"></i>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                                <div class="chat-hover ms-1">
                                    <div class="chat-action-col">
                                        <span class="d-flex" data-bs-toggle="dropdown">
                                            <i class="bx bx-dots-vertical-rounded"></i>
                                        </span>
                                        <div class="dropdown-menu chat-drop-menu dropdown-menu-end">
                                            <span class="dropdown-item"><span><i class="bx bx-archive-in"></i></span>Archive
                                                Chat
                                            </span>
                                            <span class="dropdown-item"><span><i class="bx bx-volume-mute"></i></span>Mute
                                                Notification</span>
                                            <span class="dropdown-item"><span><i class="bx bx-trash"></i></span>Delete
                                                Chat</span>
                                            <span class="dropdown-item"><span><i class="bx bx-pin"></i></span>Pin
                                                Chat</span>
                                            <span class="dropdown-item"><span><i class="bx bx-check-square"></i></span>Mark
                                                as
                                                Unread</span>
                                            <span class="dropdown-item"><span><i class="bx bx-block"></i></span>Block</span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="user-list-item chat-user-list">
                                <a href="javascript:void(0);" >
                                    <div>
                                        <div class="avatar">
                                            <img src="@/assets/img/avatar/avatar-7.jpg" class="rounded-circle"
                                                alt="image" />
                                        </div>
                                    </div>
                                    <div class="users-list-body">
                                        <div>
                                            <h5>Dina Brown</h5>
                                            <p>Have you called them?</p>
                                        </div>
                                        <div class="last-chat-time">
                                            <small class="text-muted">Yesterday</small>
                                            <div class="chat-pin">
                                                <i class="bx bx-microphone-off"></i>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                                <div class="chat-hover ms-1">
                                    <div class="chat-action-col">
                                        <span class="d-flex" data-bs-toggle="dropdown">
                                            <i class="bx bx-dots-vertical-rounded"></i>
                                        </span>
                                        <div class="dropdown-menu chat-drop-menu dropdown-menu-end">
                                            <span class="dropdown-item"><span><i class="bx bx-archive-in"></i></span>Archive
                                                Chat
                                            </span>
                                            <span class="dropdown-item"><span><i class="bx bx-volume-mute"></i></span>Mute
                                                Notification</span>
                                            <span class="dropdown-item"><span><i class="bx bx-trash"></i></span>Delete
                                                Chat</span>
                                            <span class="dropdown-item"><span><i class="bx bx-pin"></i></span>Pin
                                                Chat</span>
                                            <span class="dropdown-item"><span><i class="bx bx-check-square"></i></span>Mark
                                                as
                                                Unread</span>
                                            <span class="dropdown-item"><span><i class="bx bx-block"></i></span>Block</span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="user-list-item chat-user-list">
                                <a href="javascript:void(0);">
                                    <div>
                                        <div class="avatar avatar-online">
                                            <img src="@/assets/img/avatar/avatar-8.jpg" class="rounded-circle"
                                                alt="image" />
                                        </div>
                                    </div>
                                    <div class="users-list-body">
                                        <div>
                                            <h5>Judy Mercer</h5>
                                            <p class="missed-call-col">
                                                <i class="bx bx-phone-incoming me-1"></i>Missed Call
                                            </p>
                                        </div>
                                        <div class="last-chat-time">
                                            <small class="text-muted">25/July/23</small>
                                        </div>
                                    </div>
                                </a>
                                <div class="chat-hover ms-1">
                                    <div class="chat-action-col">
                                        <span class="d-flex" data-bs-toggle="dropdown">
                                            <i class="bx bx-dots-vertical-rounded"></i>
                                        </span>
                                        <div class="dropdown-menu chat-drop-menu dropdown-menu-end">
                                            <span class="dropdown-item"><span><i class="bx bx-archive-in"></i></span>Archive
                                                Chat
                                            </span>
                                            <span class="dropdown-item"><span><i class="bx bx-volume-mute"></i></span>Mute
                                                Notification</span>
                                            <span class="dropdown-item"><span><i class="bx bx-trash"></i></span>Delete
                                                Chat</span>
                                            <span class="dropdown-item"><span><i class="bx bx-pin"></i></span>Pin
                                                Chat</span>
                                            <span class="dropdown-item"><span><i class="bx bx-check-square"></i></span>Mark
                                                as
                                                Unread</span>
                                            <span class="dropdown-item"><span><i class="bx bx-block"></i></span>Block</span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="user-list-item chat-user-list">
                                <a href="javascript:void(0);">
                                    <div>
                                        <div class="avatar">
                                            <img src="@/assets/img/avatar/avatar-5.jpg" class="rounded-circle"
                                                alt="image" />
                                        </div>
                                    </div>
                                    <div class="users-list-body">
                                        <div>
                                            <h5>Richard Ohare</h5>
                                            <p><i class="bx bx-image-alt me-1"></i>Photo</p>
                                        </div>
                                        <div class="last-chat-time">
                                            <small class="text-muted">27/July/23</small>
                                            <div class="chat-pin">
                                                <i class="bx bx-check-double"></i>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                                <div class="chat-hover ms-1">
                                    <div class="chat-action-col">
                                        <span class="d-flex" data-bs-toggle="dropdown">
                                            <i class="bx bx-dots-vertical-rounded"></i>
                                        </span>
                                        <div class="dropdown-menu chat-drop-menu dropdown-menu-end">
                                            <span class="dropdown-item"><span><i class="bx bx-archive-in"></i></span>Archive
                                                Chat
                                            </span>
                                            <span class="dropdown-item"><span><i class="bx bx-volume-mute"></i></span>Mute
                                                Notification</span>
                                            <span class="dropdown-item"><span><i class="bx bx-trash"></i></span>Delete
                                                Chat</span>
                                            <span class="dropdown-item"><span><i class="bx bx-pin"></i></span>Pin
                                                Chat</span>
                                            <span class="dropdown-item"><span><i class="bx bx-check-square"></i></span>Mark
                                                as
                                                Unread</span>
                                            <span class="dropdown-item"><span><i class="bx bx-block"></i></span>Block</span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="user-list-item chat-user-list">
                                <a href="javascript:void(0);">
                                    <div>
                                        <div class="avatar avatar-online">
                                            <img src="@/assets/img/avatar/avatar-6.jpg" class="rounded-circle"
                                                alt="image" />
                                        </div>
                                    </div>
                                    <div class="users-list-body">
                                        <div>
                                            <h5>Charles Sellars</h5>
                                            <p>Have you called them?</p>
                                        </div>
                                        <div class="last-chat-time">
                                            <small class="text-muted">10:20 PM</small>
                                        </div>
                                    </div>
                                </a>
                                <div class="chat-hover ms-1">
                                    <div class="chat-action-col">
                                        <span class="d-flex" data-bs-toggle="dropdown">
                                            <i class="bx bx-dots-vertical-rounded"></i>
                                        </span>
                                        <div class="dropdown-menu chat-drop-menu dropdown-menu-end">
                                            <span class="dropdown-item"><span><i class="bx bx-archive-in"></i></span>Archive
                                                Chat
                                            </span>
                                            <span class="dropdown-item"><span><i class="bx bx-volume-mute"></i></span>Mute
                                                Notification</span>
                                            <span class="dropdown-item"><span><i class="bx bx-trash"></i></span>Delete
                                                Chat</span>
                                            <span class="dropdown-item"><span><i class="bx bx-pin"></i></span>Pin
                                                Chat</span>
                                            <span class="dropdown-item"><span><i class="bx bx-check-square"></i></span>Mark
                                                as
                                                Unread</span>
                                            <span class="dropdown-item"><span><i class="bx bx-block"></i></span>Block</span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </perfect-scrollbar>
        </div>
        <!-- / Chats sidebar -->
    </div>
</template>

<script>
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
import '@/assets/css/chat.css'
export default {
    components: {
        PerfectScrollbar,
    },
    data() {
        return {
            settings: {
                suppressScrollX: true,
            },
            chatVisible: false,
        };
    },
    methods: {
        scrollHanle(evt) { },
        showChat() {
            this.chatVisible = true;
        },
        hideChat() {
            this.chatVisible = false;
        },
        handleUserLinkClick() {
            if (window.innerWidth <= 767) {
                const leftSidebar = document.getElementsByClassName("left-sidebar")[0];
                if (leftSidebar) {
                    leftSidebar.classList.add("hide-left-sidebar");
                }

                const sidebarMenu = document.getElementsByClassName("sidebar-menu")[0];
                if (sidebarMenu) {
                    sidebarMenu.classList.add("d-none");
                }
            }
        },
    },
    mounted() {
        const userListItemElements = document.getElementsByClassName("user-list-item");
        const filteredUserListItems = Array.from(userListItemElements).filter((element) => {
            return (
                !element.closest("body.status-page") && !element.closest("body.voice-call-page")
            );
        });

        filteredUserListItems.forEach((element) => {
            element.addEventListener("click", () => {
                if (window.innerWidth < 1200) {
                    const leftSidebar = document.getElementsByClassName("left-sidebar")[0];
                    const chat = document.getElementsByClassName("chat")[0];

                    if (leftSidebar && chat) {
                        leftSidebar.classList.add("hide-left-sidebar");
                        // chat.classList.add("show-chatbar");
                    }
                }
            });
        });

        var userItems = document.getElementsByClassName("user-list-item");

        for (var i = 0; i < userItems.length; i++) {
            var userItem = userItems[i];
            var body = document.body;
            if (!hasClass(body, "status-page") && !hasClass(body, "voice-call-page")) {
                userItem.addEventListener("click", function () {
                    if (window.innerWidth < 1200) {
                        var leftSidebar = getElementByClass("left-sidebar");
                        var chat = getElementByClass("chat");
                        addClass(leftSidebar, "hide-left-sidebar");
                        addClass(chat, "show-chatbar");
                    }
                });
            }
        }

        var chatHeaderIcons = document.querySelectorAll(
            ".chat-header .left_side i, .page-header .left_side i"
        );
        for (var j = 0; j < chatHeaderIcons.length; j++) {
            chatHeaderIcons[j].addEventListener("click", function () {
                var leftSidebar = getElementByClass("left-sidebar");
                var chat = getElementByClass("chat");
                removeClass(leftSidebar, "hide-left-sidebar");
                removeClass(chat, "show-chatbar");
            });
        }

        function hasClass(element, className) {
            return (" " + element.className + " ").indexOf(" " + className + " ") > -1;
        }

        function getElementByClass(className) {
            var elements = document.getElementsByClassName(className);
            for (var i = 0; i < elements.length; i++) {
                if (hasClass(elements[i], className)) {
                    return elements[i];
                }
            }
            return null;
        }

        function addClass(element, className) {
            if (element && !hasClass(element, className)) {
                element.className += " " + className;
            }
        }

        function removeClass(element, className) {
            if (element) {
                element.className = element.className.replace(
                    new RegExp("(?:^|\\s)" + className + "(?!\\S)"),
                    ""
                );
            }
        }
    },
};
</script>
