<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>

  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content report-box container-fluid">
      <!-- Page Header -->
      <div class="page-header page-border">
        <div class="row align-items-center">
          <breadcrumbthree :title="title" :text="text" :text1="text1" />

          <reportstabset></reportstabset>
        </div>
      </div>
      <!-- /Page Header -->

      <!-- Project Chart -->
      <div class="project-chart">
        <div class="row">
          <earningsbatset />

          <div class="col-xl-12 d-flex">
            <div class="card flex-fill">
              <div class="card-header">
                <div class="d-flex justify-content-between align-items-center">
                  <h5 class="card-title">Employer Report</h5>

                  <div class="dropdown">
                    <button
                      class="btn btn-white btn-sm dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      2022
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <li>
                        <a href="javascript:void(0);" class="dropdown-item">2021</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" class="dropdown-item">2022</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" class="dropdown-item">2020</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div id="chart-employee"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Project Chart -->

      <earningdownload></earningdownload>

      <!-- Employer Project -->
      <div class="card">
        <div class="card-body">
          <div class="showentries">
            <label
              >Show
              <select>
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
              entries
            </label>
            <div class="entries">
              <span>Search: </span>
              <input
                type="search"
                class="form-control form-control-sm ms-2"
                placeholder=""
                aria-controls="DataTables_Table_0"
              />
            </div>
          </div>
          <div class="table-responsive">
            <a-table :columns="columns" :data-source="data">
              <template #bodyCell="{ column, record }">
                <template v-if="column.key === 'ReferenceNumber'">
                  <div>
                    <a href="javascript:;">{{ record.ReferenceNumber }}</a>
                  </div>
                </template>
                <template v-else-if="column.key === 'inputbox'">
                  <div class="form-check custom-checkbox">
                    <input type="checkbox" class="form-check-input" id="customCheck5" />
                    <label class="form-check-label" for="customCheck5"></label>
                  </div>
                </template>
                <template v-else-if="column.key === 'id'">
                  <div class="text-end subscription-end">
                    <a
                      href="javascript:void(0);"
                      class="btn btn-sm btn-secondary me-2"
                      data-bs-toggle="modal"
                      data-bs-target="#edit-category"
                      ><i class="far fa-edit"></i
                    ></a>
                    <a
                      href="javascript:void(0);"
                      class="btn btn-sm btn-danger"
                      data-bs-toggle="modal"
                      data-bs-target="#delete_category"
                      ><i class="far fa-trash-alt"></i
                    ></a>
                  </div>
                </template>
              </template>
            </a-table>
          </div>
        </div>
      </div>
      <!-- /Employer Project -->
    </div>
    <!-- /Page Wrapper -->
  </div>

  <modelpopup />
  <delete />
</template>

<script>
import c3 from "c3";
// import "@/assets/plugins/c3-chart/c3.min.css";

const columns = [
  {
    title: "#",
    key: "inputbox",
    sorter: true,
  },
  {
    title: "Transaction date",
    dataIndex: "Transactiondate",
    sorter: {
      compare: (a, b) => {
        a = a.Transactiondate.toLowerCase();
        b = b.Transactiondate.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Reference Number",
    dataIndex: "ReferenceNumber",
    key: "ReferenceNumber",
    sorter: {
      compare: (a, b) => {
        a = a.ReferenceNumber.toLowerCase();
        b = b.ReferenceNumber.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Project Name",
    dataIndex: "ProjectName",
    sorter: {
      compare: (a, b) => {
        a = a.ProjectName.toLowerCase();
        b = b.ProjectName.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Project Value",
    dataIndex: "ProjectValue",
    sorter: {
      compare: (a, b) => {
        a = a.ProjectValue.toLowerCase();
        b = b.ProjectValue.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Income",
    dataIndex: "Income",
    sorter: {
      compare: (a, b) => {
        a = a.Income.toLowerCase();
        b = b.Income.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Payment type",
    dataIndex: "Paymenttype",
    sorter: {
      compare: (a, b) => {
        a = a.Paymenttype.toLowerCase();
        b = b.Paymenttype.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Actions",
    key: "id",
    class: "text-end",
    sorter: true,
  },
];

const data = [
  {
    FIELD1: "",
    Transactiondate: "25/03/2022",
    ReferenceNumber: "#55454",
    ProjectName: "UI/UX Design",
    ProjectValue: "$450.44",
    Income: "$40.44",
    Paymenttype: "Credit card",
    Actions: "",
  },
  {
    FIELD1: "",
    Transactiondate: "25/03/2022",
    ReferenceNumber: "#55454",
    ProjectName: "UI/UX Design",
    ProjectValue: "$450.44",
    Income: "$40.44",
    Paymenttype: "Credit card",
    Actions: "",
  },
  {
    FIELD1: "",
    Transactiondate: "25/03/2022",
    ReferenceNumber: "#55454",
    ProjectName: "UI/UX Design",
    ProjectValue: "$450.44",
    Income: "$40.44",
    Paymenttype: "Credit card",
    Actions: "",
  },
];

export default {
  data() {
    return {
      title: "Reports",
      text: "Home",
      text1: "Reports",
      columns,
      data,
    };
  },
  mounted() {
    this.generateChart();
  },
  methods: {
    generateChart() {
      c3.generate({
        bindto: "#chart-employee", // id of chart wrapper
        data: {
          columns: [
            // each columns data
            ["data1", 0, 5, 5, 7, 20, 13, 7, 21, 10, 7, 10],
          ],
          type: "spline", // default type of chart
          groups: [["data1", "data2"]],
          colors: {
            data1: "#0B6BCB",
          },
          names: {
            // name of each serie
            data1: "Maximum",
          },
        },
        axis: {
          x: {
            type: "category",
            // name of each category
            categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep"],
          },
        },
        legend: {
          show: false, //hide legend
        },
        padding: {
          bottom: 0,
          top: 0,
        },
      });
    },
  },
};
</script>
