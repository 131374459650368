<template>
  <!-- Platform Location -->
  <section class="section platform-location">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <div class="row">
            <div class="col-md-6 flex-fill">
              <div class="markrt-place-img aos" data-aos="fade-up">
                <img
                  src="@/assets/img/markrt-place-img1.jpg"
                  class="img-fluid"
                  alt="Img"
                />
              </div>
            </div>
            <div class="col-md-6 flex-fill">
              <div class="d-flex flex-column row-gap">
                <div class="markrt-place-img aos" data-aos="fade-up">
                  <img
                    src="@/assets/img/markrt-place-img2.jpg"
                    class="img-fluid"
                    alt="Img"
                  />
                </div>
                <div class="markrt-place-img aos" data-aos="fade-up">
                  <img
                    src="@/assets/img/markrt-place-img3.jpg"
                    class="img-fluid"
                    alt="Img"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12 d-flex align-items-center">
          <div class="market-place-group aos" data-aos="fade-up">
            <h2>
              Discover Project Around<span class="platform-head"
                >your Location in Our Platform</span
              >
            </h2>
            <p>Get Inspired by Development Projects</p>
            <p class="platform-pasage">
              We Provide Stable Service With Experts Freelancers around the
              globe, are looking for work and provide the best they have
              .Experience state-of-the-art marketplace platform with the
              Kofejob. We combine the experience of our global community around
              the globe for a best marketplace theme.
            </p>
            <div class="market-place-btn platform-btn">
              <router-link
                to="/freelancer/project"
                class="btn btn-primary market-project me-2"
                >View Projects</router-link
              >
              <router-link to="/freelancer/project" class="btn btn-primary project-post"
                >Post a Project</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Platform Location -->
  			<!-- Company Hire -->
			<section class="section top-company-four">
				<div class="container">
					<div class="row">
						<div class="col-md-12 col-sm-12 col-12 mx-auto">
							<div class="section-header section-header-four section-locate aos" data-aos="fade-up">
								<div class="text-center">
									<h2 class="header-title">Trusted by the world’s best  </h2>
									<p>Top companies </p>
								</div>
								
							</div>
						</div>
					</div>
					<div id="trust-company-slider" class="owl-carousel owl-theme trust-slider developers-slider aos" data-aos="fade-up">
                        <Carousel :autoplay="3000" :loop="true" :wrap-around="true" :settings="settings" :breakpoints="breakpoints">
                		<Slide class="company-logos" v-for="item in IndexFourPlatform" :key="item.id">	
						
							<img  :src="require(`@/assets/img/${item.Img}`)" alt="Img">
						
                        </Slide> 
						<template #addons>
						</template>
					</Carousel>
					</div>
				</div>
			</section>
			<!-- / Company Hire -->
</template>
<script>
import { Carousel, Slide, Navigation } from "vue3-carousel";
import IndexFourPlatform from '@/assets/json/indexfourplatform.json'
import "vue3-carousel/dist/carousel.css";
export default {
	data() {
		return {
			IndexFourPlatform: IndexFourPlatform,
			settings: {
				itemsToShow: 1,
				snapAlign: "center"
			},
		
			breakpoints: {
				575: {
					itemsToShow: 1,
					snapAlign: "center",
				},
				// 700px and up
				767: {
					itemsToShow: 3,
					snapAlign: "center",
				},
				// 991px and up
				991: {
					itemsToShow: 4,
					snapAlign: "center",
				},
				// 1024 and up
				1024: {
					itemsToShow: 5,
					snapAlign: "start",
				},
			},
		}
	},
	components: {
    Carousel,
    Slide,
	Navigation
  },
}
</script>