<template>
  <pagebreadcrumb :title="title" :text="text" :text1="text1" />
  <div class="content">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h5>Blank Page</h5>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      title: "Blank Page",
      text: "Home",
      text1: "Blank Page",
    };
  },
};
</script>
