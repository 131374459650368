<template>
  <section class="section testimonial-section review review-four">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="section-header aos text-center" data-aos="fade-up">
            <h2 class="header-title">Client testimonials</h2>
            <p>
              Learning communicate to global world and build a bright future and career
              development, increase your skill with our histudy.
            </p>
          </div>
        </div>
      </div>
      <div
        id="testimonial-slider"
        class="owl-carousel owl-theme testimonial-slider aos"
        data-aos="fade-up"
      >
        <!-- Review Widget -->
        <Carousel :wrap-around="true" :settings="settings" :breakpoints="breakpoints">
          <Slide v-for="item in About_Testimonial" :key="item.id">
            <div class="review-blog">
              <div class="review-top d-flex align-items-center">
                <div class="review-img">
                  <router-link to="/employers/review"
                    ><img
                      class="img-fluid"
                      :src="require(`@/assets/img/review/${item.Image}`)"
                      alt="Post Image"
                  /></router-link>
                </div>
                <div class="review-info">
                  <h3>
                    <router-link to="/employers/review">{{ item.Name }}</router-link>
                  </h3>
                  <h5>{{ item.Posting }}</h5>
                </div>
              </div>
              <div class="review-content">
                <p class="ms-0">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat orci
                  enim, mattis nibh aliquam dui, nibh faucibus aenean.
                </p>
                <div class="rating">
                  <i class="fas fa-star filled"></i>
                  <i class="fas fa-star filled"></i>
                  <i class="fas fa-star filled"></i>
                  <i class="fas fa-star filled"></i>
                  <i class="fas fa-star"></i>
                </div>
              </div>
            </div>
            <!-- / Review Widget -->
          </Slide>
          <template #addons>
            <Navigation />
          </template>
        </Carousel>
      </div>
    </div>
  </section>
</template>
<script>
import { Carousel, Navigation, Slide } from "vue3-carousel";
import About_Testimonial from "@/assets/json/about-testimonial.json";
import "vue3-carousel/dist/carousel.css";
export default {
  data() {
    return {
      About_Testimonial: About_Testimonial,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      breakpoints: {
        575: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        767: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        991: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        1024: {
          itemsToShow: 3,
          snapAlign: "start",
        },
      },
    };
  },
  components: {
    Carousel,
    Slide,
    Navigation,
  },
};
</script>
