<template>
  <section class="section projects">
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-sm-12 col-12 mx-auto text-center">
          <div class="section-header aos" data-aos="fade-up">
            <h2 class="header-title">What’s great about it?</h2>
            <p>All the features of kofejob below</p>
          </div>
        </div>
      </div>
      <div class="row row-gap">
        <!-- Feature Item -->
        <div
          class="col-xl-3 col-md-6 aos d-flex"
          v-for="item in IndexFourProjects"
          :key="item.id"
        >
          <div class="feature-items">
            <div class="feature-icon">
              <img
                :src="require(`@/assets/img/icon/${item.Img}`)"
                class="img-fluid"
                alt="Img"
              />
            </div>
            <div class="feature-content course-count">
              <h3>{{ item.Title }}</h3>
              <p>{{ item.Text }}</p>
            </div>
          </div>
        </div>
        <!-- /Feature Item -->
      </div>
    </div>
  </section>

  <!-- Update -->
  <section class="section feature-counts">
    <div class="container">
      <div class="row">
        <div class="col-12 aos" data-aos="fade-up">
          <div class="register-job-blk">
            <div class="job-content-blk text-center aos" data-aos="fade-up">
              <h2>Want to Get Special Offers & Updates?</h2>
              <p>
                Quisque pretium dolor turpis, quis blandit turpis semper ut. Nam malesuada
                eros nec luctus laoreet.
              </p>
            </div>
            <div class="home-four-viewall">
              <router-link to="/register" class="home-four-viewall-btn"
                >Register Kofejob <i class="feather-arrow-right ms-2"></i
              ></router-link>
              <router-link to="/post-project" class="home-four-viewall-btn"
                >Post a Project<i class="feather-arrow-right ms-2"></i
              ></router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Update -->
</template>
<script>
import IndexFourProjects from "@/assets/json/indexfourprojects.json";
// import AOS from "aos";
// import "aos/dist/aos.css";
export default {
  data() {
    return {
      IndexFourProjects: IndexFourProjects,
    };
  },
  // mounted() {
  //   this.$nextTick(() => {
  //     AOS.init();
  //   });
  // },
};
</script>
