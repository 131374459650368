<template>
  <!-- Header -->
  <div class="header">
    <!-- Logo -->
    <div class="header-left">
      <router-link to="/admin/index" class="logo">
        <img src="@/assets/admin_img/logo.png" alt="Logo" />
      </router-link>
      <router-link to="/admin/index" class="logo logo-small">
        <img src="@/assets/admin_img/logo-small.png" alt="Logo" width="30" height="30" />
      </router-link>
      <!-- Sidebar Toggle -->
      <a href="javascript:void(0);" id="toggle_btn" @click="toggleSidebar">
        <i class="feather-chevrons-left"></i>
      </a>
      <!-- /Sidebar Toggle -->

      <!-- Mobile Menu Toggle -->
      <a class="mobile_btn" id="mobile_btn" @click="toggleSidebar1">
        <i class="feather-chevrons-left"></i>
      </a>
      <!-- /Mobile Menu Toggle -->
    </div>
    <!-- /Logo -->

    <!-- Search -->
    <div class="top-nav-search">
      <form>
        <input
          type="text"
          class="form-control"
          placeholder="Start typing your Search..."
        />
        <button class="btn" type="submit"><i class="feather-search"></i></button>
      </form>
    </div>
    <!-- /Search -->

    <!-- Header Menu -->
    <ul class="nav user-menu">
      <!-- Notifications -->
      <li class="nav-item dropdown">
        <a href="javascript:;" class="dropdown-toggle nav-link" data-bs-toggle="dropdown">
          <i class="feather-bell"></i> <span class="badge badge-pill">5</span>
        </a>
        <div class="dropdown-menu notifications">
          <div class="topnav-dropdown-header">
            <span class="notification-title">Notifications</span>
            <a href="javascript:void(0)" class="clear-noti"> Clear All</a>
          </div>
          <div class="noti-content">
            <ul class="notification-list">
              <li class="notification-message">
                <a href="javascript:;">
                  <div class="media d-flex">
                    <span class="avatar avatar-sm flex-shrink-0">
                      <img
                        class="avatar-img rounded-circle"
                        alt="Image"
                        src="@/assets/admin_img/profiles/avatar-02.jpg"
                      />
                    </span>
                    <div class="media-body flex-grow-1">
                      <p class="noti-details">
                        <span class="noti-title">Brian Johnson</span> paid the invoice
                        <span class="noti-title">#DF65485</span>
                      </p>
                      <p class="noti-time">
                        <span class="notification-time">4 mins ago</span>
                      </p>
                    </div>
                  </div>
                </a>
              </li>
              <li class="notification-message">
                <a href="javascript:;">
                  <div class="media d-flex">
                    <span class="avatar avatar-sm flex-shrink-0">
                      <img
                        class="avatar-img rounded-circle"
                        alt="Image"
                        src="@/assets/admin_img/profiles/avatar-03.jpg"
                      />
                    </span>
                    <div class="media-body flex-grow-1">
                      <p class="noti-details">
                        <span class="noti-title">Marie Canales</span> has accepted your
                        estimate <span class="noti-title">#GTR458789</span>
                      </p>
                      <p class="noti-time">
                        <span class="notification-time">6 mins ago</span>
                      </p>
                    </div>
                  </div>
                </a>
              </li>
              <li class="notification-message">
                <a href="javascript:;">
                  <div class="media d-flex">
                    <div class="avatar avatar-sm flex-shrink-0">
                      <span class="avatar-title rounded-circle bg-primary-light"
                        ><i class="far fa-user"></i
                      ></span>
                    </div>
                    <div class="media-body flex-grow-1">
                      <p class="noti-details">
                        <span class="noti-title">New user registered</span>
                      </p>
                      <p class="noti-time">
                        <span class="notification-time">8 mins ago</span>
                      </p>
                    </div>
                  </div>
                </a>
              </li>
              <li class="notification-message">
                <a href="javascript:;">
                  <div class="media d-flex">
                    <span class="avatar avatar-sm flex-shrink-0">
                      <img
                        class="avatar-img rounded-circle"
                        alt="Image"
                        src="@/assets/admin_img/profiles/avatar-04.jpg"
                      />
                    </span>
                    <div class="media-body flex-grow-1">
                      <p class="noti-details">
                        <span class="noti-title">Barbara Moore</span> declined the invoice
                        <span class="noti-title">#RDW026896</span>
                      </p>
                      <p class="noti-time">
                        <span class="notification-time">12 mins ago</span>
                      </p>
                    </div>
                  </div>
                </a>
              </li>
              <li class="notification-message">
                <a href="javascript:;">
                  <div class="media d-flex">
                    <div class="avatar avatar-sm flex-shrink-0">
                      <span class="avatar-title rounded-circle bg-info-light"
                        ><i class="far fa-comment"></i
                      ></span>
                    </div>
                    <div class="media-body flex-grow-1">
                      <p class="noti-details">
                        <span class="noti-title">You have received a new message</span>
                      </p>
                      <p class="noti-time">
                        <span class="notification-time">2 days ago</span>
                      </p>
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </div>
          <div class="topnav-dropdown-footer">
            <a href="javascript:;">View all Notifications</a>
          </div>
        </div>
      </li>
      <!-- /Notifications -->

      <!-- User Menu -->
      <li class="nav-item dropdown has-arrow main-drop">
        <a href="javascript:;" class="dropdown-toggle nav-link" data-bs-toggle="dropdown">
          <span class="user-img">
            <img src="@/assets/admin_img/profiles/avatar-07.jpg" alt="Image" />
            <span class="status online"></span>
          </span>
        </a>
        <div class="dropdown-menu">
          <router-link class="dropdown-item" to="/admin/profile"
            ><i class="feather feather-user me-1"></i> Profile</router-link
          >
          <router-link class="dropdown-item" to="/admin/settings"
            ><i class="feather feather-settings me-1"></i> Settings</router-link
          >
          <router-link class="dropdown-item" to="/admin/login"
            ><i class="feather feather-log-out me-1"></i> Logout</router-link
          >
        </div>
      </li>
      <!-- /User Menu -->
    </ul>
    <!-- /Header Menu -->
  </div>
  <!-- /Header -->
</template>

<script>
export default {
  data() {
    return {
      isSidebarOpen: false,
    };
  },
  mounted() {
    document.addEventListener("mouseover", this.handleMouseOver);
  },
  beforeUnmount() {
    document.removeEventListener("mouseover", this.handleMouseOver);
  },
  methods: {
    toggleSidebar1() {
      const body = document.body; // Use document.body directly
      body.classList.toggle("slide-nav");
    },
    toggleSidebar() {
      const body = document.body; // Use document.body directly
      body.classList.toggle("mini-sidebar");
    },
    handleMouseOver(event) {
      event.stopPropagation();

      const body = document.body;
      const toggleBtn = document.getElementById("toggle_btn"); // Use getElementById
      const sidebar = document.querySelector(".sidebar");
      const subdropUL = document.querySelectorAll(".subdrop + ul");

      if (
        body.classList.contains("mini-sidebar") &&
        getComputedStyle(toggleBtn).display !== "none" // Use getComputedStyle
      ) {
        const target = event.target.closest(".sidebar");

        if (target) {
          body.classList.add("expand-menu");
          subdropUL.forEach((ul) => {
            ul.style.display = "block";
          });
        } else {
          body.classList.remove("expand-menu");
          subdropUL.forEach((ul) => {
            ul.style.display = "none";
          });
        }

        event.preventDefault();
      }
    },
  },
};
</script>
