<template>
  <!-- The Modal -->
  <div class="modal fade custom-modal" id="hire">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content bg-modal">
        <div class="modal-header">
          <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="text-center pt-0 mb-4">
            <img src="@/assets/img/logo-01.png" alt="logo" class="img-fluid mb-1" />
            <h5 class="modal-title">Discuss your project with David</h5>
          </div>
          <form action="dashboard">
            <div class="modal-info">
              <div class="row">
                <div class="col-12 col-md-12">
                  <div class="input-block">
                    <input
                      type="text"
                      name="name"
                      class="form-control"
                      placeholder="First name & Lastname"
                    />
                  </div>
                  <div class="input-block">
                    <input
                      type="email"
                      name="name"
                      class="form-control"
                      placeholder="Email Address"
                    />
                  </div>
                  <div class="input-block">
                    <input
                      type="text"
                      name="name"
                      class="form-control"
                      placeholder="Phone Number"
                    />
                  </div>
                  <div class="input-block">
                    <editor v-model="content" />
                  </div>
                  <div class="input-block row">
                    <div class="col-12 col-md-4 pr-0">
                      <label class="file-upload">
                        Add Attachment <input type="file" />
                      </label>
                    </div>
                    <div class="col-12 col-md-8">
                      <p class="mb-1">Allowed file types: zip, pdf, png, jpg</p>
                      <p>Max file size: 50 MB</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="submit-section text-center">
              <button type="submit" class="btn btn-primary btn-block submit-btn">
                Hire Now
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /The Modal -->
  <!-- The Modal -->
  <div class="modal fade" id="file">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Send Your Proposal</h4>
          <span class="modal-close"
            ><a href="javascript:void(0);" data-bs-dismiss="modal" aria-label="Close"
              ><i class="fa fa-times orange-text"></i></a
          ></span>
        </div>
        <div class="modal-body">
          <div class="modal-info proposal-modal-info">
            <form action="freelancer-project-proposals">
              <div class="feedback-form proposal-form">
                <div class="row">
                  <div class="col-md-6 input-block">
                    <label class="form-label">Your Price</label>
                    <input type="text" class="form-control" placeholder="Your Price" />
                  </div>
                  <div class="col-md-6 mb-3">
                    <label class="form-label">Estimated Delivery</label>
                    <div class="input-group">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Estimated Hours"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                      />
                      <span class="input-group-text" id="basic-addon2">Days</span>
                    </div>
                  </div>

                  <div class="col-md-12 input-block">
                    <label class="form-label">Cover Letter</label>
                    <textarea class="form-control summernote"></textarea>
                  </div>
                </div>
              </div>
              <div class="suggested-milestones-form">
                <div class="row">
                  <div class="col-md-4 input-block">
                    <label class="form-label">Milestone name</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Milestone name"
                    />
                  </div>
                  <div class="col-md-2 input-block floating-icon">
                    <label class="form-label">Amount</label>
                    <input type="text" class="form-control" placeholder="Amount" />
                    <span><i class="feather-dollar-sign"></i></span>
                  </div>
                  <div class="col-md-3 input-block floating-icon">
                    <label class="form-label">Start Date</label>
                    <input
                      type="date"
                      class="form-control datetimepicker"
                      placeholder="Choose"
                    />
                  </div>
                  <div class="col-md-3 input-block floating-icon">
                    <label class="form-label">End Date</label>
                    <input
                      type="date"
                      class="form-control datetimepicker"
                      placeholder="Choose"
                    />
                  </div>
                  <div class="col-md-12">
                    <div class="new-addd">
                      <a id="new_add1" class="add-new" @click="addcertify"
                        ><i class="feather-plus-circle"></i> Add New</a
                      >
                    </div>
                  </div>
                </div>
                <div id="add_row1">
                  <div class="row" v-for="(certify, index) in certify" :key="index">
                    <div class="col-md-4 input-block">
                      <label class="form-label">Milestone name</label>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Milestone name"
                      />
                    </div>
                    <div class="col-md-2 input-block floating-icon">
                      <label class="form-label">Amount</label>
                      <input type="text" class="form-control" placeholder="Amount" />
                      <span><i class="feather-dollar-sign"></i></span>
                    </div>
                    <div class="col-md-3 input-block floating-icon">
                      <label class="form-label">Start Date</label>
                      <input
                        type="date"
                        class="form-control datetimepicker"
                        placeholder="Choose"
                      />
                    </div>
                    <div class="col-md-3 input-block floating-icon">
                      <label class="form-label">End Date</label>
                      <input
                        type="date"
                        class="form-control datetimepicker"
                        placeholder="Choose"
                      />
                    </div>
                    <div class="new-addd">
                      <a id="remove_row" class="remove_row" @click="deletecertify(index)">
                        Remove</a
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="proposal-features">
                <div class="proposal-widget proposal-warning">
                  <label class="custom_check">
                    <input type="checkbox" name="select_time" checked /><span
                      class="checkmark"
                    ></span>
                    <span class="proposal-text">Stick this Proposal to the Top</span>
                    <span class="proposal-text float-end">$12.00</span>
                  </label>
                  <p>
                    The sticky proposal will always be displayed on top of all the
                    proposals.
                  </p>
                </div>
                <div class="proposal-widget proposal-blue">
                  <label class="custom_check">
                    <input type="checkbox" name="select_time" /><span
                      class="checkmark"
                    ></span>
                    <span class="proposal-text">Stick this Proposal to the Top</span>
                    <span class="proposal-text float-end">$12.00</span>
                  </label>
                  <p>
                    The sticky proposal will always be displayed on top of all the
                    proposals.
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 submit-section">
                  <label class="custom_check">
                    <input type="checkbox" name="select_time" />
                    <span class="checkmark"></span> I agree to the Terms And Conditions
                  </label>
                </div>
                <div class="col-md-12 submit-section text-end">
                  <a
                    data-bs-dismiss="modal"
                    href="javascript:void(0);"
                    class="btn btn-cancel submit-btn"
                    >Cancel</a
                  >
                  <a
                    data-bs-toggle="modal"
                    data-bs-dismiss="modal"
                    href="#success"
                    class="btn btn-primary submit-btn"
                    >Send Proposal</a
                  >
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /The Modal -->

  <!-- The Modal -->
  <div class="modal fade custom-modal" id="success">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content proposal-modal-info">
        <div class="modal-header">
          <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="text-center modal-body-content pt-0">
            <h5 class="modal-title">Submitted Successfully</h5>
            <p>You will be Notified once, your Proposal approves.</p>
          </div>
          <div class="col-md-12 submit-section text-center">
            <router-link
              data-bs-dismiss="modal"
              to="/freelancer/freelancer-dashboard"
              class="btn btn-primary submit-btn"
              >Go to Dashboard</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /The Modal -->
</template>
<script>
import { ref } from "vue";
const currentDate = ref(new Date());
const currentDateOne = ref(new Date());
import Editor from "@tinymce/tinymce-vue";
export default {
  components: {
    editor: Editor,
  },
  data() {
    return {
      content: "Description",
      startdate: currentDate,
      startdateOne: currentDateOne,
      certify: [],
    };
  },
  methods: {
    addcertify() {
      this.certify.push({});
    },
    deletecertify(index) {
      this.certify.splice(index, 1);
    },
  },
};
</script>
