<template>
  <!-- Breadcrumb -->
  <div class="bread-crumb-bar">
    <div class="container">
      <div class="row align-items-center inner-banner">
        <div class="col-md-12 col-12 text-center">
          <div class="breadcrumb-list">
            <nav aria-label="breadcrumb" class="page-breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <router-link to="/home/"
                    ><img src="@/assets/img/home-icon.svg" alt="Img" />
                    {{ title }}</router-link
                  >
                </li>
                <li class="breadcrumb-item" aria-current="page">{{ text }}</li>
                <li class="breadcrumb-item active" aria-current="page">
                  {{ text1 }}
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Breadcrumb -->
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
    text1: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
};
</script>
