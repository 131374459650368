<template>
	<!-- The Modal -->
	<div class="modal fade edit-proposal-modal" id="write-review">
		<div class="modal-dialog modal-dialog-centered modal-md">
			<div class="modal-content">
				<div class="modal-header">
					<h4 class="modal-title">Write a Review</h4>
					<span class="modal-close"><a href="javascript:void(0);" data-bs-dismiss="modal" aria-label="Close"><i
								class="feather-x"></i></a></span>
				</div>
				<div class="modal-body">
					<form action="#">
						<div class="modal-info">
							<div class="reviewed-user">
								<img src="@/assets/img/user/table-avatar-02.jpg" alt="Img">
								<span>Bolethe Fleischer</span>
							</div>
							<div class="input-block form-info">
								<label class="col-form-label mb-0 mt-2">Ratings</label>
								<div class="rating rating-select mb-0">
									<a href="javascript:void(0);"><i class="fas fa-star"></i></a>
									<a href="javascript:void(0);"><i class="fas fa-star"></i></a>
									<a href="javascript:void(0);"><i class="fas fa-star"></i></a>
									<a href="javascript:void(0);"><i class="fas fa-star"></i></a>
									<a href="javascript:void(0);"><i class="fas fa-star"></i></a>
								</div>
							</div>
							<div class="input-block">
								<label class="form-label">Description</label>
								<textarea class="form-control"></textarea>
							</div>
						</div>
						<div class="submit-section text-end">
							<a href="javascript:void(0);" class="btn btn-cancel">Cancel</a>
							<button type="submit" class="btn btn-primary submit-btn">Submit</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
	<!-- /The Modal -->

	<!-- The Modal -->
	<div class="modal fade edit-proposal-modal" id="write-review">
		<div class="modal-dialog modal-dialog-centered modal-md">
			<div class="modal-content">
				<div class="modal-header">
					<h4 class="modal-title">Write a Review</h4>
					<span class="modal-close"><a href="javascript:void(0);" data-bs-dismiss="modal" aria-label="Close"><i
								class="feather-x"></i></a></span>
				</div>
				<div class="modal-body">
					<form action="#">
						<div class="modal-info">
							<div class="reviewed-user">
								<img src="@/assets/img/user/table-avatar-02.jpg" alt="Img">
								<span>Bolethe Fleischer</span>
							</div>
							<div class="input-block form-info">
								<label class="col-form-label mb-0 mt-2">Ratings</label>
								<div class="rating rating-select mb-0">
									<a href="javascript:void(0);"><i class="fas fa-star"></i></a>
									<a href="javascript:void(0);"><i class="fas fa-star"></i></a>
									<a href="javascript:void(0);"><i class="fas fa-star"></i></a>
									<a href="javascript:void(0);"><i class="fas fa-star"></i></a>
									<a href="javascript:void(0);"><i class="fas fa-star"></i></a>
								</div>
							</div>
							<div class="input-block">
								<label class="form-label">Description</label>
								<textarea class="form-control summernote"></textarea>
							</div>
						</div>
						<div class="submit-section text-end">
							<a href="javascript:void(0);" data-bs-dismiss="modal" class="btn btn-cancel">Cancel</a>
							<button type="submit" class="btn btn-primary submit-btn">Submit</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
	<!-- /The Modal -->

	<!-- The Modal -->
	<div class="modal fade" id="bookmark">
		<div class="modal-dialog modal-dialog-centered modal-md">
			<div class="modal-content">
				<div class="modal-header">
					<h4 class="modal-title">INVITE FREELANCER</h4>
					<span class="modal-close"><a href="javascript:void(0);" data-bs-dismiss="modal" aria-label="Close"><i
								class="far fa-times-circle orange-text"></i></a></span>
				</div>
				<div class="modal-body">
					<form action="invited-freelancer">
						<div class="modal-info">
							<div class="row">
								<div class="col-md-12">
									<div class="average-bids mb-4">
										<p>Contact <span class="text-highlight">John Doe </span> about your Project</p>
									</div>
									<div class="input-block">
										<label class="form-label">Invite title</label>
										<input type="text" class="form-control">
									</div>
								</div>
								<div class="col-md-12">
									<div class="input-block">
										<label class="form-label">Send a private message</label>
										<textarea class="form-control"
											rows="5">Hi  John Doe, I noticed your profile and would like to offer you my project. We can discuss any details over chat.</textarea>
									</div>
								</div>
								<div class="col-md-6">
									<div class="input-block">
										<label class="form-label">Hire for</label>
										<div class="radio">
											<label class="custom_radio me-2">
												<input type="radio" value="fix" checked>
												<span class="checkmark"></span> Fixed Price
											</label>
											<label class="custom_radio">
												<input type="radio" value="fix">
												<span class="checkmark"></span> Hourly Rate
											</label>
										</div>
									</div>
								</div>
								<div class="col-md-6">
									<div class="input-block">
										<label class="form-label">Hire for</label>
										<div class="radio">
											<label class="custom_radio me-2">
												<input type="radio" value="rate" checked>
												<span class="checkmark"></span> Fixed Price
											</label>
											<label class="custom_radio">
												<input type="radio" value="rate">
												<span class="checkmark"></span> Hourly Rate
											</label>
										</div>
									</div>
								</div>
								<div class="col-md-6">
									<div class="input-block">
										<label class="form-label">Budget</label>
										<div class="form-row">
											<div class="col-7">
												<div class="input-group">
													<div class="input-group-prepend bg-pink d-flex">
														<span class="input-group-text" id="basic-addon1">$</span>
													</div>
													<input type="text" class="form-control" placeholder="20.00">
												</div>
											</div>
											<div class="col-5">
												<vue-select :options="USD" id="amount" placeholder="USD" />
											</div>
										</div>
									</div>
								</div>
								<div class="col-md-6">
									<div class="input-block">
										<label class="form-label">Budget</label>
										<div class="form-row">
											<div class="col-sm-7">
												<div class="input-group form-inline mb-2">
													<div class="input-group-prepend bg-pink d-flex">
														<span class="input-group-text" id="basic-add">$</span>
													</div>
													<input type="text" class="form-control me-1" placeholder="20.00">
													<label class="invited-freelancer-label">/ hr</label>
												</div>
											</div>
											<div class="col-sm-5">
												<vue-select :options="USD" id="amountone" placeholder="USD" />
											</div>
										</div>
									</div>
								</div>
								<div class="col-md-12">
									<div class="input-block">
										<label class="form-label">Project</label>
										<vue-select :options="Renders" id="rendersone" placeholder="USD" />
									</div>
								</div>
							</div>
						</div>
						<div class="submit-section text-end">
							<button type="submit" class="btn btn-primary submit-btn">Send Invite</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
	<!-- /The Modal -->

	<!-- The Modal -->
	<div class="modal fade" id="rating">
		<div class="modal-dialog modal-dialog-centered modal-md">
			<div class="modal-content">
				<div class="modal-header d-block b-0 pb-0">
					<span class="modal-close float-end"><a href="javascript:void(0);" data-bs-dismiss="modal"
							aria-label="Close"><i class="far fa-times-circle orange-text"></i></a></span>
				</div>
				<div class="modal-body">
					<form action="project">
						<div class="modal-info">
							<div class="text-center pt-0 mb-5">
								<h3>Please login to Favourite Freelancers</h3>
							</div>
							<div class="submit-section text-center">
								<button data-bs-dismiss="modal" class="btn btn-primary black-btn click-btn">Cancel</button>
								<button type="submit" class="btn btn-primary click-btn">Submit</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
	<!-- /The Modal -->

	<!-- The Modal -->
	<div class="modal fade" id="bookmark">
		<div class="modal-dialog modal-dialog-centered modal-md">
			<div class="modal-content">
				<div class="modal-header">
					<h4 class="modal-title">INVITE FREELANCER</h4>
					<span class="modal-close"><a href="javascript:void(0);" data-bs-dismiss="modal" aria-label="Close"><i
								class="far fa-times-circle orange-text"></i></a></span>
				</div>
				<div class="modal-body">
					<form action="invited-freelancer">
						<div class="modal-info">
							<div class="row">
								<div class="col-md-12">
									<div class="average-bids mb-4">
										<p>Contact <span class="text-highlight">John Doe </span> about your Project</p>
									</div>
									<div class="input-block">
										<label class="form-label">Invite title</label>
										<input type="text" class="form-control">
									</div>
								</div>
								<div class="col-md-12">
									<div class="input-block">
										<label class="form-label">Send a private message</label>
										<textarea class="form-control"
											rows="5">Hi  John Doe, I noticed your profile and would like to offer you my project. We can discuss any details over chat.</textarea>
									</div>
								</div>
								<div class="col-md-6">
									<div class="input-block">
										<label class="form-label">Hire for</label>
										<div class="radio">
											<label class="custom_radio me-2">
												<input type="radio" value="fix" checked>
												<span class="checkmark"></span> Fixed Price
											</label>
											<label class="custom_radio">
												<input type="radio" value="fix">
												<span class="checkmark"></span> Hourly Rate
											</label>
										</div>
									</div>
								</div>
								<div class="col-md-6">
									<div class="input-block">
										<label class="form-label">Hire for</label>
										<div class="radio">
											<label class="custom_radio me-2">
												<input type="radio" value="rate" checked>
												<span class="checkmark"></span> Fixed Price
											</label>
											<label class="custom_radio">
												<input type="radio" value="rate">
												<span class="checkmark"></span> Hourly Rate
											</label>
										</div>
									</div>
								</div>
								<div class="col-md-6">
									<div class="input-block">
										<label class="form-label">Budget</label>
										<div class="form-row">
											<div class="col-7">
												<div class="input-group">
													<div class="input-group-prepend bg-pink d-flex">
														<span class="input-group-text" id="basic-addon1">$</span>
													</div>
													<input type="text" class="form-control" placeholder="20.00">
												</div>
											</div>
											<div class="col-5">
												<vue-select :options="USD" id="amounttwo" placeholder="USD" />
											</div>
										</div>
									</div>
								</div>
								<div class="col-md-6">
									<div class="input-block">
										<label class="form-label">Budget</label>
										<div class="form-row">
											<div class="col-sm-7">
												<div class="input-group form-inline mb-2">
													<div class="input-group-prepend bg-pink d-flex">
														<span class="input-group-text" id="basic-add">$</span>
													</div>
													<input type="text" class="form-control me-1" placeholder="20.00">
													<label class="invited-freelancer-label">/ hr</label>
												</div>
											</div>
											<div class="col-sm-5">
												<vue-select :options="USD" id="amountthree" placeholder="USD" />
											</div>
										</div>
									</div>
								</div>
								<div class="col-md-12">
									<div class="input-block">
										<label class="form-label">Project</label>
										<vue-select :options="Renders" id="renders"
											placeholder="3D Renders and Amazon Product Store images/Video" />
									</div>
								</div>
							</div>
						</div>
						<div class="submit-section text-end">
							<button type="submit" class="btn btn-primary submit-btn">Send Invite</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
	<!-- /The Modal -->

	<!-- The Modal -->
	<div class="modal fade" id="rating">
		<div class="modal-dialog modal-dialog-centered modal-md">
			<div class="modal-content">
				<div class="modal-header d-block b-0 pb-0">
					<span class="modal-close float-end"><a href="javascript:void(0);" data-bs-dismiss="modal"
							aria-label="Close"><i class="far fa-times-circle orange-text"></i></a></span>
				</div>
				<div class="modal-body">
					<form action="project">
						<div class="modal-info">
							<div class="text-center pt-0 mb-5">
								<h3>Please login to Favourite Freelancers</h3>
							</div>
							<div class="submit-section text-center">
								<button data-bs-dismiss="modal" class="btn btn-primary black-btn click-btn">Cancel</button>
								<button type="submit" class="btn btn-primary click-btn">Submit</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
	<!-- /The Modal -->

	<!-- Voice Call Modal -->
	<div class="modal fade call-modal" id="voice_call">
		<div class="modal-dialog modal-dialog-centered" role="document">
			<div class="modal-content">
				<div class="modal-body">
					<!-- Outgoing Call -->
					<div class="call-box incoming-box">
						<div class="call-wrapper">
							<div class="call-inner">
								<div class="call-user">
									<img alt="User Image" src="@/assets/img/img-05.jpg" class="call-avatar">
									<h4>Darren Elder</h4>
									<span>Connecting...</span>
								</div>
								<div class="call-items">
									<a href="javascript:void(0);" class="btn call-item call-end" data-bs-dismiss="modal"
										aria-label="Close"><i class="material-icons">call_end</i></a>
									<a href="javascript:void(0);" class="btn call-item call-start"><i
											class="material-icons">call</i></a>
								</div>
							</div>
						</div>
					</div>
					<!-- Outgoing Call -->
				</div>
			</div>
		</div>
	</div>
	<!-- /Voice Call Modal -->

	<!-- Video Call Modal -->
	<div class="modal fade call-modal" id="video_call">
		<div class="modal-dialog modal-dialog-centered" role="document">
			<div class="modal-content">
				<div class="modal-body">

					<!-- Incoming Call -->
					<div class="call-box incoming-box">
						<div class="call-wrapper">
							<div class="call-inner">
								<div class="call-user">
									<img class="call-avatar" src="@/assets/img/img-05.jpg" alt="User Image">
									<h4>Darren Elder</h4>
									<span>Calling ...</span>
								</div>
								<div class="call-items">
									<a href="javascript:void(0);" class="btn call-item call-end" data-bs-dismiss="modal"
										aria-label="Close"><i class="material-icons">call_end</i></a>
									<a href="javascript:void(0);" class="btn call-item call-start"><i
											class="material-icons">videocam</i></a>
								</div>
							</div>
						</div>
					</div>
					<!-- /Incoming Call -->

				</div>
			</div>
		</div>
	</div>
	<!-- Video Call Modal -->
</template>

<script>
export default {
	data() {
		return {
			USD: ["USD", "USD"],
			Renders: ["3D Renders and Amazon Product Store images/Video", "3D Renders and Amazon Product Store Video", "3D Renders and Amazon Product Store images"]
		}
	},
}
</script>
