<template>
  <!-- Blog Sidebar -->
  <div class="col-lg-4 col-md-12 sidebar-right theiaStickySidebar project-client-view">
    <div class="stickysidebar">
      <div class="card budget-widget">
        <div class="budget-widget-details">
          <h6>Budget</h6>
          <h4>$125 - $180</h4>
          <p class="mb-0">Hourly Rate</p>
        </div>
        <div>
          <a data-bs-toggle="modal" href="#file" class="btn proposal-btn btn-primary"
            >Submit Proposal
          </a>
        </div>
      </div>
      <div class="card budget-widget">
        <div class="budget-widget-details">
          <h6>About Client</h6>
          <div class="company-detail-image">
            <img src="@/assets/img/default-logo.svg" class="img-fluid" alt="logo" />
          </div>
          <h5>KIND SOFTWARES</h5>
          <span>Member Since December 31, 2020</span>
          <div class="rating mb-3">
            <i class="fas fa-star filled"></i>
            <i class="fas fa-star filled"></i>
            <i class="fas fa-star filled"></i>
            <i class="fas fa-star filled"></i>
            <i class="fas fa-star filled"></i>
            <span class="average-rating">5.0</span>
          </div>
          <ul class="d-flex list-style mb-0 social-list">
            <li>
              <a href="javascript:void(0);" class="social-link-block">
                <i class="fa-brands fa-facebook-f"></i>
              </a>
            </li>
            <li>
              <a href="javascript:void(0);" class="social-link-block">
                <i class="fab fa-twitter"></i>
              </a>
            </li>
            <li>
              <a href="javascript:void(0);" class="social-link-block">
                <i class="fa-brands fa-linkedin-in"></i>
              </a>
            </li>
            <li>
              <a href="javascript:void(0);" class="social-link-block">
                <i class="fa-brands fa-instagram"></i>
              </a>
            </li>
          </ul>
          <ul class="d-flex list-style mb-0 client-detail-list">
            <li>
              <span>Departments</span>
              <p class="mb-0">Designer</p>
            </li>
            <li>
              <span>Employees</span>
              <p class="mb-0">30-50</p>
            </li>
          </ul>
        </div>
        <div>
          <a href="javascript:void(0);" class="btn btn-primary price-btn btn-block"
            >Contact Me
          </a>
        </div>
      </div>
      <div class="card budget-widget">
        <ul class="d-flex mb-0 list-style job-list-block">
          <li>
            <span>Jobs Posted</span>
            <p class="mb-0">48</p>
          </li>
          <li>
            <span>Hire Rate</span>
            <p class="mb-0">22</p>
          </li>
          <li>
            <span>Open Jobs</span>
            <p class="mb-0">75</p>
          </li>
          <li>
            <span>Total Spent</span>
            <p class="mb-0">22</p>
          </li>
          <li>
            <span>Hired</span>
            <p class="mb-0">64</p>
          </li>
          <li>
            <span>Active</span>
            <p class="mb-0">29</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!-- /Blog Sidebar -->
</template>
