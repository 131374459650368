<template>
  <!-- The Modal -->
  <div class="modal fade" id="bookmark">
    <div class="modal-dialog modal-dialog-centered modal-md">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">INVITE FREELANCER</h4>
          <span class="modal-close"
            ><a href="javascript:void(0);" data-bs-dismiss="modal" aria-label="Close"
              ><i class="far fa-times-circle orange-text"></i></a
          ></span>
        </div>
        <div class="modal-body">
          <form action="invited-freelancer">
            <div class="modal-info">
              <div class="row">
                <div class="col-md-12">
                  <div class="average-bids mb-4">
                    <p>
                      Contact <span class="text-highlight">John Doe </span> about your
                      Project
                    </p>
                  </div>
                  <div class="input-block">
                    <label class="form-label">Invite title</label>
                    <input type="text" class="form-control" />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="input-block">
                    <label class="form-label">Send a private message</label>
                    <textarea class="form-control" rows="5">
Hi  John Doe, I noticed your profile and would like to offer you my project. We can discuss any details over chat.</textarea
                    >
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-block">
                    <label class="form-label">Hire for</label>
                    <div class="radio">
                      <label class="custom_radio me-2">
                        <input type="radio" value="fix" checked />
                        <span class="checkmark"></span> Fixed Price
                      </label>
                      <label class="custom_radio">
                        <input type="radio" value="fix" />
                        <span class="checkmark"></span> Hourly Rate
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-block">
                    <label class="form-label">Hire for</label>
                    <div class="radio">
                      <label class="custom_radio me-2">
                        <input type="radio" value="rate" checked />
                        <span class="checkmark"></span> Fixed Price
                      </label>
                      <label class="custom_radio">
                        <input type="radio" value="rate" />
                        <span class="checkmark"></span> Hourly Rate
                      </label>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-block">
                    <label class="form-label">Budget</label>
                    <div class="form-row">
                      <div class="col-7">
                        <div class="input-group">
                          <div class="input-group-prepend bg-pink d-flex">
                            <span class="input-group-text" id="basic-addon1">$</span>
                          </div>
                          <input type="text" class="form-control" placeholder="20.00" />
                        </div>
                      </div>
                      <div class="col-5">
                        <vue-select 
                          :options="USD"
                          id="usdtwo"
                          placeholder="USD"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-block">
                    <label class="form-label">Budget</label>
                    <div class="form-row">
                      <div class="col-sm-7">
                        <div class="input-group form-inline mb-2">
                          <div class="input-group-prepend bg-pink d-flex">
                            <span class="input-group-text" id="basic-add">$</span>
                          </div>
                          <input
                            type="text"
                            class="form-control me-1"
                            placeholder="20.00"
                          />
                          <label class="invited-freelancer-label">/ hr</label>
                        </div>
                      </div>
                      <div class="col-sm-5">
                        <vue-select 
                          :options="USD"
                          id="price"
                          placeholder="USD"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="input-block">
                    <label class="form-label">Project</label>
                    <vue-select 
                      :options="Project"
                      id="projectone"
                      placeholder="3D Renders and Amazon Product Store images/Video"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="submit-section text-end">
              <button type="submit" class="btn btn-primary submit-btn">
                Send Invite
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /The Modal -->

  <!-- The Modal -->
  <div class="modal fade" id="rating">
    <div class="modal-dialog modal-dialog-centered modal-md">
      <div class="modal-content">
        <div class="modal-header d-block b-0 pb-0">
          <span class="modal-close float-end"
            ><a href="javascript:void(0);" data-bs-dismiss="modal" aria-label="Close"
              ><i class="far fa-times-circle orange-text"></i></a
          ></span>
        </div>
        <div class="modal-body">
          <form action="project">
            <div class="modal-info">
              <div class="text-center pt-0 mb-5">
                <h3>Please login to Favourite Freelancers</h3>
              </div>
              <div class="submit-section text-center">
                <button
                  data-bs-dismiss="modal"
                  class="btn btn-primary black-btn click-btn"
                >
                  Cancel
                </button>
                <button type="submit" class="btn btn-primary click-btn">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /The Modal -->
</template>

<script>
export default {
  data() {
    return {
      USD: ["USD", "USD"],
      Project: ["3D Renders and Amazon Product Store images/Video", "3D Renders and Amazon Product Store Video", "3D Renders and Amazon Product Store images"]
    }
  },
}
</script>