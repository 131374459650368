<template>
    <!-- Feature -->
    <section class="section feature-project home-two-projects">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-sm-12 col-12 mx-auto">
                    <div class="section-header section-header-two text-center aos" data-aos="fade-up">
                        <h2 class="header-title">Featured Projects <span> For You</span></h2>
                        <p>We have over 2000+ Projects waiting for you</p>
                    </div>
                </div>
            </div>
            <div class="row">

                <!--- Project Item  -->
                <div class="col-lg-4 col-md-6 d-flex">
                    <div class="project-item feature-project-item aos" data-aos="fade-up">
                        <div class="project-img">
                            <router-link to="/freelancer/project"><img src="@/assets/img/project/project-19.jpg" alt="Img"
                                    class="img-fluid"></router-link>
                        </div>
                        <div class="feature-content">
                            <div class="feature-time-blk">
                                <a href="javascript:void(0);" class="btn btn-primary green-active">Full Time</a>
                                <span><i class="far fa-clock me-1"></i> 1 min ago</span>
                            </div>
                            <h4><router-link to="/freelancer/project">3D Renders and Amazon Product</router-link></h4>
                            <ul class="feature-project-list nav">
                                <li><i class="feather-user me-1"></i>UI/UX Designer</li>
                                <li><i class="feather-map-pin me-1"></i>Germany</li>
                            </ul>
                            <div class="feature-foot">
                                <div class="logo-company">
                                    <router-link to="/freelancer/project">
                                        <img src="@/assets/img/icon/logo-icon-01.svg" class="me-1" alt="icon">
                                        <span>AMAZE TECH</span>
                                    </router-link>
                                </div>
                                <router-link to="/freelancer/project-details" class="bid-now">Bid Now <i
                                        class="feather-arrow-right ms-1"></i></router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <!--- /Project Item  -->

                <!--- Project Item  -->
                <div class="col-lg-4 col-md-6 d-flex">
                    <div class="project-item feature-project-item aos" data-aos="fade-up">
                        <div class="project-img">
                            <router-link to="/freelancer/project"><img src="@/assets/img/project/project-20.jpg" alt="Img"
                                    class="img-fluid"></router-link>
                        </div>
                        <div class="feature-content">
                            <div class="feature-time-blk">
                                <a href="javascript:void(0);" class="btn btn-primary sky-active">Part Time</a>
                                <span><i class="far fa-clock me-1"></i> 10 min ago</span>
                            </div>
                            <h4><router-link to="/freelancer/project">Web Development for a Shopify Store</router-link></h4>
                            <ul class="feature-project-list nav">
                                <li><i class="feather-user me-1"></i>Web Development</li>
                                <li><i class="feather-map-pin me-1"></i>Kentucky, USA</li>
                            </ul>
                            <div class="feature-foot">
                                <div class="logo-company">
                                    <router-link to="/freelancer/project">
                                        <img src="@/assets/img/icon/logo-icon-02.svg" class="me-1" alt="icon">
                                        <span>PARK INC</span>
                                    </router-link>
                                </div>
                                <router-link to="/freelancer/project-details" class="bid-now">Bid Now <i
                                        class="feather-arrow-right ms-1"></i></router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <!--- /Project Item  -->

                <!--- Project Item  -->
                <div class="col-lg-4 col-md-6 d-flex">
                    <div class="project-item feature-project-item aos" data-aos="fade-up">
                        <div class="project-img">
                            <router-link to="/freelancer/project"><img src="@/assets/img/project/project-21.jpg" alt="Img"
                                    class="img-fluid"></router-link>
                        </div>
                        <div class="feature-content">
                            <div class="feature-time-blk">
                                <a href="javascript:void(0);" class="btn btn-primary green-active">Full Time</a>
                                <span><i class="far fa-clock me-1"></i> 5 min ago</span>
                            </div>
                            <h4><router-link to="/freelancer/project">Website Design for a Consumer Shop</router-link></h4>
                            <ul class="feature-project-list nav">
                                <li><i class="feather-user me-1"></i>Angular Developer</li>
                                <li><i class="feather-map-pin me-1"></i>Delaware, USA</li>
                            </ul>
                            <div class="feature-foot">
                                <div class="logo-company">
                                    <router-link to="/freelancer/project">
                                        <img src="@/assets/img/icon/logo-icon-01.svg" class="me-1" alt="icon">
                                        <span>AMAZE TECH</span>
                                    </router-link>
                                </div>
                                <router-link to="/freelancer/project-details" class="bid-now">Bid Now <i
                                        class="feather-arrow-right ms-1"></i></router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <!--- /Project Item  -->

                <!--- Project Item  -->
                <div class="col-lg-4 col-md-6 d-flex">
                    <div class="project-item feature-project-item aos" data-aos="fade-up">
                        <div class="project-img">
                            <router-link to="/freelancer/project"><img src="@/assets/img/project/project-22.jpg" alt="Img"
                                    class="img-fluid"></router-link>
                        </div>
                        <div class="feature-content">
                            <div class="feature-time-blk">
                                <a href="javascript:void(0);" class="btn btn-primary green-active">Full Time</a>
                                <span><i class="far fa-clock me-1"></i> 6 min ago</span>
                            </div>
                            <h4><router-link to="/freelancer/project">UI/UX for Cryptocurrency Exchange</router-link></h4>
                            <ul class="feature-project-list nav">
                                <li><i class="feather-user me-1"></i>Flutter Development</li>
                                <li><i class="feather-map-pin me-1"></i>Germany</li>
                            </ul>
                            <div class="feature-foot">
                                <div class="logo-company">
                                    <router-link to="/freelancer/project">
                                        <img src="@/assets/img/icon/logo-icon-03.svg" class="me-1" alt="icon">
                                        <span>AMAZE TECH</span>
                                    </router-link>
                                </div>
                                <router-link to="/freelancer/project-details" class="bid-now">Bid Now <i
                                        class="feather-arrow-right ms-1"></i></router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <!--- /Project Item  -->

                <!--- Project Item  -->
                <div class="col-lg-4 col-md-6 d-flex">
                    <div class="project-item feature-project-item aos" data-aos="fade-up">
                        <div class="project-img">
                            <router-link to="/freelancer/project"><img src="@/assets/img/project/project-23.jpg" alt="Img"
                                    class="img-fluid"></router-link>
                        </div>
                        <div class="feature-content">
                            <div class="feature-time-blk">
                                <a href="javascript:void(0);" class="btn btn-primary sky-active">Part Time</a>
                                <span><i class="far fa-clock me-1"></i> 2 min ago</span>
                            </div>
                            <h4><router-link to="/freelancer/project">Landing Page Redesign / Sales Page</router-link> </h4>
                            <ul class="feature-project-list nav">
                                <li><i class="feather-user me-1"></i>Web Development</li>
                                <li><i class="feather-map-pin me-1"></i>Kentucky, USA</li>
                            </ul>
                            <div class="feature-foot">
                                <div class="logo-company">
                                    <router-link to="/freelancer/project">
                                        <img src="@/assets/img/icon/logo-icon-02.svg" class="me-1" alt="icon">
                                        <span>ABC SOFTWARE</span>
                                    </router-link>
                                </div>
                                <router-link to="/freelancer/project-details" class="bid-now">Bid Now <i
                                        class="feather-arrow-right ms-1"></i></router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <!--- /Project Item  -->

                <!--- Project Item  -->
                <div class="col-lg-4 col-md-6 d-flex">
                    <div class="project-item feature-project-item aos" data-aos="fade-up">
                        <div class="project-img">
                            <router-link to="/freelancer/project"><img src="@/assets/img/project/project-24.jpg" alt="Img"
                                    class="img-fluid"></router-link>
                        </div>
                        <div class="feature-content">
                            <div class="feature-time-blk">
                                <a href="javascript:void(0);" class="btn btn-primary green-active">Full Time</a>
                                <span><i class="far fa-clock me-1"></i> 1 min ago</span>
                            </div>
                            <h4><router-link to="/freelancer/project">Build a Coaching Website Product</router-link> </h4>
                            <ul class="feature-project-list nav">
                                <li><i class="feather-user me-1"></i>Angular Developer</li>
                                <li><i class="feather-map-pin me-1"></i>Delaware, USA</li>
                            </ul>
                            <div class="feature-foot">
                                <div class="logo-company">
                                    <router-link to="/freelancer/project">
                                        <img src="@/assets/img/icon/logo-icon-04.svg" class="me-1" alt="icon">
                                        <span>AMAZE TECH</span>
                                    </router-link>
                                </div>
                                <router-link to="/freelancer/project-details" class="bid-now">Bid Now <i
                                        class="feather-arrow-right ms-1"></i></router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <!--- /Project Item  -->

                <div class="col-xl-12">
                    <div class="more-project text-center aos" data-aos="fade-up">
                        <router-link to="/freelancer/project" class="btn btn-primary">View More Projects</router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--- /Feature  -->

    <!-- Feature Developer -->
    <section class="section feature-developer home-two-developers">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-sm-12 col-12 mx-auto">
                    <div class="section-header section-header-two text-center aos" data-aos="fade-up">
                        <h2 class="header-title">Featured <span>Developers</span></h2>
                        <p>We have over 1400+ Developers </p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-3 col-md-6">
                    <div class="feature-develop-blk aos" data-aos="fade-up">
                        <div class="developer-sub-blk">
                            <div class="img-developer">
                                <router-link to="/employers/developer-details"><img src="@/assets/img/user/avatar-21.jpg" class="me-2"
                                        alt="Img"></router-link>
                            </div>
                            <div class="developer-content">
                                <h4><router-link to="/employers/developer-details">Dran Gonzalez</router-link></h4>
                                <p>React Developer</p>
                                <div class="rating">
                                    <i class="fas fa-star filled"></i>
                                    <i class="fas fa-star filled"></i>
                                    <i class="fas fa-star filled"></i>
                                    <i class="fas fa-star filled"></i>
                                    <i class="fas fa-star filled"></i>
                                    <span class="average-rating">5.0 (30)</span>
                                </div>
                            </div>
                        </div>
                        <div class="hour-rate">
                            <ul class="nav">
                                <li><i class="feather-dollar-sign me-1"></i>$34 Hourly</li>
                                <li><i class="feather-map-pin me-1"></i>Florida, USA</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-md-6">
                    <div class="feature-develop-blk aos" data-aos="fade-up">
                        <div class="developer-sub-blk">
                            <div class="img-developer">
                                <router-link to="/employers/developer-details"><img src="@/assets/img/user/avatar-22.jpg" class="me-2"
                                        alt="Img"></router-link>
                            </div>
                            <div class="developer-content">
                                <h4><router-link to="/employers/developer-details">Nicole Black</router-link></h4>
                                <p>Angular Developer</p>
                                <div class="rating">
                                    <i class="fas fa-star filled"></i>
                                    <i class="fas fa-star filled"></i>
                                    <i class="fas fa-star filled"></i>
                                    <i class="fas fa-star filled"></i>
                                    <i class="fas fa-star filled"></i>
                                    <span class="average-rating">5.0 (30)</span>
                                </div>
                            </div>
                        </div>
                        <div class="hour-rate">
                            <ul class="nav">
                                <li><i class="feather-dollar-sign me-1"></i>$32 Hourly</li>
                                <li><i class="feather-map-pin me-1"></i>Nevada, USA</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-md-6">
                    <div class="feature-develop-blk aos" data-aos="fade-up">
                        <div class="developer-sub-blk">
                            <div class="img-developer">
                                <router-link to="/employers/developer-details"><img src="@/assets/img/user/avatar-23.jpg" class="me-2"
                                        alt="Img"></router-link>
                            </div>
                            <div class="developer-content">
                                <h4><router-link to="/employers/developer-details">Megan Torres</router-link></h4>
                                <p>Java Developer</p>
                                <div class="rating">
                                    <i class="fas fa-star filled"></i>
                                    <i class="fas fa-star filled"></i>
                                    <i class="fas fa-star filled"></i>
                                    <i class="fas fa-star filled"></i>
                                    <i class="fas fa-star filled"></i>
                                    <span class="average-rating">5.0 (30)</span>
                                </div>
                            </div>
                        </div>
                        <div class="hour-rate">
                            <ul class="nav">
                                <li><i class="feather-dollar-sign me-1"></i>$24 Hourly</li>
                                <li><i class="feather-map-pin me-1"></i>Delaware, USA</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-md-6">
                    <div class="feature-develop-blk aos" data-aos="fade-up">
                        <div class="developer-sub-blk">
                            <div class="img-developer">
                                <router-link to="/employers/developer-details"><img src="@/assets/img/user/avatar-24.jpg" class="me-2"
                                        alt="Img"></router-link>
                            </div>
                            <div class="developer-content">
                                <h4><router-link to="/employers/developer-details">Shan Morris</router-link></h4>
                                <p>React Developer</p>
                                <div class="rating">
                                    <i class="fas fa-star filled"></i>
                                    <i class="fas fa-star filled"></i>
                                    <i class="fas fa-star filled"></i>
                                    <i class="fas fa-star filled"></i>
                                    <i class="fas fa-star filled"></i>
                                    <span class="average-rating">5.0 (30)</span>
                                </div>
                            </div>
                        </div>
                        <div class="hour-rate">
                            <ul class="nav">
                                <li><i class="feather-dollar-sign me-1"></i>$28 Hourly</li>
                                <li><i class="feather-map-pin me-1"></i>Florida, USA</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-md-6">
                    <div class="feature-develop-blk aos" data-aos="fade-up">
                        <div class="developer-sub-blk">
                            <div class="img-developer">
                                <router-link to="/employers/developer-details"><img src="@/assets/img/user/avatar-25.jpg" class="me-2"
                                        alt="Img"></router-link>
                            </div>
                            <div class="developer-content">
                                <h4><router-link to="/employers/developer-details">Dran Gonzalez</router-link></h4>
                                <p>React Developer</p>
                                <div class="rating">
                                    <i class="fas fa-star filled"></i>
                                    <i class="fas fa-star filled"></i>
                                    <i class="fas fa-star filled"></i>
                                    <i class="fas fa-star filled"></i>
                                    <i class="fas fa-star filled"></i>
                                    <span class="average-rating">5.0 (30)</span>
                                </div>
                            </div>
                        </div>
                        <div class="hour-rate">
                            <ul class="nav">
                                <li><i class="feather-dollar-sign me-1"></i>$34 Hourly</li>
                                <li><i class="feather-map-pin me-1"></i>Delaware, USA</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-md-6">
                    <div class="feature-develop-blk aos" data-aos="fade-up">
                        <div class="developer-sub-blk">
                            <div class="img-developer">
                                <router-link to="/employers/developer-details"><img src="@/assets/img/user/avatar-26.jpg" class="me-2"
                                        alt="Img"></router-link>
                            </div>
                            <div class="developer-content">
                                <h4><router-link to="/employers/developer-details">Nicole Black</router-link></h4>
                                <p>Angular Developer</p>
                                <div class="rating">
                                    <i class="fas fa-star filled"></i>
                                    <i class="fas fa-star filled"></i>
                                    <i class="fas fa-star filled"></i>
                                    <i class="fas fa-star filled"></i>
                                    <i class="fas fa-star filled"></i>
                                    <span class="average-rating">5.0 (30)</span>
                                </div>
                            </div>
                        </div>
                        <div class="hour-rate">
                            <ul class="nav">
                                <li><i class="feather-dollar-sign me-1"></i>$312 Hourly</li>
                                <li><i class="feather-map-pin me-1"></i>Nevada, USA</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-md-6">
                    <div class="feature-develop-blk aos" data-aos="fade-up">
                        <div class="developer-sub-blk">
                            <div class="img-developer">
                                <router-link to="/employers/developer-details"><img src="@/assets/img/user/avatar-27.jpg" class="me-2"
                                        alt="Img"></router-link>
                            </div>
                            <div class="developer-content">
                                <h4><router-link to="/employers/developer-details">Megan Torres</router-link> </h4>
                                <p>Java Developer</p>
                                <div class="rating">
                                    <i class="fas fa-star filled"></i>
                                    <i class="fas fa-star filled"></i>
                                    <i class="fas fa-star filled"></i>
                                    <i class="fas fa-star filled"></i>
                                    <i class="fas fa-star filled"></i>
                                    <span class="average-rating">5.0 (30)</span>
                                </div>
                            </div>
                        </div>
                        <div class="hour-rate">
                            <ul class="nav">
                                <li><i class="feather-dollar-sign me-1"></i>$234 Hourly</li>
                                <li><i class="feather-map-pin me-1"></i>Delaware, USA</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-md-6">
                    <div class="feature-develop-blk aos" data-aos="fade-up">
                        <div class="developer-sub-blk">
                            <div class="img-developer">
                                <router-link to="/employers/developer-details"><img src="@/assets/img/user/avatar-28.jpg" class="me-2"
                                        alt="Img"></router-link>
                            </div>
                            <div class="developer-content">
                                <h4><router-link to="/employers/developer-details">Shan Morris</router-link></h4>
                                <p>React Developer</p>
                                <div class="rating">
                                    <i class="fas fa-star filled"></i>
                                    <i class="fas fa-star filled"></i>
                                    <i class="fas fa-star filled"></i>
                                    <i class="fas fa-star filled"></i>
                                    <i class="fas fa-star filled"></i>
                                    <span class="average-rating">5.0 (30)</span>
                                </div>
                            </div>
                        </div>
                        <div class="hour-rate">
                            <ul class="nav">
                                <li><i class="feather-dollar-sign me-1"></i>$28 Hourly</li>
                                <li><i class="feather-map-pin me-1"></i>Florida, USA</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-xl-12">
                    <div class="more-project text-center aos" data-aos="fade-up">
                        <router-link to="/employers/developer-details" class="btn btn-primary">View More Freelancers</router-link>
                    </div>
                    <div class="review-bottom text-center aos" data-aos="fade-up">
                        <div class="client-rate">
                            <h4>Clients rate our Data Processing Executives</h4>
                            <div class="rating">
                                <i class="fas fa-star filled"></i>
                                <i class="fas fa-star filled"></i>
                                <i class="fas fa-star filled"></i>
                                <i class="fas fa-star filled"></i>
                                <i class="fas fa-star filled"></i>
                                <span class="average-rating">5.0 (30)</span>
                            </div>
                            <p>from 4227 reviews</p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- /Feature Developer -->

    <!-- Top skill -->
    <section class="section top-skill">
        <div class="container">
            <div class="section-header section-header-two text-center aos" data-aos="fade-up">
                <h2 class="header-title">Featured <span> Skills</span></h2>
                <p>Get work done in over 1800 different categories </p>
            </div>
            <div class="row justify-content-center aos" data-aos="fade-up">
                <div class="skill-custom-col">
                    <div class="skills-feature">
                        <div class="skill-icon">
                            <img src="@/assets/img/icon/skill-icon-01.svg" alt="Img">
                        </div>
                        <span>Translation</span>
                    </div>
                </div>
                <div class="skill-custom-col">
                    <div class="skills-feature">
                        <div class="skill-icon">
                            <img src="@/assets/img/icon/skill-icon-02.svg" alt="Img">
                        </div>
                        <span>Research Writing</span>
                    </div>
                </div>
                <div class="skill-custom-col">
                    <div class="skills-feature">
                        <div class="skill-icon">
                            <img src="@/assets/img/icon/skill-icon-03.svg" alt="Img">
                        </div>
                        <span>Web Scraping</span>
                    </div>
                </div>
                <div class="skill-custom-col">
                    <div class="skills-feature">
                        <div class="skill-icon">
                            <img src="@/assets/img/icon/skill-icon-04.svg" alt="Img">
                        </div>
                        <span>Article Writing</span>
                    </div>
                </div>
                <div class="skill-custom-col">
                    <div class="skills-feature">
                        <div class="skill-icon">
                            <img src="@/assets/img/icon/skill-icon-05.svg" alt="Img">
                        </div>
                        <span>HTML 5</span>
                    </div>
                </div>
                <div class="skill-custom-col">
                    <div class="skills-feature">
                        <div class="skill-icon">
                            <img src="@/assets/img/icon/skill-icon-06.svg" alt="Img">
                        </div>
                        <span>Website Design</span>
                    </div>
                </div>
                <div class="skill-custom-col">
                    <div class="skills-feature">
                        <div class="skill-icon">
                            <img src="@/assets/img/icon/skill-icon-07.svg" alt="Img">
                        </div>
                        <span>Mobile Apps</span>
                    </div>
                </div>
                <div class="skill-custom-col">
                    <div class="skills-feature">
                        <div class="skill-icon">
                            <img src="@/assets/img/icon/skill-icon-08.svg" alt="Img">
                        </div>
                        <span>Android Apps</span>
                    </div>
                </div>
                <div class="skill-custom-col">
                    <div class="skills-feature">
                        <div class="skill-icon">
                            <img src="@/assets/img/icon/skill-icon-09.svg" alt="Img">
                        </div>
                        <span>Iphone Apps</span>
                    </div>
                </div>
                <div class="skill-custom-col">
                    <div class="skills-feature">
                        <div class="skill-icon">
                            <img src="@/assets/img/icon/skill-icon-10.svg" alt="Img">
                        </div>
                        <span>Software Architecture</span>
                    </div>
                </div>
                <div class="skill-custom-col">
                    <div class="skills-feature">
                        <div class="skill-icon">
                            <img src="@/assets/img/icon/skill-icon-11.svg" alt="Img">
                        </div>
                        <span>Graphic Design</span>
                    </div>
                </div>
                <div class="skill-custom-col">
                    <div class="skills-feature">
                        <div class="skill-icon">
                            <img src="@/assets/img/icon/skill-icon-12.svg" alt="Img">
                        </div>
                        <span>Logo Design</span>
                    </div>
                </div>
                <div class="skill-custom-col">
                    <div class="skills-feature">
                        <div class="skill-icon">
                            <img src="@/assets/img/icon/skill-icon-13.svg" alt="Img">
                        </div>
                        <span>Public Relations</span>
                    </div>
                </div>
                <div class="skill-custom-col">
                    <div class="skills-feature">
                        <div class="skill-icon">
                            <img src="@/assets/img/icon/skill-icon-14.svg" alt="Img">
                        </div>
                        <span>Proofreading</span>
                    </div>
                </div>
                <div class="skill-custom-col">
                    <div class="skills-feature">
                        <div class="skill-icon">
                            <img src="@/assets/img/icon/skill-icon-15.svg" alt="Img">
                        </div>
                        <span>Photoshop</span>
                    </div>
                </div>
                <div class="skill-custom-col">
                    <div class="skills-feature">
                        <div class="skill-icon">
                            <img src="@/assets/img/icon/skill-icon-16.svg" alt="Img">
                        </div>
                        <span>Technical Writing</span>
                    </div>
                </div>
                <div class="skill-custom-col">
                    <div class="skills-feature">
                        <div class="skill-icon">
                            <img src="@/assets/img/icon/skill-icon-17.svg" alt="Img">
                        </div>
                        <span>Blogging</span>
                    </div>
                </div>
                <div class="skill-custom-col">
                    <div class="skills-feature">
                        <div class="skill-icon">
                            <img src="@/assets/img/icon/skill-icon-18.svg" alt="Img">
                        </div>
                        <span>Internet Marketing</span>
                    </div>
                </div>
                <div class="skill-custom-col">
                    <div class="skills-feature">
                        <div class="skill-icon">
                            <img src="@/assets/img/icon/skill-icon-19.svg" alt="Img">
                        </div>
                        <span>eCommerce</span>
                    </div>
                </div>
                <div class="skill-custom-col">
                    <div class="skills-feature">
                        <div class="skill-icon">
                            <img src="@/assets/img/icon/skill-icon-20.svg" alt="Img">
                        </div>
                        <span>Data Entry</span>
                    </div>
                </div>
                <div class="skill-custom-col">
                    <div class="skills-feature">
                        <div class="skill-icon">
                            <img src="@/assets/img/icon/skill-icon-21.svg" alt="Img">
                        </div>
                        <span>Link Building</span>
                    </div>
                </div>
                <div class="skill-custom-col">
                    <div class="skills-feature">
                        <div class="skill-icon">
                            <img src="@/assets/img/icon/skill-icon-22.svg" alt="Img">
                        </div>
                        <span>C++ Programming</span>
                    </div>
                </div>
                <div class="skill-custom-col">
                    <div class="skills-feature">
                        <div class="skill-icon">
                            <img src="@/assets/img/icon/skill-icon-23.svg" alt="Img">
                        </div>
                        <span>C# Programming</span>
                    </div>
                </div>
                <div class="skill-custom-col">
                    <div class="skills-feature">
                        <div class="skill-icon">
                            <img src="@/assets/img/icon/skill-icon-24.svg" alt="Img">
                        </div>
                        <span>Content Writing</span>
                    </div>
                </div>
                <div class="skill-custom-col">
                    <div class="skills-feature">
                        <div class="skill-icon">
                            <img src="@/assets/img/icon/skill-icon-25.svg" alt="Img">
                        </div>
                        <span>Marketing</span>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- /Top skill -->
</template>