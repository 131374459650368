<template>
  <!-- Blog -->
  <section class="section blog-tricks">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div
            class="section-header text-center aos aos-init aos-animate"
            data-aos="fade-up"
          >
            <h2 class="header-title">Featured Blogs</h2>
            <p>Read Our Article To Get Tricks</p>
          </div>
        </div>
      </div>
      <div class="row aos aos-init aos-animate" data-aos="fade-up">
        <div class="col-lg-4 col-md-6">
          <div
            class="grid-blog blog-two aos aos-init aos-animate"
            data-aos="fade-up"
          >
            <div class="blog-image">
              <router-link to="/blog/blog-details"
                ><img
                  class="img-fluid"
                  src="@/assets/img/blog/blog-12.jpg"
                  alt="Post Image"
              /></router-link>
            </div>
            <div class="blog-content">
              <div class="feature-time-blk">
                <span class="badge bg-pink d-flex align-items-center"
                  ><i class="feather-tag me-1"></i>Jobs</span
                >
                <span><i class="feather-calendar me-1"></i> 06 Oct, 2023</span>
              </div>
              <h3 class="blog-title mt-0">
                <router-link to="/blog/blog-details"
                  >Top 10 Resume Tips for Landing Your Dream Job</router-link
                >
              </h3>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div
            class="grid-blog blog-two aos aos-init aos-animate"
            data-aos="fade-up"
          >
            <div class="blog-image">
              <router-link to="/blog/blog-details"
                ><img
                  class="img-fluid"
                  src="@/assets/img/blog/blog-13.jpg"
                  alt="Post Image"
              /></router-link>
            </div>
            <div class="blog-content">
              <div class="feature-time-blk">
                <span class="badge bg-pink d-flex align-items-center"
                  ><i class="feather-tag me-1"></i>Jobs</span
                >
                <span><i class="feather-calendar me-1"></i> 06 Oct, 2023</span>
              </div>
              <h3 class="blog-title mt-0">
                <router-link to="/blog/blog-details"
                  >Navigating the Gig Economy: Freelancing and Side
                  Hustles</router-link
                >
              </h3>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div
            class="grid-blog blog-two aos aos-init aos-animate"
            data-aos="fade-up"
          >
            <div class="blog-image">
              <router-link to="/blog/blog-details"
                ><img
                  class="img-fluid"
                  src="@/assets/img/blog/blog-14.jpg"
                  alt="Post Image"
              /></router-link>
            </div>
            <div class="blog-content">
              <div class="feature-time-blk">
                <span class="badge bg-pink d-flex align-items-center"
                  ><i class="feather-tag me-1"></i>Jobs</span
                >
                <span><i class="feather-calendar me-1"></i> 06 Oct, 2023</span>
              </div>
              <h3 class="blog-title mt-0">
                <router-link to="/blog/blog-details"
                  >Interview Success: How to Ace Common Interview
                  Questions</router-link
                >
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Blog -->
  <!-- Opportunity -->
  <section class="section job-registers">
    <div class="container position-relative job-register">
      <div class="float-img">
        <img src="@/assets/img/opportunity.png" alt="img" />
      </div>
      <div class="row">
        <div class="col-12">
          <div class="register-job-blk">
            <div
              class="job-content-blk aos aos-init aos-animate"
              data-aos="fade-up"
            >
              <h2>Find Your Next Great Job Opportunity!</h2>
              <p>
                Quisque pretium dolor turpis, quis blandit turpis semper ut. Nam
                malesuada eros nec luctus laoreet.
              </p>
              <router-link to="/register" class="btn all-btn"
                >Join Now</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Opportunity -->
</template>
