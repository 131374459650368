<template>
    <ul v-bind:class="{active: currentPath == 'subscription' || currentPath == 'subscription',}">
        <li :class="currentPath == 'subscription' ? 'active' : 'notactive'">
            <router-link to="/admin/subscription">Employer </router-link>
        </li>
        <li :class="currentPath == 'subscripe-freelancer' ? 'active' : 'notactive'">
            <router-link to="/admin/subscripe-freelancer">Freelancer</router-link>
        </li>
    </ul>
</template>

<script>
export default {
    data() {
        return {
            
        }
    },
    computed: {
        currentPath() {
            return this.$route.name;
        },
        returnMenu() {
            return (
                this.$route.name == "subscription" ||
                this.$route.name == "subscripe-freelancer" 
            );
        },
    },
}
</script>