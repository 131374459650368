<template>
    <!-- Top skill -->
    <section class="section top-skill top-skill-three">
        <div class="container">
            <div class="section-header section-header-three text-center aos" data-aos="fade-up">
                <span class="badge title-badge">Skill Sets</span>
                <h2 class="header-title">Get work done in over 1800 different categories</h2>
            </div>
            <div class="row justify-content-center">
                <div class="skill-custom-col">
                    <div class="skills-feature">
                        <div class="skill-icon">
                            <img src="@/assets/img/icon/skill-icon-01.svg" alt="Img">
                        </div>
                        <span>Translation</span>
                    </div>
                </div>
                <div class="skill-custom-col">
                    <div class="skills-feature">
                        <div class="skill-icon">
                            <img src="@/assets/img/icon/skill-icon-02.svg" alt="Img">
                        </div>
                        <span>Research Writing</span>
                    </div>
                </div>
                <div class="skill-custom-col">
                    <div class="skills-feature">
                        <div class="skill-icon">
                            <img src="@/assets/img/icon/skill-icon-03.svg" alt="Img">
                        </div>
                        <span>Web Scraping</span>
                    </div>
                </div>
                <div class="skill-custom-col">
                    <div class="skills-feature">
                        <div class="skill-icon">
                            <img src="@/assets/img/icon/skill-icon-04.svg" alt="Img">
                        </div>
                        <span>Article Writing</span>
                    </div>
                </div>
                <div class="skill-custom-col">
                    <div class="skills-feature">
                        <div class="skill-icon">
                            <img src="@/assets/img/icon/skill-icon-05.svg" alt="Img">
                        </div>
                        <span>HTML 5</span>
                    </div>
                </div>
                <div class="skill-custom-col">
                    <div class="skills-feature">
                        <div class="skill-icon">
                            <img src="@/assets/img/icon/skill-icon-06.svg" alt="Img">
                        </div>
                        <span>Website Design</span>
                    </div>
                </div>
                <div class="skill-custom-col">
                    <div class="skills-feature">
                        <div class="skill-icon">
                            <img src="@/assets/img/icon/skill-icon-07.svg" alt="Img">
                        </div>
                        <span>Mobile Apps</span>
                    </div>
                </div>
                <div class="skill-custom-col">
                    <div class="skills-feature">
                        <div class="skill-icon">
                            <img src="@/assets/img/icon/skill-icon-08.svg" alt="Img">
                        </div>
                        <span>Android Apps</span>
                    </div>
                </div>
                <div class="skill-custom-col">
                    <div class="skills-feature">
                        <div class="skill-icon">
                            <img src="@/assets/img/icon/skill-icon-09.svg" alt="Img">
                        </div>
                        <span>Iphone Apps</span>
                    </div>
                </div>
                <div class="skill-custom-col">
                    <div class="skills-feature">
                        <div class="skill-icon">
                            <img src="@/assets/img/icon/skill-icon-10.svg" alt="Img">
                        </div>
                        <span> Architecture</span>
                    </div>
                </div>
                <div class="skill-custom-col">
                    <div class="skills-feature">
                        <div class="skill-icon">
                            <img src="@/assets/img/icon/skill-icon-11.svg" alt="Img">
                        </div>
                        <span>Graphic Design</span>
                    </div>
                </div>
                <div class="skill-custom-col">
                    <div class="skills-feature">
                        <div class="skill-icon">
                            <img src="@/assets/img/icon/skill-icon-12.svg" alt="Img">
                        </div>
                        <span>Logo Design</span>
                    </div>
                </div>
                <div class="skill-custom-col">
                    <div class="skills-feature">
                        <div class="skill-icon">
                            <img src="@/assets/img/icon/skill-icon-13.svg" alt="Img">
                        </div>
                        <span>Public Relations</span>
                    </div>
                </div>
                <div class="skill-custom-col">
                    <div class="skills-feature">
                        <div class="skill-icon">
                            <img src="@/assets/img/icon/skill-icon-14.svg" alt="Img">
                        </div>
                        <span>Proofreading</span>
                    </div>
                </div>
                <div class="skill-custom-col">
                    <div class="skills-feature">
                        <div class="skill-icon">
                            <img src="@/assets/img/icon/skill-icon-15.svg" alt="Img">
                        </div>
                        <span>Photoshop</span>
                    </div>
                </div>
                <div class="skill-custom-col">
                    <div class="skills-feature">
                        <div class="skill-icon">
                            <img src="@/assets/img/icon/skill-icon-16.svg" alt="Img">
                        </div>
                        <span>Technical Writing</span>
                    </div>
                </div>
                <div class="skill-custom-col">
                    <div class="skills-feature">
                        <div class="skill-icon">
                            <img src="@/assets/img/icon/skill-icon-17.svg" alt="Img">
                        </div>
                        <span>Blogging</span>
                    </div>
                </div>
                <div class="skill-custom-col">
                    <div class="skills-feature">
                        <div class="skill-icon">
                            <img src="@/assets/img/icon/skill-icon-18.svg" alt="Img">
                        </div>
                        <span>Internet Marketing</span>
                    </div>
                </div>
                <div class="skill-custom-col">
                    <div class="skills-feature">
                        <div class="skill-icon">
                            <img src="@/assets/img/icon/skill-icon-19.svg" alt="Img">
                        </div>
                        <span>eCommerce</span>
                    </div>
                </div>
                <div class="skill-custom-col">
                    <div class="skills-feature">
                        <div class="skill-icon">
                            <img src="@/assets/img/icon/skill-icon-20.svg" alt="Img">
                        </div>
                        <span>Data Entry</span>
                    </div>
                </div>
                <div class="skill-custom-col">
                    <div class="skills-feature">
                        <div class="skill-icon">
                            <img src="@/assets/img/icon/skill-icon-21.svg" alt="Img">
                        </div>
                        <span>Link Building</span>
                    </div>
                </div>
                <div class="skill-custom-col">
                    <div class="skills-feature">
                        <div class="skill-icon">
                            <img src="@/assets/img/icon/skill-icon-22.svg" alt="Img">
                        </div>
                        <span>C++ Programming</span>
                    </div>
                </div>
                <div class="skill-custom-col">
                    <div class="skills-feature">
                        <div class="skill-icon">
                            <img src="@/assets/img/icon/skill-icon-23.svg" alt="Img">
                        </div>
                        <span>C# Programming</span>
                    </div>
                </div>
                <div class="skill-custom-col">
                    <div class="skills-feature">
                        <div class="skill-icon">
                            <img src="@/assets/img/icon/skill-icon-24.svg" alt="Img">
                        </div>
                        <span>Content Writing</span>
                    </div>
                </div>
                <div class="skill-custom-col">
                    <div class="skills-feature">
                        <div class="skill-icon">
                            <img src="@/assets/img/icon/skill-icon-25.svg" alt="Img">
                        </div>
                        <span>Marketing</span>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- /Top skill -->

    <!-- App Version -->
    <section class="section next-job">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="job-next-blk text-center aos" data-aos="fade-up">
                        <h2>Find Your Next Great Job Opportunity!</h2>
                        <p>Quisque pretium dolor turpis, quis blandit turpis semper ut. Nam malesuada eros nec luctus
                            laoreet. </p>
                        <div class="next-job-btn">
                            <router-link to="/register" class="btn btn-primary next-register me-3">Register Kofejob<i
                                    class="feather-arrow-right ms-2"></i></router-link>
                            <router-link to="/home/" class="btn btn-primary next-sign">Post A Project<i
                                    class="feather-arrow-right ms-2"></i></router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- /App Version -->
</template>