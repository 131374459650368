<template>
  <div class="field-item personal-info">
    <div class="media-set">
      <div class="row">
        <div class="col-md-12">
          <h4>Social Media</h4>
        </div>
        <div class="col-md-4">
          <div class="input-block">
            <label class="form-label">Facebook</label>
            <input type="text" class="form-control" />
          </div>
        </div>
        <div class="col-md-4">
          <div class="input-block">
            <label class="form-label">Instagram </label>
            <input type="text" class="form-control" />
          </div>
        </div>
        <div class="col-md-4">
          <div class="input-block">
            <label class="form-label">LinkedIn </label>
            <input type="text" class="form-control" />
          </div>
        </div>
        <div class="col-md-4">
          <div class="input-block">
            <label class="form-label">Behance </label>
            <input type="text" class="form-control" />
          </div>
        </div>
        <div class="col-md-4">
          <div class="input-block">
            <label class="form-label">Pinterest </label>
            <input type="text" class="form-control" />
          </div>
        </div>
        <div class="col-md-4">
          <div class="input-block">
            <label class="form-label">Twitter</label>
            <input type="text" class="form-control" />
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="media-set">
        <div class="row">
          <div class="col-md-12">
            <h4>Personal Website</h4>
          </div>
          <div class="col-md-12">
            <div class="input-block">
              <label class="form-label">Website Url</label>
              <input type="text" class="form-control" />
            </div>
          </div>
        </div>
      </div>
      <div class="media-set">
        <div class="row">
          <div class="col-md-12">
            <h4>Location</h4>
          </div>
          <div class="col-md-12">
            <div class="input-block">
              <label class="form-label">Address</label>
              <input type="text" class="form-control" />
            </div>
          </div>
          <div class="col-md-3">
            <div class="input-block">
              <label class="form-label">City</label>
              <input type="text" class="form-control" />
            </div>
          </div>
          <div class="col-md-3">
            <div class="input-block">
              <label class="form-label">State / Province</label>
              <input type="text" class="form-control" />
            </div>
          </div>
          <div class="col-md-3">
            <div class="input-block">
              <label class="form-label">ZIP / Post Code</label>
              <input type="text" class="form-control" />
            </div>
          </div>
          <div class="col-md-3">
            <div class="input-block">
              <label class="form-label">ZIP / Post Code</label>
              <vue-select :options="ZIP" id="postcode" placeholder="Select" />
            </div>
          </div>
        </div>
      </div>
      <div class="media-set">
        <div class="row">
          <div class="col-md-12">
            <h4>KYC Upload</h4>
          </div>
          <div class="col-md-6">
            <div class="input-block">
              <label class="form-label">Document Type</label>
              <vue-select :options="ZIP" id="posttype" placeholder="Select" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="input-block">
              <label class="form-label">Document Number</label>
              <input type="text" class="form-control" />
            </div>
          </div>
          <div class="col-md-12">
            <div class="input-block">
              <label class="form-label">Document Number</label>
              <div class="upload-sets">
                <label class="upload-filesview">
                  Browse File
                  <input type="file" />
                </label>
                <h6>Or Drag & Drop here</h6>
              </div>
              <span class="text-success"><i class="fa fa-check-circle me-2" aria-hidden="true"></i>Passport.jpg
                Uploaded Successfully</span>
            </div>
          </div>
        </div>
      </div>
      <div class="media-set border-0">
        <div class="row">
          <div class="col-md-12">
            <h4>Working Hours</h4>
          </div>
          <div class="col-md-6">
            <div class="input-block d-flex">
              <div class="form-check form-switch d-inline-block work-check position-relative">
                <input type="checkbox" class="form-check-input" ref="checkHour" v-model="isChecked" id="check_hour" checked="" />
              </div>
              <label class="form-label ms-2">Same Every Day</label>
            </div>
          </div>
          <div class="col-md-12">
            <div class="checkout-hour">
              <div class="other-info-list">
                <ul>
                  <li class="active-hour">Mon</li>
                  <li class="active-hour">Tue</li>
                  <li class="active-hour">Wed</li>
                  <li class="active-hour">Thr</li>
                  <li class="active-hour">Fri</li>
                  <li>Sat</li>
                  <li>Sun</li>
                </ul>
              </div>
            </div>
            <div class="check-hour">
              <div class="col-md-12">
                <h4>Select Days</h4>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="row align-items-center">
                    <div class="col-md-1">
                      <div class="other-info-list">
                        <ul>
                          <li class="active-hour m-0">Mon</li>
                        </ul>
                      </div>
                    </div>
                    <div class="col-md-5">
                      <div class="input-block">
                        <label class="form-label">Start Time </label>
                        <input type="text" class="form-control" Value="8:00" />
                      </div>
                    </div>
                    <div class="col-md-5">
                      <div class="input-block">
                        <label class="form-label">End Time </label>
                        <input type="text" class="form-control" Value="20:00" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="row align-items-center">
                    <div class="col-md-1">
                      <div class="other-info-list">
                        <ul>
                          <li class="active-hour m-0">Tue</li>
                        </ul>
                      </div>
                    </div>
                    <div class="col-md-5">
                      <div class="input-block">
                        <label class="form-label">Start Time </label>
                        <input type="text" class="form-control" Value="8:00" />
                      </div>
                    </div>
                    <div class="col-md-5">
                      <div class="input-block">
                        <label class="form-label">End Time </label>
                        <input type="text" class="form-control" Value="20:00" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="row align-items-center">
                    <div class="col-md-1">
                      <div class="other-info-list">
                        <ul>
                          <li class="active-hour m-0">Wed</li>
                        </ul>
                      </div>
                    </div>
                    <div class="col-md-5">
                      <div class="input-block">
                        <label class="form-label">Start Time </label>
                        <input type="text" class="form-control" Value="8:00" />
                      </div>
                    </div>
                    <div class="col-md-5">
                      <div class="input-block">
                        <label class="form-label">End Time </label>
                        <input type="text" class="form-control" Value="20:00" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="row align-items-center">
                    <div class="col-md-1">
                      <div class="other-info-list">
                        <ul>
                          <li class="active-hour m-0">Thur</li>
                        </ul>
                      </div>
                    </div>
                    <div class="col-md-5">
                      <div class="input-block">
                        <label class="form-label">Start Time </label>
                        <input type="text" class="form-control" Value="8:00" />
                      </div>
                    </div>
                    <div class="col-md-5">
                      <div class="input-block">
                        <label class="form-label">End Time </label>
                        <input type="text" class="form-control" Value="20:00" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="row align-items-center">
                    <div class="col-md-1">
                      <div class="other-info-list">
                        <ul>
                          <li class="active-hour m-0">Fri</li>
                        </ul>
                      </div>
                    </div>
                    <div class="col-md-5">
                      <div class="input-block">
                        <label class="form-label">Start Time </label>
                        <input type="text" class="form-control" Value="8:00" />
                      </div>
                    </div>
                    <div class="col-md-5">
                      <div class="input-block">
                        <label class="form-label">End Time </label>
                        <input type="text" class="form-control" Value="20:00" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="row align-items-center">
                    <div class="col-md-1">
                      <div class="other-info-list">
                        <ul>
                          <li class="m-0">Fri</li>
                        </ul>
                      </div>
                    </div>
                    <div class="col-md-5">
                      <div class="input-block">
                        <label class="form-label">Start Time </label>
                        <input type="text" class="form-control" Value="8:00" />
                      </div>
                    </div>
                    <div class="col-md-5">
                      <div class="input-block">
                        <label class="form-label">End Time </label>
                        <input type="text" class="form-control" Value="20:00" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="row align-items-center">
                    <div class="col-md-1">
                      <div class="other-info-list">
                        <ul>
                          <li class="m-0">Sun</li>
                        </ul>
                      </div>
                    </div>
                    <div class="col-md-5">
                      <div class="input-block">
                        <label class="form-label">Start Time </label>
                        <input type="text" class="form-control" Value="8:00" />
                      </div>
                    </div>
                    <div class="col-md-5">
                      <div class="input-block">
                        <label class="form-label">End Time </label>
                        <input type="text" class="form-control" Value="20:00" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      ZIP: ["Select", "US", "UK", "India"],
      isChecked: true,
    };
  },
  mounted() {
    // Your JavaScript code goes here
    this.$refs.checkHour.addEventListener('click', this.handleCheckHour);
  },
  methods: {
    handleCheckHour() {
      // Your existing JavaScript code goes here
      var checkHourCheckbox = this.$refs.checkHour;
      var checkoutHourElements = this.$el.getElementsByClassName('checkout-hour');
      var checkHourElements = this.$el.getElementsByClassName('check-hour');

      if (checkHourCheckbox.checked) {
        for (var i = 0; i < checkoutHourElements.length; i++) {
          checkoutHourElements[i].style.display = 'block';
        }

        for (var j = 0; j < checkHourElements.length; j++) {
          checkHourElements[j].style.display = 'none';
        }
      } else {
        for (var k = 0; k < checkoutHourElements.length; k++) {
          checkoutHourElements[k].style.display = 'none';
        }

        for (var l = 0; l < checkHourElements.length; l++) {
          checkHourElements[l].style.display = 'block';
        }
      }
    },
  },
};
</script>
