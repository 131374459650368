<template>
      <!-- Search Filter -->
      <div class="card search-filter">
                        <div class="card-header d-flex justify-content-between">
                            <h4 class="card-title mb-0">Search Filter</h4>
                        </div>
                        <div class="card-body">
                            <div class="filter-widget">
                                <h4 class="filter-title">
                                    <a href="javascript:void(0);" class="w-100" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        Category	
                                        <span class="float-end"><i class="fa-solid fa-chevron-down"></i></span>
                                    </a> 
                                </h4>
                                <div id="collapseOne" class="collapse show" data-bs-parent="#accordionExample1" >
                                    <div>
                                        <label class="custom_check">
                                            <input type="checkbox" name="select_time" >
                                            <span class="checkmark"></span> Developer (25) 
                                        </label>
                                    </div>
                                    <div>
                                        <label class="custom_check">
                                            <input type="checkbox" name="select_time">
                                            <span class="checkmark"></span> UI Developer (62)
                                        </label>
                                    </div>
                                    <div>
                                        <label class="custom_check">
                                            <input type="checkbox" name="select_time">
                                            <span class="checkmark"></span>  React Developer (46)
                                        </label>
                                    </div>
                                    <div>
                                        <label class="custom_check">
                                            <input type="checkbox" name="select_time">
                                            <span class="checkmark"></span>  .Net Developer (37)
                                        </label>
                                    </div>
                                    <div id="collapseOnes" class="collapse" data-bs-parent="#accordionExample1" >
                                        <div>
                                            <label class="custom_check">
                                                <input type="checkbox" name="select_time">
                                                <span class="checkmark"></span> UI Developer (62)
                                            </label>
                                        </div>
                                        <div>
                                            <label class="custom_check">
                                                <input type="checkbox" name="select_time">
                                                <span class="checkmark"></span>  React Developer (46)
                                            </label>
                                        </div>
                                        <div>
                                            <label class="custom_check">
                                                <input type="checkbox" name="select_time">
                                                <span class="checkmark"></span>  .Net Developer (37)
                                            </label>
                                        </div>
                                    </div>
                                    <div class="showmore mt-2">
                                        <a href="javascript:void(0);"  data-bs-toggle="collapse" data-bs-target="#collapseOnes" aria-expanded="true" aria-controls="collapseOne"><i class="feather-plus me-1"></i>Show More</a>
                                    </div>
                                </div>
                            </div>
                            <div class="filter-widget">
                                <h4 class="filter-title">
                                    <a href="javascript:void(0);" class="w-100" data-bs-toggle="collapse" data-bs-target="#collapsproject" aria-expanded="true" aria-controls="collapseOne">
                                        Project Type	
                                        <span class="float-end"><i class="fa-solid fa-chevron-down"></i></span>
                                    </a> 
                                </h4>
                                <div id="collapsproject" class="collapse show" data-bs-parent="#accordionExample1" >
                                    <div>
                                        <label class="custom_check">
                                            <input type="checkbox" name="select_time" >
                                            <span class="checkmark"></span>Fixed (6) 
                                        </label>
                                    </div>
                                    <div>
                                        <label class="custom_check">
                                            <input type="checkbox" name="select_time">
                                            <span class="checkmark"></span>Hourly (7)
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="filter-widget">
                                <h4 class="filter-title">
                                    <a href="javascript:void(0);" class="w-100" data-bs-toggle="collapse" data-bs-target="#collapseOne1" aria-expanded="true" aria-controls="collapseOne">
                                        Project Duration	
                                        <span class="float-end"><i class="fa-solid fa-chevron-down"></i></span>
                                    </a> 
                                </h4>
                                <div id="collapseOne1" class="collapse show" data-bs-parent="#accordionExample1" >
                                    <div>
                                        <label class="custom_check">
                                            <input type="checkbox" name="select_time" >
                                            <span class="checkmark"></span> 1-3 Weeks (4)
                                        </label>
                                    </div>
                                    <div>
                                        <label class="custom_check">
                                            <input type="checkbox" name="select_time">
                                            <span class="checkmark"></span> 1 Month (2)
                                        </label>
                                    </div>
                                    <div>
                                        <label class="custom_check">
                                            <input type="checkbox" name="select_time">
                                            <span class="checkmark"></span>  Less than 3 Months (2)
                                        </label>
                                    </div>
                                    
                                    
                                </div>
                            </div>
                            <div class="filter-widget">
                                <h4 class="filter-title">
                                    <a href="javascript:void(0);" class="w-100" data-bs-toggle="collapse" data-bs-target="#collapseskills" aria-expanded="true" aria-controls="collapseOne">
                                        Skills	
                                        <span class="float-end"><i class="fa-solid fa-chevron-down"></i></span>
                                    </a> 
                                </h4>
                                <div id="collapseskills" class="collapse show" data-bs-parent="#accordionExample1" >
                                    <div>
                                        <label class="custom_check">
                                            <input type="checkbox" name="select_time" >
                                            <span class="checkmark"></span> After Effects (6)
                                        </label>
                                    </div>
                                    <div>
                                        <label class="custom_check">
                                            <input type="checkbox" name="select_time">
                                            <span class="checkmark"></span> Android Developer (7)	
                                        </label>
                                    </div>
                                    <div>
                                        <label class="custom_check">
                                            <input type="checkbox" name="select_time">
                                            <span class="checkmark"></span> Backend Developer (7)
                                        </label>
                                    </div>
                                    <div>
                                        <label class="custom_check">
                                            <input type="checkbox" name="select_time">
                                            <span class="checkmark"></span> Computer Operator (1)
                                        </label>
                                    </div>
                                    <div id="collapseOnes1" class="collapse" data-bs-parent="#accordionExample1" >
                                        <div>
                                            <label class="custom_check">
                                                <input type="checkbox" name="select_time" >
                                                <span class="checkmark"></span> After Effects (6)
                                            </label>
                                        </div>
                                        <div>
                                            <label class="custom_check">
                                                <input type="checkbox" name="select_time">
                                                <span class="checkmark"></span> Android Developer (7)	
                                            </label>
                                        </div>
                                        <div>
                                            <label class="custom_check">
                                                <input type="checkbox" name="select_time">
                                                <span class="checkmark"></span> Backend Developer (7)
                                            </label>
                                        </div>
                                        <div>
                                            <label class="custom_check">
                                                <input type="checkbox" name="select_time">
                                                <span class="checkmark"></span> Computer Operator (1)
                                            </label>
                                        </div>
                                    </div>
                                    <div class="showmore mt-2">
                                        <a href="javascript:void(0);"  data-bs-toggle="collapse" data-bs-target="#collapseOnes1" aria-expanded="true" aria-controls="collapseOne"><i class="feather-plus me-1"></i>Show More</a>
                                    </div>
                                </div>
                            </div>
                            <div class="filter-widget">
                                <h4 class="filter-title">
                                    <a href="javascript:void(0);" class="w-100" data-bs-toggle="collapse" data-bs-target="#collapselanguage" aria-expanded="true" aria-controls="collapseOne">
                                        Languages	
                                        <span class="float-end"><i class="fa-solid fa-chevron-down"></i></span>
                                    </a> 
                                </h4>
                                <div id="collapselanguage" class="collapse show" data-bs-parent="#accordionExample1" >
                                    <div>
                                        <label class="custom_check">
                                            <input type="checkbox" name="select_time" >
                                            <span class="checkmark"></span> English (5)
                                        </label>
                                    </div>
                                    <div>
                                        <label class="custom_check">
                                            <input type="checkbox" name="select_time">
                                            <span class="checkmark"></span> Arabic (2)
                                        </label>
                                    </div>
                                    <div>
                                        <label class="custom_check">
                                            <input type="checkbox" name="select_time">
                                            <span class="checkmark"></span>German (1)
                                        </label>
                                    </div>
                                    
                                    <div id="collapseOnes2" class="collapse" data-bs-parent="#accordionExample1" >
                                        <div>
                                            <label class="custom_check">
                                                <input type="checkbox" name="select_time" >
                                                <span class="checkmark"></span> English (5)
                                            </label>
                                        </div>
                                        <div>
                                            <label class="custom_check">
                                                <input type="checkbox" name="select_time">
                                                <span class="checkmark"></span> Arabic (2)
                                            </label>
                                        </div>
                                        <div>
                                            <label class="custom_check">
                                                <input type="checkbox" name="select_time">
                                                <span class="checkmark"></span>German (1)
                                            </label>
                                        </div>
                                    </div>
                                    <div class="showmore mt-2">
                                        <a href="javascript:void(0);"  data-bs-toggle="collapse" data-bs-target="#collapseOnes2" aria-expanded="true" aria-controls="collapseOne"><i class="feather-plus me-1"></i>Show More</a>
                                    </div>
                                </div>
                            </div>
                            <div class="filter-widget">
                                <h4 class="filter-title">
                                    <a href="javascript:void(0);" class="w-100" data-bs-toggle="collapse" data-bs-target="#collapselanguagea" aria-expanded="true" aria-controls="collapseOne">
                                        Freelancer Type	
                                        <span class="float-end"><i class="fa-solid fa-chevron-down"></i></span>
                                    </a> 
                                </h4>
                                <div id="collapselanguagea" class="collapse show" data-bs-parent="#accordionExample1" >
                                    <div>
                                        <label class="custom_check">
                                            <input type="checkbox" name="select_time" >
                                            <span class="checkmark"></span>Full Time (3)
                                        </label>
                                    </div>
                                    <div>
                                        <label class="custom_check">
                                            <input type="checkbox" name="select_time">
                                            <span class="checkmark"></span> Part Time (4)
                                        </label>
                                    </div>
                                    <div>
                                        <label class="custom_check">
                                            <input type="checkbox" name="select_time">
                                            <span class="checkmark"></span>Project Based (2)
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="filter-widget">
                                <h4 class="filter-title">
                                    <a href="javascript:void(0);" class="w-100" data-bs-toggle="collapse" data-bs-target="#collapselocation" aria-expanded="true" aria-controls="collapseOne">
                                        Location	
                                        <span class="float-end"><i class="fa-solid fa-chevron-down"></i></span>
                                    </a> 
                                </h4>
                                <div id="collapselocation" class="collapse show" data-bs-parent="#accordionExample1" >
                                    <div>
                                        <label class="custom_check">
                                            <input type="checkbox" name="select_time" >
                                            <span class="checkmark"></span>USA (25)
                                        </label>
                                    </div>
                                    <div>
                                        <label class="custom_check">
                                            <input type="checkbox" name="select_time">
                                            <span class="checkmark"></span> IND (62)
                                        </label>
                                    </div>
                                    <div>
                                        <label class="custom_check">
                                            <input type="checkbox" name="select_time">
                                            <span class="checkmark"></span>UK (46)
                                        </label>
                                    </div>
                                    <div>
                                        <label class="custom_check">
                                            <input type="checkbox" name="select_time">
                                            <span class="checkmark"></span>AUS (37)
                                        </label>
                                    </div>
                                    <div id="collapseOnes3" class="collapse" data-bs-parent="#accordionExample1" >
                                        <div>
                                            <label class="custom_check">
                                                <input type="checkbox" name="select_time">
                                                <span class="checkmark"></span> IND (62)
                                            </label>
                                        </div>
                                        <div>
                                            <label class="custom_check">
                                                <input type="checkbox" name="select_time">
                                                <span class="checkmark"></span>UK (46)
                                            </label>
                                        </div>
                                        <div>
                                            <label class="custom_check">
                                                <input type="checkbox" name="select_time">
                                                <span class="checkmark"></span>AUS (37)
                                            </label>
                                        </div>
                                    </div>
                                    <div class="showmore mt-2">
                                        <a href="javascript:void(0);"  data-bs-toggle="collapse" data-bs-target="#collapseOnes3" aria-expanded="true" aria-controls="collapseOne"><i class="feather-plus me-1"></i>Show More</a>
                                    </div>
                                </div>
                            </div>
                        
                            <div class="btn-search">
                                <button type="button" class="btn btn-primary">Search</button>
                                <button type="button" class="btn btn-block">Reset</button>
                            </div>	
                        </div>
                    </div>
                    <!-- /Search Filter -->
</template>