<template>
  <pagebreadcrumb :title="title" :text="text" :text1="text1" />
  <div class="content">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="select-project mb-4">
            <form action="project-conformation">
              <div class="title-box widget-box">
                <div class="row">
                  <div class="col-lg-12">
                    <h4>Basic Details</h4>
                  </div>
                  <div class="col-lg-12 col-md-12">
                    <div class="mb-3">
                      <label class="focus-label">Project Title</label>
                      <input type="text" class="form-control" />
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-12">
                    <div class="mb-3">
                      <label class="focus-label">Project Category</label>
                      <vue-select
                        :options="ProjectPost"
                        id="projectpost"
                        placeholder="Select"
                      />
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-12">
                    <div class="mb-3">
                      <label class="focus-label">Project Duration</label>
                      <vue-select
                        :options="ProjectDuration"
                        id="projectduration"
                        placeholder="1-3 Week"
                      />
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6">
                    <div class="mb-3">
                      <label class="focus-label">Deadline Date</label>
                      <div class="cal-icon">
                        <datepicker
                          v-model="startdate"
                          placeholder="Choose Date"
                          class="form-control datetimepicker"
                          :editable="true"
                          :clearable="false"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-12">
                    <div class="mb-3">
                      <label class="focus-label">Freelancer Type</label>
                      <vue-select
                        :options="ProjectTime"
                        id="projecttime"
                        placeholder="Select"
                      />
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-12">
                    <div class="mb-3">
                      <label class="focus-label">Freelancer Level</label>
                      <vue-select
                        :options="ProjectBasic"
                        id="projectbasic"
                        placeholder="Select"
                      />
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12">
                    <div class="mb-3">
                      <label class="focus-label">Tags</label>
                      <input type="text" class="form-control" />
                    </div>
                  </div>
                  <div class="col-lg-12 my-3">
                    <h4>Skills</h4>
                  </div>
                  <!-- Skills Content -->
                  <div class="col-lg-12 col-md-12">
                    <div class="title-content p-0">
                      <div class="title-detail">
                        <h3>Skill Set</h3>
                        <div class="mb-3">
                          <vue3-tags-input
                            :tags="tags"
                            placeholder="UX, UI, App Design, Wireframing, Branding"
                          />
                          <p class="text-muted mb-0">
                            Enter skills for needed for project, for best result add 5 or
                            more Skills
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12 my-3">
                    <h4>Budget</h4>
                  </div>
                  <div class="buget-img">
                    <ul>
                      <li>
                        <div class="hours-rate" :class="{ active: isHoursActive }">
                          <div class="hours-rate-img">
                            <label class="customize-radio">
                              <input
                                type="radio"
                                class="hoursradio"
                                @click="handleHoursRadio"
                                checked
                              />
                              <img
                                src="@/assets/img/icon/check-success.svg"
                                alt="img"
                                class="success-check"
                              />
                              <img src="@/assets/img/icon/hourly.svg" alt="img" />
                              <span class="d-block">Hourly Rate</span>
                            </label>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div class="fixed-rate" :class="{ active: isFixedActive }">
                          <div class="hours-rate-img">
                            <label class="customize-radio">
                              <input
                                type="radio"
                                name="fixed"
                                class="fixedradio"
                                @click="handleFixedRadio"
                              />
                              <img
                                src="@/assets/img/icon/check-success.svg"
                                alt="img"
                                class="success-check"
                              />
                              <img src="@/assets/img/icon/fixed.svg" alt="img" />
                              <span class="d-block">Fixed budget</span>
                            </label>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div class="hours-rates">
                    <div class="row">
                      <div class="col-lg-3 col-md-12">
                        <div class="mb-3">
                          <label class="focus-label">From ($)</label>
                          <input type="text" class="form-control" placeholder="15" />
                        </div>
                      </div>
                      <div class="col-lg-3 col-md-6">
                        <div class="mb-3">
                          <label class="focus-label">To ($)</label>
                          <input type="text" class="form-control" placeholder="250" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="fixed-rates d-none">
                    <div class="row">
                      <div class="col-lg-6 col-md-12">
                        <div class="mb-3">
                          <label class="focus-label">Enter Price ($)</label>
                          <input type="text" class="form-control" placeholder="15" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12 my-3">
                    <h4>Attachment</h4>
                    <p>
                      You can attach more than 1 files to 10 files, Size of the Document
                      should be Below 2MB
                    </p>
                  </div>
                  <div class="col-lg-12">
                    <div class="attach-file">
                      <i class="fa fa-pdf"></i>
                      Attach file
                      <input type="file" />
                    </div>
                    <div class="filename">
                      <ul>
                        <li v-for="(filename, index) in filenames" :key="index">
                          <h6 v-if="filename !== null">
                            {{ filename }}
                            <a href="#" class="file-close">
                              <i
                                class="fa fa-close"
                                @click.prevent="closeFile(index)"
                              ></i>
                            </a>
                          </h6>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-lg-12 my-3">
                    <h4>Other Requirement</h4>
                  </div>
                  <div class="col-lg-6 col-md-12">
                    <div class="mb-3">
                      <label class="focus-label">Languages</label>
                      <input type="text" class="form-control" />
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-12">
                    <div class="mb-3">
                      <label class="focus-label">Language Fluency</label>
                      <vue-select
                        :options="ProjectLanguage"
                        id="projectlanguage"
                        placeholder="Select"
                      />
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="mb-3">
                      <label class="focus-label">Write Description of Projects</label>
                      <textarea class="form-control"></textarea>
                    </div>
                  </div>
                  <!-- /Skills Content -->
                </div>

                <div class="row">
                  <div class="col-md-12 text-end">
                    <div class="btn-item">
                      <button type="submit" class="btn reset-btn">Reset</button>
                      <button type="submit" class="btn next-btn">Post a Job</button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Project Title -->
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
const currentDate = ref(new Date());
import Vue3TagsInput from "vue3-tags-input";
export default {
  components: {
    Vue3TagsInput,
  },
  data() {
    return {
      startdate: currentDate,
      isHoursActive: true,
      isFixedActive: false,
      title: "Post a Project",
      text: "Home",
      text1: "Post a Project",
      ProjectPost: ["Select", "Category", "Project"],
      ProjectBasic: ["Basic", "Intermedaite", "Professional"],
      ProjectTime: ["Select", "Full Time", "Part Time", "Project Based"],
      ProjectDuration: ["1-3 Week", "1 Month", "Less then a month", "More then a month"],
      ProjectLanguage: ["Basic", "Intermedaite", "Professional"],
      tags: ["Web Design"],
      filenames: ["Filename 1", "Filename 2", "Filename 3"],
    };
  },
  methods: {
    handleHoursRadio() {
      this.isHoursActive = true;
      this.isFixedActive = false;
      this.toggleRatesVisibility();
    },
    handleFixedRadio() {
      this.isHoursActive = false;
      this.isFixedActive = true;
      this.toggleRatesVisibility();
    },
    toggleRatesVisibility() {
      const hoursRates = document.querySelector(".hours-rates");
      const fixedRates = document.querySelector(".fixed-rates");

      if (this.isHoursActive) {
        hoursRates.classList.remove("d-none");
        fixedRates.classList.add("d-none");
      } else if (this.isFixedActive) {
        hoursRates.classList.add("d-none");
        fixedRates.classList.remove("d-none");
      }
    },
    closeFile(index) {
      // Set the item to null to hide it
      this.filenames[index] = null;
    },
  },
};
</script>
