<template>
  <div class="showentries">
    <label
      >Show
      <select>
        <option value="10">10</option>
        <option value="25">25</option>
        <option value="50">50</option>
        <option value="100">100</option>
      </select>
      entries
    </label>
    <div class="entries">
      <span>Search: </span>
      <input
        type="search"
        class="form-control form-control-sm ms-2"
        placeholder=""
        aria-controls="DataTables_Table_0"
      />
    </div>
  </div>
  <div class="table-responsive">
    <a-table :columns="columns" :data-source="data">
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'Logo'">
          <h2 class="table-avatar">
            <router-link to="/admin/profile"
              ><img
                class="img-fluid me-2"
                :src="require(`@/assets/img/company/${record.Logo}`)"
                alt="User Image"
            /></router-link>
          </h2>
        </template>
        <template v-else-if="column.key === 'inputbox'">
          <div class="form-check custom-checkbox">
            <input type="checkbox" class="form-check-input" id="customCheck5" />
            <label class="form-check-label" for="customCheck5"></label>
          </div>
        </template>
        <template v-if="column.key === 'Progress'">
          <div class="progress rounded-pill">
            <div
              class="progress-bar bg-primary rounded-pill"
              role="progressbar"
              :style="record.Progress"
              aria-valuenow="25"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </template>
        <template v-if="column.key === 'action'">
          <div class="text-end">
            <a
              href="javascript:void(0);"
              class="btn btn-sm btn-secondary me-2"
              data-bs-toggle="modal"
              data-bs-target="#add-category"
              ><i class="far fa-edit"></i
            ></a>
            <a
              href="javascript:void(0);"
              class="btn btn-sm btn-danger"
              data-bs-toggle="modal"
              data-bs-target="#delete_category"
              ><i class="far fa-trash-alt"></i
            ></a>
          </div>
        </template>
      </template>
    </a-table>
  </div>
</template>

<script>
const columns = [
  {
    title: "#",
    key: "inputbox",
    sorter: true,
  },
  {
    title: "Logo",
    dataIndex: "Logo",
    key: "Logo",
    sorter: {
      compare: (a, b) => {
        a = a.Logo.toLowerCase();
        b = b.Logo.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Title",
    dataIndex: "Title",
    sorter: true,
  },
  {
    title: "Budget",
    dataIndex: "Budget",
    sorter: {
      compare: (a, b) => {
        a = a.Budget.toLowerCase();
        b = b.Budget.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Progress",
    dataIndex: "Progress",
    key: "Progress",
    sorter: {
      compare: (a, b) => {
        a = a.Progress.toLowerCase();
        b = b.Progress.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Technology",
    dataIndex: "Technology",
    sorter: {
      compare: (a, b) => {
        a = a.Technology.toLowerCase();
        b = b.Technology.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Company",
    dataIndex: "Company",
    sorter: {
      compare: (a, b) => {
        a = a.Company.toLowerCase();
        b = b.Company.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Startdate",
    dataIndex: "Startdate",
    sorter: {
      compare: (a, b) => {
        a = a.Startdate.toLowerCase();
        b = b.Startdate.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Due date",
    dataIndex: "Duedate",
    sorter: {
      compare: (a, b) => {
        a = a.Duedate.toLowerCase();
        b = b.Duedate.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "action",
    sorter: true,
  },
];
const data = [
  {
    FIELD1: "",
    Logo: "img-7.png",
    Title: "Designer Required For Directory",
    Budget: "$2222",
    Progress: "width: 25%",
    Technology: "Java",
    Company: "Kind Software",
    Startdate: "22-05-2023",
    Duedate: "22-05-2023",
  },
  {
    FIELD1: "",
    Logo: "img-8.png",
    Title: "Lorem Ipsum is therefore always free content",
    Budget: "$7789",
    Progress: "width: 56%",
    Technology: "Python",
    Company: "Particles INC",
    Startdate: "22-05-2023",
    Duedate: "22-05-2023",
  },
  {
    FIELD1: "",
    Logo: "img-9.png",
    Title: "Required For Website Developer",
    Budget: "$2222",
    Progress: "width: 25%",
    Technology: "Codingnator",
    Company: "Kind Software",
    Startdate: "22-05-2023",
    Duedate: "22-05-2023",
  },
  {
    FIELD1: "",
    Logo: "img-10.png",
    Title: "Lorem Ipsum is therefore always free",
    Budget: "$7789",
    Progress: "width: 56%",
    Technology: "React",
    Company: "Particles INC",
    Startdate: "22-05-2023",
    Duedate: "22-05-2023",
  },
  {
    FIELD1: "",
    Logo: "img-7.png",
    Title: "Website Designer Required For Directory Theme",
    Budget: "$2222",
    Progress: "width: 25%",
    Technology: "Java",
    Company: "Kind Software",
    Startdate: "22-05-2023",
    Duedate: "22-05-2023",
  },
  {
    FIELD1: "",
    Logo: "img-1.png",
    Title: "Website Designer Required For Directory Theme",
    Budget: "$2222",
    Progress: "width: 25%",
    Technology: "Angular",
    Company: "AMAZE TECH",
    Startdate: "22-05-2023",
    Duedate: "22-05-2023",
  },
  {
    FIELD1: "",
    Logo: "img-2.png",
    Title: "Lorem Ipsum is simply dummy text of",
    Budget: "$5755",
    Progress: "width: 60%",
    Technology: "Laravel",
    Company: "Park INC",
    Startdate: "22-05-2023",
    Duedate: "22-05-2023",
  },
  {
    FIELD1: "",
    Logo: "img-3.png",
    Title: "It is a long established fact that a reader",
    Budget: "$5755",
    Progress: "width: 30%",
    Technology: "Vue",
    Company: "Tsch Zone",
    Startdate: "22-05-2023",
    Duedate: "22-05-2023",
  },
  {
    FIELD1: "",
    Logo: "img-4.png",
    Title: "There are many variations of passages of Lorem",
    Budget: "$2333",
    Progress: "width: 50%",
    Technology: "Golang",
    Company: "ABC Software",
    Startdate: "22-05-2023",
    Duedate: "22-05-2023",
  },
  {
    FIELD1: "",
    Logo: "img-8.png",
    Title: "Lorem Ipsum is therefore always free",
    Budget: "$7789",
    Progress: "width: 56%",
    Technology: "Python",
    Company: "Particles INC",
    Startdate: "22-05-2023",
    Duedate: "22-05-2023",
  },
];
export default {
  data() {
    return {
      columns,
      data,
    };
  },
};
</script>
