<template>
  <!-- Footer -->
  <footer class="footer footer-five">
    <!-- /Footer Top Nav -->
    <div class="footer-top">
      <div class="container">
        <div class="row">
          <div class="col-lg-2 col-md-4">
            <div class="footer-widget footer-menu">
              <h2 class="footer-title">Useful Links</h2>
              <ul>
                <li><router-link to="/pages/about">About Us</router-link></li>
                <li><router-link to="/blog/blog-list">Blog</router-link></li>
                <li><router-link to="/">Login</router-link></li>
                <li><router-link to="/register">Register</router-link></li>
                <li>
                  <router-link to="/forgot-password"
                    >Forgot Password</router-link
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-2 col-md-4">
            <div class="footer-widget footer-menu">
              <h2 class="footer-title">Help & Support</h2>
              <ul>
                <li><a href="javascript:void(0);">Browse Candidates</a></li>
                <li><a href="javascript:void(0);">Employers Dashboard</a></li>
                <li><a href="javascript:void(0);">Job Packages</a></li>
                <li><a href="javascript:void(0);">Jobs Featured</a></li>
                <li><a href="javascript:void(0);">Post A Job</a></li>
              </ul>
            </div>
          </div>
          <div class="col-lg-2 col-md-4">
            <div class="footer-widget footer-menu">
              <h2 class="footer-title">Other Links</h2>
              <ul>
                <li>
                  <router-link to="/freelancer/freelancer-dashboard"
                    >Freelancers</router-link
                  >
                </li>
                <li>
                  <router-link to="/freelancer/freelancer-portfolio"
                    >Freelancer Details</router-link
                  >
                </li>
                <li><router-link to="/freelancer/project">Project</router-link></li>
                <li>
                  <router-link to="/freelancer/project-details"
                    >Project Details</router-link
                  >
                </li>
                <li>
                  <router-link to="/post-project">Post Project</router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-2 col-md-4">
            <div class="footer-widget footer-menu">
              <h2 class="footer-title">Featured Catgeories</h2>
              <ul>
                <li><router-link to="/freelancer/project">Illustration</router-link></li>
                <li><router-link to="/freelancer/project">Link Building</router-link></li>
                <li><router-link to="/freelancer/project">Python Dev</router-link></li>
                <li><router-link to="/freelancer/project">Angular Dev</router-link></li>
                <li><router-link to="/freelancer/project">Node JS</router-link></li>
              </ul>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="footer-widget footer-menu">
              <h2 class="footer-title">Subscribe</h2>
              <div
                class="banner-content aos aos-init aos-animate"
                data-aos="fade-up"
              >
                <form class="form" name="store" method="post" action="project">
                  <div class="form-inner">
                    <div class="input-group">
                      <input
                        type="email"
                        class="form-control"
                        placeholder="Your Email address....."
                      />
                      <button class="btn btn-primary sub-btn" type="submit">
                        <i class="fas fa-long-arrow-alt-right long-arrow"></i>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div class="applicate-mobile-blk">
                <h6 class="mb-3">Mobile Application</h6>
              </div>
              <div class="row g-2">
                <div class="col-lg-4">
                  <a href="javascript:void(0);"
                    ><img
                      class="img-fluid"
                      src="@/assets/img/app-store.svg"
                      alt="app-store"
                  /></a>
                </div>
                <div class="col-lg-4">
                  <a href="javascript:void(0);"
                    ><img
                      class="img-fluid"
                      src="@/assets/img/google-play.svg"
                      alt="google-play"
                  /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Footer Top -->

    <!-- Footer Bottom -->
    <div class="footer-bottom">
      <div class="container">
        <!-- Copyright -->
        <div class="copyright">
          <div class="row">
            <div class="col-md-12 col-lg-12">
              <div class="footer-bottom-content">
                <div class="copyright-text">
                  <p class="mb-0">
                    Copyright {{ new Date().getFullYear() }} © KofeJob. All right reserved.
                  </p>
                </div>
                <ul>
                  <li>
                    <a href="javascript:void(0);" class="icon" target="_blank"
                      ><i class="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0);" class="icon" target="_blank"
                      ><i class="fab fa-linkedin-in"></i>
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0);" class="icon" target="_blank"
                      ><i class="fab fa-twitter"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- /Copyright -->
      </div>
    </div>
    <!-- /Footer Bottom -->
  </footer>
  <!-- /Footer -->
</template>
