<template>
  <!-- The Modal -->
  <div class="modal fade edit-proposal-modal" id="add-milestone">
    <div class="modal-dialog modal-dialog-centered modal-md">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Add New Task</h4>
          <span class="modal-close"><a href="javascript:void(0);" data-bs-dismiss="modal" aria-label="Close"><i
                class="feather-x"></i></a></span>
        </div>
        <div class="modal-body">
          <form action="#">
            <div class="modal-info">
              <div class="row">
                <div class="col-md-12">
                  <div class="input-block">
                    <label class="form-label">Task Name</label>
                    <input class="form-control" type="text" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-block">
                    <label class="form-label">Milestone</label>
                    <vue-select :options="Milestone" id="milestone" placeholder="Select" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-block">
                    <label class="form-label">Due date</label>
                    <div class="cal-icon">
                      <input class="form-control datetimepicker" type="text" />
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="input-block">
                    <label class="form-label">Description</label>
                    <textarea class="form-control summernote"></textarea>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="input-block">
                    <label class="form-label">Status</label>
                    <vue-select :options="Inprogress" id="Inprogress" placeholder="InProgress" />
                  </div>
                </div>
              </div>
            </div>
            <div class="submit-section text-end">
              <a href="javascript:void(0);" class="btn btn-cancel">Cancel</a>
              <button type="submit" class="btn btn-primary submit-btn">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /The Modal -->
  <!-- The Modal -->
  <div class="modal fade edit-proposal-modal" id="edit-milestone">
				<div class="modal-dialog modal-dialog-centered modal-md">
					<div class="modal-content">
						<div class="modal-header">
							<h4 class="modal-title">Edit Task</h4>
							<span class="modal-close"><a href="javascript:void(0);" data-bs-dismiss="modal" aria-label="Close"><i class="feather-x"></i></a></span>
						</div>
						<div class="modal-body">		
							<form action="#">
								<div class="modal-info">
									<div class="row">
										<div class="col-md-12">
											<div class="input-block">
												<label class="form-label">Task Name</label>
												<input class="form-control" type="text" value="Adding New Logo Concept">
											</div>
										</div>
										<div class="col-md-6">
											<div class="input-block">
												<label class="form-label">Milestone</label>
                        <vue-select 
                          :options="Creating"
                          id="creating"
                          placeholder="Creating a Logo Concept"
                        />
											</div>
										</div>
										<div class="col-md-6">
											<div class="input-block">
												<label class="form-label">Due date</label>
												<div class="cal-icon">
													<input class="form-control datetimepicker" type="text">
												</div>
											</div>
										</div>
										<div class="col-lg-12">
											<div class="input-block">
												<label class="form-label">Description</label>
												<textarea class="form-control summernote">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</textarea>
											</div>
										</div>
										<div class="col-md-12">
											<div class="input-block">
												<label class="form-label">Status</label>
												<vue-select :options="Inprogress" id="cancelled" placeholder="InProgress" />
											</div>
										</div>
									</div>
								</div>
								<div class="submit-section text-end">
									<a href="javascript:void(0);" class="btn btn-cancel">Cancel</a>
									<button type="submit" class="btn btn-primary submit-btn">Submit</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
  <!-- /The Modal -->
</template>

<script>
export default {
  data() {
    return {
      Milestone: ["Select", "Research", "Design", "SDevelopment"],
      Inprogress: ["InProgress", "Completed", "Pending", "Cancelled"],
      Creating: ["Creating a Logo Concept", "Design", "SDevelopment"]
    }
  },
}
</script>