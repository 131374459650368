<template>
    <!-- Trending Projects -->
    <section class="section trend-projects trend-projects-three">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-12 mx-auto">
                    <div class="section-header section-header-three aos" data-aos="fade-up">
                        <span class="badge title-badge">Trending Projects</span>
                        <h2 class="header-title">Recently Update jobs for you</h2>
                    </div>
                </div>
            </div>
            <div id="trend-slider" class="owl-carousel owl-theme trend-slider aos" data-aos="fade-up">
                <Carousel :wrap-around="true" :settings="settings" :breakpoints="breakpoints">
                    <Slide v-for="item in Trending" :key="item.id">
                        <div class="project-item trend-project-item">
                            <div class="project-img">
                                <router-link to="/freelancer/project"><img :src="require(`@/assets/img/project/${item.Image}`)" alt="Img" class="img-fluid"></router-link>
                            </div>
                            <div class="trend-project-content">
                                <div class="star-rated">
                                    <div class="rating">
                                        <i class="fas fa-star filled"></i>
                                        <i class="fas fa-star filled"></i>
                                        <i class="fas fa-star filled"></i>
                                        <i class="fas fa-star filled"></i>
                                        <i class="fas fa-star filled"></i>
                                        <span class="average-rating">5.0</span>
                                    </div>
                                    <a href="javascript:void(0);" class="select-favourite"><i
                                            class="fa-regular fa-bookmark"></i></a>
                                </div>
                                <h4 class="text-start"><router-link to="/freelancer/project" >{{item.Title}}</router-link></h4>
                                <ul class="trend-project-list">
                                    <li><i class="feather-user me-1"></i>{{item.Role}}</li>
                                    <li><i class="feather-map-pin me-1"></i>{{item.Location}}</li>
                                </ul>
                                <div class="trend-rate">
                                    <ul class="nav">
                                        <li><span>{{item.Amount}}</span></li>
                                        <li><a href="javascript:void(0);">Learn More<i class="feather-arrow-right ms-2"></i></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </Slide>
                    <template #addons>
                        <Pagination />
                    </template>
                </Carousel>
            </div>
        </div>
    </section>
    <!-- Trending Projects -->

    <!-- Our Feature -->
    <section class="section feature-count feature-count-three">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-12 mx-auto">
                    <div class="section-header section-header-three feature-count-head aos" data-aos="fade-up">
                        <h2 class="header-title">Achievement We Have Earned</h2>
                        <p>At Freelancer, we believe that talent is borderless and opportunity should be too.</p>
                    </div>
                </div>
            </div>
            <div class="row section-bottom-cards">

                <!-- Feature Item -->
                <div class="col-xl-3 col-md-6">
                    <div class="feature-item freelance-count aos" data-aos="fade-up">
                        <div class="feature-icon ">
                            <img src="@/assets/img/icon/count-icon-01.svg" class="img-fluid" alt="Img">
                        </div>
                        <div class="feature-content course-count">
                            <h3>
                                <vue3-autocounter class="counter-up" ref="counter" :startAmount="1000" :delay="3" :endAmount="9207"
                                    :duration="5" separator="," :autoinit="true" />
                            </h3>
                            <p>Freelance developers</p>
                        </div>
                    </div>
                </div>
                <!-- /Feature Item -->

                <!-- Feature Item -->
                <div class="col-xl-3 col-md-6">
                    <div class="feature-item aos" data-aos="fade-up">
                        <div class="feature-icon">
                            <img src="@/assets/img/icon/count-icon-02.svg" class="img-fluid" alt="Img">
                        </div>
                        <div class="feature-content course-count">
                            <h3>
                                <vue3-autocounter class="counter-up" ref="counter" :startAmount="1000" :delay="3" :endAmount="6000"
                                    :duration="5" separator="," :autoinit="true" />
                                    </h3>
                            <p>Projects Added</p>
                        </div>
                    </div>
                </div>
                <!-- /Feature Item -->

                <!-- Feature Item -->
                <div class="col-xl-3 col-md-6">
                    <div class="feature-item comp-project aos" data-aos="fade-up">
                        <div class="feature-icon">
                            <img src="@/assets/img/icon/count-icon-03.svg" class="img-fluid" alt="Img">
                        </div>
                        <div class="feature-content course-count">
                            <h3>
                                <vue3-autocounter class="counter-up" ref="counter" :startAmount="1000" :delay="3" :endAmount="919207"
                                    :duration="5" separator="," :autoinit="true" />
                                </h3>
                            <p>Completed projects</p>
                        </div>
                    </div>
                </div>
                <!-- /Feature Item -->

                <!-- Feature Item -->
                <div class="col-xl-3 col-md-6">
                    <div class="feature-item comp-project aos" data-aos="fade-up">
                        <div class="feature-icon">
                            <img src="@/assets/img/icon/count-icon-04.svg" class="img-fluid" alt="Img">
                        </div>
                        <div class="feature-content course-count">
                            <h3>
                                <vue3-autocounter class="counter-up" ref="counter" :startAmount="10" :delay="3" :endAmount="998"
                                    :duration="5" separator="," :autoinit="true" />
                            </h3>
                            <p>Companies Registered</p>
                        </div>
                    </div>
                </div>
                <!-- /Feature Item -->

            </div>
        </div>
    </section>
    <!-- /Our Feature -->
</template>

<script>
import { Carousel, Pagination, Slide } from "vue3-carousel";
import Trending from "@/assets/json/trending.json";
import "vue3-carousel/dist/carousel.css";
export default {
    data() {
        return {
            Trending: Trending,
            settings: {
                itemsToShow: 1,
                snapAlign: "center",
            },
            breakpoints: {
                575: {
                    itemsToShow: 1,
                    snapAlign: "center",
                },
                767: {
                    itemsToShow: 1,
                    snapAlign: "center",
                },
                991: {
                    itemsToShow: 3,
                    snapAlign: "center",
                },
                1024: {
                    itemsToShow: 3,
                    snapAlign: "start",
                },
            },
        }
    },
    components: {
        Carousel,
        Slide,
        Pagination,
    },
}
</script>