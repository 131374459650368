<template>
<!--  <pagebreadcrumb :title="title" :text="text" :text1="text1" />-->
  <!-- Page Content -->
  <div class="content">
    <div class="container">
      <div class="row">
<!--        <div class="col-md-12 col-lg-4 col-xl-3 theiaStickySidebar">-->
<!--          <div class="stickysidebar">-->
<!--            <projectsearch></projectsearch>-->
<!--          </div>-->
<!--        </div>-->
        <!-- /Search Filter -->
        <div class="col-12">
          <div class="sort-tab develop-list-select">
            <div class="row align-items-center">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                <div class="d-flex align-items-center">
                  <div class="freelance-view">
                    <h4>{{Project_List.length}} oportunidades encontradas</h4>
                  </div>
                </div>
              </div>
<!--              <div-->
<!--                class="col-xl-6 col-lg-6 col-md-6 col-sm-6 d-flex justify-content-sm-end"-->
<!--              >-->
<!--                <div class="sort-by">-->
<!--                  <vue-select :options="Rating" id="rating" placeholder="Select" />-->
<!--                </div>-->
<!--                <ul class="list-grid d-flex align-items-center">-->
<!--                  <li>-->
<!--                    <router-link to="/freelancer/project"-->
<!--                      ><i class="fas fa-th-large"></i-->
<!--                    ></router-link>-->
<!--                  </li>-->
<!--                  <li>-->
<!--                    <router-link to="/freelancer/project-list" class="favour-active"-->
<!--                      ><i class="fas fa-list"></i-->
<!--                    ></router-link>-->
<!--                  </li>-->
<!--                </ul>-->
<!--              </div>-->
            </div>
          </div>

          <div class="list-book-mark book-mark favour-book">
            <div class="row">
              <div class="col-lg-12">
                <div
                  class="project-list-card shadow"
                  v-for="record in Project_List"
                  :key="record.id"
                >
<!--                  <a href="javascript:void(0);" class="add-fav-list"-->
<!--                    ><i class="fa-regular fa-heart"></i-->
<!--                  ></a>-->
<!--                  <div class="company-detail-image">-->
<!--                    <img-->
<!--                      :src="require(`@/assets/img/${record.Image}`)"-->
<!--                      class="img-fluid"-->
<!--                      alt="logo"-->
<!--                    />-->
<!--                  </div>-->
                  <div class="col-12">
                    <div class="company-title">
<!--                      <p> {{ record.source }} </p>-->
                      <h4>
                        {{ record.title }}
                      </h4>
                    </div>
                    <div class="company-splits">
                      <div>
                        <div class="company-address">
                          <ul>
<!--                            <li><i class="feather-map-pin"></i> record.Map </li>-->
                            <li><i class="feather-calendar"></i> {{ record.date }} </li>
<!--                            <li><i class="feather-file-2"></i> record.File </li>-->
                          </ul>
                        </div>
                        <div class="company-description">
                          <p>
                            {{ record.description }}
                          </p>
                        </div>
                        <div class="company-description">
                          <div class="tags">
                            <a href="javascript:void(0);" v-for="(tag, index) in record.category" :key="index"
                              ><span class="badge badge-pill badge-design me-1">
                                {{tag}}
                              </span></a
                            >
                          </div>
                        </div>
                      </div>
                      <div class="company-split-price">
                        <h5>{{ record.budget ? record.budget : 'Negociável' }}</h5>
<!--                        <h6> record.Price </h6>-->
                        <h6></h6>
                        <button v-if="isAd" class="btn btn-submits" @click="showAlert">Enviar Proposta</button>
                        <a v-else :href="record.link" target="_blank" class="btn btn-submits">Enviar Proposta</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
<!--            <div class="row">-->
<!--              <div class="col-md-12">-->
<!--                <ul class="paginations list-pagination">-->
<!--                  <li class="page-item">-->
<!--                    <a href="javascript:void(0);"><i class="feather-chevron-left"></i></a>-->
<!--                  </li>-->
<!--                  <li class="page-item">-->
<!--                    <a href="javascript:void(0);" class="active">1</a>-->
<!--                  </li>-->
<!--                  <li class="page-item"><a href="javascript:void(0);">2</a></li>-->
<!--                  <li class="page-item"><a href="javascript:void(0);">3</a></li>-->
<!--                  <li class="page-item"><a href="javascript:void(0);">...</a></li>-->
<!--                  <li class="page-item"><a href="javascript:void(0);">10</a></li>-->
<!--                  <li class="page-item">-->
<!--                    <a href="javascript:void(0);"-->
<!--                      ><i class="feather-chevron-right"></i-->
<!--                    ></a>-->
<!--                  </li>-->
<!--                </ul>-->
<!--              </div>-->
<!--            </div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Page Content -->
  <projectlist></projectlist>
</template>
<script>
import Project_List from "@/assets/json/project-list-prof-ia.json";
import Swal from 'sweetalert2';
export default {
  data() {
    return {
      Project_List: Project_List,
      title: "Project List",
      isAd: false,
      text: "Home",
      text1: "Projects",
      Rating: ["Relevance", "Rating", "Popular", "Latest", "Free"],
    };
  },
  created() {
    if (this.$route.query.forad) {
      this.isAd = true;
    }
  },
  methods: {
    showAlert() {
      Swal.fire({
        title: 'Em apenas 2 Etapas o seu acesso será liberado!',
        html: `<strong>Etapa 01</strong><br>
              Cadastre-se na área de membros do Profissão IA (Você vai receber gratuitamente um mini curso sobre Inteligência Artificial)

              <br><br>
              <strong>Etapa 02</strong><br>
              Entre na página inicial da sua área de membros e acesse "VAGAS.IA"
              <br><br>
              `,
        icon: 'success',
        showCancelButton: false,
        confirmButtonText: 'Fazer Cadastro',
        reverseButtons: true,
      }).then(async (result) => {
        if (result.value) {
          window.location.href = 'https://profissao-ia.memberz.com.br/auth/signup'
        }
      });
    }
  }
};
</script>
