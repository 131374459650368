<template>
    <!-- Home Banner -->
    <section class="section home-banner row-middle">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-8 col-lg-7">
                    <div class="banner-content aos" data-aos="fade-up" data-aos-duration="3000">
                        <div class="rating">
                            <i class="fas fa-star checked"></i>
                            <i class="fas fa-star checked"></i>
                            <i class="fas fa-star checked"></i>
                            <i class="fas fa-star checked"></i>
                            <i class="fas fa-star checked"></i>
                            <h5>Trused by over 2M+ users</h5>
                        </div>
                        <h1>Get The Perfect <span class="orange-text"><br>Developers & Projects</span></h1>
                        <p>There are many variations of passages of the Ipsum available, but the majority have
                            suffered alteration in some form, by injected humour.</p>
                        <form class="form" name="store" id="store" method="post" action="javascript:;">
                            <div class="form-inner">
                                <div class="input-group">
                                    <span class="drop-detail">
                                        <vue-select 
                                            :options="Select"
                                            id="select"
                                            placeholder="Select"
                                        />
                                    </span>
                                    <input type="email" class="form-control" placeholder="Keywords">
                                    <button class="btn btn-primary sub-btn" type="submit">Search </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="col-md-4 col-lg-5">
                    <div class="banner-img aos" data-aos="zoom-in" data-aos-duration="3000">
                        <img src="@/assets/img/banner-img.svg" class="img-fluid" alt="banner">
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- /Home Banner -->

    <section class="section review">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-sm-12 col-12 mx-auto text-center">
                    <div class="section-header aos" data-aos="fade-up">
                        <h2 class="header-title">Popular Categories</h2>
                        <p>Get some Inspirations from 1300+ skills</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-3 col-md-6 col-12 aos" data-aos="zoom-in" data-aos-duration="1000">
                        <div class="popular-catergories">
                            <div class="popular-catergories-img">
                                <span><img src="@/assets/img/icon/categories1.svg" alt="img"></span>
                            </div>
                            <div class="popular-catergories-content">
                                <h5>Development & IT</h5>
                                <a href="javascript:void(0);">958 Skills<i class="feather-arrow-right ms-2"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-12 aos" data-aos="zoom-in" data-aos-duration="1500">
                        <div class="popular-catergories">
                            <div class="popular-catergories-img">
                                <span><img src="@/assets/img/icon/categories7.svg" alt="img"></span>
                            </div>
                            <div class="popular-catergories-content">
                                <h5>Design & Creative</h5>
                                <a href="javascript:void(0);">515 Skills<i class="feather-arrow-right ms-2"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-12 aos" data-aos="zoom-in" data-aos-duration="2000">
                        <div class="popular-catergories">
                            <div class="popular-catergories-img">
                                <span><img src="@/assets/img/icon/categories3.svg" alt="img"></span>
                            </div>
                            <div class="popular-catergories-content">
                                <h5>Digital Marketing</h5>
                                <a href="javascript:void(0);">486 Skills<i class="feather-arrow-right ms-2"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-12 aos" data-aos="zoom-in" data-aos-duration="2500">
                        <div class="popular-catergories">
                            <div class="popular-catergories-img">
                                <span><img src="@/assets/img/icon/categories4.svg" alt="img"></span>
                            </div>
                            <div class="popular-catergories-content">
                                <h5>Writing & Translation</h5>
                                <a href="javascript:void(0);">956 Skills<i class="feather-arrow-right ms-2"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-12 aos" data-aos="zoom-in" data-aos-duration="1000">
                        <div class="popular-catergories">
                            <div class="popular-catergories-img">
                                <span><img src="@/assets/img/icon/categories5.svg" alt="img"></span>
                            </div>
                            <div class="popular-catergories-content">
                                <h5>Music & Video</h5>
                                <a href="javascript:void(0);">662 Skills<i class="feather-arrow-right ms-2"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-12 aos" data-aos="zoom-in" data-aos-duration="1500">
                        <div class="popular-catergories">
                            <div class="popular-catergories-img">
                                <span><img src="@/assets/img/icon/categories6.svg" alt="img"></span>
                            </div>
                            <div class="popular-catergories-content">
                                <h5>Video & Animation</h5>
                                <a href="javascript:void(0);">226 Skills<i class="feather-arrow-right ms-2"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-12 aos" data-aos="zoom-in" data-aos-duration="2000">
                        <div class="popular-catergories">
                            <div class="popular-catergories-img">
                                <span><img src="@/assets/img/icon/categories7.svg" alt="img"></span>
                            </div>
                            <div class="popular-catergories-content">
                                <h5>Engineering & Architecture</h5>
                                <a href="javascript:void(0);">756 Skills<i class="feather-arrow-right ms-2"></i></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-12 aos" data-aos="zoom-in" data-aos-duration="2500">
                        <div class="popular-catergories">
                            <div class="popular-catergories-img">
                                <span><img src="@/assets/img/icon/categories8.svg" alt="img"></span>
                            </div>
                            <div class="popular-catergories-content">
                                <h5>Finance & Accounting</h5>
                                <a href="javascript:void(0);">958 Skills<i class="feather-arrow-right ms-2"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            Select: ["Select", "Project", "Freelancers"]
        }
    },
}
</script>