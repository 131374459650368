<template>
  <div class="content content-page">
    <div class="container">
      <div class="row">
        <div class="col-xl-3 col-lg-4 theiaStickySidebar">
          <freelancer-sidebar></freelancer-sidebar>
        </div>
        <div class="col-xl-9 col-lg-8">
          <div class="dashboard-sec freelance-favourites">
            <div class="page-title">
              <h3>Reviews</h3>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="review-card">
                  <h5>Fast and clear.</h5>
                  <p>Fast and clear. European visual concepts. Easy to communicate TOP</p>
                  <div class="reviewer-details-block">
                    <div class="reviewer-img">
                      <img
                        src="@/assets/img/user/avatar-10.jpg"
                        class="img-fluid"
                        alt="Img"
                      />
                    </div>
                    <div class="reviewer-details">
                      <h6>Theresa Phillips</h6>
                      <div class="d-flex">
                        <div class="rating">
                          <i class="fas fa-star filled"></i>
                          <i class="fas fa-star filled"></i>
                          <i class="fas fa-star filled"></i>
                          <i class="fas fa-star filled"></i>
                          <i class="fas fa-star"></i>
                          <span class="average-rating">4</span>
                        </div>
                        <div class="reviewer-log">
                          <i class="feather-calendar"></i><span>Just now</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="review-card">
                  <h5>This seller was great overall.</h5>
                  <p>
                    This seller was great overall. One thing I noticed was that there was
                    one logo out of the 4 I got that stood out amongst the others. Now I
                    loved that one logo but the other ones did not look like much thought
                    were put into them and they were a little disappointing. It is
                    understandable for the price for them to only put effort into one
                    logo, which like I said I loved. I think it would be better to tell
                    this seller to make one good logo and to provide 3 other logos that
                    work off the one good logo. Maybe make that one good logo and then
                    change colors and fonts for the other 3. But overall this seller was
                    great!
                  </p>
                  <div class="reviewer-details-block">
                    <div class="reviewer-img">
                      <img
                        src="@/assets/img/user/avatar-11.jpg"
                        class="img-fluid"
                        alt="Img"
                      />
                    </div>
                    <div class="reviewer-details">
                      <h6>Amy Stratton</h6>
                      <div class="d-flex">
                        <div class="rating">
                          <i class="fas fa-star filled"></i>
                          <i class="fas fa-star filled"></i>
                          <i class="fas fa-star filled"></i>
                          <i class="fas fa-star filled"></i>
                          <i class="fas fa-star filled"></i>
                          <span class="average-rating">5</span>
                        </div>
                        <div class="reviewer-log">
                          <i class="feather-calendar"></i><span>1 min ago</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="review-card">
                  <h5>Great help given and work</h5>
                  <p>Great help given and work was done as asked on time! :)</p>
                  <div class="reviewer-details-block">
                    <div class="reviewer-img">
                      <img
                        src="@/assets/img/user/avatar-2.jpg"
                        class="img-fluid"
                        alt="Img"
                      />
                    </div>
                    <div class="reviewer-details">
                      <h6>Henry Munford</h6>
                      <div class="d-flex">
                        <div class="rating">
                          <i class="fas fa-star filled"></i>
                          <i class="fas fa-star filled"></i>
                          <i class="fas fa-star filled"></i>
                          <i class="fas fa-star filled"></i>
                          <i class="fas fa-star filled"></i>
                          <span class="average-rating">5</span>
                        </div>
                        <div class="reviewer-log">
                          <i class="feather-calendar"></i><span>25 mins ago</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="review-card">
                  <h5>very quick & smart.</h5>
                  <p>very quick & smart. Recommend him for any web related work</p>
                  <div class="reviewer-details-block">
                    <div class="reviewer-img">
                      <img
                        src="@/assets/img/user/avatar-12.jpg"
                        class="img-fluid"
                        alt="Img"
                      />
                    </div>
                    <div class="reviewer-details">
                      <h6>Barbara McDonald</h6>
                      <div class="d-flex">
                        <div class="rating">
                          <i class="fas fa-star filled"></i>
                          <i class="fas fa-star filled"></i>
                          <i class="fas fa-star filled"></i>
                          <i class="fas fa-star filled"></i>
                          <i class="fas fa-star filled"></i>
                          <span class="average-rating">5</span>
                        </div>
                        <div class="reviewer-log">
                          <i class="feather-calendar"></i><span>1 hour ago</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="review-card">
                  <h5>
                    They delivered great work! The delivery was prompt and the
                    communication was excellent.
                  </h5>
                  <p>
                    They delivered great work! The delivery was prompt and the
                    communication was excellent. I will be using them in the future and
                    recommend them to anyone who needs their services!
                  </p>
                  <div class="reviewer-details-block">
                    <div class="reviewer-img">
                      <img
                        src="@/assets/img/user/avatar-4.jpg"
                        class="img-fluid"
                        alt="Img"
                      />
                    </div>
                    <div class="reviewer-details">
                      <h6>Tyler Lewis</h6>
                      <div class="d-flex">
                        <div class="rating">
                          <i class="fas fa-star filled"></i>
                          <i class="fas fa-star filled"></i>
                          <i class="fas fa-star filled"></i>
                          <i class="fas fa-star filled"></i>
                          <i class="fas fa-star filled"></i>
                          <span class="average-rating">5</span>
                        </div>
                        <div class="reviewer-log">
                          <i class="feather-calendar"></i><span>5 hours ago</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <ul class="paginations list-pagination">
                  <li class="page-item">
                    <a href="javascript:void(0);"><i class="feather-chevron-left"></i></a>
                  </li>
                  <li class="page-item">
                    <a href="javascript:void(0);" class="active">1</a>
                  </li>
                  <li class="page-item"><a href="javascript:void(0);">2</a></li>
                  <li class="page-item"><a href="javascript:void(0);">3</a></li>
                  <li class="page-item"><a href="javascript:void(0);">...</a></li>
                  <li class="page-item"><a href="javascript:void(0);">10</a></li>
                  <li class="page-item">
                    <a href="javascript:void(0);"
                      ><i class="feather-chevron-right"></i
                    ></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- project list -->
        </div>
      </div>
    </div>
  </div>
  <freelancer-review-modal></freelancer-review-modal>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
