<template>
  <!-- The Modal -->
  <div class="modal fade edit-proposal-modal" id="hire-now">
    <div class="modal-dialog modal-dialog-centered modal-md">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Hire - Hannah Finn</h4>
          <span class="modal-close"
            ><a href="javascript:void(0);" data-bs-dismiss="modal" aria-label="Close"
              ><i class="feather-x"></i></a
          ></span>
        </div>
        <div class="modal-body">
          <form action="#">
            <div class="modal-info">
              <div class="row">
                <div class="input-block">
                  <label class="form-label">Message</label>
                  <textarea class="form-control">
Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</textarea
                  >
                </div>
              </div>
            </div>
            <label class="custom_check close-project">
              <input type="checkbox" name="rem_password" />
              <span class="checkmark"></span>Close this project for other freelancers
            </label>
            <div class="submit-section text-end">
              <a href="javascript:void(0);" class="btn btn-cancel">Cancel</a>
              <a
                href="#success-hire"
                class="btn btn-primary submit-btn"
                data-bs-toggle="modal"
                >Let’s Discuss</a
              >
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /The Modal -->

  <!-- The Modal -->
  <div class="modal fade success-modal hire-modal" id="success-hire">
    <div class="modal-dialog modal-dialog-centered modal-md">
      <div class="modal-content">
        <div class="modal-body">
          <div class="success-msg-content text-center">
            <h4>Hired Successfully</h4>
            <p>
              Freelancer Hannah finn has been successfully hired for the project “Project
              Name”, This project will be hide for Other freelancers & in future
            </p>
            <router-link
              to="/employers/project-employer-view-proposal"
              class="btn btn-primary mt-3"
              >Go to Projects</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /The Modal -->
</template>
