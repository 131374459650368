<template>
  <!-- Sidebar -->
  <div class="sidebar" id="sidebar">
    <div class="sidebar-inner slimscroll">
      <perfect-scrollbar
        class="scroll-area"
        :settings="settings"
        @ps-scroll-y="scrollHanle"
      >
        <div id="sidebar-menu" class="sidebar-menu">
          <ul>
            <li class="menu-title"><span>Main</span></li>
            <li :class="{ active: isActive('/admin/index') }">
              <router-link to="/admin/index"
                ><i class="feather feather-home"></i> <span>Dashboard</span></router-link
              >
            </li>
            <li :class="{ active: isActive(['/admin/categories', '/admin/categories']) }">
              <router-link to="/admin/categories"
                ><i class="feather feather-copy"></i> <span>Categories</span></router-link
              >
            </li>
            <li :class="{ active: isActive('/admin/projects') }">
              <router-link to="/admin/projects"
                ><i class="feather feather-database"></i>
                <span>Projects</span></router-link
              >
            </li>
            <li
              :class="{
                active: isActive([
                  '/admin/users',
                  '/admin/activities',
                  '/admin/profile-transactions',
                  '/admin/user-active',
                  '/admin/user-administrator',
                  '/admin/user-inactive',
                  '/admin/user-profile',
                  '/admin/user-suspended',
                ]),
              }"
            >
              <router-link to="/admin/users"
                ><i class="feather feather-users"></i>
                <span>Freelancer</span></router-link
              >
            </li>
            <li
              :class="{
                active: isActive([
                  '/admin/deposit',
                  '/admin/deposit-cancelled',
                  '/admin/deposit-completed',
                  '/admin/deposit-hold',
                  '/admin/deposit-pending',
                ]),
              }"
            >
              <router-link to="/admin/deposit"
                ><i class="feather feather-user-check"></i>
                <span>Deposit</span></router-link
              >
            </li>
            <li
              :class="{
                active: isActive([
                  '/admin/withdrawn',
                  '/admin/withdrawn-cancelled',
                  '/admin/withdrawn-completed',
                  '/admin/withdrawn-pending',
                ]),
              }"
            >
              <router-link to="/admin/withdrawn"
                ><i class="feather feather-user-check"></i>
                <span>Withdrawn</span></router-link
              >
            </li>
            <li
              :class="{
                active: isActive([
                  '/admin/transaction',
                  '/admin/transaction-completed',
                  '/admin/transaction-deposit',
                  '/admin/transaction-onhold',
                  '/admin/transaction-pending',
                  'admin/transaction-withdraw',
                ]),
              }"
            >
              <router-link to="/admin/transaction"
                ><i class="feather feather-clipboard"></i>
                <span>Transaction</span></router-link
              >
            </li>
            <li :class="{ active: isActive('/admin/providers') }">
              <router-link to="/admin/providers"
                ><i class="feather feather-user-check"></i>
                <span>Providers</span></router-link
              >
            </li>
            <li
              :class="{
                active: isActive([
                  '/admin/subscription',
                  '/admin/employe-list',
                  '/admin/freelance-list',
                  '/admin/subscripe-freelancer',
                  '/admin/view-price-employe',
                  '/admin/view-price-freelance',
                ]),
              }"
            >
              <router-link to="/admin/subscription"
                ><i class="feather feather-user-check"></i>
                <span>Subscription</span></router-link
              >
            </li>
            <li
              :class="{
                active: isActive([
                  '/admin/reports',
                  '/admin/earning-employer',
                  '/admin/earning-freelancer',
                  '/admin/project-earnings',
                  '/admin/project-invoice',
                ]),
              }"
            >
              <router-link to="/admin/reports"
                ><i class="feather feather-pie-chart"></i>
                <span>Reports</span></router-link
              >
            </li>
            <li
              :class="{ active: isActive(['/admin/roles', '/admin/roles-permission']) }"
            >
              <router-link to="/admin/roles"
                ><i class="feather feather-clipboard"></i> <span>Roles</span></router-link
              >
            </li>
            <li :class="{ active: isActive('/admin/skills') }">
              <router-link to="/admin/skills"
                ><i class="feather feather-award"></i> <span>Skills</span></router-link
              >
            </li>
            <li :class="{ active: isActive('/admin/verify-identity') }">
              <router-link to="/admin/verify-identity"
                ><i class="feather feather-user-check"></i>
                <span>Verify Identity</span></router-link
              >
            </li>
            <li
              :class="{
                active: isActive([
                  '/admin/settings',
                  '/admin/delete-account',
                  '/admin/email-settings',
                  '/admin/localization-details',
                  '/admin/others-settings',
                  '/admin/payment-settings',
                  '/admin/seo-settings',
                  '/admin/social-links',
                  '/admin/social-settings',
                  '/admin/tax-types',
                ]),
              }"
            >
              <router-link to="/admin/settings"
                ><i class="feather feather-settings"></i>
                <span>Settings</span></router-link
              >
            </li>
            <li class="menu-title"><span>UI Interface</span></li>
            <li :class="{ active: isActive('/admin/components') }">
              <router-link to="/admin/components"
                ><i class="feather feather-pocket"></i>
                <span>Components</span></router-link
              >
            </li>
            <li
              class="submenu"
              v-bind:class="{
                active: isActive([
                  '/admin/form-basic-inputs',
                  '/admin/form-input-groups',
                  '/admin/form-horizontal',
                  '/admin/form-vertical',
                  '/admin/form-mask',
                  '/admin/form-validation',
                ]),
              }"
            >
              <a
                href="#sidebarformsMenu"
                data-bs-toggle="collapse"
                role="button"
                aria-expanded="false"
                aria-controls="sidebarformsMenu"
                :class="{ active: formsMenu, subdrop: formsMenu }"
                @click.prevent="toggleformsMenu"
              >
                <i class="feather feather-file-minus"></i> <span>Forms</span>
                <span class="menu-arrow"></span>
              </a>
              <ul
                class="collapse menu-dropdown"
                id="sidebarformsMenu"
                :class="{ show: formsMenu }"
              >
                <li>
                  <router-link
                    to="/admin/form-basic-inputs"
                    :class="
                      currentPath == 'admin/form-basic-inputs' ? 'active' : 'notactive'
                    "
                    >Basic Inputs</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/admin/form-input-groups"
                    :class="
                      currentPath == 'admin/form-input-groups' ? 'active' : 'notactive'
                    "
                    >Input Groups</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/admin/form-horizontal"
                    :class="
                      currentPath == 'admin/form-horizontal' ? 'active' : 'notactive'
                    "
                    >Horizontal Form</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/admin/form-vertical"
                    :class="currentPath == 'admin/form-vertical' ? 'active' : 'notactive'"
                    >Vertical Form</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/admin/form-mask"
                    :class="currentPath == 'admin/form-mask' ? 'active' : 'notactive'"
                    >Form Mask</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/admin/form-validation"
                    :class="
                      currentPath == 'admin/form-validation' ? 'active' : 'notactive'
                    "
                    >Form Validation</router-link
                  >
                </li>
              </ul>
            </li>
            <li
              class="submenu"
              v-bind:class="{
                active: isActive(['/admin/tables-basic', '/admin/data-tables']),
              }"
            >
              <a
                href="#sidebarTables"
                data-bs-toggle="collapse"
                role="button"
                aria-expanded="false"
                aria-controls="sidebarTables"
                :class="{ active: tablesMenu, subdrop: tablesMenu }"
                @click.prevent="toggletablesMenu"
                ><i class="feather feather-align-justify"></i> <span> Tables</span>
                <span class="menu-arrow"></span
              ></a>
              <ul
                class="collapse menu-dropdown"
                id="sidebarTables"
                :class="{ show: tablesMenu }"
              >
                <li>
                  <router-link
                    to="/admin/tables-basic"
                    ::class="currentPath == '/admin/tables-basic' ? 'active' : 'notactive'"
                    >Basic Tables</router-link
                  >
                </li>
                <li>
                  <router-link
                    to="/admin/data-tables"
                    :class="currentPath == '/admin/data-tables' ? 'active' : 'notactive'"
                    >Data Table</router-link
                  >
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
  <!-- /Sidebar -->
</template>
<script>
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
export default {
  data() {
    return {
      settingMenu: false,
      settings: {
        suppressScrollX: true,
      },
      activeClass: "active",
    };
  },
  methods: {
    scrollHanle(evt) {},
    isActive(paths) {
      return paths.includes(this.$route.path);
    },
    isActiveForm(paths) {
      return paths.includes(this.$route.path);
    },
    toggleformsMenu() {
      this.formsMenuData = !this.formsMenuData;
    },
    toggletablesMenu() {
      this.tablesMenuData = !this.tablesMenuData;
    },
    initActiveMenu(ele) {
      setTimeout(() => {
        if (document.querySelector("#sidebar")) {
          let a = document
            .querySelector("#sidebar")
            .querySelector('[href="' + ele + '"]');

          if (a) {
            a.classList.add("active");
            let parentCollapseDiv = a.closest(".collapse.menu-dropdown");
            if (parentCollapseDiv) {
              parentCollapseDiv.classList.add("show");
              parentCollapseDiv.parentElement.children[0].classList.add("active");
              parentCollapseDiv.parentElement.children[0].setAttribute(
                "aria-expanded",
                "true"
              );
              if (parentCollapseDiv.parentElement.closest(".collapse.menu-dropdown")) {
                parentCollapseDiv.parentElement
                  .closest(".collapse")
                  .classList.add("show");
                if (
                  parentCollapseDiv.parentElement.closest(".collapse")
                    .previousElementSibling
                )
                  parentCollapseDiv.parentElement
                    .closest(".collapse")
                    .previousElementSibling.classList.add("active");
              }
            }
          }
        }
      }, 1000);
    },
  },
  computed: {
    currentPath() {
      return this.$route.name;
    },
    formsMenu() {
      return (
        this.$route.name == "/admin/form-basic-inputs" ||
        this.$route.name == "/admin/form-input-groups" ||
        this.$route.name == "/admin/form-horizontal" ||
        this.$route.name == "/admin/form-vertical" ||
        this.$route.name == "/admin/form-mask" ||
        this.$route.name == "/admin/form-validation"
      );
    },
    tablesMenu() {
      return (
        this.$route.name == "/admin/data-tables" ||
        this.$route.name == "/admin/tables-basic"
      );
    },
  },
  components: {
    PerfectScrollbar,
  },
};
</script>
