<template>
  <div class="dashboard-page">
    <div class="content content-page">
      <div class="container">
        <div class="row">
          <div class="col-xl-3 col-lg-4 theiaStickySidebar">
            <freelancer-sidebar></freelancer-sidebar>
          </div>
          <div class="col-xl-9 col-lg-8">
            <div class="page-title">
              <h3>My Proposals</h3>
            </div>
            <!-- Proposals list -->
            <div class="proposals-section">
              <!-- Proposals -->
              <div
                class="freelancer-proposals"
                v-for="record in Freelancer_Project_Proposals"
                :key="record.id"
              >
                <div class="project-proposals align-items-center freelancer">
                  <div class="proposal-info">
                    <div class="proposals-details">
                      <h3 class="proposals-title">
                        {{ record.Title }}
                      </h3>
                      <ul>
                        <li>
                          <div class="proposal-img">
                            <div class="proposal-client">
                              <img
                                :src="require(`@/assets/img/user/${record.Image}`)"
                                alt="Img"
                                class="img-fluid"
                              />
                              <h4>{{ record.Name }}</h4>
                              <span class="info-btn">client</span>
                            </div>
                          </div>
                        </li>
                        <li>
                          <div class="proposal-client-price">
                            <h4 class="title-info">Client Price</h4>
                            <h3 class="client-price">
                              {{ record.Amount }}
                              <span class="price-type">( Fixed )</span>
                            </h3>
                          </div>
                        </li>
                        <li>
                          <div class="proposal-job-type">
                            <h4 class="title-info">Job Type</h4>
                            <h3>Hourly</h3>
                          </div>
                        </li>
                        <li>
                          <div class="project-amount">
                            <h4 class="title-info">Your Price</h4>
                            <h3>{{ record.Price }}<span>(in 12 Days)</span></h3>
                          </div>
                        </li>
                        <li>
                          <div class="project-action text-center">
                            <a data-bs-toggle="modal" href="#file" class="projects-btn"
                              >{{ record.Options }}
                            </a>
                            <router-link to="freelancer-view-project-detail"
                              >View Project</router-link
                            >
                            <a href="javascript:void(0);" class="proposal-delete mb-0"
                              >Delete Proposal</a
                            >
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Proposals -->
            </div>
            <!-- /Proposals list -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <freelancer-project-proposals-modal></freelancer-project-proposals-modal>
</template>
<script>
import Freelancer_Project_Proposals from "@/assets/json/freelancer-project-proposals.json";
export default {
  data() {
    return {
      Freelancer_Project_Proposals: Freelancer_Project_Proposals,
    };
  },
};
</script>
