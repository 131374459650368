<template>
  <div id="global-loader">
    <div class="whirly-loader"></div>
    <div class="loader-img">
      <img src="@/assets/img/load-icon.svg" class="img-fluid" alt="Image" />
    </div>
  </div>
</template>
<script>
export default {
  mounted() {},
};
</script>
