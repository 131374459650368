<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>

  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content container-fluid">
      <breadcrumbadmin :title="title" :text="text" :text1="text1" :text2="text2" />

      <div class="row">
        <div class="col-lg-12">
          <!-- Settings Menu -->
          <settingstabset />
          <!-- Settings Menu -->

          <div class="row">
            <div class="col-lg-6 col-md-8">
              <div class="card">
                <div class="card-body pt-0">
                  <div class="card-header">
                    <h5 class="card-title">Social Link Settings</h5>
                  </div>
                  <form>
                    <div class="settings-form">
                      <div class="links-info" v-for="item in event" :key="item.id">
                        <div class="row form-row links-cont">
                          <div class="col-12 col-md-11">
                            <div class="form-group form-placeholder d-flex">
                              <button class="btn social-icon">
                                <i :class="item.icon"></i>
                              </button>
                              <input
                                type="text"
                                class="form-control"
                                :placeholder="item.placeholder"
                              />
                            </div>
                          </div>
                          <div class="col-12 col-md-1">
                            <a
                              href="javascript:;"
                              class="btn trash"
                              @click="deleteEvent(event)"
                            >
                              <i class="feather-trash-2"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <a href="javascript:void(0);" class="btn add-links" @click="add()">
                        <i class="fas fa-plus me-1"></i> Add More
                      </a>
                    </div>
                    <div class="form-group mb-0">
                      <div class="settings-btns">
                        <button type="submit" variant="orange">Submit</button>
                        <button type="submit" variant="grey">Cancel</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Page Wrapper -->
</template>

<script>
const event = [
  {
    id: 1,
    icon: "feather-facebook",
    placeholder: "https://www.facebook.com",
  },
  {
    id: 2,
    icon: "feather-twitter",
    placeholder: "https://www.twitter.com",
  },
  {
    id: 3,
    icon: "feather-youtube",
    placeholder: "https://www.youtube.com",
  },
  {
    id: 4,
    icon: "feather-linkedin",
    placeholder: "https://www.linkedin.com",
  },
];

export default {
  data() {
    return {
      title: "Settings",
      text: "Home",
      text1: "Settings",
      text2: "Social Links",
      event,
    };
  },
  methods: {
    deleteEvent: function (event) {
      this.event.splice(this.event.indexOf(event), 1);
    },
    add() {
      this.event.push({
        id: 5,
        icon: "feather-github",
        placeholder: "Social Link",
      });
    },
  },
};
</script>
