<template>
  <div class="table-responsive">
    <a-table :columns="columns" :data-source="data">
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'Profile'">
          <h2 class="table-avatar">
            <router-link to="/admin/profile" class="review-text"
              ><img
                class="review-img avatar-img rounded-circle me-2"
                :src="require(`@/assets/admin_img/profiles/${record.Profile}`)"
                alt="User Image"
              />
              {{ record.Name }}
            </router-link>
          </h2>
        </template>
        <template v-else-if="column.key === 'inputbox'">
          <div class="form-check custom-checkbox">
            <input type="checkbox" class="form-check-input" id="customCheck5" />
            <label class="form-check-label" for="customCheck5"></label>
          </div>
        </template>
        <template v-else-if="column.key === 'Stars'">
          <i class="fas fa-star text-primary me-1"></i>
          <i class="fas fa-star text-primary me-1"></i>
          <i class="fas fa-star text-primary me-1"></i>
          <i class="fas fa-star text-primary me-1"></i>
          <i class="fas fa-star text-muted me-1"></i>
        </template>
        <template v-else-if="column.key === 'action'">
          <div class="text-end text-nowrap">
            <a href="javascript:void(0);" class="btn btn-approve text-white me-2"
              >Approve</a
            >
            <a href="javascript:void(0);" class="btn btn-disable">Enable</a>
          </div>
        </template>
      </template>
    </a-table>
  </div>
</template>

<script>
const columns = [
  {
    title: "#",
    key: "inputbox",
    sorter: true,
  },
  {
    title: "Profile",
    dataIndex: "Profile",
    key: "Profile",
    sorter: {
      compare: (a, b) => {
        a = a.Profile.toLowerCase();
        b = b.Profile.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Designation",
    dataIndex: "Designation",
    sorter: {
      compare: (a, b) => {
        a = a.Designation.toLowerCase();
        b = b.Designation.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "comments",
    dataIndex: "comments",
    sorter: {
      compare: (a, b) => {
        a = a.comments.toLowerCase();
        b = b.comments.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Stars",
    dataIndex: "Stars",
    key: "Stars",
    sorter: {
      compare: (a, b) => {
        a = a.Stars.toLowerCase();
        b = b.Stars.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Category",
    dataIndex: "Category",
    sorter: {
      compare: (a, b) => {
        a = a.Stars.toLowerCase();
        b = b.Stars.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Actions",
    key: "action",
    class: "text-end",
    sorter: true,
  },
];

const data = [
  {
    FIELD1: "",
    Profile: "avatar-04.jpg",
    Name: "Deboarah",
    Designation: "Associate Engineer",
    comments: "Lorem ipsum dolor sit amet, consectetur a...",
    Stars: "",
    Category: "React",
  },
  {
    FIELD1: "",
    Profile: "avatar-11.jpg",
    Name: "Sofia Briant",
    Designation: "Technial Manager",
    comments: "Lorem ipsum dolor sit amet, consectetur a...",
    Stars: "",
    Category: "JAVA",
  },
  {
    FIELD1: "",
    Profile: "avatar-02.jpg",
    Name: "Bess Twishes",
    Designation: "Designer",
    comments: "Lorem ipsum dolor sit amet, consectetur a...",
    Stars: "",
    Category: ".NET",
  },
  {
    FIELD1: "",
    Profile: "avatar-05.jpg",
    Name: "Rayan Lester",
    Designation: "Technial Manager",
    comments: "Lorem ipsum dolor sit amet, consectetur a...",
    Stars: "",
    Category: "Python",
  },
  {
    FIELD1: "",
    Profile: "avatar-06.jpg",
    Name: "Sarah Alexander",
    Designation: "Designer",
    comments: "Lorem ipsum dolor sit amet, consectetur a...",
    Stars: "",
    Category: "Golang",
  },
  {
    FIELD1: "",
    Profile: "avatar-14.jpg",
    Name: "Janet Paden",
    Designation: "CEO",
    comments: "Lorem ipsum dolor sit amet, consectetur a...",
    Stars: "",
    Category: "Angular",
  },
  {
    FIELD1: "",
    Profile: "avatar-01.jpg",
    Name: "George Wells",
    Designation: "Tech Lead",
    comments: "Lorem ipsum dolor sit amet, consectetur a...",
    Stars: "",
    Category: "Node",
  },
  {
    FIELD1: "",
    Profile: "avatar-15.jpg",
    Name: "Timothy Smith",
    Designation: "Technial Manager",
    comments: "Lorem ipsum dolor sit amet, consectetur a...",
    Stars: "",
    Category: "Angular",
  },
];

export default {
  data() {
    return {
      columns,
      data,
    };
  },
};
</script>
