<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper login-body">
    <div class="login-wrapper">
      <div class="container">
        <img
          class="img-fluid logo-dark mb-2"
          src="@/assets/admin_img/logo-01.png"
          alt="Logo"
        />

        <div class="loginbox">
          <div class="login-right">
            <div class="login-right-wrap">
              <h1>Forgot Password?</h1>
              <p class="account-subtitle">
                Enter your email to get a password reset link
              </p>

              <!-- Form -->
              <form action="/admin/login">
                <div class="form-group form-focus" :class="{ focused: isEmailFocused }">
                  <input
                    type="email"
                    class="form-control floating"
                    @focus="isEmailFocused = true"
                    @blur="handleEmailBlur"
                    v-model="emailInput"
                  />
                  <label
                    class="focus-label"
                    :class="{ active: isEmailFocused || emailInput !== '' }"
                    >Email</label
                  >
                </div>
                <div class="form-group mb-0">
                  <button
                    class="btn btn-primary btn-block btn-lg login-btn"
                    type="submit"
                    >FIND OUT PASSWORD</button
                  >
                </div>
              </form>
              <!-- /Form -->
              <div class="row form-row login-foot">
                <div class="col-lg-6 login-forgot">
                  <router-link class="forgot-link" to="/admin/login">Sign In</router-link>
                </div>
                <div class="col-lg-6 dont-have">
                  New to Kofejob? <router-link to="/admin/register">Join Now</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Main Wrapper -->
</template>
<script>
export default {
  data() {
    return {
      isEmailFocused: false,
      emailInput: "",
    };
  },
  methods: {
    handleEmailBlur() {
      if (this.emailInput.trim() === "") {
        this.isEmailFocused = false;
      }
    },
  },
};
</script>
