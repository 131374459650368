<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>

  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content container-fluid">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row align-items-center">
          <div class="col">
            <h3 class="page-title">Projects</h3>
            <ul class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link to="/admin/index">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Projects</li>
            </ul>
          </div>
          <div class="col-auto">
            <a
              class="btn filter-btn"
              href="javascript:void(0);"
              @click="toggleFilterInputs"
              id="filter_search"
            >
              <i class="fas fa-filter"></i>
            </a>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <!-- Search Filter -->
      <div
        class="card filter-card"
        :style="{ display: filterInputsDisplay }"
        id="filter_inputs"
      >
        <div class="card-body pb-0">
          <form action="javascript:;" method="post">
            <div class="row filter-row">
              <div class="col-sm-6 col-md-3">
                <div class="form-group">
                  <label>Company Name</label>
                  <input class="form-control" type="text" />
                </div>
              </div>
              <div class="col-sm-6 col-md-3">
                <div class="form-group">
                  <label>From Date</label>
                  <div class="cal-icon">
                    <datepicker
                      v-model="startdate"
                      placeholder="Choose Date"
                      class="form-control datetimepicker"
                      :editable="true"
                      :clearable="false"
                    />
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-3">
                <div class="form-group">
                  <label>To Date</label>
                  <div class="cal-icon">
                    <datepicker
                      v-model="startdate"
                      placeholder="Choose Date"
                      class="form-control datetimepicker"
                      :editable="true"
                      :clearable="false"
                    />
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-3">
                <div class="form-group">
                  <button class="btn btn-primary btn-block" type="submit">Submit</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <!-- /Search Filter -->

      <div class="card bg-white projects-card">
        <div class="card-body pt-0">
          <div class="card-header">
            <h5 class="card-title">Projects views</h5>
          </div>
          <div class="reviews-menu-links">
            <ul role="tablist" class="nav nav-pills card-header-pills nav-justified">
              <li class="nav-item">
                <a href="#tab-4" data-bs-toggle="tab" class="nav-link active"
                  >All (272)</a
                >
              </li>
              <li class="nav-item">
                <a href="#tab-5" data-bs-toggle="tab" class="nav-link">Active (218)</a>
              </li>
              <li class="nav-item">
                <a href="#tab-6" data-bs-toggle="tab" class="nav-link">
                  In Active (03)
                </a>
              </li>
              <li class="nav-item">
                <a href="#tab-7" data-bs-toggle="tab" class="nav-link">Trash (0)</a>
              </li>
            </ul>
          </div>
          <div class="tab-content pt-0">
            <div role="tabpanel" id="tab-4" class="tab-pane fade active show">
              <div class="showentries">
                <label
                  >Show
                  <select>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  entries
                </label>
                <div class="entries">
                  <span>Search: </span>
                  <input
                    type="search"
                    class="form-control form-control-sm ms-2"
                    placeholder=""
                    aria-controls="DataTables_Table_0"
                  />
                </div>
              </div>
              <div class="table-responsive">
                <a-table :columns="columns" :data-source="data">
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key === 'Logo'">
                      <h2 class="table-avatar">
                        <router-link to="/admin/profile"
                          ><img
                            class="img-fluid me-2"
                            :src="require(`@/assets/admin_img/company/${record.Logo}`)"
                            alt="User Image"
                          />
                        </router-link>
                      </h2>
                    </template>
                    <template v-else-if="column.key === 'inputbox'">
                      <div class="form-check custom-checkbox">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          id="customCheck5"
                        />
                        <label class="form-check-label" for="customCheck5"></label>
                      </div>
                    </template>
                    <template v-else-if="column.key === 'Progress'">
                      <div class="progress rounded-pill">
                        <div
                          class="progress-bar bg-primary rounded-pill"
                          role="progressbar"
                          :style="record.Progress"
                          aria-valuenow="25"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </template>
                    <template v-else-if="column.key === 'action'">
                      <div class="text-end">
                        <a
                          href="javascript:void(0);"
                          class="btn btn-sm btn-secondary me-2"
                          data-bs-toggle="modal"
                          data-bs-target="#add-category"
                          ><i class="far fa-edit"></i
                        ></a>
                        <a
                          href="javascript:void(0);"
                          class="btn btn-sm btn-danger"
                          data-bs-toggle="modal"
                          data-bs-target="#delete_category"
                          ><i class="far fa-trash-alt"></i
                        ></a>
                      </div>
                    </template>
                  </template>
                </a-table>
              </div>
            </div>
            <div role="tabpanel" id="tab-5" class="tab-pane fade">
              <projectone />
            </div>
            <div role="tabpanel" id="tab-6" class="tab-pane fade">
              <projecttwo />
            </div>
            <div role="tabpanel" id="tab-7" class="tab-pane fade">
              <projectthree />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Wrapper -->
  </div>

  <!-- Category Modal -->
  <div class="modal fade custom-modal" id="add-category">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Projects</h4>
          <button type="button" class="close" data-bs-dismiss="modal">
            <span>&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <form>
            <div class="form-group">
              <label>Title</label>
              <input
                type="text"
                class="form-control"
                placeholder="Website Designer Required For Directory Theme"
              />
            </div>
            <div class="form-group">
              <label>Budget</label>
              <input type="text" class="form-control" placeholder="$2222" />
            </div>
            <div class="form-group">
              <label>Technology</label>
              <input type="text" class="form-control" placeholder="Angler" />
            </div>
            <div class="form-group">
              <label>Technology</label>
              <input type="text" class="form-control" placeholder="AMAZE TECH" />
            </div>
            <div class="form-group">
              <label>From Date</label>
              <div class="cal-icon">
                <datepicker
                  v-model="startdate"
                  placeholder="20-01-2022"
                  class="form-control datetimepicker"
                  :editable="true"
                  :clearable="false"
                />
              </div>
            </div>
            <div class="form-group">
              <label>To Date</label>
              <div class="cal-icon">
                <datepicker
                  v-model="startdate"
                  placeholder="20-01-2022"
                  class="form-control datetimepicker"
                  :editable="true"
                  :clearable="false"
                />
              </div>
            </div>
            <div class="mt-4">
              <button type="submit" class="btn btn-primary btn-block">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- Category Modal -->

  <delete />
</template>

<script>
const columns = [
  {
    title: "#",
    key: "inputbox",
    sorter: true,
  },
  {
    title: "Logo",
    dataIndex: "Logo",
    key: "Logo",
    sorter: {
      compare: (a, b) => {
        a = a.Logo.toLowerCase();
        b = b.Logo.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Title",
    dataIndex: "Title",
    sorter: true,
  },
  {
    title: "Budget",
    dataIndex: "Budget",
    sorter: {
      compare: (a, b) => {
        a = a.Budget.toLowerCase();
        b = b.Budget.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Progress",
    dataIndex: "Progress",
    key: "Progress",
    sorter: {
      compare: (a, b) => {
        a = a.Progress.toLowerCase();
        b = b.Progress.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Technology",
    dataIndex: "Technology",
    sorter: {
      compare: (a, b) => {
        a = a.Technology.toLowerCase();
        b = b.Technology.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Company",
    dataIndex: "Company",
    sorter: {
      compare: (a, b) => {
        a = a.Company.toLowerCase();
        b = b.Company.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Startdate",
    dataIndex: "Startdate",
    sorter: {
      compare: (a, b) => {
        a = a.Startdate.toLowerCase();
        b = b.Startdate.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Due date",
    dataIndex: "Duedate",
    sorter: {
      compare: (a, b) => {
        a = a.Duedate.toLowerCase();
        b = b.Duedate.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "action",
    sorter: true,
  },
];

const data = [
  {
    FIELD1: "",
    Logo: "img-1.png",
    Title: "Website Designer Required For Directory Theme",
    Budget: "$2222",
    Progress: "width: 25%",
    Technology: "Angular",
    Company: "AMAZE TECH",
    Startdate: "22-05-2023",
    Duedate: "22-05-2023",
  },
  {
    FIELD1: "",
    Logo: "img-2.png",
    Title: "Lorem Ipsum is simply dummy text of",
    Budget: "$5755",
    Progress: "width: 60%",
    Technology: "Laravel",
    Company: "Park INC",
    Startdate: "22-05-2023",
    Duedate: "22-05-2023",
  },
  {
    FIELD1: "",
    Logo: "img-3.png",
    Title: "It is a long established fact that a reader",
    Budget: "$5755",
    Progress: "width: 30%",
    Technology: "Vue",
    Company: "Tsch Zone",
    Startdate: "22-05-2023",
    Duedate: "22-05-2023",
  },
  {
    FIELD1: "",
    Logo: "img-4.png",
    Title: "There are many variations of passages of Lorem",
    Budget: "$2333",
    Progress: "width: 50%",
    Technology: "Golang",
    Company: "ABC Software",
    Startdate: "22-05-2023",
    Duedate: "22-05-2023",
  },
  {
    FIELD1: "",
    Logo: "img-5.png",
    Title: "Website Designer Required For Directory Theme",
    Budget: "$2222",
    Progress: "width: 25%",
    Technology: "Node js",
    Company: "Host Technologies",
    Startdate: "22-05-2023",
    Duedate: "22-05-2023",
  },
  {
    FIELD1: "",
    Logo: "img-6.png",
    Title: "combined with a handful of model sentence structures",
    Budget: "$1500",
    Progress: "width: 45%",
    Technology: ".Net",
    Company: "SM Developer",
    Startdate: "22-05-2023",
    Duedate: "22-05-2023",
  },
  {
    FIELD1: "",
    Logo: "img-7.png",
    Title: "Designer Required For Directory",
    Budget: "$2222",
    Progress: "width: 25%",
    Technology: "Java",
    Company: "Kind Software",
    Startdate: "22-05-2023",
    Duedate: "22-05-2023",
  },
  {
    FIELD1: "",
    Logo: "img-8.png",
    Title: "Lorem Ipsum is therefore always free content",
    Budget: "$7789",
    Progress: "width: 56%",
    Technology: "Python",
    Company: "Particles INC",
    Startdate: "22-05-2023",
    Duedate: "22-05-2023",
  },
  {
    FIELD1: "",
    Logo: "img-9.png",
    Title: "Required For Website Developer",
    Budget: "$2222",
    Progress: "width: 25%",
    Technology: "Codingnator",
    Company: "Kind Software",
    Startdate: "22-05-2023",
    Duedate: "22-05-2023",
  },
  {
    FIELD1: "",
    Logo: "img-10.png",
    Title: "Lorem Ipsum is therefore always free",
    Budget: "$7789",
    Progress: "width: 56%",
    Technology: "React",
    Company: "Particles INC",
    Startdate: "22-05-2023",
    Duedate: "22-05-2023",
  },
];

import { ref } from "vue";
const currentDate = ref(new Date());
export default {
  data() {
    return {
      title: "Projects",
      text: "Home",
      text1: "Projects",
      columns,
      data,
      filterInputsDisplay: "none",
      startdate: currentDate,
    };
  },
  methods: {
    toggleFilterInputs() {
      this.filterInputsDisplay = this.filterInputsDisplay === "none" ? "block" : "none";
    },
  },
};
</script>
