<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>

  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content container-fluid">
      <breadcrumbadmin :title="title" :text="text" :text1="text1" :text2="text2" />

      <div class="row">
        <div class="col-lg-12">
          <!-- Settings Menu -->
          <settingstabset />
          <!-- Settings Menu -->

          <div class="row">
            <div class="col-md-6">
              <div class="card">
                <div class="card-body pt-0">
                  <div class="card-header">
                    <h5 class="card-title">SEO Settings</h5>
                  </div>
                  <form>
                    <div class="settings-form">
                      <div class="form-group form-placeholder">
                        <label>Meta Title <span class="star-red">*</span></label>
                        <input type="text" class="form-control" />
                      </div>
                      <div class="form-group">
                        <label>Meta Keywords <span class="star-red">*</span></label>
                        <vue3-tags-input
                          type="text"
                          data-role="tagsinput"
                          id="services"
                          class="input-tags form-control"
                          name="services"
                          :tags="tags"
                          placeholder="Meta Keywords"
                        />
                      </div>
                      <div class="form-group">
                        <label>Meta Description <span class="star-red">*</span></label>
                        <textarea class="form-control"></textarea>
                      </div>
                      <div class="form-group mb-0">
                        <div class="settings-btns">
                          <button type="submit" variant="orange">Submit</button>
                          <button type="submit" variant="grey">Cancel</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Page Wrapper -->
</template>

<script>
import Vue3TagsInput from "vue3-tags-input";
export default {
  components: {
    Vue3TagsInput,
  },
  data() {
    return {
      title: "Settings",
      text: "Home",
      text1: "Settings",
      text2: "Seo Settings",
      tags: ["Lorem", "Ipsum"],
      myValue: "",
    };
  },
};
</script>
