import { createApp } from 'vue'
import { router } from './router';
import App from './App.vue'
import {BootstrapVue3, BToastPlugin} from 'bootstrap-vue-3'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/reset.css';
import VueSelect from 'vue3-select2-component'
import VCalendar from 'v-calendar';
import VueFeather from 'vue-feather';
import VueApexCharts from "vue3-apexcharts";
import DatePicker from 'vue3-datepicker'
import Vue3Autocounter from 'vue3-autocounter';
import VueSlickCarousel from 'vue-slick-carousel'
import VueEasyLightbox from "vue-easy-lightbox";

/**************** Layouts Components  *************/
import Header from '@/views/frontend/layouts/header.vue'
import HeaderTwo from '@/views/frontend/layouts/header-two.vue'
import HeaderThree from '@/views/frontend/layouts/header-three.vue'
import HeaderFour from '@/views/frontend/layouts/header-four.vue'
import HeaderFive from '@/views/frontend/layouts/header-five.vue'
import HeaderMenu from '@/views/frontend/layouts/header-menu.vue'
import Footer from '@/views/frontend/layouts/footer.vue'
import Freelancer_Header from '@/views/frontend/layouts/freelancer-header.vue'
import Employer_Header from '@/views/frontend/layouts/employer-header.vue'
import Notification from '@/views/frontend/layouts/notification.vue'
import Loader from '@/views/frontend/layouts/loader.vue'

/**************** frontend  *************/


/**************** Home Component  *************/
import IndexBanner from '@/views/frontend/pages/index/home/index-banner.vue'
import IndexDeveloped from '@/views/frontend/pages/index/home/index-developed.vue'
import IndexProject from '@/views/frontend/pages/index/home/index-project.vue'
import IndexInstructor from '@/views/frontend/pages/index/home/index-instructor.vue'
import IndexReview from '@/views/frontend/pages/index/home/index-review.vue'
import IndexNews from '@/views/frontend/pages/index/home/index-news.vue'
import HomeTwoBanner from '@/views/frontend/pages/index/home2/home-two-banner.vue'
import FeaturedProjects from '@/views/frontend/pages/index/home2/featured-projects.vue'
import GreatAbout from '@/views/frontend/pages/index/home2/great-about.vue'
import CompanyHire from '@/views/frontend/pages/index/home2/company-hire.vue'
import FooterTwo from '@/views/frontend/pages/index/home2/footer-two.vue'
import HomeThreeBanner from '@/views/frontend/pages/index/home3/home-three-banner.vue'
import MarketPlace from '@/views/frontend/pages/index/home3/market-place.vue'
import Trending from '@/views/frontend/pages/index/home3/trending.vue'
import HomeThreeFeature from '@/views/frontend/pages/index/home3/home-three-feature.vue'
import TopSkill from '@/views/frontend/pages/index/home3/top-skill.vue'
import HomeThreeFaq from '@/views/frontend/pages/index/home3/home-three-faq.vue'
import FooterThree from '@/views/frontend/pages/index/home3/footer-three.vue'
import HomeFourHomeBanner from '@/views/frontend/pages/index/home4/home-banner.vue'
import HomeFourCategories from '@/views/frontend/pages/index/home4/categories.vue'
import HomeFourProjects from '@/views/frontend/pages/index/home4/projects.vue'
import HomeFourDevelopers from '@/views/frontend/pages/index/home4/developers.vue'
import HomeFourMarketPlace from '@/views/frontend/pages/index/home4/marketplace.vue'
import HomeFourFeature from '@/views/frontend/pages/index/home4/feature.vue'
import HomeFourTestimonial from '@/views/frontend/pages/index/home4/testimonial.vue'
import HomeFourPlatform from '@/views/frontend/pages/index/home4/platform.vue'
import HomeFourFaq from '@/views/frontend/pages/index/home4/faq.vue'
import HomeFourBlog from '@/views/frontend/pages/index/home4/blog.vue'
import FooterFour from '@/views/frontend/pages/index/home4/footer-four.vue'
import HomeFiveHomeBanner from '@/views/frontend/pages/index/home5/home-banner.vue'
import HomeFiveCategories from '@/views/frontend/pages/index/home5/categories.vue'
import HomeFiveProjects from '@/views/frontend/pages/index/home5/projects.vue'
import HomeFiveJob from '@/views/frontend/pages/index/home5/job.vue'
import HomeFiveMarketPlace from '@/views/frontend/pages/index/home5/marketplace.vue'
import HomeFiveDevelopers from '@/views/frontend/pages/index/home5/developers.vue'
import HomeFiveFaq from '@/views/frontend/pages/index/home5/faq.vue'
import HomeFiveCounter from '@/views/frontend/pages/index/home5/counter.vue'
import HomeFiveReview from '@/views/frontend/pages/index/home5/review.vue'
import HomeFiveClient from '@/views/frontend/pages/index/home5/client.vue'
import HomeFiveBlog from '@/views/frontend/pages/index/home5/blog.vue'
import FooterFive from '@/views/frontend/pages/index/home5/footer-five.vue'



/**************** Admin  *************/ 
// Components`
import PageBreadcrumb from '@/components/frontend/breadcrumb/pagebreadcrumb.vue'
import Breadcrumb from '@/components/frontend/breadcrumb/breadcrumb.vue'
import Freelancer_Portfolio_Modal from '@/components/frontend/modal/freelancer-portfolio-modal.vue'
import Freelancer_Review_Modal from '@/components/frontend/modal/freelancer-review-modal.vue'
import Freelancer_Password_Modal from '@/components/frontend/modal/freelancer-password-modal.vue'
import Freelancer_Verify_Modal from '@/components/frontend/modal/freelancer-verify-modal.vue'
import Freelancer_Membership_Modal from '@/components/frontend/modal/freelancer-membership-modal.vue'
import Freelancer_Project_Proposals_Modal from '@/components/frontend/modal/freelancer-project-proposals-modal.vue'
import Freelancer_Completed_Modal from '@/components/frontend/modal/freelancer-completed-modal.vue'
import Freelancer_Task_Modal from '@/components/frontend/modal/freelancer-task-modal.vue'
import Freelancer_Payment_Modal from '@/components/frontend/modal/freelancer-payment-modal.vue'
import Freelancer_Milestone_Modal from '@/components/frontend/modal/freelancer-milestone-modal.vue'


//frontend 
import About_Testimonial from '@/views/frontend/pages/pages/about-us/about-testimonial.vue'
import About from '@/views/frontend/pages/pages/about-us/about.vue'
import Archivement from '@/views/frontend/pages/pages/about-us/archivement.vue'
import Potential_About from '@/views/frontend/pages/pages/about-us/potential-about.vue'
import Job_About from '@/views/frontend/pages/pages/about-us/job-about.vue'
import Accounting_Onboard from '@/views/frontend/pages/pages/onboard-screen/accounting-onboard.vue'
import Others_Info from '@/views/frontend/pages/pages/onboard-screen/others-info.vue'
import Personal_Info from '@/views/frontend/pages/pages/onboard-screen/personal-info.vue'
import Skills_Experience from '@/views/frontend/pages/pages/onboard-screen/skills-experience.vue'
import Blog_Sidebar from '@/views/frontend/pages/blogs/blog-sidebar.vue'
import Freelancer_Sidebar from '@/views/frontend/pages/freelancer/freelancer-sidebar.vue'
import Membership_Table from '@/views/frontend/pages/freelancer/membership/membership-table.vue'
import Experience from '@/views/frontend/pages/freelancer/developer-profile/experience.vue'
import Reviews from '@/views/frontend/pages/freelancer/developer-profile/reviews.vue'
import Profile_Sidebar from '@/views/frontend/pages/freelancer/developer-profile/profile-sidebar.vue'
import EmployerPersonalInfo from '@/views/frontend/pages/pages/onboard-screen/employer-personal-info.vue'
import EmployerSkillsExperience from '@/views/frontend/pages/pages/onboard-screen/employer-skills-experience.vue'
import FreelancerSkills from '@/views/frontend/pages/freelancer/freelancer-profile-settings/skills.vue'
import FreelancerExperience from '@/views/frontend/pages/freelancer/freelancer-profile-settings/experience.vue'
import FreelancerEducation from '@/views/frontend/pages/freelancer/freelancer-profile-settings/education.vue'
import FreelancerCertification from '@/views/frontend/pages/freelancer/freelancer-profile-settings/certification.vue'
import FreelancerLanguage from '@/views/frontend/pages/freelancer/freelancer-profile-settings/language.vue'
import FreelancerSamedate from '@/views/frontend/pages/freelancer/freelancer-profile-settings/samedate.vue'
import ChatSidebar from '@/views/frontend/pages/employers/chats/chat-sidebar.vue'
import ChatRightSidebar from '@/views/frontend/pages/employers/chats/chat-right-sidebar.vue'
import ChatImage from '@/views/frontend/pages/employers/chats/chat-images.vue'
import ChatRightImage from '@/views/frontend/pages/employers/chats/chat-right-image.vue'
import ChatRightVideo from '@/views/frontend/pages/employers/chats/chat-right-video.vue'


//Modal

import managemodal from '@/components/frontend/modal/managemodal.vue'
import projectemployerviewproposal from '@/components/frontend/modal/projectemployerviewproposal.vue'
import Companyprofile from '@/components/frontend/modal/companyprofile.vue'
import Projectmilestone from '@/components/frontend/modal/projectmilestone.vue'
import Filesmodal from '@/components/frontend/modal/filesmodal.vue'
import Milestonesmodal from '@/components/frontend/modal/milestonesmodal.vue'
import Tasksmodal from '@/components/frontend/modal/tasksmodal.vue'
import Completedprojects from '@/components/frontend/modal/completedprojects.vue'
import Invitedfavourites from '@/components/frontend/modal/invitedfavourites.vue'
import Developerdetailsmodal from '@/components/frontend/modal/developerdetailsmodal.vue'
import Developermodal from '@/components/frontend/modal/developermodal.vue'
import Membershipplansmodal from '@/components/frontend/modal/membershipplansmodal.vue'
import Verifyidentitymodal from '@/components/frontend/modal/verifyidentitymodal.vue'
import ProjectModal from '@/components/frontend/modal/projectmodal.vue'
import Projectlist from '@/components/frontend/modal/projectlist.vue'
import Projectdetails from '@/components/frontend/modal/projectdetails.vue'





//Employer 

import ProjectSideBar from '@/components/frontend/projectsidebar/sidebar.vue'

//Freelancer projectsearch
import Projectsearch from '@/views/frontend/pages/freelancer/projectsearch.vue'
import Projectdetailssidebar from '@/views/frontend/pages/freelancer/project-details/projectdetailssidebar.vue'





//Admin 

// Breadcrumb
import BreadcrumbAdmin from '@/components/admin/breadcrumb/breadcrumbadmin.vue'
import SingleBreadcrumb from '@/components/admin/breadcrumb/singlebreadcrumb.vue'
import BreadcrumbThree from '@/components/admin/breadcrumb/breadcrumbthree.vue'

import AdminHeader from '@/views/admin/layouts/header.vue'
import Sidebar from '@/views/admin/layouts/sidebar.vue'
import SettingsSideMenu from '@/views/admin/layouts/settingssidemenu.vue'

//admin component
import AdminIndexReview from '@/views/admin/pages/home/adminindexreview.vue'
import Delete from '@/components/admin/delete.vue'
import DepositTabset from '@/components/admin/deposittabset.vue'
import ProjectOne from '@/views/admin/pages/projects/projectone.vue'
import ProjectTwo from '@/views/admin/pages/projects/projecttwo.vue'
import ProjectThree from '@/views/admin/pages/projects/projectthree.vue'
import FreelancerTabset from '@/components/admin/freelancertabset.vue'
import WithdrawnTabset from '@/components/admin/withdrawntabset.vue'
import TransactionTabset from '@/components/admin/transactiontabset.vue'
import SubscriptionTabset from '@/components/admin/subscriptiontabset.vue'
import ReportsTabset from '@/components/admin/reportstabset.vue'
import VerifyFreelance from '@/views/admin/pages/verify-identity/verify-freelance.vue'
import VerifyEmployer from '@/views/admin/pages/verify-identity/verify-employer.vue'
import SettingsTabset from '@/components/admin/settingstabset.vue'
import ReviewAll from '@/views/admin/pages/home/reviewall.vue'
import ReviewActive from '@/views/admin/pages/home/reviewactive.vue'
import ReviewPending from '@/views/admin/pages/home/reviewpending.vue'
import ReviewTrash from '@/views/admin/pages/home/reviewtrash.vue'
import EarningsTabset from '@/components/admin/earningtabset.vue'
import UsersTabset from '@/components/admin/userstabset.vue'
import ModelPopUp from '@/components/admin/modelpopup.vue'
import Showentries from '@/components/admin/showentries.vue'
import Projectdownload from '@/views/admin/pages/reports/projectdownload.vue'
import Earningdownload from '@/views/admin/pages/reports/earningdownload.vue'
import Servervalidation from '@/views/admin/pages/forms/servervalidation.vue'



//Admin Modal
import Taxmodal from '@/components/admin/taxmodal.vue'


// plugins
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/fontawesome.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'boxicons/css/boxicons.css'
import 'boxicons/css/boxicons.min.css'
import '@/assets/css/feather.css';


const app = createApp(App)


/**************** Layouts Components  *************/
app.component('layouts-header', Header)
app.component('header-two', HeaderTwo)
app.component('header-three', HeaderThree)
app.component('header-four', HeaderFour)
app.component('header-five', HeaderFive)
app.component('header-menu', HeaderMenu)
app.component('layouts-footer', Footer)
app.component('freelancer-header',Freelancer_Header)
app.component('employer-header',Employer_Header)
app.component('notification',Notification)
app.component('loader', Loader)

/**************** Home Component  *************/
app.component('index-banner', IndexBanner)
app.component('index-developed', IndexDeveloped)
app.component('index-project', IndexProject)
app.component('index-instructor', IndexInstructor)
app.component('index-review', IndexReview)
app.component('index-news', IndexNews)
app.component('home-two-banner', HomeTwoBanner)
app.component('featured-projects', FeaturedProjects)
app.component('great-about', GreatAbout)
app.component('company-hire', CompanyHire)
app.component('footer-two', FooterTwo)
app.component('home-three-banner', HomeThreeBanner)
app.component('market-place', MarketPlace)
app.component('trending', Trending)
app.component('home-three-feature', HomeThreeFeature)
app.component('top-skill', TopSkill)
app.component('home-three-faq', HomeThreeFaq)
app.component('footer-three', FooterThree)
app.component('home-four-homebanner',HomeFourHomeBanner)
app.component('home-four-categories',HomeFourCategories)
app.component('home-four-projects',HomeFourProjects)
app.component('home-four-developers',HomeFourDevelopers)
app.component('home-four-marketplace',HomeFourMarketPlace)
app.component('home-four-feature',HomeFourFeature)
app.component('home-four-testimonial',HomeFourTestimonial)
app.component('home-four-platform',HomeFourPlatform)
app.component('home-four-faq',HomeFourFaq)
app.component('home-four-blog',HomeFourBlog)
app.component('footer-four', FooterFour)
app.component('home-five-homebanner',HomeFiveHomeBanner)
app.component('home-five-categories',HomeFiveCategories)
app.component('home-five-projects',HomeFiveProjects)
app.component('home-five-job',HomeFiveJob)
app.component('home-five-marketplace',HomeFiveMarketPlace)
app.component('home-five-developers',HomeFiveDevelopers)
app.component('home-five-faq',HomeFiveFaq)
app.component('home-five-counter',HomeFiveCounter)
app.component('home-five-review',HomeFiveReview)
app.component('home-five-client',HomeFiveClient)
app.component('home-five-blog',HomeFiveBlog)
app.component('footer-five', FooterFive)

//sidebar
app.component('projectsidebar', ProjectSideBar)





// frontend Modalpopup
app.component('managemodal',managemodal)
app.component('projectemployerviewproposal',projectemployerviewproposal)
app.component('companyprofile',Companyprofile)
app.component('projectmilestone',Projectmilestone)
app.component('filesmodal',Filesmodal)
app.component('milestonesmodal',Milestonesmodal)
app.component('tasksmodal',Tasksmodal)
app.component('completedprojects',Completedprojects)
app.component('invitedfavourites',Invitedfavourites)
app.component('developerdetailsmodal',Developerdetailsmodal)
app.component('developermodal',Developermodal)
app.component('membershipplansmodal',Membershipplansmodal)
app.component('verifyidentitymodal',Verifyidentitymodal)
app.component('projectmodal',ProjectModal)
app.component('projectlist',Projectlist)
app.component('projectdetails',Projectdetails)



//freelancer projectsearch
app.component('projectsearch',Projectsearch)
app.component('projectdeatailsidebar',Projectdetailssidebar)




//Admin

// Breadcrumb
app.component('breadcrumbadmin', BreadcrumbAdmin)
app.component('singlebreadcrumb', SingleBreadcrumb)
app.component('breadcrumbthree', BreadcrumbThree)

app.component('layout-header', AdminHeader)
app.component('layout-sidebar', Sidebar)
app.component('settingssidemenu',SettingsSideMenu)



//Admin component
app.component('adminindexreview',AdminIndexReview)
app.component('delete',Delete)
app.component('deposittabset',DepositTabset)
app.component('projectone',ProjectOne)
app.component('projecttwo',ProjectTwo)
app.component('projectthree',ProjectThree)
app.component('freelancertabset',FreelancerTabset)
app.component('withdrawntabset',WithdrawnTabset)
app.component('transactiontabset',TransactionTabset)
app.component('subscriptiontabset',SubscriptionTabset)
app.component('reportstabset',ReportsTabset)
app.component('verify-freelance',VerifyFreelance)
app.component('verify-employer',VerifyEmployer)
app.component('settingstabset',SettingsTabset)
app.component('reviewall',ReviewAll)
app.component('reviewactive',ReviewActive)
app.component('reviewpending',ReviewPending)
app.component('reviewtrash',ReviewTrash)
app.component('earningsbatset',EarningsTabset)
app.component('userstabset',UsersTabset)
app.component('modelpopup',ModelPopUp)
app.component('showentries',Showentries)
app.component('projectdownload',Projectdownload)
app.component('earningdownload',Earningdownload)
app.component('servervalidation',Servervalidation)
app.component('freelancerskills',FreelancerSkills)
app.component('freelancerexperience',FreelancerExperience)
app.component('freelancereducation',FreelancerEducation)
app.component('freelancerlanguage',FreelancerLanguage)
app.component('freelancercertification',FreelancerCertification)
app.component('freelancersamedate',FreelancerSamedate)
app.component('chat-sidebar', ChatSidebar)
app.component('chat-right-sidebar', ChatRightSidebar)
app.component('chat-image', ChatImage)
app.component('chat-right-image', ChatRightImage)
app.component('chat-right-video', ChatRightVideo)

// Admin Modal
app.component('taxmodal',Taxmodal)

// Frontend breadcrumb
app.component('pagebreadcrumb',PageBreadcrumb)
app.component('breadcrumb',Breadcrumb)

// Frontend Modal
app.component('freelancer-portfolio-modal',Freelancer_Portfolio_Modal)
app.component('freelancer-review-modal',Freelancer_Review_Modal)
app.component('freelancer-password-modal',Freelancer_Password_Modal)
app.component('freelancer-verify-modal',Freelancer_Verify_Modal)
app.component('freelancer-membership-modal',Freelancer_Membership_Modal)
app.component('freelancer-project-proposals-modal',Freelancer_Project_Proposals_Modal)
app.component('freelancer-completed-modal',Freelancer_Completed_Modal)
app.component('freelancer-task-modal',Freelancer_Task_Modal)
app.component('freelancer-payment-modal',Freelancer_Payment_Modal)
app.component('freelancer-milestone-modal',Freelancer_Milestone_Modal)
// Frontend Component
app.component('about-testimonial',About_Testimonial)
app.component('About',About)
app.component('Archivement',Archivement)
app.component('job-about',Job_About)
app.component('potential-about',Potential_About)
app.component('accounting-onboard',Accounting_Onboard)
app.component('others-info',Others_Info)
app.component('personal-info',Personal_Info)
app.component('skills-experience',Skills_Experience)
app.component('blog-sidebar',Blog_Sidebar)
app.component('freelancer-sidebar',Freelancer_Sidebar)
app.component('membership-table',Membership_Table)
app.component('experience',Experience)
app.component('reviews',Reviews)
app.component('profile-sidebar',Profile_Sidebar)


app.component('employer-personal-info', EmployerPersonalInfo)
app.component('employer-skills-experience', EmployerSkillsExperience)

app.component('vue-slick-carousel', VueSlickCarousel)
app.component('vue3-autocounter', Vue3Autocounter)
app.component('vue-select', VueSelect)
app.component(VueFeather.name, VueFeather)
app.component('datepicker', DatePicker)
app.use(VueApexCharts)
app.use(VueEasyLightbox)
.use(BootstrapVue3)
.use(BToastPlugin)
.use(Antd)
app.use(VCalendar, {})
app.use(router).mount('#app');
