<template>
  <!-- Page Content -->
  <div class="content content-page bookmark">
    <div class="container">
      <div class="row">
        <projectsidebar></projectsidebar>

        <div class="col-xl-9 col-lg-8">
          <!-- project list -->
          <div class="my-projects-view favourite-group">
            <div class="row">
              <div class="col-lg-12">
                <div class="page-title">
                  <h3>Bookmarked Freelancers</h3>
                </div>
                <div class="freelance-box book-mark favour-book">
                  <div class="row">
                    <div class="col-md-6 col-lg-6 col-xl-4">
                      <div class="freelance-widget">
                        <div class="freelance-content">
                          <a href="javascript:void(0);" class="favourite color-active"
                            ><i class="feather-heart"></i
                          ></a>
                          <div class="freelance-img">
                            <router-link to="/employers/developer-details">
                              <img
                                src="@/assets/img/user/table-avatar-03.jpg"
                                alt="User Image"
                              />
                              <span class="verified"
                                ><i class="fas fa-check-circle"></i
                              ></span>
                            </router-link>
                          </div>
                          <div class="freelance-info">
                            <h3>
                              <router-link to="/employers/developer-details"
                                >George Wells</router-link
                              >
                            </h3>
                            <div class="freelance-specific">UI/UX Designer</div>
                            <div class="freelance-location">
                              <i class="feather-map-pin me-1"></i>Alabama, USA
                            </div>
                            <div class="rating">
                              <i class="fas fa-star filled"></i>
                              <i class="fas fa-star filled"></i>
                              <i class="fas fa-star filled"></i>
                              <i class="fas fa-star"></i>
                              <i class="fas fa-star"></i>
                              <span class="average-rating">4.7 (32)</span>
                            </div>
                            <div class="freelance-tags">
                              <a href="javascript:void(0);"
                                ><span class="badge badge-pill badge-design me-1"
                                  >Web Design</span
                                ></a
                              >
                              <a href="javascript:void(0);"
                                ><span class="badge badge-pill badge-design me-1"
                                  >UI Design</span
                                ></a
                              >
                              <a href="javascript:void(0);"
                                ><span class="badge badge-pill badge-design me-1"
                                  >Node Js</span
                                ></a
                              >
                            </div>
                            <div class="freelancers-price">$25 Hourly</div>
                          </div>
                        </div>
                        <div class="cart-hover">
                          <a href="javascript:void(0);" class="btn-invite-fav">Invite</a>
                          <router-link
                            to="/employers/developer-details"
                            class="btn-cart"
                            tabindex="-1"
                            >View Profile</router-link
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-6 col-xl-4">
                      <div class="freelance-widget">
                        <div class="freelance-content">
                          <a href="javascript:void(0);" class="favourite color-active"
                            ><i class="feather-heart"></i
                          ></a>
                          <div class="freelance-img">
                            <router-link to="/employers/developer-details">
                              <img src="@/assets/img/user/user-02.jpg" alt="User Image" />
                              <span class="verified"
                                ><i class="fas fa-check-circle"></i
                              ></span>
                            </router-link>
                          </div>
                          <div class="freelance-info">
                            <h3>
                              <router-link to="/employers/developer-details"
                                >Timothy Smith</router-link
                              >
                            </h3>
                            <div class="freelance-specific">PHP Developer</div>
                            <div class="freelance-location">
                              <i class="feather-map-pin me-1"></i>Illinois, USA
                            </div>
                            <div class="rating">
                              <i class="fas fa-star filled"></i>
                              <i class="fas fa-star filled"></i>
                              <i class="fas fa-star filled"></i>
                              <i class="fas fa-star"></i>
                              <i class="fas fa-star"></i>
                              <span class="average-rating">4.8 (55)</span>
                            </div>
                            <div class="freelance-tags">
                              <a href="javascript:void(0);"
                                ><span class="badge badge-pill badge-design me-1"
                                  >Web Design</span
                                ></a
                              >
                              <a href="javascript:void(0);"
                                ><span class="badge badge-pill badge-design me-1"
                                  >UI Design</span
                                ></a
                              >
                              <a href="javascript:void(0);"
                                ><span class="badge badge-pill badge-design me-1"
                                  >Node Js</span
                                ></a
                              >
                            </div>
                            <div class="freelancers-price">$21 Hourly</div>
                          </div>
                        </div>
                        <div class="cart-hover">
                          <a href="javascript:void(0);" class="btn-invite-fav">Invite</a>
                          <router-link
                            to="/employers/developer-details"
                            class="btn-cart"
                            tabindex="-1"
                            >View Profile</router-link
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-6 col-xl-4">
                      <div class="freelance-widget">
                        <div class="freelance-content">
                          <a href="javascript:void(0);" class="favourite color-active"
                            ><i class="feather-heart"></i
                          ></a>
                          <div class="freelance-img">
                            <router-link to="/employers/developer-details">
                              <img
                                src="@/assets/img/user/proposal-user-02.jpg"
                                alt="User Image"
                              />
                              <span class="verified"
                                ><i class="fas fa-check-circle"></i
                              ></span>
                            </router-link>
                          </div>
                          <div class="freelance-info">
                            <h3>
                              <router-link to="/employers/developer-details"
                                >Janet Paden</router-link
                              >
                            </h3>
                            <div class="freelance-specific">Graphic Designer</div>
                            <div class="freelance-location">
                              <i class="feather-map-pin me-1"></i>New York, USA
                            </div>
                            <div class="rating">
                              <i class="fas fa-star filled"></i>
                              <i class="fas fa-star filled"></i>
                              <i class="fas fa-star filled"></i>
                              <i class="fas fa-star"></i>
                              <i class="fas fa-star"></i>
                              <span class="average-rating">4.1 (30)</span>
                            </div>
                            <div class="freelance-tags">
                              <a href="javascript:void(0);"
                                ><span class="badge badge-pill badge-design me-1"
                                  >Web Design</span
                                ></a
                              >
                              <a href="javascript:void(0);"
                                ><span class="badge badge-pill badge-design me-1"
                                  >UI Design</span
                                ></a
                              >
                              <a href="javascript:void(0);"
                                ><span class="badge badge-pill badge-design me-1"
                                  >Node Js</span
                                ></a
                              >
                            </div>
                            <div class="freelancers-price">Free</div>
                          </div>
                        </div>
                        <div class="cart-hover">
                          <a href="javascript:void(0);" class="btn-invite-fav">Invite</a>
                          <router-link
                            to="/employers/developer-details"
                            class="btn-cart"
                            tabindex="-1"
                            >View Profile</router-link
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-6 col-xl-4">
                      <div class="freelance-widget">
                        <div class="freelance-content">
                          <a href="javascript:void(0);" class="favourite color-active"
                            ><i class="feather-heart"></i
                          ></a>
                          <div class="freelance-img">
                            <router-link to="/employers/developer-details">
                              <img
                                src="@/assets/img/user/table-avatar-02.jpg"
                                alt="User Image"
                              />
                              <span class="verified"
                                ><i class="fas fa-check-circle"></i
                              ></span>
                            </router-link>
                          </div>
                          <div class="freelance-info">
                            <h3>
                              <router-link to="/employers/developer-details"
                                >James Douglas</router-link
                              >
                            </h3>
                            <div class="freelance-specific">iOS Developer</div>
                            <div class="freelance-location">
                              <i class="feather-map-pin me-1"></i>Florida, USA
                            </div>
                            <div class="rating">
                              <i class="fas fa-star filled"></i>
                              <i class="fas fa-star filled"></i>
                              <i class="fas fa-star filled"></i>
                              <i class="fas fa-star"></i>
                              <i class="fas fa-star"></i>
                              <span class="average-rating">3.2 (22)</span>
                            </div>
                            <div class="freelance-tags">
                              <a href="javascript:void(0);"
                                ><span class="badge badge-pill badge-design me-1"
                                  >Web Design</span
                                ></a
                              >
                              <a href="javascript:void(0);"
                                ><span class="badge badge-pill badge-design me-1"
                                  >UI Design</span
                                ></a
                              >
                              <a href="javascript:void(0);"
                                ><span class="badge badge-pill badge-design me-1"
                                  >Node Js</span
                                ></a
                              >
                            </div>
                            <div class="freelancers-price">Free</div>
                          </div>
                        </div>
                        <div class="cart-hover">
                          <a href="javascript:void(0);" class="btn-invite-fav">Invite</a>
                          <router-link
                            to="/employers/developer-details"
                            class="btn-cart"
                            tabindex="-1"
                            >View Profile</router-link
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-6 col-xl-4">
                      <div class="freelance-widget">
                        <div class="freelance-content">
                          <a href="javascript:void(0);" class="favourite color-active"
                            ><i class="feather-heart"></i
                          ></a>
                          <div class="freelance-img">
                            <router-link to="/employers/developer-details">
                              <img
                                src="@/assets/img/user/table-avatar-05.jpg"
                                alt="User Image"
                              />
                              <span class="verified"
                                ><i class="fas fa-check-circle"></i
                              ></span>
                            </router-link>
                          </div>
                          <div class="freelance-info">
                            <h3>
                              <router-link to="/employers/developer-details"
                                >Floyd Lewis</router-link
                              >
                            </h3>
                            <div class="freelance-specific">SEO Developer</div>
                            <div class="freelance-location">
                              <i class="feather-map-pin me-1"></i>Texas, USA
                            </div>
                            <div class="rating">
                              <i class="fas fa-star filled"></i>
                              <i class="fas fa-star filled"></i>
                              <i class="fas fa-star filled"></i>
                              <i class="fas fa-star"></i>
                              <i class="fas fa-star"></i>
                              <span class="average-rating">4.9 (40)</span>
                            </div>
                            <div class="freelance-tags">
                              <a href="javascript:void(0);"
                                ><span class="badge badge-pill badge-design me-1"
                                  >Web Design</span
                                ></a
                              >
                              <a href="javascript:void(0);"
                                ><span class="badge badge-pill badge-design me-1"
                                  >UI Design</span
                                ></a
                              >
                              <a href="javascript:void(0);"
                                ><span class="badge badge-pill badge-design me-1"
                                  >Node Js</span
                                ></a
                              >
                            </div>
                            <div class="freelancers-price">$30 Hourly</div>
                          </div>
                        </div>
                        <div class="cart-hover">
                          <a href="javascript:void(0);" class="btn-invite-fav">Invite</a>
                          <router-link
                            to="/employers/developer-details"
                            class="btn-cart"
                            tabindex="-1"
                            >View Profile</router-link
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-6 col-xl-4">
                      <div class="freelance-widget">
                        <div class="freelance-content">
                          <a href="javascript:void(0);" class="favourite color-active"
                            ><i class="feather-heart"></i
                          ></a>
                          <div class="freelance-img">
                            <router-link to="/employers/developer-details">
                              <img src="@/assets/img/user/user-03.jpg" alt="User Image" />
                              <span class="verified"
                                ><i class="fas fa-check-circle"></i
                              ></span>
                            </router-link>
                          </div>
                          <div class="freelance-info">
                            <h3>
                              <router-link to="/employers/developer-details"
                                >Jacqueline Daye</router-link
                              >
                            </h3>
                            <div class="freelance-specific">SM Developer</div>
                            <div class="freelance-location">
                              <i class="feather-map-pin me-1"></i>California, USA
                            </div>
                            <div class="rating">
                              <i class="fas fa-star filled"></i>
                              <i class="fas fa-star filled"></i>
                              <i class="fas fa-star filled"></i>
                              <i class="fas fa-star"></i>
                              <i class="fas fa-star"></i>
                              <span class="average-rating">4.3 (35)</span>
                            </div>
                            <div class="freelance-tags">
                              <a href="javascript:void(0);"
                                ><span class="badge badge-pill badge-design me-1"
                                  >Web Design</span
                                ></a
                              >
                              <a href="javascript:void(0);"
                                ><span class="badge badge-pill badge-design me-1"
                                  >UI Design</span
                                ></a
                              >
                              <a href="javascript:void(0);"
                                ><span class="badge badge-pill badge-design me-1"
                                  >Node Js</span
                                ></a
                              >
                            </div>
                            <div class="freelancers-price">$12 Hourly</div>
                          </div>
                        </div>
                        <div class="cart-hover">
                          <a href="javascript:void(0);" class="btn-invite-fav">Invite</a>
                          <router-link
                            to="/employers/developer-details"
                            class="btn-cart"
                            tabindex="-1"
                            >View Profile</router-link
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-6 col-xl-4">
                      <div class="freelance-widget">
                        <div class="freelance-content">
                          <a href="javascript:void(0);" class="favourite color-active"
                            ><i class="feather-heart"></i
                          ></a>
                          <div class="freelance-img">
                            <router-link to="/employers/developer-details">
                              <img
                                src="@/assets/img/user/table-avatar-04.jpg"
                                alt="User Image"
                              />
                              <span class="verified"
                                ><i class="fas fa-check-circle"></i
                              ></span>
                            </router-link>
                          </div>
                          <div class="freelance-info">
                            <h3>
                              <router-link to="/employers/developer-details"
                                >Crystal Kemper</router-link
                              >
                            </h3>
                            <div class="freelance-specific">Network Engineer</div>
                            <div class="freelance-location">
                              <i class="feather-map-pin me-1"></i>Maryland, USA
                            </div>
                            <div class="rating">
                              <i class="fas fa-star filled"></i>
                              <i class="fas fa-star filled"></i>
                              <i class="fas fa-star filled"></i>
                              <i class="fas fa-star"></i>
                              <i class="fas fa-star"></i>
                              <span class="average-rating">3.5 (28)</span>
                            </div>
                            <div class="freelance-tags">
                              <a href="javascript:void(0);"
                                ><span class="badge badge-pill badge-design me-1"
                                  >Web Design</span
                                ></a
                              >
                              <a href="javascript:void(0);"
                                ><span class="badge badge-pill badge-design me-1"
                                  >UI Design</span
                                ></a
                              >
                              <a href="javascript:void(0);"
                                ><span class="badge badge-pill badge-design me-1"
                                  >Node Js</span
                                ></a
                              >
                            </div>
                            <div class="freelancers-price">$20 Hourly</div>
                          </div>
                        </div>
                        <div class="cart-hover">
                          <a href="javascript:void(0);" class="btn-invite-fav">Invite</a>
                          <router-link
                            to="/employers/developer-details"
                            class="btn-cart"
                            tabindex="-1"
                            >View Profile</router-link
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-6 col-xl-4">
                      <div class="freelance-widget">
                        <div class="freelance-content">
                          <a href="javascript:void(0);" class="favourite color-active"
                            ><i class="feather-heart"></i
                          ></a>
                          <div class="freelance-img">
                            <router-link to="/employers/developer-details">
                              <img
                                src="@/assets/img/user/avatar-8.jpg"
                                alt="User Image"
                              />
                              <span class="verified"
                                ><i class="fas fa-check-circle"></i
                              ></span>
                            </router-link>
                          </div>
                          <div class="freelance-info">
                            <h3>
                              <router-link to="/employers/developer-details"
                                >Tony Ingle</router-link
                              >
                            </h3>
                            <div class="freelance-specific">Business Analyst</div>
                            <div class="freelance-location">
                              <i class="feather-map-pin me-1"></i>Missouri, USA
                            </div>
                            <div class="rating">
                              <i class="fas fa-star filled"></i>
                              <i class="fas fa-star filled"></i>
                              <i class="fas fa-star"></i>
                              <i class="fas fa-star"></i>
                              <i class="fas fa-star"></i>
                              <span class="average-rating">2.1 (15)</span>
                            </div>
                            <div class="freelance-tags">
                              <a href="javascript:void(0);"
                                ><span class="badge badge-pill badge-design me-1"
                                  >Web Design</span
                                ></a
                              >
                              <a href="javascript:void(0);"
                                ><span class="badge badge-pill badge-design me-1"
                                  >UI Design</span
                                ></a
                              >
                              <a href="javascript:void(0);"
                                ><span class="badge badge-pill badge-design me-1"
                                  >Node Js</span
                                ></a
                              >
                            </div>
                            <div class="freelancers-price">$42 Hourly</div>
                          </div>
                        </div>
                        <div class="cart-hover">
                          <a href="javascript:void(0);" class="btn-invite-fav">Invite</a>
                          <router-link
                            to="/employers/developer-details"
                            class="btn-cart"
                            tabindex="-1"
                            >View Profile</router-link
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-6 col-xl-4">
                      <div class="freelance-widget">
                        <div class="freelance-content">
                          <a href="javascript:void(0);" class="favourite color-active"
                            ><i class="feather-heart"></i
                          ></a>
                          <div class="freelance-img">
                            <router-link to="/employers/developer-details">
                              <img
                                src="@/assets/img/user/avatar-9.jpg"
                                alt="User Image"
                              />
                              <span class="verified"
                                ><i class="fas fa-check-circle"></i
                              ></span>
                            </router-link>
                          </div>
                          <div class="freelance-info">
                            <h3>
                              <router-link to="/employers/developer-details"
                                >Kathleen Kaiser</router-link
                              >
                            </h3>
                            <div class="freelance-specific">IT Project Manager</div>
                            <div class="freelance-location">
                              <i class="feather-map-pin me-1"></i>Kansas, USA
                            </div>
                            <div class="rating">
                              <i class="fas fa-star filled"></i>
                              <i class="fas fa-star filled"></i>
                              <i class="fas fa-star filled"></i>
                              <i class="fas fa-star filled"></i>
                              <i class="fas fa-star"></i>
                              <span class="average-rating">4.5 (36)</span>
                            </div>
                            <div class="freelance-tags">
                              <a href="javascript:void(0);"
                                ><span class="badge badge-pill badge-design me-1"
                                  >Web Design</span
                                ></a
                              >
                              <a href="javascript:void(0);"
                                ><span class="badge badge-pill badge-design me-1"
                                  >UI Design</span
                                ></a
                              >
                              <a href="javascript:void(0);"
                                ><span class="badge badge-pill badge-design me-1"
                                  >Node Js</span
                                ></a
                              >
                            </div>
                            <div class="freelancers-price">$25 Hourly</div>
                          </div>
                        </div>
                        <div class="cart-hover">
                          <a href="javascript:void(0);" class="btn-invite-fav">Invite</a>
                          <router-link
                            to="/employers/developer-details"
                            class="btn-cart"
                            tabindex="-1"
                            >View Profile</router-link
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <ul class="paginations list-pagination">
                  <li class="page-item">
                    <a href="javascript:void(0);"><i class="feather-chevron-left"></i></a>
                  </li>
                  <li class="page-item">
                    <a href="javascript:void(0);" class="active">1</a>
                  </li>
                  <li class="page-item"><a href="javascript:void(0);">2</a></li>
                  <li class="page-item"><a href="javascript:void(0);">3</a></li>
                  <li class="page-item"><a href="javascript:void(0);">...</a></li>
                  <li class="page-item"><a href="javascript:void(0);">10</a></li>
                  <li class="page-item">
                    <a href="javascript:void(0);"
                      ><i class="feather-chevron-right"></i
                    ></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- project list -->
        </div>
      </div>
    </div>
  </div>

  <!-- /Page Content -->
</template>
