<template>
  <section class="section review">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="aos aos-init" data-aos="fade-up">
            <div class="demand-professional">
              <h2>We unleash your business potential.</h2>
              <p>
                Through strategy, design, and planning we build brand identities that
                connect with your template. We then fine-tune a marketing plan that allows
                us to laser focus.
              </p>
            </div>
            <div class="demand-post-job align-items-start">
              <div class="demand-post-img">
                <img src="@/assets/img/icon/checks.svg" alt="Img" class="img-fluid" />
              </div>
              <div class="demand-content">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Est modi,
                  saepe hic esse maxime quasi, sapiente ex debitis quis dolorum unde,
                  quibusdam eveniet nobis enim porro repudiandae nesciunt quidem.
                </p>
              </div>
            </div>
            <div class="demand-post-job align-items-start">
              <div class="demand-post-img">
                <img src="@/assets/img/icon/checks.svg" alt="Img" class="img-fluid" />
              </div>
              <div class="demand-content">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Est modi,
                  saepe hic esse maxime quasi, sapiente ex debitis quis dolorum unde,
                  quibusdam eveniet nobis enim porro repudiandae nesciunt quidem.
                </p>
              </div>
            </div>
            <div class="demand-post-job align-items-start">
              <div class="demand-post-img">
                <img src="@/assets/img/icon/checks.svg" alt="Img" class="img-fluid" />
              </div>
              <div class="demand-content">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Est modi,
                  saepe hic esse maxime quasi, sapiente ex debitis quis dolorum unde,
                  quibusdam eveniet nobis enim porro repudiandae nesciunt quidem.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="work-set-image">
            <div class="recent-pro-img aos" data-aos="zoom-in" data-aos-duration="1000">
              <img src="@/assets/img/blog/abt3.png" alt="Img" class="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
