<template>
    <!-- Great About -->
    <section class="section great-about">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-sm-12 col-12 mx-auto">
                    <div class="section-header section-header-two text-center aos" data-aos="fade-up">
                        <h2 class="header-title">What's Great About <span>Kofejob</span></h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-3 col-md-6">
                    <div class="great-blk aos" data-aos="fade-up">
                        <div class="great-icon">
                            <img src="@/assets/img/icon/great-icon-01.svg" alt="Img">
                        </div>
                        <div class="great-content">
                            <h4>Browse portfolios</h4>
                            <p>Find professionals you can trust by browsing their samples of previous work and reading their
                                profile reviews.</p>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-md-6">
                    <div class="great-blk aos" data-aos="fade-up">
                        <div class="great-icon">
                            <img src="@/assets/img/icon/great-icon-02.svg" alt="Img">
                        </div>
                        <div class="great-content">
                            <h4>Fast bids</h4>
                            <p>Receive obligation free quotes from our talented freelancers fast. 80% of projects get bid on
                                within 60 seconds.</p>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-md-6">
                    <div class="great-blk aos" data-aos="fade-up">
                        <div class="great-icon">
                            <img src="@/assets/img/icon/great-icon-03.svg" alt="Img">
                        </div>
                        <div class="great-content">
                            <h4>Quality work</h4>
                            <p>Receive obligation free quotes from our talented freelancers fast. 80% of projects get bid on
                                within 60 seconds.</p>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-md-6">
                    <div class="great-blk aos" data-aos="fade-up">
                        <div class="great-icon">
                            <img src="@/assets/img/icon/great-icon-04.svg" alt="Img">
                        </div>
                        <div class="great-content">
                            <h4>Track progress</h4>
                            <p>Keep up-to-date and on-the-go with our time tracker, and mobile app. Always know what
                                freelancers are up to.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- /Great About -->

    <!-- About Project -->
    <section class="section about-project">
        <div class="about-position">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-6 d-flex">
                        <div class="about-it-blk w-100 aos" data-aos="fade-up">
                            <div class="about-it-img">
                                <a href="javascript:void(0);"><img class="img-fluid" src="@/assets/img/about-it-01.jpg"
                                        alt="Img"></a>
                            </div>
                            <div class="about-it-content text-center">
                                <h4>I need a developed Project</h4>
                                <p>Get the perfect Developed project for your budget from our creative community.</p>
                                <div class="more-project text-center mt-0">
                                    <router-link to="/freelancer/project" class="btn btn-primary project-post">Post a Project</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 d-flex">
                        <div class="about-it-blk w-100 aos" data-aos="fade-up">
                            <div class="about-it-img">
                                <a href="javascript:void(0);"><img class="img-fluid" src="@/assets/img/about-it-02.jpg"
                                        alt="Img"></a>
                            </div>
                            <div class="about-it-content text-center">
                                <h4>Find Your Next Great Job Opportunity!</h4>
                                <p>Do you want to earn money, find unlimited clients and build your freelance career?</p>
                                <div class="more-project text-center mt-0">
                                    <router-link to="/employers/developer-details" class="btn btn-primary start-bid">Start Bidding</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- /About Project -->

    <!-- Job Location -->
    <section class="section job-location home-two-jobsec">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-sm-12 col-12 mx-auto">
                    <div class="section-header section-header-two d-block text-center section-locate aos"
                        data-aos="fade-up">
                        <h2 class="header-title">Jobs By <span>Locations</span></h2>
                        <p>Find your favourite jobs and get the benefits of yourself</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-3 col-md-4">
                    <div class="job-locate-blk aos" data-aos="fade-up">
                        <div class="location-img">
                            <router-link to="/freelancer/project"><img class="img-fluid" src="@/assets/img/location/location-01.jpg"
                                    alt="Img"></router-link>
                        </div>
                        <div class="job-it-content">
                            <h6>Nevada, USA</h6>
                            <ul class="nav job-locate-foot">
                                <li>80 Companies</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-md-4">
                    <div class="job-locate-blk aos" data-aos="fade-up">
                        <div class="location-img">
                            <router-link to="/freelancer/project"><img class="img-fluid" src="@/assets/img/location/location-02.jpg"
                                    alt="Img"></router-link>
                        </div>
                        <div class="job-it-content">
                            <h6>London, UK</h6>
                            <ul class="nav job-locate-foot">
                                <li>40 Companies</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-md-4">
                    <div class="job-locate-blk aos" data-aos="fade-up">
                        <div class="location-img">
                            <router-link to="/freelancer/project"><img class="img-fluid" src="@/assets/img/location/location-03.jpg"
                                    alt="Img"></router-link>
                        </div>
                        <div class="job-it-content">
                            <h6>Bangalore, India</h6>
                            <ul class="nav job-locate-foot">
                                <li>50 Companies</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-md-4">
                    <div class="job-locate-blk aos" data-aos="fade-up">
                        <div class="location-img">
                            <router-link to="/freelancer/project"><img class="img-fluid" src="@/assets/img/location/location-04.jpg"
                                    alt="Img"></router-link>
                        </div>
                        <div class="job-it-content">
                            <h6>Newyork, USA</h6>
                            <ul class="nav job-locate-foot">
                                <li>60 Companies</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-md-4">
                    <div class="job-locate-blk aos" data-aos="fade-up">
                        <div class="location-img">
                            <router-link to="/freelancer/project"><img class="img-fluid" src="@/assets/img/location/location-05.jpg"
                                    alt="Img"></router-link>
                        </div>
                        <div class="job-it-content">
                            <h6>Paris, France</h6>
                            <ul class="nav job-locate-foot">
                                <li>80 Companies</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-md-4">
                    <div class="job-locate-blk aos" data-aos="fade-up">
                        <div class="location-img">
                            <router-link to="/freelancer/project"><img class="img-fluid" src="@/assets/img/location/location-06.jpg"
                                    alt="Img"></router-link>
                        </div>
                        <div class="job-it-content">
                            <h6>Berlin, Germany</h6>
                            <ul class="nav job-locate-foot">
                                <li>50 Companies</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-md-4">
                    <div class="job-locate-blk aos" data-aos="fade-up">
                        <div class="location-img">
                            <router-link to="/freelancer/project"><img class="img-fluid" src="@/assets/img/location/location-07.jpg"
                                    alt="Img"></router-link>
                        </div>
                        <div class="job-it-content">
                            <h6>Amsterdam, Netherland</h6>
                            <ul class="nav job-locate-foot">
                                <li>30 Companies</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-md-4">
                    <div class="job-locate-blk aos" data-aos="fade-up">
                        <div class="location-img">
                            <router-link to="/freelancer/project"><img class="img-fluid" src="@/assets/img/location/location-08.jpg"
                                    alt="Img"></router-link>
                        </div>
                        <div class="job-it-content">
                            <h6>California, USA</h6>
                            <ul class="nav job-locate-foot">
                                <li>70 Companies</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-xl-12">
                    <div class="more-project text-center aos aos-init aos-animate" data-aos="fade-up">
                        <router-link to="/freelancer/project" class="btn btn-primary">View More Locations</router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- /Job Location -->
</template>