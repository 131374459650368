<template>
  <!-- Page Content -->
  <div class="content content-page">
    <div class="container">
      <div class="row">
        <projectsidebar></projectsidebar>

        <div class="col-xl-9 col-lg-8">
          <div class="dashboard-sec">
            <div class="page-title">
              <h3>Dashboard</h3>
            </div>
            <div class="row">
              <div class="col-md-6 col-lg-4 col-xl-3">
                <div class="dash-widget">
                  <div class="dash-info">
                    <div class="dashboard-icon">
                      <img
                        src="@/assets/img/icon/freelancer-dashboard-icon-01.svg"
                        alt="Img"
                      />
                    </div>
                    <div class="dash-widget-info">Completed Jobs</div>
                  </div>
                  <div
                    class="dash-widget-more d-flex align-items-center justify-content-between"
                  >
                    <div class="dash-widget-count">30</div>
                    <router-link to="/freelancer/freelancer-completed-projects" class="d-flex"
                      >View Details</router-link
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-4 col-xl-3">
                <div class="dash-widget">
                  <div class="dash-info">
                    <div class="dashboard-icon dashboard-icon-two">
                      <img
                        src="@/assets/img/icon/freelancer-dashboard-icon-02.svg"
                        alt="Img"
                      />
                    </div>
                    <div class="dash-widget-info">Task Completed</div>
                  </div>
                  <div
                    class="dash-widget-more d-flex align-items-center justify-content-between"
                  >
                    <div class="dash-widget-count">5</div>
                    <router-link to="/freelancer/freelancer-completed-projects" class="d-flex"
                      >View Details</router-link
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-4 col-xl-3">
                <div class="dash-widget">
                  <div class="dash-info">
                    <div class="dashboard-icon dashboard-icon-three">
                      <img
                        src="@/assets/img/icon/freelancer-dashboard-icon-03.svg"
                        alt="Img"
                      />
                    </div>
                    <div class="dash-widget-info">Reviews</div>
                  </div>
                  <div
                    class="dash-widget-more d-flex align-items-center justify-content-between"
                  >
                    <div class="dash-widget-count">25</div>
                    <router-link to="/freelancer/freelancer-completed-projects" class="d-flex"
                      >View Details</router-link
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-4 col-xl-3">
                <div class="dash-widget">
                  <div class="dash-info">
                    <div class="dashboard-icon dashboard-icon-four">
                      <img
                        src="@/assets/img/icon/freelancer-dashboard-icon-04.svg"
                        alt="Img"
                      />
                    </div>
                    <div class="dash-widget-info">Earning</div>
                  </div>
                  <div
                    class="dash-widget-more d-flex align-items-center justify-content-between"
                  >
                    <div class="dash-widget-count">5962</div>
                    <router-link to="/freelancer/freelancer-completed-projects" class="d-flex"
                      >View Details</router-link
                    >
                  </div>
                </div>
              </div>
            </div>

            <!-- Chart Content -->
            <div class="row">
              <div class="col-xl-8 d-flex">
                <div class="card flex-fill ongoing-project-card">
                  <div class="pro-head">
                    <h5 class="card-title mb-0">Overview</h5>
                    <div class="month-detail">
                      <vue-select
                        :options="Months"
                        id="months"
                        placeholder="Last 6 months"
                      />
                    </div>
                  </div>
                  <div class="pro-body p-0">
                    <apexchart
                      type="line"
                      height="300"
                      :options="chartprofile.profile"
                      :series="chartprofile.series"
                    ></apexchart>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 d-flex">
                <div class="flex-fill card ongoing-project-card">
                  <div class="pro-head b-0">
                    <h5 class="card-title mb-0">Static Analytics</h5>
                  </div>
                  <div class="pro-body p-0">
                    <apexchart
                      type="radialBar"
                      height="250"
                      :options="chartradial.radial"
                      :series="chartradial.series"
                    ></apexchart>
                    <div class="d-flex flex-wrap justify-content-between">
                      <ul class="static-list">
                        <li>
                          <span><i class="fas fa-circle text-violet me-1"></i>Jobs</span>
                        </li>
                        <!-- <li><span><i class="fas fa-circle text-pink me-1"></i> Applied Proposals</span></li> -->
                        <li>
                          <span
                            ><i class="fas fa-circle text-yellow me-1"></i>Proposals</span
                          >
                        </li>
                        <!-- <li><span><i class="fas fa-circle text-blue me-1"></i>Bookmarked Projects</span></li> -->
                      </ul>
                      <ul class="static-list">
                        <!-- <li><span><i class="fas fa-circle text-violet me-1"></i>Jobs</span></li> -->
                        <li>
                          <span
                            ><i class="fas fa-circle text-pink me-1"></i> Applied
                            Proposals</span
                          >
                        </li>
                        <!-- <li><span><i class="fas fa-circle text-yellow me-1"></i>Proposals</span></li> -->
                        <li>
                          <span
                            ><i class="fas fa-circle text-blue me-1"></i>Bookmarked
                            Projects</span
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Chart Content -->

            <div class="row">
              <!-- Past Earnings -->
              <div class="col-xl-12">
                <div class="card mb-4 ongoing-project-card">
                  <div class="pro-head">
                    <h2>Recent Earnings</h2>
                    <router-link to="/employers/view-project-detail" class="btn fund-btn"
                      >View All</router-link
                    >
                  </div>
                  <div class="table-responsive recent-earnings flex-fill">
                    <table class="table mb-0">
                      <thead>
                        <tr>
                          <th>Details</th>
                          <th>Job Type</th>
                          <th>Budget</th>
                          <th>Create On</th>
                          <th>Expiring On</th>
                          <th>Proposals</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="record in Recent_Earnings" :key="record.id">
                          <td>{{ record.Details }}</td>
                          <td>{{ record.JobType }}</td>
                          <td>{{ record.Budget }}</td>
                          <td>{{ record.CreateOn }}</td>
                          <td>{{ record.ExpiringOn }}</td>
                          <td>{{ record.Proposals }}</td>
                          <td>
                            <a href="javascript:void(0);"><i class="feather-eye"></i></a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <!-- /Past Earnings -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Page Content -->
</template>

<script>
import {
  chartprofile,
  chartradial,
} from "@/views/frontend/pages/freelancer/dashboard/data.js";
import Recent_Earnings from "@/assets/json/recent-earnings.json";
export default {
  data() {
    return {
      Recent_Earnings: Recent_Earnings,
      Months: ["Last 6 months", "Last 2 months"],
      chartradial: chartradial,
      chartprofile: chartprofile,
    };
  },
};
</script>
