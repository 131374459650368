<template>
  <div class="account-page">
    <div class="login-wrapper">
      <div class="content w-100">
        <!-- Login Content -->
        <div class="account-content">
          <div class="align-items-center justify-content-center">
            <div class="login-right">
              <div class="login-header text-center">
                <router-link to="/home/"
                  ><img src="@/assets/img/logo.svg" alt="logo" class="img-fluid"
                /></router-link>
                <h3>We love to see you joining our community</h3>
              </div>
              <nav class="user-tabs mb-4">
                <ul role="tablist" class="nav nav-pills nav-justified">
                  <li class="nav-item">
                    <a
                      href="#developer"
                      @click="activateTab('developer')"
                      :class="{ 'nav-link': true, active: activeTab === 'developer' }"
                      >Freelancer</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      href="#company"
                      @click="activateTab('company')"
                      :class="{ 'nav-link': true, active: activeTab === 'company' }"
                      >Company</a
                    >
                  </li>
                </ul>
              </nav>
              <div class="tab-content pt-0">
                <div role="tabpanel" id="developer" class="tab-pane fade active show">
                  <Form
                    @submit="onSubmit"
                    :validation-schema="schema"
                    v-slot="{ errors }"
                  >
                    <div class="input-block">
                      <label class="focus-label"
                        >User Name <span class="label-star"> *</span></label
                      >
                      <input type="email" class="form-control floating" />
                    </div>
                    <div class="input-block">
                      <label class="focus-label"
                        >Email Address<span class="label-star"> *</span></label
                      >
                      <Field
                        name="email"
                        type="text"
                        class="form-control"
                        placeholder="Email"
                        :class="{ 'is-invalid': errors.email }"
                      />
                      <div class="invalid-feedback">{{ errors.email }}</div>
                      <div class="emailshow text-danger" id="email"></div>
                    </div>
                    <div class="input-block">
                      <label class="focus-label"
                        >Password <span class="label-star"> *</span></label
                      >
                      <div class="position-relative">
                        <Field
                          name="password"
                          :type="showPassword ? 'text' : 'password'"
                          class="form-control pass-input mt-2"
                          placeholder="Password"
                          :class="{ 'is-invalid': errors.password }"
                        />
                        <span @click="toggleShow" class="toggle-password2">
                          <i
                            :class="{
                              'fas fa-eye': showPassword,
                              'fas fa-eye-slash': !showPassword,
                            }"
                          ></i>
                        </span>
                        <div class="invalid-feedback">{{ errors.password }}</div>
                        <div class="emailshow text-danger" id="password"></div>
                      </div>
                    </div>
                    <div class="input-block mb-0">
                      <label class="focus-label"
                        >Confirm Password <span class="label-star"> *</span></label
                      >
                      <div class="position-relative">
                        <Field
                          name="confirmpassword"
                          :type="showPassword1 ? 'text' : 'password'"
                          class="form-control pass-input mt-2"
                          :class="{ 'is-invalid': errors.confirmpassword }"
                        />
                        <span @click="toggleShow1" class="toggle-password2">
                          <i
                            :class="{
                              'fas fa-eye': showPassword1,
                              'fas fa-eye-slash': !showPassword1,
                            }"
                          ></i>
                        </span>
                        <div class="invalid-feedback">{{ errors.confirmpassword }}</div>
                        <div class="emailshow text-danger" id="confirmpassword"></div>
                      </div>
                    </div>
                    <div class="dont-have">
                      <label class="custom_check">
                        <input type="checkbox" name="rem_password" />
                        <span class="checkmark"></span> I have read and agree to all
                        <router-link to="/privacy-policy"
                          >Terms &amp; Conditions</router-link
                        >
                      </label>
                    </div>
                    <button
                      class="btn btn-primary w-100 btn-lg login-btn d-flex align-items-center justify-content-center"
                      type="submit"
                    >
                      Sign In Now<i class="feather-arrow-right ms-2"></i>
                    </button>
                    <div class="login-or">
                      <p><span>Or</span></p>
                    </div>
                    <div class="row social-login">
                      <div class="col-sm-4">
                        <a href="javascript:void(0);" class="btn btn-block"
                          ><img
                            src="@/assets/img/icon/google-icon.svg"
                            alt="Google"
                          />Google</a
                        >
                      </div>
                      <div class="col-sm-4">
                        <a href="javascript:void(0);" class="btn btn-block"
                          ><img src="@/assets/img/icon/fb-icon.svg" alt="Fb" />Facebook</a
                        >
                      </div>
                      <div class="col-sm-4">
                        <a href="javascript:void(0);" class="btn btn-block"
                          ><img
                            src="@/assets/img/icon/ios-icon.svg"
                            alt="Apple"
                          />Apple</a
                        >
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-8 dont-have d-flex align-items-center">
                        Already have account
                        <router-link to="/" class="ms-2">Sign in?</router-link>
                      </div>
                    </div>
                  </Form>
                </div>
                <div role="tabpanel" id="company" class="tab-pane fade">
                  <Form
                    @submit="onSubmit"
                    :validation-schema="schema"
                    v-slot="{ errors }"
                  >
                    <div class="input-block">
                      <label class="focus-label"
                        >User Name <span class="label-star"> *</span></label
                      >
                      <input type="email" class="form-control floating" />
                    </div>
                    <div class="input-block">
                      <label class="focus-label"
                        >Email Address<span class="label-star"> *</span></label
                      >
                      <Field
                        name="email"
                        type="text"
                        class="form-control"
                        placeholder="Email"
                        :class="{ 'is-invalid': errors.email }"
                      />
                      <div class="invalid-feedback">{{ errors.email }}</div>
                      <div class="emailshow text-danger" id="email"></div>
                    </div>
                    <div class="input-block">
                      <label class="focus-label"
                        >Password <span class="label-star"> *</span></label
                      >
                      <div class="position-relative">
                        <Field
                          name="password"
                          :type="showPassword ? 'text' : 'password'"
                          class="form-control pass-input mt-2"
                          placeholder="Password"
                          :class="{ 'is-invalid': errors.password }"
                        />
                        <span @click="toggleShow" class="toggle-password2">
                          <i
                            :class="{
                              'fas fa-eye': showPassword,
                              'fas fa-eye-slash': !showPassword,
                            }"
                          ></i>
                        </span>
                        <div class="invalid-feedback">{{ errors.password }}</div>
                        <div class="emailshow text-danger" id="password"></div>
                      </div>
                    </div>
                    <div class="input-block mb-0">
                      <label class="focus-label"
                        >Confirm Password <span class="label-star"> *</span></label
                      >
                      <div class="position-relative">
                        <Field
                          name="confirmpassword"
                          :type="showPassword1 ? 'text' : 'password'"
                          class="form-control pass-input mt-2"
                          :class="{ 'is-invalid': errors.confirmpassword }"
                        />
                        <span @click="toggleShow1" class="toggle-password2">
                          <i
                            :class="{
                              'fas fa-eye': showPassword1,
                              'fas fa-eye-slash': !showPassword1,
                            }"
                          ></i>
                        </span>
                        <div class="invalid-feedback">{{ errors.confirmpassword }}</div>
                        <div class="emailshow text-danger" id="confirmpassword"></div>
                      </div>
                    </div>
                    <div class="dont-have">
                      <label class="custom_check">
                        <input type="checkbox" name="rem_password" />
                        <span class="checkmark"></span> I have read and agree to all
                        <router-link to="/privacy-policy"
                          >Terms &amp; Conditions</router-link
                        >
                      </label>
                    </div>
                    <button
                      class="btn btn-primary w-100 btn-lg login-btn d-flex align-items-center justify-content-center"
                      type="submit"
                    >
                      Sign In Now<i class="feather-arrow-right ms-2"></i>
                    </button>
                    <div class="login-or">
                      <p><span>Or</span></p>
                    </div>
                    <div class="row social-login">
                      <div class="col-sm-4">
                        <a href="javascript:void(0);" class="btn btn-block"
                          ><img
                            src="@/assets/img/icon/google-icon.svg"
                            alt="Google"
                          />Google</a
                        >
                      </div>
                      <div class="col-sm-4">
                        <a href="javascript:void(0);" class="btn btn-block"
                          ><img src="@/assets/img/icon/fb-icon.svg" alt="Fb" />Facebook</a
                        >
                      </div>
                      <div class="col-sm-4">
                        <a href="javascript:void(0);" class="btn btn-block"
                          ><img
                            src="@/assets/img/icon/ios-icon.svg"
                            alt="Apple"
                          />Apple</a
                        >
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-8 dont-have d-flex align-items-center">
                        Already have account
                        <router-link to="/home/" class="ms-2">Sign in?</router-link>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /Login Content -->
      </div>
    </div>
  </div>
</template>
<script>
import { Form, Field } from "vee-validate";
import { router } from "@/router";
import * as Yup from "yup";
export default {
  components: {
    Form,
    Field,
  },
  data() {
    return {
      activeTab: "developer",
      showPassword: false,
      showPassword1: false,
      password: null,
    };
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
    buttonLabel() {
      return this.showPassword1 ? "Hide" : "Show";
    },
  },
  methods: {
    activateTab(tab) {
      this.activeTab = tab;
    },
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    toggleShow1() {
      this.showPassword1 = !this.showPassword1;
    },
  },
  mounted() {},
  setup() {
    const schema = Yup.object().shape({
      email: Yup.string().required("Email is required").email("Email is invalid"),
      password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .required("Password is required"),
      confirmpassword: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .required("Confirm password is required"),
    });
    const onSubmit = (values) => {
      if (values.password === values.confirmpassword) {
        let Rawdata = localStorage.getItem("storedData");
        let Pdata = [];
        Pdata = JSON.parse(Rawdata);
        const Eresult = Pdata.find(({ email }) => email == values.email);
        if (Eresult) {
          document.getElementById("email").innerHTML = "This email are already exist";
        } else {
          Pdata.push(values);
          const jsonData = JSON.stringify(Pdata);
          router.push("/");
          localStorage.setItem("storedData", jsonData);
        }
      } else {
        document.getElementById("confirmpassword").innerHTML = "Password not matching";
      }
    };
    return {
      schema,
      onSubmit,
    };
  },
};
</script>
