<template>
  <div class="dashboard-page">
    <div class="content">
      <div class="container">
        <div class="row">
          <div class="col-xl-3 col-lg-4 theiaStickySidebar">
            <freelancer-sidebar></freelancer-sidebar>
          </div>

          <div class="col-xl-9 col-lg-8">
            <div class="page-title">
              <h3>Manage Projects</h3>
            </div>
            <nav class="user-tabs mb-4">
              <ul class="nav nav-tabs nav-tabs-bottom nav-justified">
                <li class="nav-item">
                  <router-link class="nav-link" to="freelancer-view-project-detail"
                    >Overview & Discussions</router-link
                  >
                </li>
                <li class="nav-item">
                  <router-link class="nav-link" to="freelancer-milestones"
                    >Milestones</router-link
                  >
                </li>
                <li class="nav-item">
                  <router-link class="nav-link" to="freelancer-task">Tasks</router-link>
                </li>
                <li class="nav-item">
                  <router-link class="nav-link active" to="freelancer-files"
                    >Files</router-link
                  >
                </li>
                <li class="nav-item">
                  <router-link class="nav-link" to="freelancer-payment"
                    >Payments</router-link
                  >
                </li>
              </ul>
            </nav>

            <!-- project list -->
            <div class="my-projects-view">
              <div class="row">
                <div class="col-lg-12">
                  <div
                    class="title-head d-flex justify-content-between align-items-center mb-4"
                  >
                    <h4 class="mb-0">Files</h4>
                  </div>

                  <div class="table-responsive table-box manage-projects-table">
                    <table class="table table-center table-hover datatable no-sort">
                      <thead class="thead-pink">
                        <tr>
                          <th>File Preview</th>
                          <th>Title</th>
                          <th>Description</th>
                          <th>Type</th>
                          <th>Size</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="record in Freelancer_Files" :key="record.id">
                          <td>
                            <img
                              :src="require(`@/assets/img/icon/${record.Image}`)"
                              alt="Img"
                            />
                          </td>
                          <td>{{ record.Title }}</td>
                          <td>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                            do...
                          </td>
                          <td>{{ record.Type }}</td>
                          <td>{{ record.Size }}</td>
                          <td>
                            <div class="action-table-data">
                              <div class="edit-delete-action m-0">
                                <a href="javascript:void(0);" class="download-icon"
                                  ><i class="feather-download"></i
                                ></a>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <!-- project list -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Freelancer_Files from "@/assets/json/freelancer-files.json";
export default {
  data() {
    return {
      Freelancer_Files: Freelancer_Files,
    };
  },
};
</script>
