<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>

  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content container-fluid">
      <!-- Page Header -->
      <div class="page-header">
        <div class="row align-items-center">
          <div class="col">
            <breadcrumbadmin :title="title" :text="text" :text1="text1" :text2="text2" />
          </div>
          <div class="col-auto">
            <a
              href="javascript:;"
              class="btn me-2 add-button"
              data-bs-toggle="modal"
              data-bs-target="#add-category"
            >
              <i class="fas fa-plus"></i>
            </a>
            <a
              class="btn filter-btn"
              href="javascript:void(0);"
              @click="toggleFilterInputs"
              id="filter_search"
            >
              <i class="fas fa-filter"></i>
            </a>
          </div>
        </div>
      </div>
      <!-- /Page Header -->

      <!-- Search Filter -->
      <div
        class="card filter-card"
        :style="{ display: filterInputsDisplay }"
        id="filter_inputs"
      >
        <div class="card-body pb-0">
          <form action="javascript:;" method="post">
            <div class="row filter-row">
              <div class="col-sm-6 col-md-3">
                <div class="form-group">
                  <label>Add Bid</label>
                  <input class="form-control" type="text" />
                </div>
              </div>
              <div class="col-sm-6 col-md-3">
                <div class="form-group">
                  <label>From Date</label>
                  <div class="cal-icon">
                    <datepicker
                      v-model="startdate"
                      placeholder="Choose Date"
                      class="form-control datetimepicker"
                      :editable="true"
                      :clearable="false"
                    />
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-3">
                <div class="form-group">
                  <label>To Date</label>
                  <div class="cal-icon">
                    <datepicker
                      v-model="startdate"
                      placeholder="Choose Date"
                      class="form-control datetimepicker"
                      :editable="true"
                      :clearable="false"
                    />
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-md-3">
                <div class="form-group">
                  <button class="btn btn-primary btn-block w-100" type="submit">
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <!-- /Search Filter -->

      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-body">
              <div class="table-responsive">
                <div class="showentries">
                  <label
                    >Show
                    <select>
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                    entries
                  </label>
                  <div class="entries">
                    <span>Search: </span>
                    <input
                      type="search"
                      class="form-control form-control-sm ms-2"
                      placeholder=""
                      aria-controls="DataTables_Table_0"
                    />
                  </div>
                </div>

                <a-table
                  class="stripped table-hover"
                  :columns="columns"
                  :data-source="data"
                >
                  <template #bodyCell="{ column, record }">
                    <template v-if="column.key === 'Name'">
                      <div>{{ record.Name }}</div>
                    </template>
                    <template v-else-if="column.key === 'Info'">
                      <div class="white-space">
                        {{ record.Info }}
                      </div>
                    </template>
                    <template v-else-if="column.key === 'id'">
                      <td class="text-end d-flex">
                        <a
                          href="javascript:void(0);"
                          class="btn btn-sm btn-secondary me-2"
                          data-bs-toggle="modal"
                          data-bs-target="#edit-category"
                          ><i class="far fa-edit"></i
                        ></a>
                        <a
                          href="javascript:void(0);"
                          class="btn btn-sm btn-danger"
                          data-bs-toggle="modal"
                          data-bs-target="#delete_category"
                        >
                          <i class="far fa-trash-alt"></i>
                        </a>
                      </td>
                    </template>
                  </template>
                </a-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Wrapper -->
  </div>

  <!-- The Modal -->
  <div class="modal fade custom-modal" id="add-category">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Add Bid Fees</h4>
          <button type="button" class="close" data-bs-dismiss="modal">
            <span>&times;</span>
          </button>
        </div>

        <!-- Modal body -->
        <div class="modal-body">
          <form>
            <div class="form-group">
              <label>Name</label>
              <input type="text" class="form-control" placeholder="Enter Fee Name" />
            </div>
            <div class="form-group">
              <label>Info</label>
              <textarea rows="5" class="form-control" placeholder="Enter Info"></textarea>
            </div>
            <div class="form-group">
              <label>Value</label>
              <input type="text" class="form-control" placeholder="Enter Value" />
            </div>
            <div class="mt-4">
              <button type="submit" class="btn btn-primary btn-block">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /The Modal -->

  <!-- The Modal -->
  <div class="modal fade custom-modal" id="edit-category">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Edit Bid Fees</h4>
          <button type="button" class="close" data-bs-dismiss="modal">
            <span>&times;</span>
          </button>
        </div>

        <!-- Modal body -->
        <div class="modal-body">
          <form>
            <div class="form-group">
              <label>Name</label>
              <input type="text" class="form-control" value="Bidding on projects	" />
            </div>
            <div class="form-group">
              <label>Info</label>
              <textarea rows="6" class="form-control">
Freelancers are able to bid for free up to their monthly allocation of bids provided by their membership package. Optional upgrades to promote a bid will incur additional costs.</textarea
              >
            </div>
            <div class="form-group">
              <label>Value</label>
              <input type="text" class="form-control" value="FREE" />
            </div>
            <div class="mt-4">
              <button type="submit" class="btn btn-primary btn-block">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- /The Modal -->

  <!--Delete modal-->
  <delete />
  <!-- /Delete modal-->
</template>

<script>
const columns = [
  {
    title: "Name",
    dataIndex: "Name",
    sorter: {
      compare: (a, b) => {
        a = a.Name.toLowerCase();
        b = b.Name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Info",
    dataIndex: "Info",
    key: "Info",
    sorter: {
      compare: (a, b) => {
        a = a.Info.toLowerCase();
        b = b.Info.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Value",
    dataIndex: "Value",
    sorter: {
      compare: (a, b) => {
        a = a.Value.toLowerCase();
        b = b.Value.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Action",
    key: "id",
    sorter: true,
  },
];

const data = [
  {
    Name: "Bidding on projects",
    Info:
      "Freelancers are able to bid for free up to their monthly allocation of bids provided by their membership package. Optional upgrades to promote a bid will incur additional costs.",
    Value: "FREE",
  },
  {
    Name: "Sponsored bid",
    Info:
      "Sponsoring your bid is a way to get noticed immediately by Employers. Use it to move your bid to the top of the bid list by paying a sponsorship amount. Sponsored bids are ranked in the bid list by highest amount paid then by reputation.",
    Value: "0.75% of bid amount(minimum $5.00USD, maximum$20.00 USD)",
  },
  {
    Name: "Highlight Bid",
    Info: "Highlight your bid to visually stand out to the Employer from other bidders.",
    Value: "$50.00",
  },
];

import { ref } from "vue";
const currentDate = ref(new Date());
export default {
  data() {
    return {
      columns,
      data,
      title: "Bid Fees",
      text: "Home",
      text1: "Fees",
      text2: "Bid Fees",
      startdate: currentDate,
      filterInputsDisplay: "none",
    };
  },
  methods: {
    toggleFilterInputs() {
      this.filterInputsDisplay = this.filterInputsDisplay === "none" ? "block" : "none";
    },
  },
};
</script>
