const areaChart = {
  series: [{
    name: 'freelance Developers',
    color: '#0B6BCB',
    data: [31, 40, 28, 51, 42, 109, 100]
    },
    {
    name: 'Developers per project',
    color: '#cdd5ef',
    data: [11, 32, 45, 32, 34, 52, 41]
    },
    {
    name: 'completed projects',
    color: '#feb019',
    data: [12, 36, 42, 30, 39, 58, 40]
    }],
   
    sline: {
      chart: {
        id: "vuechart-example",
      },
      chart:{
        height: 335,
        type: 'area',
        toolbar: {
          show: false
        }
      },
      dataLabels: {
        enabled: false
      },
        stroke: {
        curve: 'smooth'
      },
      xaxis: {
        type: 'datetime',
        categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"]
      },
      tooltip: {
      x: {
        format: 'dd/MM/yy HH:mm'
      },
    },
    }
   
};

export {
  areaChart
}
