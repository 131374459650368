<template>
  <section class="section feature-count feature-count-three">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-12 mx-auto">
          <div
            class="section-header section-header-three feature-count-head aos"
            data-aos="fade-up"
          >
            <h2 class="header-title">Achievement We Have Earned</h2>
            <p>
              At Freelancer, we believe that talent is borderless and opportunity should
              be too.
            </p>
          </div>
        </div>
      </div>
      <div class="row section-bottom-cards">
        <!-- Feature Item -->
        <div class="col-xl-3 col-md-6">
          <div class="feature-item freelance-count aos" data-aos="fade-up">
            <div class="feature-icon">
              <img
                src="@/assets/img/icon/count-icon-01.svg"
                class="img-fluid"
                alt="Img"
              />
            </div>
            <div class="feature-content course-count">
              <h3>
                <vue3-autocounter
                  class="counter-up"
                  ref="counter"
                  :startAmount="0"
                  :delay="3"
                  :endAmount="9207"
                  :duration="2"
                  separator=","
                  :autoinit="true"
                />
              </h3>
              <p>Freelance developers</p>
            </div>
          </div>
        </div>
        <!-- /Feature Item -->

        <!-- Feature Item -->
        <div class="col-xl-3 col-md-6">
          <div class="feature-item aos" data-aos="fade-up">
            <div class="feature-icon">
              <img
                src="@/assets/img/icon/count-icon-02.svg"
                class="img-fluid"
                alt="Img"
              />
            </div>
            <div class="feature-content course-count">
              <h3>
                <vue3-autocounter
                  class="counter-up"
                  ref="counter"
                  :startAmount="0"
                  :delay="3"
                  :endAmount="6000"
                  :duration="2"
                  separator=","
                  :autoinit="true"
                />
              </h3>
              <p>Projects Added</p>
            </div>
          </div>
        </div>
        <!-- /Feature Item -->

        <!-- Feature Item -->
        <div class="col-xl-3 col-md-6">
          <div class="feature-item comp-project aos" data-aos="fade-up">
            <div class="feature-icon">
              <img
                src="@/assets/img/icon/count-icon-03.svg"
                class="img-fluid"
                alt="Img"
              />
            </div>
            <div class="feature-content course-count">
              <h3>
                <vue3-autocounter
                  class="counter-up"
                  ref="counter"
                  :startAmount="0"
                  :delay="3"
                  :endAmount="919207"
                  :duration="2"
                  separator=","
                  :autoinit="true"
                />
              </h3>
              <p>Completed projects</p>
            </div>
          </div>
        </div>
        <!-- /Feature Item -->

        <!-- Feature Item -->
        <div class="col-xl-3 col-md-6">
          <div class="feature-item comp-project aos" data-aos="fade-up">
            <div class="feature-icon">
              <img
                src="@/assets/img/icon/count-icon-04.svg"
                class="img-fluid"
                alt="Img"
              />
            </div>
            <div class="feature-content course-count">
              <h3>
                <vue3-autocounter
                  class="counter-up"
                  ref="counter"
                  :startAmount="0"
                  :delay="3"
                  :endAmount="998"
                  :duration="2"
                  separator=","
                  :autoinit="true"
                />
              </h3>
              <p>Companies Registered</p>
            </div>
          </div>
        </div>
        <!-- /Feature Item -->
      </div>
    </div>
  </section>
</template>
