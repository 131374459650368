<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-8 offset-md-2">
          <!-- Page Not Found -->
          <div class="empty-content text-center">
            <img src="@/assets/img/404.png" alt="logo" class="img-fluid" />
            <h2>Page not found</h2>
            <p>
              Oops! looks like you followed a bad link. If you think this is a problem
              with us, please tell us.
            </p>
            <div class="btn-item">
              <router-link class="btn get-btn" to="/home/"
                >Go To Home <i class="feather-arrow-right ms-2"></i
              ></router-link>
              <a class="btn courses-btn" href="javascript:void(0);"
                >Back <i class="feather-arrow-right ms-2"></i
              ></a>
            </div>
          </div>
          <!-- / Page Not Found -->
        </div>
      </div>
    </div>
  </div>
</template>
