<template>
  <div class="dashboard-page">
    <div class="content content-page">
      <div class="container">
        <div class="row">
          <div class="col-xl-3 col-lg-4 theiaStickySidebar">
            <freelancer-sidebar></freelancer-sidebar>
          </div>

          <div class="col-xl-9 col-lg-8">
            <div class="dashboard-sec payout-section freelancer-statements plan-billing">
              <div class="page-title portfolio-title">
                <h3 class="mb-0">Plan & Billing</h3>
              </div>
              <div class="plan-billing-section">
                <div class="row row-gap">
                  <div
                    class="col-xl-4 col-md-6"
                    v-for="record in Freelancer_Membership"
                    :key="record.id"
                  >
                    <div class="package-detail">
                      <h4>{{ record.Plan }}</h4>
                      <p>{{ record.Details }}</p>
                      <h3 class="package-price">
                        {{ record.Month }} <span>/ Month</span>
                      </h3>
                      <div class="package-feature">
                        <ul>
                          <li>{{ record.Credits }}</li>
                          <li>{{ record.Service }}</li>
                          <li>{{ record.Visibility }}</li>
                          <li>{{ record.Features }}</li>
                          <li>{{ record.Visibility1 }}</li>
                          <li>{{ record.Expiry }}</li>
                          <li :class="record.Class">{{ record.Profile }}</li>
                        </ul>
                      </div>
                      <a
                        href="#payout_modal"
                        data-bs-toggle="modal"
                        class="btn btn-outline-primary btn-block"
                        >Select Plan</a
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="page-title">
                <h3>Current Plan</h3>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="member-plan pro-box">
                    <div class="member-detail">
                      <div class="row">
                        <div class="col-md-4">
                          <h5>The Unlimited</h5>
                          <div class="yr-amt">Our most popular plan for small teams.</div>
                          <div class="expiry-on">
                            <span><i class="feather-calendar"></i>Renew Date:</span> 24
                            JAN 2022
                          </div>
                        </div>
                        <div class="col-md-8 change-plan mt-3 mt-md-0">
                          <div>
                            <h3>$1200</h3>
                            <div class="yr-duration">Duration: One Year</div>
                          </div>
                          <div class="change-plan-btn">
                            <a href="javascript:void(0);" class="btn btn-primary-lite"
                              >Cancel Subscription</a
                            >
                            <a
                              href="javascript:void(0);"
                              class="btn btn-primary black-btn"
                              >Change Plan</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Table -->
              <div class="table-top-section">
                <div class="table-header">
                  <h5 class="mb-0">Statement</h5>
                </div>
              </div>
              <membership-table></membership-table>
              <!-- /Table -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <freelancer-membership-modal></freelancer-membership-modal>
</template>
<script>
import Freelancer_Membership from "@/assets/json/freelancer-membership.json";
export default {
  data() {
    return {
      Freelancer_Membership: Freelancer_Membership,
    };
  },
};
</script>
