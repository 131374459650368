<template>
  <div class="modal fade edit-proposal-modal" id="file">
    <div class="modal-dialog modal-dialog-centered modal-md">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">EDIT PROPOSAL</h4>
          <span class="modal-close"
            ><a href="javascript:void(0);" data-bs-dismiss="modal" aria-label="Close"
              ><i class="feather-x"></i></a
          ></span>
        </div>
        <div class="modal-body">
          <form action="#">
            <div class="modal-info">
              <div class="row">
                <div class="col-md-6">
                  <div class="input-block">
                    <label class="form-label">Your Price</label>
                    <input type="text" class="form-control" value="$90" />
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="input-block">
                    <label class="form-label">Estimated Delivery</label>
                    <input type="text" class="form-control" value="100" />
                    <span class="input-group-text">Days</span>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="input-block">
                    <label class="form-label">Cover Letter</label>
                    <textarea class="form-control summernote"></textarea>
                  </div>
                </div>
              </div>
              <div class="modal-info">
                <h4>Suggested Milestones</h4>
                <div class="row">
                  <div class="col-md-8">
                    <div class="input-block">
                      <label class="form-label">Milestone name</label>
                      <input type="text" class="form-control" value="Initial Setup" />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="input-block">
                      <label class="form-label">Amount</label>
                      <input type="text" class="form-control" value="100" />
                      <span class="input-group-text">$</span>
                      <div class="add-new text-end mt-2">
                        <a href="javascript:void(0);"
                          ><i class="feather feather-plus-circle me-1"></i>Add New</a
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="modal-check-item">
                      <div>
                        <label class="custom_check">
                          <input type="checkbox" name="rem_password" />
                          <span class="checkmark"></span>Stick this Proposal to the Top
                        </label>
                        <p>
                          The sticky proposal will always be displayed on top of all the
                          proposals.
                        </p>
                      </div>
                      <div class="price">
                        <span>$12.00</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="modal-check-item bg-light-blue">
                      <div>
                        <label class="custom_check">
                          <input type="checkbox" name="rem_password" />
                          <span class="checkmark"></span>$ Make Sealed Proposal
                        </label>
                        <p>
                          The sealed proposal will be sent to the project author only it
                          will not be visible publically.
                        </p>
                      </div>
                      <div class="price">
                        <span>$7.00</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <label class="custom_check">
                      <input type="checkbox" name="rem_password" />
                      <span class="checkmark"></span>I agree to the terms and privacy
                      policy
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="submit-section text-end">
              <a href="javascript:void(0);" class="btn btn-cancel">Cancel</a>
              <button type="submit" class="btn btn-primary submit-btn">
                Save Changes
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
