<template>
  <div class="card">
    <div class="card-body">
      <div class="showentries">
        <label
          >Show
          <select>
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
          entries
        </label>
        <div class="entries">
          <span>Search: </span>
          <input
            type="search"
            class="form-control form-control-sm ms-2"
            placeholder=""
            aria-controls="DataTables_Table_0"
          />
        </div>
      </div>
      <div class="table-responsive">
        <a-table :columns="columns" :data-source="data">
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'Name'">
              <div>{{ record.Name }}</div>
            </template>
            <template v-else-if="column.key === 'Img'">
              <h2 class="table-avatar">
                <router-link to="/admin/profile">
                  <img
                    class="me-2 verify-img rounded-circle img-fluid"
                    :src="require(`@/assets/admin_img/profiles/${record.Document}`)"
                    alt="User Image"
                  />
                </router-link>
              </h2>
            </template>
            <template v-else-if="column.key === 'action'">
              <div>
                <a href="javascript:;" class="btn btn-success btn-disable me-2"
                  >Approve</a
                >
                <a href="javascript:;" class="btn btn-disable">Reject</a>
              </div>
            </template>
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
const columns = [
  {
    title: "No",
    dataIndex: "No",
    sorter: true,
  },
  {
    title: "Name",
    dataIndex: "Name",
    key: "Name",
    sorter: {
      compare: (a, b) => {
        a = a.Name.toLowerCase();
        b = b.Name.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Contact Number",
    dataIndex: "ContactNumber",
    sorter: {
      compare: (a, b) => {
        a = a.ContactNumber.toLowerCase();
        b = b.ContactNumber.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "CNIC / Passport / NIN / SSN",
    dataIndex: "CNIC",
    sorter: {
      compare: (a, b) => {
        a = a.CNIC.toLowerCase();
        b = b.CNIC.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Document",
    dataIndex: "Document",
    key: "Img",
    sorter: true,
  },
  {
    title: "Address",
    dataIndex: "Address",
    sorter: {
      compare: (a, b) => {
        a = a.Address.toLowerCase();
        b = b.Address.toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
      },
    },
  },
  {
    title: "Actions",
    key: "action",
    sorter: true,
  },
];

const data = [
  {
    No: 1,
    Name: "Crystal Kemper",
    ContactNumber: "601-316-9060",
    CNIC: "518-43-5504",
    Document: "avatar-11.jpg",
    Address: "Tanglewood Road, Fulton",
  },
  {
    No: 2,
    Name: "Cutshaw Emiley",
    ContactNumber: "601-283-5402",
    CNIC: "158-07-8510",
    Document: "avatar-12.jpg",
    Address: "2764 Brownton Road, Belzoni",
  },
  {
    No: 3,
    Name: "Nick John",
    ContactNumber: "601-316-9060",
    CNIC: "221-04-8830",
    Document: "avatar-13.jpg",
    Address: "Maine, USA",
  },
  {
    No: 4,
    Name: "Floyd Lewis",
    ContactNumber: "601-316-9060",
    CNIC: "221-04-8830",
    Document: "avatar-14.jpg",
    Address: "Alabama, USA",
  },
  {
    No: 5,
    Name: "Santra Leodhapher",
    ContactNumber: "601-345-2325",
    CNIC: "221-04-8830",
    Document: "avatar-15.jpg",
    Address: "North Carolina, USA",
  },
  {
    No: 6,
    Name: "James Douglas",
    ContactNumber: "601-788-9087",
    CNIC: "231-65-5656",
    Document: "avatar-16.jpg",
    Address: "Indiana, USA",
  },
  {
    No: 7,
    Name: "John Smith",
    ContactNumber: "601-232-3422",
    CNIC: "221-05-3445",
    Document: "avatar-01.jpg",
    Address: "New Jersey, USA",
  },
  {
    No: 8,
    Name: "Robert Leodhapher",
    ContactNumber: "601-454-8666",
    CNIC: "231-45-3434",
    Document: "avatar-02.jpg",
    Address: "Kentucky, USA",
  },
  {
    No: 9,
    Name: "Will Smith",
    ContactNumber: "601-316-9060",
    CNIC: "221-04-8830",
    Document: "avatar-08.jpg",
    Address: "Colorado, USA",
  },
  {
    No: 10,
    Name: "Reboah Daniel",
    ContactNumber: "601-316-9060",
    CNIC: "221-04-8830",
    Document: "avatar-06.jpg",
    Address: "California, USA",
  },
];

export default {
  data() {
    return {
      columns,
      data,
    };
  },
};
</script>
