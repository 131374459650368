<template>
  <div class="content content-page">
    <div class="container">
      <div class="row">
        <div class="col-xl-3 col-lg-4 theiaStickySidebar">
          <freelancer-sidebar></freelancer-sidebar>
        </div>

        <div class="col-xl-9 col-lg-8">
          <div class="dashboard-sec payout-section freelancer-statements">
            <div class="page-title portfolio-title">
              <h3 class="mb-0">Verification Details</h3>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-12">
                <div class="mb-3">
                  <label class="focus-label">Document Type</label>
                  <vue-select
                    :options="Freelancer_Verify"
                    id="freelancerverify"
                    placeholder="Select"
                  />
                </div>
              </div>
              <div class="col-lg-6 col-md-12">
                <div class="mb-3">
                  <label class="focus-label">Document Number</label>
                  <input type="text" class="form-control" />
                </div>
              </div>
              <div class="col-lg-12">
                <div class="input-block">
                  <label class="form-label">Document Number</label>
                  <div class="upload-sets">
                    <label class="upload-filesview">
                      Browse File
                      <input type="file" />
                    </label>
                    <h6>Or Drag &amp; Drop here</h6>
                  </div>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="d-flex doc-btn">
                  <a
                    href="#success-verified"
                    data-bs-toggle="modal"
                    class="btn btn-primary"
                    >Submit Verification</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <freelancer-verify-modal></freelancer-verify-modal>
</template>
<script>
export default {
  data() {
    return {
      Freelancer_Verify: ["Select", "Option1", "Option2"],
    };
  },
};
</script>
