<template>
    <div class="subscribe-employe">
        <ul v-bind:class="{active: currentPath == '/project-earnings' || currentPath == '/project-earnings',}">
            <li :class="currentPath == '/project-earnings' ? 'active-project' : 'notactive'">
                <router-link to="/admin/project-earnings">Administrator </router-link>
            </li>
            <li :class="currentPath == 'earning-employer' ? 'active-project' : 'notactive'">
                <router-link to="/admin/earning-employer">Employer</router-link>
            </li>
            <li :class="currentPath == 'earning-freelancer' ? 'active-project' : 'notactive'">
                <router-link to="/admin/earning-freelancer">Freelancer</router-link>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    computed: {
        currentPath() {
        return this.$route.name;
        },
        returnMenu() {
            return (
                this.$route.name == "project-earnings" ||
                this.$route.name == "earning-employer" ||
                this.$route.name == "earning-freelancer" 
            );
        },
    },
}
</script>