<template>
    <!--- Developed Project  -->
    <section class="section news pt-0 review-set">
        <div class="container">
            <div class="row">
                <!-- Feature Item -->
                <div class="col-lg-6 col-md-12">
                    <div class="work-box bg1" data-aos="zoom-in" data-aos-duration="1000">
                        <div class="work-content">
                            <h2>I need a Developed <span>Project</span></h2>
                            <p>Get the perfect Developed project for your budget from our creative community.</p>
                            <router-link to="/freelancer/project" class="btn btn-primary">Browse</router-link>
                        </div>
                    </div>
                </div>
                <!-- /Feature Item -->
                <div class="col-lg-6 col-md-12">
                    <div class="work-box aos bg2" data-aos="zoom-in" data-aos-duration="2000">
                        <div class="work-content ">
                            <h2>Find Your Next Great <span>Job Opportunity!</span></h2>
                            <p>Do you want to earn money, find unlimited clients and build your freelance career?</p>
                            <router-link to="/freelancer/project" class="btn btn-primary">Browse</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--- /Developed Project  -->

    <!-- Our Feature -->
    <section class="section projects pt-0">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-sm-12 col-12 mx-auto text-center">
                    <div class="section-header aos" data-aos="fade-up">
                        <h2 class="header-title">Achievement We Have Earned</h2>
                        <p>At Freelancer, we believe that talent is borderless and opportunity should be too.</p>
                    </div>
                </div>
                <!-- Feature Item -->
                <div class="col-xl-3 col-md-6 aos" data-aos="zoom-in" data-aos-duration="1000">
                    <div class="feature-item freelance-count ">
                        <div class="feature-icon">
                            <img src="@/assets/img/icon/achievement-1.svg" class="img-fluid" alt="Img">
                        </div>
                        <div class="feature-content course-count">
                            <h3>
                                <vue3-autocounter class="counter-up" ref="counter" :startAmount="1000" :delay="3" :endAmount="9207"
                                :duration="5" separator="," :autoinit="true" />
                            </h3>
                            <p>Freelance developers</p>
                        </div>
                    </div>
                </div>
                <!-- /Feature Item -->

                <!-- Feature Item -->
                <div class="col-xl-3 col-md-6 aos" data-aos="zoom-in" data-aos-duration="1500">
                    <div class="feature-item ">
                        <div class="feature-icon">
                            <img src="@/assets/img/icon/achievement-2.svg" class="img-fluid" alt="Img">
                        </div>
                        <div class="feature-content course-count">
                            <h3>
                                <vue3-autocounter class="counter-up" ref="counter" :startAmount="1000" :delay="3" :endAmount="8368"
                                :duration="5" separator="," :autoinit="true" /></h3>
                            <p>Projects Added</p>
                        </div>
                    </div>
                </div>
                <!-- /Feature Item -->

                <!-- Feature Item -->
                <div class="col-xl-3 col-md-6 aos" data-aos="zoom-in" data-aos-duration="2000">
                    <div class="feature-item comp-project ">
                        <div class="feature-icon">
                            <img src="@/assets/img/icon/achievement-3.svg" class="img-fluid" alt="Img">
                        </div>
                        <div class="feature-content course-count">
                            <h3>
                                <vue3-autocounter class="counter-up" ref="counter" :startAmount="1000" :delay="3" :endAmount="9198"
                                :duration="5" separator="," :autoinit="true" />
                            </h3>
                            <p>Completed projects</p>
                        </div>
                    </div>
                </div>
                <!-- /Feature Item -->

                <!-- Feature Item -->
                <div class="col-xl-3 col-md-6 aos" data-aos="zoom-in" data-aos-duration="2500">
                    <div class="feature-item comp-project ">
                        <div class="feature-icon">
                            <img src="@/assets/img/icon/achievement-4.svg" class="img-fluid" alt="Img">
                        </div>
                        <div class="feature-content course-count">
                            <h3>
                                <vue3-autocounter class="counter-up" ref="counter" :startAmount="10" :delay="3" :endAmount="998"
                                :duration="5" separator="," :autoinit="true" />
                            </h3>                            
                            <p>Companies Registered</p>
                        </div>
                    </div>
                </div>
                <!-- /Feature Item -->

            </div>
        </div>
    </section>
    <!-- /Our Feature -->
</template>