<template>
  <div class="about-us-page">
    <!-- Breadcrumb -->
    <pagebreadcrumb :title="title" :text="text" :text1="text1" />
    <!-- /Breadcrumb -->

    <!-- About -->
    <about></about>
    <!-- /About -->

    <great-about></great-about>

    <potential-about></potential-about>

    <archivement></archivement>

    <!-- Review -->
    <about-testimonial></about-testimonial>
    <!-- / Review -->

    <job-about></job-about>
  </div>
</template>
<script>
export default {
  data() {
    return {
      title: "About Us",
      text: "Home",
      text1: "About Us",
    };
  },
};
</script>
