<template>
    <div class="subscribe-employe users-list">
        <ul v-bind:class="{active: currentPath == 'deposit' || currentPath == 'deposit',}">
            <li :class="currentPath == 'deposit' ? 'active' : 'notactive'">
                <router-link to="/admin/deposit">History </router-link>
            </li>
            <li :class="currentPath == 'deposit-pending' ? 'active' : 'notactive'">
                <router-link to="/admin/deposit-pending">Pending</router-link>
            </li>
            <li :class="currentPath == 'deposit-hold' ? 'active' : 'notactive'">
                <router-link to="/admin/deposit-hold">On Hold</router-link>
            </li>
            <li :class="currentPath == 'deposit-cancelled' ? 'active' : 'notactive'">
                <router-link to="/admin/deposit-cancelled">Cancelled</router-link>
            </li>
            <li :class="currentPath == 'deposit-completed' ? 'active' : 'notactive'">
                <router-link to="/admin/deposit-completed">Completed</router-link>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    data() {
        return {
            
        }
    },
    computed: {
        currentPath() {
            return this.$route.name;
        },
        returnMenu() {
            return (
                this.$route.name == "deposit" ||
                this.$route.name == "deposit-pending" ||
                this.$route.name == "deposit-hold" ||
                this.$route.name == "deposit-cancelled" ||
                this.$route.name == "deposit-completed"
            );
        },
    },
}
</script>