<template>
  <layout-header></layout-header>
  <layout-sidebar></layout-sidebar>

  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content container-fluid">
      <breadcrumbadmin :title="title" :text="text" :text1="text1" :text2="text2" />

      <div class="row">
        <div class="col-lg-12">
          <!-- Settings Menu -->

          <settingstabset />

          <!-- Settings Menu -->

          <div class="row">
            <div class="col-md-6">
              <div class="card">
                <div class="card-body pt-0">
                  <div class="card-header">
                    <h5 class="card-title">Localization Details</h5>
                  </div>
                  <form>
                    <div class="settings-form">
                      <div class="form-group">
                        <label>Time Zone</label>
                        <vue-select
                          :options="Timezone"
                          placeholder="(UTC +5:30) Antarctica/Palmer"
                        />
                      </div>
                      <div class="form-group">
                        <label>Date Format</label>
                        <vue-select :options="Dates" placeholder="15 May 2016" />
                      </div>
                      <div class="form-group">
                        <label>Time Format</label>
                        <vue-select :options="Time" placeholder="12 Hours" />
                      </div>
                      <div class="form-group">
                        <label>Currency Symbol</label>
                        <vue-select :options="Currency" placeholder="$" />
                      </div>
                      <div class="form-group mb-0">
                        <div class="settings-btns">
                          <button type="submit" variant="orange">Update</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Page Wrapper -->
</template>

<script>
export default {
  data() {
    return {
      title: "Settings",
      text: "Home",
      text1: "Settings",
      text2: "Localization",
      Timezone: ["(UTC +5:30) Antarctica/Palmer", "(UTC+05:30) India"],
      Dates: [
        "15 May 2016",
        "15/05/2016",
        "15.05.2016",
        "15-05-2016",
        "05/15/2016",
        "2016/05/15",
        "2016-05-15",
      ],
      Time: ["12 Hours", "24 Hours", "36 Hours", "48 Hours", "60 Hours"],
      Currency: ["$", "₹", "£", "€"],
    };
  },
};
</script>
